import { Icon, Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import styles from "./styles.module.scss";

interface Props {
  messageId: string;
  children?: React.ReactNode;
}

const ErrorPlaceholder = React.memo(({ messageId, children }: Props) => (
  <div className={styles["container"]}>
    <div className={styles["icon-and-text"]}>
      <Icon iconName="Error" />
      <Label>
        <FormattedMessage id={messageId} />
      </Label>
    </div>
    {children || null}
  </div>
));

export default ErrorPlaceholder;
