import { Icon, Label } from "@fluentui/react";
import classnames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { useTeamPermission } from "../../hooks/permission";
import { BriefFormGroup } from "../../types/formGroup";
import styles from "./styles.module.scss";

interface Props {
  formGroup: BriefFormGroup;
  onDeleteFormGroup(formGroupId: string): void;
}

const FormGroupIcons = React.memo((props: { formGroup: BriefFormGroup }) => {
  const { formGroup } = props;
  return (
    <div className={classnames(styles["form-group-image-container"])}>
      {formGroup.forms.slice(0, 6).map(form => {
        if (form.image) {
          return (
            <div
              style={{ backgroundImage: `url("${form.image}")` }}
              className={styles["form-group-image"]}
              key={form.id}
            />
          );
        }
        return (
          <div className={styles["form-group-image"]} key={form.id}>
            {form.name.slice(0, 1)}
          </div>
        );
      })}
    </div>
  );
});

const FormGroupGridItem = React.memo((props: Props) => {
  const { formGroup, onDeleteFormGroup } = props;
  const { hasPermissionToRemoveResource } = useTeamPermission();

  const onDeleteClicked = React.useCallback(() => {
    onDeleteFormGroup(formGroup.id);
  }, [formGroup, onDeleteFormGroup]);

  return (
    <div
      className={classnames(styles["form-group-grid-item"], {
        [styles["compact"]]: !hasPermissionToRemoveResource,
      })}
      title={formGroup.name}
    >
      <Link to={`/form-group/${formGroup.id}/edit`}>
        {formGroup.forms.length > 0 ? (
          <FormGroupIcons formGroup={formGroup} />
        ) : (
          <div
            className={classnames(
              styles["form-group-image-container"],
              styles["image-placeholder"]
            )}
          />
        )}
      </Link>
      <div className={styles["bottom-container"]}>
        <Label className={styles["form-group-name"]}>{formGroup.name}</Label>
        {hasPermissionToRemoveResource && (
          <>
            <div className={styles["separator"]} />
            <div className={styles["delete-row"]}>
              <Icon iconName="Delete" onClick={onDeleteClicked} />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default FormGroupGridItem;
