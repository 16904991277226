import { MoveToNextStep, TerminateCurrentTutorial } from "../actions/tutorial";
import { ToolBoxTutorialTargetIds } from "../constants";
import { Tutorial } from "../models";

export const LabellerToolBarTutorial: Tutorial = [
  {
    id: ToolBoxTutorialTargetIds.InfoIcon,
    titleId: "tutorial.labeller_tool.info.header",
    descriptionId: "tutorial.labeller_tool.info.content",
    primaryButton: {
      textId: "teaching_bubble.lets_go",
      action: MoveToNextStep,
    },
    secondaryButton: {
      textId: "teaching_bubble.maybe_later",
      action: TerminateCurrentTutorial,
    },
    hideFooter: true,
  },
  [
    {
      id: ToolBoxTutorialTargetIds.SelectToolBox,
      titleId: "tutorial.labeller_tool.select.header",
      descriptionId: "tutorial.labeller_tool.select.content",
      primaryButton: {
        textId: "teaching_bubble.next",
        action: MoveToNextStep,
      },
    },
    {
      id: ToolBoxTutorialTargetIds.AnchorToolBox,
      titleId: "tutorial.labeller_tool.anchor.header",
      descriptionId: "tutorial.labeller_tool.anchor.content",
      primaryButton: {
        textId: "teaching_bubble.next",
        action: MoveToNextStep,
      },
    },
    {
      id: ToolBoxTutorialTargetIds.DetectionRegionToolBox,
      titleId: "tutorial.labeller_tool.detection_region.header",
      descriptionId: "tutorial.labeller_tool.detection_region.content",
      primaryButton: {
        textId: "teaching_bubble.next",
        action: TerminateCurrentTutorial,
      },
    },
  ],
];
