import * as React from "react";
import { useParams } from "react-router";

import { BatchNote } from "../components/BatchNote";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FormGroupNavBarLayout } from "../components/FormGroupNavBar";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { useBatchCommandBarItems } from "../hooks/command_bar_items";
import { useCommonFormGroupContainerState } from "../hooks/form_group";

type PathParam = {
  formGroupId: string;
};

export const FormGroupBatchContainer = React.memo(() => {
  const { formGroupId } = useParams<PathParam>();
  const containerState = useCommonFormGroupContainerState(formGroupId);

  const commandBarItems = useBatchCommandBarItems(
    ...(containerState.state === "success"
      ? [
          containerState.formGroup.id,
          containerState.formGroup.name,
          containerState.workerToken,
        ]
      : [undefined, undefined, undefined])
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
        ) : containerState.state === "success" ? (
          <FormGroupNavBarLayout
            formGroup={containerState.formGroup}
            commbarBarItems={commandBarItems}
          >
            <BatchNote />
          </FormGroupNavBarLayout>
        ) : null}
      </Main>
    </Layout>
  );
});

export default FormGroupBatchContainer;
