import {
  CommandBar,
  IBreadcrumbItem,
  ICommandBarItemProps,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

import { useLocale } from "../../contexts/locale";
import { DetailedForm } from "../../types/form";
import { Breadcrumb } from "../WrappedMSComponents/Breadcrumb";
import styles from "./styles.module.scss";

interface Props {
  form: DetailedForm;
  commbarBarItems?: ICommandBarItemProps[];
}

const FormNavBar = React.memo((props: Props) => {
  const { form, commbarBarItems } = props;
  const history = useHistory();
  const location = useLocation();
  const { localized } = useLocale();
  const onBreadcrumbItemClick = useCallback(
    (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      if (ev === undefined || item === undefined || item.href === undefined) {
        return;
      }
      ev.preventDefault();
      history.push(item.href);
    },
    [history]
  );

  const breadcrumbItems: IBreadcrumbItem[] = useMemo(() => {
    return form.isTemplate
      ? [
          {
            text: localized("form_template.header_prefix", {
              name: localized(`form_template.${form.name}`),
            }),
            key: form.name,
          },
        ]
      : [
          {
            text: localized("header.forms"),
            key: "forms",
            href: "/form",
            onClick: onBreadcrumbItemClick,
          },
          { text: form.name, key: form.name },
        ];
  }, [form.name, form.isTemplate, localized, onBreadcrumbItemClick]);

  const onPivotItemClick = useCallback(
    (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
      if (!ev || !item || !item.props.itemKey) {
        return;
      }
      ev.preventDefault();
      ev.stopPropagation();
      if (window.location.pathname.split("/")[1] === "form-template") {
        history.push(`/form-template/${form.id}/${item.props.itemKey}`);
      } else {
        history.push(`/form/${form.id}/${item.props.itemKey}`);
      }
    },
    [form.id, history]
  );

  const selectedKey = useMemo(
    () => location.pathname.split("/")[3],
    [location.pathname]
  );

  return (
    <div className={styles["container"]}>
      <Breadcrumb className={styles["breadcrumb"]} items={breadcrumbItems} />
      <div className={styles["tab-bar"]}>
        <Pivot onLinkClick={onPivotItemClick} selectedKey={selectedKey}>
          {!form.isTemplate && (
            <PivotItem
              headerText={localized("header.edit")}
              itemKey="edit"
              itemIcon="tag"
            />
          )}
          <PivotItem
            headerText={localized("header.api")}
            itemKey="api"
            itemIcon="PlugConnected"
          />
          <PivotItem
            headerText={localized("header.test")}
            itemKey="test"
            itemIcon="TestBeaker"
          />
          <PivotItem
            headerText={localized("header.batch")}
            itemKey="batch"
            itemIcon="Copy"
          />
        </Pivot>
        {commbarBarItems && <CommandBar items={commbarBarItems} />}
      </div>
    </div>
  );
});

interface FormNavBarLayoutProps extends Props {
  children: React.ReactNode;
}

export const FormNavBarLayout: React.FC<FormNavBarLayoutProps> = (
  props: FormNavBarLayoutProps
) => {
  const { children, ...rest } = props;

  return (
    <>
      <FormNavBar {...rest} />
      <div className={styles["content"]}>{props.children}</div>
    </>
  );
};

export default FormNavBar;
