import { useSelector } from "react-redux";

import { RootState } from "../redux/types";

export function useUserSetting() {
  const isCustomPlan = useSelector(
    (state: RootState) =>
      (state.resourceOwner.plan && state.resourceOwner.plan === "custom") ||
      false
  );
  const isGoogleServiceAccoutKeySet = useSelector(
    (state: RootState) => state.resourceOwner.isGoogleOcrEngineSet
  );
  const isAzureSubscriptionKeySet = useSelector(
    (state: RootState) => state.resourceOwner.isAzureOcrEngineSet
  );

  return {
    isCustomPlan,
    isGoogleServiceAccoutKeySet,
    isAzureSubscriptionKeySet,
  };
}
