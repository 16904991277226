import { apiClient } from "../apiClient";
import { ListTeamOptions } from "../apiClient/mixin/team";
import { useThunkDispatch } from "../hooks/thunk";
import { Dispatch, RootState } from "../redux/types";
import { BriefCustomModel } from "../types/customModel";
import { BriefForm } from "../types/form";
import { BriefFormGroup } from "../types/formGroup";
import { PageInfoWithOffset } from "../types/pageInfo";
import { PlanQuota } from "../types/quota";
import {
  TeamDetail,
  TeamListItem,
  TeamMembersAndInvitations,
} from "../types/team";
import { Usage, UsageRange } from "../types/usage";

export interface AdminGotTeamList {
  readonly type: "AdminGotTeamList";
  readonly payload: {
    region: string;
    teams: TeamListItem[];
    listTeamOptions?: ListTeamOptions;
    pageInfo: PageInfoWithOffset;
  };
}

export interface AdminGettingTeamList {
  readonly type: "AdminGettingTeamList";
  readonly payload: {
    region: string;
  };
}

export interface AdminGotTeamDetail {
  readonly type: "AdminGotTeamDetail";
  readonly payload: {
    team: TeamDetail;
  };
}

export interface AdminGettingTeamDetail {
  readonly type: "AdminGettingTeamDetail";
  readonly teamId: string;
  readonly region: string;
}

export interface AdminGettingTeamMembers {
  readonly type: "AdminGettingTeamMembers";
  readonly payload: {
    readonly teamId: string;
    readonly region: string;
  };
}

export interface AdminGotTeamMembers {
  readonly type: "AdminGotTeamMembers";
  readonly payload: {
    readonly teamId: string;
    readonly region: string;
    readonly membersAndInvitations: TeamMembersAndInvitations;
  };
}

export interface AdminGettingTeamUsage {
  readonly type: "AdminGettingTeamUsage";
  readonly payload: {
    readonly range: UsageRange;
    readonly teamId: string;
    readonly region: string;
  };
}

export interface AdminGotTeamUsage {
  readonly type: "AdminGotTeamUsage";
  readonly payload: {
    readonly range: UsageRange;
    readonly teamId: string;
    readonly region: string;
    readonly usages: Usage[];
  };
}

export interface AdminGettingTeamForms {
  readonly type: "AdminGettingTeamForms";
  readonly payload: {
    teamId: string;
    region: string;
  };
}

export interface AdminGotTeamForms {
  readonly type: "AdminGotTeamForms";
  readonly payload: {
    forms: BriefForm[];
    pageInfo: PageInfoWithOffset;
  };
}

export interface AdminGettingTeamFormGroups {
  readonly type: "AdminGettingTeamFormGroups";
  readonly payload: {
    teamId: string;
    region: string;
  };
}

export interface AdminGotTeamFormGroups {
  readonly type: "AdminGotTeamFormGroups";
  readonly payload: {
    formGroups: BriefFormGroup[];
    pageInfo: PageInfoWithOffset;
  };
}

export interface AdminGettingTeamCustomModels {
  readonly type: "AdminGettingTeamCustomModels";
  readonly payload: {
    teamId: string;
    region: string;
  };
}

export interface AdminGotTeamCustomModels {
  readonly type: "AdminGotTeamCustomModels";
  readonly payload: {
    customModels: BriefCustomModel[];
    pageInfo: PageInfoWithOffset;
  };
}

export function listTeams(
  region: string,
  options?: ListTeamOptions,
  offset: number = 0,
  size: number = 20
) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: "AdminGettingTeamList",
      payload: {
        region,
      },
    });

    const listResult = await apiClient.listTeam(region, size, offset, options);

    dispatch({
      type: "AdminGotTeamList",
      payload: {
        region,
        teams: listResult.teams,
        listTeamOptions: options,
        pageInfo: listResult.pageInfo,
      },
    });
  };
}

export function getTeam(teamId: string, region: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: "AdminGettingTeamDetail",
      teamId,
      region,
    });

    const { team } = await apiClient.getTeam(teamId, region);

    dispatch({
      type: "AdminGotTeamDetail",
      payload: {
        team,
      },
    });
  };
}

export function getTeamMembers(teamId: string, region: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: "AdminGettingTeamMembers",
      payload: {
        teamId,
        region,
      },
    });

    const result = await apiClient.listTeamMembers(teamId, region);

    dispatch({
      type: "AdminGotTeamMembers",
      payload: {
        teamId,
        region,
        membersAndInvitations: result,
      },
    });
  };
}

export function getTeamUsage(
  range: UsageRange,
  teamId: string,
  region: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: "AdminGettingTeamUsage",
      payload: {
        range,
        teamId,
        region,
      },
    });

    const usages = await apiClient.getUsage(range, teamId, region);

    dispatch({
      type: "AdminGotTeamUsage",
      payload: {
        range,
        teamId,
        region,
        usages,
      },
    });
  };
}

export function listTeamForms(
  teamId: string,
  region: string,
  offset: number = 0,
  size: number = 20
) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: "AdminGettingTeamForms",
      payload: {
        teamId,
        region,
      },
    });

    const listResult = await apiClient.listFormWithOffset(
      size,
      offset.toString(),
      undefined,
      teamId,
      region
    );

    dispatch({
      type: "AdminGotTeamForms",
      payload: {
        forms: listResult.forms,
        pageInfo: listResult.pageInfo,
      },
    });
  };
}

export function listTeamFormGroups(
  teamId: string,
  region: string,
  offset: number = 0,
  size: number = 20
) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: "AdminGettingTeamFormGroups",
      payload: {
        teamId,
        region,
      },
    });

    const listResult = await apiClient.listFormGroupsWithOffset(
      size,
      offset.toString(),
      teamId,
      region
    );

    dispatch({
      type: "AdminGotTeamFormGroups",
      payload: {
        formGroups: listResult.formGroups,
        pageInfo: listResult.pageInfo,
      },
    });
  };
}

export function listTeamCustomModels(
  teamId: string,
  region: string,
  offset: number = 0,
  size: number = 20
) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: "AdminGettingTeamCustomModels",
      payload: {
        teamId,
        region,
      },
    });

    const listResult = await apiClient.listCustomModelsWithOffset(
      size,
      offset.toString(),
      teamId,
      region
    );

    dispatch({
      type: "AdminGotTeamCustomModels",
      payload: {
        customModels: listResult.customModels,
        pageInfo: listResult.pageInfo,
      },
    });
  };
}

export function setTrial(
  teamId: string,
  region: string,
  quota?: number,
  trialEndAt?: Date
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const team = getState().admin.teamDetail.team;

    await apiClient.setTrial(quota, trialEndAt, teamId, region);

    if (team !== undefined) {
      const planQuota: PlanQuota | null =
        quota !== undefined
          ? {
              aggregate: {
                hardLimit: quota,
                softLimit: quota,
              },
              annually: null,
              daily: null,
              monthly: null,
            }
          : null;

      dispatch({
        type: "AdminGotTeamDetail",
        payload: {
          team: {
            ...team,
            plan: "trial",
            quota: planQuota,
            trialEndAt,
          },
        },
      });
    }
  };
}

export function clearTrial(teamId: string, region: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const team = getState().admin.teamDetail.team;

    await apiClient.clearTrial(teamId, region);

    if (team !== undefined) {
      dispatch({
        type: "AdminGotTeamDetail",
        payload: {
          team: {
            ...team,
            plan: "free",
            quota: null,
            trialEndAt: null,
          },
        },
      });
    }
  };
}

export type AdminAction =
  | AdminGotTeamList
  | AdminGettingTeamList
  | AdminGotTeamDetail
  | AdminGettingTeamDetail
  | AdminGettingTeamMembers
  | AdminGotTeamMembers
  | AdminGettingTeamUsage
  | AdminGotTeamUsage
  | AdminGettingTeamForms
  | AdminGotTeamForms
  | AdminGettingTeamFormGroups
  | AdminGotTeamFormGroups
  | AdminGettingTeamCustomModels
  | AdminGotTeamCustomModels;

export function useAdminActionCreator() {
  return {
    listTeams: useThunkDispatch(listTeams),
    getTeam: useThunkDispatch(getTeam),
    getTeamMembers: useThunkDispatch(getTeamMembers),
    getTeamUsage: useThunkDispatch(getTeamUsage),
    listTeamForms: useThunkDispatch(listTeamForms),
    listTeamFormGroups: useThunkDispatch(listTeamFormGroups),
    listTeamCustomModels: useThunkDispatch(listTeamCustomModels),
    setTrial: useThunkDispatch(setTrial),
    clearTrial: useThunkDispatch(clearTrial),
  };
}
