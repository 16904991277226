@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 22px 12px 6px;
}

.sub-content {
  width: 100%;
  padding-right: 10px;
}

.tab-bar {
  width: 100%;
  height: 100%;
  padding-top: 8px;
  display: flex;
  flex-direction: column;

  > div > button {
    width: 92px;
  }

  > div:nth-child(2) {
    flex: 1 1 0;
    overflow-y: auto;
  }
}

.custom_extraction_items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;

  .custom_extraction_items_label {
    margin-right: 4px;
    font-size: 16px;
  }

  i {
    font-size: 14px;
  }
}

.custom_extraction_items_dropdown {
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  margin-top: 8px;
  margin-bottom: 20px;
  padding-right: 16px;
}

.separator {
  height: 1px;
  width: 100%;
  margin: 16px 0 16px;
  background-color: $separator-color-1;
}
