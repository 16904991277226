@import "../../styles/variables.scss";

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  margin-left: 30px;
  margin-top: 24px;
}

.subtitle {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #605e5c;
  margin-left: 30px;
  margin-top: 8px;
  display: inline-block;
}

.delete-button {
  float: right;
  margin-right: 40px;
}

.delete-button:not(:disabled) {
  :global .ms-Icon {
    color: $darker-primary-color;
  }
  color: $darker-primary-color;
}
.delete-button:hover {
  opacity: 0.7;
}

.delete-button:active {
  opacity: 0.5;
}

.grid {
  margin-left: 30px;
  margin-top: 36px;
}
