import * as React from "react";
import { useSelector } from "react-redux";

import { toggleLeftBarCollapsed } from "../actions/app";
import { RootState } from "../redux/types";
import { useThunkDispatch } from "./thunk";

export function useTextFieldChange(cb: (value: string) => void) {
  return React.useCallback(
    (
      _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      cb(value || "");
    },
    [cb]
  );
}

export function useLeftBar() {
  const isLeftBarCollapsed = useSelector(
    (state: RootState) => state.app.isLeftBarCollapsed
  );

  return {
    isCollapsed: isLeftBarCollapsed,
    toggleCollapsed: useThunkDispatch(toggleLeftBarCollapsed),
  };
}
