@import "../../styles/variables.scss";

.key-value-modal {
  :global .ms-Dialog-title {
    padding-bottom: 8px;
  }
}

.input-field {
  margin-bottom: 16px;

  :global(.ms-Dropdown.is-disabled) {
    :global(.ms-Dropdown-title) {
      color: transparent;
    }
  }
}

.modal-title-text {
  display: block;
  font-size: 18px;
  color: #201f1e;
  padding-top: 18px;
  padding-bottom: 3px;
}

.modal-desc-row {
  margin-bottom: 8px;
}

.modal-desc-text {
  font-size: 14px;
  color: #201f1e;

  &.see-example-button {
    $text-color: #0078d4;
    cursor: pointer;
    color: $text-color;

    &:hover {
      color: rgba($text-color, 0.7);
    }
  }
}

.values-title-row {
  margin: 25px 0px 5px 0px;
  display: flex;
  flex-direction: column;

  .values-title-text {
    font-size: 18px;
    color: #201f1e;
    font-weight: bold;
  }

  .values-error-text {
    font-size: 12px;
    color: #a4262c;
  }
}

.pattern-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .regex-input {
    margin-right: 10px;
    flex: 1 1 0px;
  }

  .build-button {
    margin-top: 30px;
  }

  .regex-builder-button {
    align-self: flex-start;
    margin-top: 30px;
  }
}

.regex-builder-button {
  $text-color: #0078d4;
  font-size: 14px;
  color: $text-color;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    color: rgba($text-color, 0.7);
  }
}

.key-section {
  .title-section {
    padding: 9px 4px;
    border-bottom: 1px solid #e9e9e9;
  }

  .title-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .title-text {
    font-weight: 700;
    font-size: 18px;
    color: $dark-color;
  }

  .table-header-text {
    font-weight: 600;
    font-size: 14px;
    color: $dark-color;
  }

  .key-column {
    flex: 1 1 0px;
  }

  .fuzzy-column {
    flex: 0 0 auto;
    width: 156px;
  }

  .table-header-cell {
    padding: 10px 4px;
  }

  .table-header-row {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-direction: row;
  }

  .token-row {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-direction: row;
  }

  .key-input {
    width: 193px;
    margin: 10px 0px;
  }

  .table-cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.fuzzy-cell {
      justify-content: space-between;
    }
  }

  .empty-row {
    display: block;
    text-align: center;
    padding: 10px;
    color: #201f1e;
    border-bottom: 1px solid #e9e9e9;
  }

  .fuzzy-toggle {
    margin: 0px;
  }

  .delete-button {
    font-size: 16px;
    padding: 15px 24px;
    cursor: pointer;

    &:hover {
      color: rgba(black, 0.5);
    }
  }

  .new-key-button {
    display: block;
    $text-color: #0078d4;
    cursor: pointer;
    font-size: 13px;
    color: $text-color;

    &:hover {
      color: rgba($text-color, 0.7);
    }
  }

  .regex-builder-button {
    align-self: flex-start;
    margin: 11px 0px;
  }

  .section-error {
    font-size: 12px;
    color: #a4262c;
  }
}
