import { DefaultButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { useCustomModelEditor } from "../../contexts/customModelEditor";
import styles from "./styles.module.scss";

const RECOMMMEND_IMAGE_COUNT = 10;

export default function CustomModelSampleUploadButton() {
  const { customModel, openUploadFileDialog } = useCustomModelEditor();

  const remainingCount =
    RECOMMMEND_IMAGE_COUNT - customModel.config.sampleImages.length;

  return (
    <>
      {remainingCount > 0 && (
        <>
          <div className={styles["arrow-body"]}>
            <FormattedMessage
              id="custom_model_editor.upload_files_button_tip"
              values={{ count: remainingCount }}
            />
          </div>
          <div className={styles["arrow-down"]} />
        </>
      )}

      <div className={styles["bottom"]}>
        <div className={styles["bottom-right"]}>
          <DefaultButton
            iconProps={{ iconName: "add" }}
            className={styles["upload-files-button"]}
            onClick={openUploadFileDialog}
          >
            <FormattedMessage id="custom_model_editor.upload_files_button" />
          </DefaultButton>
        </div>
      </div>
    </>
  );
}
