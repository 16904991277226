import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { useAdminActionCreator } from "../actions/admin";
import { exportForm } from "../actions/form";
import { exportFormGroup } from "../actions/formGroup";
import ConfirmModal from "../components/ConfirmModal";
import GiveTrialModal from "../components/GiveTrialModal";
import LoadingModal from "../components/LoadingModal";
import TeamDetailLayout from "../components/TeamDetail";
import { useFetchTeam } from "../hooks/admin";
import { useToast } from "../hooks/toast";
import { AdminPathParam } from "../models";
import { RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { TeamDetail } from "../types/team";

interface ReduxProps {
  team?: TeamDetail;
  isFetching: boolean;
}

function useRedux(): ReduxProps {
  const { team, isFetching } = useSelector((state: RootState) => {
    const { team, isFetching } = state.admin.teamDetail;

    return {
      team,
      isFetching,
    };
  });

  return {
    team,
    isFetching,
  };
}

function _AdminTeamDetailContainer() {
  const { teamId, region } = useParams<AdminPathParam>();
  const { team, isFetching } = useRedux();
  const [isGiveTrialModalOpened, setIsGiveTrialModalOpened] =
    React.useState<boolean>(false);
  const [isClearTrialModalOpened, setIsClearTrialModalOpened] =
    React.useState<boolean>(false);
  const { setTrial, clearTrial } = useAdminActionCreator();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const toast = useToast();

  const handleExportForm = React.useCallback(
    async (formId: string) => {
      await exportForm(formId, teamId, region);
    },
    [teamId, region]
  );

  const handleExportFormGroup = React.useCallback(
    async (formGroupId: string) => {
      await exportFormGroup(formGroupId, teamId, region);
    },
    [teamId, region]
  );

  const onGiveTrialCancelled = React.useCallback(() => {
    setIsGiveTrialModalOpened(false);
  }, []);

  const onGiveTrial = React.useCallback(
    async (quota?: number, endTrialAt?: Date) => {
      setIsSubmitting(true);
      try {
        await setTrial(teamId, region, quota, endTrialAt);
        setIsGiveTrialModalOpened(false);
        toast.success("team.detail.plan.set_trial.success");
      } catch (e) {
        console.error("Failed to set trial: ", e);
        toast.error("error.give_trial.cannot_set_trial");
      } finally {
        setIsSubmitting(false);
      }
    },
    [teamId, region, setTrial, toast]
  );

  const onClearTrial = React.useCallback(async () => {
    setIsSubmitting(true);
    try {
      await clearTrial(teamId, region);
      setIsClearTrialModalOpened(false);
      toast.success("team.detail.plan.clear_trial.success");
    } catch (e) {
      console.error("Failed to clear trial: ", e);
      toast.error("error.give_trial.cannot_clear_trial");
    } finally {
      setIsSubmitting(false);
    }
  }, [teamId, region, clearTrial, toast]);

  const presetClearTrialModel = React.useCallback(() => {
    setIsClearTrialModalOpened(true);
    setIsGiveTrialModalOpened(false);
  }, []);

  const presetGiveTrialModel = React.useCallback(() => {
    setIsGiveTrialModalOpened(true);
    setIsClearTrialModalOpened(false);
  }, []);

  const closeClearTrialModel = React.useCallback(() => {
    setIsClearTrialModalOpened(false);
  }, []);

  useFetchTeam(teamId, region);

  return (
    <>
      {team && (
        <TeamDetailLayout
          region={region}
          team={team}
          exportForm={handleExportForm}
          exportFormGroup={handleExportFormGroup}
          presetGiveTrialModel={presetGiveTrialModel}
          presetClearTrialModel={presetClearTrialModel}
        />
      )}
      <GiveTrialModal
        isOpen={isGiveTrialModalOpened}
        onCancel={onGiveTrialCancelled}
        onGiveTrial={onGiveTrial}
      />
      <ConfirmModal
        isOpen={isClearTrialModalOpened}
        modalType={ConfirmModalType.Destory}
        titleId="team.detail.plan.clear_trial"
        messageId="team.detail.plan.are_you_sure_to_clear_trial"
        actionId="team.detail.plan.clear"
        onCancel={closeClearTrialModel}
        onConfirm={onClearTrial}
      />
      <LoadingModal isOpen={isFetching || isSubmitting} />
    </>
  );
}

export const AdminTeamDetailContainer = React.memo(_AdminTeamDetailContainer);
export default AdminTeamDetailContainer;
