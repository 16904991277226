@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.form-grid {
  height: 100%;
  overflow-y: auto;
  background-color: #fff;

  .form-list {
    padding: 0 16px;
    height: 100%;
  }

  .empty-state-container {
    display: flex;
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    .empty-state {
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: row;

      @include no-select();

      i {
        font-size: 60px;
        margin-right: 12px;
      }

      .empty-state-text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        label {
          margin-top: 0;
          font-weight: 500;
        }
      }
    }
  }
}
