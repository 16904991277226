.form-non-saved-prompt-dialog {
  :global .ms-Dialog-main {
    min-height: 150px;
  }

  :global .ms-Dialog-subText {
    color: inherit;
  }

  .button-actions {
    display: flex;
    align-items: center;
  }

  .cancel-button {
    border: none;
    margin-left: -8px;
  }

  .cancel-button:hover {
    opacity: 0.7;
    color: inherit;
  }

  .cancel-button:active {
    opacity: 0.3;
  }

  .dont-save-button {
    margin-left: auto;
    margin-right: 10px;
  }
}
