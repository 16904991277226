@import "../../styles/variables.scss";

.container {
  height: 438px;
  width: 586px;
  margin-left: 12px;
  background: #f8f8f8;

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px 20px 0px 20px;
    display: flex;
    flex-direction: column;
  }

  .error {
    color: #a4262c;
    font-size: 12px;
    line-height: 16px;
    margin-top: 16px;
  }

  .field {
    font-size: 14px;
    line-height: 16px;
  }

  .name-field-row {
    display: flex;
  }

  .name-field {
    width: 138px;
    margin-top: 6px;
  }

  .name-text-field {
    max-width: 240px;
  }

  .tips {
    margin-top: 16px;
  }

  .header {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .header-bar {
    margin-top: 10px;
    display: flex;
  }

  .type-header {
    width: 142px;
  }

  .info-header {
    width: 248px;
  }

  .exact-match-header {
    width: 100px;
  }

  .divider1 {
    border-bottom: 1px solid #edebe9;
    margin-top: 16px;
    height: 1px;
  }

  .divider2 {
    border-bottom: 1px solid #edebe9;
    margin-top: 10px;
    height: 1px;
  }

  .match-mode-choice-group {
    :global .ms-ChoiceField {
      display: inline-block;
      margin-right: 60px;
      margin-top: 10px;
    }

    margin-top: 12px;
  }

  .list-container {
    flex-grow: 1;
    margin-top: 8px;
    position: relative;
  }

  .list {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    .row {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      :global .ms-Button-icon {
        color: #000000;
      }
    }

    .pattern-field {
      margin-right: 20px;
    }

    .type-field {
      margin-right: 20px;
    }

    .row > div:nth-child(1) {
      width: 138px;
    }

    .row > div:nth-child(2) {
      width: 252px;
    }

    .row > div:nth-child(3) {
      width: 90px;
    }

    .sticky {
      position: sticky; /* stylelint-disable-line */
      bottom: 0px;
      background: #f8f8f8;
    }
  }

  $add-new-info-button-color: #0078d4;

  .add-new-info-button {
    color: $add-new-info-button-color;
    margin-left: -10px;
  }

  .add-new-info-button:hover {
    color: darken($add-new-info-button-color, 30%);
  }

  .add-new-info-button:active {
    color: darken($add-new-info-button-color, 20%);
  }
}
