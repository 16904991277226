import classnames from "classnames";
import React, { useMemo } from "react";

import FreePlanUpgradeMessageBarContainer from "../../containers/FreePlanUpgradeMessageBar";
import LeftBar from "../../containers/LeftBar";
import { useLeftBar } from "../../hooks/component";
import styles from "./styles.module.scss";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = React.memo((props: LayoutProps) => {
  const { children } = props;

  return <div className={styles["layout"]}>{children}</div>;
});

export class Top extends React.PureComponent<any> {
  render() {
    return <div className={styles["top"]}>{this.props.children}</div>;
  }
}

export class Bottom extends React.PureComponent<any> {
  render() {
    return <div className={styles["bottom"]}>{this.props.children}</div>;
  }
}
interface RightProps {
  children: React.ReactNode;
  hasTop?: boolean;
  hasBottom?: boolean;
}

export class Right extends React.PureComponent<RightProps, unknown> {
  render() {
    const classes = [styles["right"]];

    if (this.props.hasTop) {
      classes.push(styles["has-top"]);
    }

    if (this.props.hasBottom) {
      classes.push(styles["has-bottom"]);
    }
    return <div className={classes.join(" ")}>{this.props.children}</div>;
  }
}

interface MainProps {
  children: React.ReactNode;
  hasTop?: boolean;
  hasRight?: boolean;
  hasBottom?: boolean;
  hasLeft?: boolean;
}

export const Main = React.memo(function Main(props: MainProps) {
  const { children, hasTop, hasRight, hasBottom } = props;
  const leftBar = useLeftBar();

  const hasLeft = props.hasLeft !== undefined ? props.hasLeft : true;

  const classes = useMemo(() => {
    const _classes = [styles["main"]];

    if (hasTop) {
      _classes.push(styles["has-top"]);
    }

    if (hasRight) {
      _classes.push(styles["has-right"]);
    }

    if (hasBottom) {
      _classes.push(styles["has-bottom"]);
    }

    return _classes;
  }, [hasTop, hasRight, hasBottom]);

  return (
    <div className={classes.join(" ")}>
      {hasLeft && <LeftBar />}
      <div
        className={classnames(styles["content"], {
          [styles["with-full-leftbar"]]: !leftBar.isCollapsed && hasLeft,
        })}
      >
        {children}
      </div>
      <FreePlanUpgradeMessageBarContainer />
    </div>
  );
});
