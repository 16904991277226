import { ActionButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { useCustomModelEditor } from "../../contexts/customModelEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import CustomModelEditorImageGridItem from "../CustomModelEditorImageGridItem";
import styles from "./styles.module.scss";

export default function CustomModelEditorImageGrid() {
  const {
    customModel,
    checkedImageSet,
    onCheckedImageMapChange,
    deleteImageEnabled,
    removeCheckedImages,
  } = useCustomModelEditor();
  const imageAssets = customModel.config.sampleImages;
  const { localized } = useLocale();
  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <>
      <div className={styles["title"]}>
        <FormattedMessage id="custom_model_editor.image_grid.title" />
      </div>

      <div className={styles["row"]}>
        <div className={styles["subtitle"]}>
          <FormattedMessage id="custom_model_editor.image_grid.subtitle" />
        </div>

        {hasPermissionToEditResource && (
          <ActionButton
            className={styles["delete-button"]}
            iconProps={{ iconName: "Delete" }}
            disabled={!deleteImageEnabled}
            onClick={removeCheckedImages}
          >
            {localized("custom_model_grid.delete_files_button")}
          </ActionButton>
        )}
      </div>

      <div className={styles["grid"]}>
        {imageAssets.map(image => (
          <CustomModelEditorImageGridItem
            name={image.filename}
            url={image.url ? image.url : ""}
            checked={checkedImageSet.has(image.assetId)}
            disabled={image.isOnCVAT}
            onCheckedChange={onCheckedImageMapChange(image.assetId)}
            date={image.updatedAt}
            contentType={image.contentType}
            key={image.assetId}
          />
        ))}
      </div>
    </>
  );
}
