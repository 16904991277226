import { Icon } from "@fluentui/react";
import * as React from "react";

import InputModal from "../../components/InputModal";
import LoadingModal from "../../components/LoadingModal";
import { PluginError } from "../../errors";
import { useReceiptGroups } from "../../hooks/receipt_groups";
import { useToast } from "../../hooks/toast";
import styles from "./styles.module.scss";

function validateReceiptGroupName(name: string) {
  return !name.trim() ? "error.create_receipt_group.empty_name" : undefined;
}

const CreateReceiptGroupButton = React.memo(() => {
  const { createReceiptGroup } = useReceiptGroups();

  const toast = useToast();

  const [isCreateReceiptGroupModalOpened, setIsCreateReceiptGroupModalOpened] =
    React.useState(false);
  const [isCreatingReceiptGroup, setIsCreatingReceiptGroup] =
    React.useState(false);

  const openCreateReceiptGroupModal = React.useCallback(() => {
    setIsCreateReceiptGroupModalOpened(true);
  }, []);

  const closeCreateReceiptGroupModal = React.useCallback(() => {
    setIsCreateReceiptGroupModalOpened(false);
  }, []);

  const doCreateReceiptGroup = React.useCallback(
    async (receiptGroupName: string) => {
      closeCreateReceiptGroupModal();
      setIsCreatingReceiptGroup(true);
      try {
        await createReceiptGroup(receiptGroupName);
      } catch (e) {
        if ((e as any).code === PluginError.ReceiptGroupReachedLimit) {
          toast.error("error.number_of_receipt_group_reached_limit");
        } else {
          toast.error("error.fail_to_create_receipt_group");
        }
      } finally {
        setIsCreatingReceiptGroup(false);
      }
    },
    [createReceiptGroup, closeCreateReceiptGroupModal, toast]
  );

  return (
    <>
      <Icon
        className={styles["create-receipt-group-button"]}
        onClick={openCreateReceiptGroupModal}
        iconName="CircleAddition"
      />
      <InputModal
        titleId="create_receipt_group.title"
        textFieldLabelId="create_receipt_group.receipt_group_name"
        buttonLabelId="create_receipt_group.create"
        inputValidatorWithMessage={validateReceiptGroupName}
        isOpen={isCreateReceiptGroupModalOpened}
        onCancel={closeCreateReceiptGroupModal}
        onSubmit={doCreateReceiptGroup}
      />
      <LoadingModal isOpen={isCreatingReceiptGroup} />
    </>
  );
});

export default CreateReceiptGroupButton;
