import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { CreateRenameTeamForm } from "../CreateTeamModal";
import styles from "./styles.module.scss";

interface Props {
  onCreateTeam: (
    name: string,
    region: string,
    lookupId: string
  ) => Promise<void>;
}

export function OnboardingImpl(props: Props) {
  const { onCreateTeam } = props;

  return (
    <div className={styles["input-container"]}>
      <div className={styles["input-section"]}>
        <h3>
          <FormattedMessage id="onboarding.welcome_message" />
        </h3>

        <CreateRenameTeamForm isRename={false} onConfirm={onCreateTeam} />
      </div>
    </div>
  );
}
