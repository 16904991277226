import { ICommandBarItemProps } from "@fluentui/react";
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useReceiptGroupActionCreator } from "../actions/receiptGroup";
import ConfirmModal from "../components/ConfirmModal";
import { CustomFieldModal } from "../components/CustomFieldKeyValueModal";
import InputModal from "../components/InputModal";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import ReceiptFormEditor from "../components/ReceiptFormEditor";
import ReceiptGroupEditor from "../components/ReceiptGroupEditor";
import { ReceiptNavBarLayout } from "../components/ReceiptNavBar";
import HeaderContainer from "../containers/Header";
import { useLocale } from "../contexts/locale";
import errors from "../errors";
import { useTeamPermission } from "../hooks/permission";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { BriefFormWithType } from "../types/form";
import { CustomField, ReceiptGroupWithType } from "../types/receiptGroup";

function validateReceiptGroupName(name: string) {
  return !name.trim() ? "error.rename_receipt_group.empty_name" : undefined;
}

function validateTokenName(name: string) {
  return !name.trim() ? "error.add_token.invalid" : undefined;
}

function getLoadingLabel(
  isAddingToken: boolean,
  isRenamingReceiptGroup: boolean
) {
  if (isAddingToken) {
    return "receipt_group_list.renaming_receipt_group";
  } else if (isRenamingReceiptGroup) {
    return "receipt_group_list.adding_token";
  } else {
    return "";
  }
}

type CurrentReceiptGroup = ReceiptGroupWithType | BriefFormWithType | undefined;

function useRenameReceiptGroupModal(
  receiptGroupRef: React.MutableRefObject<CurrentReceiptGroup>
) {
  const [isRenameReceiptGroupModalOpened, setIsRenameReceiptGroupModalOpened] =
    useState(false);
  const [isRenamingReceiptGroup, setIsRenamingReceiptGroup] = useState(false);

  const { renameReceiptGroup, updateReceiptForm } =
    useReceiptGroupActionCreator();

  const toast = useToast();

  const closeRenameReceiptGroupModal = useCallback(() => {
    setIsRenameReceiptGroupModalOpened(false);
  }, []);

  const onRenameReceiptGroup = useCallback(() => {
    setIsRenameReceiptGroupModalOpened(true);
  }, []);

  const doRenameReceiptGroup = useCallback(
    async (receiptGroupName: string) => {
      if (!receiptGroupRef.current) return;

      closeRenameReceiptGroupModal();
      setIsRenamingReceiptGroup(true);

      try {
        switch (receiptGroupRef.current.type) {
          case "receipt_group":
            await renameReceiptGroup(
              receiptGroupRef.current.id,
              receiptGroupName
            );
            break;
          case "form":
            await updateReceiptForm(receiptGroupRef.current.id, {
              ...receiptGroupRef.current,
              name: receiptGroupName,
            });
            break;
          default:
            throw new Error(`Unknown receipt group type`);
        }
      } catch (e) {
        if (e !== errors.ConflictFound) {
          toast.error("error.fail_to_rename_receipt_group");
        }
      } finally {
        setIsRenamingReceiptGroup(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      closeRenameReceiptGroupModal,
      setIsRenamingReceiptGroup,
      renameReceiptGroup,
      updateReceiptForm,
      toast,
    ]
  );

  return React.useMemo(
    () => ({
      isRenameReceiptGroupModalOpened,
      isRenamingReceiptGroup,
      closeRenameReceiptGroupModal,
      doRenameReceiptGroup,
      onRenameReceiptGroup,
    }),
    [
      isRenameReceiptGroupModalOpened,
      isRenamingReceiptGroup,
      closeRenameReceiptGroupModal,
      doRenameReceiptGroup,
      onRenameReceiptGroup,
    ]
  );
}

function useEditToken(
  receiptGroupRef: React.MutableRefObject<CurrentReceiptGroup>
) {
  const [isAddTokenModalOpened, setIsAddTokenModalOpened] = useState(false);
  const [isAddingToken, setIsAddingToken] = useState(false);

  const { addToken, removeToken } = useReceiptGroupActionCreator();
  const toast = useToast();

  const closeAddTokenModal = useCallback(() => {
    setIsAddTokenModalOpened(false);
  }, []);

  const onAddToken = useCallback(() => {
    setIsAddTokenModalOpened(true);
  }, []);

  const doAddToken = useCallback(
    (tokenName: string) => {
      if (!receiptGroupRef.current) return;
      const receiptGroupId = receiptGroupRef.current.id;

      closeAddTokenModal();
      setIsAddingToken(true);

      addToken(receiptGroupId, tokenName)
        .then(() => {
          setIsAddingToken(false);
        })
        .catch(() => {
          setIsAddingToken(false);
          toast.error("error.fail_to_add_token");
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeAddTokenModal, toast, addToken]
  );

  const onDeleteToken = useCallback(
    (tokenValue: string) => {
      if (!receiptGroupRef.current) return;
      removeToken(receiptGroupRef.current.id, tokenValue).catch(() => {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeToken]
  );

  return React.useMemo(
    () => ({
      isAddTokenModalOpened,
      isAddingToken,
      closeAddTokenModal,
      onAddToken,
      doAddToken,
      onDeleteToken,
    }),
    [
      isAddTokenModalOpened,
      isAddingToken,
      closeAddTokenModal,
      onAddToken,
      doAddToken,
      onDeleteToken,
    ]
  );
}

function useDeleteReceiptGroupModal(
  receiptGroupRef: React.MutableRefObject<CurrentReceiptGroup>
) {
  const [
    isDeleteReceiptGroupConfirmModalOpened,
    setIsDeleteReceiptGroupConfirmModalOpened,
  ] = useState(false);

  const { removeReceiptGroup, removeReceiptForm } =
    useReceiptGroupActionCreator();
  const { localized } = useLocale();
  const { hasPermissionToRemoveResource } = useTeamPermission();
  const toast = useToast();

  const openDeleteReceiptGroupModal = useCallback(() => {
    setIsDeleteReceiptGroupConfirmModalOpened(true);
  }, []);

  const closeDeleteReceiptGroupModal = useCallback(() => {
    setIsDeleteReceiptGroupConfirmModalOpened(false);
  }, []);

  const commbarBarItems = useCallback(
    (): ICommandBarItemProps[] =>
      hasPermissionToRemoveResource
        ? [
            {
              key: "delete-receipt-group",
              text: localized("receipt_edit.delete"),
              iconProps: { iconName: "Delete" },
              onClick: openDeleteReceiptGroupModal,
            },
          ]
        : [],
    [localized, openDeleteReceiptGroupModal, hasPermissionToRemoveResource]
  );

  const onConfirmDelete = useCallback(
    async () => {
      if (!receiptGroupRef.current) return;

      closeDeleteReceiptGroupModal();

      try {
        switch (receiptGroupRef.current.type) {
          case "receipt_group":
            await removeReceiptGroup(receiptGroupRef.current.id);
            break;
          case "form":
            await removeReceiptForm(receiptGroupRef.current.id);
            break;
          default:
            throw new Error(`Unknown receipt group type`);
        }
      } catch {
        toast.error("error.error_during_remove_receipt_group");
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeDeleteReceiptGroupModal, removeReceiptGroup, removeReceiptForm, toast]
  );

  return React.useMemo(
    () => ({
      isDeleteReceiptGroupConfirmModalOpened,
      closeDeleteReceiptGroupModal,
      onConfirmDelete,
      commbarBarItems,
    }),
    [
      isDeleteReceiptGroupConfirmModalOpened,
      closeDeleteReceiptGroupModal,
      onConfirmDelete,
      commbarBarItems,
    ]
  );
}

function useCustomFieldModal(
  receiptGroup: CurrentReceiptGroup,
  receiptGroupRef: React.MutableRefObject<CurrentReceiptGroup>
) {
  const [isCustomFieldModalOpened, setIsCustomFieldModalOpened] =
    useState(false);

  const [customFieldModalMode, setCustomFieldModalMode] = useState<
    "create" | "edit"
  >("create");

  const [editingCustomField, setEditingCustomField] = useState<
    CustomField | undefined
  >(undefined);

  const toast = useToast();

  const existingFieldNames = React.useMemo(
    () =>
      receiptGroup && receiptGroup.type === "receipt_group"
        ? receiptGroup.custom_fields.map(x => x.name)
        : [],
    [receiptGroup]
  );

  const {
    addCustomField,
    removeCustomField,
    getReceiptForm,
    updateReceiptForm,
  } = useReceiptGroupActionCreator();

  const onAddCustomField = useCallback(() => {
    setIsCustomFieldModalOpened(true);
    setCustomFieldModalMode("create");
  }, []);

  const onEditCustomField = useCallback((customField: CustomField) => {
    setIsCustomFieldModalOpened(true);
    setEditingCustomField(customField);
    setCustomFieldModalMode("edit");
  }, []);

  const closeCustomFieldModal = useCallback(() => {
    setIsCustomFieldModalOpened(false);
    setEditingCustomField(undefined);
  }, []);

  const doAddCustomField = useCallback(
    (customField: CustomField, originalFieldName?: string) => {
      if (!receiptGroupRef.current) {
        return;
      }
      const receiptGroupId = receiptGroupRef.current.id;

      addCustomField(receiptGroupId, { ...customField }, originalFieldName)
        .then(() => {
          closeCustomFieldModal();
        })
        .catch(() => {
          closeCustomFieldModal();
          toast.error("error.fail_to_add_custom_field");
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addCustomField, closeCustomFieldModal, toast]
  );

  const onDeleteCustomField = useCallback(
    (fieldName: string) => {
      if (!receiptGroupRef.current) {
        return;
      }
      removeCustomField(receiptGroupRef.current.id, fieldName).catch(() => {
        toast.error("error.fail_to_remove_custom_field");
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast, removeCustomField]
  );

  return React.useMemo(
    () => ({
      customFieldModalMode,
      isCustomFieldModalOpened,
      existingFieldNames,
      editingCustomField,
      onAddCustomField,
      onEditCustomField,
      onDeleteCustomField,
      getReceiptForm,
      updateReceiptForm,
      closeCustomFieldModal,
      doAddCustomField,
    }),
    [
      customFieldModalMode,
      isCustomFieldModalOpened,
      existingFieldNames,
      editingCustomField,
      onAddCustomField,
      onEditCustomField,
      onDeleteCustomField,
      getReceiptForm,
      updateReceiptForm,
      closeCustomFieldModal,
      doAddCustomField,
    ]
  );
}

function _ReceiptGroupEditContainer() {
  const receiptGroup = useSelector<RootState, CurrentReceiptGroup>(
    state => state.receiptGroup.currentReceiptGroup
  );

  const receiptGroupRef = useRef(receiptGroup);
  receiptGroupRef.current = receiptGroup;

  const {
    isRenameReceiptGroupModalOpened,
    isRenamingReceiptGroup,
    closeRenameReceiptGroupModal,
    doRenameReceiptGroup,
    onRenameReceiptGroup,
  } = useRenameReceiptGroupModal(receiptGroupRef);

  const {
    isAddTokenModalOpened,
    isAddingToken,
    closeAddTokenModal,
    onAddToken,
    doAddToken,
    onDeleteToken,
  } = useEditToken(receiptGroupRef);

  const {
    isDeleteReceiptGroupConfirmModalOpened,
    closeDeleteReceiptGroupModal,
    onConfirmDelete,
    commbarBarItems,
  } = useDeleteReceiptGroupModal(receiptGroupRef);

  const {
    customFieldModalMode,
    isCustomFieldModalOpened,
    existingFieldNames,
    editingCustomField,
    onAddCustomField,
    onEditCustomField,
    onDeleteCustomField,
    getReceiptForm,
    updateReceiptForm,
    closeCustomFieldModal,
    doAddCustomField,
  } = useCustomFieldModal(receiptGroup, receiptGroupRef);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <ReceiptNavBarLayout commbarBarItems={commbarBarItems()}>
          {receiptGroup && receiptGroup.type === "receipt_group" && (
            <>
              <InputModal
                titleId="add_token.title"
                textFieldLabelId="label.token_value"
                buttonLabelId="add_token.add"
                inputValidatorWithMessage={validateTokenName}
                isOpen={isAddTokenModalOpened}
                onCancel={closeAddTokenModal}
                onSubmit={doAddToken}
              />
              <CustomFieldModal
                mode={customFieldModalMode}
                isOpen={isCustomFieldModalOpened}
                existingFieldNames={existingFieldNames}
                customField={editingCustomField}
                onCancel={closeCustomFieldModal}
                onSubmit={doAddCustomField}
              />
              <ReceiptGroupEditor
                receiptGroup={receiptGroup}
                onRenameReceiptGroup={onRenameReceiptGroup}
                onAddToken={onAddToken}
                onDeleteToken={onDeleteToken}
                onAddCustomField={onAddCustomField}
                onEditCustomField={onEditCustomField}
                onDeleteCustomField={onDeleteCustomField}
              />
            </>
          )}
          {receiptGroup && receiptGroup.type === "form" && (
            <ReceiptFormEditor
              receiptForm={receiptGroup}
              onRenameReceiptForm={onRenameReceiptGroup}
              getReceiptForm={getReceiptForm}
              updateReceiptForm={updateReceiptForm}
            />
          )}
        </ReceiptNavBarLayout>
      </Main>
      <InputModal
        titleId="rename_receipt_group.title"
        textFieldLabelId="rename_receipt_group.receipt_group_name"
        buttonLabelId="rename_receipt_group.rename"
        inputValidatorWithMessage={validateReceiptGroupName}
        isOpen={isRenameReceiptGroupModalOpened}
        onCancel={closeRenameReceiptGroupModal}
        onSubmit={doRenameReceiptGroup}
        defaultValue={receiptGroup ? receiptGroup.name : ""}
      />
      <ConfirmModal
        isOpen={isDeleteReceiptGroupConfirmModalOpened}
        modalType={ConfirmModalType.Destory}
        titleId="receipt_group_list.delete_receipt_group"
        messageId="receipt_group_list.are_you_sure_to_delete_receipt_group"
        actionId="receipt_group_list.delete"
        onCancel={closeDeleteReceiptGroupModal}
        onConfirm={onConfirmDelete}
      />
      <LoadingModal
        isOpen={isAddingToken || isRenamingReceiptGroup}
        messageId={getLoadingLabel(isAddingToken, isRenamingReceiptGroup)}
      />
    </Layout>
  );
}

export const ReceiptGroupEditContainer = React.memo(_ReceiptGroupEditContainer);
export default ReceiptGroupEditContainer;
