import { DatePicker } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { useLocale } from "../../contexts/locale";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface Props {
  onDateRangeChange: (dateFrom: Date, dateTo: Date) => void;
  onDateRangeSearch: () => void;
  dateFrom: Date;
  dateTo: Date;
  minDate: Date;
  showTitle?: boolean;
}

function _UsageDateRangeSelector(props: Props) {
  const { dateFrom, dateTo, minDate, showTitle } = props;

  const onDateFromChange = useCallback(
    (date?: Date | null) => {
      if (date) props.onDateRangeChange(date, props.dateTo);
    },
    [props]
  );

  const onDateToChange = useCallback(
    (date?: Date | null) => {
      if (date) props.onDateRangeChange(props.dateFrom, date);
    },
    [props]
  );

  const formatDate = useCallback((date?: Date) => {
    return date?.toLocaleDateString("en-GB") ?? "";
  }, []);

  const { localized } = useLocale();

  return (
    <div className={styles["usage-date-range-selector"]}>
      {showTitle && (
        <h1>
          <FormattedMessage id={"usage.usage_view.title"} />
        </h1>
      )}
      <div className={styles["date-picker-row"]}>
        <DatePicker
          label={localized("usage.date_range_selector.from")}
          onSelectDate={onDateFromChange}
          value={dateFrom}
          className={styles["date-picker"]}
          minDate={minDate}
          maxDate={dateTo as any}
          formatDate={formatDate}
        />
        <DatePicker
          label={localized("usage.date_range_selector.to")}
          onSelectDate={onDateToChange}
          value={dateTo}
          className={styles["date-picker"]}
          minDate={dateFrom as any}
          maxDate={new Date()}
          formatDate={formatDate}
        />
        <DefaultButton
          className={styles["search-button"]}
          textId="usage.date_range_selector.search"
          onClick={props.onDateRangeSearch}
        />
      </div>
    </div>
  );
}

export const UsageDateRangeSelector = React.memo(_UsageDateRangeSelector);
export default UsageDateRangeSelector;
