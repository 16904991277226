import { Icon, Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import styles from "./styles.module.scss";

interface Props {
  messageId: string;
}

const NoResultPlaceholder = React.memo((props: Props) => {
  const { messageId } = props;
  return (
    <div className={styles["no-result-placeholder"]}>
      <Icon iconName="Error" />
      <Label>
        <FormattedMessage id={messageId} />
      </Label>
    </div>
  );
});

export default NoResultPlaceholder;
