@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

$top-height: 40px;
$bottom-height: 64px;
$button-color: #ffd633;
$button-width: 252px;
$border-color: #c5c5c6;

.container {
  position: absolute;
  top: $top-height;
  width: 100%;
  height: calc(100vh - #{$top-height});
  max-height: calc(100vh - #{$top-height});
  @include ms-depth-8;
}

.content {
  width: 100%;
  overflow-y: auto;
  padding: 20px 24px 20px 24px;
  height: calc(100vh - #{$top-height} - #{$bottom-height});

  > :global(div.ms-Pivot--tabs) {
    > button {
      font-size: 14px;
    }

    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 22px;
    border-bottom: 1px solid $border-color;
  }

  .desc {
    font-size: 14px;
    margin-top: 12px;
    line-height: 20px;
  }

  .subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .text-field {
    max-width: $button-width;
    width: $button-width;
    margin-bottom: 12px;
    margin-right: 8px;

    ::placeholder {
      font-size: 12px;
    }
  }

  .label-field {
    display: flex;

    button {
      color: #201f1e;
    }
  }

  .frozen-field {
    background-color: #edebe9;
    width: $button-width;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    height: 32px;
    margin-bottom: 12px;
    padding: 6px 8px;
  }

  .add-new-info-button {
    width: $button-width;
    margin-bottom: 12px;
    :global(span.ms-Button-textContainer) {
      flex-grow: 0;
    }
  }
}

$bottom-side-margin: 28px;
.bottom {
  width: calc(100% - (#{$bottom-side-margin} * 2));
  height: $bottom-height;
  max-height: $bottom-height;

  border-top: 1px solid $border-color;
  margin: 0 $bottom-side-margin;

  .next-step-button {
    float: right;
    margin-top: 10px;
  }

  .next-step-button:not(:disabled) {
    color: #000000;
    background-color: $button-color;
    border-color: $button-color;
    font-weight: 600;
  }

  .next-step-button:hover {
    background-color: darken($button-color, 30%);
    border-color: darken($button-color, 30%);
  }

  .next-step-button:active {
    background-color: darken($button-color, 20%);
    border-color: darken($button-color, 20%);
  }
}
