import { apiClient } from "../apiClient";
import { useThunkDispatch } from "../hooks/thunk";
import { Dispatch, RootState } from "../redux/types";
import { Webhook } from "../types/webhook";
import { handleConflict } from "./app";
import { selectTeam } from "./resourceOwner";

export interface StartedFetchingWebhookListAction {
  readonly type: "StartedFetchingWebhookList";
}

export interface GotWebhookListAction {
  readonly type: "GotWebhookList";
  readonly payload: {
    webhooks: Webhook[];
  };
}

export interface WebhookCreatedAction {
  readonly type: "WebhookCreated";
  readonly payload: {
    webhook: Webhook;
  };
}

export interface GotWebhookAction {
  readonly type: "GotWebhook";
  readonly payload: {
    webhook: Webhook;
  };
}

export interface WebhookUpdatedAction {
  readonly type: "WebhookUpdated";
  readonly payload: {
    webhook: Webhook;
  };
}

export interface WebhookRemovedAction {
  readonly type: "WebhookRemoved";
  readonly payload: {
    webhookId: string;
  };
}

export function listWebhooks() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const state = getState();
    const { resourceOwnerId } = state.resourceOwner;

    if (!resourceOwnerId) {
      return;
    }

    dispatch({ type: "StartedFetchingWebhookList" });

    const listResult = await apiClient.listWebhooks(resourceOwnerId);

    dispatch({
      type: "GotWebhookList",
      payload: {
        webhooks: listResult.webhooks,
      },
    });

    return Promise.resolve();
  };
}

export function createWebhook(
  name: string,
  url: string,
  formIds: string[],
  formGroupIds: string[]
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<Webhook> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const webhook = await apiClient.createWebhook(
      name,
      url,
      formIds,
      formGroupIds,
      resourceOwnerId
    );

    dispatch({
      type: "WebhookCreated",
      payload: {
        webhook,
      },
    });

    return webhook;
  };
}

export function getWebhook(webhookId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const webhook = await apiClient.getWebhook(webhookId, resourceOwnerId);

    selectTeam(webhook.resourceOwnerId)(dispatch, getState);

    dispatch({
      type: "GotWebhook",
      payload: {
        webhook,
      },
    });
  };
}

export function updateWebhook(
  webhookId: string,
  name: string,
  url: string,
  formIds: string[],
  formGroupIds: string[],
  retrievedAt: string
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<Webhook> => {
    const { resourceOwnerId } = getState().resourceOwner;

    const webhook = await handleConflict(
      () =>
        apiClient.updateWebhook(
          webhookId,
          name,
          url,
          formIds,
          formGroupIds,
          retrievedAt,
          resourceOwnerId
        ),
      () =>
        apiClient.updateWebhook(
          webhookId,
          name,
          url,
          formIds,
          formGroupIds,
          undefined,
          resourceOwnerId
        ),
      {
        titleId: "webhook.modified_prompt.title",
        messageId: "webhook.modified_prompt.desc",
        actionId: "common.save_and_overwrite",
      }
    )(dispatch, getState);

    dispatch({
      type: "WebhookCreated",
      payload: {
        webhook,
      },
    });

    return webhook;
  };
}

export function removeWebhook(webhookId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    await apiClient.deleteWebhook(webhookId, resourceOwnerId);

    dispatch({
      type: "WebhookRemoved",
      payload: {
        webhookId,
      },
    });
  };
}

export type WebhookAction =
  | WebhookCreatedAction
  | GotWebhookListAction
  | GotWebhookAction
  | WebhookUpdatedAction
  | WebhookRemovedAction
  | StartedFetchingWebhookListAction;

export function useWebhookActionCreator() {
  return {
    list: useThunkDispatch(listWebhooks),
    create: useThunkDispatch(createWebhook),
    get: useThunkDispatch(getWebhook),
    update: useThunkDispatch(updateWebhook),
    remove: useThunkDispatch(removeWebhook),
  };
}
