import { Checkbox, Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import * as React from "react";

import { BriefForm } from "../../types/form";
import { BriefFormGroup } from "../../types/formGroup";
import styles from "./styles.module.scss";

interface WebhookConnectFormFieldProps {
  forms: BriefForm[];
  formGroups: BriefFormGroup[];
  connectWithFormIds: string[];
  connectWithFormGroupIds: string[];
  onConnectFormChange: (
    formId: string
  ) => (
    ev?: React.FormEvent<unknown> | undefined,
    checked?: boolean | undefined
  ) => void;
  onConnectFormGroupChange: (
    formGroupId: string
  ) => (
    ev?: React.FormEvent<unknown> | undefined,
    checked?: boolean | undefined
  ) => void;
  connectWithErrorMessage?: string;
}

export const WebhookConnectFormField = React.memo(
  (props: WebhookConnectFormFieldProps) => {
    const {
      forms,
      formGroups,
      connectWithFormIds,
      connectWithFormGroupIds,
      onConnectFormChange,
      onConnectFormGroupChange,
      connectWithErrorMessage,
    } = props;

    return (
      <>
        <div
          className={classNames(
            styles["connect-container"],
            connectWithErrorMessage && styles["error"]
          )}
        >
          {forms.length > 0 && (
            <Label className={styles["connect-list-header"]}>
              <FormattedMessage id="webhook.connect_with.form" />
            </Label>
          )}
          {forms.map(form => (
            <div className={styles["row"]} key={form.id}>
              <Checkbox
                className={styles["check-box"]}
                label={form.name}
                checked={connectWithFormIds.includes(form.id)}
                onChange={onConnectFormChange(form.id)}
              />
            </div>
          ))}
          {formGroups.length > 0 && (
            <Label className={styles["connect-list-header"]}>
              <FormattedMessage id="webhook.connect_with.form_group" />
            </Label>
          )}
          {formGroups.map(formGroup => (
            <div className={styles["row"]} key={formGroup.id}>
              <Checkbox
                className={styles["check-box"]}
                label={formGroup.name}
                checked={connectWithFormGroupIds.includes(formGroup.id)}
                onChange={onConnectFormGroupChange(formGroup.id)}
              />
            </div>
          ))}
        </div>
        {connectWithErrorMessage && (
          <span className={styles["error-message"]}>
            <FormattedMessage id={connectWithErrorMessage} />
          </span>
        )}
      </>
    );
  }
);

export default WebhookConnectFormFieldProps;
