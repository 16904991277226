import { IconButton, PrimaryButton, TextField } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { useCustomModelEditor } from "../../contexts/customModelEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import InfoNote from "../InfoNote";
import styles from "./styles.module.scss";

interface InfoFieldProps {
  value: string;
  onChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
  onRemove: () => void;
  onCommit: () => void;
  removeVisible: boolean;
}

function InfoField(props: InfoFieldProps) {
  const { value, onChange, onRemove, onCommit, removeVisible } = props;
  const { localized } = useLocale();
  const { hasPermissionToEditResource } = useTeamPermission();

  const onBlur = React.useCallback(() => {
    if (value.length > 0) {
      onCommit();
    }
  }, [value, onCommit]);

  const onKeyPress = React.useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Enter" && event.target instanceof HTMLElement) {
      event.target.blur();
    }
  }, []);

  const { isRequestCustomModelTrainingError } = useCustomModelEditor();

  const errorMessage =
    isRequestCustomModelTrainingError && value.trim() === ""
      ? localized("custom_model_editor.right_bar.text_field.error")
      : undefined;

  return (
    <div className={styles["info-field"]}>
      <TextField
        className={styles["text-field"]}
        placeholder={localized(
          "custom_model_editor.right_bar.text_field.placeholder"
        )}
        errorMessage={errorMessage}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        disabled={!hasPermissionToEditResource}
      />
      {removeVisible && (
        <IconButton iconProps={{ iconName: "Delete" }} onClick={onRemove} />
      )}
    </div>
  );
}

export default function CustomModelEditorRightBar() {
  const { localized } = useLocale();
  const {
    customModel,
    appendInfoField,
    onInfoFieldChange,
    onInfoFieldRemove,
    onInfoFieldChangeCommit,
    initialTrainingRequested,
    updateTrainingRequestEnabled,
    requestCustomModelTraining,
  } = useCustomModelEditor();

  const buttonLabel = initialTrainingRequested
    ? "custom_model_editor.right_bar.update_training_button"
    : "custom_model_editor.right_bar.request_training_button";

  const { hasPermissionToEditResource } = useTeamPermission();

  const removeVisible =
    customModel.config.fields.length > 1 && hasPermissionToEditResource;

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <div className={styles["title"]}>
          <FormattedMessage id="custom_model_editor.right_bar.title" />
        </div>
        <div className={styles["desc"]}>
          <InfoNote notes={["custom_model_editor.right_bar.desc"]} />
        </div>
        <div className={styles["subtitle"]}>
          <FormattedMessage id="custom_model_editor.right_bar.subtitle" />
        </div>

        {hasPermissionToEditResource && (
          <PrimaryButton
            iconProps={{ iconName: "add" }}
            className={styles["add-new-info-button"]}
            onClick={appendInfoField}
          >
            {localized("custom_model_editor.right_bar.add_new_info_button")}
          </PrimaryButton>
        )}

        {customModel.config.fields.map((value, index) => (
          <InfoField
            value={value}
            key={`info-field-${index}`}
            onChange={onInfoFieldChange(index)}
            onRemove={onInfoFieldRemove(index)}
            onCommit={onInfoFieldChangeCommit}
            removeVisible={removeVisible}
          />
        ))}
      </div>
      {hasPermissionToEditResource && (
        <div className={styles["bottom"]}>
          <PrimaryButton
            className={styles["create-model-button"]}
            onClick={requestCustomModelTraining}
            disabled={!updateTrainingRequestEnabled}
          >
            {localized(buttonLabel)}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}
