import { Dropdown, IDropdownOption, Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useSelector } from "react-redux";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { RootState } from "../../redux/types";
import { FormExtractionMode } from "../../types/form";
import { FileDefaultButton } from "../FileButton";
import styles from "./styles.module.scss";

interface Props {
  onSelectImage: (file?: File) => void;
  extractionMode?: FormExtractionMode;
  onExtractionModeChanged?: (extractionMode: FormExtractionMode) => void;
}

export const OCRTestPlaceholder: React.FC<Props> = (props: Props) => {
  const { onSelectImage, extractionMode, onExtractionModeChanged } = props;

  const { localized } = useLocale();

  const onFiles = React.useCallback(
    (files?: File[]) => onSelectImage(files && files[0]),
    [onSelectImage]
  );

  const { form } = useSelector((state: RootState) => {
    const { currentForm, currentFormImageSize } = state.form;

    return {
      form: currentForm,
      imageSize: currentFormImageSize,
    };
  });

  const showMultiDocument =
    onExtractionModeChanged && (form && form.documentType) !== "bank_statement";

  const onMultiDocumentDropdownChanged = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option?.key && onExtractionModeChanged)
        onExtractionModeChanged(option.key as FormExtractionMode);
    },
    [onExtractionModeChanged]
  );

  return (
    <div className={styles["placeholder"]}>
      <div>
        <div className={styles["upload-icon"]}>
          <Icon iconName="TestBeakerSolid" />
        </div>

        <div className={styles["instructions"]}>
          <p>
            <FormattedMessage id="ocr_test.no_image" />
          </p>
          {showMultiDocument && (
            <>
              <Dropdown
                className={styles["multidoc-dropdown"]}
                options={Object.values(FormExtractionMode).map(key => ({
                  key: key,
                  text: localized(`form.extraction_mode.${key}`),
                }))}
                selectedKey={extractionMode}
                onChange={onMultiDocumentDropdownChanged}
              />
            </>
          )}
          <FileDefaultButton
            textId={"common.choose"}
            onFiles={onFiles}
            accept={SUPPORTED_EXTRACT_MIME.join(",")}
          />
        </div>
      </div>
    </div>
  );
};
