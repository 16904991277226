import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import FormInspectorV2 from "../components/FormInspectorV2";
import { FormNavBarLayout } from "../components/FormNavBar";
import FormNotSavedPrompt from "../components/FormNotSavedPrompt";
import Labeller from "../components/Labeller";
import { Layout, Main, Right, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import NoFormImagePlaceholder from "../components/NoFormImagePlaceholder";
import HeaderContainer from "../containers/Header";
import { FormEditorProvider, useFormEditor } from "../contexts/formEditor";
import { useTutorial } from "../contexts/tutorial";
import { FormEditorTab, PathParam } from "../models";

function useSetUpForm(formId: string) {
  const {
    form,
    get: getForm,
    getFormImageSize,
    imageSize,
    setIsFailedToFetchForm,
  } = useFormEditor();

  useEffect(() => {
    if (!form || form.id !== formId) {
      getForm(formId)
        .then(() => {
          getFormImageSize();
        })
        .catch(() => {
          setIsFailedToFetchForm(true);
        });
    } else if (!imageSize) {
      getFormImageSize();
    }
  }, [
    form,
    getForm,
    getFormImageSize,
    imageSize,
    setIsFailedToFetchForm,
    formId,
  ]);
}

function useFormInspectorTabSelection() {
  const { selectedTab, setSelectedTab } = useFormEditor();

  const onSelectFormInspectorTab = useCallback(
    tabClicked => {
      setSelectedTab(tabClicked);
    },
    [setSelectedTab]
  );

  return { selectedTab, setSelectedTab, onSelectFormInspectorTab };
}

function useAutoSelectFormInspectorTab({
  formId,
  setSelectedTab,
}: {
  formId: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<FormEditorTab>>;
}) {
  const { form } = useFormEditor();

  const [previousSelectedFormId, setPreviousSelectedFormId] =
    useState<string>("");

  useEffect(() => {
    if (form && form.id === formId && previousSelectedFormId !== formId) {
      const tab = form.image === "" ? "document" : "region";
      setSelectedTab(tab);
      setPreviousSelectedFormId(formId);
    }
  }, [form, formId, setSelectedTab, previousSelectedFormId]);
}

function useTemplateSentry(formId: string) {
  const history = useHistory();
  const { form } = useFormEditor();

  useEffect(() => {
    if (formId === form?.id && form?.isTemplate) {
      history.push(`/form/${formId}/test`);
    }
  }, [formId, form, history]);
}

const FormEditorContainerImpl = React.memo(() => {
  const {
    isFailedToFetchForm,
    form,
    canvasStore,
    imageSize,
    onSelectAnchor,
    onSelectField,
    onSelectDetectionRegion,
    selectedAnchorId,
    selectedFieldId,
    selectedDetectionRegionId,
  } = useFormEditor();

  const { setCurrentTutorial } = useTutorial();

  const { formId } = useParams<PathParam>();

  useTemplateSentry(formId);
  useSetUpForm(formId);

  const { selectedTab, setSelectedTab, onSelectFormInspectorTab } =
    useFormInspectorTabSelection();

  useAutoSelectFormInspectorTab({
    formId,
    setSelectedTab,
  });

  const isFormImageReady =
    form !== undefined && form.image && imageSize === undefined;
  const isFormLoaded = form !== undefined && form.id === formId;
  const isLoading = (!isFormLoaded || isFormImageReady) && !isFailedToFetchForm;

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      {isLoading ? (
        <LoadingModal isOpen={isLoading} />
      ) : isFailedToFetchForm ? (
        <ErrorPlaceholder messageId="common.fail_to_fetch_form" />
      ) : (
        form && (
          <>
            <Main hasTop={true} hasRight={!isFailedToFetchForm}>
              <FormNavBarLayout form={form}>
                {form.image && imageSize ? (
                  <Labeller
                    canvasStore={canvasStore}
                    imageURL={form.image}
                    width={imageSize.width}
                    height={imageSize.height}
                    anchors={form.anchors}
                    fields={form.fields}
                    detectionRegions={form.detectionRegions}
                    onSelectAnchor={onSelectAnchor}
                    onSelectField={onSelectField}
                    onSelectDetectionRegion={onSelectDetectionRegion}
                    setCurrentTutorial={setCurrentTutorial}
                    selectedAnchorId={selectedAnchorId}
                    selectedFieldId={selectedFieldId}
                    selectedDetectionRegionId={selectedDetectionRegionId}
                  />
                ) : (
                  <NoFormImagePlaceholder />
                )}
              </FormNavBarLayout>
            </Main>
            <Right hasTop={true}>
              <FormInspectorV2
                form={form}
                selectedTab={selectedTab}
                onSelectFormInspectorTab={onSelectFormInspectorTab}
              />
            </Right>
            <FormNotSavedPrompt />
          </>
        )
      )}
    </Layout>
  );
});

const FormEditorContainer = React.memo(() => {
  return (
    <FormEditorProvider>
      <FormEditorContainerImpl />
    </FormEditorProvider>
  );
});

export default FormEditorContainer;
