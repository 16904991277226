import { IconButton, Label, Toggle } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";

import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import {
  APIDescription,
  APIParameter,
  APIParameterAction,
  APIResponseField,
} from "../../models";
import {
  ExternalServicesAlertBoxForForm,
  ExternalServicesAlertBoxForReceipt,
} from "../ExternalServiceAlertBox";
import styles from "./styles.module.scss";

function useMakeActionTableContext(showAndScrollToAsyncSection: () => void) {
  return React.useMemo(
    () => ({
      asyncMode: showAndScrollToAsyncSection,
    }),
    [showAndScrollToAsyncSection]
  );
}

type ActionTableContextValue = ReturnType<typeof useMakeActionTableContext>;
const ActionTableContext = React.createContext<ActionTableContextValue>(
  null as any
);

function useActionTable() {
  return React.useContext(ActionTableContext);
}

interface Props {
  getDescription: (...args: any[]) => APIDescription;
  shouldShowAsync: boolean;
  shouldShowMultiDocument: boolean;
}

function useAsyncDocumentation() {
  const [isShowingAsyncDocumentation, setIsShowingAsyncDocumentation] =
    React.useState(false);
  const asyncSectionRef = React.useRef<HTMLDivElement | null>(null);

  const showAndScrollToAsyncSection = React.useCallback(() => {
    setIsShowingAsyncDocumentation(true);
    if (asyncSectionRef.current) {
      asyncSectionRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  const toggleShowingAsyncSection = React.useCallback(() => {
    setIsShowingAsyncDocumentation(prevState => !prevState);
  }, []);

  return React.useMemo(
    () => ({
      asyncSectionRef,
      isShowingAsyncDocumentation,
      toggleShowingAsyncSection,
      showAndScrollToAsyncSection,
    }),
    [
      asyncSectionRef,
      isShowingAsyncDocumentation,
      toggleShowingAsyncSection,
      showAndScrollToAsyncSection,
    ]
  );
}

interface EndpointSectionProps {
  description: APIDescription;
}

function EndpointSection(props: EndpointSectionProps) {
  return (
    <div className={styles["section"]}>
      <h1>
        <FormattedMessage id="apinote.endpoint" />
      </h1>
      <div>
        <div className={styles["endpoint-group"]}>
          <span className={styles["method"]}>{props.description.method}</span>
          <span className={styles["endpoint"]}>
            {props.description.endpoint}
          </span>
        </div>
        <pre className={styles["description"]}>
          <FormattedMessage id={`${props.description.name}.description`} />
        </pre>
      </div>
    </div>
  );
}

interface ActionButtonProps {
  apiName: string;
  paramName: string;
  action: APIParameterAction;
}

function ActionButton(props: ActionButtonProps) {
  const { apiName, paramName, action } = props;
  const actionFunction = useActionTable()[action];

  return (
    <span className={styles["action-button"]} onClick={actionFunction}>
      <FormattedMessage id={`${apiName}.${paramName}.action.${action}`} />
    </span>
  );
}

interface ParamTableProps {
  apiName: string;
  params: APIParameter[];
}
function ParamTable(props: ParamTableProps) {
  const { apiName, params } = props;

  return (
    <table>
      <thead>
        <tr>
          <td>
            <FormattedMessage id="apinote.param_name" />
          </td>
          <td>
            <FormattedMessage id="apinote.param_description" />
          </td>
        </tr>
      </thead>
      <tbody>
        {params.map((param, idx) => {
          const values = param.actions
            ? {
                values: param.actions.reduce(
                  (acc: any, action: APIParameterAction) => ({
                    ...acc,
                    [action]: (
                      <ActionButton
                        apiName={apiName}
                        paramName={param.name}
                        action={action}
                      />
                    ),
                  }),
                  {}
                ),
              }
            : {};
          return (
            <tr key={idx}>
              <td>
                {param.name}
                {param.isOptional && (
                  <span className={styles["optional"]}>
                    <FormattedMessage id="apinote.optional" />
                  </span>
                )}
              </td>
              <td>
                <FormattedMessage
                  id={`${apiName}.${param.name}.desc`}
                  {...values}
                />
                {param.defaultValue && (
                  <div className={styles["default-value"]}>
                    <FormattedMessage id="apinote.default" />
                    <span>{param.defaultValue}</span>
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

interface ParamSectionProps {
  description: APIDescription;
}

function HeaderParamSection(props: ParamSectionProps) {
  const { description } = props;

  return (
    <div className={styles["section"]}>
      <h1>
        <FormattedMessage id="apinote.header_parameters" />
      </h1>
      <ParamTable
        apiName={description.name}
        params={description.headerParams}
      />
    </div>
  );
}

function FormParamSection(props: ParamSectionProps) {
  const { description } = props;

  return (
    <div className={styles["section"]}>
      <h1>
        <FormattedMessage id="apinote.form_parameters" />
      </h1>
      <ParamTable apiName={description.name} params={description.formParams} />
    </div>
  );
}

interface EndpointSectionProps {
  description: APIDescription;
}

function URLExampleSection(props: EndpointSectionProps) {
  const { description } = props;

  return (
    <div className={styles["section"]}>
      <h1>
        <FormattedMessage id="apinote.example" />
      </h1>
      <pre>{description.cURLExample}</pre>
    </div>
  );
}

interface ResponseFieldTableProps {
  apiName: string;
  fields: APIResponseField[];
  type?: string;
}

function ResponseFieldTable(props: ResponseFieldTableProps) {
  const { apiName, fields, type } = props;

  return (
    <table>
      <thead>
        <tr>
          <td>
            <FormattedMessage id="apinote.field_name" />
          </td>
          <td>
            <FormattedMessage id="apinote.field_type" />
          </td>
          <td>
            <FormattedMessage id="apinote.field_description" />
          </td>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, idx) => {
          return (
            <tr key={idx}>
              <td>
                {field.name}
                {field.isOptional && (
                  <span className={styles["optional"]}>
                    <FormattedMessage id="apinote.optional" />
                  </span>
                )}
              </td>
              <td>{field.fieldType}</td>
              <td>
                <FormattedMessage
                  id={`${apiName}.field${type ? "." + type : ""}.${
                    field.name
                  }.desc`}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

interface ResponseFieldsSectionProps {
  description: APIDescription;
  isMultiDocument: boolean;
  shouldShowMultiDocument: boolean;
  toggleResponseSection: (_e: React.MouseEvent, checked?: boolean) => void;
}

function ResponseFieldsSection(props: ResponseFieldsSectionProps) {
  const {
    shouldShowMultiDocument,
    isMultiDocument,
    toggleResponseSection,
    description,
  } = props;
  const { localized } = useLocale();

  return (
    <div className={styles["section"]}>
      <h1 className={styles["heading-with-toggle"]}>
        <span className={styles["heading"]}>
          <FormattedMessage id="apinote.response" />
        </span>
        {shouldShowMultiDocument && (
          <Toggle
            label={localized("apinote.toggle.multi_document")}
            onText={localized("apinote.toggle.on")}
            offText={localized("apinote.toggle.off")}
            checked={isMultiDocument}
            onChange={toggleResponseSection}
            inlineLabel
          />
        )}
      </h1>
      <ResponseFieldTable
        apiName={description.name}
        fields={description.responseFields}
      />
      {description.types &&
        description.types.map((type, index) => (
          <div key={index}>
            <Label>{type.name}</Label>
            <ResponseFieldTable
              apiName={description.name}
              fields={type.fields}
              type={type.name}
            />
          </div>
        ))}
    </div>
  );
}

interface AsyncComponentProps {
  asyncSectionRef: React.MutableRefObject<HTMLDivElement | null>;
  isShowingAsyncDocumentation: boolean;
  toggleShowingAsyncSection: () => void;
}

function AsyncComponent(props: AsyncComponentProps) {
  const { localized } = useLocale();
  const {
    asyncSectionRef,
    isShowingAsyncDocumentation,
    toggleShowingAsyncSection,
  } = props;

  const acceptedResponse = `202 Accepted 

{ 
status: "ok",
job_id: <string>
}`;

  const pendingResponse = `201 Created

{
status: "pending",
job_id: <string>
}`;

  const okResponse = `200 OK

{
status: "ok",
// ${localized("apinote.async.structure_same_as")}
}`;

  const jobApi = `${AppConfig.worker.endpoint}extract/jobs/:job_id`;

  return (
    <div
      className={classnames(styles["section"], styles["async"])}
      ref={asyncSectionRef}
    >
      <h1>
        <IconButton
          iconProps={{
            iconName: isShowingAsyncDocumentation
              ? "ChevronDown"
              : "ChevronRight",
          }}
          onClick={toggleShowingAsyncSection}
        />
        <span onClick={toggleShowingAsyncSection}>
          <FormattedMessage id="apinote.async.using_async_mode" />
        </span>
      </h1>
      {isShowingAsyncDocumentation && (
        <>
          <p>
            <FormattedMessage
              id="apinote.async.using_async_mode.desc1"
              values={{
                tag: <code>async</code>,
              }}
            />
            <FormattedMessage
              id="apinote.async.using_async_mode.desc2"
              values={{
                tag: <code>async</code>,
              }}
            />
          </p>
          <div className={styles["section"]}>
            <h1>
              <FormattedMessage id="apinote.async.job_id" />
            </h1>
            <p>
              <FormattedMessage
                id="apinote.async.job_id.desc"
                values={{
                  resp: <code>202 Accepted</code>,
                  status: <code>status</code>,
                  jobId: <code>job_id</code>,
                }}
              />
            </p>
            <pre>{acceptedResponse}</pre>
            <h1>
              <FormattedMessage id="apinote.async.getting_result" />
            </h1>
            <div className={styles["endpoint-group"]}>
              <span className={styles["method"]}>GET</span>
              <span className={styles["endpoint"]}>{jobApi}</span>
            </div>
            <p>
              <FormattedMessage
                id="apinote.async.getting_result.desc1"
                values={{
                  endpoint: <code>/extract/jobs/:job_id</code>,
                  jobIdParam: <code>:job_id</code>,
                  jobId: <code>job_id</code>,
                  extractEndpoint: <code>/extract</code>,
                }}
              />
              <br />
              <br />
              <FormattedMessage
                id="apinote.async.getting_result.desc2"
                values={{ method: <code>GET</code> }}
              />
              <br />
              <FormattedMessage id="apinote.async.getting_result.desc3" />
            </p>
            <h4>
              <FormattedMessage id="apinote.async.pending" />
            </h4>
            <pre>{pendingResponse}</pre>
            <h4>
              <FormattedMessage id="apinote.async.completed" />
            </h4>
            <pre>{okResponse}</pre>
          </div>
        </>
      )}
    </div>
  );
}

function _APINote(props: Props) {
  const [isMultiDocument, setIsMultiDocument] = React.useState(false);
  const { getDescription, shouldShowAsync, shouldShowMultiDocument } = props;

  const {
    asyncSectionRef,
    isShowingAsyncDocumentation,
    toggleShowingAsyncSection,
    showAndScrollToAsyncSection,
  } = useAsyncDocumentation();

  const actionTable = useMakeActionTableContext(showAndScrollToAsyncSection);

  const toggleResponseSection = React.useCallback(
    (_e: React.MouseEvent, checked?: boolean) => {
      setIsMultiDocument(!!checked);
    },
    []
  );

  const description = getDescription(isMultiDocument);
  return (
    <ActionTableContext.Provider value={actionTable}>
      <div className={styles["api-note"]}>
        {description.name === "receipt" ? (
          <ExternalServicesAlertBoxForReceipt />
        ) : (
          <ExternalServicesAlertBoxForForm />
        )}
        <EndpointSection description={description} />
        {shouldShowAsync && !AppConfig.worker.shouldAsyncDisabled && (
          <AsyncComponent
            asyncSectionRef={asyncSectionRef}
            isShowingAsyncDocumentation={isShowingAsyncDocumentation}
            toggleShowingAsyncSection={toggleShowingAsyncSection}
          />
        )}
        <URLExampleSection description={description} />
        <HeaderParamSection description={description} />
        <FormParamSection description={description} />
        <ResponseFieldsSection
          description={description}
          isMultiDocument={isMultiDocument}
          shouldShowMultiDocument={shouldShowMultiDocument}
          toggleResponseSection={toggleResponseSection}
        />
      </div>
    </ActionTableContext.Provider>
  );
}

export const APINote = React.memo(_APINote);
export default APINote;
