import { apiClient } from "../apiClient";
import { UpdateFormGroupRequestParams } from "../apiClient/mixin/formGroup";
import { useThunkDispatch } from "../hooks/thunk";
import { Dispatch, RootState } from "../redux/types";
import { BriefForm } from "../types/form";
import { FormGroupType } from "../types/formGroup";
import { BriefFormGroup, DetailFormGroup } from "../types/formGroup";
import { PageInfo } from "../types/pageInfo";
import { triggerFileSave } from "../utils/file";
import { FormsInvalidated } from "./form";
import { selectTeam } from "./resourceOwner";

export interface GettingFormGroupListAction {
  readonly type: "GettingFormGroupList";
  readonly listVersion: number;
}

export const GotFormGroupList = "GotFormGroupList";
export interface GotFormGroupListAction {
  readonly type: typeof GotFormGroupList;
  readonly payload: {
    formGroups: BriefFormGroup[];
    pageInfo: PageInfo;
    listVersion: number;
  };
}

export const FormGroupCreated = "FormGroupCreated";
export interface FormGroupCreatedAction {
  readonly type: typeof FormGroupCreated;
  readonly payload: {
    formGroup: BriefFormGroup;
  };
}

export const FormGroupDeleted = "FormGroupDeleted";
export interface FormGroupDeletedAction {
  readonly type: typeof FormGroupDeleted;
  readonly payload: {
    formGroupId: string;
  };
}

export const GotFormGroup = "GotFormGroup";
export interface GotFormGroupAction {
  readonly type: typeof GotFormGroup;
  readonly payload: {
    formGroup: DetailFormGroup;
  };
}

export const GotForms = "GotForms";
export interface GotFormsAction {
  readonly type: typeof GotForms;
  readonly payload: {
    forms: BriefForm[];
  };
}

export const FormGroupsInvalidated = "FormGroupsInvalidated";
export interface FormGroupsInvalidatedAction {
  readonly type: typeof FormGroupsInvalidated;
}

export function listFormGroups(size: number = 20) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const state = getState();
    const { pageInfo, listVersion } = state.formGroup;
    const { resourceOwnerId } = state.resourceOwner;

    if (pageInfo && !pageInfo.hasNext) {
      return Promise.resolve();
    }

    const newListVersion = listVersion + 1;

    dispatch({
      type: "GettingFormGroupList",
      listVersion: newListVersion,
    });

    const cursor = pageInfo && pageInfo.hasNext ? pageInfo.cursor : "";
    const paginatedResult = await apiClient.listFormGroups(
      size,
      cursor,
      resourceOwnerId
    );

    dispatch({
      type: GotFormGroupList,
      payload: {
        formGroups: paginatedResult.formGroups,
        pageInfo: paginatedResult.pageInfo,
        listVersion: newListVersion,
      },
    });
  };
}

export function createFormGroup(name: string, type: FormGroupType) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<BriefFormGroup> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const formGroup = await apiClient.createFormGroup(
      name,
      type,
      resourceOwnerId
    );

    dispatch({
      type: FormGroupCreated,
      payload: {
        formGroup: formGroup,
      },
    });

    return formGroup;
  };
}

export function deleteFormGroup(formGroupId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    await apiClient.deleteFormGroup(formGroupId, resourceOwnerId);

    dispatch({
      type: FormGroupDeleted,
      payload: {
        formGroupId: formGroupId,
      },
    });
  };
}

export function getFormGroup(formGroupId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<DetailFormGroup> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const formGroup = await apiClient.getFormGroup(
      formGroupId,
      resourceOwnerId
    );

    if (formGroup.resourceOwnerId) {
      selectTeam(formGroup.resourceOwnerId)(dispatch, getState);
    }

    dispatch({
      type: GotFormGroup,
      payload: {
        formGroup: formGroup,
      },
    });

    return formGroup;
  };
}

export function updateFormGroup(
  formGroupId: string,
  params: UpdateFormGroupRequestParams
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<DetailFormGroup> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const formGroup = await apiClient.updateFormGroup(
      formGroupId,
      params,
      resourceOwnerId
    );

    dispatch({
      type: GotFormGroup,
      payload: {
        formGroup: formGroup,
      },
    });

    return formGroup;
  };
}

export function fetchForms() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const fetchedForms: BriefForm[] = [];

      const _fetchAllForms = async (cursor: string) => {
        try {
          const { resourceOwnerId } = getState().resourceOwner;
          const options = undefined;
          const listResult = await apiClient.listForm(
            50,
            cursor,
            options,
            resourceOwnerId
          );

          fetchedForms.push(...listResult.forms);

          if (listResult.pageInfo.hasNext) {
            _fetchAllForms(listResult.pageInfo.cursor);
          } else {
            dispatch({
              type: GotForms,
              payload: {
                forms: fetchedForms,
              },
            });
            resolve();
          }
        } catch (_e) {
          reject();
        }
      };

      return _fetchAllForms("");
    });
  };
}

export async function exportFormGroup(
  formGroupId: string,
  resourceOwnerId?: string,
  region?: string
): Promise<void> {
  const path =
    `/export-form-group?form_group_id=${formGroupId}` +
    (resourceOwnerId ? `&resource_owner_id=${resourceOwnerId}` : "");
  const url = apiClient.getEffectiveEndpoint(path, region);
  const response = await apiClient.fetch(url);
  triggerFileSave(response);
}

export function importFormGroup(file: File, useExistingForms: boolean) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const url = apiClient.getEffectiveEndpoint("/import-form-group");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("use_existing_forms", useExistingForms ? "1" : "0");
    if (resourceOwnerId) {
      formData.append("resource_owner_id", resourceOwnerId);
    }

    const response = await apiClient.fetch(url, {
      method: "POST",
      body: formData,
    });

    const { result } = await response.json();

    if (!result || result.status !== "ok") {
      throw result;
    }

    dispatch({
      type: FormGroupsInvalidated,
    });
    dispatch({
      type: FormsInvalidated,
    });

    return result;
  };
}

export type FormGroupAction =
  | GettingFormGroupListAction
  | GotFormGroupListAction
  | FormGroupCreatedAction
  | FormGroupDeletedAction
  | GotFormGroupAction
  | GotFormsAction
  | FormGroupsInvalidatedAction;

export function useFormGroupActionCreator() {
  return {
    list: useThunkDispatch(listFormGroups),
    create: useThunkDispatch(createFormGroup),
    get: useThunkDispatch(getFormGroup),
    update: useThunkDispatch(updateFormGroup),
    fetchForms: useThunkDispatch(fetchForms),
    delete: useThunkDispatch(deleteFormGroup),
    import: useThunkDispatch(importFormGroup),
  };
}
