import { ICommandBarItemProps } from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { extractFieldInTestMode } from "../actions/form";
import { extractReceiptInfoInTestMode } from "../actions/receiptGroup";
import { chooseFile } from "../components/FileButton";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import MultiDocumentIndicator from "../components/MultiDocumentIndicator";
import { OCRTest } from "../components/OCRTest";
import { ReceiptNavBarLayout } from "../components/ReceiptNavBar";
import { ReceiptTest } from "../components/ReceiptTest";
import { SUPPORTED_EXTRACT_MIME } from "../constants";
import HeaderContainer from "../containers/Header";
import { useLocale } from "../contexts/locale";
import { FOCRError } from "../errors";
import { useWorkerToken } from "../hooks/app";
import { useToast } from "../hooks/toast";
import { OCRTestReport, ReceiptTestReport } from "../models";
import { RootState } from "../redux/types";
import { BriefFormWithType, FormExtractionMode } from "../types/form";
import { ReceiptGroupWithType } from "../types/receiptGroup";
import { imageURLToFile } from "../utils/image";

type ReceiptGroup = ReceiptGroupWithType | BriefFormWithType;

function useSelectMultiDocument() {
  const [extractionMode, setExtractionMode] = useState(
    FormExtractionMode.singlePage
  );

  const onExtractionModeChanged = useCallback((value: FormExtractionMode) => {
    setExtractionMode(value);
  }, []);

  return React.useMemo(
    () => ({
      extractionMode,
      onExtractionModeChanged,
    }),
    [extractionMode, onExtractionModeChanged]
  );
}

function useExtractReceiptInfo(
  receiptGroup: ReceiptGroup | undefined,
  token: string | undefined,
  extractionMode: FormExtractionMode
) {
  const [ocrTestReport, setOcrTestReport] = useState<OCRTestReport | undefined>(
    undefined
  );
  const [receiptTestReport, setReceiptTestReport] = useState<
    ReceiptTestReport | undefined
  >(undefined);
  const [imageDataURI, setImageDataURI] = useState<string | undefined>(
    undefined
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const toast = useToast();

  const clearOcrTestReport = useCallback(() => {
    setOcrTestReport(undefined);
  }, []);

  const extractWithImageFile = useCallback(
    (file?: File) => {
      if (!receiptGroup) return;

      if (file && token) {
        let promise: Promise<OCRTestReport | ReceiptTestReport>;

        if (receiptGroup.type === "receipt_group") {
          setIsLoading(true);
          promise = extractReceiptInfoInTestMode(token, receiptGroup.id, file);
        } else {
          setIsUploading(true);
          promise = extractFieldInTestMode(
            token,
            receiptGroup.id,
            file,
            extractionMode,
            () => {
              setIsUploading(false);
              setIsLoading(true);
            }
          );
        }
        promise
          .then(resp => {
            if (receiptGroup.type === "receipt_group") {
              setIsLoading(false);
              setReceiptTestReport(resp as ReceiptTestReport);
              setImageDataURI((resp as ReceiptTestReport).image);
            } else {
              setIsLoading(false);
              setOcrTestReport(resp as OCRTestReport);
            }
          })
          .catch(error => {
            setIsUploading(false);
            setIsLoading(false);
            if (error instanceof FOCRError) {
              toast.error(error.messageId, undefined, error.detail);
            } else {
              toast.error("error.cannot_load_image");
            }
          });
      }
    },
    [receiptGroup, token, extractionMode, toast]
  );

  const extractWithImageUrl = useCallback(
    (url: string) => {
      imageURLToFile(url)
        .then(extractWithImageFile)
        .catch(e => {
          console.log(e);
        });
    },
    [extractWithImageFile]
  );

  return React.useMemo(
    () => ({
      ocrTestReport,
      clearOcrTestReport,
      receiptTestReport,
      imageDataURI,
      extractWithImageUrl,
      extractWithImageFile,
      isLoading,
      isUploading,
    }),
    [
      ocrTestReport,
      clearOcrTestReport,
      receiptTestReport,
      imageDataURI,
      extractWithImageUrl,
      extractWithImageFile,
      isLoading,
      isUploading,
    ]
  );
}

function useCommandBarItems(
  extractWithImageFile: (file?: File) => void,
  clearOcrTestReport: () => void,
  ocrTestReport: OCRTestReport | undefined,
  shouldMultiDocument: boolean
) {
  const { localized } = useLocale();
  const onFiles = useCallback(
    (files?: File[]) => extractWithImageFile(files && files[0]),
    [extractWithImageFile]
  );

  return useMemo((): ICommandBarItemProps[] => {
    return [
      ...(shouldMultiDocument && ocrTestReport
        ? [
            {
              key: "multi-document",
              commandBarButtonAs: () => {
                return <MultiDocumentIndicator />;
              },
              renderedInOverflow: false,
            },
          ]
        : []),
      ocrTestReport
        ? {
            key: "choose",
            text: localized("ocr_test.upload_another_file"),
            iconProps: { iconName: "FileImage" },
            onClick: clearOcrTestReport,
          }
        : {
            key: "choose",
            text: localized("ocr_test.choose"),
            iconProps: { iconName: "FileImage" },
            onClick: () => {
              chooseFile(SUPPORTED_EXTRACT_MIME.join(","))
                .then(onFiles)
                .catch(() => {});
            },
          },
    ];
  }, [
    localized,
    ocrTestReport,
    onFiles,
    clearOcrTestReport,
    shouldMultiDocument,
  ]);
}

function _ReceiptTestContainer() {
  const receiptGroup = useSelector<RootState, ReceiptGroup | undefined>(
    state => state.receiptGroup.currentReceiptGroup
  );

  const { token } = useWorkerToken();

  const { extractionMode, onExtractionModeChanged } = useSelectMultiDocument();

  const {
    ocrTestReport,
    clearOcrTestReport,
    receiptTestReport,
    extractWithImageFile,
    extractWithImageUrl,
    isLoading,
    isUploading,
    imageDataURI,
  } = useExtractReceiptInfo(receiptGroup, token, extractionMode);

  const commbarBarItems = useCommandBarItems(
    extractWithImageFile,
    clearOcrTestReport,
    ocrTestReport,
    extractionMode !== FormExtractionMode.singlePage
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {isLoading ? (
          <LoadingModal
            messageId="receipt_test.recognizing"
            isOpen={isLoading}
          />
        ) : (
          <span />
        )}
        {isUploading ? (
          <LoadingModal messageId="common.uploading" isOpen={isUploading} />
        ) : (
          <span />
        )}
        <ReceiptNavBarLayout commbarBarItems={commbarBarItems}>
          {receiptGroup && receiptGroup.type === "receipt_group" && (
            <ReceiptTest
              onSelectImage={extractWithImageFile}
              onSelectSampleImage={extractWithImageUrl}
              receiptTestReport={receiptTestReport}
              imageDataURI={imageDataURI}
            />
          )}
          {receiptGroup && receiptGroup.type === "form" && (
            <OCRTest
              onSelectImage={extractWithImageFile}
              onSelectSampleImage={extractWithImageUrl}
              isSampleForm={receiptGroup.isSample}
              isReceiptForm={true}
              isTemplate={receiptGroup.isTemplate}
              ocrTestReport={ocrTestReport}
              formName={receiptGroup.name}
              extractionMode={extractionMode}
              onExtractionModeChanged={onExtractionModeChanged}
            />
          )}
        </ReceiptNavBarLayout>
      </Main>
    </Layout>
  );
}

export const ReceiptTestContainer = React.memo(_ReceiptTestContainer);
export default ReceiptTestContainer;
