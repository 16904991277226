@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .upload-icon {
    @include no-select();
    font-size: 60px;
  }

  .instruction1 {
    line-height: 24px;
    margin-bottom: 15px;
  }

  .instruction2 {
    color: #8a8886;
    line-height: 14px;
    margin-bottom: 15px;
  }
}
