@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

$create-form-bar-height: 42px;

.team-table {
  td {
    padding: 0px 12px;
  }

  td:nth-child(3) {
    min-width: 140px;
  }
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: $create-form-bar-height;
  background-color: #fff;
  border-bottom: 1px solid $separator-color-2;

  padding-left: 32px;

  .button-area {
    cursor: pointer;
    text-align: center;
    padding-right: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > i {
      margin-right: 8px;
    }
  }

  .float-right {
    margin-left: auto;
  }
}

.content {
  height: calc(100% - #{$create-form-bar-height});
  position: relative;
}

.team-action-button {
  color: $theme-primary;
}

.team-destroy-button {
  color: $alert-color;
}

.team-table-row {
  height: 43px;
  td:first-child {
    width: 100%;
  }
  td:nth-child(2) {
    min-width: 140px;
  }
}

.section {
  padding: 32px;
  vertical-align: middle;

  .title {
    margin-bottom: 26px;

    h3 {
      padding-bottom: 5px;
    }

    .region {
      color: gray;
      font-weight: normal;
    }
  }
}
