import * as React from "react";
import { useParams } from "react-router";

import { getExtractAPIDescription } from "../apidoc/extract";
import APINote from "../components/APINote";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FormNavBarLayout } from "../components/FormNavBar";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { useAPICommandBarItems } from "../hooks/command_bar_items";
import { useCommonFormContainerState } from "../hooks/form";

type PathParam = {
  formId: string;
};

export const FormAPIContainer = React.memo(() => {
  const { formId } = useParams<PathParam>();
  const containerState = useCommonFormContainerState(formId);

  const commandBarItems = useAPICommandBarItems(
    ...(containerState.state === "success"
      ? [containerState.form.id, containerState.workerToken]
      : [undefined, undefined])
  );
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
        ) : containerState.state === "success" ? (
          <FormNavBarLayout
            form={containerState.form}
            commbarBarItems={commandBarItems}
          >
            <APINote
              getDescription={getExtractAPIDescription}
              shouldShowAsync={true}
              shouldShowMultiDocument={true}
            />
          </FormNavBarLayout>
        ) : null}
      </Main>
    </Layout>
  );
});

export default FormAPIContainer;
