import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";

import { importFormGroup } from "../../actions/formGroup";
import { useLocale } from "../../contexts/locale";
import { useToast } from "../../hooks/toast";
import { ThunkActionCreatorType } from "../../redux/types";
import { FileDefaultButton } from "../FileButton";
import { PrimaryLoadingButton } from "../LoadingButton";
import styles from "./styles.module.scss";

interface Props {
  resourceOwnerId?: string;
  isOpen: boolean;
  onCancel(): void;
  onSuccess(): void;
  importFormGroup: ThunkActionCreatorType<typeof importFormGroup>;
}

const ImportFormGroupModal = (props: Props) => {
  const { onCancel, isOpen, onSuccess, importFormGroup } = props;
  const toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [file, setFile] = useState<File | undefined>(undefined);
  const [useExistingForms, setUseExistingForms] = useState<boolean>(false);

  const { localized } = useLocale();

  const onDismissed = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (isLoading || !file) return;
      try {
        setIsLoading(true);
        await importFormGroup(file, useExistingForms);
        onSuccess();
      } catch {
        toast.error("error.fail_to_import_form_group");
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, file, useExistingForms, importFormGroup, onSuccess, toast]
  );

  const onFilesChange = useCallback((files?: File[]) => {
    if (!files || files.length === 0) {
      return;
    }
    const selectedFile = files[0];
    setFile(selectedFile);
  }, []);

  const onUseExistingFormsChanged = useCallback(
    (
      _ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
      checked?: boolean
    ) => {
      setUseExistingForms(!!checked);
    },
    []
  );

  const onDismiss = useCallback(() => {
    if (isLoading) {
      return;
    }
    onCancel();
  }, [isLoading, onCancel]);

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("import_form_group.title"),
    }),
    [localized]
  );

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  return (
    <Dialog
      minWidth={480}
      hidden={!isOpen}
      onDismiss={onDismiss}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={onSubmit}>
        <div className={styles["upload-file-field"]}>
          <div className={styles["file-section"]}>
            <div className={styles["file-name-container"]}>
              <Text>{file && file.name}</Text>
            </div>
            <FileDefaultButton
              textId={"common.choose_file"}
              onFiles={onFilesChange}
              accept="application/zip"
              disabled={isLoading}
            />
          </div>
        </div>
        <Checkbox
          disabled={isLoading}
          className={styles["use-existing-forms-checkbox"]}
          checked={useExistingForms}
          onChange={onUseExistingFormsChanged}
          label={localized("import_form_group.use_existing_forms")}
        />
        <Text className={styles["use-existing-forms-desc"]}>
          {localized("import_form_group.use_existing_forms.desc")}
        </Text>
        <DialogFooter>
          <DefaultButton
            onClick={onCancel}
            disabled={isLoading}
            text={localized("common.cancel")}
          />
          <PrimaryLoadingButton
            type="submit"
            disabled={!file}
            isLoading={isLoading}
            loadingMessageId="common.importing"
          >
            {localized("common.import")}
          </PrimaryLoadingButton>
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export { ImportFormGroupModal };
