.container {
  .auto-extraction-items {
    width: 100%;
    margin-bottom: 12px;
    padding-bottom: 4px;

    .auto-extraction-items-label {
      margin-top: 15px;
      label {
        display: inline;
        margin-right: 4px;
      }

      i {
        font-size: 14px;
      }
    }

    .row {
      display: flex;
    }

    .check-box {
      margin-top: 8px;
    }

    .setting {
      margin-top: 8px;
      height: inherit;
    }

    .setting:hover {
      background-color: transparent;
    }
  }

  label {
    font-size: 16px;
  }
}
