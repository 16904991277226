@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.usage-history {
  margin: 0px 30px;
  min-width: 540px;

  h1 {
    padding: 10px 0;
    font-size: 18px;
    margin: 10px 0;
    border-bottom: 1px solid $tab-bar-separator;
  }
}

.usage {
  @include ms-depth-4;
  @include cardBoxBorderRadius();
  min-width: 550px;
  width: 90%;
  margin-bottom: 24px;
  display: inline-block;
  padding: 20px;

  .api-name-label {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
  }

  .no-border {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .total-label {
    font-weight: 400;
  }

  .table_total {
    text-align: right;
    margin-top: 12px;
    padding-right: 12px;
  }
  table {
    tr {
      th:last-child {
        text-align: right;
      }
      td:last-child {
        text-align: right;
      }
      td:nth-child(2) {
        color: $light-gray-color;
      }
    }
  }
}
