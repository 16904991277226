import React from "react";
import { useSelector } from "react-redux";

import { ListTeamOptions } from "../apiClient/mixin/team";
import { TeamListLayout } from "../components/TeamList";
import { getRegionKeys } from "../config";
import { useFetchAllRegionTeams } from "../hooks/admin";
import { useFetchPlans, useLocalizeRegion } from "../hooks/app";
import { TeamListState } from "../reducers/admin";
import { RootState } from "../redux/types";
import { Plan } from "../types/plan";
import {
  URLParamsKey,
  getParam,
  removeParam,
  setParam,
  setParams,
} from "../utils/params";
import { isUUID } from "../utils/uuid";

const PAGE_SIZE = 20;

function getFilterOptions(
  searchText?: string,
  fieldToSearch?: string,
  selectedPlan?: string
): ListTeamOptions {
  const filterOptions: ListTeamOptions = {
    searchString: searchText === "" ? undefined : searchText,
    fieldToSearch: fieldToSearch === "" ? undefined : fieldToSearch,
  };
  if (selectedPlan !== undefined && selectedPlan !== "") {
    if (isUUID(selectedPlan)) {
      filterOptions.planId = selectedPlan;
    } else {
      filterOptions.plan = selectedPlan;
    }
  }
  return filterOptions;
}

function getOffset(page: number | null): number {
  if (page === null || isNaN(page) || page <= 0) {
    return 0;
  }
  return PAGE_SIZE * (page - 1);
}

function getPage(offset: number): number {
  if (offset <= PAGE_SIZE) {
    return 1;
  }
  return Math.ceil(offset / PAGE_SIZE);
}

interface ReduxProps {
  teamsStateByRegion: {
    [key: string]: TeamListState;
  };
  plans: Plan[];
}

function useRedux(): ReduxProps {
  const { teamsStateByRegion, plans } = useSelector((state: RootState) => {
    const { teamsStateByRegion } = state.admin;
    const { plans } = state.app;
    return {
      plans,
      teamsStateByRegion,
    };
  });

  return {
    plans,
    teamsStateByRegion,
  };
}

function _AdminTeamListContainer() {
  const { teamsStateByRegion, plans } = useRedux();
  const [regionKey, setRegionKey] = React.useState(
    getParam(URLParamsKey.region) ?? getRegionKeys()[0]
  );
  const localizeRegion = useLocalizeRegion();

  const getRegionLabel = React.useCallback(
    regionKey => {
      const isFetching = teamsStateByRegion[regionKey]?.isFetching ?? false;
      const totalCount =
        teamsStateByRegion[regionKey]?.pageInfo?.totalCount ?? 0;
      return `${localizeRegion(regionKey)} (${
        isFetching && totalCount === 0 ? "..." : totalCount
      })`;
    },
    [localizeRegion, teamsStateByRegion]
  );

  const regionOptions = React.useMemo(
    () =>
      getRegionKeys().map(r => ({
        key: r,
        label: getRegionLabel(r),
      })),
    [getRegionLabel]
  );
  const [fieldToSearch, setFieldToSearch] = React.useState<string | undefined>(
    getParam(URLParamsKey.filterType) ?? ""
  );
  const [searchText, setSearchText] = React.useState<string | undefined>(
    getParam(URLParamsKey.search) ?? undefined
  );
  const [selectedPlan, setSelectedPlan] = React.useState<string | undefined>(
    getParam(URLParamsKey.plan) ?? ""
  );
  const [filterOptions, setFilterOptions] = React.useState<ListTeamOptions>(
    getFilterOptions(searchText, fieldToSearch, selectedPlan)
  );
  const [offset, setOffset] = React.useState<number>(
    getOffset(parseInt(getParam(URLParamsKey.page) ?? "0"))
  );

  const onSubmitSearch = React.useCallback(() => {
    setOffset(0);
    removeParam(URLParamsKey.page);
    setFilterOptions(getFilterOptions(searchText, fieldToSearch, selectedPlan));
    setParams(
      new Map([
        [URLParamsKey.search, searchText],
        [URLParamsKey.filterType, fieldToSearch],
        [URLParamsKey.plan, selectedPlan],
      ])
    );
  }, [searchText, selectedPlan, fieldToSearch]);

  const { refetchRegionTeamsWithOffset } = useFetchAllRegionTeams(
    filterOptions,
    PAGE_SIZE,
    offset,
    regionKey
  );

  const onNavigateToPage = React.useCallback(
    (page: number) => {
      const newOffset = getOffset(page);
      setOffset(newOffset);
      if (page === 1) {
        removeParam(URLParamsKey.page);
      } else {
        setParam(URLParamsKey.page, page.toString());
      }
      refetchRegionTeamsWithOffset(regionKey, newOffset);
    },
    [regionKey, refetchRegionTeamsWithOffset]
  );

  const onRegionKeyChange = React.useCallback(
    (newRegionKey: string) => {
      if (newRegionKey === regionKey) {
        return;
      }

      let newRegionParam: string | undefined = newRegionKey;
      if (newRegionKey === getRegionKeys()[0]) {
        newRegionParam = undefined;
      }
      setRegionKey(newRegionKey);

      let newPageParam: string | undefined = undefined;
      const teamsState = teamsStateByRegion[newRegionKey];
      const newOffset = teamsState.pageInfo?.offset ?? 0;

      if (newOffset > PAGE_SIZE) {
        newPageParam = getPage(newOffset).toString();
        setOffset(newOffset);
      } else {
        // Fetch first page
        setOffset(0);
        refetchRegionTeamsWithOffset(newRegionKey, 0);
      }

      setParams(
        new Map([
          [URLParamsKey.region, newRegionParam],
          [URLParamsKey.page, newPageParam],
        ])
      );
    },
    [regionKey, teamsStateByRegion, refetchRegionTeamsWithOffset]
  );

  useFetchPlans();

  return (
    <TeamListLayout
      regionOptions={regionOptions}
      teamsStateByRegion={teamsStateByRegion}
      plans={plans}
      offset={offset}
      pageSize={PAGE_SIZE}
      regionKey={regionKey}
      setRegionKey={onRegionKeyChange}
      searchText={searchText}
      setSearchText={setSearchText}
      selectedPlan={selectedPlan}
      setSelectedPlan={setSelectedPlan}
      onSubmitSearch={onSubmitSearch}
      onNavigateToPage={onNavigateToPage}
      fieldToSearch={fieldToSearch}
      setFieldToSearch={setFieldToSearch}
    />
  );
}

export const AdminTeamListContainer = React.memo(_AdminTeamListContainer);
export default AdminTeamListContainer;
