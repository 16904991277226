import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useAppActionCreator } from "../actions/app";
import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import { SHOULD_RELOAD_COOKIE_POLLING_INTERVAL } from "../constants";
import { useLocale } from "../contexts/locale";
import messages from "../locale-data/en.json";
import { RootState } from "../redux/types";
import { capitalizeFirstLetter } from "../utils/capitalize";
import { CookieKey, getCookie } from "../utils/cookies";
import { URLParamsKey, setParam } from "../utils/params";

export function useEnsureCrossRegionLogout() {
  const isAuthenticated = useSelector<RootState, boolean>(
    state => state.user.isAuthenticated
  );

  React.useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const intervalId = window.setInterval(() => {
      if (getCookie(CookieKey.shouldReload)) {
        window.location.reload();
      }
    }, SHOULD_RELOAD_COOKIE_POLLING_INTERVAL);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [isAuthenticated]);
}

export function useEnsureTeamLookupIdIsInURL() {
  const user = useSelector((state: RootState) => state.user.currentUser);
  const resourceOwnerId = useSelector(
    (state: RootState) => state.resourceOwner.resourceOwnerId
  );

  const location = useLocation();

  React.useEffect(() => {
    if (!user || !resourceOwnerId) {
      return;
    }
    const lookupId = user.teams.find(
      team => team.id === resourceOwnerId
    )?.lookupId;
    if (lookupId && !location.pathname.startsWith("/admin")) {
      setParam(URLParamsKey.team, lookupId, true);
    }
  }, [location, resourceOwnerId, user]);
}

export function useWorkerToken() {
  const { getWorkerToken } = useResourceOwnerActionCreator();
  const [isFailed, setIsFailed] = React.useState(false);
  const token = useSelector(
    (state: RootState) => state.resourceOwner.workerToken
  );

  React.useEffect(() => {
    if (!token) {
      getWorkerToken().catch(e => {
        console.warn(e);
        setIsFailed(true);
      });
    }
  }, [getWorkerToken, token]);

  return {
    token,
    isFailed,
  };
}

export function useLocalizeRegion() {
  const { localized } = useLocale();

  const localizeRegion = React.useCallback(
    (region: string) => {
      const localeId = `multi.region.${region}`;
      if (localeId in messages) {
        return localized(localeId);
      } else {
        return capitalizeFirstLetter(region);
      }
    },
    [localized]
  );

  return localizeRegion;
}

export function useFetchPlans() {
  const { listPlan } = useAppActionCreator();
  React.useEffect(
    () => {
      listPlan();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listPlan]
  );
}
