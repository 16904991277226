import { RegionsConfig } from "../config";
import { URLParamsKey } from "../utils/params";

export function redirectToRegionForTeam(
  region: string,
  teamLookupId: string,
  path?: string
) {
  const destination = new URL(
    path ?? "",
    RegionsConfig.endpoints[region].portal
  );
  destination.searchParams.append(URLParamsKey.team, teamLookupId);

  window.location.href = destination.toString();
}
