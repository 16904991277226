import * as yup from "yup";

import { CustomModelMapper } from "./mappers/customModel";
import { pageInfoSchema, pageInfoWithOffsetSchema } from "./pageInfo";

export type CustomModelStatus =
  | "not_synced"
  | "labelling"
  | "training"
  | "trained";

const customModelStatusSchema = yup
  .string()
  .oneOf(["not_synced", "labelling", "training", "trained"]);

export const getUploadURLResponseSchema = yup
  .object({
    url: yup.string().defined(),
    data: yup.mixed((_x): _x is { [key: string]: string } => true).defined(),
    fileField: yup.string().defined(),
    assetId: yup.string().defined(),
  })
  .camelCase();

export const previewImageSchema = yup
  .object({
    assetId: yup.string().required(),
    contentType: yup.string().defined().nullable(),
    url: yup.string().defined().nullable(),
  })
  .camelCase();

export const briefCustomModelSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    previewImage: previewImageSchema.nullable(),
    noOfSampleImages: yup.number().required(),
    createdAt: yup.date().required(),
    status: customModelStatusSchema.required(),
  })
  .camelCase();

export const paginatedBriefCustomModelSchema = yup
  .object({
    pageInfo: pageInfoSchema.required(),
    customModels: yup.array(briefCustomModelSchema).defined(),
  })
  .camelCase();

const customModelVersionRespSchema = yup.object({
  tag: yup.string().defined(),
  created_at: yup.number().required(),
  fields: yup.array(yup.string().defined()).optional(),
});

export const customModelRespSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  created_at: yup.string().defined(),
  updated_at: yup.string().defined(),
  config: yup
    .object({
      fields: yup.array(yup.string().defined()).defined(),
      frozen_fields: yup.array(yup.string().defined()).optional(),
      remark: yup.string().defined(),
      training_requested: yup.boolean().required(),
      fxcm_type: yup.string().optional(),
      sample_images: yup
        .array(
          yup.object({
            asset_id: yup.string().defined(),
            filename: yup.string().defined(),
            updated_at: yup.string().defined(),
            content_type: yup.string().optional(),
            is_on_cvat: yup.boolean().required(),
            url: yup.string().optional(),
          })
        )
        .defined(),
    })
    .required(),
  cvat_project_id: yup.string().defined().nullable(),
  form_id: yup.string().defined().nullable(),
  last_cvat_project_start_sync_at: yup.string().defined().nullable(),
  last_cvat_project_finish_sync_at: yup.string().defined().nullable(),
  start_training_at: yup.string().defined().nullable(),
  finish_training_at: yup.string().defined().nullable(),
  start_deployment_at: yup.string().defined().nullable(),
  finish_deployment_at: yup.string().defined().nullable(),
  deployed_model_version: yup.string().defined().nullable(),
  model_versions: yup.array(customModelVersionRespSchema).defined(),
  model_version_in_training: customModelVersionRespSchema.defined().nullable(),
  resource_owner_id: yup.string().defined(),
  status: customModelStatusSchema.required(),
});

export const customModelSchema = yup
  .mixed((_x): _x is CustomModel => true)
  .defined()
  .test((_value, context) => {
    customModelRespSchema.validateSync(context.originalValue);
    return true;
  })
  .transform((_, resp) => CustomModelMapper.fromResp(resp));

export const customModelOptionSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
  })
  .camelCase();

export type PreviewImage = yup.InferType<typeof previewImageSchema>;
export type BriefCustomModel = yup.InferType<typeof briefCustomModelSchema>;
export type PaginatedBriefCustomModel = yup.InferType<
  typeof paginatedBriefCustomModelSchema
>;

export type CustomModelResp = yup.InferType<typeof customModelRespSchema>;
export type CustomModelVersionResp = yup.InferType<
  typeof customModelVersionRespSchema
>;

export type CustomModelOption = yup.InferType<typeof customModelOptionSchema>;

export type CustomModelSampleImage = {
  assetId: string;
  filename: string;
  updatedAt: number;
  contentType: string;
  isOnCVAT: boolean;
  url?: string;
};

export type CustomModelVersion = {
  tag: string;
  createdAt: Date;
  fields?: string[];

  isInTraining: boolean;
  isActive: boolean;
};

export type CustomModel = {
  id: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
  config: {
    fields: string[];
    frozenFields: string[];
    remark: string;
    trainingRequested: boolean;
    sampleImages: CustomModelSampleImage[];
    fxcmType: string;
  };
  CVATProjectID: string | null;
  formID: string | null;
  lastCVATProjectStartSyncAt: number | null;
  lastCVATProjectFinishSyncAt: number | null;
  startTrainingAt: number | null;
  finishTrainingAt: number | null;
  startDeploymentAt: number | null;
  finishDeploymentAt: number | null;
  modelVersions: CustomModelVersion[];
  resourceOwnerId: string;
  status: CustomModelStatus;
};

export const paginatedWithOffsetBriefCustomModelSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    customModels: yup.array(briefCustomModelSchema).defined(),
  })
  .camelCase();

export type PaginatedWithOffsetBriefCustomModel = yup.InferType<
  typeof paginatedWithOffsetBriefCustomModelSchema
>;
