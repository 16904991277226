import { AutoExtractionItem } from "../types/formConfig";

export const DEFAULT_AUTO_EXTRACTION_ITEM_MAP: {
  [key: string]: AutoExtractionItem[];
} = {
  receipt: [
    "total_amount",
    "date",
    "time",
    "invoice_number",
    "telephone",
    "product_info",
  ],
  bank_statement: ["name", "address", "issue_date"],
  address_proof: ["name", "address", "issue_date"],
  invoice: ["total_amount", "invoice_date", "invoice_number"],
  international_id: ["name_full", "date_birth"],
};

export const AUTO_EXTRACTION_ITEMS: {
  [key: string]: AutoExtractionItem[];
} = {
  general: [
    "date",
    "time",
    "name",
    "address",
    "company",
    "job_title",
    "detect_manipulation",
    "table_extraction",
    "detect_signature",
    "barcode",
  ],
  receipt: [
    "total_amount",
    "date",
    "short_date",
    "time",
    "address",
    "invoice_number",
    "telephone",
    "merchant_fax",
    "merchant_website",
    "store_number",
    "subtotal",
    "rounding",
    "total_discount",
    "enter_time",
    "leave_time",
    "num_person",
    "credit_card_type",
    "credit_card_no",
    "product_info",
    "payment_info",
    "merchant_name",
    "merchant",
    "detect_manipulation",
  ],
  bank_statement: ["name", "address", "issue_date", "detect_manipulation"],
  address_proof: [
    "name",
    "address",
    "issue_date",
    "ner_name",
    "ner_address",
    "date",
  ],
  invoice: [
    "invoice_number",
    "total_amount",
    "subtotal",
    "invoice_date",
    "merchant_website",
    "total_discount",
    "due_date",
    "bank_account_no",
    "bill_to_phone",
    "reference_no",
    "purchase_order_no",
    "table_extraction",
  ],
  international_id: [
    "address_code",
    "address_full",
    "code_document",
    "code_identity",
    "date_birth",
    "date_expiry",
    "date_issue",
    "gender",
    "issuing_authority",
    "name_full",
    "name_full_non_latin",
    "nationality",
    "place_of_birth",
    "detect_portrait",
  ],
};
