import { BriefForm } from "../form";
import {
  BriefFormGroup,
  DetailFormGroup,
  FormGroupAnchorBase,
  FormGroupAnchorRespBase,
  FormGroupTokenGroupBase,
  FormGroupTokenGroupRespBase,
} from "../formGroup";
import { TokenGroupImageMapper, TokenGroupTextMapper } from "./tokenGroup";

export const BriefFormGroupMapper = {
  fromDetail: (detailGroup: DetailFormGroup): BriefFormGroup => {
    const formsById: { [id: string]: BriefForm } = {};
    [...detailGroup.anchors, ...detailGroup.tokenGroups].forEach(
      groupOrAnchor => {
        formsById[groupOrAnchor.form.id] = groupOrAnchor.form;
      }
    );
    return {
      id: detailGroup.id,
      name: detailGroup.name,
      type: detailGroup.type,
      isTemplate: detailGroup.isTemplate,
      forms: Object.values(formsById),
      updatedAt: detailGroup.updatedAt,
    };
  },
};

export const FormGroupTokenGroupMapper = {
  toResp: (
    tokenGroup: FormGroupTokenGroupBase
  ): FormGroupTokenGroupRespBase => {
    return {
      id: tokenGroup.id,
      form_id: tokenGroup.formId,
      order: tokenGroup.order,
      match_mode: tokenGroup.matchMode,
      texts: TokenGroupTextMapper.toResp(tokenGroup.texts),
      images: TokenGroupImageMapper.toResp(tokenGroup.images),
    };
  },
};

export const FormGroupAnchorMapper = {
  toResp: (anchor: FormGroupAnchorBase): FormGroupAnchorRespBase => {
    return {
      id: anchor.id,
      form_id: anchor.formId,
    };
  },
};
