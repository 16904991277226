@import "../../styles/variables.scss";

.code-word {
  font-family: monospace;
  padding: 2px;
  background-color: $code-background;
}

div:first-child {
  margin-bottom: 12px;
}

.code-block {
  margin: 12px 24px;
}

.function-doc {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $tab-bar-separator;

  .function-title {
    margin-bottom: 8px;
  }
  .parameters-container {
    > p {
      font-size: 11pt;
      margin-bottom: 4px;
    }

    padding-top: 10px;

    .parameters {
      margin-right: 20px;
    }

    li {
      margin-left: 20px;
      line-height: 16pt;
      font-size: 10pt;

      .parameter-name {
        font-weight: bold;
      }

      .optional {
        margin-left: 6px;
        font-weight: normal;
        font-style: italic;
      }
    }
  }
}

.function-doc:first-child {
  padding-top: 0px;
}

.function-doc:last-child {
  border-bottom: 0;
}
