import * as React from "react";

import { useAdminActionCreator } from "../actions/admin";
import { ListTeamOptions } from "../apiClient/mixin/team";
import { getRegionKeys } from "../config";
import { UsageRange } from "../types/usage";

const REGION_KEYS = getRegionKeys();

export function useFetchAllRegionTeams(
  options?: ListTeamOptions,
  size: number = 20,
  offset: number = 0,
  initialRegion?: string
): {
  refetchRegionTeamsWithOffset: (regions: string, offset: number) => void;
} {
  const { listTeams } = useAdminActionCreator();
  React.useEffect(
    () => {
      for (const region of REGION_KEYS) {
        if (region === initialRegion) {
          listTeams(region, options, offset, size);
        } else {
          listTeams(region, options, 0, size);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, size]
  );

  const refetchRegionTeamsWithOffset = React.useCallback(
    (region, offset) => {
      listTeams(region, options, offset, size);
    },
    [listTeams, options, size]
  );

  return { refetchRegionTeamsWithOffset };
}

export function useFetchTeam(teamId: string, region: string) {
  const { getTeam } = useAdminActionCreator();
  React.useEffect(() => {
    getTeam(teamId, region);
  }, [getTeam, teamId, region]);
}

export function useFetchTeamMembers(teamId: string, region: string) {
  const { getTeamMembers } = useAdminActionCreator();
  React.useEffect(() => {
    getTeamMembers(teamId, region);
  }, [getTeamMembers, teamId, region]);
}

export function useFetchTeamUsage(
  range: UsageRange,
  teamId: string,
  region: string
) {
  const { getTeamUsage } = useAdminActionCreator();
  React.useEffect(() => {
    getTeamUsage(range, teamId, region);
  }, [getTeamUsage, range, teamId, region]);
}

export function useFetchTeamForms(
  size: number = 20,
  offset: number = 0,
  teamId: string,
  region: string
) {
  const { listTeamForms } = useAdminActionCreator();
  React.useEffect(() => {
    listTeamForms(teamId, region, offset, size);
  }, [listTeamForms, teamId, region, offset, size]);
}

export function useFetchTeamFormGroups(
  size: number = 20,
  offset: number = 0,
  teamId: string,
  region: string
) {
  const { listTeamFormGroups } = useAdminActionCreator();
  React.useEffect(() => {
    listTeamFormGroups(teamId, region, offset, size);
  }, [listTeamFormGroups, teamId, region, offset, size]);
}

export function useFetchTeamCustomModels(
  size: number = 20,
  offset: number = 0,
  teamId: string,
  region: string
) {
  const { listTeamCustomModels } = useAdminActionCreator();
  React.useEffect(() => {
    listTeamCustomModels(teamId, region, offset, size);
  }, [listTeamCustomModels, teamId, region, offset, size]);
}
