@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.team-detail {
  background-color: #fff;
  padding: 32px;

  .team-icon {
    @include no-select();
    font-size: 32px;
    line-height: 32px;
    height: 72px;
    width: 72px;
    align-self: start;
    background-color: $theme-primary;
    border-radius: 50%;
    color: #fff;
    padding: 20px;
  }

  .nav-bar {
    margin-top: 0;
  }

  .team-info {
    margin-top: 26px;
    display: flex;
  }

  .team-info-text-container {
    margin-left: 24px;
    font-weight: 400;
    font-size: 16px;
  }

  .team-info-detail-columns {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $light-gray-color;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
  }

  .team-info-detail-column:nth-child(even) {
    margin-left: 32px;
  }

  .tab-bar {
    margin-top: 20px;
  }
}
