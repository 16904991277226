import { Icon, Text } from "@fluentui/react";
import React from "react";
import { useSelector } from "react-redux";
import * as uuid from "uuid";

import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import {
  DetailFormGroup,
  FormGroupTokenGroup,
  FormGroupTokenGroupBase,
  FormGroupTokenGroupMatchMode,
} from "../../types/formGroup";
import { TokenGroupImage, TokenGroupText } from "../../types/tokenGroup";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import { FormGroupTokenGroupModal } from "./FormGroupTokenGroupModal";
import { FormGroupTokenGroupTableRow } from "./FormGroupTokenGroupTableRow";
import styles from "./styles.module.scss";

interface Props {
  formGroup: DetailFormGroup;
  onUpdateTokenGroups: (
    tokenGroups: FormGroupTokenGroupBase[]
  ) => Promise<void>;
}

export const FormGroupTokenGroupList = React.memo((props: Props) => {
  const { formGroup, onUpdateTokenGroups } = props;
  const { localized } = useLocale();

  const forms = useSelector((state: RootState) => state.formGroup.forms);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [isTokenGroupModalOpen, setIsTokenGroupModalOpen] =
    React.useState<boolean>(false);
  const [editingTokenGroup, setEditingTokenGroup] = React.useState<
    FormGroupTokenGroup | undefined
  >(undefined);

  const { hasPermissionToEditResource } = useTeamPermission();

  const onAddTokenGroup = React.useCallback(() => {
    setIsTokenGroupModalOpen(true);
    setEditingTokenGroup(undefined);
  }, []);

  const onEditTokenGroup = React.useCallback(
    (tokenGroup: FormGroupTokenGroup) => {
      setIsTokenGroupModalOpen(true);
      setEditingTokenGroup(tokenGroup);
    },
    []
  );

  const closeTokenGroupModal = React.useCallback(() => {
    setIsTokenGroupModalOpen(false);
    setEditingTokenGroup(undefined);
  }, []);

  const saveTokenGroup = React.useCallback(
    async (
      tokenGroupId: string | undefined,
      formId: string,
      matchMode: FormGroupTokenGroupMatchMode
    ) => {
      closeTokenGroupModal();
      const newGroups: FormGroupTokenGroupBase[] = [...formGroup.tokenGroups];
      if (tokenGroupId !== undefined) {
        const originalIndex = formGroup.tokenGroups.findIndex(
          group => group.id === tokenGroupId
        );
        if (originalIndex === -1) {
          return;
        }
        newGroups[originalIndex].formId = formId;
        newGroups[originalIndex].matchMode = matchMode;
      } else {
        const newTokenGroup: FormGroupTokenGroupBase = {
          id: uuid.v4(),
          formId,
          matchMode,
          order: newGroups.length,
          texts: [],
          images: [],
        };
        newGroups.push(newTokenGroup);
      }
      try {
        setIsLoading(true);
        await onUpdateTokenGroups(newGroups);
      } finally {
        setIsLoading(false);
      }
    },
    [closeTokenGroupModal, formGroup, onUpdateTokenGroups]
  );

  const onRemoveTokenGroup = React.useCallback(
    async (tokenGroupId: string) => {
      const newTokenGroups = formGroup.tokenGroups.filter(
        group => group.id !== tokenGroupId
      );
      try {
        setIsLoading(true);
        await onUpdateTokenGroups(newTokenGroups);
      } finally {
        setIsLoading(false);
      }
    },
    [formGroup, onUpdateTokenGroups]
  );

  const onMoveItem = React.useCallback(
    async (item: FormGroupTokenGroup, direction: 1 | -1) => {
      const originalIndex = formGroup.tokenGroups.findIndex(
        group => group.id === item.id
      );
      if (originalIndex === -1) {
        return;
      }
      const newIndex = originalIndex + direction;
      if (newIndex > formGroup.tokenGroups.length - 1 || newIndex < 0) {
        return;
      }
      const newGroups = [...formGroup.tokenGroups];
      newGroups.splice(originalIndex, 1);
      newGroups.splice(newIndex, 0, item);
      // Reassign the order number
      newGroups.forEach((group, index) => {
        group.order = index;
      });
      try {
        setIsLoading(true);
        await onUpdateTokenGroups(newGroups);
      } finally {
        setIsLoading(false);
      }
    },
    [formGroup, onUpdateTokenGroups]
  );

  const onUpdateTokenGroup = React.useCallback(
    (tokenGroup: FormGroupTokenGroupBase) => {
      const originalIndex = formGroup.tokenGroups.findIndex(
        group => group.id === tokenGroup.id
      );
      if (originalIndex === -1) {
        return;
      }
      const newGroups: FormGroupTokenGroupBase[] = [...formGroup.tokenGroups];
      newGroups[originalIndex] = tokenGroup;
      setIsLoading(true);
      onUpdateTokenGroups(newGroups)
        .finally(() => {
          setIsLoading(false);
        })
        .catch(e => {
          // onUpdateTokenGroups should handle all errors
          // We should fix onUpdateTokenGroups if we catch error here
          console.error("Update token group error: ", e);
        });
    },
    [onUpdateTokenGroups, formGroup]
  );

  const onDeleteTextToken = React.useCallback(
    (tokenGroup: FormGroupTokenGroupBase, textTokenId: string) => {
      const newTexts = tokenGroup.texts.filter(text => text.id !== textTokenId);
      const newGroup = { ...tokenGroup, texts: newTexts };
      onUpdateTokenGroup(newGroup);
    },
    [onUpdateTokenGroup]
  );

  const onDeleteImageToken = React.useCallback(
    (tokenGroup: FormGroupTokenGroupBase, imageTokenId: string) => {
      const newImages = tokenGroup.images.filter(
        image => image.id !== imageTokenId
      );
      const newGroup = { ...tokenGroup, images: newImages };
      onUpdateTokenGroup(newGroup);
    },
    [onUpdateTokenGroup]
  );

  const onUpsertTextToken = React.useCallback(
    (tokenGroup: FormGroupTokenGroupBase, textToken: TokenGroupText) => {
      const newTexts = [...tokenGroup.texts];
      const originalIndex = newTexts.findIndex(
        token => token.id === textToken.id
      );
      if (originalIndex === -1) {
        // Is add new
        newTexts.push(textToken);
      } else {
        // Is update existing
        newTexts[originalIndex] = textToken;
      }
      const newGroup: FormGroupTokenGroupBase = {
        ...tokenGroup,
        texts: newTexts,
      };
      onUpdateTokenGroup(newGroup);
    },
    [onUpdateTokenGroup]
  );

  const onUpsertImageToken = React.useCallback(
    (tokenGroup: FormGroupTokenGroupBase, imageToken: TokenGroupImage) => {
      const newImages = [...tokenGroup.images];
      const originalIndex = newImages.findIndex(
        token => token.id === imageToken.id
      );
      if (originalIndex === -1) {
        // Is add new
        newImages.push(imageToken);
      } else {
        // Is update existing
        newImages[originalIndex] = imageToken;
      }
      const newGroup: FormGroupTokenGroupBase = {
        ...tokenGroup,
        images: newImages,
      };
      onUpdateTokenGroup(newGroup);
    },
    [onUpdateTokenGroup]
  );

  const columnNames = React.useMemo((): string[] => {
    return [
      "form_group_token_group_editor.form",
      "form_group_token_group_editor.match_mode",
    ].concat(
      hasPermissionToEditResource
        ? ["", "form_group_token_group_editor.action"]
        : []
    );
  }, [hasPermissionToEditResource]);

  return (
    <div className={styles["form-group-form-list"]}>
      {hasPermissionToEditResource && (
        <div className={styles["add-new-area"]}>
          <div
            className={styles["add-new-form-button"]}
            onClick={onAddTokenGroup}
          >
            <Icon
              iconName="BuildQueueNew"
              className={styles["add-new-form-button-icon"]}
            />
            <Text>{localized("add.new.form")}</Text>
          </div>
        </div>
      )}
      <Table
        columnIds={columnNames}
        className={styles["form-group-table-no-hover"]}
      >
        {formGroup.tokenGroups.map(tokenGroup => (
          <FormGroupTokenGroupTableRow
            key={tokenGroup.id}
            tokenGroup={tokenGroup}
            onDeleteTextToken={onDeleteTextToken}
            onDeleteImageToken={onDeleteImageToken}
            onAddTextToken={onUpsertTextToken}
            onUpdateTextToken={onUpsertTextToken}
            onAddImageToken={onUpsertImageToken}
            onUpdateImageToken={onUpsertImageToken}
            onMoveItem={onMoveItem}
            onEditTokenGroup={onEditTokenGroup}
            onRemoveTokenGroup={onRemoveTokenGroup}
          />
        ))}
      </Table>

      {isLoading && (
        <div className={styles["loading-container"]}>
          <ShortSpinner />
        </div>
      )}
      {isTokenGroupModalOpen && (
        <FormGroupTokenGroupModal
          forms={forms}
          tokenGroup={editingTokenGroup}
          onCloseModal={closeTokenGroupModal}
          onSave={saveTokenGroup}
        />
      )}
    </div>
  );
});
