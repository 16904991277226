import { AppConfig } from "../config";
import { APIDescription } from "../models";

export const ExtractReceiptInfoAPIDescription: APIDescription = {
  name: "extract_receipt_info",
  method: "POST",
  endpoint: `${AppConfig.worker.endpoint}extract-receipt-info`,

  headerParams: [
    { name: "Content-Type", isOptional: true },
    { name: "X-WORKER-TOKEN" },
    { name: "X-WORKER-RECEIPT-GROUP-ID" },
    { name: "X-WORKER-IMAGE-URL", isOptional: true },
    { name: "X-WORKER-ENCODING", isOptional: true, defaultValue: "raw" },
    { name: "X-WORKER-PDF-DPI", isOptional: true, defaultValue: "150" },
  ],

  formParams: [
    { name: "receipt_group_id" },
    { name: "image", isOptional: true },
    { name: "image_url", isOptional: true },
    { name: "pdf_dpi", isOptional: true, defaultValue: "100" },
  ],
  responseFields: [
    { name: "status", fieldType: "string" },
    { name: "receipt_group_id", fieldType: "string" },
    { name: "amount", fieldType: "string" },
    { name: "date", fieldType: "string" },
    { name: "time", fieldType: "string" },
    { name: "tokens", fieldType: "string[]" },
    { name: "custom_fields", fieldType: "CustomField[]" },
    { name: "error", isOptional: true, fieldType: "any" },
  ],
  types: [
    {
      name: "CustomField",
      fields: [
        { name: "name", fieldType: "string" },
        { name: "value", fieldType: "string" },
      ],
    },
  ],
  cURLExample: `curl -X POST \\
${AppConfig.worker.endpoint}extract-receipt-info \\
-H 'Content-Type: image/jpeg' \\
-H 'X-WORKER-RECEIPT-GROUP-ID: REPLACE-YOUR-RECEIPT-GROUP-ID-HERE' \\
-H 'X-WORKER-TOKEN: REPLACE-YOUR-WORKER-TOKEN-HERE' \\
--data-binary "@/path/to/query/image.jpg"`,
};
