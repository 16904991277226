import { Values } from "@oursky/react-messageformat";

import { apiClient } from "../apiClient";
import { AuthGearAPIClient } from "../apiClient/authGear";
import { AppConfig } from "../config";
import { CONFIRM_MODAL_FADE_OUT_DELAY } from "../constants";
import errors, { FOCRError } from "../errors";
import { useThunkDispatch } from "../hooks/thunk";
import { Dispatch, RootState } from "../redux/types";
import { ConfirmModalType, ConfirmationTask } from "../types/confirmation";
import { Plan } from "../types/plan";
import { User } from "../types/user";
import { URLParamsKey, getParam, removeParam, setParam } from "../utils/params";
import { redirectToRegionForTeam } from "../utils/region";
import { checkIsPaymentRequired } from "./resourceOwner";
import { makeUserLoginAction } from "./user";

export const Initialized = "Initialized";
export interface InitializedAction {
  readonly type: typeof Initialized;
}

export interface AuthenticatedAction {
  readonly type: "Authenticated";
}

export const DisablePaymentRequiredToast = "DisablePaymentRequiredToast";
export interface DisablePaymentRequiredToastAction {
  readonly type: typeof DisablePaymentRequiredToast;
}

export const ToggleLeftBarCollapsed = "toggleLeftBarCollapsed";
export interface ToggleLeftBarCollapsedAction {
  readonly type: typeof ToggleLeftBarCollapsed;
  readonly value?: boolean;
}

export const UpgradeBarDismissed = "UpgradeBarDismissed";
export interface UpgradeBarDismissedAction {
  readonly type: typeof UpgradeBarDismissed;
}

export const GotPlanList = "GotPlanList";
export interface GotPlanListAction {
  readonly type: typeof GotPlanList;
  readonly payload: {
    plans: Plan[];
  };
}

export const GotInvitationCode = "GotInvitationCode";
export interface GotInvitationCodeAction {
  readonly type: "GotInvitationCode";
  readonly invitationCode: string;
}

export interface ShowConfirmModalAction {
  readonly type: "ShowConfirmModal";
  readonly task: ConfirmationTask;
}

export interface HideConfirmModalAction {
  readonly type: "HideConfirmModal";
  readonly cleanUpTimerId: number;
}

export interface CleanUpConfirmModalAction {
  readonly type: "CleanUpConfirmModal";
}

export function hideConfirmModal() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { comfirmModalCleanUpTimerId } = getState().app;
    if (comfirmModalCleanUpTimerId !== undefined) {
      clearTimeout(comfirmModalCleanUpTimerId);
    }

    const cleanUpTimerId = window.setTimeout(() => {
      dispatch({
        type: "CleanUpConfirmModal",
      });
    }, CONFIRM_MODAL_FADE_OUT_DELAY);

    dispatch({
      type: "HideConfirmModal",
      cleanUpTimerId,
    });
  };
}

export function requestUserConfirmation(
  options: {
    titleId: string;
    messageId: string;
    actionId: string;
    type: ConfirmModalType;
    messageValues?: Values;
  },
  shouldThrow = true
) {
  const { titleId, messageId, actionId, messageValues, type } = options;
  return async (dispatch: Dispatch, getState: () => RootState) => {
    return new Promise<boolean>(async (resolve, reject) => {
      dispatch({
        type: "ShowConfirmModal",
        task: {
          modalType: type,
          titleId,
          messageId,
          actionId,
          messageValues,
          onCancel: () => {
            hideConfirmModal()(dispatch, getState);
            if (shouldThrow) {
              reject(errors.ConfirmationRejected);
            } else {
              resolve(false);
            }
          },
          onConfirm: async () => {
            hideConfirmModal()(dispatch, getState);
            resolve(true);
          },
        },
      });
    });
  };
}

export function handleConflict<T>(
  fn: () => Promise<T>,
  fnForOverride: () => Promise<T>,
  options: {
    titleId: string;
    messageId: string;
    actionId: string;
    messageValues?: Values;
  }
) {
  return (dispatch: Dispatch, getState: () => RootState): Promise<T> => {
    const { titleId, messageId, actionId, messageValues } = options;

    return new Promise(async (resolve, reject) => {
      try {
        const result = await fn();
        resolve(result);
      } catch (e) {
        if (e instanceof FOCRError && e === errors.ConflictFound) {
          const { comfirmModalCleanUpTimerId } = getState().app;
          if (comfirmModalCleanUpTimerId !== undefined) {
            clearTimeout(comfirmModalCleanUpTimerId);
          }

          dispatch({
            type: "ShowConfirmModal",
            task: {
              modalType: ConfirmModalType.Save,
              titleId,
              messageId,
              actionId,
              messageValues,
              onCancel: () => {
                hideConfirmModal()(dispatch, getState);
                reject(e);
              },
              onConfirm: async () => {
                try {
                  const result = await fnForOverride();
                  resolve(result);
                } catch (e) {
                  reject(e);
                } finally {
                  hideConfirmModal()(dispatch, getState);
                }
              },
            },
          });
        } else {
          reject(e);
        }
      }
    });
  };
}

interface InitializeResult {
  invitationCode?: string;
}

async function redirectToRegionForTeamIfNeeded() {
  const teamLookupId = getParam(URLParamsKey.team);
  if (teamLookupId) {
    try {
      const { region } = await apiClient.lookupTeam(teamLookupId);
      if (region !== AppConfig.region) {
        redirectToRegionForTeam(region, teamLookupId);
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  return false;
}

function setTeamLookupIdInUrl(
  user: User,
  resourceOwnerId: string | undefined,
  isTeam: boolean
) {
  const teamLookupId = isTeam
    ? user.teams.find(team => team.id === resourceOwnerId)?.lookupId
    : null;

  if (teamLookupId) {
    setParam(URLParamsKey.team, teamLookupId, true);
  } else {
    removeParam(URLParamsKey.team, true);
  }
}

export function initialize() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<InitializeResult> => {
    let _invitationCode: string | undefined;
    let _isAuthenticated = false;
    let shouldDispatchInitialized = true;
    try {
      const { isAuthenticated, invitationCode, userInfo } =
        await apiClient.initialize();
      _invitationCode = invitationCode;
      _isAuthenticated = isAuthenticated;

      if (userInfo) {
        dispatch({ type: "GotUserInfo", userInfo });
      }

      if (invitationCode) {
        dispatch({ type: GotInvitationCode, invitationCode });
      }

      if (isAuthenticated) {
        if (await redirectToRegionForTeamIfNeeded()) {
          shouldDispatchInitialized = false;
          return {};
        }

        const user = await apiClient.getUser();
        const action = makeUserLoginAction(user);
        if (!window.location.pathname.startsWith("/admin")) {
          setTeamLookupIdInUrl(
            user,
            action.payload.resourceOwnerId,
            action.payload.isTeam
          );
        }
        dispatch(action);

        if (action.payload.resourceOwnerId)
          await checkIsPaymentRequired()(dispatch, getState);

        if (apiClient instanceof AuthGearAPIClient) {
          apiClient.onNoSession = () => {
            dispatch({ type: "UserLogout" });
          };
        }
      }
    } catch (e) {
      if (e !== errors.UserNotFound) {
        shouldDispatchInitialized = false;
        console.error(e);
      }
    } finally {
      if (_isAuthenticated) {
        dispatch({ type: "Authenticated" });
      }
      if (shouldDispatchInitialized) {
        dispatch({ type: Initialized });
      }
      return {
        invitationCode: _invitationCode,
      };
    }
  };
}

export function disablePaymentRequiredToast() {
  return (dispatch: Dispatch) => {
    dispatch({ type: DisablePaymentRequiredToast });
  };
}

export function toggleLeftBarCollapsed(value: boolean | undefined) {
  return (dispatch: Dispatch) => {
    dispatch({ type: ToggleLeftBarCollapsed, value });
  };
}

export function dismissUpgradeBar() {
  return (dispatch: Dispatch) => {
    dispatch({ type: UpgradeBarDismissed });
  };
}

export function fetchPlans() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    if (getState().app.plans.length > 0) {
      return Promise.resolve();
    }

    const plans = await apiClient.listPlan();

    dispatch({
      type: GotPlanList,
      payload: {
        plans: plans,
      },
    });
  };
}

export type AppAction =
  | InitializedAction
  | DisablePaymentRequiredToastAction
  | ToggleLeftBarCollapsedAction
  | UpgradeBarDismissedAction
  | GotPlanListAction
  | GotInvitationCodeAction
  | ShowConfirmModalAction
  | HideConfirmModalAction
  | CleanUpConfirmModalAction
  | AuthenticatedAction;

export function useAppActionCreator() {
  return {
    initialize: useThunkDispatch(initialize),
    disablePaymentRequiredToast: useThunkDispatch(disablePaymentRequiredToast),
    toggleLeftBarCollapsed: useThunkDispatch(toggleLeftBarCollapsed),
    dismissUpgradeBar: useThunkDispatch(dismissUpgradeBar),
    listPlan: useThunkDispatch(fetchPlans),
  };
}
