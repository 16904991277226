{
  "app.name": "FormExtractor",
  "app.toast.payment_required": "All extraction free quotas are used up. Please subscribe to our business plan",

  "signup.name": "Sign Up",
  "signup.have_account": "I already have an account.",

  "login.name": "Login",
  "login.no_account_yet": "I don't have an account yet.",

  "login_change_password_form.title": "Change Password",
  "login_change_password_form.submit_button": "Change Password",

  "form_grid.no_form_yet": "You don't have any form yet.",
  "form_grid.team_no_form_yet": "Your team doesn't have any form yet.",
  "form_grid.empty_state_tips": "Create one by clicking the button at the top bar.",
  "form_grid.delete_form": "Delete Form",
  "form_grid.are_you_sure_to_delete_form": "Are you sure to delete the selected form?",
  "form_grid.delete": "Delete",
  "form_grid.creating_form": "Creating form...",
  "form_grid.importing_form": "Importing form...",

  "form_group_grid.no_form_yet": "You don't have any form group yet.",
  "form_group_grid.team_no_form_yet": "Your team doesn't have any form group yet.",
  "form_group_grid.empty_state_tips": "Create one by clicking the button at the top bar.",
  "form_group_grid.creating_form_group": "Creating form group...",
  "form_group_grid.deleting_form_group": "Deleting form group...",
  "form_group_grid.delete_form": "Delete Form Group",
  "form_group_grid.are_you_sure_to_delete_form_group": "Are you sure to delete the selected form group?",
  "form_group_grid.delete": "Delete",

  "form_group_api.token_copied": "Access token is copied to clipboard",
  "form_group_api.id_copied": "Form Group ID is copied to clipboard",
  "form_group_api.copy_id": "Copy Form Group ID",
  "form_group_api.copy_token": "Copy Access Token",

  "form_group.modifed_prompt.title": "This form group has been modified",
  "form_group.modifed_prompt.desc": "This form group has been modified outside this tab since you open it. Are you sure you want to save this form group?",

  "receipt_group_list.no_receipt_yet": "You don't have any receipt group yet.",
  "receipt_group_list.empty_state_tips": "Create one by clicking the button at the top bar.",
  "receipt_group_list.delete_receipt_group": "delete receipt group",
  "receipt_group_list.are_you_sure_to_delete_receipt_group": "Are you sure to delete selected receipt group?",
  "receipt_group_list.delete": "Delete",
  "receipt_group_list.creating_receipt_group": "Creating receipt group...",
  "receipt_group_list.renaming_receipt_group": "Renaming receipt group...",
  "receipt_group_list.adding_token": "Adding token...",

  "receipt_group_list_item.edit": "Edit",
  "receipt_group_list_item.test": "Test",
  "receipt_group_list_item.api": "API",
  "receipt_group_list_item.id": "ID",

  "receipt_group.modified_prompt.title": "This receipt group has been modified",
  "receipt_group.modified_prompt.desc": "This receipt group has been modified outside this tab since you open it. Are you sure you want to save it?",

  "rename_receipt_group.title": "Rename receipt group",
  "rename_receipt_group.receipt_group_name": "Receipt Group Name",
  "rename_receipt_group.rename": "Rename",

  "rename_form_group.title": "Rename Form Group",
  "rename_form_group.form_group_name": "Form Group Name",
  "rename_form_group.rename": "Rename",

  "left_bar.templates": "Templates",
  "left_bar.forms": "Forms",
  "left_bar.form_list": "Form List",
  "left_bar.form_group_list": "Form Group List",
  "left_bar.custom_model": "Custom Model",
  "left_bar.custom_model_list": "Custom Model List",
  "left_bar.promotion_box.message": "Free plan users can use up to 100 API calls. Upgrade your plan to get unlimited usage.",
  "left_bar.promotion_box.upgrade_now": "Upgrade Now",
  "left_bar.integrations": "Integrations",
  "left_bar.webhooks": "Webhooks",
  "left_bar.powered_by_formx": "Powered by FormX.ai",
  "left_bar.detect_documents": "Detect Documents",
  "left_bar.teams": "Teams",

  "labeller.token_copied": "Token is copied to clipboard",

  "form_template.header_prefix": "Template - {name}",
  "form_template.business_registration": "Business Registration",
  "form_template.travel_agency_chinese": "Travel Agency Chinese",
  "form_template.travel_agency_english": "Travel Agency English",
  "form_template.food_license": "Food License",
  "form_template.hawker_license": "Hawker License",
  "form_template.hkid_new": "Hong Kong ID (New)",
  "form_template.hkid_old": "Hong Kong ID (Old)",
  "form_template.hkid": "Hong Kong ID",
  "form_template.twid": "Taiwan ID",
  "form_template.twid_front": "Taiwan ID (Front)",
  "form_template.twid_back": "Taiwan ID (Back)",
  "form_template.moid": "Macau ID",
  "form_template.sgid": "Singapore ID",
  "form_template.passport": "Passport",
  "form_template.intl_id": "International ID/Passport",
  "form_template.intl_id_fallback_form": "International ID/Passport",
  "form_template.intl_id_moid": "International ID (Macau ID)",
  "form_template.intl_id_hkid_old": "International ID (Old Hong Kong ID)",
  "form_template.intl_id_hkid_new": "International ID (Hong Kong ID)",
  "form_template.intl_id_twid_front": "International ID (Taiwan ID Front)",
  "form_template.intl_id_twid_back": "International ID (Taiwan ID Back)",

  "form_template.desc.intl_id": "See the list of supported countries",

  "form_inspector.form_settings": "Form Settings",
  "form_inspector.anchor_settings": "Anchor Settings",
  "form_inspector.field_settings": "Field Settings",
  "form_inspector.detection_region_settings": "Detection Region Settings",
  "form_inspector.get_token": "Copy Token",
  "form_inspector.form_name": "Name",
  "form_inspector.form_id": "ID",
  "form_inspector.num_form_feature": "# Form Features",
  "form_inspector.num_query_feature": "# Query Features",
  "form_inspector.enable": "Enable",
  "form_inspector.label": "Label",
  "form_inspector.languages": "Languages",
  "form_inspector.tesseract.psm": "Page segmentation modes",
  "form_inspector.tesseract.preset": "Preset",
  "form_inspector.azure.mode": "Mode",
  "form_inspector.google.mode": "Mode",
  "form_inspector.recognizer": "Recognizer",
  "form_inspector.document_type": "Document Type",

  "form_inspector.add_new_field": "Add New Field",
  "form_inspector.add_extraction_field": "Add Extraction Field",
  "form_inspector.choose_extraction_type": "Choose Extraction Type",
  "form_inspector.choose_extraction_type_warning": "Please choose an extraction type",
  "form_inspector.delete": "Delete",
  "form_inspector.delete_region": "Delete Region",
  "form_inspector.delete_anchor": "Delete Anchor",
  "form_inspector.settings": "Settings",
  "form_inspector.view_settings": "View Settings",
  "form_inspector.detection_region_field_advanced_settings": "Advanced Settings",
  "form_inspector.detection_region_type": "Type",
  "form_inspector.detection_region_field_engine": "Engine",
  "form_inspector.detection_region_field_api": "API",
  "form_inspector.detection_region_field_edit_script": "Edit Script",
  "form_inspector.detection_region_field_edit": "Edit",
  "form_inspector.detection_region_field_extract_string_between_words": "Extract string between words",
  "form_inspector.detection_region_field_extract_string_between_words.desc": "You can highlight a large text area (such as a paragraph of text) and extract string between words.\n\nFor example, if your document contains \"The total amount of the bill is $521 dollars\", you can use {between} with \"the bill is\" as {startMark} and \"dollars\" as {endMark}.",
  "form_inspector.detection_region_field.error.field_label_unique": "Field label has to be unique",
  "form_inspector.detection_region_field.error.field_label_empty": "Field label cannot be empty",

  "form_inspector.detection_region_field_text_in_paragraph_type": "Type",
  "form_inspector.detection_region_field_text_in_paragraph_type.before": "Before",
  "form_inspector.detection_region_field_text_in_paragraph_type.after": "After",
  "form_inspector.detection_region_field_text_in_paragraph_mark.start": "Start Mark",
  "form_inspector.detection_region_field_text_in_paragraph_mark.end": "End Mark",
  "form_inspector.detection_region_field_text_in_paragraph_type.between": "Between",
  "form_inspector.detection_region_field_text_in_paragraph_fuzzy_match": "Fuzzy Search",
  "form_inspector.detection_region_field_text_in_paragraph_ignore_whitespace": "Ignore Whitespaces",

  "form_inspector.detection_region_field_script_editor.js_io_tab_title": "JavaScript I/O",
  "form_inspector.detection_region_field_script_editor.function_tab_title": "Available Functions",
  "form_inspector.detection_region_field_script_editor.hide_tab_title": "Hide",

  "form_inspector.detection_region_field_text_setting_editor.title": "Text Settings",
  "form_inspector.detection_region_field_text_setting_editor.desc": "Please select a text type.",
  "form_inspector.detection_region_field_text_setting_editor.remove_watermark": "Remove watermark in the background",

  "form_inspector.detection_region_field_date_time_setting_editor.title": "Date/Time Settings",
  "form_inspector.detection_region_field_date_time_setting_editor.desc": "Detect a certain date/time exists in the document anywhere.",
  "form_inspector.detection_region_field_date_time_setting_editor.date_option": "Date",
  "form_inspector.detection_region_field_date_time_setting_editor.date_fornat_option": "Format",
  "form_inspector.detection_region_field_date_time_setting_editor.time_option": "Time",
  "form_inspector.detection_region_field_date_time_setting_editor.option_title": "Select to detect",

  "form_inspector.text": "Text",
  "form_inspector.date_time": "Date/Time",

  "form_inspector.single_line_eng": "Single Line English",
  "form_inspector.single_line_chi": "Single Line Chinese",
  "form_inspector.single_line_eng_chi": "Single Line English and Chinese",
  "form_inspector.block_eng": "Block of English",
  "form_inspector.block_chi": "Block of Chinese",
  "form_inspector.block_eng_chi": "Block of English and Chinese",
  "form_inspector.handwritten_eng": "Handwritten English",
  "form_inspector.handwritten_chi": "Handwritten Chinese",
  "form_inspector.handwritten_eng_chi": "Handwritten English and Chinese",
  "form_inspector.barcode": "Barcode / QR code",
  "form_inspector.checkbox": "Checkbox",
  "form_inspector.date": "Date",
  "form_inspector.time": "Time",
  "form_inspector.number": "Number",
  "form_inspector.script": "Script",
  "form_inspector.name": "Name",
  "form_inspector.address": "Hong Kong Address",
  "form_inspector.total_amount": "Total Amount",
  "form_inspector.text_in_paragraph": "Text in paragraph",
  "form_inspector.table": "Table",

  "form_inspector.document_field.type": "Type",
  "form_inspector.document_field.field_name": "Field Name",
  "form_inspector.document_field.key_value_type": "Key-Value",
  "form_inspector.document_field.text_token_group_type": "Text Tokens",
  "form_inspector.document_field.image_token_group_type": "Image Tokens",
  "form_inspector.document_field.error.field_name_unique": "Field name has to be unique",
  "form_inspector.document_field.error.field_name_empty": "Field name cannot be empty",

  "field_list.label": "Label",
  "field_list.recognizer": "Recognizer",
  "field_list.setting": "Setting",
  "field_list.languages": "Languages",
  "field_list.config": "Config",
  "field_list.preset": "Preset",
  "field_list.none": "None",
  "field_list.azure.mode": "Mode",
  "field_list.google.mode": "Mode",
  "field_list.empty": "You have not define any field for this form yet.",

  "form_group_editor.name": "Name",
  "form_group_editor.rename": "Rename",
  "form_group_editor.edit_post_process_script": "Edit Post Processing Script",
  "form_group_editor.forms": "Forms",
  "form_group_anchor_editor.form": "Form",
  "form_group_anchor_editor.action": "Action",
  "form_group_anchor_modal.title.add": "Add Form",
  "form_group_anchor_modal.title.edit": "Edit Form",
  "form_group_anchor_modal.select_a_form": "Select a form",
  "form_group_anchor_modal.description": "Only forms with anchors are selectable",
  "form_group_anchor_modal.please_select_a_form": "Please select a form",
  "form_group_anchor_modal.no_anchor": "No Anchor",
  "form_group_token_group_modal.title.add": "Add Form",
  "form_group_token_group_modal.title.edit": "Edit Form",
  "form_group_token_group_modal.select_a_form": "Select a form",
  "form_group_token_group_modal.please_select_a_form": "Please select a form",
  "form_group_token_group_modal.match_mode": "Match mode",
  "form_group_token_group_modal.match_mode.all": "Match ALL tokens\n\nFormX choose this form if and only if all the tokens could be found on the uploaded document",
  "form_group_token_group_modal.match_mode.any": "Match any of the tokens\n\nFormX choose this form if any of the token found on the uploaded document. If multiple of forms share the same token, the one with higher order will be selected",
  "form_group_token_group_editor.form": "Form",
  "form_group_token_group_editor.match_mode": "Match Mode",
  "form_group_token_group_editor.match_mode.all": "All",
  "form_group_token_group_editor.match_mode.any": "Any",
  "form_group_token_group_editor.action": "Action",

  "receipt_group_editor.name": "Name",
  "receipt_group_editor.tokens": "Tokens",
  "receipt_group_editor.token_groups": "Token Groups",
  "receipt_group_editor.custom_fields": "Custom Fields",
  "receipt_group_editor.key_values": "Key Values",
  "receipt_group_editor.rename": "Rename",
  "receipt_group_editor.add_token": "Add token",
  "receipt_group_editor.add_custom_field": "Add custom field",
  "receipt_group_editor.tokens_description_1": "Token is short text pattern that you want to detect from a receipt.",
  "receipt_group_editor.tokens_description_2": "By defining appropriate tokens, you could tag receipt with the shop name, address, payment method, etc.",
  "receipt_group_editor.custom_fields_description_1": "Custom field is a pattern of value near (above, below, on the left or right of) a token.",
  "receipt_group_editor.custom_fields_description_2": "By defining appropriate custom fields, you could extract extra information like account number from the receipt.",
  "receipt_group_editor.no_tokens": "You have not defined any token yet.",
  "receipt_group_editor.no_custom_field": "You have not defined any custom field yet.",
  "receipt_group_editor.no_token": "You have not defined any token yet.",

  "ocr_test.choose": "Choose Image",
  "ocr_test.upload_another_file": "Upload another file",
  "ocr_test.no_image": "Upload image for information extraction",
  "ocr_test.extracting": "Extracting...",
  "ocr_test.warped_image": "Warped Image",
  "ocr_test.field_id": "ID",
  "ocr_test.field_content": "Content",
  "ocr_test.field_error": "Error",
  "ocr_test.text_tokens": "Text Tokens",
  "ocr_test.image_tokens": "Image Tokens",
  "ocr_test.jump_to_json": "Jump to JSON",
  "ocr_test.json": "JSON Output",
  "ocr_test.extract_error": "There is an error extracting data from this page",
  "ocr_test.extract_error.reason": "Reason: {message}",
  "ocr_test.multi_document.indicator": "Uploading files with multiple documents.",
  "ocr_test.try_sample": "Test with examples",
  "ocr_test.badge.field": "FIELD",
  "ocr_test.badge.kv": "K/V",
  "ocr_test.badge.token": "TOKEN",
  "ocr_test.badge.auto": "AUTO",

  "ocr_test_table.header.type": "Type",
  "ocr_test_table.header.name": "Name",
  "ocr_test_table.header.value": "Value",
  "ocr_test_table.header.confidence": "Confidence",
  "ocr_test_table.header.image": "Image",
  "ocr_test_table.type.field": "Field",
  "ocr_test_table.type.key_value": "Key Value",
  "ocr_test_table.type.auto": "Auto",
  "ocr_test_table.type.token": "Token",
  "ocr_test_table.type.custom_model": "Custom Model",

  "detect_documents_test.choose": "Choose Image",
  "detect_documents_test.upload_another_file": "Upload another file",
  "detect_documents_test.detecting": "Detecting...",
  "detect_documents_test.json": "JSON Output",
  "detect_documents_test.warped_image": "Warped Image",
  "detect_documents_test.no_image": "Upload Image for document detection",

  "detect_documents_test_table.header.type": "Type",
  "detect_documents_test_table.header.bbox": "Bounding Box",
  "detect_documents_test_table.header.type_score": "Confidence (Type)",
  "detect_documents_test_table.header.bbox_score": "Confidence (BBox)",

  "batch.batch_extraction_using_desktop_app": "Batch extraction using Desktop App",
  "batch.introduction": "Form Extractor has a companion desktop app for you to do batch extraction with just a few clicks. To do batch extraction, please following steps below.",
  "batch.step_download": "Download the desktop app according to your platform below, install and open it.",
  "batch.step_open": "Click the \"Open in Desktop App\" button in the top right corner of this page. Desktop App should open with form ID and access token being filled, click \"Submit\".",
  "batch.first_time_open": "Your browser may ask you whether you want to open the desktop app or not, check \"Always allow\" and click \"Open\".",
  "batch.manual_fill_in": "If for any reason, the form ID and access token are not filled in the desktop app, you can fill them in manually. You could copy them by clicking the corresponding buttons on the top right corner of this page.",
  "batch.step_extract": "Select or simply drop those images file you want to do extraction, click \"Extract\".",
  "batch.step_result": "Wait a while for the extraction to complete, then the desktop app will ask you where to save the result.",
  "batch.download": "Download Desktop App",
  "batch.macos_app": "Download for macOS",
  "batch.window_app": "Download for Windows",

  "receipt_test.no_image": "Upload image for OCR",
  "receipt_test.recognizing": "Recognizing...",
  "receipt_test.amount": "Amount",
  "receipt_test.date": "Date",
  "receipt_test.time": "Time",
  "receipt_test.tokens": "Tokens",
  "receipt_test.custom_fields": "Custom Fields",
  "receipt_test.try_sample": "You may want to try following receipts as well.",

  "create_form.title": "Create a New Form",
  "create_form.form_name": "Form Name",
  "create_form.create": "Create",
  "create_form.template_image": "Master Image",

  "create_document_extractor.title": "Create a New Document Extractor",
  "create_document_extractor.session_fixed_format.title": "My Documents have a fixed format",
  "create_document_extractor.session_fixed_format.description": "For document with a fixed format, such as form or government licenses, you will need to upload a master image, which should be cleared and from a scanner. You will create different region / anchor to extract information accurately. {readMore}",

  "create_document_extractor.session_non_fixed_format.title": "My Documents don’t have a fixed format",
  "create_document_extractor.session_non_fixed_format.description": "For document without a fixed format, such as letter, or some simple tables, key/value pair. You can extract information by {keyValueLink}, detect text/ logos, or extract entity such as Name, Address, etc. You don’t need a master image.",

  "create_document_extractor.session_diff_layout_format.title": "My Document have different layout",
  "create_document_extractor.session_diff_layout_format.description": "If the type of document you need to extract information on have different layouts, such as receipts, academic transcript, which various by where the document came from. You may explirer our templates. If it is not available yet, we can train a custom AI models with around 15-50 samples provided from you.",

  "create_document_extractor.link.key_value": "Key/value",
  "create_document_extractor.link.read_more": "Read more",

  "create_ai_form.title": "Create an AI Model for my document",
  "create_ai_form.description": "For now Custom AI model is available for customers with Professional Plan only, please let us know the following information and we will contact you for details as soon as possible.",

  "create_ai_form.question1.title": "1. What type of the document",
  "create_ai_form.question1.placeholder": "Input document type here",
  "create_ai_form.question1.error.empty": "Type of document cannot be empty",
  "create_ai_form.question1.error.reach_limit": "Number of characters reached limit",

  "create_ai_form.question2.title": "2. How many sample image you have",
  "create_ai_form.question2.placeholder": "Input numbers here",
  "create_ai_form.question2.error.empty": "Numbers cannot be empty",

  "create_ai_form.question3.title": "3. What information you will need",
  "create_ai_form.question3.placeholder": "Input the inforamtion here",
  "create_ai_form.question3.error.empty": "Information cannot be empty",

  "create_ai_form.question4.title": "4. Your contact email / number",
  "create_ai_form.question4.placeholder": "Input email address or contact number",
  "create_ai_form.question4.error.empty": "Contact email / number of document cannot be empty",
  "create_ai_form.question4.error.invalid_format": "Invalid contact email / number",

  "create_ai_form.submitting": "Submitting...",
  "create_ai_form.error.fail_to_submit_form": "Failed to submit form",
  "create_ai_form.info.submitted_form": "Thanks for interested in using our Custom AI model and we will contact you for details as soon as possible.",

  "create_form_group.title": "Create a New Form Group",
  "create_form_group.form_group_name": "Form Group Name",
  "create_form_group.form_group_mode": "Mode",
  "create_form_group.form_group_mode.anchor": "By Anchor\n\nAnchor defines appearance for a document. FormX.ai determines the form to be used according to similarity of appearance",
  "create_form_group.form_group_mode.token": "By Tokens\n\nDefine a list of tokens (could be text or image) for each forms in this form group. FormX.ai determines the form to be used according to the token(s) recognised on the document",
  "create_form_group.create": "Create",

  "create_custom_model_dialog.title": "Create a New Custom Model",
  "create_custom_model_dialog.textfield.label": "Custom Model Name",
  "create_custom_model_dialog.my_custom_extractor": "My Custom Extractors",
  "custom_model_editor.upload_image.desc.title": "Upload Sample Files",
  "custom_model_editor.upload_image.desc.subtitle": "Show us some samples and we will extract the information for you",
  "custom_model_editor.upload_image.center.title": "Start with 10 images. The more images you add, the better the results.",
  "custom_model_editor.upload_image.center.subtitle": "Drag and drop or {link} to add files",
  "custom_model_editor.upload_image.center.subtitle.link": "click here",
  "custom_model_editor.upload_image.empty": "No sample image has uploaded yet.",
  "custom_model_editor.image_grid.title": "Sample Files",
  "custom_model_editor.image_grid.subtitle": "Show us some samples and we will extract the information for you",
  "custom_model_editor.image_grid.item.upload_at": "Upload At {date}",
  "custom_model_editor.upload_files_button": "Upload Files",
  "custom_model_editor.upload_files_button_tip": "Upload {count} more images to create a more accurate model",

  "custom_model_editor.right_bar.title": "Customize Information To Extract",
  "custom_model_editor.right_bar.desc": "Tell us what information do you want to extract. It can be identification numbers, names, address, amounts, etc.",
  "custom_model_editor.right_bar.subtitle": "Type information name and press Enter",
  "custom_model_editor.right_bar.add_new_info_button": "Add New Information",
  "custom_model_editor.right_bar.request_training_button": "Request Initial Training",
  "custom_model_editor.right_bar.update_training_button": "Update Training Request",
  "custom_model_editor.right_bar.text_field.placeholder": "e.g. Customer name, ID number, address",
  "custom_model_editor.right_bar.text_field.error": "Please fill in the information",
  "custom_model_editor.right_bar.next_step": "Next Step",
  "custom_model_editor.right_bar.tab.labels": "Labels",
  "custom_model_editor.right_bar.label.desc": "Specify the data to extract from your documents. Then, prepare your model for training by labelling your samples using our internal tool, CVAT.",
  "custom_model_editor.right_bar.label.subtitle": "Input label name and press Enter",
  "custom_model_editor.right_bar.label.subtitle.readonly": "Label names",
  "custom_model_editor.right_bar.label.placeholder": "e.g. name, id_number, home_address",
  "custom_model_editor.right_bar.label.error.empty": "Please fill in the information",
  "custom_model_editor.right_bar.label.error.invalid_format": "Invalid format, only allow a-z, 0-9 and _",
  "custom_model_editor.right_bar.add_new_label_button": "Add New Label",
  "custom_model_editor.custom_model_is_created": "Custom model is created.",
  "custom_model_editor.custom_model_is_saved": "Custom model is saved.",

  "custom_model_editor.remark_dialog.title": "Training Custom Model for Extraction Is In Limited Preview",
  "custom_model_editor.remark_dialog.desc": "You’re in the waiting list for this feature. Please tell us more about your use cases and we will contact you and prioritise this feature for you.",
  "custom_model_editor.remark_dialog.placeholder": "Enter any remarks about your documents and information to extract",

  "custom_model_editor.rename_dialog.title": "Rename the custom model",
  "custom_model_editor.rename_dialog.label": "Custom Model Name",

  "custom_model_editor.modifed_prompt.title": "This custom model has been modified",
  "custom_model_editor.modifed_prompt.desc": "This custom model has been modified outside this tab since you open it. Are you sure you want to save it?",

  "custom_model_grid.custom_model_label": "Custom Model",
  "custom_model_grid.new_custom_model_button": "Add New Custom Model",
  "custom_model_grid.delete_files_button": "Delete Files",
  "custom_model_grid.delete_custom_model": "Custom Model",
  "custom_model_grid.custom_model_is_deleted": "Custom Model is deleted",
  "custom_model_grid.are_you_sure_to_delete_form_group": "Are you sure to delete the selected custom model?",
  "custom_model_grid.delete": "Delete",

  "custom_model_grid.no_model.title": "Create a Custom Model",
  "custom_model_grid.team_no_model.title": "Your team doesn't have any custom model yet.",
  "custom_model_grid.no_model.desc": "A custom model allows you to extract information if your document has various layout",
  "custom_model_grid.no_model.button": "Create a New Custom Model",

  "custom_model_label.title": "Prepare your model for training",
  "custom_model_label.sync.title": "Sync your samples and labels for labelling",
  "custom_model_label.sync.desc": "Once you have uploaded your samples and set up all your labels and tables, you can sync them to CVAT, our internal tool for data labelling.",
  "custom_model_label.sync.desc2": "{highlighted}, you will have to sync and label again before re-training your model.",
  "custom_model_label.sync.desc2.highlighted": "If you added new sample files or labels",
  "custom_model_label.sync.button": "Sync with CVAT",
  "custom_model_label.sync.button.in_progress": "Sync in Progress",
  "custom_model_label.sync.error": "Failed to trigger sync with CVAT, please try again later",
  "custom_model_label.label.title": "Label all your samples",
  "custom_model_label.label.desc": "Show us where to extract your information in your sample files by labelling all fields on the images. The document area should be labelled as {documentLayout}.",
  "custom_model_label.label.desc2": "Make sure to label all your samples before training the model.",
  "custom_model_label.label.button": "Start labelling in CVAT",
  "custom_model_label.train.title": "Train your model",
  "custom_model_label.train.desc": "Once you have labelled all your samples, you can train your model and use it to extract information from your documents, or in other forms you have created in FormX.",
  "custom_model_label.train.button": "Train model",
  "custom_model_label.train.button.in_progress": "Train in Progress",
  "custom_model_label.train.error": "Failed to trigger model training, please try again later",

  "custom_model_model.model_version": "Model Version",
  "custom_model_model.created_at": "Created At",
  "custom_model_model.labels": "Labels",
  "custom_model_model.actions": "Actions",
  "custom_model_model.button.model_training": "Model training",
  "custom_model_model.button.active_model": "Active Model",
  "custom_model_model.button.set_active": "Set as active",
  "custom_model_model.button.deploying": "Deploying",
  "custom_model_model.note1": "You can use this custom model in other forms you have created to extract information according to the labels.",
  "custom_model_model.note2": "To use the model in other forms, set the version of the model you want to use as “Active”, and then select this custom model in the Settings tab of the other form.",
  "custom_model_model.deploy.error": "Failed to deploy the custom model, please try again later. If the issue presist, please contact us at support@formextractorai.com",

  "custom_model_test.warn.deployment_in_progress": "Model deployment is in progress, the extraction result might not be available.",
  "custom_model_test.warn.no_deployed_model": "No model verison is deployed for this custom model, the custom model extraction result will not be available.",

  "merchant_list_settings_modal.title": "Merchant Settings",
  "merchant_list_settings_modal.merchants_count": "{count}/{limit} Merchants",
  "merchant_list_settings_modal.desc1": "FormX matches the merchant name extracted with a database. To improve the accuracy of your specific use case, provide a list of merchant names in the text box below. Type in a new line for each merchant name.",
  "merchant_list_settings_modal.textfield.placeholder": "One merchant name per new line.",

  "merchant_settings_modal.title": "Merchant Post-processing Settings",
  "merchant_settings_modal.desc1": "This feature enrich the original Merchant Name extraction. You can add rules with additional information to search for a specific merchant and transform the result into an ID that be mapped into your CRM system.",
  "merchant_settings_modal.desc2": "Tell us the information that is unique to the merchant, such as its name, phone number, URL, etc. When those information is detected, FormX will return the merchant ID matched with those information.",
  "merchant_settings_modal.desc2_highlighted": "unique",
  "merchant_settings_modal.search_placeholder": "Search by merchant info",

  "merchant_settings_modal.sort_by_alphabet": "A to Z",
  "merchant_settings_modal.sort_by_alphabet_reversed": "Z to A",
  "merchant_settings_modal.sort_by_earlier_updated": "Earliest Modified",
  "merchant_settings_modal.sort_by_latest_modified": "Latest Modified",
  "merchant_settings_modal.sort_placeholder": "Sort by...",

  "merchant_settings_modal.list_placeholder1": "Add custom merchant",
  "merchant_settings_modal.list_placeholder2": "OR",
  "merchant_settings_modal.list_placeholder3": "Add custom rules to extract merchant",

  "merchant_settings_modal.list_filter_empty": "We couldn’t find any result for “{filter}”",

  "merchant_settings_modal.list.default_merchant_name": "Merchant Name",
  "merchant_settings_modal.list.more_info": " + {count} More Info",

  "merchant_settings_modal.match_all_info": "Match ALL info",
  "merchant_settings_modal.match_one_info": "Match ONE info only",

  "merchant_settings_modal.form.merchant_id": "Merchant ID",
  "merchant_settings_modal.form.tips": "Make sure you fill in at least one merchant’s information",

  "merchant_settings_modal.form.match_one": "Match ANY of the following info",
  "merchant_settings_modal.form.match_all": "Match ALL filled info",

  "merchant_settings_modal.form.type_header": "Type of info",
  "merchant_settings_modal.form.info_header": "Info to match",
  "merchant_settings_modal.form.exact_match": "Exact match",
  "merchant_settings_modal.form.action": "Action",

  "merchant_settings_modal.field.brand_name": "Brand Name",
  "merchant_settings_modal.field.phone_number": "Phone Number",
  "merchant_settings_modal.field.fax_number": "Fax Number",
  "merchant_settings_modal.field.url": "URL",
  "merchant_settings_modal.field.merchant_name": "Merchant Name",

  "merchant_settings_modal.field.brand_name_placeholder": "e.g. Tiffany & Co",
  "merchant_settings_modal.field.merchant_name_placeholder": "e.g. Tiffany & Co",
  "merchant_settings_modal.field.phone_number_placeholder": "e.g. +852 28765432",
  "merchant_settings_modal.field.fax_number_placeholder": "e.g. (852)28765432",
  "merchant_settings_modal.field.url_placeholder": "e.g. www.apple.com",

  "merchant_settings_modal.add_new_information": "+ Add New Information",

  "merchant_settings_modal.error.merchant_missing": "Merchant Name Required",
  "merchant_settings_modal.error.fill_at_least_one_info": "Need to specify at least 1 info",
  "merchant_settings_modal.error.unable_to_save": "Unable to save settings: some custom merchant names are missing required information.",

  "import_form_group.title": "Import Form Group",
  "import_form_group.use_existing_forms": "Use Existing Forms",
  "import_form_group.use_existing_forms.desc": "If any existing forms were duplicated with those you are uploading, by checking this checkbox, those existing forms will be used instead of duplicating a new set for this group.",

  "create_receipt_group.title": "Create a New Receipt Group",
  "create_receipt_group.receipt_group_name": "Receipt Group Name",
  "create_receipt_group.create": "Create",

  "add_token.title": "Add token",
  "add_token.token_value": "Enter token value here",
  "add_token.add": "add",

  "header.admin": "Admin",
  "header.back_to_client": "Back to client portal",
  "header.editor": "Editor",
  "header.field_list": "Field List",
  "header.ocr_test": "Test",
  "header.ocr_api": "API",
  "header.batch": "Batch",
  "header.receipt_edit": "Edit",
  "header.receipt_test": "Test",
  "header.receipt_api": "API",
  "header.edit": "Edit",
  "header.setup": "Set up",
  "header.test": "Test",
  "header.api": "API",
  "header.label": "Label",
  "header.model": "Model",
  "header.receipt_batch": "Batch",
  "header.forms": "Forms",
  "header.form_groups": "Form Groups",
  "header.custom_models": "Custom Models",
  "header.receipts": "Receipts",
  "header.usage": "Usage",
  "header.setting": "Setting",
  "header.change_password": "Change Password",
  "header.logout": "Logout",
  "header.payment": "Payment",
  "header.user_setting": "User Setting",
  "header.personal": "Personal",
  "header.new_team": "New Team",
  "header.manage_team": "Manage Team",
  "header.sign_out": "Sign out",
  "header.detect_documents": "Detect Documents",
  "header.audit": "Audit Log",

  "change_password.title": "Change Password",
  "change_password.old_password": "Old Password",
  "change_password.new_password": "New Password",
  "change_password.verify_new_password": "Confirm New Password",
  "change_password.submit": "Submit",

  "new_team.title": "Create a New Team Account",
  "new_team.team_name": "Team Name",
  "new_team.team_region": "Server Location",
  "new_team.team_region.desc": "Server location specifies where your data is stored and processed. It cannot be changed once set.",

  "confirm.modal.challenge": "Please type ‘{challenge}’ to confirm.",

  "common.email": "Email",
  "common.email.placeholder": "Enter Email here",
  "common.email.empty": "Email cannot be empty",
  "common.username": "Username",
  "common.username.placeholder": "Enter Username here",
  "common.username.empty": "Username cannot be empty",
  "common.password": "Password",
  "common.password.placeholder": "Enter Password here",
  "common.password.empty": "Password cannot be empty",
  "common.retype_password": "Verify Password",
  "common.retype_password.placeholder": "Enter Password here again",
  "common.cancel": "Cancel",
  "common.close": "Close",
  "common.choose": "Choose",
  "common.confirm": "Confirm",
  "common.done": "Done",
  "common.continue": "Continue",
  "common.customize": "Customize",
  "common.rename": "Rename",
  "common.delete": "Delete",
  "common.fail_to_fetch_form": "Oops, we can't find your form!",
  "common.fail_to_fetch_form_group": "Oops, we can't find your form group!",
  "common.fail_to_fetch_receipt_group": "Oops, we can't find your receipt group!",
  "common.fail_to_fetch_template": "Templates can't be fetched at the moment.",
  "common.fail_to_fetch_custom_model": "Oops, we can't find your custom model!",
  "common.fail_to_fetch_team_members": "Oops, we can't find your team members!",
  "common.fail_to_fetch_webhook": "Oops, we can't find your webhook!",
  "common.import_form_success": "The form is successfully imported",

  "common.loading": "Loading...",
  "common.uploading": "Uploading...",
  "common.importing": "Importing...",
  "common.save": "Save",
  "common.save_and_overwrite": "Save and Overwrite",
  "common.choose_image": "Choose image",
  "common.choose_file": "Choose file",
  "common.upload_image": "Upload image",
  "common.replace_image": "Replace image",
  "common.upload_image_optional": "Upload Image (Optional)",
  "common.choose_a_file": "Choose a file",
  "common.yes": "Yes",
  "common.no": "No",
  "common.ok": "OK",
  "common.export": "Export",
  "common.submit": "Submit",
  "common.update": "Update",
  "common.on": "On",
  "common.off": "Off",
  "common.exporting": "Exporting...",
  "common.import": "Import",
  "common.remove": "Remove",
  "common.next": "Next",

  "error.unexpected_server_response": "Unexpected server response, please try again later.",
  "error.unknown_error": "Unknown error happened.",
  "error.some_fields_are_empty": "Some field are not filled in.",
  "error.user_not_found": "User not exists.",
  "error.user_already_exists": "User already exists.",
  "error.invalid_email": "Invalid email format.",
  "error.password_not_match": "Passwords are not match.",
  "error.duplicated_user": "E-mail is already in use.",
  "error.fail_to_create_form": "Fail to create form.",
  "error.fail_to_create_form_group": "Fail to create form group.",
  "error.fail_to_create_receipt_group": "Fail to create receipt group.",
  "error.fail_to_create_webhook": "Fail to create webhook.",
  "error.fail_to_upload_image.exceed_max_size": "Failed to upload master image. The file is exceeding the max file size of {maxSize}.",
  "error.fail_to_rename_receipt_group": "Fail to rename receipt group.",
  "error.number_of_receipt_group_reached_limit": "Number of receipt group reached limit.",
  "error.error_during_remove_form": "Error happened during removing form.",
  "error.error_during_remove_form_group": "Error happened during removing form group.",
  "error.error_during_remove_receipt_group": "Error happened during removing receipt group.",
  "error.empty_language": "Empty language is not allowed.",
  "error.form_not_ready_for_ocr": "Form is not ready for OCR. Have you marked any anchor?",
  "error.create_receipt_group.empty_name": "Receipt group name cannot be empty.",
  "error.rename_receipt_group.empty_name": "Receipt group name cannot be empty.",
  "error.rename_form_group.empty_name": "Form group name cannot be empty.",
  "error.add_token.invalid": "Token is invalid.",
  "error.create_form.empty_name": "Form name cannot be empty.",
  "error.create_form_group.empty_name": "Form group name cannot be empty.",
  "error.create_form.no_image": "Image is required.",
  "error.google_service_account_key_is_not_set": "Google service account key is not set yet.",
  "error.azure_subscription_key_is_not_set": "Azure subscription key is not set yet.",
  "error.change_password.empty_old_password": "Old password is empty.",
  "error.change_password.empty_new_password": "New password is empty.",
  "error.change_password.inconsistent_new_password": "New passwords are not consistent.",
  "error.incorrect_password": "Incorrect password.",
  "error.incorrect_email_or_password": "Incorrect email or password. Please try again.",
  "error.cannot_get_image_dimension": "Cannot get image dimension. Image file might be corrupted.",
  "error.form_image_size_too_large": "File too large. Please try again with a smaller file (<5MB).",
  "error.invalid_token": "Invalid token.",
  "error.form_id_not_found": "Form ID not found.",
  "error.form_compute_feature_no_image": "No image was submitted for feature computation.",
  "error.cannot_load_image": "Cannot load image.",
  "error.image_dimension_exceed_limit": "Image dimension cannot exceed {max_image_size_in_pixel}px in either dimension.",
  "error.master_image.cannot_load_image": "Cannot load image. Note that only image files are allowed for master image.",
  "error.uploaded_file_too_large": "File too large. Please try again with a smaller file (<5MB).",
  "error.form_not_found": "Form not found.",
  "error.form_not_ready": "Form not ready, make sure at least one anchor is defined.",
  "error.form_unmatched_query_image": "Query image is not match the specified form",
  "error.error_during_extracting_form_info": "Error during extracting form info",
  "error.form_group_not_found": "Form group not found",
  "error.form_group_is_empty": "Form group is empty",
  "error.receipt_group_id_not_found": "Receipt group ID not found",
  "error.fail_to_get_receipt_groups": "Fail to get receipt groups.",
  "error.fail_to_load_receipt_details": "Fail to load receipt details.",
  "error.error_during_extract_receipt_info": "Error during extracting receipt info",
  "error.error_during_accessing_google_vision_api": "Error during accessing Google Vision API \n ({detail})",
  "error.azure_computer_vision_setting_is_not_set": "Azure computer vision subscription key and/or endpoint is not set",
  "error.fail_to_save_form": "Failed to save form",
  "error.fail_to_update_form_group": "Failed to update form group",
  "error.fail_to_import_form": "Failed to import form",
  "error.fail_to_import_form_group": "Failed to import form group",
  "error.fail_to_export_form": "Failed to export form",
  "error.fail_to_open_script_editor": "Failed to open postprocessing script editor",
  "error.extraction_timeout": "The extraction timed out. Please try with a smaller document. If the problem persists, contact us for help.",
  "error.webhook.empty_webhook_name": "Webhook Name is empty.",
  "error.webhook.empty_webhook_url": "Webhook URL is empty.",
  "error.webhook.empty_connect_with": "Connect With is empty.",
  "error.webhook.invalid_webhook_url": "Webhook URL is invalid.",
  "error.conflict_found": "This form has been modified outside this tab.",
  "error.password_too_simple": "The password must contain at least {passwordMinLength} characters, 1 number, 1 lowercase and uppercase letter, and 1 symbol from `~!@#$%^&*()-_+=",
  "error.password_is_same": "The new password is the same as the current password. Please use another password.",
  "error.password_is_reused": "Password reuse is forbidden. Please use another password.",
  "error.account_locked": "The account is locked. Please contact the administrator to unlock the account.",
  "error.password_expired": "Password expired, please change it to proceed.",
  "error.fail_to_add_token": "Fail to add token",
  "error.fail_to_add_custom_field": "Fail to add custom field",
  "error.fail_to_remove_custom_field": "Fail to remove custom field",
  "error.edit_custom_field.empty_name": "Name cannot be empty",
  "error.edit_custom_field.empty_token": "Token cannot be empty",
  "error.edit_custom_field.empty_pattern": "Pattern cannot be empty",
  "error.edit_custom_field.duplicated_name": "Name should be unique",
  "error.edit_custom_field.invalid_regexp": "Invalid regular expression",
  "error.regex_builder.invalid_pattern_length_range": "Minimum length > Maximum length",
  "error.usage.unknown_error": "Unknown error occurred when retrieving usage record, please reload the page",
  "error.anchor_not_found": "Anchor not found.  Your form might be out-of-sync, consider refresh.",
  "error.field_not_found": "Field not found.  Your form might be out-of-sync, consider refresh.",
  "error.detection_region_not_found": "Detection region not found. Your form might be out-of-sync, consider refresh.",
  "error.delete_fallback_form_failure": "Fallback form of form group cannot be deleted.",
  "error.create_payment_method_failure": "Failed to create payment method.",
  "error.payment.required": "Free quota used up, please upgrade to paid plans.",
  "error.too_many_request": "You have called the extract API too frequent, please try again later.",
  "error.usage_reached_hard_limit": "Your usage has reached the plan quota limit, please try again later.",
  "error.subscription.unexpected": "Unexpected subscription error, please try again later.",
  "error.subscription.stripe.no.card.element": "Can't get Stripe card element.",
  "error.subscription.stripe.element.not.init": "Stripe element not initialised.",
  "error.stripe.key.empty": "Stripe key is empty",
  "error.stripe.load.failure": "Failed to load Stripe",
  "error.stripe.load.failure.try.refresh": "Failed to load Stripe, try refreshing.",
  "error.stripe.error": "Failed to handle payment action. Please try again later.",
  "errors.stripe.card.processing_error": "An error occurred while processing your card. Try again in a little bit.",
  "errors.stripe.card.card_declined": "Your card was declined.",
  "errors.stripe.card.incorrect_cvc": "Your card's security code is incorrect.",
  "errors.stripe.card.expired_card": "Your card has expired.",
  "error.subscription.stripe.invalid.card.number": "Invalid card number",
  "error.subscription.stripe.invalid.expiry.date": "Invalid expiry date",
  "error.subscription.stripe.invalid.cvc": "Invalid expiry date",
  "error.subscription.stripe.invalid.postal.code": "Invalid expiry date",
  "error.subscription.stripe.invalid.default": "Invalid card input",

  "error.image_token_modal.no_image": "Image is required.",
  "error.image_token_modal.empty_name": "Image token name cannot be empty.",
  "error.image_token_modal.duplicated_name": "Duplicated image token name.",
  "error.token_group_modal.empty_name": "Token group name cannot be empty.",
  "error.token_group_modal.duplicated_name": "Duplicated token group name.",
  "error.text_token_modal.empty_value": "Text token value cannot be empty.",
  "error.text_token_modal.duplicated_value": "Text Token has to be unique.",

  "error.custom_model.fail_to_create_custom_model": "Fail to create custom model",
  "error.custom_model.fail_to_get_custom_models": "Fail to get custom models",
  "error.custom_model.fail_to_get_custom_model": "Fail to get custom model",
  "error.custom_model.fail_to_save_custom_model": "Fail to save custom model",
  "error.custom_model.fail_to_delete_custom_model": "Fail to delete custom model",
  "error.custom_model.image_format_not_supported": "Uploaded image format not supported. Please try another image",
  "error.custom_model.some_image_not_supported": "Uploaded images contain unsupported and they are ignored",

  "error.create_team.team_name.validation.empty_team_name": "Team name cannot be empty.",
  "error.create_team.team_name.validation.too_short": "Team name is too short. (Min: 3 characters)",
  "error.create_team.team_name.validation.too_long": "Team name is too long. (Max: 100 characters)",
  "error.create_team.team_name.validation.no_leading_or_trailing_whitespace": "Team name cannot start or end with whitespace.",

  "error.create_team.lookup_id.validation.alphanumeric_or_hyphens_only": "Lookup ID can only contain alphanumeric characters or hyphens.",
  "error.create_team.lookup_id.validation.no_upper_case": "Team ID must not contain uppercase letters.",
  "error.create_team.lookup_id.validation.no_space": "Team ID must not contain spaces.",
  "error.create_team.lookup_id.validation.too_short": "Team ID is too short. (Min: 4 characters)",
  "error.create_team.lookup_id.validation.too_long": "Team ID is too long. (Max: 100 characters)",
  "error.create_team.lookup_id.validation.no_multiple_spaces": "Team ID cannot contain multiple spaces.",

  "error.create_team.failed": "Failed to create team.",
  "error.fail_to_load_member_list": "Fail to load member list.",

  "error.invite.empty_email": "Email cannot be empty.",
  "error.invite.invalid_email": "Invalid email.",
  "error.invite.team_user_already_exists": "Fail to send invite to {email}. A team user with that email already exists.",
  "error.invite.invitation_already_exists": "Fail to send invite to {email}. An invitation with that email already exists.",
  "error.invite.invalid_invitation": "Fail to accept the invitation. The invitation is expired or invalid.",
  "error.invite.invitation_email_not_match": "Fail to accept the invitation. Your email is not match with the invitation.",
  "error.invite.fail_to_accept": "Fail to accept invite.",
  "error.invite.not_found": "Invitation not found.",

  "error.team.lookup_id_already_exists": "Team lookup ID already exists.",

  "error.audit_log.not_found": "There is no logs for the selected month",
  "error.audit_log.failed_to_load_audit_logs": "Failed to load audit logs. Please try again.",
  "error.audit_log.failed_to_generate_csv_file": "Failed to generate CSV file.",

  "error.give_trial.empty_quota": "Quota cannot be empty.",
  "error.give_trial.cannot_set_trial": "Failed to set trial.",
  "error.give_trial.cannot_clear_trial": "Failed to clear trial.",

  "error.merchant_list_settings.too_many_merchants": "Number of merchants exceeds the limit. ({count}/{limit})",

  "language.english": "English",
  "language.traditional_chinese": "Traditional Chinese",

  "tesseract.psm.1": "Automatic page segmentation with OSD",
  "tesseract.psm.3": "Automatic page segmentation but no OSD",
  "tesseract.psm.4": "Single column of text of variable sizes",
  "tesseract.psm.5": "Single uniform block of vertically aligned text",
  "tesseract.psm.6": "Single uniform block of text",
  "tesseract.psm.7": "Single text line",
  "tesseract.psm.8": "Single word",
  "tesseract.psm.9": "Single word in a circle",
  "tesseract.psm.10": "Single character",
  "tesseract.psm.11": "Sparse text, no order",
  "tesseract.psm.12": "Sparse text with OSD",
  "tesseract.psm.13": "Raw line",
  "tesseract.preset.english_only": "English Only",
  "tesseract.preset.chinese_only": "Chinese Only",
  "tesseract.preset.mixed": "Mixed English and Chinese",
  "tesseract.preset.number": "Number",

  "azure.mode.printed": "Printed",
  "azure.mode.handwritten": "Hand-written",

  "google.mode.printed": "Printed",
  "google.mode.handwritten": "Hand-written",

  "symbol_detector.preset.checkbox": "Checkbox",

  "recognizer.tesseract": "Tesseract (OCR)",
  "recognizer.zbar": "ZBar (Barcode/QR Code)",
  "recognizer.google": "Google Cloud Vision",
  "recognizer.azure": "Azure Computer Vision",
  "recognizer.symbol_detector": "Checkbox",
  "recognizer.abbyy": "ABBYY (OCR)",

  "setting.title": "Setting",
  "setting.google_cloud_vision": "Google Cloud Vision",
  "setting.google_service_account_key": "Service Account Key ID",
  "setting.choose_service_account_key": "Choose service account key",
  "setting.uploaded_invalid_file": "File uploaded invalid",

  "setting.azure_computer_vision": "Azure Computer Vision",
  "setting.azure_subscription_key": "Subscription Key",
  "setting.azure_endpoint": "Endpoint",
  "setting.pricing": "Pricing",
  "setting.contact_us_about_getting_the_key": "Contact us about getting the key",

  "setting.google_cloud_vision_description": "This is required for:\n  - OCR with Google Cloud Vision engine\n  - Receipt information extraction",
  "setting.how_to_get_google_service_account_key": "How to get a Google service account key",
  "setting.how_to_enable_google_cloud_vision_api": "How to enable Google Could Vision API",

  "setting.azure_computer_vision_description": "This is required for:\n  - OCR with Azure Computer Vision engine",
  "setting.how_to_get_azure_subscription_key": "How to get Azure subscription key",

  "setting.update_fail": "Failed to update settings.",
  "setting.update_success": "Successfully updated settings.",
  "setting.modified_prompt.title": "Settings have been modified",
  "setting.modified_prompt.desc": "Settings have been modified outside this tab since you open it. Are you sure you want to save them?",

  "apinote.endpoint": "HTTP Endpoint",
  "apinote.header_parameters": "Parameters in HTTP request header",
  "apinote.form_parameters": "Parameters in form data",
  "apinote.param_name": "Name",
  "apinote.param_description": "Description",
  "apinote.default": "Default value:",
  "apinote.optional": "optional",
  "apinote.example": "cURL Example",
  "apinote.field_name": "Name",
  "apinote.field_type": "Type",
  "apinote.field_description": "Description",
  "apinote.response": "Fields in HTTP response",

  "apinote.toggle.multi_document": "Multi Document",
  "apinote.toggle.async_mode": "Asynchronous Mode",
  "apinote.toggle.on": "ON",
  "apinote.toggle.off": "OFF",

  "apinote.async.structure_same_as": "structure same as the /extract API",
  "apinote.async.using_async_mode": "Using the Asynchronous mode",
  "apinote.async.using_async_mode.desc1": "If the request takes too long to complete, you can use the asynchronous mode to avoid timeout.",
  "apinote.async.using_async_mode.desc2": "This can be enabled by the {tag} parameter either in request header or form data.",
  "apinote.async.job_id": "Job ID",
  "apinote.async.job_id.desc": "If an async job is successfully created, a {resp} response will be returned with {status} and {jobId}.",
  "apinote.async.getting_result": "Getting the extraction result",
  "apinote.async.getting_result.desc1": "The extraction result can be queried by polling the {endpoint} endpoint. Replace {jobIdParam} with the {jobId} you get in the response of {extractEndpoint} call.",
  "apinote.async.getting_result.desc2": "Send a {method} request to the endpoint with the access token in the header until the result is returned.",
  "apinote.async.getting_result.desc3": "The extraction result will be deleted 24 hours after the job is completed, no matter it has been queried or not.",
  "apinote.async.pending": "Pending",
  "apinote.async.completed": "Completed",

  "ocrapi.token_copied": "Access token is copied to clipboard",
  "ocrapi.copy_token": "Copy Access Token",
  "ocrapi.id_copied": "Form ID is copied to clipboard",
  "ocrapi.copy_id": "Copy Form ID",
  "ocrapi.open_in_desktop_app": "Open in Desktop App",
  "extract.description": "FormX will isolate extraction fields from the uploaded image then perform OCR on these fields.\n\nForm ID can be submitted as multipart/form-data or through a header. Image can be uploaded as is or through an image_url.\nFor uploading image directly, it can be uploaded in the request body or via multipart/form-data.\nOn the other hand, if you want to specify an image_url, it can be submitted via a header or multipart/form-data.",
  "extract.Content-Type.desc": "`image/jpeg` or `image/png` or `application/pdf`, required if sent image content in the request body",
  "extract.X-WORKER-TOKEN.desc": "Access token",
  "extract.X-WORKER-FORM-ID.desc": "Form ID",
  "extract.X-WORKER-IMAGE-URL.desc": "URL of image to extract fields in jpg or png format, or URL of a pdf file. This is required if request body is empty",
  "extract.X-WORKER-ENCODING.desc": "Encoding of the request body, allowed 'raw' or 'base64'",
  "extract.X-WORKER-PDF-DPI.desc": "DPI of the uploaded pdf file",
  "extract.X-WORKER-SHOW-CONFIDENCE.desc": "Flag for showing confidence score in response",
  "extract.X-WORKER-AUTO-ADJUST-IMAGE-SIZE.desc": "Flag for auto adjusting image size for better extraction result, it will take a longer for extraction if enabled",
  "extract.X-WORKER-MULTI-PAGE-PDF.desc": "Flag for treating different pages of the uploaded PDF file as different documents and extract all of them. If false, only the first page will be extracted",
  "extract.X-WORKER-DETECT-MULTI-DOCUMENT.desc": "Flag for detecting multiple documents from the first page. If false, only one document will be extracted per page",
  "extract.X-WORKER-RETURN-REQUEST-ID.desc": "Flag for showing request id in the response",
  "extract.X-WORKER-ASYNC.desc": "Flag for using the {asyncMode}",
  "extract.X-WORKER-ASYNC.action.asyncMode": "asynchronous mode",
  "extract.token.desc": "Access Token",
  "extract.form_id.desc": "Form ID",
  "extract.image.desc": "Image to extract fields in jpg or png format, or a pdf file. Either specific this or image_url",
  "extract.image_url.desc": "URL of image to extract fields in jpg or png format, or URL to a pdf file. Either specify this or image",
  "extract.pdf_dpi.desc": "DPI of the uploaded pdf file",
  "extract.show_confidence.desc": "Flag for showing confidence score in response",
  "extract.auto_adjust_image_size.desc": "Flag for auto adjusting image size for better extraction result, it will take a longer for extraction if enabled",
  "extract.multi_page_pdf.desc": "Flag for treating different pages of the uploaded PDF file as different documents and extract all of them. If false, only the first page will be extracted",
  "extract.return_request_id.desc": "Flag for showing request id in the response",
  "extract.detect_multi_document.desc": "Flag for detecting multiple documents from the first page. If false, only one document will be extracted per page",
  "extract.async.desc": "Flag for using the {asyncMode}",
  "extract.async.action.asyncMode": "asynchronous mode",
  "extract.field.status.desc": "\"ok\" if success, \"failed\" if failed",
  "extract.field.form_id.desc": "Form ID",
  "extract.field.fields.desc": "List of extracted fields and fields in detection regions",
  "extract.field.auto_extraction_items.desc": "List of detected auto extraction items",
  "extract.field.key_values.desc": "List of detected key-value pair",
  "extract.field.token_groups.desc": "List of detected token groups",
  "extract.field.error.desc": "Only exists if failed, its shape depends on the failure, but it will always has \"code\" and \"message\" fields",
  "extract.field.documents.desc": "List of extracted documents",

  "extract.field.Document.form_id.desc": "Form ID",
  "extract.field.Document.fields.desc": "List of extracted fields and fields in detection regions",
  "extract.field.Document.auto_extraction_items.desc": "List of detected auto extraction items",
  "extract.field.Document.key_values.desc": "List of detected key-value pair",
  "extract.field.Document.token_groups.desc": "List of detected token groups",
  "extract.field.Document.error.desc": "Only exists if failed, its shape depends on the failure, but it will always has \"code\" and \"message\" fields",

  "extract.field.Field.region_id.desc": "Detection region ID",
  "extract.field.Field.name.desc": "Field label",
  "extract.field.Field.type.desc": "Field type",
  "extract.field.Field.value.desc": "Extracted content",
  "extract.field.Field.error.desc": "Message of the error if exists",
  "extract.field.Field.confidence.desc": "Confidence score. It only exists if confidence score is enabled in request. For field with type in name and adress, it will be shown inside value field.",

  "extract.field.AutoExtractionItem.name.desc": "Item name",
  "extract.field.AutoExtractionItem.value.desc": "Item value",
  "extract.field.AutoExtractionItem.confidence.desc": "Confidence score. It only exists if confidence score is enabled in request. For name, address job_title item, it will be shown inside value field.",

  "extract.field.KeyValue.name.desc": "Item name",
  "extract.field.KeyValue.value.desc": "Item value",
  "extract.field.KeyValue.confidence.desc": "Confidence score. It only exists if confidence score is enabled in request.",

  "extract.field.TokenGroup.name.desc": "Token group name",
  "extract.field.TokenGroup.texts.desc": "List of detected text tokens in this group.",
  "extract.field.TokenGroup.images.desc": "List of detected image tokens in this group.",

  "extract.field.Token.id.desc": "Token id",
  "extract.field.Token.value.desc": "Token value",
  "extract.field.Token.confidence.desc": "Confidence score. It only exists if confidence score is enabled in request.",

  "extract.field.CustomModel.id.desc": "Custom model id",
  "extract.field.CustomModel.model_version.desc": "Custom model verison",
  "extract.field.CustomModel.name.desc": "Custom model name",
  "extract.field.CustomModel.result.desc": "Extraction result of the custom model, its structure vary among different custom model.",

  "date_format.settings.modal.title": "Date Settings",

  "receipt_dropdown.placeholder": "Select a Receipt Group",
  "receipt.empty": "No Receipt Group Found",

  "receipt_edit.delete": "Delete Receipt group",

  "receipt_api.token_copied": "Access token is copied to clipboard",
  "receipt_api.copy_token": "Copy Access Token",
  "receipt_api.id_copied": "Receipt group ID is copied to clipboard",
  "receipt_api.copy_id": "Copy Receipt group ID",

  "extract_receipt_info.Content-Type.desc": "`image/jpeg` or `image/png` or `application/pdf`, required if sent image content in the request body",
  "extract_receipt_info.X-WORKER-TOKEN.desc": "Access Token",
  "extract_receipt_info.X-WORKER-RECEIPT-GROUP-ID.desc": "Receipt group ID",
  "extract_receipt_info.X-WORKER-IMAGE-URL.desc": "URL of image to extract fields in jpg or png format, or URL of a pdf file. This is required if request body is empty",
  "extract_receipt_info.X-WORKER-ENCODING.desc": "Encoding of the request body, allowed 'raw' or 'base64'",
  "extract_receipt_info.X-WORKER-PDF-DPI.desc": "DPI of the uploaded pdf file",
  "extract_receipt_info.receipt_group_id.desc": "Receipt group ID",
  "extract_receipt_info.image.desc": "Image to extract fields in jpg or png format, or a pdf file. Either specific this or image_url",
  "extract_receipt_info.image_url.desc": "URL of image to extract fields in jpg or png format, or URL to a pdf file. Either specify this or image",
  "extract_receipt_info.field.status.desc": "\"ok\" if success",
  "extract_receipt_info.field.receipt_group_id.desc": "Receipt group ID",
  "extract_receipt_info.field.amount.desc": "Extracted total amount",
  "extract_receipt_info.field.date.desc": "Extracted date",
  "extract_receipt_info.field.time.desc": "Extracted time",
  "extract_receipt_info.field.tokens.desc": "List of detected pre-defined tokens",
  "extract_receipt_info.field.custom_fields.desc": "List of detected custom fields",
  "extract_receipt_info.field.error.desc": "Only exists if failed, its shape depends on the failure, but it will always has \"code\" and \"message\" fields",

  "extract_receipt_info.field.CustomField.name.desc": "Name of the detected custom field",
  "extract_receipt_info.field.CustomField.value.desc": "Value of the detected custom field",

  "extract_receipt_info.description": "Extract info from from the uploaded receipt image include total amount, date and time,\nalso check the existence of predefined token.\n\nUser could upload the image and specified the form id as multipart/form-data,\nor it could specify form id in header and put image content in the request body.\n\nUser could also specify image url in header or multipart/form-data.",

  "detect_documents.Content-Type.desc": "`image/jpeg` or `image/png` or `application/pdf`, required if sent image content in the request body",
  "detect_documents.X-WORKER-TOKEN.desc": "Access token",
  "detect_documents.X-WORKER-IMAGE-URL.desc": "URL of image to detect documents in jpg or png format, or URL of a pdf file. This is required if request body is empty",
  "detect_documents.X-WORKER-ENCODING.desc": "Encoding of the request body, allowed 'raw' or 'base64'",
  "detect_documents.X-WORKER-PDF-DPI.desc": "DPI of the uploaded pdf file",
  "detect_documents.image.desc": "Image to detect documents in jpg or png format, or a pdf file. Either specific this or image_url",
  "detect_documents.image_url.desc": "URL of image to detect documents in jpg or png format, or URL to a pdf file. Either specify this or image",
  "detect_documents.pdf_dpi.desc": "DPI of the uploaded pdf file",
  "detect_documents.field.status.desc": "\"ok\" if success",
  "detect_documents.field.documents.desc": "List of detected documents",
  "detect_documents.field.error.desc": "Only exists if failed, its shape depends on the failure, but it will always has \"code\" and \"message\" fields",

  "detect_documents.field.Document.bbox.desc": "Bounding box of the detected document",
  "detect_documents.field.Document.bbox_score.desc": "Confidence score of the detected bounding box",
  "detect_documents.field.Document.type.desc": "Type of the detected document",
  "detect_documents.field.Document.type_score.desc": "Confidence score of the detected type",

  "detect_documents.description": "FormX will detect documents such as ID card and receipt from the uploaded image.\nIt will return the bounding box and type of each detected document.\n\nImage can be uploaded as is or through an image_url.\nFor uploading image directly, it can be uploaded in the request body or via multipart/form-data.\nOn the other hand, if you want to specify an image_url, it can be submitted via a header or multipart/form-data.",

  "key_value_modal.title.create": "Create Key Value",
  "key_value_modal.title.edit": "Edit Key Value",

  "edit_custom_field.title.create": "Create custom field",
  "edit_custom_field.title.edit": "Edit custom field",
  "edit_custom_field.submit.create": "Create",
  "edit_custom_field.submit.edit": "OK",
  "edit_custom_field.pattern": "Pattern (RegExp) to match",
  "edit_custom_field.position": "Position",
  "edit_custom_field.use_fuzzy_search": "Use Fuzzy Search",
  "edit_custom_field.position.left": "Left",
  "edit_custom_field.position.right": "Right",
  "edit_custom_field.position.below": "Below",
  "edit_custom_field.position.above": "Above",
  "edit_custom_field.regex_builder_button": "Builder",
  "edit_custom_field.name": "Name",
  "edit_custom_field.token": "Token",

  "regex_builder.title": "Regex Builder",
  "regex_builder.pattern_choice": "Preset Pattern",
  "regex_builder.pattern_choice.lowercase_alphabet": "Lowercase Alphabet",
  "regex_builder.pattern_choice.uppercase_alphabet": "Uppercase Alphabet",
  "regex_builder.pattern_choice.chinese_char": "Chinese Character",
  "regex_builder.pattern_choice.number": "Number",
  "regex_builder.pattern_choice.dot": "Dot",
  "regex_builder.pattern_length": "Length",
  "regex_builder.pattern_range_min": "Min",
  "regex_builder.pattern_range_max": "Max",
  "regex_builder.apply": "Apply",
  "regex_builder.instruction.zero_for_unspecified_length": "* Enter 0 for unspecified Min/Max length",
  "regex_builder.instruction.same_value_for_exact_length": "* Set Min = Max for exact length",

  "usage.api.extract": "Extract API",
  "usage.api.extract_fields": "Extract Fields API (Obsolete)",
  "usage.api.extract_receipt_info": "Extract Receipt Info API",
  "usage.api.detect_documents": "Detect Documents API",
  "usage.usage_view.title": "Usage History",

  "usage.table.name": "Name",
  "usage.table.entity_id": "Entity ID",
  "usage.table.count": "Count",
  "usage.table.total": "Total: {count}",
  "usage.table.deleted_entity": "(Deleted)",

  "usage.date_range_selector.title": "Search History by Date Range",
  "usage.date_range_selector.from": "From",
  "usage.date_range_selector.to": "To",
  "usage.date_range_selector.search": "Search",

  "alert.google_service_account_key_is_required_for_receipt_info_extract": "You need to set the Google service account key and enable Google Could Vision API before doing receipt information extraction.\nClick Setting button near top right corner to go to setting page and set there.",
  "alert.template_google_setting_is_required": "To use this template, you need to set the Google service account key and enable Google Could Vision API first.",
  "alert.template_azure_setting_is_required": "To use this template, you need to set the Azure Subscription Key and Endpoint first.",
  "alert.set_up": "Set up",

  "scriptdoc.instruction.toggle": "Script Instructions",
  "scriptdoc.parameters": "Parameters:",
  "scriptdoc.return_value": "Return Value:",
  "scriptdoc.optional": "Optional",

  "scriptdoc.fuzzy_search.desc": "Searches through a text with user-defined pattern",
  "scriptdoc.fuzzy_search.pattern.desc": "Will be used to search target text",
  "scriptdoc.fuzzy_search.text.desc": "The target text where search will be conducted upon",
  "scriptdoc.fuzzy_search.options.desc": "Options for configuring the fuzzy search behaviour",
  "scriptdoc.fuzzy_search.options.max_subs.desc": "maximum substitutions between the pattern and return string",
  "scriptdoc.fuzzy_search.options.max_insert.desc": "amount of character that can be skipped in the pattern",
  "scriptdoc.fuzzy_search.options.max_delete.desc": "amount of character that can be skipped in the return string",
  "scriptdoc.fuzzy_search.options.max_l_dist.desc": "maximum Levenshtein distance allowed between the pattern and return string",
  "scriptdoc.fuzzy_search.return_value.matches.desc": "array of matches",
  "scriptdoc.fuzzy_search.return_value.matches.start.desc": "start position of the match",
  "scriptdoc.fuzzy_search.return_value.matches.end.desc": "end position of the match",
  "scriptdoc.fuzzy_search.return_value.matches.dist.desc": "Levenshtein distance between the matched part and the pattern",
  "scriptdoc.fuzzy_search.return_value.matches.matched.desc": "matched part",

  "scriptdoc.edit_distance.desc": "Returns the Levenshtein distance (edit distance) between two strings, which is a string metric for measuring the difference.",
  "scriptdoc.edit_distance.string1.desc": "First string that will be compared with the other",
  "scriptdoc.edit_distance.string2.desc": "Second string that will be compared with the first",
  "scriptdoc.edit_distance.return_value.edit_distance.desc": "Levenshtein distance between string1 and string2",

  "payment.plan.quota": "Quota",
  "payment.plan.subscribe": "Subscribe",
  "payment.plan.subscribe.enterprise": "Contact Sales",

  "payment.plan.free.title": "Free Trial",
  "payment.plan.free.price": "Try out our features! The First 100 pages are free!",

  "payment.plan.starter.title": "Starter",
  "payment.plan.starter.desc": "Automate your entire business process for growing business!",
  "payment.plan.starter.price": "USD $299 / mo",
  "payment.plan.starter.detail1": "Up to 2,000 pages per month",
  "payment.plan.starter.detail2": "No SLA",
  "payment.plan.starter.detail3": "Email support only",

  "payment.plan.enterprise.title": "Enterprise",
  "payment.plan.enterprise.desc": "For organizations that need additional security and control, and higher volume",
  "payment.plan.enterprise.price": "Contact Sales",
  "payment.plan.enterprise.detail1": "Includes all the Starter Features",
  "payment.plan.enterprise.detail2": "Standard/Tailor-made SLA",
  "payment.plan.enterprise.detail3": "Dedicated Account Manager",
  "payment.plan.enterprise.detail4": "Client on-boarding",
  "payment.plan.enterprise.detail5": "Personalized team training",
  "payment.plan.enterprise.detail6": "Private Cloud Deployment",

  "payment.plan.header": "Current Plan",
  "payment.existing.header": "Billing Info",
  "payment.update.header": "Update Billing Info",
  "payment.subscribe.header": "Subscribe to Starter Plan",
  "payment.subscribe.header.enterprise": "Subscribe to Enterprise Plan",
  "payment.unsubscribe.header": "Unsubscribe from Starter Plan",
  "payment.unsubscribe.button": "Unsubscribe",
  "payment.alert.box.reminder": "All free quotas are used up. To continue using our extraction APIs, please subscribe to our business plan.",
  "payment.last_four_digits": " (last four)",
  "payment.method.header": "Payment Method",
  "payment.billing_email": "Billing Email",
  "payment.update_builling_email": "Update Billing Email",

  "sentry.report": "Report Error",
  "sentry.error_occurred": "Oops... We have just crashed. Please refresh and try again.",

  "form_editor.form_is_saved": "Form is saved.",
  "form_editor.form_is_saved_but_recommend_add_anchor": "Form is saved. Still you are recommended to have at least 2 Anchors set up.",
  "form_editor.fail_to_save_form": "Failed to save form, please refresh and try again.",
  "form.editor.no.image.placeholder.instruction1": "Upload a Master Image to extract information from regions",
  "form.editor.no.image.placeholder.instruction2": "This document has no Master Image",
  "form.editor.no.image.placeholder.multi_document.title": "The file contains multiple documents",
  "form.editor.no.image.uploading": "Uploading image...",
  "form.editor.no.image.missing.image": "No image selected",
  "form.editor.no.image.upload.button": "Upload Now",
  "form_editor.form_not_saved_prompt.save_warning": "Your have unsaved changes. Would you like to save them before leaving?",
  "form_editor.form_not_saved_prompt.title": "Save changes?",
  "form_editor.form_not_saved_prompt.save_changes": "Save changes",
  "form_editor.form_not_saved_prompt.dont_save": "Don't save",
  "form_editor.form_not_saved_prompt.saving": "Saving...",
  "form_editor.form_modifed_prompt.title": "This form has been modified",
  "form_editor.form_modifed_prompt.desc": "This form has been modified outside this tab since you open it. Are you sure you want to save this form?",

  "form.extraction_mode.multi_page_pdf": "PDF file contains multiple pages",
  "form.extraction_mode.single_page": "Single document",
  "form.extraction_mode.single_page_multi_document": "Multiple documents in one image",

  "form_inspector.tab.header.region": "Region",
  "form_inspector.tab.header.document": "Document",
  "form_inspector.tab.header.settings": "Settings",
  "form_inspector.anchor.area": "Red area is an \"Anchor\".",
  "form_inspector.anchor.description": "Anchor helps FormExtractor to identify and rectify the document.",
  "form_inspector.anchor.hints": "Please try to mark at least 3 - 5 red areas as anchors, which should be the part of document that never change (such as Logo, Header, Footer, etc).",
  "form_inspector.detection_region.area": "Blue area is \"Detection Region\".",
  "form_inspector.detection_region.reminder": "Each detection region should have at least ONE field.",
  "form_inspector.detection_region.hints": "Give a meaningful label name, and select the type of content you expect in the region for best accuracy.",
  "form_inspector.detection_region.tooltip.pointer": "Pointer",
  "form_inspector.detection_region.tooltip.anchor_region": "Anchor Region",
  "form_inspector.detection_region.tooltip.detection_region": "Detection Region",
  "form_inspector.detection_region.tooltip.field_tool": "Field",

  "form_inspector.nothing_detected.desc": "Add a detection region in blue, to extract information at certain regions. For example, if you know there is an \"Address\" at the bottom of a document, you can create a region highlight bottom part of the document, and create a field with type \"Address\" to extract address from it.",
  "form_inspector.document.desc": "Extract information from anywhere in the document. If you know the relative position of the information, you may want to use Region for higher accuracy.",

  "form_inspector.key_value.description": "Detect a field with key & value which is common in form, e.g a field with key \"First Name\" would have \"John\" as value.",
  "form_inspector.key_value.hints": "You can specify the key and the position of the value (is it on the right? left? above? or below the key) to extract the data.",
  "form_inspector.token_group.description": "Detect if a list of tokens (could be text or image) exists in the document anywhere.",
  "form_inspector.token_group.hints": "It would be useful to say, to identify if a receipt is from a certain restaurant with the restaurant name, or if a bank logo exists in the document.",

  "script_editor_modal.title.edit_post_processing_script": "Edit Post-processing script",

  "add.token.group.title": "Add Token Group",
  "edit.token.group.title": "Edit Token Group",

  "form.inspector.tab.header.key_value": "Key Value",
  "form.inspector.tab.header.token_group": "Token Group",

  "add.key_value.button": "Add Key Value",

  "add.token_group.button": "Add Token Group",

  "add.text.token.link": "Add new text token",
  "add.image.token.link": "Add new image token",

  "add.text.token.title": "Add Text Token",
  "edit.text.token.title": "Edit Text Token",
  "add.image.token.title": "Add Image Token",
  "edit.image.token.title": "Edit Image Token",
  "placeholder.image.token.name": "Enter image token name here",
  "placeholder.text.token.name": "Enter text token value here",
  "token.modal.confirm.button": "Confirm",

  "document.tab.auto_extraction_item.title": "Auto Extraction Items",
  "document.tab.custom_extraction_item.title": "Custom Extraction Items",
  "document.tab.custom_extraction_item.choose_extraction_type": "Choose Extraction Type",
  "document.tab.custom_extraction_item.choose_extraction_type_warning": "Please choose an extraction type",
  "document.tab.add_new_item": "Add New Extraction Item",

  "setting.tab.replace_master_image": "Replace Master Image:",
  "setting.tab.choose_a_file": "Choose a file",
  "setting.tab.export_form": "Export this form:",
  "setting.tab.download_as_zip": "Download as ZIP",
  "setting.tab.edit_post_process_script": "Edit Post Processing Script",
  "setting.tab.import_form": "Import form:",
  "setting.tab.import_from_zip": "Import from ZIP",
  "setting.tab.replace_current_form": "Replace the current form",
  "setting.tab.import_as_new_form": "Import as a new form",
  "setting.tab.confirm_replace_current_form": "Do you want to import and replace this form configuration? This cannot be undone.",
  "setting.tab.confirm_replace_dialog.title": "Import Form Configuration",
  "setting.tab.import": "Import",
  "setting.tab.file": "File",
  "setting.tab.advanced_form_settings": "Advanced Form Settings",
  "setting.tab.custom_models": "Custom Models",
  "setting.tab.custom_model.dropdown.placeholder": "Choose Custom Model",
  "setting.tab.custom_model.button.add": "Add Custom Model",
  "setting.tab.custom_model.error.empty": "Please choose a custom model",

  "document_type.general": "General",
  "document_type.bank_statement": "Bank Statement",
  "document_type.receipt": "Receipt",
  "document_type.address_proof": "Address Proof",
  "document_type.invoice": "Invoice",
  "document_type.international_id": "International ID",

  "auto_extraction_item.total_amount": "Total Amount",
  "auto_extraction_item.date": "Date",

  "auto_extraction_item.address_code": "Address Code",
  "auto_extraction_item.address_full": "Address",
  "auto_extraction_item.code_document": "Document Code",
  "auto_extraction_item.code_identity": "Identity Code",
  "auto_extraction_item.date_birth": "Date of Birth",
  "auto_extraction_item.date_expiry": "Date of Expiry",
  "auto_extraction_item.date_issue": "Issue Date",
  "auto_extraction_item.gender": "Gender",
  "auto_extraction_item.issuing_authority": "Issuing Authority",
  "auto_extraction_item.name_full": "Name",
  "auto_extraction_item.name_full_non_latin": "Name (Non-latin)",
  "auto_extraction_item.nationality": "Nationality",
  "auto_extraction_item.place_of_birth": "Place of birth",
  "auto_extraction_item.barcode": "Barcode / QR code",

  "auto_extraction_item.short_date": "Short Date",
  "auto_extraction_item.time": "Time",
  "auto_extraction_item.name": "Name",
  "auto_extraction_item.address": "Hong Kong Address",
  "auto_extraction_item.invoice_number": "Invoice Number",
  "auto_extraction_item.telephone": "Telephone",
  "auto_extraction_item.merchant_name": "Merchant Name",
  "auto_extraction_item.merchant_fax": "Fax",
  "auto_extraction_item.merchant_website": "Website",
  "auto_extraction_item.store_number": "Store Number",
  "auto_extraction_item.subtotal": "Subtotal",
  "auto_extraction_item.rounding": "Rounding",
  "auto_extraction_item.total_discount": "Total Discount",
  "auto_extraction_item.enter_time": "Enter Time",
  "auto_extraction_item.leave_time": "Leave Time",
  "auto_extraction_item.num_person": "Number of Person",
  "auto_extraction_item.credit_card_type": "Credit Card Type",
  "auto_extraction_item.credit_card_no": "Credit Card Number",
  "auto_extraction_item.product_info": "Product-Level Information",
  "auto_extraction_item.payment_info": "Payment Information",
  "auto_extraction_item.company": "Company Name",
  "auto_extraction_item.job_title": "Job Title",
  "auto_extraction_item.merchant": "Merchant Post-processing",
  "auto_extraction_item.detect_manipulation": "Detect Image Manipulation",
  "auto_extraction_item.table_extraction": "Table",
  "auto_extraction_item.issue_date": "Issue Date",
  "auto_extraction_item.ner_name": "Name (NER)",
  "auto_extraction_item.ner_address": "Hong Kong Address (NER)",
  "auto_extraction_item.invoice_date": "Invoice Date",
  "auto_extraction_item.due_date": "Due Date",
  "auto_extraction_item.bank_account_no": "Bank Account Number",
  "auto_extraction_item.bill_to_phone": "Customer Phone Number",
  "auto_extraction_item.reference_no": "Reference Number",
  "auto_extraction_item.purchase_order_no": "Purchase Order Number",
  "auto_extraction_item.detect_signature": "Signature",
  "auto_extraction_item.detect_portrait": "Portrait",
  "add.new.form": "Add New Form",
  "add.new.form_group": "Add New Form Group",
  "add.new.receipt": "Add New Receipt",
  "add.new.webhook": "Add new webhook",
  "import.new.form": "Import Form",
  "import.new.form_group": "Import Group",

  "label.token_group_name": "Token Group Name",
  "label.token_value": "Token Value",
  "label.text_token": "Text Token",
  "label.text_token.label": "Label",
  "label.image_token": "Image Token",
  "label.match_mode": "Match Mode",
  "label.match_one_only": "Match ONE closest token only",
  "label.match_all": "Match ALL tokens",
  "label.exact_match": "Exact Match",
  "label.token_type": "Token Type",
  "label.images": "Images",
  "label.texts": "Texts",
  "label.remove_whitespaces": "Remove Whitespaces",
  "dropdown.placeholder": "Select...",

  "key_value.modal.title": "Key-Value Settings",
  "key_value.modal.label": "Label",
  "key_value.modal.key": "Key",
  "key_value.modal.value": "Value",
  "key_value.modal.key.placeholder": "Token to aid locating the field",
  "key_value.modal.enable_fuzzy_search": "Enable Fuzzy Search",
  "key_value.modal.pattern": "Pattern (Regex to match, optional)",
  "key_value.modal.pattern_builder": "Builder",
  "key_value.modal.regex_builder": "Regex Builder",
  "key_value.modal.desc": "Detect a field with key & value using a \"Key Value\" processor. For Example",
  "key_value.modal.advance_usage": "Tips: If the regex pattern contains capturing groups, only the first's value will be returned. This is useful when you only want to extract part of the matched pattern.",

  "key_value.example.key": "Key",
  "key_value.example.value": "Value",
  "key_value.example.key.example": "First Name:",
  "key_value.example.value.example": "John",
  "key_value.example.desc": "Value Position = Right",

  "key_value.modal.label.duplicated_error": "Label has to be unique",
  "key_value.modal.label.empty_error": "Label cannot be empty",
  "key_value.modal.key.empty_error": "Key cannot be empty",
  "key_value.modal.key.invalid_regex_error": "Invalid regular expression",

  "key_value.modal.pattern_or_key_is_required_error": "At least one key or pattern is required",
  "key_value.modal.pattern.empty_error": "Pattern cannot be empty",
  "key_value.modal.pattern.invalid_regex_error": "Invalid regular expression",

  "key_value.modal.keys.title": "Keys",
  "key_value.modal.keys.empty": "Start by Adding New Key",
  "key_value.modal.keys.add": "+ Add New Key",
  "key_value.modal.keys.columns.key": "Key",
  "key_value.modal.keys.columns.fuzzy": "Fuzzy Search",
  "key_value.modal.keys.key_input.placeholder": "Enter Key",

  "text_token.model.title": "Text Tokens Settings",
  "text_token.modal.description_1": "Define one or multiple text tokens, and return the field value when it's detected.",
  "text_token.modal.description_2": "For example, if you define \"{McDonald}\" and \"{MacDonald}\" as Text Token, and {Mc} as Field Value, FormX will return {fieldNameExample} when those Text Tokens exists in the document.",
  "text_token.modal.add_tokens": "Add Tokens",
  "text_token.modal.add_token_button": "Add Token",
  "text_token.modal.header_1": "Text Token",
  "text_token.modal.header_2": "Field Value",
  "text_token.modal.header_3": "Exact Match",
  "text_token.modal.header_4": "Remove White Space",
  "text_token.modal.header_5": "Action",
  "text_token.modal.save_changes_button": "Save Changes",

  "image_token.model.title": "Image Tokens Settings",
  "image_token.modal.description": "Detect an image exists in the document anywhere.",
  "image_token.modal.header_1": "Image Uploaded",
  "image_token.modal.header_2": "Image Token Name",
  "image_token.modal.header_3": "Action",

  "minimal_field_modal.title": "{field_type} Settings",

  "key_value.card.pattern.any": "Any",

  "unsupport_browser_dialog.title": "Browser not supported",
  "unsupport_browser_dialog.message": "Some of the portal features are not optimized for this browser. Please use an up-to-date desktop browser.",
  "unsupport_browser_dialog.dismiss": "Dismiss",

  "command_bar_items.copy_form_id": "Copy Form ID",
  "command_bar_items.copy_access_token": "Copy Access Token",
  "command_bar_items.open_in_desktop_app": "Open in Desktop App",
  "command_bar_items.export_as_zip": "Export as ZIP",

  "teaching_bubble.title": "Before you start",

  "teaching_bubble.description": "It is recommended to learn how FormExtractor can be used under various scenarios and understand the basics. Head over to our help page for more information.",
  "teaching_bubble.lets_go": "Let's go",
  "teaching_bubble.next": "Next",
  "teaching_bubble.maybe_later": "Maybe later",
  "teaching_bubble.step": "{numerator} of {denominator}",

  "tutorial.labeller_tool.info.header": "Tutorial on Tool Bar of Labeller",
  "tutorial.labeller_tool.info.content": "The bar below provides three tools for you to draw on your master form. By clicking the go button, you will be briefed with each's functionality and usage.",
  "tutorial.labeller_tool.select.header": "Select Tool",
  "tutorial.labeller_tool.select.content": "This tool allows you to select marked areas, either an Anchor (red) or Detection Region (blue). Once selected, you can move one around, press the delete key to get rid of it, or resize by pulling the corners.",
  "tutorial.labeller_tool.anchor.header": "Anchor Tool",
  "tutorial.labeller_tool.anchor.content": "An Anchor is a position keeper primary used in FormX's warping process. To yield favorable extraction results, you need at least 2 of them drawn. With this tool selected, each click on the master form creates a dot. Connecting the final dot with the first completes an Anchor.",
  "tutorial.labeller_tool.detection_region.header": "Detection Region Tool",
  "tutorial.labeller_tool.detection_region.content": "To extract information from an area, mark it with a Detection Region. With this tool selected, you can pull to draw a rectangle box where extraction(s) will be carried out upon this box.",

  "webhook.title": "Webhooks",
  "webhook.no_webhook_yet": "You don’t have a webhook integration yet.",
  "webhook.add": "Add a webhook",
  "webhook.new.title": "New Webhook",
  "webhook.name": "Webhook Name",
  "webhook.name.placeholder": "e.g. Zapier",
  "webhook.url": "Webhook URL",
  "webhook.connect_with": "Connect with",
  "webhook.connect_with.form": "Form",
  "webhook.connect_with.form_group": "Form Group",
  "webhook.creating_webhook": "Creating webhook...",
  "webhook.created": "New webhook integration is created. Connect to a form to get started.",
  "webhook.list.edit": "Edit",
  "webhook.list.webhooks": "Webhooks",
  "webhook.list.connected_with": "Connected with",
  "webhook.list.action": "Action",
  "webhook.edit.title": "Edit Webhook",
  "webhook.edit.fetching": "Fetching Webhook...",
  "webhook.edit.updating": "Updating Webhook...",
  "webhook.edit.success": "Webhook is updated.",
  "webhook.edit.fail": "Fail to update webhook.",
  "webhook.edit.remove.title": "Delete Webhook",
  "webhook.edit.remove.message": "Are you sure to delete this webhook?",
  "webhook.edit.remove.action": "Delete",
  "webhook.edit.remove.success": "Webhook is deleted.",
  "webhook.edit.remove.fail": "Fail to delete webhook.",
  "webhook.edit.fetch_forms_form_groups_fail": "Fail to fetch forms and form groups.",
  "webhook.modified_prompt.title": "This webhook has been modified",
  "webhook.modified_prompt.desc": "This webhook has been modified outside this tab since you open it. Are you sure you want to save it?",

  "invite.new.member": "Invite",
  "invite.new.member.title": "Invite a team member",
  "invite.new.member.access": "Access",
  "invite.new.member.success_message": "Invite has been sent to {email}.",
  "invite.new.member.fail_message": "Fail to send invite to {email}.",
  "invite.new.member.inviting": "Inviting...",

  "team.members.header": "Team Members - {team}",
  "team.region": "Server Location: {region}",
  "team.creating_team": "Creating team...",
  "team.detail.title": "Team Details",
  "team.detail.region": "Region",
  "team.detail.lookup_id": "Lookup ID",
  "team.detail.created_at": "Created at",
  "team.detail.total_usage": "Total usage",
  "team.detail.last_usage_at": "Last usage at",
  "team.detail.members": "Members",
  "team.detail.usage": "Usage",
  "team.detail.resources": "Resources",
  "team.detail.plan": "Plan",
  "team.detail.forms": "Forms",
  "team.detail.form_groups": "Form Groups",
  "team.detail.custom_models": "Custom Models",
  "team.detail.resources.name": "Name",
  "team.detail.resources.form_id": "Form ID",
  "team.detail.resources.form_group_id": "Form Group ID",
  "team.detail.resources.mode": "Mode",
  "team.detail.resources.form_group_type.anchor": "Anchors",
  "team.detail.resources.form_group_type.token": "Tokens",
  "team.detail.resources.no_of_picture_uploaded": "# of pictures uploaded",
  "team.detail.resources.model_id": "Model ID",
  "team.detail.resources.status": "Status",
  "team.detail.resources.forms.empty": "No forms yet.",
  "team.detail.resources.form_groups.empty": "No form groups yet.",
  "team.detail.resources.custom_models.empty": "No custom models yet.",
  "team.detail.resources.custom_model.status.not_synced": "Not synced",
  "team.detail.resources.custom_model.status.labelling": "Labelling",
  "team.detail.resources.custom_model.status.training": "Training",
  "team.detail.resources.custom_model.status.trained": "Trained",
  "team.detail.current_plan": "Current Plan",
  "team.detail.quota": "Quota",
  "team.detail.end_at": "End at",
  "team.detail.plan.quota.unlimited": "Unlimited",
  "team.detail.plan.quota.annually": "{quota} (Annually)",
  "team.detail.plan.quota.monthly": "{quota} (Monthly)",
  "team.detail.plan.quota.daily": "{quota} (Daily)",
  "team.detail.plan.remove_trial": "Remove Trial",
  "team.detail.plan.give_trial": "Give Trial",
  "team.detail.plan.save": "Save",
  "team.detail.plan.trial_model": "Trial Mode",
  "team.detail.plan.no_of_pages": "Number of pages",
  "team.detail.plan.trial_indefinitely": "Trial indefinitely",
  "team.detail.plan.set_trial.success": "The team plan has been set to trial.",
  "team.detail.plan.clear_trial": "Clear Trial",
  "team.detail.plan.are_you_sure_to_clear_trial": "Are you sure to clear trial?",
  "team.detail.plan.clear": "Clear",
  "team.detail.plan.clear_trial.success": "The team plan has been set to free.",
  "team.lookup_id": "Team ID",
  "team.lookup_id.smol": "Team ID: {id}",
  "team.lookup_id.desc": "Team ID is used to uniquely identify your team. It cannot be changed once set.",
  "team.rename": "Rename Team",
  "team.renaming": "Renaming...",
  "team.rename.success_message": "Team renamed to {team}.",
  "team.rename.fail_message": "Fail to rename team.",
  "team.delete": "Delete Team",
  "team.deleting": "Deleting team...",
  "team.delete.fail_message": "Fail to delete team",
  "team.delete.confirm": "Are you sure to delete this team? All resources including forms and form groups will be deleted. This action cannot be undone.",
  "team.delete.plan_is_not_free.title": "Current Subscription",
  "team.delete.plan_is_not_free.message": "You are currently using a paid subscription. Please cancel your subscription before deleting your team.",
  "team.change.permission.title": "Losing admin permission",
  "team.change.admin_permission.message": "Are you sure to change your own permission? You will lose all administrative privileges of this team, including adding or removing members.",
  "team.list.no_team_yet": "No team yet.",
  "team.list.title": "Teams",
  "team.list.team": "Team",
  "team.list.region": "Region",
  "team.list.contact_email": "Contact Email",
  "team.list.plan": "Plan",
  "team.list.created_at": "Created At",
  "team.list.search_team_name": "Team Name",
  "team.list.search_team_email": "Team Email",
  "team.list.all": "All",
  "team.list.search": "Search",
  "team.list.search_by": "Search By",
  "team.remove": "Remove",
  "team.remove.fail_message": "Fail to remove team.",
  "team.remove_user": "Remove team member",
  "team.remove_user.confirm": "Are you sure you want to remove {email} from {team}?",
  "team.remove_user.success_message": "Team member removed.",
  "team.remove_user.unexpected_error": "Unexpected error occurred while removing user.",
  "team.column.status": "Status",
  "team.column.role": "Role",
  "team.role.admin": "Admin",
  "team.role.editor": "Editor",
  "team.role.read_only": "Read only",
  "team.role.custom": "Custom",
  "team.role.admin.with_article": "an Admin",
  "team.role.editor.with_article": "an Editor",
  "team.role.read_only.with_article": "a Read-only Member",
  "team.role.custom.with_article": "a Custom Member",
  "team.role.failed_to_update": "Failed to update team role.",
  "team.role.updated": "{email} is changed to {role}",
  "team.modified_prompt.title": "Team has been modified",
  "team.modified_prompt.desc": "Your team's configuration has been modified outside this tab since you open it. Are you sure you want to save it?",
  "team.invitation.invalid": "Invite Invalid",
  "team.invitation.invalid.message": "This invite link is invalid or expired. Please contact the admin to resend an invite.",
  "team.invitation.continue_to_formx": "Continue to FormX",
  "team.invitation.logged_out": "You're invited to join {team} on FormX",
  "team.invitation.logged_out.message": "You will need to create a new FormX account or login to your existing account",
  "team.invitation.account_not_match": "The invitee email does not match your logged-in email",
  "team.invitation.account_not_match.message": "Try login with a different user?",
  "team.invitation.account_not_match.login": "Login with another user",
  "team.invitation.received": "You're invited to join {team}",
  "team.invitation.received.message": "Click the button below to join {team} as {role}",
  "team.invitation.accept_invitation": "Accept Invitation",
  "team.invitation.remove.success_message": "Invitation removed.",
  "team.invitation.remove": "Remove invitation",
  "team.invitation.remove.unexpected_error": "Unexpected error occurred while removing invitation.",
  "team.invitation.remove.confirm": "Are you sure you want to remove the invitation to {email} from {team}?",
  "team.invitation.pending": "Pending",
  "team.invitation.accepted": "Accepted",
  "team.invitation.email.oneself": "{email} (You)",

  "multi.region.tw-gcp": "Taiwan",
  "multi.region.sg-gcp": "Singapore",
  "multi.region.default": "Default",

  "onboarding.welcome_message": "Let's start by creating a team.",

  "audit.header.title": "Audit Log",
  "audit.header.description": "Download the audit log as a CSV file",
  "audit.datepicker.year.label": "Year",
  "audit.datepicker.month.label": "Month",
  "audit.datepicker.month.option.m1": "01 - January",
  "audit.datepicker.month.option.m2": "02 - February",
  "audit.datepicker.month.option.m3": "03 - March",
  "audit.datepicker.month.option.m4": "04 - April",
  "audit.datepicker.month.option.m5": "05 - May",
  "audit.datepicker.month.option.m6": "06 - June",
  "audit.datepicker.month.option.m7": "07 - July",
  "audit.datepicker.month.option.m8": "08 - August",
  "audit.datepicker.month.option.m9": "09 - September",
  "audit.datepicker.month.option.m10": "10 - October",
  "audit.datepicker.month.option.m11": "11 - November",
  "audit.datepicker.month.option.m12": "12 - December",
  "audit.download": "Download",
  "audit.download.preparing": "Preparing",
  "audit.download.update": "Update file",
  "audit.download.generate": "Generate file",
  "audit.download.generating": "Generating...",
  "audit.download.generating.file": "Generating file",
  "audit.download.generating.message": "The file is being generated. Please come back in a few minutes.",
  "audit.download.error": "There is no logs for the selected month",
  "audit.table.header": "Date",
  "audit.table.incomplete": " (Incomplete)"
}
