interface MenuOptions {
  label: string;
}

export function localizedMenuOptions(
  localized: (key: string) => string,
  options: MenuOptions[]
) {
  return options.map(x => ({ ...x, label: localized(x.label) }));
}
