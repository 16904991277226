@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.detection-region-field {
  width: 100%;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid $tab-bar-separator;
  display: flex;
  flex: 1;
  flex-direction: column;

  p {
    font-size: 10pt;
  }

  .field-type-select-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .field-type-select {
      width: calc(100% - 36px);
    }

    .delete-button {
      @include icon-frame-size(24px);
      cursor: pointer;
      margin-top: 16px;
    }
  }

  .extra-button {
    margin-top: 12px;
    align-self: flex-start;
  }
}

.detection-region-field:last-child {
  border: 0;
}

.detection-region-field::after {
  content: "";
  clear: both;
  display: table;
}

.action-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: -4px;
}

.action-button:disabled {
  :global .ms-Icon {
    color: rgb(180, 178, 176);
  }
}

.action-button {
  :global .ms-Icon {
    color: #464646;
  }
}
