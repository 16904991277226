import * as React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

const MultiDocumentIndicator = () => {
  const { localized } = useLocale();
  return (
    <div className={styles["text"]}>
      {localized("ocr_test.multi_document.indicator")}
    </div>
  );
};

export default MultiDocumentIndicator;
