@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.header {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 43px;

  .logo {
    position: relative;
    float: left;
    margin: 0 12px;
    @include no-select;
  }

  .space {
    display: flex;
    flex: 1;
  }

  .active-button {
    background-color: $theme-dark-alt;
  }

  .command-button {
    height: 43px;
    min-width: 43px;
    margin-right: 0px;
    padding: 0px;
    border-width: 0px;
    color: $primary-text-color !important;

    > span {
      padding: 0px 12px;
    }

    > span > i {
      color: $primary-text-color !important;
    }
    > span > span > span {
      max-width: 240px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 28px;
    }
  }

  .user-button {
    > span > i {
      font-size: 14px;
    }
    > span > i:after {
      content: "";
      position: relative;
      display: block;
      width: 26px;
      height: 26px;
      border: 1px solid #fff;
      top: -22px;
      border-radius: 14px;
    }
  }

  .menu {
    display: inline-block;
  }

  .right {
    float: right;
  }

  .icon-button {
    margin-left: 16px;
    margin-right: 16px;
    color: #fff;
    cursor: pointer;
  }
}

.team-menu {
  min-width: 160px;
  max-width: 240px;
}

.dropdown-menu {
  min-width: 128px;
  max-width: 240px;
}

.username {
  margin-right: 12px;
  color: $primary-text-color !important;
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 28px;
  font-size: 14px;
}
