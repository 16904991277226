import * as React from "react";
import { useParams } from "react-router";

import { getExtractAPIDescription } from "../apidoc/extract";
import APINote from "../components/APINote";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FormGroupNavBarLayout } from "../components/FormGroupNavBar";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { useAPICommandBarItems } from "../hooks/command_bar_items";
import { useCommonFormGroupContainerState } from "../hooks/form_group";

type PathParam = {
  formGroupId: string;
};

export const FormGroupAPIContainer = React.memo(() => {
  const { formGroupId } = useParams<PathParam>();

  const containerState = useCommonFormGroupContainerState(formGroupId);

  const commandBarItems = useAPICommandBarItems(
    ...(containerState.state === "success"
      ? [containerState.formGroup.id, containerState.workerToken]
      : [undefined, undefined])
  );
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
        ) : containerState.state === "success" ? (
          <FormGroupNavBarLayout
            formGroup={containerState.formGroup}
            commbarBarItems={commandBarItems}
          >
            <APINote
              getDescription={getExtractAPIDescription}
              shouldShowAsync={true}
              shouldShowMultiDocument={true}
            />
          </FormGroupNavBarLayout>
        ) : null}
      </Main>
    </Layout>
  );
});

export default FormGroupAPIContainer;
