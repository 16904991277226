import { CardElement, Elements, useElements } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { disablePaymentRequiredToast, fetchPlans } from "../actions/app";
import {
  checkIsPaymentRequired,
  setPaymentMethod,
  subscribePlan,
  unsubscribe,
  useResourceOwnerActionCreator,
} from "../actions/resourceOwner";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import Payment from "../components/Payment";
import HeaderContainer from "../containers/Header";
import { FOCRError } from "../errors";
import { useToast } from "../hooks/toast";
import { RootState, ThunkActionCreatorType } from "../redux/types";
import { Plan, isSubscribablePlan } from "../types/plan";
import { SubscriptionData, User } from "../types/user";
import { getStripe, getStripeError } from "../utils/stripe";

interface Props {
  location: Location;
  currentUser: User;
  plans: Plan[];
  subscribePlan: ThunkActionCreatorType<typeof subscribePlan>;
  setPaymentMethod: ThunkActionCreatorType<typeof setPaymentMethod>;
  unsubscribe: ThunkActionCreatorType<typeof unsubscribe>;
  checkIsPaymentRequired: ThunkActionCreatorType<typeof checkIsPaymentRequired>;
  disablePaymentRequiredToast: ThunkActionCreatorType<
    typeof disablePaymentRequiredToast
  >;
  fetchPlans: ThunkActionCreatorType<typeof fetchPlans>;
}

function usePlanIdSelection(plans: Plan[]) {
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    plans.forEach(plan => {
      if (plan.name === "starter") {
        setSelectedPlanId(plan.id);
      }
    });
  }, [plans]);

  return React.useMemo(
    () => ({
      selectedPlanId,
    }),
    [selectedPlanId]
  );
}

const PaymentContainerImpl = React.memo((props: Props) => {
  const {
    location,
    currentUser,
    subscribePlan,
    unsubscribe,
    setPaymentMethod,
    disablePaymentRequiredToast,
    checkIsPaymentRequired,
    fetchPlans,
    plans,
    ...restProps
  } = props;

  const plan = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.plan
  );
  const subscriptionData = useSelector<RootState, SubscriptionData | undefined>(
    state => state.resourceOwner.subscriptionData
  );
  const { getSubscriptionData } = useResourceOwnerActionCreator();

  const isSubscribed = plan !== undefined && isSubscribablePlan(plan);

  const [isLoading, setIsLoading] = useState(true);
  const [isCardReady, setIsCardReady] = useState<boolean>(false);
  const [errorId, setErrorId] = useState<string | undefined>(undefined);

  const [isSelectedNewcard, setIsSelectedNewcard] = useState<boolean>(
    subscriptionData ? subscriptionData.paymentMethod === undefined : true
  );

  const elements = useElements();
  const toast = useToast();

  const { selectedPlanId } = usePlanIdSelection(plans);

  const userEmail = useSelector(
    (state: RootState) => state.user.currentUser?.email
  );

  const [billingEmail, setBillingEmail] = React.useState("");

  const shouldUpdateEmail = React.useMemo(
    () => subscriptionData?.billingEmail !== billingEmail,
    [billingEmail, subscriptionData]
  );

  const onBillingEmailChange = useCallback(
    (
      _e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string | undefined
    ) => {
      if (newValue) {
        setBillingEmail(newValue);
      }
    },
    [setBillingEmail]
  );

  useEffect(() => {
    setIsSelectedNewcard(
      subscriptionData ? subscriptionData.paymentMethod === undefined : true
    );
  }, [subscriptionData]);

  useEffect(() => {
    setBillingEmail(
      subscriptionData?.billingEmail
        ? subscriptionData.billingEmail
        : userEmail ?? ""
    );
  }, [subscriptionData, userEmail]);

  useEffect(() => {
    if (!subscriptionData) getSubscriptionData();
  }, [subscriptionData, getSubscriptionData]);

  useEffect(() => {
    disablePaymentRequiredToast();
    fetchPlans();

    getStripe()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("error.stripe.load.failure.try.refresh");
      });
  }, [toast, disablePaymentRequiredToast, fetchPlans]);

  const onBillingEmailSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      await setPaymentMethod(undefined, billingEmail);
    } catch (e) {
      if (e instanceof FOCRError) {
        toast.error(e.messageId);
      } else {
        toast.error("error.subscription.unexpected");
      }
    }
    setIsLoading(false);
  }, [billingEmail, setPaymentMethod, toast]);

  const onSubmit = useCallback(async () => {
    if (elements === null) {
      toast.error("error.subscription.stripe.element.not.init");
      return;
    }

    const card = elements.getElement(CardElement);

    if (card === null) {
      toast.error("error.subscription.stripe.no.card.element");
      return;
    }
    setIsLoading(true);

    try {
      const shouldSetPaymentMethod =
        (!isSubscribed && isSelectedNewcard) || isSubscribed;

      if (shouldSetPaymentMethod) {
        await setPaymentMethod(card, billingEmail);
        card.clear();
      } else if (shouldUpdateEmail) {
        await setPaymentMethod(undefined, billingEmail);
      }

      if (!isSubscribed && selectedPlanId !== undefined) {
        await subscribePlan(selectedPlanId);
      }
    } catch (e) {
      if (e instanceof FOCRError) {
        toast.error(e.messageId);
      } else {
        toast.error("error.subscription.unexpected");
      }
    }

    setIsLoading(false);
  }, [
    elements,
    toast,
    isSubscribed,
    isSelectedNewcard,
    shouldUpdateEmail,
    selectedPlanId,
    setPaymentMethod,
    billingEmail,
    subscribePlan,
  ]);

  const onCardInputChange = useCallback(
    (event: StripeCardElementChangeEvent) => {
      if (event.error) {
        const errorId = getStripeError(event.error.code);
        setErrorId(errorId);
      } else {
        setErrorId(undefined);
      }

      setIsCardReady(event.complete);
    },
    []
  );

  const onSelectCard = useCallback(isNewCard => {
    setIsSelectedNewcard(isNewCard);
  }, []);

  const onUnsubscribe = useCallback(() => {
    setIsLoading(true);
    unsubscribe()
      .then(() => {
        checkIsPaymentRequired().finally(() => {
          // tslint:disable-line:no-floating-promises
          setIsLoading(false);
          setIsSelectedNewcard(false);
        });
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("error.subscription.unexpected");
      });
  }, [unsubscribe, checkIsPaymentRequired, toast]);

  const onSubscribeEnterprisePlanClicked = useCallback(() => {
    window.open(
      "https://www.formx.ai/talk-with-us?utm_campaign=portal_payment",
      "_blank"
    );
  }, []);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <>
        <Main hasTop={true}>
          <Payment
            subscriptionData={subscriptionData}
            plans={plans}
            selectedPlanId={selectedPlanId}
            onSubmit={onSubmit}
            onCardInputChange={onCardInputChange}
            onUnsubscribe={onUnsubscribe}
            errorId={errorId}
            billingEmail={billingEmail}
            shouldUpdateEmail={shouldUpdateEmail}
            isSubscribed={isSubscribed}
            isCardReady={isCardReady}
            isSelectedNewcard={isSelectedNewcard}
            onSelectCard={onSelectCard}
            onSubscribeEnterprisePlanClicked={onSubscribeEnterprisePlanClicked}
            onBillingEmailChange={onBillingEmailChange}
            onBillingEmailSubmit={onBillingEmailSubmit}
            {...restProps}
          />
        </Main>
      </>
      <LoadingModal
        messageId="common.loading"
        isOpen={isLoading || plans.length === 0}
      />
    </Layout>
  );
});

function mapStateToProps(state: RootState) {
  const { currentUser } = state.user;
  const { plans } = state.app;

  return {
    currentUser,
    plans,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        subscribePlan,
        setPaymentMethod,
        unsubscribe,
        disablePaymentRequiredToast,
        checkIsPaymentRequired,
        fetchPlans,
      },
      dispatch
    ),
  };
}

const PaymentContainer = React.memo((props: Props) => {
  return (
    <Elements stripe={getStripe()}>
      <PaymentContainerImpl {...props} />
    </Elements>
  );
});

export default connect(
  mapStateToProps as any,
  mapDispatchToProps
)(PaymentContainer);
