@import "../../styles/variables.scss";

$nav-bar-height: 96px;
$pivot-max-width: 200px;
.container {
  padding-top: 4px;
  padding-left: 12px;
  height: $nav-bar-height;
  background-color: $bgcolor;

  .breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;

    .breadcrumb-text-item {
      padding: 0px 10px;
    }

    .breadcrumb-arrow {
      font-size: 12px;
    }
  }

  .tab-bar {
    position: relative;

    :global(.ms-Pivot) {
      position: absolute;
      max-width: $pivot-max-width;
    }
    :global(.ms-CommandBar) {
      float: right;
      margin-left: $pivot-max-width;
    }
  }
}

.content {
  height: calc(100% - #{$nav-bar-height});
}

.placeholder-container {
  height: calc(100% - #{$nav-bar-height});
  padding-bottom: $nav-bar-height;
}

.receipt-dropdown {
  width: 250px;
  margin: 0px 10px;
}

.receipt-dropdown-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-receipt-group-button {
  color: $darker-primary-color;
  font-size: 18px;
  cursor: pointer;
}
