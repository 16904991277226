import {
  FetchReceiptGroupListStarted,
  FirstReceiptGroupSelected,
  GotReceiptGroup,
  GotReceiptGroupList,
  GotReceiptGroupListAction,
  ReceiptFormRemoved,
  ReceiptGroupCreated,
  ReceiptGroupRemoved,
  ReceiptGroupSelected,
  UpdatedReceiptForm,
  UpdatedReceiptGroup,
} from "../actions/receiptGroup";
import {
  CreateTeam,
  TeamInvitationAccepted,
  TeamUserRemoved,
} from "../actions/team";
import { RootAction } from "../redux/types";
import { BriefForm, BriefFormWithType } from "../types/form";
import { PageInfo } from "../types/pageInfo";
import { ReceiptGroup, ReceiptGroupWithType } from "../types/receiptGroup";

export interface ReceiptGroupState {
  readonly receiptGroups: ReceiptGroupWithType[];
  readonly forms: BriefFormWithType[];
  readonly areReceiptGroupsFetched: boolean;
  readonly pageInfo?: PageInfo;
  readonly isFetching: boolean;
  readonly currentReceiptGroup?: ReceiptGroupWithType | BriefFormWithType;
}

const defaultState: ReceiptGroupState = {
  receiptGroups: [],
  forms: [],
  areReceiptGroupsFetched: false,
  isFetching: false,
};

function addTypeToReceiptGroup(
  receiptGroup: ReceiptGroup
): ReceiptGroupWithType {
  return {
    ...receiptGroup,
    type: "receipt_group",
  };
}

function addTypeToForm(form: BriefForm): BriefFormWithType {
  return {
    ...form,
    type: "form",
  };
}

function upsertReceiptGroups(
  prevReceiptGroups: ReceiptGroupWithType[],
  newReceiptGroups: ReceiptGroup[]
) {
  const results = [...prevReceiptGroups];
  newReceiptGroups.forEach(newReceiptGroup => {
    const newGroupWithType = addTypeToReceiptGroup(newReceiptGroup);
    const existingGroupIndex = results.findIndex(
      existGroup => existGroup.id === newReceiptGroup.id
    );
    if (existingGroupIndex >= 0) {
      results[existingGroupIndex] = newGroupWithType;
    } else {
      results.push(newGroupWithType);
    }
  });
  return results;
}

function upsertReceiptForms(
  prevReceiptForms: BriefFormWithType[],
  newReceiptForms: BriefForm[]
) {
  const results = [...prevReceiptForms];
  newReceiptForms.forEach(newReceiptForm => {
    const newFormWithType = addTypeToForm(newReceiptForm);
    const existingFormIndex = results.findIndex(
      existForm => existForm.id === newFormWithType.id
    );
    if (existingFormIndex >= 0) {
      results[existingFormIndex] = newFormWithType;
    } else {
      results.push(newFormWithType);
    }
  });
  return results;
}

function handleGotReceiptGroupList(
  state: ReceiptGroupState,
  action: GotReceiptGroupListAction
): ReceiptGroupState {
  const { receiptGroups, forms, pageInfo } = action.payload;

  const updatedReceiptGroups = upsertReceiptGroups(
    state.receiptGroups,
    receiptGroups
  );

  const updatedReceiptForms = upsertReceiptForms(state.forms, forms);

  return {
    ...state,
    receiptGroups: updatedReceiptGroups,
    forms: updatedReceiptForms,
    areReceiptGroupsFetched: true,
    pageInfo: {
      ...pageInfo,
    },
    isFetching: false,
  };
}

export function reducer(
  state: ReceiptGroupState = defaultState,
  action: RootAction
): ReceiptGroupState {
  switch (action.type) {
    case FetchReceiptGroupListStarted:
      return {
        ...state,
        isFetching: true,
      };
    case GotReceiptGroupList:
      return handleGotReceiptGroupList(state, action);

    case ReceiptGroupSelected:
      return {
        ...state,
        currentReceiptGroup: action.payload.receiptGroup,
      };

    case FirstReceiptGroupSelected: {
      const receiptGroup: BriefFormWithType | ReceiptGroupWithType | undefined =
        state.forms[0] || state.receiptGroups[0];
      return {
        ...state,
        currentReceiptGroup: receiptGroup,
      };
    }

    case ReceiptGroupCreated: {
      const form = addTypeToForm(action.payload.form);
      return {
        ...state,
        forms: [form, ...state.forms],
        currentReceiptGroup: form,
      };
    }
    case GotReceiptGroup:
      return {
        ...state,
        currentReceiptGroup: addTypeToReceiptGroup(action.payload.receiptGroup),
      };

    case ReceiptGroupRemoved:
      return {
        ...state,
        receiptGroups: state.receiptGroups.filter(
          x => x.id !== action.payload.receiptGroupId
        ),
        currentReceiptGroup:
          state.currentReceiptGroup &&
          state.currentReceiptGroup.id === action.payload.receiptGroupId
            ? undefined
            : state.currentReceiptGroup,
      };

    case ReceiptFormRemoved:
      return {
        ...state,
        forms: state.forms.filter(x => x.id !== action.payload.formId),
        currentReceiptGroup:
          state.currentReceiptGroup &&
          state.currentReceiptGroup.id === action.payload.formId
            ? undefined
            : state.currentReceiptGroup,
      };

    case UpdatedReceiptGroup: {
      const receiptGroupWithType = addTypeToReceiptGroup(
        action.payload.receiptGroup
      );
      return {
        ...state,
        receiptGroups: state.receiptGroups.map(x => {
          if (x.id === action.payload.receiptGroup.id) {
            return receiptGroupWithType;
          } else {
            return x;
          }
        }),
        currentReceiptGroup: receiptGroupWithType,
      };
    }

    case UpdatedReceiptForm: {
      const receiptFormWithType = addTypeToForm(action.payload.form);
      return {
        ...state,
        forms: state.forms.map(x => {
          if (x.id === action.payload.form.id) {
            return receiptFormWithType;
          } else {
            return x;
          }
        }),
        currentReceiptGroup: receiptFormWithType,
      };
    }

    case TeamUserRemoved: {
      const { removedUserId, currentUser } = action;
      if (removedUserId === currentUser.id) {
        return {
          ...defaultState,
        };
      } else {
        return state;
      }
    }

    case "UserLogin":
    case "UserLogout":
    case "SelectTeam":
    case CreateTeam:
    case TeamInvitationAccepted:
    case "TeamDeleted":
      return {
        ...defaultState,
      };

    default:
      return state;
  }
}
