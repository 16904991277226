@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.editor-container {
  border: 1px solid $tab-bar-separator;
  height: 300px;
  min-width: 500px;
}

@media only screen and (min-width: 600px) and (max-width: 1300px) {
  .editor-container {
    width: 800px;
  }
}

@media only screen and (min-width: 1300px) {
  .editor-container {
    width: 1200px;
  }
}

.editor-expanded {
  height: 610px;
}

.tab-content {
  overflow-y: scroll;
  padding: 12px 20px;
  border: 1px solid $tab-bar-separator;

  margin-top: 10px;
  height: 250px;
}

.section-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;

  @include no-select();

  * {
    cursor: pointer;
  }

  > i {
    font-size: 10px;
    padding-right: 8px;
  }

  > label {
    font-size: 12px;
  }

  .expanded {
    transform: scaleY(-1);
  }
}

.water-mark-setting {
  margin-top: 16px;
}
