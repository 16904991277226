import {
  DisablePaymentRequiredToast,
  GotInvitationCode,
  GotPlanList,
  Initialized,
  ToggleLeftBarCollapsed,
  UpgradeBarDismissed,
} from "../actions/app";
import { RootAction } from "../redux/types";
import { ConfirmationTask } from "../types/confirmation";
import { Plan } from "../types/plan";

export interface AppState {
  readonly isInitialized: boolean;
  readonly shouldShowPaymentRequiredToast: boolean;
  readonly isLeftBarCollapsed: boolean;
  readonly isUpgradeBarDismissed: boolean;
  readonly plans: Plan[];
  readonly invitationCode?: string;
  readonly isShowingConfirmModal: boolean;
  readonly pendingConfirmationTask?: ConfirmationTask;
  readonly comfirmModalCleanUpTimerId?: number;
}

export const defaultState = {
  isInitialized: false,
  shouldShowPaymentRequiredToast: true,
  isLeftBarCollapsed: false,
  isUpgradeBarDismissed: false,
  plans: [],
  isTeam: false,
  invitationCode: undefined,
  isShowingConfirmModal: false,
};
export function reducer(
  state: AppState = defaultState,
  action: RootAction
): AppState {
  switch (action.type) {
    case "ShowConfirmModal":
      return {
        ...state,
        isShowingConfirmModal: true,
        pendingConfirmationTask: action.task,
      };
    case "HideConfirmModal":
      return {
        ...state,
        isShowingConfirmModal: false,
      };
    case "CleanUpConfirmModal":
      return {
        ...state,
        pendingConfirmationTask: undefined,
      };
    case Initialized:
      return {
        ...state,
        isInitialized: true,
      };
    case DisablePaymentRequiredToast:
      return {
        ...state,
        shouldShowPaymentRequiredToast: false,
      };
    case ToggleLeftBarCollapsed:
      return {
        ...state,
        isLeftBarCollapsed:
          action.value === undefined ? !state.isLeftBarCollapsed : action.value,
      };
    case UpgradeBarDismissed:
      return {
        ...state,
        isUpgradeBarDismissed: true,
      };
    case GotPlanList:
      return {
        ...state,
        plans: action.payload.plans,
      };
    case GotInvitationCode:
      return {
        ...state,
        invitationCode: action.invitationCode,
      };
  }

  return state;
}
