import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { KeyValueTabPaneProvider } from "../../contexts/keyValueTab";
import { TokenGroupTabPaneProvider } from "../../contexts/tokenGroupTabPane";
import { useTeamPermission } from "../../hooks/permission";
import { useToast } from "../../hooks/toast";
import { BriefForm, DetailedForm } from "../../types/form";
import { KeyValue } from "../../types/keyValue";
import { TokenGroup } from "../../types/tokenGroup";
import { ExternalServicesAlertBoxForReceipt } from "../ExternalServiceAlertBox";
import KeyValueTabPane from "../KeyValueTabPane";
import ShortSpinner from "../ShortSpinner";
import TokenGroupTabPane from "../TokenGroupTabPane";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface Props {
  receiptForm: BriefForm;
  onRenameReceiptForm: () => void;
  getReceiptForm: (formId: string) => Promise<DetailedForm>;
  updateReceiptForm: (
    formId: string,
    newValues: {
      name: string;
      tokenGroups: TokenGroup[];
      keyValues: KeyValue[];
      updatedAt: string;
    }
  ) => Promise<BriefForm>;
}

function ReceiptFormNameEditor(props: Props) {
  const { receiptForm, onRenameReceiptForm } = props;
  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <>
      <h1>
        <FormattedMessage id="receipt_group_editor.name" />
      </h1>
      <div className={styles["section"]}>
        <Label className={styles["group-name"]}>{receiptForm.name}</Label>
        {hasPermissionToEditResource && (
          <DefaultButton
            textId="receipt_group_editor.rename"
            onClick={onRenameReceiptForm}
          />
        )}
      </div>
    </>
  );
}

interface ReceiptKeyValuesEditorProps {
  detailForm: DetailedForm;
  updateFormKeyValues: (keyValues: KeyValue[]) => Promise<void>;
}

function ReceiptKeyValuesEditor(props: ReceiptKeyValuesEditorProps) {
  const { detailForm, updateFormKeyValues } = props;
  return (
    <>
      <h1>
        <FormattedMessage id="receipt_group_editor.key_values" />
      </h1>
      <div className={styles["section"]}>
        <div className={styles["edit-section-container"]}>
          <KeyValueTabPaneProvider
            keyValues={detailForm.keyValues}
            form={detailForm}
            updateFormKeyValues={updateFormKeyValues}
          >
            <KeyValueTabPane keyValues={detailForm.keyValues} />
          </KeyValueTabPaneProvider>
        </div>
      </div>
    </>
  );
}

interface ReceiptTokenGroupEditorProps {
  detailForm: DetailedForm;
  updateFormTokenGroups: (tokenGroups: TokenGroup[]) => Promise<void>;
}

function ReceiptTokenGroupEditor(props: ReceiptTokenGroupEditorProps) {
  const { detailForm, updateFormTokenGroups } = props;
  return (
    <>
      <h1>
        <FormattedMessage id="receipt_group_editor.token_groups" />
      </h1>
      <div className={styles["section"]}>
        <div className={styles["edit-section-container"]}>
          <TokenGroupTabPaneProvider
            tokenGroups={detailForm.tokenGroups}
            updateFormTokenGroups={updateFormTokenGroups}
          >
            <TokenGroupTabPane tokenGroups={detailForm.tokenGroups} />
          </TokenGroupTabPaneProvider>
        </div>
      </div>
    </>
  );
}

const ReceiptFormEditor = React.memo((props: Props) => {
  const { receiptForm, getReceiptForm, updateReceiptForm } = props;

  const toast = useToast();

  const [detailForm, setDetailForm] = React.useState<DetailedForm | undefined>(
    undefined
  );
  const [isFetchingForm, setIsFetchingForm] = React.useState<boolean>(false);
  const [isUpdatingForm, setIsUpdatingForm] = React.useState<boolean>(false);

  const fetchFormDetail = React.useCallback(async () => {
    if (isFetchingForm) return;
    setIsFetchingForm(true);
    try {
      const detailedForm = await getReceiptForm(receiptForm.id);
      setDetailForm(detailedForm);
    } catch {
      toast.error("error.error.fail_to_load_receipt_details");
    } finally {
      setIsFetchingForm(false);
    }
  }, [toast, receiptForm.id, isFetchingForm, getReceiptForm]);

  const updateFormKeyValues = React.useCallback(
    async (keyValues: KeyValue[]) => {
      if (isUpdatingForm || !detailForm) return;
      setIsUpdatingForm(true);

      try {
        await updateReceiptForm(detailForm.id, {
          ...detailForm,
          keyValues: keyValues,
        });
      } finally {
        setIsUpdatingForm(false);
      }
    },
    [isUpdatingForm, updateReceiptForm, detailForm]
  );

  const updateFormTokenGroups = React.useCallback(
    async (tokenGroups: TokenGroup[]) => {
      if (isUpdatingForm || !detailForm) return;
      setIsUpdatingForm(true);

      try {
        await updateReceiptForm(detailForm.id, {
          ...detailForm,
          tokenGroups: tokenGroups,
        });
      } finally {
        setIsUpdatingForm(false);
      }
    },
    [isUpdatingForm, updateReceiptForm, detailForm]
  );

  React.useEffect(
    () => {
      fetchFormDetail().catch(() => {
        throw new Error("Unreachable");
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [receiptForm]
  );

  return (
    <div className={styles["receipt-form-editor"]}>
      <div className={styles["content"]}>
        <ExternalServicesAlertBoxForReceipt />
        <ReceiptFormNameEditor {...props} />
        {detailForm && (
          <>
            <ReceiptKeyValuesEditor
              detailForm={detailForm}
              updateFormKeyValues={updateFormKeyValues}
            />
            <ReceiptTokenGroupEditor
              detailForm={detailForm}
              updateFormTokenGroups={updateFormTokenGroups}
            />
          </>
        )}
        {(isUpdatingForm || isFetchingForm) && (
          <div className={styles["loading-container"]}>
            <ShortSpinner />
          </div>
        )}
      </div>
    </div>
  );
});

export default ReceiptFormEditor;
