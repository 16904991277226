import { PrimaryButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { TeamDetail } from "../../types/team";
import { QuotaTable } from "../QuotaTable";
import styles from "./styles.module.scss";

interface Props {
  team: TeamDetail;
  region: string;
  presetGiveTrialModel: () => void;
  presetClearTrialModel: () => void;
}

function _TeamDetailPlan(props: Props) {
  const { team, presetGiveTrialModel, presetClearTrialModel } = props;
  const { quota, plan, trialEndAt } = team;
  const { localized } = useLocale();

  const onRemoveTrialClicked = React.useCallback(() => {
    presetClearTrialModel();
  }, [presetClearTrialModel]);

  const onGiveTrialClicked = React.useCallback(() => {
    presetGiveTrialModel();
  }, [presetGiveTrialModel]);

  return (
    <div className={styles["team-plan"]}>
      <div className={styles["team-plan-info"]}>
        <div className={styles["team-plan-info-title"]}>
          <FormattedMessage id="team.detail.current_plan" />
        </div>
        <div className={styles["team-plan-info-content"]}>{plan}</div>
      </div>
      {(plan === "trial" || plan === "quota_plan") && (
        <QuotaTable quota={quota} />
      )}
      {plan === "trial" && trialEndAt != null && (
        <div className={styles["team-plan-info"]}>
          <div className={styles["team-plan-info-title"]}>
            <FormattedMessage id="team.detail.end_at" />
          </div>
          <div className={styles["team-plan-info-content"]}>
            {trialEndAt.toLocaleDateString().replace(/\//g, "-")}
          </div>
        </div>
      )}
      <div className={styles["team-plan-buttons"]}>
        {plan === "trial" && (
          <PrimaryButton
            type="button"
            onClick={onRemoveTrialClicked}
            text={localized("team.detail.plan.remove_trial")}
          />
        )}
        {plan === "free" && (
          <PrimaryButton
            type="button"
            onClick={onGiveTrialClicked}
            text={localized("team.detail.plan.give_trial")}
          />
        )}
      </div>
    </div>
  );
}

export const TeamDetailPlan = React.memo(_TeamDetailPlan);
export default TeamDetailPlan;
