@import "../../styles/mixin.scss";
.no-result-placeholder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  @include no-select();

  > i {
    font-size: 40px;
    margin-right: 12px;
  }

  label {
    font-weight: 500;
    font-size: 14pt;
  }
}
