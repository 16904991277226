import { Icon, Label, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React from "react";

import { useTeamPermission } from "../../hooks/permission";
import icons from "../../styles/icons.module.scss";
import { KeyValue } from "../../types/keyValue";
import styles from "./styles.module.scss";

interface Props {
  keyValue: KeyValue;
  className?: string;
  onEdit(keyValue: KeyValue): void;
  onDelete(keyValueName: string): void;
}

export const KeyValueCard = React.memo((props: Props) => {
  const { className, keyValue, onEdit, onDelete } = props;

  const { hasPermissionToEditResource } = useTeamPermission();

  const onEditClick = React.useCallback(() => {
    onEdit(keyValue);
  }, [keyValue, onEdit]);

  const onDeleteClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onDelete(keyValue.name);
    },
    [keyValue, onDelete]
  );

  return (
    <div
      className={classnames(styles["item-key-value"], className)}
      onClick={onEditClick}
    >
      <div className={styles["title-row"]}>
        <Label>{keyValue.name}</Label>
        <div
          className={classnames(
            icons[`position-${keyValue.position}`],
            styles["position-icon"]
          )}
        />
        <div className={styles["space"]} />
        {hasPermissionToEditResource && (
          <Icon iconName="Delete" onClick={onDeleteClick} />
        )}
      </div>
      <div>
        <div className={styles["field-item"]}>
          <Icon iconName="InsertTextBox" />
          <Text>{keyValue.tokens.map(t => t.token).join(", ")}</Text>
        </div>
        <div className={styles["field-item"]}>
          <Icon iconName="GridViewSmall" />
          {keyValue.pattern === "" ? (
            <Text className={styles["special-pattern"]}>
              <FormattedMessage id="key_value.card.pattern.any" />
            </Text>
          ) : (
            <Text>{keyValue.pattern}</Text>
          )}
        </div>
      </div>
      <div />
    </div>
  );
});
