@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.labeller {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  background-color: $tab-bar-separator;

  .canvas {
    background-repeat: no-repeat;
    margin: auto;
    background-origin: content-box;
  }
}

.floating-left {
  position: fixed;
  top: 50%;
  margin-left: 16px;
  width: 48px;
  margin-top: -72px;

  .info {
    color: #fff;
    font-size: 26px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: block;
    margin: auto;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .toolbox {
    background-color: #464646;

    .tool {
      width: 48px;
      height: 48px;
      cursor: pointer;
    }

    div.selected {
      box-shadow: inset 0 0 18px rgba(187, 182, 53, 0.6);
    }

    div:hover {
      background-color: $form-button-color;
    }

    div:active {
      background-color: $form-button-active-color;
    }
  }
}
