.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 400px;
  margin: auto;
}

.input-section {
  width: 100%;
  animation-name: fade;
  animation-duration: 1s;

  .action {
    padding-top: 10px;
    float: right;
  }
}

h3 {
  padding-bottom: 10px;
  font-weight: 600;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
