.text {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #a19f9d;
  margin-right: 10px;
}

@media only screen and (max-width: 960px) {
  .text {
    display: none;
  }
}
