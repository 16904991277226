@import "../../styles/variables.scss";

.container {
  width: 100%;
  height: calc(100vh - 48px);
  position: relative;
  overflow-y: auto;
}

.menu-bar {
  width: 100%;
  height: 42px;
  padding-left: 23px;
  border-bottom: 1px solid $separator-color-2;

  .action-button {
    float: left;
    margin-right: 32px;

    :global .ms-Icon {
      color: rgb(32, 31, 30);
    }
  }

  .action-button:hover {
    :global .ms-Icon {
      color: $darker-primary-color;
    }
  }

  .action-button:active {
    :global .ms-Icon {
      color: rgb(32, 31, 30);
    }
  }
}

.grid {
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.no-model-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.no-model {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 518px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 138px;
    height: 160px;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .desc {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #c4c4c4;
    margin-bottom: 16px;
  }
}
