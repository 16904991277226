import { apiClient } from "../apiClient";
import { useThunkDispatch } from "../hooks/thunk";
import { Dispatch } from "../redux/types";
import { RootState } from "../redux/types";
import { AuditLogJob } from "../types/auditLog";

export function generateAuditLogCsv(year: number, month: number) {
  return async (
    _dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const state = getState();
    const { resourceOwnerId } = state.resourceOwner;
    await apiClient.generateAuditLogCsv(year, month, resourceOwnerId);
  };
}

export function listAuditLogJobs(id?: string) {
  return async (
    _dispatch: Dispatch,
    getState: () => RootState
  ): Promise<AuditLogJob[]> => {
    if (!id) {
      const { resourceOwnerId } = getState().resourceOwner;
      id = resourceOwnerId;
    }
    return await apiClient.listAuditLogJobs(id);
  };
}

export function useAuditLogActionCreator() {
  return {
    generateAuditLogCsv: useThunkDispatch(generateAuditLogCsv),
    listAuditLogJobs: useThunkDispatch(listAuditLogJobs),
  };
}
