import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";

import {
  DetectMultiDocumentDocument,
  DetectMultiDocumentReport,
} from "../../models";
import { ValueRenderer } from "../OCRTest/ValueRenderer";
import styles from "./TestReportTable.module.scss";

interface TestReportTableProps {
  report: DetectMultiDocumentReport;
}

function formatConfidence(value: number | null | undefined) {
  return value === null || value === undefined ? null : value.toFixed(2);
}

function HeaderCell(props: { headerId: string; columnClassName?: string }) {
  const { headerId, columnClassName } = props;
  return (
    <td
      className={cn(
        styles["header-cell"],
        styles["table-column"],
        columnClassName
      )}
    >
      <Text className={styles["header-text"]}>
        <FormattedMessage id={headerId} />
      </Text>
    </td>
  );
}

function BodyCell(props: {
  text: string;
  columnClassName?: string;
  alt?: string;
}) {
  const { text, columnClassName, alt } = props;
  return (
    <td
      className={cn(
        styles["body-cell"],
        styles["table-column"],
        columnClassName
      )}
    >
      <Text title={alt} className={styles["body-text"]}>
        {text}
      </Text>
    </td>
  );
}

function ValueCell(props: { value: any; columnClassName?: string }) {
  const { value, columnClassName } = props;

  return (
    <td
      className={cn(
        styles["body-cell"],
        styles["table-column"],
        columnClassName
      )}
    >
      <ValueRenderer value={value} />
    </td>
  );
}

function HeaderRow() {
  return (
    <tr className={cn(styles["table-row"], styles["header-row"])}>
      <HeaderCell
        headerId="detect_documents_test_table.header.type"
        columnClassName={styles["name-column"]}
      />
      <HeaderCell headerId="detect_documents_test_table.header.bbox" />
      <HeaderCell
        headerId="detect_documents_test_table.header.type_score"
        columnClassName={styles["confidence-column"]}
      />
      <HeaderCell
        headerId="detect_documents_test_table.header.bbox_score"
        columnClassName={styles["confidence-column"]}
      />
    </tr>
  );
}

function Row(props: { document: DetectMultiDocumentDocument }) {
  const { document } = props;
  return (
    <tr className={cn(styles["table-row"], styles["body-row"])}>
      <BodyCell text={document.type} columnClassName={styles["name-column"]} />
      <ValueCell value={`[${document.bbox.join(", ")}]`} />
      <ValueCell
        value={formatConfidence(document.type_score)}
        columnClassName={styles["confidence-column"]}
      />
      <ValueCell
        value={formatConfidence(document.bbox_score)}
        columnClassName={styles["confidence-column"]}
      />
    </tr>
  );
}

function DocumentSection(props: { documents: DetectMultiDocumentDocument[] }) {
  const { documents } = props;
  return (
    documents && (
      <>
        {documents.map((document, index) => (
          <Row document={document} key={`field/${index}`} />
        ))}
      </>
    )
  );
}

export const TestReportTable = React.memo((props: TestReportTableProps) => {
  const { report } = props;

  return (
    <table className={styles["report-table"]}>
      <thead>
        <HeaderRow />
      </thead>
      <tbody>
        <DocumentSection documents={report.documents} />
      </tbody>
    </table>
  );
});
