@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.receipt-form-editor {
  padding: 10px;
  padding-top: 0;
  height: 100%;
  overflow: auto;

  .content {
    position: relative;
  }

  h1 {
    padding: 10px 0;
    font-size: 18px;
    margin: 10px 20px;
    border-bottom: 1px solid #ccc;
  }

  .section {
    padding: 0 30px;
    margin-bottom: 20px;
  }

  .group-name {
    font-size: 16px;
    line-height: 20pt;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .edit-section-container {
    min-width: 340px;
    max-width: 500px;
  }

  .loading-container {
    position: absolute;
    background-color: rgba($color: white, $alpha: 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
