import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { useTeamActionCreator } from "../actions/team";
import { useUserActionCreator } from "../actions/user";
import {
  InvitationAccountNotMatch,
  InvitationInvalid,
  InvitationLoggedOut,
  InvitationReceived,
} from "../components/InvitationAccept";
import styles from "../components/InvitationAccept/styles.module.scss";
import { Layout, Main, Top } from "../components/Layout";
import ShortSpinner from "../components/ShortSpinner";
import { FOCRError } from "../errors";
import errors from "../errors";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { Permission } from "../types/team";
import HeaderContainer from "./Header";

type InvitationState =
  | "loading"
  | "accountNotMatch"
  | "invalid"
  | "loggedOut"
  | "received";

function useInvitationState() {
  const { invitationIsValid } = useTeamActionCreator();
  const invitationCode = useSelector<RootState, string | undefined>(
    state => state.app.invitationCode
  );
  const isAuthenticated = useSelector<RootState, boolean>(
    state => state.user.isAuthenticated
  );

  const [invitationState, setInvitationState] =
    React.useState<InvitationState>("loading");

  const [teamName, setTeamName] = React.useState<string | undefined>(undefined);
  const [teamPermission, setTeamPermission] = React.useState<
    Permission | undefined
  >(undefined);

  const checkInvitation = React.useCallback(async () => {
    if (!invitationCode) {
      setInvitationState("invalid");
      return;
    }
    try {
      const { isValid, reason, teamName, permission } = await invitationIsValid(
        invitationCode
      );

      if (isValid) {
        if (!isAuthenticated) {
          setInvitationState("loggedOut");
        } else {
          setInvitationState("received");
        }
      } else {
        if (reason === "email_not_match") {
          setInvitationState("accountNotMatch");
        } else {
          setInvitationState("invalid");
        }
      }
      setTeamName(teamName);
      setTeamPermission(permission);
    } catch {
      setInvitationState("invalid");
      return;
    }
  }, [invitationCode, invitationIsValid, isAuthenticated]);

  React.useEffect(() => {
    checkInvitation();
  }, [checkInvitation]);

  return React.useMemo(
    () => ({
      invitationState,
      teamName,
      teamPermission,
    }),
    [invitationState, teamName, teamPermission]
  );
}

export default function InvitationContainer() {
  const { acceptTeamInvitation } = useTeamActionCreator();
  const { triggerLoginWithInvitation, triggerSignupWithInvitation } =
    useUserActionCreator();

  const { invitationState, teamName, teamPermission } = useInvitationState();

  const invitationCode = useSelector<RootState, string | undefined>(
    state => state.app.invitationCode
  );

  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    if (!window.location.href.includes("invitation-code")) {
      history.push("/form");
    }
  }, [history]);

  const loginWithAnotherUser = React.useCallback(() => {
    if (!invitationCode) {
      return;
    }
    triggerLoginWithInvitation(invitationCode);
  }, [invitationCode, triggerLoginWithInvitation]);

  const signup = React.useCallback(() => {
    if (!invitationCode) {
      return;
    }
    triggerSignupWithInvitation(invitationCode);
  }, [invitationCode, triggerSignupWithInvitation]);

  const returnHome = React.useCallback(() => {
    window.location.href = window.location.origin;
  }, []);

  const acceptInvitation = React.useCallback(async () => {
    if (!invitationCode) {
      return;
    }
    try {
      await acceptTeamInvitation(invitationCode);
    } catch (e) {
      if (e instanceof FOCRError && e !== errors.UnknownError) {
        toast.error(e.messageId);
      } else {
        toast.error("error.invite.fail_to_accept");
      }
      return;
    }

    history.push("/team");
  }, [acceptTeamInvitation, history, invitationCode, toast]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true} hasLeft={false}>
        <div className={styles["section"]}>
          {invitationState === "loading" ? (
            <div className={styles["invitation-container"]}>
              <ShortSpinner key={0} />
            </div>
          ) : invitationState === "accountNotMatch" ? (
            <InvitationAccountNotMatch
              loginWithAnotherUser={loginWithAnotherUser}
            />
          ) : invitationState === "invalid" ? (
            <InvitationInvalid returnHome={returnHome} />
          ) : invitationState === "loggedOut" ? (
            <InvitationLoggedOut teamName={teamName} signup={signup} />
          ) : invitationState === "received" ? (
            <InvitationReceived
              onAccept={acceptInvitation}
              teamName={teamName}
              teamPermission={teamPermission}
            />
          ) : null}
        </div>
      </Main>
    </Layout>
  );
}
