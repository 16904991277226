import * as React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { useHistory } from "react-router";

import { useAppActionCreator } from "../actions/app";
import { CommonConfirmModal } from "../components/ConfirmModal";
import SentryBoundary from "../components/SentryBoundary";
import SplashScreen from "../components/SplashScreen";
import { ToastProvider } from "../components/Toast";
import UnsupportedBrowserDialog from "../components/UnsupportedBrowserDialog";
import { AppConfig } from "../config";
import {
  PAYMENT_REQUIRED_TOAST_ID,
  UUIDPattern,
  UserFeatureFlag,
} from "../constants";
import { TutorialProvider } from "../contexts/tutorial";
import {
  useEnsureCrossRegionLogout,
  useEnsureTeamLookupIdIsInURL,
} from "../hooks/app";
import { useAutoHideLeftBar, useAutoShowLeftBar } from "../hooks/autoLeftBar";
import { useTeamPermission } from "../hooks/permission";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import AdminContainer from "./Admin";
import AuditLogContainer from "./AuditLog";
import CustomModelAPIContainer from "./CustomModelAPI";
import CustomModelBatchContainer from "./CustomModelBatch";
import CustomModelEditor from "./CustomModelEditor";
import CustomModelGridContainer from "./CustomModelGrid";
import CustomModelLabelContainer from "./CustomModelLabel";
import CustomModelModelContainer from "./CustomModelModel";
import CustomModelTestContainer from "./CustomModelTest";
import DetectMultiDocumentAPIContainer from "./DetectMultiDocumentAPI";
import DetectMultiDocumentTestContainer from "./DetectMultiDocumentTest";
import FormAPIContainer from "./FormAPI";
import FormBatchContainer from "./FormBatch";
import FormEditorContainer from "./FormEditor";
import FormGridContainer from "./FormGrid";
import FormGroupAPIContainer from "./FormGroupAPI";
import FormGroupBatchContainer from "./FormGroupBatch";
import FormGroupEditContainer from "./FormGroupEdit";
import FormGroupGridContainer from "./FormGroupGrid";
import FormGroupTestContainer from "./FormGroupTest";
import FormTestContainer from "./FormTest";
import InvitationContainer from "./Invitation";
import LoginContainer from "./Login";
import OnboardingContainer from "./Onboarding";
import PaymentContainer from "./Payment";
import ReceiptAPIContainer from "./ReceiptAPI";
import ReceiptBatchContainer from "./ReceiptBatch";
import ReceiptGroupEditContainer from "./ReceiptGroupEdit";
import ReceiptTestContainer from "./ReceiptTest";
import SettingContainer from "./Setting";
import SignUpContainer from "./SignUp";
import TeamContainer from "./Team";
import Tutorial from "./Tutorial";
import UsageContainer from "./Usage";
import WebhookCreateContainer from "./WebhookCreate";
import WebhookEditContainer from "./WebhookEdit";
import WebhookListContainer from "./WebhookList";

Modal.setAppElement("#root");

interface PaymentRequiredToastPresenterProps {
  onToastClicked: () => void;
}
const PaymentRequiredToastPresenter = React.memo(
  (props: PaymentRequiredToastPresenterProps) => {
    const { onToastClicked } = props;
    const toast = useToast();

    React.useEffect(() => {
      toast.error("app.toast.payment_required", {
        id: PAYMENT_REQUIRED_TOAST_ID,
        autoDismiss: false,
        onDismiss: (_id: string) => {
          onToastClicked();
        },
      });
    }, [toast, onToastClicked]);

    return null;
  }
);

const FormRoute = React.memo(() => {
  useAutoHideLeftBar();

  return (
    <Switch>
      <Route
        path={`/form/:formId(${UUIDPattern})/edit`}
        component={FormEditorContainer}
      />
      <Route
        path={`/form/:formId(${UUIDPattern})/api`}
        component={FormAPIContainer}
      />
      <Route
        path={`/form/:formId(${UUIDPattern})/test`}
        component={FormTestContainer}
      />
      <Route
        path={`/form/:formId(${UUIDPattern})/batch`}
        component={FormBatchContainer}
      />
      <Route
        path={`/form/:formId(${UUIDPattern})`}
        render={props => (
          <Redirect to={`/form/${props.match.params.formId}/edit`} />
        )}
      />
    </Switch>
  );
});

const RedirectToHome = React.memo(() => {
  useAutoShowLeftBar();

  return <Redirect to="/form" />;
});

const MainRoute = React.memo(() => {
  const isPaymentRequired = useSelector<RootState>(
    state => state.resourceOwner.isPaymentRequired
  );
  const isCustomPlan = useSelector<RootState>(
    state => state.resourceOwner.plan && state.resourceOwner.plan === "custom"
  );
  const isTeam = useSelector<RootState>(state => state.resourceOwner.isTeam);
  const isAdmin = useSelector<RootState>(
    state => state.user.currentUser?.isAdmin
  );
  const enabledAuditLog = useSelector<RootState>(
    state => state.resourceOwner.enabledAuditLog
  );
  const { hasPermissionToViewMembership, hasPermissionToViewAuditLog } =
    useTeamPermission();
  const isCustomModelTrainingEnabled = useSelector<RootState>(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.CustomModelTraining
    )
  );
  const shouldShowPaymentRequiredToast = useSelector<RootState>(
    state => state.app.shouldShowPaymentRequiredToast
  );
  const canCreateResource = useSelector<RootState>(
    state => state.resourceOwner.permissions.createResource
  );
  const canEditResource = useSelector<RootState>(
    state => state.resourceOwner.permissions.editResource
  );

  const isUsingAuthGear = !!AppConfig.authGear;

  const history = useHistory();
  const redirectToPayment = React.useCallback(() => {
    history.push("/payment");
  }, [history]);

  return (
    <>
      <Switch>
        {!isUsingAuthGear && (
          <Route exact={true} path="/signup" component={SignUpContainer} />
        )}
        <Route path={"/receipt-edit"} component={ReceiptGroupEditContainer} />
        <Route path={"/receipt-test"} component={ReceiptTestContainer} />
        <Route path={"/receipt-api"} component={ReceiptAPIContainer} />
        <Route path={"/receipt-batch"} component={ReceiptBatchContainer} />

        <Route exact path={"/form/"} component={FormGridContainer} />
        <Route path={"/form/"} component={FormRoute} />
        {/* {isAdmin && <Route path={"/admin/"} component={AdminRoute} />} */}
        {isAdmin && <Route path={"/admin/"} component={AdminContainer} />}
        <Route
          path={`/form-template/:formId(${UUIDPattern})/api`}
          component={FormAPIContainer}
        />
        <Route
          path={`/form-template/:formId(${UUIDPattern})/test`}
          component={FormTestContainer}
        />
        <Route
          path={`/form-template/:formId(${UUIDPattern})/batch`}
          component={FormBatchContainer}
        />

        <Route
          path={`/form-group/:formGroupId(${UUIDPattern})/edit`}
          component={FormGroupEditContainer}
        />
        <Route
          path={`/form-group/:formGroupId(${UUIDPattern})/api`}
          component={FormGroupAPIContainer}
        />
        <Route
          path={`/form-group/:formGroupId(${UUIDPattern})/test`}
          component={FormGroupTestContainer}
        />
        <Route
          path={`/form-group/:formGroupId(${UUIDPattern})/batch`}
          component={FormGroupBatchContainer}
        />
        <Route path={"/form-group/"} component={FormGroupGridContainer} />
        <Route
          path={`/form-group-template/:formGroupId(${UUIDPattern})/api`}
          component={FormGroupAPIContainer}
        />
        <Route
          path={`/form-group-template/:formGroupId(${UUIDPattern})/test`}
          component={FormGroupTestContainer}
        />
        <Route
          path={`/form-group-template/:formGroupId(${UUIDPattern})/batch`}
          component={FormGroupBatchContainer}
        />
        <Route
          path={`/custom-model/:customModelId(${UUIDPattern})/setup`}
          component={CustomModelEditor}
        />
        {canCreateResource && (
          <Route path={"/webhook/new/"} component={WebhookCreateContainer} />
        )}
        {canEditResource && (
          <Route
            path={`/webhook/:webhookId(${UUIDPattern})/edit`}
            component={WebhookEditContainer}
          />
        )}
        <Route path={"/webhook/"} component={WebhookListContainer} />
        <Route
          path={"/detect-documents/api"}
          component={DetectMultiDocumentAPIContainer}
        />
        <Route
          path={"/detect-documents/test"}
          component={DetectMultiDocumentTestContainer}
        />
        {isCustomModelTrainingEnabled && (
          <Route path={`/custom-model/:customModelId(${UUIDPattern})/`}>
            <Switch>
              <Route
                path={`/custom-model/:customModelId(${UUIDPattern})/label`}
                component={CustomModelLabelContainer}
              />
              <Route
                path={`/custom-model/:customModelId(${UUIDPattern})/model`}
                component={CustomModelModelContainer}
              />
              <Route
                path={`/custom-model/:customModelId(${UUIDPattern})/api`}
                component={CustomModelAPIContainer}
              />
              <Route
                path={`/custom-model/:customModelId(${UUIDPattern})/test`}
                component={CustomModelTestContainer}
              />
              <Route
                path={`/custom-model/:customModelId(${UUIDPattern})/batch`}
                component={CustomModelBatchContainer}
              />
            </Switch>
          </Route>
        )}
        <Route path={"/custom-model"} component={CustomModelGridContainer} />

        <Route path={"/usage/"} component={UsageContainer} />
        {isCustomPlan ? (
          <Route path={"/setting/"} component={SettingContainer} />
        ) : (
          <Route path={"/payment/"} component={PaymentContainer} />
        )}

        {isTeam && hasPermissionToViewMembership && (
          <Route path={"/team/"} component={TeamContainer} />
        )}

        <Route path={"/invitation/"} component={InvitationContainer} />

        {enabledAuditLog && hasPermissionToViewAuditLog && (
          <Route path={"/audit-log/"} component={AuditLogContainer} />
        )}

        <Route render={() => <RedirectToHome />} />
      </Switch>

      <Switch>
        <Route path={"/payment/"} />
        {shouldShowPaymentRequiredToast && isPaymentRequired && (
          <Route
            render={() => (
              <PaymentRequiredToastPresenter
                onToastClicked={redirectToPayment}
              />
            )}
          />
        )}
      </Switch>

      <Switch>
        <Route exact={true} path="/signup" />
        <Route component={UnsupportedBrowserDialog} />
      </Switch>
    </>
  );
});

const loadTawk = () => {
  const script = document.createElement("script");
  script.src = "https://embed.tawk.to/61494c7bd326717cb6827f37/1fg355ho5";
  script.async = true;
  script.crossOrigin = "*";
  script.charset = "UTF-8";
  document.body.appendChild(script);
};

export const App: React.FC = React.memo(() => {
  const isInitialized = useSelector<RootState>(
    state => state.app.isInitialized
  );
  const isCurrentUserExist = useSelector<RootState, boolean>(
    state => state.user.currentUser !== undefined
  );
  const isNoResourceOwnerExists = useSelector<RootState, boolean>(
    state => state.resourceOwner.resourceOwnerId === undefined
  );
  const isAuthenticated = useSelector<RootState, boolean>(
    state => state.user.isAuthenticated
  );

  const history = useHistory();
  const { initialize } = useAppActionCreator();
  useEnsureTeamLookupIdIsInURL();
  useEnsureCrossRegionLogout();

  React.useEffect(() => {
    initialize().then(result => {
      const { invitationCode } = result;
      if (
        invitationCode &&
        !window.location.pathname.startsWith("/login") &&
        !window.location.pathname.startsWith("/signup")
      ) {
        history.push(`/invitation?invitation-code=${invitationCode}`);
      }
    });
  }, [history, initialize]);

  React.useEffect(() => {
    if (!AppConfig.shouldTawkDisabled) {
      loadTawk();
    }
  }, []);

  return (
    <ToastProvider
      placement="top-center"
      autoDismiss={true}
      autoDismissTimeout={5000}
    >
      <TutorialProvider>
        <SentryBoundary>
          {!isInitialized ? (
            <SplashScreen />
          ) : (
            <Switch>
              <Route path={"/invitation"} component={InvitationContainer} />
              {isAuthenticated && isNoResourceOwnerExists && (
                <Route component={OnboardingContainer} />
              )}

              {isCurrentUserExist ? (
                <MainRoute />
              ) : !AppConfig.authGear ? (
                <Switch>
                  <Route
                    exact={true}
                    path="/signup"
                    component={SignUpContainer}
                  />
                  <Route
                    exact={true}
                    path="/login"
                    component={LoginContainer}
                  />
                  <Route path="*" render={() => <Redirect to="/login" />} />
                </Switch>
              ) : (
                <Route component={SplashScreen} />
              )}
            </Switch>
          )}

          <Tutorial />
          <CommonConfirmModal />
        </SentryBoundary>
      </TutorialProvider>
    </ToastProvider>
  );
});

export default App;
