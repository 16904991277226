import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { ModalState } from "../models";
import { DetailedForm } from "../types/form";
import { KeyValue } from "../types/keyValue";

function useKeyValueCallbacks(
  keyValues: KeyValue[],
  updateFormKeyValues: (keyValues: KeyValue[]) => void
) {
  const [isKeyValueModalOpened, setIsKeyValueModalOpened] =
    useState<ModalState>(ModalState.Closed);

  const [edittingKeyValue, setEdittingKeyValue] = useState<
    KeyValue | undefined
  >();

  const [modalMode, setModalMode] = useState<"create" | "edit">("create");

  const closeKeyValueModal = useCallback(() => {
    setIsKeyValueModalOpened(ModalState.Closed);
    setEdittingKeyValue(undefined);
  }, []);

  const onCreateKeyValue = useCallback(() => {
    setModalMode("create");
    setIsKeyValueModalOpened(ModalState.OpenedToCreate);
  }, []);

  const onEditKeyValue = useCallback((keyValue: KeyValue) => {
    setModalMode("edit");
    setIsKeyValueModalOpened(ModalState.OpenedToEdit);
    setEdittingKeyValue(keyValue);
  }, []);

  const onKeyValueSubmit = useCallback(
    (keyValue: KeyValue) => {
      closeKeyValueModal();

      if (edittingKeyValue) {
        const indexIfExists = keyValues.findIndex(
          ({ name }) => name === edittingKeyValue.name
        );
        if (indexIfExists > -1) {
          const newKeyValues = keyValues.slice(0);
          newKeyValues[indexIfExists] = keyValue;
          updateFormKeyValues(newKeyValues);
        }
      } else {
        updateFormKeyValues([...keyValues, keyValue]);
      }
    },
    [closeKeyValueModal, keyValues, updateFormKeyValues, edittingKeyValue]
  );

  const onDeleteKeyValue = useCallback(
    (fieldName: string) => {
      updateFormKeyValues(keyValues.filter(({ name }) => name !== fieldName));
    },
    [keyValues, updateFormKeyValues]
  );

  return useMemo(
    () => ({
      modalMode,
      isKeyValueModalOpened,
      edittingKeyValue,
      closeKeyValueModal,
      onCreateKeyValue,
      onEditKeyValue,
      onKeyValueSubmit,
      onDeleteKeyValue,
    }),
    [
      modalMode,
      isKeyValueModalOpened,
      edittingKeyValue,
      closeKeyValueModal,
      onCreateKeyValue,
      onEditKeyValue,
      onKeyValueSubmit,
      onDeleteKeyValue,
    ]
  );
}

function useMakeContext(
  keyValues: KeyValue[],
  updateFormKeyValues: (keyValues: KeyValue[]) => void,
  form: DetailedForm
) {
  const context = {
    ...useKeyValueCallbacks(keyValues, updateFormKeyValues),
    form,
  };
  return context;
}

type KeyValueTabPaneContextValue = ReturnType<typeof useMakeContext>;
const KeyValueTabPaneContext = createContext<KeyValueTabPaneContextValue>(
  null as any
);

interface Props {
  keyValues: KeyValue[];
  updateFormKeyValues: (keyValues: KeyValue[]) => void;
  children: React.ReactNode;
  form: DetailedForm;
}

export const KeyValueTabPaneProvider = (props: Props) => {
  const value = useMakeContext(
    props.keyValues,
    props.updateFormKeyValues,
    props.form
  );
  return <KeyValueTabPaneContext.Provider {...props} value={value} />;
};

export function useKeyValueTabPane() {
  return useContext(KeyValueTabPaneContext);
}
