import { Dropdown, Icon, Label, PrimaryButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import produce from "immer";
import React, { useCallback, useMemo, useState } from "react";

import { useFormEditor } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { ModalState } from "../../models";
import { DetailedForm } from "../../types/form";
import { KeyValue } from "../../types/keyValue";
import { TokenGroup } from "../../types/tokenGroup";
import DocumentFieldCard from "../DocumentFieldCard";
import DocumentTypeTabPane from "../DocumentTypeTabPane";
import ImageTokenModalV2 from "../ImageTokenModalV2";
import InfoNote from "../InfoNote";
import { KeyValueModal } from "../KeyValueModal";
import TextTokenModal from "../TextTokenModal";
import styles from "./styles.module.scss";

type DocumentFieldType =
  | "keyValueType"
  | "textTokenGroupType"
  | "imageTokenGroupType";

type DocumentField = {
  name: string;
  created_at: number;
  type: DocumentFieldType;
  position: number;
  data: KeyValue | TokenGroup;
};

const useDocumentTabPaneState = () => {
  const { localized } = useLocale();
  const {
    form,
    updateFormKeyValues,
    updateFormTokenGroups,
    addDefaultDocumentField,
  } = useFormEditor();
  const dForm = form as DetailedForm;
  const { keyValues, tokenGroups } = dForm;

  const [isKeyValueModalOpened, setIsKeyValueModalOpened] =
    useState<ModalState>(ModalState.Closed);

  const [edittingKeyValue, setEdittingKeyValue] = useState<
    KeyValue | undefined
  >();

  const [modalMode, setModalMode] = useState<"create" | "edit">("create");

  const closeKeyValueModal = useCallback(() => {
    setIsKeyValueModalOpened(ModalState.Closed);
    setEdittingKeyValue(undefined);
  }, []);

  const onCreateKeyValue = useCallback(() => {
    setModalMode("create");
    setIsKeyValueModalOpened(ModalState.OpenedToCreate);
  }, []);

  const onEditKeyValue = useCallback((keyValue: KeyValue) => {
    setModalMode("edit");
    setIsKeyValueModalOpened(ModalState.OpenedToEdit);
    setEdittingKeyValue(keyValue);
  }, []);

  const onKeyValueSubmit = useCallback(
    (keyValue: KeyValue) => {
      closeKeyValueModal();

      if (edittingKeyValue) {
        const indexIfExists = keyValues.findIndex(
          ({ name }) => name === edittingKeyValue.name
        );
        if (indexIfExists > -1) {
          const newKeyValues = keyValues.slice(0);
          newKeyValues[indexIfExists] = keyValue;
          updateFormKeyValues(newKeyValues);
        }
      } else {
        updateFormKeyValues([...keyValues, keyValue]);
      }
    },
    [closeKeyValueModal, keyValues, updateFormKeyValues, edittingKeyValue]
  );

  const onDeleteKeyValue = useCallback(
    (fieldName: string) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      updateFormKeyValues(keyValues.filter(({ name }) => name !== fieldName));
    },
    [updateFormKeyValues, keyValues]
  );

  const onDeleteTokenGroup = useCallback(
    (i: number) => (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();

      updateFormTokenGroups(
        produce(tokenGroups, draftTokenGroups => {
          draftTokenGroups.splice(i, 1);
        })
      );
    },
    [tokenGroups, updateFormTokenGroups]
  );

  const [editingTextTokenDocumentField, setEditingTextTokenDocumentField] =
    useState<DocumentField | undefined>();

  const [editingImageTokenDocumentField, setEditingImageTokenDocumentField] =
    useState<DocumentField | undefined>();

  const {
    edittingTextTokenGroup: editingTextTokenGroup,
    edittingImageTokenGroup: editingImageTokenGroup,
  } = useMemo(() => {
    const edittingTextTokenGroup =
      editingTextTokenDocumentField &&
      editingTextTokenDocumentField.type === "textTokenGroupType"
        ? (editingTextTokenDocumentField.data as TokenGroup)
        : undefined;

    const edittingImageTokenGroup =
      editingImageTokenDocumentField &&
      editingImageTokenDocumentField.type === "imageTokenGroupType"
        ? (editingImageTokenDocumentField.data as TokenGroup)
        : undefined;

    return {
      edittingTextTokenGroup,
      edittingImageTokenGroup,
    };
  }, [editingTextTokenDocumentField, editingImageTokenDocumentField]);

  const documentFields = useMemo(() => {
    return keyValues
      .map((keyValue, i) => {
        const field: DocumentField = {
          name: keyValue.name,
          created_at: keyValue.created_at,
          type: "keyValueType",
          data: keyValue,
          position: i,
        };
        return field;
      })
      .concat(
        tokenGroups.map((tokenGroup, i) => {
          const type =
            tokenGroup.tokenType === "texts"
              ? "textTokenGroupType"
              : "imageTokenGroupType";
          const field: DocumentField = {
            name: tokenGroup.name,
            created_at: tokenGroup.created_at,
            type,
            data: tokenGroup,
            position: i,
          };
          return field;
        })
      )
      .sort((a, b) => {
        if (a.created_at === 0 && b.created_at === 0) {
          const aType = a.type === "keyValueType" ? 0 : 1;
          const bType = b.type === "keyValueType" ? 0 : 1;
          return bType - aType; // Show Key Valye Type first if created_at is not set
        }
        return b.created_at - a.created_at;
      });
  }, [keyValues, tokenGroups]);

  const onDocumentFieldSettingTrigger = useCallback(
    (index: number) => () => {
      const field = documentFields[index];
      switch (field.type) {
        case "keyValueType":
          const keyValue = field.data as KeyValue;
          setModalMode("edit");
          setIsKeyValueModalOpened(ModalState.OpenedToEdit);
          setEdittingKeyValue(keyValue);
          break;
        case "textTokenGroupType":
          setEditingTextTokenDocumentField(field);
          break;
        case "imageTokenGroupType":
          setEditingImageTokenDocumentField(field);
          break;
      }
    },
    [
      setModalMode,
      setEdittingKeyValue,
      setEditingTextTokenDocumentField,
      documentFields,
    ]
  );

  const removeKeyValue = useCallback(
    (index: number) => {
      const newKeyValues = [...keyValues];
      newKeyValues.splice(index, 1);
      updateFormKeyValues(newKeyValues);
    },
    [keyValues, updateFormKeyValues]
  );

  const removeTokenGroup = useCallback(
    (index: number) => {
      const newTokenGroups = [...tokenGroups];
      newTokenGroups.splice(index, 1);
      updateFormTokenGroups(newTokenGroups);
    },
    [tokenGroups, updateFormTokenGroups]
  );

  const onDocumentFieldDeleteClick = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const field = documentFields[index];

      switch (field.type) {
        case "keyValueType":
          removeKeyValue(field.position);
          break;
        case "textTokenGroupType":
        case "imageTokenGroupType":
          removeTokenGroup(field.position);
          break;
      }
    },
    [removeKeyValue, removeTokenGroup, documentFields]
  );

  const updateKeyValue = useCallback(
    (keyValue, index) => {
      const newKeyValues = [...keyValues];
      newKeyValues[index] = keyValue;
      updateFormKeyValues(newKeyValues);
    },
    [keyValues, updateFormKeyValues]
  );

  const updateTokenGroup = useCallback(
    (tokenGroup, index) => {
      updateFormTokenGroups(
        produce(tokenGroups, draft => {
          draft[index] = tokenGroup;
          return draft;
        })
      );
    },
    [tokenGroups, updateFormTokenGroups]
  );

  const onDocumentFieldNameChange = useCallback(
    (index: number) =>
      (
        _e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        const field = documentFields[index];
        const newName = newValue as string;
        if (field.type === "keyValueType") {
          const keyValue = { ...keyValues[field.position] };
          keyValue.name = newName;
          updateKeyValue(keyValue, field.position);
        } else {
          const tokenGroup = { ...tokenGroups[field.position] };
          tokenGroup.name = newName;
          updateTokenGroup(tokenGroup, field.position);
        }
      },
    [documentFields, tokenGroups, updateTokenGroup, keyValues, updateKeyValue]
  );

  const onTextTokenModalCancel = useCallback(() => {
    setEditingTextTokenDocumentField(undefined);
  }, [setEditingTextTokenDocumentField]);

  const onTextTokenModalSubmit = useCallback(
    (tokenGroup: TokenGroup) => {
      updateTokenGroup(
        tokenGroup,
        (editingTextTokenDocumentField as DocumentField).position
      );
      setEditingTextTokenDocumentField(undefined);
    },
    [updateTokenGroup, editingTextTokenDocumentField]
  );

  const onImageTokenModalCancel = useCallback(() => {
    setEditingImageTokenDocumentField(undefined);
  }, [setEditingImageTokenDocumentField]);

  const onImageTokenModalSubmit = useCallback(
    (tokenGroup: TokenGroup) => {
      updateTokenGroup(
        tokenGroup,
        (editingImageTokenDocumentField as DocumentField).position
      );
      setEditingImageTokenDocumentField(undefined);
    },
    [
      updateTokenGroup,
      editingImageTokenDocumentField,
      setEditingImageTokenDocumentField,
    ]
  );

  const onAddNewField = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      addDefaultDocumentField();
    },
    [addDefaultDocumentField]
  );

  const isDocumentFieldNameDuplicated = useCallback(
    (fieldIndex: number) => {
      const field = documentFields[fieldIndex];
      const trimmedFieldName = field.name.trim();

      return documentFields.some((field, index) => {
        return fieldIndex !== index && field.name.trim() === trimmedFieldName;
      });
    },
    [documentFields]
  );

  const detectDocumentFieldNameError = useCallback(
    (index: number) => {
      const field = documentFields[index];
      if (field.name.trim() === "") {
        return localized(
          "form_inspector.document_field.error.field_name_empty"
        );
      }

      if (isDocumentFieldNameDuplicated(index)) {
        return localized(
          "form_inspector.document_field.error.field_name_unique"
        );
      }
      return "";
    },
    [documentFields, isDocumentFieldNameDuplicated, localized]
  );

  return useMemo(
    () => ({
      isKeyValueModalOpened,
      modalMode,
      onEditKeyValue,
      onKeyValueSubmit,
      onCreateKeyValue,
      edittingKeyValue,
      closeKeyValueModal,
      onDeleteKeyValue,
      onDeleteTokenGroup,
      editingTextTokenGroup,
      editingImageTokenGroup,
      documentFields,
      onDocumentFieldSettingTrigger,
      onDocumentFieldDeleteClick,
      onDocumentFieldNameChange,
      onAddNewField,
      onTextTokenModalCancel,
      onTextTokenModalSubmit,
      onImageTokenModalCancel,
      onImageTokenModalSubmit,
      detectDocumentFieldNameError,
    }),
    [
      isKeyValueModalOpened,
      modalMode,
      onEditKeyValue,
      onKeyValueSubmit,
      onCreateKeyValue,
      edittingKeyValue,
      closeKeyValueModal,
      onDeleteKeyValue,
      onDeleteTokenGroup,
      editingTextTokenGroup,
      editingImageTokenGroup,
      documentFields,
      onDocumentFieldSettingTrigger,
      onDocumentFieldDeleteClick,
      onDocumentFieldNameChange,
      onAddNewField,
      onTextTokenModalCancel,
      onTextTokenModalSubmit,
      onImageTokenModalCancel,
      onImageTokenModalSubmit,
      detectDocumentFieldNameError,
    ]
  );
};

const DocumentTabPane = React.memo(() => {
  const { localized } = useLocale();
  const formEditor = useFormEditor();
  const {
    hideInV1Form,
    documentCustomExtractionType,
    onDocumentCustomExtractionTypeChange,
    documentCustomExtractionTypesOptions,
    documentCustomExtractionErrorMessage,
  } = formEditor;

  const { hasPermissionToEditResource } = useTeamPermission();

  const form = formEditor.form as DetailedForm;
  const {
    modalMode,
    isKeyValueModalOpened,
    edittingKeyValue,
    closeKeyValueModal,
    onKeyValueSubmit,
    editingTextTokenGroup,
    editingImageTokenGroup,
    documentFields,
    onDocumentFieldSettingTrigger,
    onDocumentFieldDeleteClick,
    onDocumentFieldNameChange,
    onAddNewField,
    onTextTokenModalCancel,
    onTextTokenModalSubmit,

    onImageTokenModalSubmit,
    onImageTokenModalCancel,
    detectDocumentFieldNameError,
  } = useDocumentTabPaneState();

  return (
    <React.Fragment>
      <KeyValueModal
        form={form}
        mode={modalMode}
        isOpen={isKeyValueModalOpened !== ModalState.Closed}
        keyValue={edittingKeyValue}
        onCancel={closeKeyValueModal}
        onSubmit={onKeyValueSubmit}
        renameEnabled={false}
      />
      <TextTokenModal
        onCancel={onTextTokenModalCancel}
        onSubmit={onTextTokenModalSubmit}
        inputTokenGroup={editingTextTokenGroup}
      />
      <ImageTokenModalV2
        onCancel={onImageTokenModalCancel}
        onSubmit={onImageTokenModalSubmit}
        inputTokenGroup={editingImageTokenGroup}
      />
      <div className={styles["content"]}>
        <InfoNote notes={["form_inspector.document.desc"]} />

        <div className={styles["sub-content"]}>
          {hideInV1Form(
            form,
            <>
              <DocumentTypeTabPane form={form} />
              <div className={styles["separator"]} />
            </>
          )}

          {documentFields.length > 0 && (
            <div className={styles["custom_extraction_items"]}>
              <Label className={styles["custom_extraction_items_label"]}>
                <FormattedMessage id="document.tab.custom_extraction_item.title" />
              </Label>
              <Icon iconName="Info" />
            </div>
          )}

          {hasPermissionToEditResource && (
            <>
              <Dropdown
                className={styles["custom_extraction_items_dropdown"]}
                selectedKey={documentCustomExtractionType}
                placeholder={localized(
                  "document.tab.custom_extraction_item.choose_extraction_type"
                )}
                options={documentCustomExtractionTypesOptions}
                onChange={onDocumentCustomExtractionTypeChange}
                errorMessage={documentCustomExtractionErrorMessage}
              />
              <div className={styles["button-container"]}>
                <PrimaryButton
                  className={styles["button"]}
                  text={localized("document.tab.add_new_item")}
                  onClick={onAddNewField}
                />
              </div>
            </>
          )}

          <React.Fragment>
            {documentFields.map((field, i) => (
              <DocumentFieldCard
                key={i}
                fieldNameValue={field.name}
                fieldNameErrorMessage={detectDocumentFieldNameError(i)}
                type={field.type}
                onFieldNameValueChange={onDocumentFieldNameChange(i)}
                onDeleteClick={onDocumentFieldDeleteClick(i)}
                onSettingTrigger={onDocumentFieldSettingTrigger(i)}
              />
            ))}
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
});

export default DocumentTabPane;
