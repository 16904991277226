@import "../../styles/mixin.scss";

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    pointer-events: auto;
  }

  .upload-icon {
    @include no-select();
    font-size: 60px;
    line-height: 60px;
    height: 60px;
    align-self: start;
  }

  .instructions {
    margin-left: 12px;
    font-weight: 500;

    p {
      margin: 5px 0;

      button {
        margin: 0 8px 0 8px;
      }
    }
  }
}
