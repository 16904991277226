@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.grid-item {
  @include ms-depth-4;

  display: inline-block;
  width: 282px;
  height: 368px;
  border-radius: 10px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;

  .image-preview {
    width: 282px;
    height: 250px;
    object-fit: cover;
    left: 50%;
    position: absolute;
    top: 0px;
    transform: translate(-50%, 0%);
  }

  .pdf-icon {
    position: absolute;
    left: 50%;
    top: 86px;
    width: 72px;
    height: 72px;
    transform: translate(-50%, 0);
  }

  .checkbox {
    top: 16px;
    left: 16px;
    position: absolute;
    background-color: white;

    :global .ms-Checkbox-checkbox {
      margin-right: 0px;
    }
  }

  .label {
    width: 90%;
    margin-left: 16px;
    margin-right: 16px;
    height: 40px;
    position: absolute;
    top: 278px;
    text-align: center;
    overflow: hidden;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #201f1e;
  }

  .date {
    position: absolute;
    top: 330px;

    left: 50%;
    transform: translate(-50%, 0%);

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #605e5c;
  }
}
