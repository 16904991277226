import * as React from "react";
import { useSelector } from "react-redux";

import { get as _getForm, useFormActionCreator } from "../actions/form";
import { useThunkDispatch } from "../hooks/thunk";
import { RootState } from "../redux/types";
import { BriefForm, DetailedForm } from "../types/form";
import { useWorkerToken } from "./app";

export function useForm(formId: string) {
  const getForm = useThunkDispatch(_getForm);
  const form = useSelector((state: RootState) => state.form.currentForm);
  const [isFailedToFetchForm, setIsFailedToFetchForm] = React.useState(false);

  React.useEffect(() => {
    if (!form || form.id !== formId) {
      getForm(formId).catch(() => {
        setIsFailedToFetchForm(true);
      });
    }
  }, [form, getForm, formId]);

  return {
    form,
    isFailedToFetchForm,
  };
}

interface CommonFormContainerSuccessState {
  state: "success";
  workerToken: string;
  form: DetailedForm;
}

interface CommonFormContainerErrorState {
  state: "error";
}

interface CommonFormContainerLoadingState {
  state: "loading";
}

export type CommonFormContainerState =
  | CommonFormContainerLoadingState
  | CommonFormContainerErrorState
  | CommonFormContainerSuccessState;

export function useCommonFormContainerState(formId: string) {
  const { form, isFailedToFetchForm } = useForm(formId);

  const { token: workerToken, isFailed: isFailedToGetWorkerToken } =
    useWorkerToken();

  const containerState: CommonFormContainerState =
    isFailedToGetWorkerToken || isFailedToFetchForm
      ? { state: "error" }
      : form !== undefined && form.id === formId && workerToken !== undefined
      ? { state: "success", form, workerToken }
      : { state: "loading" };

  return containerState;
}

export function useListAllForms() {
  const { list: listForm } = useFormActionCreator();
  const forms = useSelector((state: RootState) => state.form.forms);
  const pageInfo = useSelector((state: RootState) => state.form.pageInfo);
  const [isFailedToFetchForms, setIsFailedToFetchForms] = React.useState(false);

  React.useEffect(() => {
    if ((forms.length === 0 && !pageInfo) || pageInfo?.hasNext) {
      listForm().catch(() => {
        setIsFailedToFetchForms(true);
      });
    }
  }, [forms, pageInfo, listForm]);

  return {
    forms,
    pageInfo,
    isFailedToFetchForms,
  };
}

interface ListAllFormsContainerSuccessState {
  state: "success";
  forms: BriefForm[];
}

interface ListAllFormsContainerErrorState {
  state: "error";
}

interface ListAllFormsContainerLoadingState {
  state: "loading";
}

export type ListAllFormsContainerState =
  | ListAllFormsContainerLoadingState
  | ListAllFormsContainerErrorState
  | ListAllFormsContainerSuccessState;

export function useListAllFormsContainerState() {
  const { forms, pageInfo, isFailedToFetchForms } = useListAllForms();

  const containerState: ListAllFormsContainerState = isFailedToFetchForms
    ? { state: "error" }
    : pageInfo != null && !pageInfo.hasNext
    ? { state: "success", forms }
    : { state: "loading" };

  return containerState;
}
