import { Icon, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import { AppConfig } from "../../config";
import { useLocalizeRegion } from "../../hooks/app";
import { useTeamPermission } from "../../hooks/permission";
import { RemoteData } from "../../hooks/remoteData";
import { RootState } from "../../redux/types";
import {
  Permission,
  TeamMembersAndInvitations,
  getTeamRoleMessageId,
} from "../../types/team";
import ErrorPlaceholder from "../ErrorPlaceholder";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import {
  DisplayTeamUser,
  TeamPermissionControl,
} from "../TeamPermissionControl";
import styles from "./styles.module.scss";

interface Props {
  usersRemoteData: RemoteData<TeamMembersAndInvitations>;
  setTeamUserPermission: (userId: string, permission: Permission) => void;
  removeTeamUser: (userId: string) => void;
  removeInvitation: (invitationId: string) => void;
  onInviteMember: () => void;
  onRenameTeam: () => void;
  onRemoveTeam: () => void;
}

interface TeamTableRowProps {
  user: DisplayTeamUser;
  hasSingleAdmin: boolean;
  setTeamUserPermission: (userId: string, permission: Permission) => void;
  removeTeamUser: (userId: string) => void;
  removeInvitation: (invitationId: string) => void;
}

function TeamTableRow(props: TeamTableRowProps) {
  const {
    user,
    hasSingleAdmin,
    setTeamUserPermission,
    removeTeamUser,
    removeInvitation,
  } = props;
  const userEmail = useSelector<RootState, string | undefined>(
    state => state.user.currentUser?.email
  );
  const isInvitation = user.userId === undefined;
  const isLastAdmin = hasSingleAdmin && user.permission.editMembership;
  const { hasPermissionToEditMembership } = useTeamPermission();

  return (
    <tr className={styles["team-table-row"]}>
      <td>
        {userEmail === user.email ? (
          <FormattedMessage
            id={"team.invitation.email.oneself"}
            values={{ email: user.email }}
          />
        ) : (
          user.email
        )}
      </td>
      <td>
        {isInvitation ? (
          <FormattedMessage id={"team.invitation.pending"} />
        ) : (
          <FormattedMessage id={"team.invitation.accepted"} />
        )}
      </td>
      <td>
        {(!isInvitation && isLastAdmin) || !hasPermissionToEditMembership ? (
          <FormattedMessage id={getTeamRoleMessageId(user.permission)} />
        ) : (
          <TeamPermissionControl
            user={user}
            setTeamUserPermission={setTeamUserPermission}
            removeTeamUser={removeTeamUser}
            removeInvitation={removeInvitation}
          />
        )}
      </td>
    </tr>
  );
}

export function Team(props: Props) {
  const {
    usersRemoteData,
    setTeamUserPermission,
    removeTeamUser,
    removeInvitation,
    onInviteMember,
    onRenameTeam,
    onRemoveTeam,
  } = props;
  const teamName = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.teamName
  );

  const displayUsers = React.useMemo(() => {
    if (usersRemoteData.state === "success") {
      const displayUsers = usersRemoteData.value.members.map(user => {
        return {
          email: user.email,
          userId: user.userId,
          permission: user.permission,
        };
      });

      const displayInvitations = usersRemoteData.value.invitations.map(
        invitation => {
          return {
            email: invitation.email,
            invitationId: invitation.id,
            permission: invitation.permission,
          };
        }
      );

      return [...displayUsers, ...displayInvitations];
    } else {
      return [];
    }
  }, [usersRemoteData]);

  const hasSingleAdmin =
    usersRemoteData.state === "success" &&
    usersRemoteData.value.members.filter(user => user.permission.editMembership)
      .length === 1;

  const localizeRegion = useLocalizeRegion();

  return (
    <InviteMemberBarLayout
      onInviteMember={onInviteMember}
      onRenameTeam={onRenameTeam}
      onRemoveTeam={onRemoveTeam}
    >
      <div className={styles["section"]}>
        <div className={styles["title"]}>
          <h3>
            <FormattedMessage
              id={"team.members.header"}
              values={{ team: teamName ?? "" }}
            />
          </h3>
          <Text variant="small" className={styles["region"]}>
            <FormattedMessage
              id={"team.region"}
              values={{ region: localizeRegion(AppConfig.region) }}
            />
          </Text>
        </div>

        {usersRemoteData.state === "loading" && <ShortSpinner key={0} />}
        {usersRemoteData.state === "success" && (
          <Table
            columnIds={[
              "common.email",
              "team.column.status",
              "team.column.role",
            ]}
            className={styles["team-table"]}
          >
            {displayUsers.map(user => (
              <TeamTableRow
                user={user}
                hasSingleAdmin={hasSingleAdmin}
                setTeamUserPermission={setTeamUserPermission}
                removeTeamUser={removeTeamUser}
                removeInvitation={removeInvitation}
                key={user.email}
              />
            ))}
          </Table>
        )}
        {usersRemoteData.state === "error" && (
          <ErrorPlaceholder messageId="common.fail_to_fetch_team_members" />
        )}
      </div>
    </InviteMemberBarLayout>
  );
}

interface TeamActionBarProps {
  onInviteMember: () => void;
  onRenameTeam: () => void;
  onRemoveTeam: () => void;
}

function TeamActionBar(props: TeamActionBarProps) {
  const { onInviteMember, onRenameTeam, onRemoveTeam } = props;
  const { hasPermissionToEditMembership, hasPermissionToEditTeamSetting } =
    useTeamPermission();

  return (
    <div className={styles["top-bar"]}>
      {hasPermissionToEditMembership && (
        <div className={styles["button-area"]} onClick={onInviteMember}>
          <Icon
            iconName="CirclePlus"
            className={styles["team-action-button"]}
          />
          <Text>
            <FormattedMessage id="invite.new.member" />
          </Text>
        </div>
      )}
      {hasPermissionToEditTeamSetting && (
        <>
          <div className={styles["button-area"]} onClick={onRenameTeam}>
            <Icon iconName="Edit" className={styles["team-action-button"]} />
            <Text>
              <FormattedMessage id="team.rename" />
            </Text>
          </div>
          <div
            className={classNames(styles["button-area"], styles["float-right"])}
            onClick={onRemoveTeam}
          >
            <Icon iconName="Delete" className={styles["team-destroy-button"]} />
            <Text>
              <FormattedMessage id="team.delete" />
            </Text>
          </div>
        </>
      )}
    </div>
  );
}

interface TeamActionBarLayoutProps extends TeamActionBarProps {
  children: React.ReactNode;
}

export function InviteMemberBarLayout(props: TeamActionBarLayoutProps) {
  const { children, ...rest } = props;
  const { hasPermissionToEditMembership, hasPermissionToEditTeamSetting } =
    useTeamPermission();

  return (
    <div className={styles["content"]}>
      {(hasPermissionToEditMembership || hasPermissionToEditTeamSetting) && (
        <TeamActionBar {...rest} />
      )}
      {children}
    </div>
  );
}
