import {
  CreateTeam,
  TeamInvitationAccepted,
  TeamRenamed,
  TeamUserRemoved,
  UserPermissionUpdated,
} from "../actions/team";
import { UserFeatureFlag } from "../constants";
import { RootAction } from "../redux/types";
import { ResourceOwnerSetting } from "../types/resourceOwner";
import { BriefTeam, Permission } from "../types/team";
import { SubscriptionData, User } from "../types/user";

export interface ResourceOwnerState {
  readonly plan?: string;
  readonly planId?: string | null;
  readonly resourceOwnerId?: string;
  readonly isTeam: boolean;
  readonly teamName?: string;
  readonly workerToken?: string;
  readonly permissions: Permission;
  readonly isPaymentRequired: boolean;
  readonly isGoogleOcrEngineSet: boolean;
  readonly isAzureOcrEngineSet: boolean;
  readonly enabledFeatures: string[];
  readonly enabledAuditLog: boolean;

  // These are cached on demand.
  readonly subscriptionData?: SubscriptionData;
  readonly setting?: ResourceOwnerSetting;

  // Computed properties
  isFeatureEnabled(): (feature: string) => boolean;
}

const userPermission = {
  viewSubscription: true,
  editSubscription: true,
  viewMembership: true,
  editMembership: true,
  viewResource: true,
  editResource: true,
  createResource: true,
  removeResource: true,
  viewTeamSetting: true,
  editTeamSetting: true,
  viewAuditLog: true,
};

export const defaultState = {
  isTeam: false,
  permissions: userPermission,
  isPaymentRequired: false,
  isGoogleOcrEngineSet: false,
  isAzureOcrEngineSet: false,
  enabledFeatures: [],
  isFeatureEnabled: function (this: ResourceOwnerState) {
    return (feature: string) =>
      (this.enabledFeatures &&
        !!this.enabledFeatures.some((f: string) =>
          [UserFeatureFlag.All, feature].includes(f)
        )) ||
      location.host === "form-extractor.pandawork.com";
  },
  enabledAuditLog: false,
};

export function reducer(
  state: ResourceOwnerState = defaultState,
  action: RootAction
): ResourceOwnerState {
  switch (action.type) {
    case "UserLogin": {
      const { resourceOwnerId, user } = action.payload;
      return getInitialStateForResourceOwner(state, resourceOwnerId, user);
    }
    case "UserLogout":
      return {
        ...state,
        resourceOwnerId: undefined,
        isTeam: false,
        teamName: undefined,
        workerToken: undefined,
        plan: undefined,
        planId: undefined,
        subscriptionData: undefined,
        setting: undefined,
        isPaymentRequired: false,
        isGoogleOcrEngineSet: false,
        isAzureOcrEngineSet: false,
        enabledFeatures: [],
        enabledAuditLog: false,
      };
    case CreateTeam:
    case TeamInvitationAccepted: {
      const { team, permission } = action;
      return {
        ...state,
        resourceOwnerId: team.id,
        isTeam: true,
        teamName: team?.name,
        workerToken: undefined,
        plan: team.plan,
        planId: team.planId,
        permissions: permission,
        subscriptionData: undefined,
        setting: undefined,
        isGoogleOcrEngineSet: team.isGoogleOcrEngineSet,
        isAzureOcrEngineSet: team.isAzureOcrEngineSet,
        enabledFeatures: team.enabledFeatures,
        enabledAuditLog: team.enabledAuditLog,
      };
    }
    case "TeamDeleted": {
      const { teamId, currentUser } = action;
      const resourceOwnerId = getFirstAvailableResourceOwnerIdFromUser(
        currentUser,
        teamId
      );
      return getInitialStateForResourceOwner(
        state,
        resourceOwnerId,
        currentUser
      );
    }

    case TeamUserRemoved: {
      const { removedUserId, currentUser } = action;
      const resourceOwnerId =
        getFirstAvailableResourceOwnerIdFromUser(currentUser);
      if (removedUserId === currentUser.id) {
        return getInitialStateForResourceOwner(
          state,
          resourceOwnerId,
          currentUser
        );
      } else {
        return state;
      }
    }
    case TeamRenamed: {
      const { teamId, name } = action;
      if (state.resourceOwnerId === teamId) {
        return {
          ...state,
          teamName: name,
        };
      } else {
        return state;
      }
    }
    case "SelectTeam": {
      const { resourceOwnerId, user } = action.payload;
      return getInitialStateForResourceOwner(state, resourceOwnerId, user);
    }
    case "PlanUpdated":
      return {
        ...state,
        plan: action.plan,
        planId: action.planId,
      };
    case "GotWorkerToken":
      const { workerToken } = action;
      return {
        ...state,
        workerToken,
      };
    case "GotSubscriptionData":
      const { subscriptionData } = action;
      return {
        ...state,
        subscriptionData,
      };
    case "GotSetting":
      const { setting } = action;
      return {
        ...state,
        setting,
      };
    case "SettingUpdated": {
      const { setting } = action;
      return {
        ...state,
        setting,
      };
    }
    case "IsPaymentRequiredUpdated":
      const { isPaymentRequired } = action;
      return {
        ...state,
        isPaymentRequired,
      };
    case "PaymentMethodUpdated":
      const { last4, brand } = action.payload;
      return {
        ...state,
        subscriptionData: {
          ...state.subscriptionData,
          paymentMethod: { last4, brand },
        },
      };
    case "BillingEmailUpdated":
      const { email } = action;
      return {
        ...state,
        subscriptionData: {
          ...state.subscriptionData,
          billingEmail: email,
          paymentMethod: state.subscriptionData?.paymentMethod ?? null,
        },
      };

    case UserPermissionUpdated:
      const { permission, teamId, isCurrentUser } = action;
      if (state.isTeam && state.resourceOwnerId === teamId && isCurrentUser) {
        return {
          ...state,
          permissions: permission,
        };
      } else {
        return state;
      }

    case "SettingUpdated":
      return {
        ...state,
        setting: action.setting,
      };
  }

  return state;
}

function getFirstAvailableResourceOwnerIdFromUser(
  user: User,
  exclude?: string
): string | undefined {
  const firstTeam = user.teams.filter(t => t.id !== exclude)[0];
  return user.resourceOwner?.id ?? (firstTeam && firstTeam.id) ?? undefined;
}

function getInitialStateForResourceOwner(
  state: ResourceOwnerState,
  resourceOwnerId: string | undefined,
  user: User
): ResourceOwnerState {
  const isTeam =
    user.resourceOwner?.id === undefined
      ? resourceOwnerId !== undefined
      : user.resourceOwner?.id !== resourceOwnerId;

  const briefTeam =
    isTeam && resourceOwnerId
      ? getBriefTeamById(resourceOwnerId, user)
      : undefined;

  return {
    ...state,
    resourceOwnerId: resourceOwnerId,
    isTeam: isTeam,
    teamName: briefTeam?.name,
    workerToken: undefined,
    plan:
      isTeam && resourceOwnerId ? briefTeam?.plan : user.resourceOwner?.plan,
    planId:
      isTeam && resourceOwnerId
        ? briefTeam?.planId
        : user.resourceOwner?.planId,
    permissions:
      isTeam && resourceOwnerId
        ? getPermissionByTeamId(resourceOwnerId, user)
        : userPermission,
    isGoogleOcrEngineSet:
      isTeam && resourceOwnerId
        ? briefTeam?.isGoogleOcrEngineSet ?? false
        : user.resourceOwner?.isGoogleOcrEngineSet ?? false,
    isAzureOcrEngineSet:
      isTeam && resourceOwnerId
        ? briefTeam?.isAzureOcrEngineSet ?? false
        : user.resourceOwner?.isAzureOcrEngineSet ?? false,
    enabledFeatures:
      isTeam && resourceOwnerId
        ? briefTeam?.enabledFeatures ?? []
        : user.resourceOwner?.enabledFeatures ?? [],
    enabledAuditLog:
      isTeam && resourceOwnerId
        ? briefTeam?.enabledAuditLog ?? false
        : user.resourceOwner?.enabledAuditLog ?? false,
    subscriptionData: undefined,
    setting: undefined,
  };
}

function getBriefTeamById(teamId: string, user: User): BriefTeam | undefined {
  return user.teams.find(team => team.id === teamId);
}

function getPermissionByTeamId(teamId: string, user: User): Permission {
  return (
    user.permissions.find(permission => permission.teamId === teamId)
      ?.permission ?? userPermission
  );
}
