import { apiClient } from "../apiClient";
import { UserInfo } from "../apiClient/authGear";
import errors from "../errors";
import { useThunkDispatch } from "../hooks/thunk";
import { Dispatch } from "../redux/types";
import { User } from "../types/user";
import { URLParamsKey, getParam, setParam } from "../utils/params";
import { PreferenceKey, getPreference } from "../utils/preference";
import { toast } from "../utils/toast";

export interface UserLoginAction {
  readonly type: "UserLogin";
  readonly payload: {
    user: User;
    resourceOwnerId?: string;
    isTeam: boolean;
  };
}

export interface UserLogoutAction {
  readonly type: "UserLogout";
}

export interface UserCreatedAction {
  readonly type: "UserCreated";
  readonly user: User;
}

export interface GotUserInfoAction {
  readonly type: "GotUserInfo";
  readonly userInfo: UserInfo;
}

export type UserAction =
  | UserLoginAction
  | UserLogoutAction
  | UserCreatedAction
  | GotUserInfoAction;

function getResourceOwnerIdAndTeamLookupIdFromUrl(user: User) {
  const teamLookupId =
    getParam(URLParamsKey.team) ||
    getPreference(PreferenceKey.lastSelectedTeamLookupId);
  const briefTeam = user.teams.find(team => team.lookupId === teamLookupId);
  return briefTeam?.id ? [briefTeam.id, teamLookupId] : null;
}

export function makeUserLoginAction(user: User): UserLoginAction {
  const [resourceOwnerId, teamLookupId] =
    getResourceOwnerIdAndTeamLookupIdFromUrl(user) ||
      (user.resourceOwner && [user.resourceOwner.id, null]) ||
      (user.teams[0] && [user.teams[0].id, user.teams[0].lookupId]) || [
        null,
        null,
      ];

  if (teamLookupId && !window.location.pathname.startsWith("/admin")) {
    setParam(URLParamsKey.team, teamLookupId);
  }

  const isTeam =
    user.teams.find(team => team.id === resourceOwnerId) !== undefined;

  return {
    type: "UserLogin",
    payload: {
      user,
      resourceOwnerId: resourceOwnerId ?? undefined,
      isTeam,
    },
  };
}

export function createUser(region: string) {
  return async (dispatch: Dispatch): Promise<User> => {
    const user = await apiClient.createUser(region);

    dispatch({
      type: "UserCreated",
      user,
    });

    return user;
  };
}

export function signUp(username: string, email: string, password: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    await apiClient.signUp(username, email, password);
    const user = await apiClient.getUser();
    const action = makeUserLoginAction(user);
    dispatch(action);
  };
}

export function login(email: string, password: string, newPassword?: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    await apiClient.login(email, password, newPassword);
    const user = await apiClient.getUser();
    const action = makeUserLoginAction(user);
    dispatch(action);
  };
}

export function logout() {
  return async (dispatch: Dispatch): Promise<void> => {
    await apiClient.logout();
    if (toast.dismiss) {
      toast.dismiss();
    }
    dispatch({ type: "UserLogout" });
  };
}

export async function changePassword(oldPassword: string, newPassword: string) {
  try {
    await apiClient.changePasword(oldPassword, newPassword);
  } catch (e) {
    if (e === errors.IncorrectEmailOrPassword) {
      throw errors.IncorrectPassword;
    } else {
      throw e;
    }
  }
}

export function triggerSignupWithInvitation(invitationCode: string) {
  return async (_dispatch: Dispatch): Promise<void> => {
    await apiClient.triggerSignupWithInvitation(invitationCode);
  };
}

export function triggerLoginWithInvitation(invitationCode: string) {
  return async (_dispatch: Dispatch): Promise<void> => {
    await apiClient.triggerLoginWithInvitation(invitationCode);
  };
}

export function openUserSetting() {
  return async (_dispatch: Dispatch): Promise<void> => {
    return await apiClient.openUserSetting({ openInSameTab: true });
  };
}

export function useUserActionCreator() {
  return {
    createUser: useThunkDispatch(createUser),
    signUp: useThunkDispatch(signUp),
    logout: useThunkDispatch(logout),
    login: useThunkDispatch(login),
    triggerLoginWithInvitation: useThunkDispatch(triggerLoginWithInvitation),
    triggerSignupWithInvitation: useThunkDispatch(triggerSignupWithInvitation),
    openUserSetting: useThunkDispatch(openUserSetting),
  };
}
