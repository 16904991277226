import { Palette } from "./types/palette";

export type AuthGearConfig = {
  endpoint: string;
  clientID: string;
  redirectURI: string;
};

export type AuthConfig = {
  passwordMinLength: number;
};

export type LeftBarConfig = {
  hideCustomModel: boolean;
  hideTemplate: boolean;
  hideIntegration: boolean;
};

export type AdminConfig = {
  planOptions: string[];
};

export const AppConfig: {
  environment: string;
  admin: AdminConfig;
  auth: AuthConfig;
  authGear: AuthGearConfig | null;
  api: { endpoint?: string };
  worker: { endpoint?: string; shouldAsyncDisabled?: boolean };
  stripe: { apiKey: string };
  sentry: {
    dsn: string | undefined;
    release: string | undefined;
  };
  desktopApp: {
    macOSUrl: string;
    windowsUrl: string;
  };
  helpPageUrl: string;
  zapier: {
    createAIModelRequestUrl: string;
  };
  leftBar: LeftBarConfig;
  region: string;
  provider?: string;
  cookieDomain?: string;
  maxUploadImageSize: number | undefined;
  enableAbbyy?: boolean;
  hideTeamSelectionMenu?: boolean;
  shouldTawkDisabled?: boolean;
  theme?: Partial<Palette>;
  documentTitle?: string;
  usageDateRangeSelectorMinDate?: number;
  auditLogRetentionPeriod: number;
} = {
  region: "default",
  admin: {
    planOptions: ["free", "custom", "trial"],
  },
  auth: {
    passwordMinLength: 8,
  },
  leftBar: {
    hideCustomModel: false,
    hideTemplate: false,
    hideIntegration: false,
  },
  auditLogRetentionPeriod: 12,
  ...(window as any).appConfig,
};

export const RegionsConfig: {
  teamLookupRegion: string;
  endpoints: {
    [Key: string]: {
      api: string;
      portal: string;
      worker: string;
    };
  };
} = {
  teamLookupRegion: AppConfig.region,
  endpoints: {
    [AppConfig.region]: {
      api: AppConfig.api.endpoint,
      portal: window.location.origin,
      worker: AppConfig.worker.endpoint,
    },
  },
  ...((window as any)?.regionsConfig || {}),
};

export function getRegionKeys(): string[] {
  return Object.keys(RegionsConfig.endpoints);
}

export const STRIPE_PUBLISHABLE_KEY: string = AppConfig.stripe.apiKey;
export const HELP_PAGE_URL: string = AppConfig.helpPageUrl;
export const CREATE_AI_MODAL_REQUEST_URL: string =
  AppConfig.zapier.createAIModelRequestUrl;
export const ENVIRONMENT: string = AppConfig.environment;

export default {
  STRIPE_PUBLISHABLE_KEY,
  HELP_PAGE_URL,
  CREATE_AI_MODAL_REQUEST_URL,
  ENVIRONMENT,
};
