@import "../../styles/variables.scss";

.ocr-test {
  position: relative;
  height: 100%;
  padding: 0 10px;
  overflow: auto;
  display: flex;
  flex-flow: column;
}

.sample-image-box {
  text-align: left;
  padding: 0 10px;
  h1 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .template-description {
    font-weight: 400;
    font-size: 12px;
    color: #605e5c;
    margin-left: 14px;
    i {
      margin-right: 2px;
      vertical-align: bottom;
      margin-bottom: 3px;
    }
  }
}

.report {
  padding: 0 10px;
  margin-bottom: 80px;

  .section {
    margin: 20px;
    margin-top: 0;

    .warped-image-wrapper {
      display: flex;
      background: #faf9f8;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: 500px;
    }

    .control-button {
      width: 32px;
      height: 32px;
      padding: 0;
      min-width: 0;
    }

    .pagination {
      padding: 4px 12px;
      background: rgba(225, 223, 221, 0.5);
      border-radius: 100px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #605e5c;
      position: absolute;
      bottom: 16px;
      left: 24px;
    }

    .extract-error {
      text-align: center;
      padding: 100px 20px;
      font-size: 16px;

      .reason {
        font-size: 14px;
        color: #605e5c;
      }

      img {
        margin-bottom: -60px;
      }
    }

    .jump-to-json {
      position: absolute;
      bottom: 17px;
      left: 92px;
      font-size: 14px;
    }

    h1 {
      padding: 10px 0;
      font-size: 18px;
      margin: 10px 0;
      border-bottom: 1px solid #ccc;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    pre {
      padding: 10px;
      font-family: monospace;
      font-size: 14px;
      line-height: 1.6em;

      background-color: #ddd;
      overflow-x: auto;

      pre {
        padding: 0;
        &.highlighted {
          background-color: #ffd63320;
        }
      }
    }

    .field-attr,
    .tokens-view {
      margin: 10px;
    }

    img {
      max-width: 100%;
      object-fit: contain;
      margin: 0 auto;
      max-height: 100%;
    }

    .tokens-view {
      ul {
        margin: 4px 0;
      }

      li {
        display: inline;
        padding: 2px 6px;
        margin: 0 4px;
        background-color: #f3f2f1;
      }

      li:first-child {
        margin-left: 0;
      }
    }
  }

  .badge {
    display: inline;
    font-size: 10px;
    border: 1px solid #ccc;
    padding: 0 4px;
    margin: 0 4px;
  }
}
