import {
  CreateTeam,
  TeamInvitationAccepted,
  TeamRenamed,
  TeamUserRemoved,
} from "../actions/team";
import { UserInfo } from "../apiClient/authGear";
import { RootAction } from "../redux/types";
import { TeamRef } from "../types/team";
import { User } from "../types/user";

export interface UserState {
  readonly isAuthenticated: boolean;
  readonly userInfo?: UserInfo;
  readonly currentUser?: User;
  readonly teamRefsByRegion: { [Key: string]: TeamRef[] };
}

const defaultState: UserState = {
  teamRefsByRegion: {},
  isAuthenticated: false,
};

export function reducer(
  state: UserState = defaultState,
  action: RootAction
): UserState {
  switch (action.type) {
    case "Authenticated":
      return {
        ...state,
        isAuthenticated: true,
      };
    case "GotUserInfo":
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case "UserCreated":
      return {
        ...state,
        currentUser: action.user,
      };
    case "UserLogin":
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload.user,
      };
    case "UserLogout":
      return defaultState;
    case "GotTeamRefs":
      return {
        ...state,
        teamRefsByRegion: {
          ...state.teamRefsByRegion,
          [action.region]: action.refs,
        },
      };
    case "PlanUpdated":
      if (!state.currentUser?.resourceOwner) {
        return {
          ...state,
        };
      }
      if (!action.teamId) {
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            resourceOwner: {
              ...state.currentUser?.resourceOwner,
              plan: action.plan,
              planId: action.planId,
            },
          },
        };
      } else {
        const newTeams = state.currentUser.teams.map(team => {
          if (team.id === action.teamId) {
            return {
              ...team,
              plan: action.plan,
              planId: action.planId,
            };
          }
          return team;
        });
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            teams: newTeams,
          },
        };
      }

    case CreateTeam:
    case TeamInvitationAccepted:
      if (!state.currentUser) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          teams: [...state.currentUser.teams, action.team],
          permissions: [
            ...state.currentUser.permissions,
            {
              teamId: action.team.id,
              permission: action.permission,
            },
          ],
        },
      };

    case TeamUserRemoved: {
      const { teamId, currentUser, removedUserId } = action;
      if (removedUserId !== currentUser.id) {
        return state;
      }
      return removeTeamFromState(teamId, state);
    }

    case "TeamDeleted": {
      const { teamId } = action;
      return removeTeamFromState(teamId, state);
    }

    case TeamRenamed: {
      const { teamId, name } = action;
      if (!state.currentUser) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          teams: state.currentUser.teams.map(team => {
            if (team.id === teamId) {
              return {
                ...team,
                name,
              };
            }
            return team;
          }),
        },
      };
    }

    default:
      return state;
  }
}

function removeTeamFromState(teamId: string, state: UserState): UserState {
  if (!state.currentUser) {
    return state;
  }
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      teams: state.currentUser.teams.filter(team => team.id !== teamId),
      permissions: state.currentUser.permissions.filter(
        permission => permission.teamId !== teamId
      ),
    },
  };
}
