import React from "react";
import { useParams } from "react-router";

import { useCustomModelActionCreator } from "../actions/customModel";
import CustomModelLabel from "../components/CustomModelLabel";
import { CustomModelNavBarLayout } from "../components/CustomModelNavBar";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import {
  useAutoReloadCustomModel,
  useCommonCustomModelContainerState,
} from "../hooks/custom_model";
import { useToast } from "../hooks/toast";

type PathParam = {
  customModelId: string;
};

function _CustomModelLabelContainer() {
  const { customModelId } = useParams<PathParam>();

  const toast = useToast();
  const { triggerSyncWithCVAT, triggerModelTraining } =
    useCustomModelActionCreator();
  const containerState = useCommonCustomModelContainerState(customModelId);

  const containerStateRef = React.useRef(containerState);
  containerStateRef.current = containerState;

  const isTriggeringSyncWithCVATRef = React.useRef(false);
  const [isTriggeringSyncWithCVAT, setIsTriggeringSyncWithCVAT] =
    React.useState(false);
  const doTriggerSyncWithCVAT = React.useCallback(async () => {
    if (isTriggeringSyncWithCVATRef.current) {
      return;
    }

    if (containerStateRef.current.state === "success") {
      isTriggeringSyncWithCVATRef.current = true;
      setIsTriggeringSyncWithCVAT(true);
      const { workerToken, customModel } = containerStateRef.current;
      try {
        await triggerSyncWithCVAT(workerToken, customModel.id);
      } catch (e) {
        console.error(e);
        toast.error("custom_model_label.sync.error");
      } finally {
        isTriggeringSyncWithCVATRef.current = false;
        setIsTriggeringSyncWithCVAT(false);
      }
    }
  }, [triggerSyncWithCVAT, toast]);

  const openCVATProject = React.useCallback(() => {
    if (containerStateRef.current.state === "success") {
      window.open(
        `https://cvat.pandawork.com/projects/${containerStateRef.current.customModel.CVATProjectID}`
      );
    }
  }, []);

  const isTriggeringModelTrainingRef = React.useRef(false);
  const [isTriggeringModelTraining, setITriggeringModelTraining] =
    React.useState(false);
  const doTriggerModelTraining = React.useCallback(async () => {
    if (isTriggeringModelTrainingRef.current) {
      return;
    }
    if (containerStateRef.current.state === "success") {
      isTriggeringModelTrainingRef.current = true;
      setITriggeringModelTraining(true);
      const { customModel } = containerStateRef.current;
      try {
        await triggerModelTraining(customModel.id);
      } catch (e) {
        console.error(e);
        toast.error("custom_model_label.train.error");
      } finally {
        isTriggeringModelTrainingRef.current = false;
        setITriggeringModelTraining(false);
      }
    }
  }, [triggerModelTraining, toast]);

  useAutoReloadCustomModel(
    containerState.state === "success" ? containerState.customModel.id : null,
    containerState.state === "success" &&
      containerState.isSyncWithCVATInProgress,
    30 * 1000
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        ) : containerState.state === "success" ? (
          <CustomModelNavBarLayout customModel={containerState.customModel}>
            <CustomModelLabel
              isSyncWithCVATInProgress={
                containerState.isSyncWithCVATInProgress ||
                isTriggeringSyncWithCVAT
              }
              isTrainingInProgress={
                containerState.isTrainingInProgress || isTriggeringModelTraining
              }
              canAccessCVATProject={containerState.canAccessCVATProject}
              triggerSyncWithCVAT={doTriggerSyncWithCVAT}
              openCVATProject={openCVATProject}
              triggerModelTraining={doTriggerModelTraining}
            />
          </CustomModelNavBarLayout>
        ) : null}
      </Main>
    </Layout>
  );
}

export const CustomModelLabelContainer = React.memo(_CustomModelLabelContainer);
export default CustomModelLabelContainer;
