import {
  ActionButton,
  Dropdown,
  Icon,
  Label,
  PrimaryButton,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React from "react";

import { useDetectionRegionInspector } from "../../contexts/detectionRegionInspector";
import { useFormEditor } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useDetectionRegionFieldTypeHierarchy } from "../../hooks/detection_region_field";
import { useTeamPermission } from "../../hooks/permission";
import { DetectionRegion } from "../../types/detectionRegion";
import DetectionRegionFieldCard from "../DetectionRegionFieldCard";
import InfoNote from "../InfoNote";
import styles from "./styles.module.scss";

interface Props {
  selectedDetectionRegionId: string;
  selectedDetectionRegion: DetectionRegion;
}

const DetectionRegionInspectorV2 = React.memo((props: Props) => {
  const { selectedDetectionRegionId, selectedDetectionRegion } = props;
  const { localized } = useLocale();

  const { fields } = selectedDetectionRegion.config;

  const {
    addDetectionRegionField,
    detectDetectionRegionExtractionType,
    onDetectDetectionRegionExtractionTypeChange,
    checkAndAddDetectionRegionField,
    detectDetectionRegionExtractionErrorMessage,
  } = useDetectionRegionInspector();
  const { deleteDetectionRegion, onSelectDetectionRegion } = useFormEditor();

  const onDeleteDetectionRegion = React.useCallback(() => {
    onSelectDetectionRegion(undefined);
    deleteDetectionRegion(selectedDetectionRegionId);
  }, [
    onSelectDetectionRegion,
    deleteDetectionRegion,
    selectedDetectionRegionId,
  ]);

  const { getDetectionRegionFieldTypeHierarchyKeyTexts } =
    useDetectionRegionFieldTypeHierarchy();

  const detectionRegionFieldTypeOptions =
    getDetectionRegionFieldTypeHierarchyKeyTexts();

  const { hasPermissionToEditResource } = useTeamPermission();

  const onAddNewFieldButtonClicked = React.useCallback(() => {
    checkAndAddDetectionRegionField(
      selectedDetectionRegionId,
      addDetectionRegionField
    );
  }, [
    addDetectionRegionField,
    checkAndAddDetectionRegionField,
    selectedDetectionRegionId,
  ]);

  return (
    <div className={styles["detection-region-insepctor"]}>
      <InfoNote
        notes={[
          "form_inspector.detection_region.area",
          "form_inspector.detection_region.reminder",
          "form_inspector.detection_region.hints",
        ]}
      />
      <div className={styles["sub-content"]}>
        {hasPermissionToEditResource && (
          <>
            <div className={styles["add_extraction_field"]}>
              <Label className={styles["add_extraction_field_label"]}>
                <FormattedMessage id="form_inspector.add_extraction_field" />
              </Label>
              <Icon iconName="Info" />
            </div>

            <Dropdown
              placeholder={localized("form_inspector.choose_extraction_type")}
              selectedKey={detectDetectionRegionExtractionType}
              onChange={onDetectDetectionRegionExtractionTypeChange}
              errorMessage={detectDetectionRegionExtractionErrorMessage}
              options={detectionRegionFieldTypeOptions.map(({ key, text }) => ({
                key,
                text: localized(text),
              }))}
            />

            <div className={styles["button-container"]}>
              <PrimaryButton
                className={styles["button"]}
                text={localized("form_inspector.add_new_field")}
                onClick={onAddNewFieldButtonClicked}
              />

              <ActionButton
                iconProps={{ iconName: "Delete" }}
                className={classnames(
                  styles["button"],
                  styles["delete-button"]
                )}
                onClick={onDeleteDetectionRegion}
              >
                {localized("form_inspector.delete_region")}
              </ActionButton>
            </div>
          </>
        )}

        {fields.map((field, fieldIndex) => {
          return (
            <DetectionRegionFieldCard
              key={fieldIndex}
              selectedDetectionRegionId={selectedDetectionRegionId}
              field={field}
              fieldIndex={fieldIndex}
            />
          );
        })}
      </div>
    </div>
  );
});

export default DetectionRegionInspectorV2;
