import { Image } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { AppConfig } from "../../config";
import downloadForMac from "../../images/download-mac.svg";
import downloadForWindows from "../../images/download-win.svg";
import styles from "./styles.module.scss";

function _BatchNote() {
  const onMacOsAppClick = React.useCallback(() => {
    if (AppConfig.desktopApp && AppConfig.desktopApp.macOSUrl) {
      window.open(AppConfig.desktopApp.macOSUrl, "_blank");
    }
  }, []);

  const onWindowsAppClick = React.useCallback(() => {
    if (AppConfig.desktopApp && AppConfig.desktopApp.windowsUrl) {
      window.open(AppConfig.desktopApp.windowsUrl, "_blank");
    }
  }, []);

  return (
    <div className={styles["batch-note"]}>
      <section>
        <h1>
          <FormattedMessage id="batch.batch_extraction_using_desktop_app" />
        </h1>

        <div>
          <FormattedMessage id="batch.introduction" />
          <ol className={styles["steps"]}>
            <li>
              <FormattedMessage id="batch.step_download" />
            </li>
            <li>
              <FormattedMessage id="batch.step_open" />
              <ul className={styles["extra-info"]}>
                <li>
                  <FormattedMessage id="batch.first_time_open" />
                </li>
                <li>
                  <FormattedMessage id="batch.manual_fill_in" />
                </li>
              </ul>
            </li>
            <li>
              <FormattedMessage id="batch.step_extract" />
            </li>
            <li>
              <FormattedMessage id="batch.step_result" />
            </li>
          </ol>
        </div>
      </section>

      <section>
        <h1>
          <FormattedMessage id="batch.download" />
        </h1>

        <div className={styles["download-buttons"]}>
          <Image src={downloadForMac} onClick={onMacOsAppClick} />
          <Image src={downloadForWindows} onClick={onWindowsAppClick} />
        </div>
      </section>
    </div>
  );
}

export const BatchNote = React.memo(_BatchNote);
export default BatchNote;
