import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";

import { Usage } from "../../types/usage";
import { Table } from "../Table";
import styles from "./styles.module.scss";

interface Props {
  usages: Usage[];
}

const obsoletedApiList = ["extract_fields", "extract_receipt_info"];
const noEntityApiList = ["detect_documents"];

function _UsageView(props: Props) {
  const { usages } = props;
  return (
    <div className={styles["usage-history"]}>
      {usages.map((usage: Usage, usageIndex: number) =>
        !obsoletedApiList.includes(usage.api) || usage.total > 0 ? (
          <div className={styles["usage"]} key={usageIndex}>
            <div
              className={classnames(styles["api-name-label"], {
                [styles["no-border"]]: noEntityApiList.includes(usage.api),
              })}
            >
              <label>
                <FormattedMessage id={`usage.api.${usage.api}`} />
              </label>
              <label className={styles["total-label"]}>{usage.total}</label>
            </div>
            {!noEntityApiList.includes(usage.api) && (
              <>
                <Table
                  columnIds={[
                    "usage.table.name",
                    "usage.table.entity_id",
                    "usage.table.count",
                  ]}
                  className={styles["table"]}
                >
                  {usage.counts.map((usage, i) => (
                    <tr key={i}>
                      <td>{usage.name}</td>
                      <td>{usage.entityId}</td>
                      <td>{usage.count}</td>
                    </tr>
                  ))}
                </Table>
                <div className={styles["table_total"]}>
                  <FormattedMessage
                    id={"usage.table.total"}
                    values={{ count: usage.total }}
                  />
                </div>
              </>
            )}
          </div>
        ) : null
      )}
    </div>
  );
}

export const UsageView = React.memo(_UsageView);
export default UsageView;
