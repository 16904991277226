@import "../../styles/variables.scss";

$top-height: 43px;
$bottom-height: 64px;
$right-width: 340px;

.layout {
  position: relative;
  height: 100vh;
}

.main {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  > div {
    flex-shrink: 0;
  }

  > .content {
    width: calc(100% - #{$left-bar-collapsed-width});
    height: 100%;
    overflow-y: auto;
    flex-shrink: 0;
  }

  > .with-full-leftbar {
    width: calc(100% - #{$left-bar-full-width});
  }
}

.top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $top-height;
  z-index: 10;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: $bottom-height;
  z-index: 2;
}

.right {
  @media only screen and (max-width: 960px) {
    display: none;
  }

  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: $right-width;
  z-index: 3;
}

.has-top {
  top: $top-height;
}

.has-right {
  right: $right-width;

  @media only screen and (max-width: 960px) {
    right: 0;
  }
}

.has-bottom {
  bottom: $bottom-height;
}
