.position-left {
  background-image: url("../images/icon-position-left.png");
}

.position-right {
  background-image: url("../images/icon-position-right.png");
}

.position-below {
  background-image: url("../images/icon-position-below.png");
}

.position-above {
  background-image: url("../images/icon-position-above.png");
}
