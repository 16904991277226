import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import classnames from "classnames";
import * as React from "react";

import styles from "./styles.module.scss";

interface ImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  frameClass?: string;
}

enum LoadingState {
  Initial,
  Loading,
  Loaded,
  Errored,
}

function useLoadImage(src?: string) {
  const loadingStateRef = React.useRef<LoadingState>(LoadingState.Initial);
  const [loadingState, setLoadingState] = React.useState<LoadingState>(
    loadingStateRef.current
  );

  React.useEffect(() => {
    if (loadingStateRef.current === LoadingState.Initial && src !== undefined) {
      const onLoad = () => {
        loadingStateRef.current = LoadingState.Loaded;
        setLoadingState(loadingStateRef.current);
      };

      const onError = () => {
        loadingStateRef.current = LoadingState.Errored;
        setLoadingState(loadingStateRef.current);
      };

      const image = new Image();
      image.addEventListener("load", onLoad);
      image.addEventListener("error", onError);

      image.src = src;
      if (!image.complete) {
        loadingStateRef.current = LoadingState.Loading;
        setLoadingState(loadingStateRef.current);
      }

      return () => {
        image.removeEventListener("load", onLoad);
        image.removeEventListener("error", onError);
      };
    }

    return () => {};
  }, [src]);

  return loadingState;
}

export const Img: React.FC<ImageProps> = (props: ImageProps) => {
  const loadingState = useLoadImage(props.src);

  const { frameClass, ...rest } = props;

  switch (loadingState) {
    case LoadingState.Loading:
      return (
        <div className={classnames(styles["frame"], frameClass)}>
          <div>
            <Spinner size={SpinnerSize.medium} />
          </div>
        </div>
      );
    case LoadingState.Initial:
    case LoadingState.Loaded:
      if (frameClass) {
        return (
          <div className={frameClass}>
            {<img {...rest} alt={rest.alt || ""} />}
          </div>
        );
      }
      return <img {...rest} alt={rest.alt || ""} />;
    case LoadingState.Errored:
      return (
        <div
          className={classnames(styles["frame"], styles["errored"], frameClass)}
        >
          <div>
            <Icon iconName="Cancel" />
          </div>
        </div>
      );
  }
};
