import { MessageBar, MessageBarType } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useParams } from "react-router";

import { CustomModelNavBarLayout } from "../components/CustomModelNavBar";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { OCRTest } from "../components/OCRTest";
import HeaderContainer from "../containers/Header";
import { useTestCommandBarItems } from "../hooks/command_bar_items";
import { useCommonCustomModelContainerState } from "../hooks/custom_model";
import { useExtractTest } from "../hooks/extract_test";
import { FormExtractionMode } from "../types/form";

type PathParam = {
  customModelId: string;
};

function _CustomModelTestContainer() {
  const { customModelId } = useParams<PathParam>();

  const containerState = useCommonCustomModelContainerState(customModelId);

  const {
    extractWithFile,
    extractWithURL,
    isRecognizing,
    isUploading,
    testReport,
    setTestReport,
    extractionMode,
    onExtractionModeChanged,
  } = useExtractTest(
    containerState.state === "success" && containerState.customModel.formID
      ? containerState.customModel.formID
      : undefined
  );

  const commbarBarItems = useTestCommandBarItems(
    extractWithFile,
    !!testReport && extractionMode !== FormExtractionMode.singlePage,
    !!testReport,
    () => setTestReport(undefined)
  );

  const hasDeployedModel =
    containerState.state === "success" &&
    containerState.customModel.modelVersions.find(x => x.isActive) !==
      undefined;

  const shouldShowWarning =
    containerState.state === "success" &&
    (containerState.isDeploymentInProgress || !hasDeployedModel);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        ) : containerState.state === "success" ? (
          <>
            <CustomModelNavBarLayout
              customModel={containerState.customModel}
              commbarBarItems={commbarBarItems}
            >
              {shouldShowWarning && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  <FormattedMessage
                    id={
                      containerState.isDeploymentInProgress
                        ? "custom_model_test.warn.deployment_in_progress"
                        : "custom_model_test.warn.no_deployed_model"
                    }
                  />
                </MessageBar>
              )}
              <OCRTest
                onSelectImage={extractWithFile}
                onSelectSampleImage={extractWithURL}
                isSampleForm={false}
                isTemplate={false}
                isReceiptForm={false}
                ocrTestReport={testReport}
                formName={containerState.customModel.name}
                extractionMode={extractionMode}
                onExtractionModeChanged={onExtractionModeChanged}
              />
            </CustomModelNavBarLayout>
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
          </>
        ) : null}
      </Main>
    </Layout>
  );
}

export const CustomModelTestContainer = React.memo(_CustomModelTestContainer);
export default CustomModelTestContainer;
