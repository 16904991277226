import * as yup from "yup";

import { briefFormSchema } from "./form";
import { briefFormGroupSchema } from "./formGroup";

export const webhookSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    webhookUrl: yup.string().defined(),
    forms: yup.array(briefFormSchema),
    formGroups: yup.array(briefFormGroupSchema),
    updatedAt: yup.string().defined(),
    resourceOwnerId: yup.string().defined(),
  })
  .camelCase();

export type Webhook = yup.InferType<typeof webhookSchema>;

export const webhookListSchema = yup
  .object({
    webhooks: yup.array(webhookSchema).required(),
  })
  .camelCase();

export type WebhookList = yup.InferType<typeof webhookListSchema>;
