import { IconButton } from "@fluentui/react";
import { Link } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React, { useCallback } from "react";
import { useHistory } from "react-router";

import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { useTeamPermission } from "../../hooks/permission";
import IconPdf from "../../images/icon-pdf-file.svg";
import styles from "./styles.module.scss";

interface Props {
  id: string;
  url: string;
  name: string;
  contentType: string;
  onDeleteCustomModel: (customModelId: string) => void;
}

export default function CustomModelGridItem(props: Props) {
  const { url, name, id, contentType, onDeleteCustomModel } = props;
  const link = `/custom-model/${id}/setup`;
  const { push } = useHistory();
  const openCustomModel = useCallback(() => {
    push(link);
  }, [push, link]);
  const { hasPermissionToRemoveResource } = useTeamPermission();

  const onDeleteButtonClicked = useCallback(() => {
    onDeleteCustomModel(id);
  }, [id, onDeleteCustomModel]);

  return (
    <div
      className={classnames(styles["container"], {
        [styles["compact"]]: !hasPermissionToRemoveResource,
      })}
    >
      <Link onClick={openCustomModel}>
        <div className={styles["preivew-container"]}>
          {contentType === "" ? (
            <div className={styles["image-preview"]} />
          ) : SUPPORTED_IMAGE_MIME.includes(contentType) ? (
            <img alt="preview" src={url} className={styles["image-preview"]} />
          ) : (
            <img alt="pdf" src={IconPdf} className={styles["pdf-icon"]} />
          )}
        </div>
      </Link>

      <div className={styles["label"]}>
        <FormattedMessage id="custom_model_grid.custom_model_label" />
      </div>

      <div className={styles["name"]}>{name}</div>

      {hasPermissionToRemoveResource && (
        <>
          <div className={styles["divider"]} />

          <div className={styles["buttons"]}>
            <IconButton
              iconProps={{ iconName: "Delete" }}
              onClick={onDeleteButtonClicked}
            />
          </div>
        </>
      )}
    </div>
  );
}
