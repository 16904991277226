import { AppConfig, RegionsConfig } from "./config";
import {
  EXTRACTION_MAXIMUM_POLLING_TIME,
  EXTRACTION_POLLING_INTERVAL,
} from "./constants";
import errors, { FOCRError } from "./errors";
import {
  DetectMultiDocumentReport,
  OCRTestReportMultipleDocument,
  OCRTestReportSingleDocument,
  ReceiptTestReport,
} from "./models";
import { FormExtractionMode } from "./types/form";
import { getWorkerRequestError } from "./utils/errors";

export interface WorkerResponseSuccess {
  status: "ok";
}

export type WorkerResponseFailure = {
  status: "failed";
  error: WorkerResponseError;
};

export type WorkerResponseError = {
  code: number;
  message: string;
};

export type WorkerResponse = WorkerResponseSuccess | WorkerResponseFailure;

interface OCRTestResponseSingleDocument
  extends WorkerResponseSuccess,
    OCRTestReportSingleDocument {}

interface OCRTestResponseMultipleDocument
  extends WorkerResponseSuccess,
    OCRTestReportMultipleDocument {}

type OCRTestResponse =
  | OCRTestResponseSingleDocument
  | OCRTestResponseMultipleDocument;

interface OCRTestJobCreatedResponse extends WorkerResponseSuccess {
  job_id: string;
}

interface ReceiptTestResponse
  extends WorkerResponseSuccess,
    ReceiptTestReport {}

export interface DetectMultiDocumentResponse
  extends WorkerResponseSuccess,
    DetectMultiDocumentReport {}

export class WorkerClient {
  endpoint: string;
  accessToken: string;

  static accessTokenHeader = "X-WORKER-TOKEN";

  constructor(
    accessToken: string,
    endpoint: string = RegionsConfig.endpoints[AppConfig.region].worker
  ) {
    this.accessToken = accessToken;
    this.endpoint = endpoint;
  }

  prefixEndpoint(endpoint: string): string {
    return new URL(endpoint, this.endpoint).href;
  }

  async fetch(resource: RequestInfo, init?: RequestInit): Promise<Response> {
    const request = new Request(resource, init);
    request.headers.set(WorkerClient.accessTokenHeader, this.accessToken);
    return fetch(request);
  }

  async syncCvatProjectForCustomModel(customModelId: string): Promise<void> {
    const response = (await (
      await this.fetch(
        this.prefixEndpoint("/sync-cvat-project-for-custom-model"),
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            custom_model_id: customModelId,
          }),
        }
      )
    ).json()) as WorkerResponse;

    if (response.status !== "ok") {
      throw response.error;
    }
  }

  async extractFieldInTestMode(
    entityId: string,
    image: File,
    extractionMode: FormExtractionMode,
    startsRecognizing: () => void,
    useAsync: boolean = true
  ): Promise<OCRTestResponse> {
    const formData = new FormData();
    const shouldUseAsync = useAsync && !AppConfig.worker.shouldAsyncDisabled;
    formData.append("form_id", entityId);
    formData.append("image", image);
    formData.append("test_mode", "json");
    formData.append("show_confidence", "true");
    formData.append("async", shouldUseAsync ? "true" : "false");

    switch (extractionMode) {
      case FormExtractionMode.multiPagePdf:
        formData.append("multi_page_pdf", "true");
        break;
      case FormExtractionMode.singlePageMultiDocument:
        formData.append("detect_multi_document", "true");
        break;
    }

    try {
      const response = (await (
        await this.fetch(this.prefixEndpoint("/extract"), {
          method: "POST",
          body: formData,
        })
      ).json()) as WorkerResponse;

      if (response.status === "failed") {
        throw getWorkerRequestError(response.error);
      }

      if (!shouldUseAsync) {
        startsRecognizing();
        return response as OCRTestResponse;
      }

      const jobId = (response as OCRTestJobCreatedResponse).job_id;

      startsRecognizing();

      let timeElapsed = 0;

      while (true) {
        const response = await this.fetch(
          this.prefixEndpoint(`/extract/jobs/${jobId}`)
        );
        if (response.status !== 201) {
          const result = await response.json();
          if (result.status === "failed") {
            throw getWorkerRequestError(result.error);
          }

          return result as OCRTestResponse;
        }
        if (timeElapsed > EXTRACTION_MAXIMUM_POLLING_TIME) {
          throw errors.ExtractionTimeout;
        }
        await new Promise(r => setTimeout(r, EXTRACTION_POLLING_INTERVAL));
        timeElapsed += EXTRACTION_POLLING_INTERVAL;
      }
    } catch (e) {
      if (e instanceof FOCRError) {
        throw e;
      }
      throw getWorkerRequestError(e);
    }
  }

  async extractReceiptInfoInTestMode(
    receiptGroupId: string,
    image: File
  ): Promise<ReceiptTestResponse> {
    const formData = new FormData();
    formData.append("receipt_group_id", receiptGroupId);
    formData.append("image", image);
    formData.append("test_mode", "yes");

    const response = (await (
      await this.fetch(this.prefixEndpoint("/extract-receipt-info"), {
        method: "POST",
        body: formData,
      })
    ).json()) as WorkerResponse;

    if (response.status === "failed") {
      throw getWorkerRequestError(response.error);
    }

    return response as ReceiptTestResponse;
  }

  async detectMultiDocument(image: File): Promise<DetectMultiDocumentResponse> {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("should_output_debug_image", "yes");

    const response = (await (
      await this.fetch(this.prefixEndpoint("/detect-documents"), {
        method: "POST",
        body: formData,
      })
    ).json()) as WorkerResponse;

    if (response.status === "failed") {
      throw getWorkerRequestError(response.error);
    }

    return response as DetectMultiDocumentResponse;
  }
}

export function workerClient(accessToken: string): WorkerClient {
  return new WorkerClient(accessToken);
}
