import { produce } from "immer";

import {
  AddCustomModelInfoField,
  AddCustomModelInfoFieldAction,
  AppendSampleImage,
  AppendSampleImageAction,
  CreateCustomModel,
  CreateCustomModelAction,
  DeleteCustomModel,
  DeleteCustomModelAction,
  DeployModelVersion,
  DeployModelVersionAction,
  FreezeFieldsForLabelling,
  GetCustomModel,
  GetCustomModelAction,
  GetCustomModels,
  GetCustomModelsAction,
  GettingCustomModelsAction,
  RemoveCustomModelInfoField,
  RemoveCustomModelInfoFieldAction,
  RemoveSampleImages,
  RemoveSampleImagesAction,
  RenameCustomModel,
  RenameCustomModelAction,
  RequestCustomModelTraining,
  RequestCustomModelTrainingAction,
  ResetDefaultCustomModel,
  ResetDefaultCustomModelAction,
  TriggerModelTraining,
  TriggerSyncWithCVAT,
  UpdateCustomModelInfoField,
  UpdateCustomModelInfoFieldAction,
} from "../actions/customModel";
import {
  CreateTeam,
  TeamInvitationAccepted,
  TeamUserRemoved,
} from "../actions/team";
import { DefaultCustomModel } from "../constants";
import { RootAction } from "../redux/types";
import { BriefCustomModel, CustomModel } from "../types/customModel";
import { PageInfo } from "../types/pageInfo";

export interface CustomModelState {
  readonly currentCustomModel: CustomModel;
  readonly customModels: BriefCustomModel[];
  readonly pageInfo?: PageInfo;
  readonly listVersion: number;
}

const defaultState: CustomModelState = {
  currentCustomModel: { ...DefaultCustomModel },
  customModels: [],
  listVersion: 0,
};

function resetDefaultCustomModelAction(
  state: CustomModelState,
  action: ResetDefaultCustomModelAction
) {
  return produce(state, draft => {
    const customModel = { ...DefaultCustomModel };
    customModel.name = action.payload.name;
    draft.currentCustomModel = customModel;
  });
}

function getCustomModel(state: CustomModelState, action: GetCustomModelAction) {
  return produce(state, draft => {
    draft.currentCustomModel = action.payload.currentCustomModel;
  });
}

export function gettingCustomModels(
  state: CustomModelState,
  action: GettingCustomModelsAction
) {
  const { listVersion } = action;
  return { ...state, listVersion };
}

function getCustomModels(
  state: CustomModelState,
  action: GetCustomModelsAction
) {
  if (action.payload.listVersion !== state.listVersion) {
    return state;
  }

  return produce(state, draft => {
    const { refresh, customModels, pageInfo, listVersion } = action.payload;

    if (refresh) {
      draft.customModels = customModels;
    } else {
      draft.customModels = [...draft.customModels, ...customModels];
    }
    draft.pageInfo = pageInfo;
    draft.listVersion = listVersion;
  });
}

function appendSampleImage(
  state: CustomModelState,
  action: AppendSampleImageAction
) {
  return produce(state, draft => {
    draft.currentCustomModel.config.sampleImages.push(action.payload);
    draft.currentCustomModel.config.trainingRequested = false;

    if (state.currentCustomModel.config.sampleImages.length === 0) {
      draft.customModels = [
        {
          id: state.currentCustomModel.id,
          name: state.currentCustomModel.name,
          previewImage: {
            assetId: action.payload.assetId,
            contentType: action.payload.contentType,
            url: action.payload.url,
          },
          noOfSampleImages: 0,
          createdAt: new Date(state.currentCustomModel.createdAt ?? ""),
          status: state.currentCustomModel.status,
        },
        ...state.customModels.filter(x => x.id !== state.currentCustomModel.id),
      ];
    }
  });
}

function removeSampleImages(
  state: CustomModelState,
  action: RemoveSampleImagesAction
) {
  return produce(state, draft => {
    const orig = draft.currentCustomModel.config.sampleImages;
    const next = orig.filter(image => {
      return !action.payload.assetIds.has(image.assetId);
    });
    draft.currentCustomModel.config.sampleImages = next;
    draft.currentCustomModel.config.trainingRequested = false;

    if (state.currentCustomModel.config.sampleImages.length === 1) {
      draft.customModels = [
        {
          id: state.currentCustomModel.id,
          name: state.currentCustomModel.name,
          previewImage: null,
          noOfSampleImages: 1,
          createdAt: new Date(),
          status: state.currentCustomModel.status,
        },
        ...state.customModels.filter(x => x.id !== state.currentCustomModel.id),
      ];
    }
  });
}

function addInfoField(
  state: CustomModelState,
  action: AddCustomModelInfoFieldAction
) {
  return produce(state, draft => {
    draft.currentCustomModel.config.fields.unshift(action.payload.value);
    draft.currentCustomModel.config.trainingRequested = false;
  });
}

function removeInfoField(
  state: CustomModelState,
  action: RemoveCustomModelInfoFieldAction
) {
  return produce(state, draft => {
    draft.currentCustomModel.config.fields.splice(action.payload.index, 1);
    draft.currentCustomModel.config.trainingRequested = false;
  });
}

function updateField(
  state: CustomModelState,
  action: UpdateCustomModelInfoFieldAction
) {
  return produce(state, draft => {
    const { index, value } = action.payload;
    draft.currentCustomModel.config.fields[index] = value;
    draft.currentCustomModel.config.trainingRequested = false;
  });
}

function deleteCustomModel(
  state: CustomModelState,
  action: DeleteCustomModelAction
) {
  return produce(state, draft => {
    const orig = draft.customModels;
    const next = orig.filter(model => {
      return model.id !== action.payload.customModelId;
    });
    draft.customModels = next;
  });
}

export function createCustomModel(
  state: CustomModelState,
  action: CreateCustomModelAction
) {
  return produce(state, draft => {
    const { id, name } = action.payload.customModel;

    draft.currentCustomModel = action.payload.customModel;
    draft.customModels = [
      {
        id,
        name,
        previewImage: null,
        noOfSampleImages: 0,
        createdAt: new Date(),
        status: "not_synced",
      },
      ...state.customModels,
    ];
  });
}

export function renameCustomModel(
  state: CustomModelState,
  action: RenameCustomModelAction
) {
  return produce(state, draft => {
    draft.currentCustomModel.name = action.payload.name;
    draft.currentCustomModel.config.trainingRequested = false;

    draft.customModels = [
      {
        id: state.currentCustomModel.id,
        name: action.payload.name,
        previewImage:
          state.customModels.find(x => x.id === state.currentCustomModel.id)
            ?.previewImage || null,
        noOfSampleImages:
          state.customModels.find(x => x.id === state.currentCustomModel.id)
            ?.noOfSampleImages || 0,
        createdAt: new Date(state.currentCustomModel.createdAt ?? ""),
        status: state.currentCustomModel.status,
      },
      ...state.customModels.filter(x => x.id !== state.currentCustomModel.id),
    ];
  });
}

export function requestCustomModelTraining(
  state: CustomModelState,
  action: RequestCustomModelTrainingAction
) {
  return produce(state, draft => {
    const { remark } = action.payload;
    if (draft.currentCustomModel.config.remark === "") {
      draft.currentCustomModel.config.remark = remark;
    }
    draft.currentCustomModel.config.trainingRequested = true;
  });
}

export function freezeFieldsForLabelling(state: CustomModelState) {
  return produce(state, draft => {
    const { frozenFields, fields } = draft.currentCustomModel.config;
    draft.currentCustomModel.config.frozenFields = frozenFields.concat(fields);
    draft.currentCustomModel.config.fields = [];
  });
}

export function triggerSyncWithCVAT(state: CustomModelState) {
  return produce(state, draft => {
    draft.currentCustomModel.lastCVATProjectStartSyncAt = new Date().getTime();
  });
}

export function triggerModelTraining(state: CustomModelState) {
  return produce(state, draft => {
    draft.currentCustomModel.startTrainingAt = new Date().getTime();
  });
}

export function deployModelVersion(
  state: CustomModelState,
  action: DeployModelVersionAction
) {
  return produce(state, draft => {
    draft.currentCustomModel.startDeploymentAt = new Date().getTime();
    draft.currentCustomModel.modelVersions =
      draft.currentCustomModel.modelVersions.map(x => ({
        ...x,
        isActive: x.tag === action.payload.modelVersionTag,
      }));
  });
}

export function reducer(
  state: CustomModelState = defaultState,
  action: RootAction
): CustomModelState {
  switch (action.type) {
    case ResetDefaultCustomModel:
      return resetDefaultCustomModelAction(
        state,
        action as ResetDefaultCustomModelAction
      );
    case CreateCustomModel:
      return createCustomModel(state, action as CreateCustomModelAction);
    case GetCustomModel:
      return getCustomModel(state, action as GetCustomModelAction);
    case "GettingCustomModels":
      return gettingCustomModels(state, action as GettingCustomModelsAction);
    case GetCustomModels:
      return getCustomModels(state, action as GetCustomModelsAction);
    case AppendSampleImage:
      return appendSampleImage(state, action as AppendSampleImageAction);
    case RemoveSampleImages:
      return removeSampleImages(state, action as RemoveSampleImagesAction);
    case AddCustomModelInfoField:
      return addInfoField(state, action as AddCustomModelInfoFieldAction);
    case RemoveCustomModelInfoField:
      return removeInfoField(state, action as RemoveCustomModelInfoFieldAction);
    case UpdateCustomModelInfoField:
      return updateField(state, action as UpdateCustomModelInfoFieldAction);
    case DeleteCustomModel:
      return deleteCustomModel(state, action as DeleteCustomModelAction);
    case RenameCustomModel:
      return renameCustomModel(state, action as RenameCustomModelAction);
    case RequestCustomModelTraining:
      return requestCustomModelTraining(
        state,
        action as RequestCustomModelTrainingAction
      );
    case FreezeFieldsForLabelling:
      return freezeFieldsForLabelling(state);
    case TriggerSyncWithCVAT:
      return triggerSyncWithCVAT(state);
    case TriggerModelTraining:
      return triggerModelTraining(state);
    case DeployModelVersion:
      return deployModelVersion(state, action as DeployModelVersionAction);
    case TeamUserRemoved: {
      const { removedUserId, currentUser } = action;
      if (removedUserId === currentUser.id) {
        return {
          ...defaultState,
        };
      } else {
        return state;
      }
    }
    case "UserLogin":
    case "UserLogout":
      return {
        ...defaultState,
      };

    case "SelectTeam":
    case CreateTeam:
    case TeamInvitationAccepted:
    case "TeamDeleted":
      return {
        ...defaultState,
        listVersion: state.listVersion,
      };
  }

  return state;
}
