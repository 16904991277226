@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.item-key-value {
  width: 100%;
  height: 135px;
  position: relative;
  margin-top: 12px;
  padding: 8px;
  border: 1px solid $tab-bar-separator;
  display: inline-block;
  cursor: pointer;
  @include cardBoxBorderRadius;

  .title-row {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid $tab-bar-separator;

    > label {
      max-width: 180px;
      margin-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .space {
      flex: 1;
    }

    div {
      display: inline-block;
      @include icon-frame-size(20px);
      margin: 6px 3px;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    .position-icon {
      background-position-y: 2px;
    }

    i {
      cursor: pointer;
    }
  }

  .field-item {
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 100%;
    line-height: 32px;
    padding: 0 6px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    > i {
      margin-top: 2px;
      font-size: 24px;
    }

    > span {
      margin-left: 12px;
      flex: 1;
      border-bottom: 1px solid $tab-bar-separator;
    }
  }

  span.special-pattern {
    border: 0;
    margin-top: 2px;
    color: #888;
  }
}
