import { apiClient } from "../apiClient";
import { MAX_RECEIPT_GROUPS } from "../constants";
import { useThunkDispatch } from "../hooks/thunk";
import { ReceiptTestReport } from "../models";
import { Dispatch, LambdaSuccess, RootState } from "../redux/types";
import { BriefForm, BriefFormWithType, DetailedForm } from "../types/form";
import { KeyValue } from "../types/keyValue";
import { PageInfo } from "../types/pageInfo";
import {
  CustomField,
  ReceiptGroup,
  ReceiptGroupWithType,
} from "../types/receiptGroup";
import { TokenGroup } from "../types/tokenGroup";
import { workerClient } from "../workerClient";
import { handleConflict } from "./app";

interface ReceiptTestResponse extends LambdaSuccess, ReceiptTestReport {}

export const GotReceiptGroupList = "GotReceiptGroupList";
export interface GotReceiptGroupListAction {
  readonly type: typeof GotReceiptGroupList;
  readonly payload: {
    receiptGroups: ReceiptGroup[];
    forms: BriefForm[];
    pageInfo: PageInfo;
  };
}

export const FetchReceiptGroupListStarted = "FetchReceiptGroupListStarted";
export interface FetchReceiptGroupListStartedAction {
  readonly type: typeof FetchReceiptGroupListStarted;
}

export const ReceiptGroupSelected = "ReceiptGroupSelected";
export interface SelectReceiptGroupAction {
  readonly type: typeof ReceiptGroupSelected;
  readonly payload: {
    receiptGroup: ReceiptGroupWithType | BriefFormWithType;
  };
}

export const FirstReceiptGroupSelected = "FirstReceiptGroupSelected";
export interface FirstReceiptGroupSelectedAction {
  readonly type: typeof FirstReceiptGroupSelected;
}

export const ReceiptGroupCreated = "ReceiptGroupCreated";
export interface ReceiptGroupCreatedAction {
  readonly type: typeof ReceiptGroupCreated;
  readonly payload: {
    form: BriefForm;
  };
}

export const GotReceiptGroup = "GotReceiptGroup";
export interface GotReceiptGroupAction {
  readonly type: typeof GotReceiptGroup;
  readonly payload: {
    receiptGroup: ReceiptGroup;
  };
}

export const UpdatedReceiptGroup = "UpdatedReceiptGroup";
export interface UpdatedReceiptGroupAction {
  readonly type: typeof UpdatedReceiptGroup;
  readonly payload: {
    receiptGroup: ReceiptGroup;
  };
}

export const UpdatedReceiptForm = "UpdatedReceiptForm";
export interface UpdatedReceiptFormAction {
  readonly type: typeof UpdatedReceiptForm;
  readonly payload: {
    form: BriefForm;
  };
}

export const ReceiptGroupRemoved = "ReceiptGroupRemoved";
export interface ReceiptGroupRemovedAction {
  readonly type: typeof ReceiptGroupRemoved;
  readonly payload: {
    receiptGroupId: string;
  };
}

export const ReceiptFormRemoved = "ReceiptFormRemoved";
export interface ReceiptFormRemovedAction {
  readonly type: typeof ReceiptFormRemoved;
  readonly payload: {
    formId: string;
  };
}

export function list(shouldSelecteFirstReceiptIfNeeded = true) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    dispatch({
      type: FetchReceiptGroupListStarted,
    });

    const isNoReceiptGroupSelected =
      getState().receiptGroup.currentReceiptGroup === undefined;
    const { resourceOwnerId, isTeam } = getState().resourceOwner;

    // We always fetch the first MAX_RECEIPT_GROUPS receipt groups
    return Promise.all([
      isTeam
        ? Promise.resolve(undefined)
        : apiClient.listReceiptGroup(MAX_RECEIPT_GROUPS, ""),
      apiClient.listForm(
        MAX_RECEIPT_GROUPS,
        "",
        {
          isReceipt: true,
        },
        resourceOwnerId
      ),
    ]).then(([listReceiptGroupResult, listFormResp]) => {
      dispatch({
        type: GotReceiptGroupList,
        payload: {
          receiptGroups: listReceiptGroupResult?.receiptGroups ?? [],
          forms: listFormResp.forms,
          pageInfo: listReceiptGroupResult?.pageInfo ?? listFormResp.pageInfo,
        },
      });

      if (shouldSelecteFirstReceiptIfNeeded && isNoReceiptGroupSelected) {
        dispatch({
          type: FirstReceiptGroupSelected,
        });
      }
    });
  };
}

export function select(receiptGroup: ReceiptGroupWithType | BriefFormWithType) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ReceiptGroupSelected,
      payload: {
        receiptGroup,
      },
    });
  };
}

export function create(receiptGroupName: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<BriefForm> => {
    const { resourceOwnerId } = getState().resourceOwner;

    const form = await apiClient.createForm(
      receiptGroupName,
      {
        isReceipt: true,
      },
      resourceOwnerId
    );

    dispatch({
      type: ReceiptGroupCreated,
      payload: {
        form,
      },
    });

    return form;
  };
}

export function get(receiptGroupId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    const receiptGroup = await apiClient.getReceiptGroup(receiptGroupId);

    dispatch({
      type: GotReceiptGroup,
      payload: {
        receiptGroup: receiptGroup,
      },
    });
  };
}

export function getReceiptForm(formId: string) {
  return async (): Promise<DetailedForm> => {
    return await apiClient.getForm(formId);
  };
}

export function removeReceiptGroup(receiptGroupId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    await apiClient.deleteReceiptGroup(receiptGroupId);

    dispatch({
      type: ReceiptGroupRemoved,
      payload: {
        receiptGroupId: receiptGroupId,
      },
    });
    dispatch({
      type: FirstReceiptGroupSelected,
    });
  };
}

export function removeReceiptForm(formId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    await apiClient.deleteForm(formId);

    dispatch({
      type: ReceiptFormRemoved,
      payload: {
        formId: formId,
      },
    });
    dispatch({
      type: FirstReceiptGroupSelected,
    });
  };
}

export function renameReceiptGroup(receiptGroupId: string, newName: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    const receiptGroup = await apiClient.updateReceiptGroup(receiptGroupId, {
      name: newName,
    });

    dispatch({
      type: UpdatedReceiptGroup,
      payload: {
        receiptGroup: receiptGroup,
      },
    });
  };
}

export function updateReceiptForm(
  formId: string,
  newValues: {
    name?: string;
    tokenGroups?: TokenGroup[];
    keyValues?: KeyValue[];
    updatedAt: string;
  }
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<BriefForm> => {
    const form = await handleConflict(
      () =>
        apiClient.updateForm(formId, {
          name: newValues.name,
          tokenGroups: newValues.tokenGroups,
          keyValues: newValues.keyValues,
          shouldOverwrite: false,
          lastRetrieved: newValues.updatedAt,
        }),
      () =>
        apiClient.updateForm(formId, {
          name: newValues.name,
          tokenGroups: newValues.tokenGroups,
          keyValues: newValues.keyValues,
          shouldOverwrite: true,
          lastRetrieved: newValues.updatedAt,
        }),
      {
        titleId: "receipt_group.modified_prompt.title",
        messageId: "receipt_group.modified_prompt.desc",
        actionId: "common.save_and_overwrite",
      }
    )(dispatch, getState);

    dispatch({
      type: UpdatedReceiptForm,
      payload: {
        form,
      },
    });
    return form;
  };
}

function getReceiptGroup(state: RootState, receiptGroupId: string) {
  let receiptGroup = state.receiptGroup.receiptGroups.filter(
    x => x.id === receiptGroupId
  )[0];

  if (!receiptGroup) {
    const { currentReceiptGroup } = state.receiptGroup;
    if (
      currentReceiptGroup &&
      currentReceiptGroup.type === "receipt_group" &&
      currentReceiptGroup.id === receiptGroupId
    ) {
      receiptGroup = currentReceiptGroup;
    }
  }

  return receiptGroup;
}

export function addToken(receiptGroupId: string, token: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const receiptGroup = getReceiptGroup(getState(), receiptGroupId);
    if (!receiptGroup) return;

    const updatedTokens = receiptGroup.tokens;
    const tokensToAppend = token.split(",").map(x => x.toLowerCase().trim());

    for (const token of tokensToAppend) {
      if (updatedTokens.indexOf(token) === -1) {
        updatedTokens.push(token);
      }
    }

    const updatedReceiptGroup = await apiClient.updateReceiptGroup(
      receiptGroupId,
      {
        tokens: updatedTokens,
      }
    );

    dispatch({
      type: UpdatedReceiptGroup,
      payload: {
        receiptGroup: updatedReceiptGroup,
      },
    });
  };
}

export function removeToken(receiptGroupId: string, token: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const receiptGroup = getReceiptGroup(getState(), receiptGroupId);
    if (!receiptGroup) return;
    const updatedTokens = receiptGroup.tokens.filter(x => x !== token);

    const updatedReceiptGroup = await apiClient.updateReceiptGroup(
      receiptGroupId,
      { tokens: updatedTokens }
    );

    dispatch({
      type: UpdatedReceiptGroup,
      payload: {
        receiptGroup: updatedReceiptGroup,
      },
    });
  };
}

export function addCustomField(
  receiptGroupId: string,
  customField: CustomField,
  originalFieldName?: string
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const receiptGroup = getReceiptGroup(getState(), receiptGroupId);
    if (!receiptGroup) return;

    const updatedCustomFields = receiptGroup.custom_fields.map(x =>
      x.name === originalFieldName ? customField : x
    );

    if (!updatedCustomFields.find(x => x.name === customField.name)) {
      updatedCustomFields.push(customField);
    }

    const updatedReceiptGroup = await apiClient.updateReceiptGroup(
      receiptGroupId,
      {
        custom_fields: updatedCustomFields,
      }
    );

    dispatch({
      type: UpdatedReceiptGroup,
      payload: {
        receiptGroup: updatedReceiptGroup,
      },
    });
  };
}

export function removeCustomField(receiptGroupId: string, fieldName: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const receiptGroup = getReceiptGroup(getState(), receiptGroupId);
    if (!receiptGroup) return new Promise<void>(() => {});

    const updatedCustomFields = receiptGroup.custom_fields.filter(
      x => x.name !== fieldName
    );

    const updatedReceiptGroup = await apiClient.updateReceiptGroup(
      receiptGroupId,
      {
        custom_fields: updatedCustomFields,
      }
    );

    dispatch({
      type: UpdatedReceiptGroup,
      payload: {
        receiptGroup: updatedReceiptGroup,
      },
    });
  };
}

export function extractReceiptInfoInTestMode(
  token: string,
  receiptGroupId: string,
  image: File
): Promise<ReceiptTestResponse> {
  return workerClient(token).extractReceiptInfoInTestMode(
    receiptGroupId,
    image
  );
}

export type ReceiptGroupAction =
  | GotReceiptGroupListAction
  | SelectReceiptGroupAction
  | FirstReceiptGroupSelectedAction
  | ReceiptGroupCreatedAction
  | GotReceiptGroupAction
  | ReceiptGroupRemovedAction
  | ReceiptFormRemovedAction
  | UpdatedReceiptGroupAction
  | UpdatedReceiptFormAction
  | FetchReceiptGroupListStartedAction;

export function useReceiptGroupActionCreator() {
  return {
    removeReceiptGroup: useThunkDispatch(removeReceiptGroup),
    renameReceiptGroup: useThunkDispatch(renameReceiptGroup),
    addToken: useThunkDispatch(addToken),
    removeToken: useThunkDispatch(removeToken),
    addCustomField: useThunkDispatch(addCustomField),
    removeCustomField: useThunkDispatch(removeCustomField),

    removeReceiptForm: useThunkDispatch(removeReceiptForm),
    getReceiptForm: useThunkDispatch(getReceiptForm),
    updateReceiptForm: useThunkDispatch(updateReceiptForm),
  };
}
