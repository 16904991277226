import { Values as _LocalizerValue } from "@oursky/react-messageformat";

import { TutorialActionType } from "./actions/tutorial";
import { DetectionRegionField } from "./types/detectionRegion";
import {
  ScriptEditorModalPayloadForDetectionRegionField,
  ScriptEditorModalPayloadForForm,
  ScriptEditorModalPayloadForFormGroup,
} from "./types/scriptEditorModalPayload";

export type Dimension = {
  width: number;
  height: number;
};

export type OCRTestField = {
  region_id: string;
  name: string;
  type: string;
  value: any;
  image: string;
  error?: string;
  confidence?: number | null;
};

export type OCRKeyValue = {
  name: string;
  value: any;
  confidence?: number | null;
  image?: string;
};

export type OCRToken = {
  id: string;
  value: string;
  confidence?: number | null;
};

export type OCRTokenGroup = {
  name: string;
  texts?: OCRToken[];
  images?: OCRToken[];
};

export type CustomModelFieldResult = {
  value: any;
  confidence?: number | null;
};

export type CustomModelSectionResult = {
  [key in string]: CustomModelFieldResult[];
};

export type CustomModelResult = {
  id: string;
  name: string;
  model_version: string;
  result: CustomModelSectionResult;
};

export type OCRTestReportSingleDocument = {
  form_id: string;
  warped_image: string;
  fields: OCRTestField[];
  key_values?: OCRKeyValue[];
  token_groups?: OCRTokenGroup[];
  auto_extraction_items?: OCRKeyValue[];
  custom_models?: CustomModelResult[];
};

export type OCRTestReportError = {
  error: {
    code: number;
    message: string;
  };
};

export type OCRTestReportMultipleDocument = {
  documents: (OCRTestReportSingleDocument | OCRTestReportError)[];
};

export type OCRTestReport =
  | OCRTestReportSingleDocument
  | OCRTestReportMultipleDocument;

export type DetectMultiDocumentDocument = {
  bbox: number[];
  bbox_score: number;
  type: string;
  type_score: number;
};

export type DetectMultiDocumentReport = {
  documents: DetectMultiDocumentDocument[];
  warped_image: string;
};

export interface AutoExtractionName {
  title: string;
  name: string;
}

export function isAutoExtractionName(val: any): val is AutoExtractionName {
  return val instanceof Object && "title" in val && "name" in val;
}

export type DetectedCustomField = {
  name: string;
  value: string;
};

export type ReceiptTestReport = {
  receipt_group_id: string;
  amount: number;
  date: string;
  time: string;
  tokens: string[];
  custom_fields: DetectedCustomField[];
  image: string;
};

export type APIParameterAction = "asyncMode";

export type APIParameter = {
  name: string;
  defaultValue?: string;
  isOptional?: boolean;
  actions?: APIParameterAction[];
};

export type APIResponseField = {
  name: string;
  isOptional?: boolean;
  fieldType: string;
};

export type APIType = {
  name: string;
  fields: APIResponseField[];
};

export type APIDescription = {
  name: string;
  method: string;
  endpoint: string;
  cURLExample: string;

  headerParams: APIParameter[];
  formParams: APIParameter[];
  responseFields: APIResponseField[];
  types?: APIType[];
};

export interface SelectOption {
  label: string;
  value: string;
}

export interface ScriptFunctionContent {
  name: string;
  parameters: ScriptFunctionParameter[];
  returnValue?: ScriptFunctionParameter;
}

export interface ScriptFunctionParameter {
  name: string;
  type: string;
  isOptional?: boolean;
  fields?: ScriptFunctionParameter[];
}

export type ExternalServices = "azure" | "google";

export type ScriptEditorModalPayload =
  | ScriptEditorModalPayloadForDetectionRegionField
  | ScriptEditorModalPayloadForForm
  | ScriptEditorModalPayloadForFormGroup;

export interface BaseFieldSettingModalPayload {
  field: DetectionRegionField;
  index: number;
  selectedDetectionRegionId: string;
}

export interface MinimalFieldModalPayload extends BaseFieldSettingModalPayload {
  titleId: string;
}

export type PathParam = {
  formId: string;
  formGroupId: string;
};

export type AdminPathParam = {
  teamId: string;
  region: string;
};

export type FormEditorTab = "region" | "document" | "settings";

export interface UploadAssetResponse {
  url?: string;
  name: string;
}

export interface UpdateImagePayload {
  imageUrl: string;
  imageId: string;
}

export enum ModalState {
  OpenedToEdit,
  OpenedToCreate,
  Closed,
}

export type LocalizerValue = _LocalizerValue;

export type Localizer = (key: string, values?: LocalizerValue) => string;

export interface ToastOptions {
  id?: string;
  autoDismiss?: boolean;
  onDismiss?: (id: string) => void;
}

export interface TutorialContent {
  id: string;
  titleId: string;
  descriptionId: string;
  primaryButton: {
    textId: string;
    action: TutorialActionType;
  };
  secondaryButton?: {
    textId: string;
    action: TutorialActionType;
  };
  hideFooter?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TutorialArray extends Array<Tutorial> {}

export type Tutorial = TutorialContent | TutorialArray;
export type TutorialIndex = number[];
