import { TeachingBubble } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import {
  MoveToNextStep,
  SetCurrentTutorial,
  TerminateCurrentTutorial,
  TutorialActionType,
} from "../actions/tutorial";
import { useLocale } from "../contexts/locale";
import { useTutorial } from "../contexts/tutorial";

const Tutorial = React.memo(() => {
  const { localized } = useLocale();

  const {
    content,
    step,
    moveToNextStep,
    terminateCurrentTutorial,
    setCurrentTutorial,
  } = useTutorial();

  const actionSwitcher = useCallback(
    (action: TutorialActionType) => {
      switch (action) {
        case MoveToNextStep:
          return moveToNextStep;
        case TerminateCurrentTutorial:
          return terminateCurrentTutorial;
        case SetCurrentTutorial:
          return setCurrentTutorial;
      }
    },
    [moveToNextStep, terminateCurrentTutorial, setCurrentTutorial]
  );

  return content ? (
    <TeachingBubble
      target={`#${content.id}`}
      headline={localized(content.titleId)}
      primaryButtonProps={{
        text: localized(content.primaryButton.textId),
        onClick: () => {
          actionSwitcher(content.primaryButton.action)(content);
        },
      }}
      secondaryButtonProps={
        content.secondaryButton
          ? {
              text: localized(content.secondaryButton.textId),
              onClick: () => {
                if (content.secondaryButton) {
                  actionSwitcher(content.secondaryButton.action)(content);
                }
              },
            }
          : undefined
      }
      footerContent={
        content.hideFooter !== true && step
          ? localized("teaching_bubble.step", {
              numerator: step[0],
              denominator: step[1],
            })
          : undefined
      }
    >
      <FormattedMessage id={content.descriptionId} />
    </TeachingBubble>
  ) : null;
});

export default Tutorial;
