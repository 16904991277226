import React from "react";
import { useSelector } from "react-redux";

import { AppConfig } from "../../config";
import { useFetchTeamUsage } from "../../hooks/admin";
import { RootState } from "../../redux/types";
import { Usage, UsageRange } from "../../types/usage";
import ShortSpinner from "../ShortSpinner";
import UsageView from "../Usage";
import UsageDateRangeSelector from "../UsageDateRangeSelector";
import styles from "./styles.module.scss";

interface ReduxProps {
  usages?: Usage[];
  isFetching: boolean;
}

function useRedux(): ReduxProps {
  const { usages, isFetching } = useSelector((state: RootState) => {
    const { usages, isFetching } = state.admin.teamDetail.usageState;

    return {
      usages,
      isFetching,
    };
  });

  return { usages, isFetching };
}

interface Props {
  teamId: string;
  region: string;
}

function useDateRangeSelector() {
  const [dateFrom, setDateFrom] = React.useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  });
  const [dateTo, setDateTo] = React.useState(new Date());

  const dateFromRef = React.useRef(dateFrom);
  dateFromRef.current = dateFrom;

  const dateToRef = React.useRef(dateTo);
  dateToRef.current = dateTo;

  const onDateRangeChange = React.useCallback(
    (dateFrom: Date, dateTo: Date) => {
      setDateFrom(dateFrom);
      setDateTo(dateTo);
    },
    []
  );

  return React.useMemo(
    () => ({
      dateFromRef,
      dateToRef,
      onDateRangeChange,
    }),
    [dateFromRef, dateToRef, onDateRangeChange]
  );
}

function _TeamDetailUsage(props: Props) {
  const { teamId, region } = props;
  const { isFetching, usages } = useRedux();
  const { dateFromRef, dateToRef, onDateRangeChange } = useDateRangeSelector();
  const [usageRange, setUsageRange] = React.useState<UsageRange>({
    start: new Date(dateFromRef.current.setHours(0, 0, 0, 0)).toISOString(),
    end: new Date(dateToRef.current.setHours(23, 59, 59, 999)).toISOString(),
  });

  const searchDateRange = React.useCallback(() => {
    setUsageRange({
      start: new Date(dateFromRef.current.setHours(0, 0, 0, 0)).toISOString(),
      end: new Date(dateToRef.current.setHours(23, 59, 59, 999)).toISOString(),
    });
  }, [dateFromRef, dateToRef]);

  React.useEffect(() => {
    searchDateRange();
  }, [searchDateRange]);

  useFetchTeamUsage(usageRange, teamId, region);

  const minDate = React.useMemo(
    () =>
      new Date(
        Date.now() -
          (AppConfig.usageDateRangeSelectorMinDate || 365) * 24 * 60 * 60 * 1000
      ),
    []
  );

  return (
    <>
      {isFetching && <ShortSpinner key={0} />}
      {!isFetching && (
        <div className={styles["team-usage"]}>
          <UsageDateRangeSelector
            onDateRangeChange={onDateRangeChange}
            onDateRangeSearch={searchDateRange}
            dateFrom={dateFromRef.current}
            dateTo={dateToRef.current}
            minDate={minDate}
          />
          <UsageView usages={usages ?? []} />
        </div>
      )}
    </>
  );
}

export const TeamDetailUsage = React.memo(_TeamDetailUsage);
export default TeamDetailUsage;
