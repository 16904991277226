import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { BriefForm } from "../../types/form";
import { FormGroupAnchor } from "../../types/formGroup";
import styles from "./styles.module.scss";

interface Props {
  forms: BriefForm[];
  anchor: FormGroupAnchor | undefined;
  onCloseModal: () => void;
  onSave: (anchorId: string | undefined, formId: string) => void;
}

const FormGroupAnchorModal = React.memo((props: Props) => {
  const { anchor, onSave, onCloseModal, forms } = props;

  const isEdit = anchor !== undefined;

  const { localized } = useLocale();

  const formOptions = React.useMemo((): IDropdownOption[] => {
    const allForms = anchor ? [anchor.form, ...forms] : forms;
    const options: IDropdownOption[] = allForms.map(form => {
      let text = form.name;
      if (!form.isAvailableInAnchorFormGroup) {
        text += ` (${localized("form_group_anchor_modal.no_anchor")})`;
      }
      return {
        key: form.id,
        text,
        disabled: !form.isAvailableInAnchorFormGroup,
      };
    });
    return options;
  }, [forms, anchor, localized]);

  const [isFormIdInvalid, setIsFormIdInvalid] = React.useState<boolean>(false);
  const [formId, setFormId] = React.useState<string | undefined>(
    anchor ? anchor.formId : undefined
  );

  const onSaveClicked = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!formId) {
        setIsFormIdInvalid(true);
        return;
      }
      onSave(anchor && anchor.id, formId);
      onCloseModal();
    },
    [onSave, formId, anchor, onCloseModal]
  );

  const onSelectedFormChange = React.useCallback(
    (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) return;
      setFormId(option.key as string);
    },
    []
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isEdit
          ? "form_group_anchor_modal.title.edit"
          : "form_group_anchor_modal.title.add"
      ),
    }),
    [localized, isEdit]
  );

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  return (
    <Dialog
      hidden={false}
      onDismiss={onCloseModal}
      minWidth={400}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form onSubmit={onSaveClicked}>
        <Dropdown
          selectedKey={formId}
          options={formOptions}
          onChange={onSelectedFormChange}
          label={localized("form_group_anchor_modal.select_a_form")}
          placeholder={localized(
            "form_group_anchor_modal.please_select_a_form"
          )}
          errorMessage={
            isFormIdInvalid
              ? localized("form_group_anchor_modal.please_select_a_form")
              : undefined
          }
        />
        <div className={styles["anchor-modal-description"]}>
          <Text>{localized("form_group_anchor_modal.description")}</Text>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={onCloseModal}
            text={localized("common.cancel")}
          />
          <PrimaryButton type="submit" text={localized("common.save")} />
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export { FormGroupAnchorModal };
