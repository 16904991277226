.permission-toggle {
  font-size: 14px;
  font-weight: 400;
  color: rgb(32, 30, 31);
  text-align: left;
  width: calc(100% + 24px);
  height: 43px;
  border-style: none;

  padding-bottom: 11px;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 11px;

  left: -12px;

  :global .ms-Button-flexContainer {
    align-items: center;
  }

  :global .ms-Button-label {
    margin-left: 0px;
    margin-top: 1px;
    height: 100%;
  }

  :global .ms-Button-menuIcon {
    margin-left: auto;
    margin-right: 0;
  }
}
