import {
  AcceptTeamInvitationResp,
  TeamInvitationIsValidResp,
  acceptTeamInvitationRespSchema,
  teamInvitationIsValidRespSchema,
} from "../../types/team";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface InvitationApiClient {
  acceptTeamInvitation: (
    invitationCode: string
  ) => Promise<AcceptTeamInvitationResp>;
  removeInvitation: (invitationCode: string) => Promise<void>;
  invitationIsValid: (
    invitationCode: string
  ) => Promise<TeamInvitationIsValidResp>;
}

export function withInvitationApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async acceptTeamInvitation(
      invitationCode: string
    ): Promise<AcceptTeamInvitationResp> {
      return this.lambda(
        "invitation:accept",
        {
          invitation_id: invitationCode,
        },
        acceptTeamInvitationRespSchema.defined()
      );
    }

    async removeInvitation(invitationCode: string): Promise<void> {
      return this.lambda("invitation:remove", {
        invitation_id: invitationCode,
      });
    }

    async invitationIsValid(
      invitationCode: string
    ): Promise<TeamInvitationIsValidResp> {
      return this.lambda(
        "invitation:check",
        {
          invitation_id: invitationCode,
        },
        teamInvitationIsValidRespSchema.defined()
      );
    }
  };
}
