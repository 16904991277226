import { IBreadcrumbProps, Breadcrumb as MSBreadcrumb } from "@fluentui/react";
import React from "react";

import styles from "./styles.module.scss";

export const Breadcrumb: React.FC<IBreadcrumbProps> = props => {
  return (
    <div className={styles["breadcrumb-wrapper"]}>
      <MSBreadcrumb {...props} />
    </div>
  );
};
