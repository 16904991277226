import { Link } from "@fluentui/react";
import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useCustomModelEditor } from "../../contexts/customModelEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import styles from "./styles.module.scss";

const MAX_FILE_DEPTH = 10;

async function findFiles(items: DataTransferItemList) {
  const files: File[] = [];

  const addDirectory = async (item: any, level: number) => {
    if (level > MAX_FILE_DEPTH || item === null) {
      return;
    }
    if (item.isDirectory) {
      const directoryReader = item.createReader();
      await new Promise<any>(resolve => {
        directoryReader.readEntries(function (entries: any) {
          resolve(
            Promise.all(
              entries.map(function (entry: any) {
                return addDirectory(entry, level + 1);
              })
            )
          );
        });
      });
    } else {
      const file = await new Promise<File>(resolve => {
        item.file((file: File) => {
          resolve(file);
        });
      });
      files.push(file);
    }
  };

  const pendingItems = Array.from(items).map(item => {
    return item.webkitGetAsEntry();
  });

  await Promise.all(
    pendingItems.map(item => {
      return addDirectory(item, 1);
    })
  );
  return files;
}

function useDnD(onDrop: (files: File[]) => void) {
  const dropAreaRef = useRef<HTMLDivElement>(null);
  const counter = useRef<number>(0);
  const [isDraggedIn, setIsDraggedIn] = useState<boolean>(false);

  const onDragEnter = useCallback((event: any) => {
    counter.current++;
    event.preventDefault();
    setIsDraggedIn(true);
  }, []);

  const onDragLeave = useCallback(() => {
    counter.current--;
    setIsDraggedIn(counter.current > 0);
  }, []);

  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
  }, []);

  const _onDrop = useCallback(
    async (event: DragEvent) => {
      event.preventDefault();

      counter.current = 0;
      setIsDraggedIn(false);

      const dataTransfer = event.dataTransfer;
      if (dataTransfer === null) {
        return;
      }
      if (dataTransfer.items) {
        onDrop(await findFiles(dataTransfer.items));
      } else {
        const files = Array.from(dataTransfer.files);
        onDrop(files);
      }
    },
    [onDrop]
  );

  useEffect(() => {
    const ref = dropAreaRef.current;
    if (!ref) {
      return;
    }
    ref.addEventListener("dragenter", onDragEnter);
    ref.addEventListener("dragleave", onDragLeave);
    ref.addEventListener("dragover", onDragOver);
    ref.addEventListener("drop", _onDrop);

    return () => {
      ref.removeEventListener("dragenter", onDragEnter);
      ref.removeEventListener("dragleave", onDragLeave);
      ref.removeEventListener("dragover", onDragOver);
      ref.removeEventListener("drop", _onDrop);
    };
  }, [onDrop, onDragEnter, onDragLeave, onDragOver, _onDrop]);

  return useMemo(
    () => ({
      dropAreaRef,
      isDraggedIn,
    }),
    [dropAreaRef, isDraggedIn]
  );
}

function EmptyStateUI() {
  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage id={"custom_model_editor.upload_image.empty"} />
          </Label>
        </div>
      </div>
    </div>
  );
}

export default function CustomModelEditorUploadPane() {
  const { localized } = useLocale();
  const { openUploadFileDialog, uploadCustomModelImages } =
    useCustomModelEditor();
  const { dropAreaRef } = useDnD(uploadCustomModelImages);
  const { hasPermissionToEditResource } = useTeamPermission();

  const values: { [key in string]: any } = {
    link: (
      <Link onClick={openUploadFileDialog}>
        {localized("custom_model_editor.upload_image.center.subtitle.link")}
      </Link>
    ),
  };

  return (
    <div className={styles["container"]}>
      {hasPermissionToEditResource ? (
        <div className={styles["dotted-box"]} ref={dropAreaRef}>
          <div className={styles["dotted-box-inner"]}>
            <div className={styles["desc"]}>
              <div className={styles["desc-title"]}>
                <FormattedMessage id="custom_model_editor.upload_image.desc.title" />
              </div>

              <div className={styles["desc-subtitle"]}>
                <FormattedMessage id="custom_model_editor.upload_image.desc.subtitle" />
              </div>
            </div>

            <div className={styles["dotted-box-center"]}>
              <div className={styles["center-title"]}>
                <FormattedMessage id="custom_model_editor.upload_image.center.title" />
              </div>

              <div className={styles["center-subtitle"]}>
                <FormattedMessage
                  id="custom_model_editor.upload_image.center.subtitle"
                  values={values}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyStateUI />
      )}
    </div>
  );
}
