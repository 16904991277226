import { ApiClientConstructor, _BaseApiClient } from "../base";
import { AuditLogApiClient, withAuditLogApi } from "./auditLog";
import { AuthApiClient, withAuthApi } from "./auth";
import { CustomModelApiClient, withCustomModelApi } from "./customModel";
import { FormApiClient, withFormApi } from "./form";
import { FormGroupApiClient, withFormGroupApi } from "./formGroup";
import { InvitationApiClient, withInvitationApi } from "./invitation";
import { PlanAPIClient, withPlanApi } from "./plan";
import { ReceiptGroupApiClient, withReceiptGroupApi } from "./receiptGroup";
import { RequestLogApiClient, withRequestLogApi } from "./requestLog";
import { ResourceOwnerApiClient, withResourceOwnerApi } from "./resourceOwner";
import { TeamApiClient, withTeamApi } from "./team";
import { TemplateApiClient, withTemplateApi } from "./template";
import { UserApiClient, withUserApi } from "./user";
import { WebhookApiClient, withWebhookApi } from "./webhook";

export function applyMixins(baseClass: ApiClientConstructor<_BaseApiClient>) {
  return withAuditLogApi(
    withCustomModelApi(
      withReceiptGroupApi(
        withRequestLogApi(
          withTemplateApi(
            withAuthApi(
              withUserApi(
                withResourceOwnerApi(
                  withTeamApi(
                    withFormGroupApi(
                      withPlanApi(
                        withFormApi(
                          withWebhookApi(withInvitationApi(baseClass))
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  );
}

export type ApiClientMixin = UserApiClient &
  ResourceOwnerApiClient &
  AuthApiClient &
  TemplateApiClient &
  RequestLogApiClient &
  CustomModelApiClient &
  PlanAPIClient &
  FormGroupApiClient &
  FormApiClient &
  ReceiptGroupApiClient &
  TeamApiClient &
  WebhookApiClient &
  InvitationApiClient &
  AuditLogApiClient;
