@import "../../styles/variables.scss";

.upload-file-field {
  margin-top: 12px;
  .file-section {
    display: flex;
    flex-direction: row;

    .file-name-container {
      position: relative;
      flex: 1 0 0px;
      border-bottom: 1px solid $text-field-border-color;
      height: 30px;
      padding-bottom: 4px;
      margin-right: 25px;

      > span {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.invisible-file-upload-input {
  display: none;
}

.use-existing-forms-checkbox {
  margin-top: 12px;
}

.use-existing-forms-desc {
  margin-top: 4px;
  padding-left: 28px;
  display: block;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
