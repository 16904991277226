export function getPreference(key: PreferenceKey): string | null {
  return window.localStorage.getItem(key);
}

export function setPreference(key: PreferenceKey, value: string) {
  window.localStorage.setItem(key, value);
}

export function removePreference(key: PreferenceKey) {
  window.localStorage.removeItem(key);
}

export enum PreferenceKey {
  lastSelectedScriptTab = "scriptEditor.lastSelectedTab",
  isScriptTabHidden = "scriptEditor.isScriptTabHidden",
  isUnsupportedBrowserDialogDismissed = "unsupportedBrowserDialog.isDismissed",
  authGearDefaultRefreshToken = "authgear_default_refreshToken",
  lastSelectedTeamLookupId = "formx.lastSelectedTeamLookupId",
}
