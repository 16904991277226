@import "../../styles/variables.scss";

$nav-bar-height: 96px;
$tabbar-height: 44px;
$pivot-max-width: 200px;
.container {
  padding-top: 4px;
  padding-left: 12px;
  background-color: $bgcolor;

  .breadcrumb-container {
    width: 100%;
    height: 36px;
    margin-bottom: 5px;

    .breadcrumb {
      margin-top: 0;
      float: left;
    }

    .rename-button {
      margin-left: 8px;
      float: left;
    }
  }

  .tab-bar {
    position: relative;

    :global(.ms-Pivot) {
      position: absolute;
      max-width: $pivot-max-width;
    }
    :global(.ms-CommandBar) {
      float: right;
      margin-left: $pivot-max-width;
    }
  }
}

.container-with-tabbar {
  height: $nav-bar-height;
}

.container-without-tabbar {
  height: calc(#{$nav-bar-height} - #{$tabbar-height});
}

.content {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$nav-bar-height});
}

.content-without-tabbar {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$nav-bar-height} + #{$tabbar-height});
}
