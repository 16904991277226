import {
  CommandButton,
  ContextualMenuItemType,
  DirectionalHint,
  IButtonProps,
  IButtonStyles,
  IContextualMenuItem,
  IContextualMenuProps,
  Label,
  TeachingBubble,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { AppConfig, HELP_PAGE_URL } from "../../config";
import { useTeamSelection } from "../../containers/Header";
import { useLocale } from "../../contexts/locale";
import { useLocalizeRegion } from "../../hooks/app";
import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import theme from "../../theme";
import { redirectToRegionForTeam } from "../../utils/region";
import { LinkWithTeamRef } from "../LinkWithTeamRef";
import styles from "./styles.module.scss";

interface Props {
  username: string;
  plan: string;
  isTeam: boolean;
  isAdmin: boolean;
  isOnAdminPortal: boolean;
  onLogout: () => void;
  onChangePassword: () => void;
  onCreateTeam: () => void;
  onToggleAdminPortal: () => void;
  enabledAuditLog: boolean;
  onOpenUserSetting: () => void;
  onUserInteractionItemClick: (name: string) => () => void;
  onClickManageTeam: () => void;
}

export interface LocationState {
  newUser: boolean;
}

function useTeamSelectionMenu(openCreateTeamModal: () => void) {
  const resourceOwnerId = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.resourceOwnerId
  );
  const userId = useSelector<RootState, string>(state =>
    state.user.currentUser !== undefined ? state.user.currentUser.id : ""
  );
  const { teams, onChangeResourceOwner, endPointTeamRefs } = useTeamSelection();
  const userHasResourceOwner = useSelector<RootState, boolean>(
    state => state.user.currentUser?.resourceOwner !== null
  );
  const { localized } = useLocale();
  const localizeRegion = useLocalizeRegion();

  const currentResourceOwnerName = useMemo(() => {
    if (resourceOwnerId === userId || !resourceOwnerId) {
      return localized("header.personal");
    }

    const currentTeam = teams.find(team => team.id === resourceOwnerId);

    return currentTeam ? currentTeam.name : "";
  }, [resourceOwnerId, userId, teams, localized]);

  const localRegionMenuItems: IContextualMenuItem[] = useMemo(
    () => [
      ...(userHasResourceOwner
        ? [
            {
              key: "personal",
              text: localized("header.personal"),
              iconProps:
                resourceOwnerId === userId || !resourceOwnerId
                  ? {
                      iconName: "Accept",
                    }
                  : {},
              onClick: () => onChangeResourceOwner(userId),
            },
          ]
        : []),
      ...teams.map(team => ({
        key: team.id,
        text: team.name,
        iconProps:
          resourceOwnerId === team.id
            ? {
                iconName: "Accept",
              }
            : {},
        onClick: () => onChangeResourceOwner(team.id),
      })),
    ],
    [
      localized,
      onChangeResourceOwner,
      resourceOwnerId,
      teams,
      userHasResourceOwner,
      userId,
    ]
  );

  const teamMenuProps: IContextualMenuProps = useMemo(
    () => ({
      items: [
        ...(Object.keys(endPointTeamRefs).length > 0
          ? [
              {
                key: "Header",
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                  title: localizeRegion(AppConfig.region),
                  items: localRegionMenuItems,
                },
              },
            ]
          : localRegionMenuItems),
        ...Object.keys(endPointTeamRefs).map(key => ({
          key: key,
          itemType: ContextualMenuItemType.Section,
          sectionProps: {
            title: localizeRegion(key),
            items: endPointTeamRefs[key].map(ref => ({
              key: ref.lookupId,
              text: ref.name,
              onClick: () => {
                redirectToRegionForTeam(
                  key,
                  ref.lookupId,
                  window.location.pathname.split("/")[1]
                );
              },
            })),
          },
        })),
        { key: "divider", itemType: ContextualMenuItemType.Divider },
        {
          key: "new-team",
          text: localized("header.new_team"),
          iconProps: {
            iconName: "AddTo",
          },
          onClick: () => {
            openCreateTeamModal();
          },
        },
      ],
      className: styles["team-menu"],
    }),
    [
      endPointTeamRefs,
      localized,
      localRegionMenuItems,
      openCreateTeamModal,
      localizeRegion,
    ]
  );

  return useMemo(
    () => ({
      teamMenuProps,
      currentResourceOwnerName,
    }),
    [teamMenuProps, currentResourceOwnerName]
  );
}

const Header = (props: Props) => {
  const {
    username,
    plan,
    isTeam,
    isAdmin,
    isOnAdminPortal,
    enabledAuditLog,
    onChangePassword,
    onLogout,
    onCreateTeam,
    onToggleAdminPortal,
    onOpenUserSetting,
    onUserInteractionItemClick,
    onClickManageTeam,
  } = props;
  const { localized } = useLocale();
  const location = useLocation<LocationState>();

  const [isTeachingBubbleVisible, setIsTeachingBubbleVisible] =
    useState<boolean>(!!(location.state && location.state.newUser));

  const { hasPermissionToViewMembership, hasPermissionToViewAuditLog } =
    useTeamPermission();

  const onInfoClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      setIsTeachingBubbleVisible(prevState => !prevState);
    },
    []
  );

  const onTeachingBubbleDismiss = useCallback((_e?: any) => {
    setIsTeachingBubbleVisible(false);
  }, []);

  const menuProps: IContextualMenuProps = useMemo(
    () => ({
      items: [
        ...(isTeam &&
        hasPermissionToViewMembership &&
        !AppConfig.hideTeamSelectionMenu
          ? [
              {
                key: "manage-team",
                text: localized("header.manage_team"),
                onClick: onClickManageTeam,
              },
            ]
          : []),
        {
          key: "usage",
          text: localized("header.usage"),
          onClick: onUserInteractionItemClick("/usage"),
        },
        ...(plan === "custom"
          ? [
              {
                key: "setting",
                text: localized("header.setting"),
                onClick: onUserInteractionItemClick("/setting"),
              },
            ]
          : []),
        ...(["basic", "free", "quota_plan"].includes(plan)
          ? [
              {
                key: "payment",
                text: localized("header.payment"),
                onClick: onUserInteractionItemClick("/payment"),
              },
            ]
          : []),
        ...(enabledAuditLog && hasPermissionToViewAuditLog
          ? [
              {
                key: "audit",
                text: localized("header.audit"),
                onClick: onUserInteractionItemClick("/audit-log"),
              },
            ]
          : []),
      ],
      className: styles["dropdown-menu"],
      alignTargetEdge: true,
    }),
    [
      isTeam,
      enabledAuditLog,
      hasPermissionToViewMembership,
      hasPermissionToViewAuditLog,
      localized,
      onClickManageTeam,
      onUserInteractionItemClick,
      plan,
    ]
  );

  const userMenuProps: IContextualMenuProps = useMemo(
    () => ({
      items: [
        AppConfig.authGear
          ? {
              key: "user-setting",
              text: localized("header.user_setting"),
              onClick: onOpenUserSetting,
            }
          : {
              key: "change-password",
              text: localized("header.change_password"),
              onClick: onChangePassword,
            },
        ...(isAdmin
          ? [
              {
                key: "admin",
                text: localized(
                  isOnAdminPortal ? "header.back_to_client" : "header.admin"
                ),
                onClick: onToggleAdminPortal,
              },
            ]
          : []),
        {
          key: "sign-out",
          text: localized("header.sign_out"),
          onClick: onLogout,
        },
      ],
      className: styles["dropdown-menu"],
    }),
    [
      localized,
      onChangePassword,
      onLogout,
      onOpenUserSetting,
      onToggleAdminPortal,
      isAdmin,
      isOnAdminPortal,
    ]
  );

  const { currentResourceOwnerName, teamMenuProps } =
    useTeamSelectionMenu(onCreateTeam);

  const teachingBubblePrimaryButtonProps: IButtonProps = useMemo(
    () => ({
      children: (
        <Label styles={{ root: { color: theme.palette.themePrimary } }}>
          <FormattedMessage id="teaching_bubble.lets_go" />
        </Label>
      ),
      href: HELP_PAGE_URL,
      target: "_blank",
      rel: "noopener noreferrer",
    }),
    []
  );

  const commandButtonStyle: IButtonStyles = {
    rootExpanded: {
      background: theme.palette.themeDarkAlt,
    },
    rootHovered: {
      background: theme.palette.themeDarkAlt,
    },
    rootPressed: {
      background: theme.palette.themeDark,
    },
  };
  const isAuthenticated = useSelector<RootState>(
    state => state.user.isAuthenticated
  );
  const isResourceOwnerExists = useSelector<RootState, boolean>(
    state => state.resourceOwner.resourceOwnerId !== undefined
  );

  const shouldShowTeamMenu =
    !AppConfig.hideTeamSelectionMenu &&
    isAuthenticated &&
    isResourceOwnerExists &&
    location.pathname !== "/invitation";

  return (
    <div
      className={styles["header"]}
      style={{ backgroundColor: theme.palette.themePrimary }}
    >
      {isAuthenticated ? (
        <LinkWithTeamRef to={`/`}>
          <div className={styles["logo"]}>
            <img src="/logo.svg" alt="" draggable={false} />
          </div>
        </LinkWithTeamRef>
      ) : (
        <a href="https://formx.ai" className={styles["logo"]}>
          <img src="/logo.svg" alt="" draggable={false} />
        </a>
      )}

      {shouldShowTeamMenu && currentResourceOwnerName && (
        <CommandButton
          className={styles["command-button"]}
          text={currentResourceOwnerName}
          menuProps={teamMenuProps}
          styles={commandButtonStyle}
        />
      )}
      <div className={styles["space"]} />

      {isAuthenticated && (
        <>
          {isResourceOwnerExists && (
            <CommandButton
              className={styles["command-button"]}
              iconProps={{ iconName: "Settings" }}
              menuProps={menuProps}
              menuIconProps={{ style: { display: "none" } }}
              styles={commandButtonStyle}
            />
          )}
          <CommandButton
            id="infoButton"
            className={classnames(styles["command-button"], {
              [styles["active-button"]]: isTeachingBubbleVisible,
            })}
            iconProps={{ iconName: "Help" }}
            onClick={onInfoClick}
            styles={commandButtonStyle}
          />
          <CommandButton
            className={classnames(
              styles["command-button"],
              styles["user-button"]
            )}
            text={username}
            menuProps={userMenuProps}
            menuIconProps={{ iconName: "Contact" }}
            styles={commandButtonStyle}
          />

          {isTeachingBubbleVisible && (
            <TeachingBubble
              target="#infoButton"
              primaryButtonProps={teachingBubblePrimaryButtonProps}
              hasCloseButton={true}
              headline={localized("teaching_bubble.title")}
              onDismiss={onTeachingBubbleDismiss}
              calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
            >
              <FormattedMessage id="teaching_bubble.description" />
            </TeachingBubble>
          )}
        </>
      )}
    </div>
  );
};

export default Header;
