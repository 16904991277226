import {
  IconButton,
  Pivot,
  PivotItem,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";
import { useHistory } from "react-router";

import { useCustomModelEditor } from "../../contexts/customModelEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import InfoNote from "../InfoNote";
import styles from "./styles.module.scss";

interface InfoFieldProps {
  value: string;
  error: string | null;
  onChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
  onRemove: () => void;
  onCommit: () => void;
  shouldShowDeleteButton: boolean;
}

function InfoField(props: InfoFieldProps) {
  const {
    value,
    onChange,
    onRemove,
    onCommit,
    shouldShowDeleteButton: removeVisible,
    error,
  } = props;
  const { localized } = useLocale();
  const { hasPermissionToEditResource } = useTeamPermission();

  const onBlur = React.useCallback(() => {
    if (value.length > 0) {
      onCommit();
    }
  }, [value, onCommit]);

  const onKeyPress = React.useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Enter" && event.target instanceof HTMLElement) {
      event.target.blur();
    }
  }, []);

  const errorMessage = error ? localized(error) : undefined;

  return (
    <div className={styles["label-field"]}>
      <TextField
        className={styles["text-field"]}
        placeholder={localized(
          "custom_model_editor.right_bar.label.placeholder"
        )}
        errorMessage={errorMessage}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        disabled={!hasPermissionToEditResource}
      />
      {removeVisible && (
        <IconButton iconProps={{ iconName: "Delete" }} onClick={onRemove} />
      )}
    </div>
  );
}

interface FrozenFieldProps {
  value: string;
}

function FrozenField(props: FrozenFieldProps) {
  return <div className={styles["frozen-field"]}>{props.value}</div>;
}

const LabelsPane = React.memo(() => {
  const { localized } = useLocale();
  const {
    customModel,
    appendInfoField,
    onInfoFieldChange,
    onInfoFieldRemove,
    onInfoFieldChangeCommit,
    infoFieldErrors,
  } = useCustomModelEditor();
  const { hasPermissionToEditResource } = useTeamPermission();

  const shouldShowRemoveButton =
    customModel.config.fields.length + customModel.config.frozenFields.length >
    1;

  return (
    <>
      <div className={styles["desc"]}>
        <InfoNote notes={["custom_model_editor.right_bar.label.desc"]} />
      </div>
      <div className={styles["subtitle"]}>
        {hasPermissionToEditResource ? (
          <FormattedMessage id="custom_model_editor.right_bar.label.subtitle" />
        ) : (
          <FormattedMessage id="custom_model_editor.right_bar.label.subtitle.readonly" />
        )}
      </div>

      {hasPermissionToEditResource && (
        <PrimaryButton
          iconProps={{ iconName: "add" }}
          className={styles["add-new-info-button"]}
          onClick={appendInfoField}
        >
          {localized("custom_model_editor.right_bar.add_new_info_button")}
        </PrimaryButton>
      )}

      {customModel.config.fields.map((value, index) => (
        <InfoField
          value={value}
          key={`label-field-${index}`}
          error={infoFieldErrors === null ? null : infoFieldErrors[index]}
          onChange={onInfoFieldChange(index)}
          onRemove={onInfoFieldRemove(index)}
          onCommit={onInfoFieldChangeCommit}
          shouldShowDeleteButton={shouldShowRemoveButton}
        />
      ))}
      {customModel.config.frozenFields.map((value, index) => (
        <FrozenField value={value} key={`frozen-field-${index}`} />
      ))}
    </>
  );
});

const CustomModelEditorRightBarV2 = React.memo(() => {
  const { localized } = useLocale();
  const { customModel, freezeFieldsForLabelling } = useCustomModelEditor();
  const history = useHistory();
  const { hasPermissionToEditResource } = useTeamPermission();

  const customModelId = customModel.id;

  const onNextButtonClicked = useCallback(async () => {
    if ((await freezeFieldsForLabelling()) === true) {
      history.push(`/custom-model/${customModelId}/label`);
    }
  }, [freezeFieldsForLabelling, history, customModelId]);

  const hasFieldToFreeze = customModel.config.fields.length > 0;

  return (
    <div className={styles["container"]}>
      <Pivot className={styles["content"]} linkFormat={"tabs"}>
        <PivotItem
          itemKey="label"
          headerText={localized("custom_model_editor.right_bar.tab.labels")}
        >
          <LabelsPane />
        </PivotItem>
      </Pivot>
      {hasPermissionToEditResource && (
        <div className={styles["bottom"]}>
          <PrimaryButton
            className={styles["next-step-button"]}
            onClick={onNextButtonClicked}
            disabled={!hasFieldToFreeze}
          >
            <FormattedMessage id="custom_model_editor.right_bar.next_step" />
          </PrimaryButton>
        </div>
      )}
    </div>
  );
});

export default CustomModelEditorRightBarV2;
