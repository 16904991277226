import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { useCustomModelActionCreator } from "../../actions/customModel";
import { DefaultCustomModel } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { useToast } from "../../hooks/toast";
import styles from "./styles.module.scss";

interface ContentProps {
  onClose(): void;
}

const Content = (props: ContentProps) => {
  const { onClose } = props;

  const { localized } = useLocale();

  const [name, setName] = useState("");
  const confirmEnabled = name.trim() !== "";

  const { push } = useHistory();
  const toast = useToast();
  const { createCustomModel } = useCustomModelActionCreator();

  const createCustomModelThenOpenLink = useCallback(
    (name: string) => {
      const newCustomModel = { ...DefaultCustomModel };
      newCustomModel.name = name;

      createCustomModel(newCustomModel, "")
        .then(createdCustomModel => {
          toast.success("custom_model_editor.custom_model_is_created");
          push(`/custom-model/${createdCustomModel.id}/setup`);
        })
        .catch(() => {
          toast.error("error.custom_model.fail_to_create_custom_model");
        });
    },
    [push, createCustomModel, toast]
  );

  const onNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value) {
        setName(value as string);
      } else {
        setName("");
      }
    },
    []
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();
      createCustomModelThenOpenLink(name);
      onClose();
    },
    [onClose, name, createCustomModelThenOpenLink]
  );

  return (
    <form onSubmit={onSubmit}>
      <TextField
        label={localized("create_custom_model_dialog.textfield.label")}
        onChange={onNameChange}
      />

      <DialogFooter>
        <DefaultButton onClick={onClose} text={localized("common.cancel")} />
        <PrimaryButton
          type="submit"
          text={localized("common.confirm")}
          disabled={!confirmEnabled}
        />
      </DialogFooter>
    </form>
  );
};

interface Props extends ContentProps {
  isOpen: boolean;
}

const CreateCustomModelModal = (props: Props) => {
  const { onClose, isOpen } = props;

  const { localized } = useLocale();

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("create_custom_model_dialog.title"),
      titleProps: {
        className: styles["create-custom-model-title"],
      },
    }),
    [localized]
  );

  return (
    <Dialog
      minWidth={405}
      hidden={!isOpen}
      onDismiss={onClose}
      dialogContentProps={dialogContentProps}
    >
      <Content onClose={onClose} />
    </Dialog>
  );
};

export default CreateCustomModelModal;
