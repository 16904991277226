import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";
import { useSelector } from "react-redux";

import { useFetchTeamMembers } from "../../hooks/admin";
import { RootState } from "../../redux/types";
import {
  TeamMembersAndInvitations,
  getTeamRoleMessageId,
} from "../../types/team";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import { DisplayTeamUser } from "../TeamPermissionControl";
import styles from "./styles.module.scss";

interface Props {
  teamId: string;
  region: string;
}

interface TeamTableRowProps {
  user: DisplayTeamUser;
}

function TeamTableRow(props: TeamTableRowProps) {
  const { user } = props;
  const userEmail = useSelector<RootState, string | undefined>(
    state => state.user.currentUser?.email
  );
  const isInvitation = user.userId === undefined;

  return (
    <tr className={styles["team-table-row"]}>
      <td>
        {userEmail === user.email ? (
          <FormattedMessage
            id={"team.invitation.email.oneself"}
            values={{ email: user.email }}
          />
        ) : (
          user.email
        )}
      </td>
      <td>
        {isInvitation ? (
          <FormattedMessage id={"team.invitation.pending"} />
        ) : (
          <FormattedMessage id={"team.invitation.accepted"} />
        )}
      </td>
      <td>
        <FormattedMessage id={getTeamRoleMessageId(user.permission)} />
      </td>
    </tr>
  );
}

interface ReduxProps {
  membersAndInvitations?: TeamMembersAndInvitations;
  isFetching: boolean;
}

function useRedux(): ReduxProps {
  const { membersAndInvitations, isFetching } = useSelector(
    (state: RootState) => {
      const { membersAndInvitations, isFetching } =
        state.admin.teamDetail.membersState;

      return {
        membersAndInvitations,
        isFetching,
      };
    }
  );

  return { membersAndInvitations, isFetching };
}

function _TeamDetailMemberList(props: Props) {
  const { teamId, region } = props;
  const { membersAndInvitations, isFetching } = useRedux();

  useFetchTeamMembers(teamId, region);

  const displayUsers = React.useMemo(() => {
    if (membersAndInvitations === undefined) {
      return [];
    }
    const displayUsers = membersAndInvitations.members.map(user => {
      return {
        email: user.email,
        userId: user.userId,
        permission: user.permission,
      };
    });

    const displayInvitations = membersAndInvitations.invitations.map(
      invitation => {
        return {
          email: invitation.email,
          invitationId: invitation.id,
          permission: invitation.permission,
        };
      }
    );

    return [...displayUsers, ...displayInvitations];
  }, [membersAndInvitations]);

  return (
    <>
      {isFetching && <ShortSpinner key={0} />}
      {!isFetching && (
        <Table
          columnIds={["common.email", "team.column.status", "team.column.role"]}
          className={styles["team-table"]}
        >
          {displayUsers.map(user => (
            <TeamTableRow user={user} key={user.email} />
          ))}
        </Table>
      )}
    </>
  );
}

export const TeamDetailMemberList = React.memo(_TeamDetailMemberList);
export default TeamDetailMemberList;
