import { Icon, Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";

import { listFormGroups } from "../../actions/formGroup";
import { useTeamPermission } from "../../hooks/permission";
import { RootState, ThunkActionCreatorType } from "../../redux/types";
import { BriefFormGroup } from "../../types/formGroup";
import FormGroupGridItem from "../FormGroupGridItem";
import ShortSpinner from "../ShortSpinner";
import styles from "./styles.module.scss";

interface Props {
  formGroups: BriefFormGroup[];
  hasMore: boolean;
  isEmpty: boolean;

  listFormGroup: ThunkActionCreatorType<typeof listFormGroups>;
  onDeleteFormGroup(formGroupId: string): void;
}

function FormGroupEmpty() {
  const { hasPermissionToCreateResource } = useTeamPermission();
  const isTeam = useSelector<RootState, boolean>(
    (state: RootState) => state.resourceOwner.isTeam
  );

  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        {hasPermissionToCreateResource && <Icon iconName="PageAdd" />}
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage
              id={
                isTeam
                  ? "form_group_grid.team_no_form_yet"
                  : "form_group_grid.no_form_yet"
              }
            />
          </Label>
          {hasPermissionToCreateResource && (
            <Label>
              <FormattedMessage id="form_group_grid.empty_state_tips" />
            </Label>
          )}
        </div>
      </div>
    </div>
  );
}

export const FormGroupGrid = React.memo((props: Props) => {
  const { listFormGroup, formGroups, isEmpty, hasMore, onDeleteFormGroup } =
    props;

  const loadMore = React.useCallback(() => {
    return listFormGroup();
  }, [listFormGroup]);
  const resourceOwnerId = useSelector((state: RootState) => {
    return state.resourceOwner.resourceOwnerId;
  });

  return (
    <div className={styles["form-group-grid"]}>
      {isEmpty ? (
        <FormGroupEmpty />
      ) : (
        <InfiniteScroll
          className={styles["form-group-list"]}
          loadMore={loadMore}
          hasMore={hasMore}
          key={resourceOwnerId}
          loader={<ShortSpinner key={0} />}
          useWindow={false}
        >
          {formGroups.map((formGroup, i) => (
            <FormGroupGridItem
              onDeleteFormGroup={onDeleteFormGroup}
              formGroup={formGroup}
              key={i}
            />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
});
