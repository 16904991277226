import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useCustomModelActionCreator } from "../actions/customModel";
import ConfirmModal from "../components/ConfirmModal";
import CreateCustomModelModal from "../components/CreateCustomModelModal";
import CustomModelGrid from "../components/CustomModelGrid";
import { Layout, Main, Top } from "../components/Layout";
import HeaderContainer from "../containers/Header";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";

function useCreateCustomModel() {
  const [isCreateCustomModelModalOpened, setIsCreateCustomModelModalOpened] =
    useState<boolean>(false);

  const onCreate = useCallback(() => {
    setIsCreateCustomModelModalOpened(true);
  }, []);

  const onCancelCreate = useCallback(() => {
    setIsCreateCustomModelModalOpened(false);
  }, []);

  return useMemo(
    () => ({ isCreateCustomModelModalOpened, onCreate, onCancelCreate }),
    [isCreateCustomModelModalOpened, onCreate, onCancelCreate]
  );
}

function useDeleteCustomModel() {
  const toast = useToast();
  const { deleteCustomModel } = useCustomModelActionCreator();

  const [isDeleteConfirmationModalOpened, setIsDeleteConfirmationModalOpened] =
    useState<boolean>(false);

  const [customModelIdToDelete, setCustomModelIdToDelete] = useState<
    string | null
  >(null);

  const onConfirmDelete = useCallback(() => {
    setIsDeleteConfirmationModalOpened(false);

    if (customModelIdToDelete == null) {
      return;
    }

    deleteCustomModel(customModelIdToDelete)
      .then(() => {
        toast.success("custom_model_grid.custom_model_is_deleted");
      })
      .catch(() => {
        toast.error("error.custom_model.fail_to_delete_custom_model");
      });
  }, [customModelIdToDelete, toast, deleteCustomModel]);

  const onCancelDelete = useCallback(() => {
    setIsDeleteConfirmationModalOpened(false);
    setCustomModelIdToDelete(null);
  }, []);

  const onDelete = useCallback((customModelId: string) => {
    setIsDeleteConfirmationModalOpened(true);
    setCustomModelIdToDelete(customModelId);
  }, []);

  return useMemo(
    () => ({
      isDeleteConfirmationModalOpened,
      onDelete,
      onConfirmDelete,
      onCancelDelete,
    }),
    [isDeleteConfirmationModalOpened, onDelete, onConfirmDelete, onCancelDelete]
  );
}

export function useFetchCustomModels() {
  const toast = useToast();
  const { getCustomModels } = useCustomModelActionCreator();

  const customModels = useSelector(
    (state: RootState) => state.customModel.customModels
  );
  const resourceOwnerId = useSelector<RootState, string | undefined>(
    (state: RootState) => state.resourceOwner.resourceOwnerId
  );

  const loadMore = useCallback(
    (page: number) => {
      getCustomModels({ refresh: page === 0 }).catch(() => {
        toast.error("error.custom_model.fail_to_get_custom_models");
      });
    },
    [toast, getCustomModels]
  );

  const pageInfo = useSelector(
    (state: RootState) => state.customModel.pageInfo
  );

  const hasMore = pageInfo ? pageInfo.hasNext : true;
  const isEmpty = !!(
    pageInfo &&
    !pageInfo.hasNext &&
    customModels.length === 0
  );

  return useMemo(
    () => ({
      customModels,
      hasMore,
      loadMore,
      isEmpty,
      resourceOwnerId,
    }),
    [customModels, hasMore, loadMore, isEmpty, resourceOwnerId]
  );
}

function _CustomModelGridContainer() {
  const { isCreateCustomModelModalOpened, onCreate, onCancelCreate } =
    useCreateCustomModel();

  const {
    isDeleteConfirmationModalOpened,
    onDelete,
    onCancelDelete,
    onConfirmDelete,
  } = useDeleteCustomModel();

  const { customModels, hasMore, loadMore, isEmpty, resourceOwnerId } =
    useFetchCustomModels();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <CustomModelGrid
          hasMore={hasMore}
          isEmpty={isEmpty}
          loadMore={loadMore}
          resourceOwnerId={resourceOwnerId}
          customModels={customModels}
          onCreateCustomModel={onCreate}
          onDeleteCustomModel={onDelete}
        />
      </Main>
      <CreateCustomModelModal
        isOpen={isCreateCustomModelModalOpened}
        onClose={onCancelCreate}
      />
      <ConfirmModal
        isOpen={isDeleteConfirmationModalOpened}
        modalType={ConfirmModalType.Destory}
        titleId="custom_model_grid.delete_custom_model"
        messageId="custom_model_grid.are_you_sure_to_delete_form_group"
        actionId="custom_model_grid.delete"
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
      />
    </Layout>
  );
}

export const CustomModelGridContainer = React.memo(_CustomModelGridContainer);
export default CustomModelGridContainer;
