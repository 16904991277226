import { useEffect, useRef, useState } from "react";

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useDelayFirstTransition<T>(
  value: T,
  minDelayInMilliseconds: number = 300
): T {
  const initialValueRef = useRef<T>(value);
  const initialTimestamp = useRef(new Date());
  const [output, setOutput] = useState<T>(value);
  useEffect(() => {
    if (value !== initialValueRef.current) {
      const delta = new Date().getTime() - initialTimestamp.current.getTime();
      if (delta >= minDelayInMilliseconds) {
        setOutput(value);
      } else {
        setTimeout(() => {
          setOutput(value);
        }, minDelayInMilliseconds - delta);
      }
    }
  }, [value, minDelayInMilliseconds]);
  return output;
}
