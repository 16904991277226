import { Icon, Label, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";

import { KeyValue } from "../../types/keyValue";
import { KeyValueMapper } from "../../types/mappers/keyValue";
import { CustomField, ReceiptGroup } from "../../types/receiptGroup";
import { ExternalServicesAlertBoxForReceipt } from "../ExternalServiceAlertBox";
import { KeyValueCard } from "../KeyValueCard";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface Props {
  receiptGroup: ReceiptGroup;
  onRenameReceiptGroup: () => void;
  onAddToken: () => void;
  onDeleteToken: (tokenValue: string) => void;
  onAddCustomField: () => void;
  onEditCustomField: (customField: CustomField) => void;
  onDeleteCustomField: (fieldName: string) => void;
}

export default class ReceiptGroupEditor extends React.PureComponent<
  Props,
  unknown
> {
  onDeleteTokenClick = (token: string) => () => {
    this.props.onDeleteToken(token);
  };

  _onEditCustomField = (keyValue: KeyValue) => {
    this.props.onEditCustomField(KeyValueMapper.toCustomField(keyValue));
  };

  private renderNameSection() {
    const { receiptGroup, onRenameReceiptGroup } = this.props;
    return (
      <>
        <h1>
          <FormattedMessage id="receipt_group_editor.name" />
        </h1>
        <div className={styles["section"]}>
          <Label className={styles["group-name"]}>{receiptGroup.name}</Label>
          <DefaultButton
            textId="receipt_group_editor.rename"
            onClick={onRenameReceiptGroup}
          />
        </div>
      </>
    );
  }

  private renderTokensSection() {
    const { receiptGroup, onAddToken } = this.props;
    return (
      <>
        <h1>
          <FormattedMessage id="receipt_group_editor.tokens" />
        </h1>
        <div className={styles["section"]}>
          <Text className={styles["description"]}>
            <FormattedMessage id="receipt_group_editor.tokens_description_1" />
          </Text>
          <br />
          <Text className={styles["description"]}>
            <FormattedMessage id="receipt_group_editor.tokens_description_2" />
          </Text>

          <div className={styles["tag-list"]}>
            {receiptGroup.tokens.map((token, i) => (
              <div
                key={i}
                className={classnames(
                  styles["text-token-card"],
                  styles["token-card"]
                )}
              >
                <Text>{token}</Text>
                <Icon
                  className={styles["delete-button"]}
                  onClick={this.onDeleteTokenClick(token)}
                  iconName="StatusCircleErrorX"
                />
              </div>
            ))}
            {receiptGroup.tokens.length === 0 && (
              <div className={styles["empty-state"]}>
                <FormattedMessage id="receipt_group_editor.no_token" />
              </div>
            )}
          </div>

          <DefaultButton
            textId="receipt_group_editor.add_token"
            onClick={onAddToken}
          />
        </div>
      </>
    );
  }

  private renderCustomFieldSection() {
    const { receiptGroup, onAddCustomField, onDeleteCustomField } = this.props;
    return (
      <>
        <h1>
          <FormattedMessage id="receipt_group_editor.custom_fields" />
        </h1>
        <div className={styles["section"]}>
          <Text className={styles["description"]}>
            <FormattedMessage id="receipt_group_editor.custom_fields_description_1" />
          </Text>
          <br />
          <Text className={styles["description"]}>
            <FormattedMessage id="receipt_group_editor.custom_fields_description_2" />
          </Text>
          <div className={styles["custom-field-list"]}>
            {receiptGroup.custom_fields.map((customField, i) => (
              <KeyValueCard
                className={styles["custom-field-item"]}
                key={i}
                keyValue={KeyValueMapper.fromCustomField(customField)}
                onDelete={onDeleteCustomField}
                onEdit={this._onEditCustomField}
              />
            ))}
            {receiptGroup.custom_fields.length === 0 && (
              <div className={styles["empty-state"]}>
                <FormattedMessage id="receipt_group_editor.no_custom_field" />
              </div>
            )}
          </div>
          <DefaultButton
            textId="receipt_group_editor.add_custom_field"
            onClick={onAddCustomField}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className={styles["receipt-group-editor"]}>
        <ExternalServicesAlertBoxForReceipt />
        {this.renderNameSection()}
        {this.renderTokensSection()}
        {this.renderCustomFieldSection()}
      </div>
    );
  }
}
