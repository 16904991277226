import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import { FormGroupType } from "../../types/formGroup";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onCancel(): void;
  onCreate(name: string, type: FormGroupType): void;
}

interface FormGroupTypeOption extends IChoiceGroupOption {
  key: FormGroupType;
}

const CreateFormGroupModal = (props: Props) => {
  const { onCreate, onCancel, isOpen } = props;

  const { localized } = useLocale();

  const formGroupTypeOptions = React.useMemo(
    (): FormGroupTypeOption[] => [
      {
        key: "anchor",
        text: localized("create_form_group.form_group_mode.anchor"),
      },
      {
        key: "token",
        text: localized("create_form_group.form_group_mode.token"),
      },
    ],
    [localized]
  );

  const [formGroupName, setFormGroupName] = useState<string>("");
  const [formGroupType, setFormGroupType] = useState<FormGroupType>("anchor");
  const [isFormGroupNameFieldValid, setIsFormGroupNameFieldValid] =
    useState<boolean>(true);

  const onFormGroupNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setFormGroupName(value);
        setIsFormGroupNameFieldValid(true);
      }
    },
    []
  );

  const onFormGroupTypeChange = useCallback(
    (
      _?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option !== undefined) {
        setFormGroupType((option as FormGroupTypeOption).key);
      }
    },
    []
  );

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (!isOpen) return;
      event.preventDefault();
      event.stopPropagation();

      const name = formGroupName.trim();
      if (!name) {
        setIsFormGroupNameFieldValid(false);
        return;
      }

      onCreate(name, formGroupType);
    },
    [isOpen, formGroupName, onCreate, formGroupType]
  );

  const onDismissed = useCallback(() => {
    setFormGroupName("");
    setFormGroupType("anchor");
    setIsFormGroupNameFieldValid(true);
  }, []);

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("create_form_group.title"),
    }),
    [localized]
  );

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  return (
    <Dialog
      minWidth={400}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={onSubmit}>
        <TextField
          label={localized("create_form_group.form_group_name")}
          onChange={onFormGroupNameChange}
          value={formGroupName}
          errorMessage={
            isFormGroupNameFieldValid
              ? undefined
              : localized("error.create_form_group.empty_name")
          }
        />
        <ChoiceGroup
          className={styles["form-group-mode-choice-group"]}
          label={localized("create_form_group.form_group_mode")}
          options={formGroupTypeOptions}
          selectedKey={formGroupType}
          onChange={onFormGroupTypeChange}
        />
        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
          <PrimaryButton
            type="submit"
            text={localized("create_form_group.create")}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export default CreateFormGroupModal;
