import { UserInfo } from "@authgear/web";
import * as yup from "yup";

import errors, { ApiError, ApiErrorMap } from "../errors";
import { UploadAssetResponse } from "../models";
import { LambdaSuccess } from "../redux/types";

export type Error = {
  code: number;
  message?: string;
};

export interface BaseApiClient {
  initialize: () => Promise<ApiClientInitializeResult>;
  getEffectiveEndpoint: (url: string, region?: string) => string;
  fetch: (resource: RequestInfo, init?: RequestInit) => Promise<Response>;
  lambdaRaw: <T extends LambdaSuccess>(action: string, args: any) => Promise<T>;
  lambda: <T>(action: string, args: any, schema?: yup.Schema<T>) => Promise<T>;
  createAsset: (file: File) => Promise<UploadAssetResponse>;
}

export interface CreateAssetResponse extends LambdaSuccess {
  upload: {
    url: string;
    file_field: string;
    asset_id: string;
    data: { [key: string]: string };
  };
  signed_url: string;
}

export interface ApiClientInitializeResult {
  isAuthenticated: boolean;
  invitationCode?: string;
  userInfo?: UserInfo;
}

export class _BaseApiClient {
  endpoint: string;
  regionalEndpoints: { [Key: string]: string };

  constructor(endpoint: string, regionalEndpoints: { [Key: string]: string }) {
    this.endpoint = endpoint;
    this.regionalEndpoints = regionalEndpoints;
  }

  fetch(_resource: RequestInfo, _init?: RequestInit): Promise<Response> {
    throw "Not Implemented";
  }

  initialize(): Promise<ApiClientInitializeResult> {
    throw "Not Implemented";
  }

  getEffectiveEndpoint(url: string, region?: string): string {
    if (url.match(/^https?:\/\//)) {
      return url;
    }
    if (region) {
      return new URL(url, this.regionalEndpoints[region]).href;
    } else {
      return new URL(url, this.endpoint).href;
    }
  }

  actionToUrl(action: string, region?: string): string {
    const [group, op] = action.split(":");
    return this.getEffectiveEndpoint(`${group}/${op}`, region);
  }

  async lambdaRaw<T extends LambdaSuccess>(
    action: string,
    args: any,
    options?: { region: string }
  ): Promise<T> {
    const { result } = await this.fetch(
      this.actionToUrl(action, options?.region),
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(args),
      }
    ).then(x => x.json());

    if (result.status === "ok") {
      return result as T;
    } else {
      throw result.error;
    }
  }

  async lambda<T>(
    action: string,
    args: any,
    schema?: yup.Schema<T>,
    selectedKey?: string | null,
    options?: { region: string }
  ): Promise<T> {
    try {
      const { status, ...rest } = await this.lambdaRaw<T & LambdaSuccess>(
        action,
        args,
        options
      );

      let result: any = rest;
      const keys = Object.keys(rest);
      if (keys.length === 1 && selectedKey === undefined) {
        result = result[keys[0]];
      } else if (
        selectedKey !== undefined &&
        selectedKey !== null &&
        selectedKey in keys
      ) {
        result = result[selectedKey];
      }

      try {
        return schema
          ? await schema.validate(result)
          : (result as unknown as T);
      } catch (e) {
        console.error(e);
        throw errors.UnexpectedServerResponseError;
      }
    } catch (e: any) {
      const error =
        e.code && e.code in ApiErrorMap
          ? ApiErrorMap[e.code as ApiError]
          : errors.UnknownError;

      throw error;
    }
  }

  async createAsset(file: File): Promise<UploadAssetResponse> {
    const result = await this.lambdaRaw<CreateAssetResponse>("asset:create", {
      filename: file.name,
      size: file.size,
      content_type: file.type,
    });

    const formData = new FormData();
    for (const [key, value] of Object.entries(result.upload.data)) {
      formData.append(key, value);
    }
    formData.append(result.upload.file_field, file);

    await fetch(result.upload.url, {
      method: "POST",
      body: formData,
    });

    return {
      url: result.signed_url,
      name: result.upload.asset_id,
    };
  }

  injectOptionalFields<A, B extends { [key: string]: any }>(
    a: A,
    b: B
  ): A & Partial<B> {
    const result: A & Partial<B> = {
      ...a,
    };
    Object.keys(b).forEach((k: string) => {
      if (b[k] !== undefined) {
        (result as any)[k] = b[k];
      }
    });
    return result;
  }
}

export type ApiClientConstructor<T extends _BaseApiClient> = new (
  ...args: any[]
) => T;
