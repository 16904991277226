.batch-note {
  height: 100%;
  overflow: auto;
  padding: 0 10px;
  padding-bottom: 60px;

  section {
    margin: 20px;
    margin-top: 0;
    font-size: 14px;

    h1 {
      padding: 10px 0;
      font-size: 18px;
      margin: 10px 0;
      border-bottom: 1px solid #ccc;
    }

    > div {
      padding: 0 10px;
    }
  }

  ol,
  ul {
    margin: 10px 20px;
    li {
      width: 80%;
      margin: 4px;
      line-height: 1.6em;
    }
  }

  .extra-info {
    font-size: 12px;
  }

  .download-buttons {
    :global(.ms-Image) {
      display: inline-block;
      margin: 4px 8px;
      cursor: pointer;
    }
  }
}
