import * as yup from "yup";

import { briefFormSchema } from "./form";
import { FormGroupConfig } from "./formGroupConfig";
import { pageInfoSchema, pageInfoWithOffsetSchema } from "./pageInfo";
import {
  TokenGroupImageResp,
  TokenGroupTextResp,
  tokenGroupImageSchema,
  tokenGroupTextSchema,
} from "./tokenGroup";

const formGroupType = yup.string().oneOf(["anchor", "token"]).defined();
const formGroupTokenGroupMatchMode = yup
  .string()
  .oneOf(["all", "any"])
  .defined();

export const briefFormGroupSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    type: formGroupType.required(),
    forms: yup.array(briefFormSchema).defined(),
    isTemplate: yup.boolean().required(),
    updatedAt: yup.string().defined(),
  })
  .camelCase();

export const paginatedBriefFormGroupSchema = yup
  .object({
    pageInfo: pageInfoSchema.required(),
    formGroups: yup.array(briefFormGroupSchema).required(),
  })
  .camelCase();

const formGroupTokenGroupBaseSchema = yup
  .object({
    id: yup.string().defined(),
    formId: yup.string().defined(),
    order: yup.number().defined(),
    matchMode: formGroupTokenGroupMatchMode.required(),
    texts: yup.array(tokenGroupTextSchema).defined(),
    images: yup.array(tokenGroupImageSchema).defined(),
  })
  .camelCase();

const formGroupTokenGroupSchema = formGroupTokenGroupBaseSchema
  .shape({
    form: briefFormSchema.required(),
  })
  .camelCase();

const formGroupAnchorBaseSchema = yup
  .object({
    id: yup.string().defined(),
    formId: yup.string().defined(),
  })
  .camelCase();

export const formGroupAnchorSchema = formGroupAnchorBaseSchema.shape({
  form: briefFormSchema,
});

export const detailFormGroupSchema = yup
  .object({
    config: yup.mixed((_input): _input is FormGroupConfig => true).defined(),
    id: yup.string().defined(),
    name: yup.string().defined(),
    type: formGroupType.required(),
    isTemplate: yup.boolean().required(),
    tokenGroups: yup.array(formGroupTokenGroupSchema).defined(),
    anchors: yup.array(formGroupAnchorSchema).defined(),
    updatedAt: yup.string().defined(),
    resourceOwnerId: yup.string().nullable(),
  })
  .camelCase();

export type FormGroupType = yup.InferType<typeof formGroupType>;
export type FormGroupTokenGroupMatchMode = yup.InferType<
  typeof formGroupTokenGroupMatchMode
>;

export type BriefFormGroup = yup.InferType<typeof briefFormGroupSchema>;
export type FormGroupTokenGroupBase = yup.InferType<
  typeof formGroupTokenGroupBaseSchema
>;

export type FormGroupTokenGroup = yup.InferType<
  typeof formGroupTokenGroupSchema
>;

export type FormGroupAnchorBase = yup.InferType<
  typeof formGroupAnchorBaseSchema
>;

export type FormGroupAnchor = yup.InferType<typeof formGroupAnchorSchema>;

export type DetailFormGroup = yup.InferType<typeof detailFormGroupSchema>;

export type PaginatedBriefFormGroup = yup.InferType<
  typeof paginatedBriefFormGroupSchema
>;

export interface FormGroupTokenGroupRespBase {
  id: string;
  form_id: string;
  order: number;
  match_mode: FormGroupTokenGroupMatchMode;
  texts: TokenGroupTextResp[];
  images: TokenGroupImageResp[];
}

export interface FormGroupAnchorRespBase {
  id: string;
  form_id: string;
}

export const paginatedWithOffsetBriefFormGroupSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    formGroups: yup.array(briefFormGroupSchema).required(),
  })
  .camelCase();

export type PaginatedWithOffsetBriefFormGroup = yup.InferType<
  typeof paginatedWithOffsetBriefFormGroupSchema
>;
