@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dotted-box {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 2px dashed $darker-primary-color;
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 1140px;
  width: 80%;
  height: 40vh;
  max-height: 426px;
}

.dotted-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.dotted-box-center {
  top: 50%;
  position: absolute;
  transform: translate(0, -50%);
  width: 100%;
}

.desc {
  position: absolute;
  bottom: calc(100% + 26px);
}

.desc-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}

.desc-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #605e5c;
}

.center-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.center-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #605e5c;
  text-align: center;
}

.empty-state-container {
  display: flex;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;

  .empty-state {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;

    @include no-select();

    i {
      font-size: 60px;
      margin-right: 12px;
    }

    .empty-state-text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      label {
        margin-top: 0;
        font-weight: 500;
      }
    }
  }
}
