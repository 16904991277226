import * as yup from "yup";

export type BoundingBox = yup.InferType<typeof boundingBoxSchema>;

export const boundingBoxSchema = yup.object({
  top: yup.number().defined(),
  left: yup.number().defined(),
  right: yup.number().defined(),
  bottom: yup.number().defined(),
});
