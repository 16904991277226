import { StripeCardElement } from "@stripe/stripe-js";

import { apiClient } from "../apiClient";
import errors from "../errors";
import { useThunkDispatch } from "../hooks/thunk";
import { Dispatch, RootState } from "../redux/types";
import { ResourceOwnerSetting } from "../types/resourceOwner";
import { PaymentMethod, UserSetting } from "../types/user";
import { SubscriptionData, User } from "../types/user";
import { getPluginStripeError } from "../utils/errors";
import { URLParamsKey, setParam } from "../utils/params";
import { getStripe } from "../utils/stripe";
import { handleConflict } from "./app";

export interface PlanUpdatedAction {
  readonly type: "PlanUpdated";
  readonly plan: string;
  readonly planId: string | null;
  readonly teamId?: string;
}

export interface IsPaymentRequiredUpdatedAction {
  readonly type: "IsPaymentRequiredUpdated";
  readonly isPaymentRequired: boolean;
}

export interface PaymentMethodUpdatedAction {
  readonly type: "PaymentMethodUpdated";
  readonly payload: PaymentMethod;
  readonly isTeam?: boolean;
}

export interface BillingEmailUpdatedAction {
  readonly type: "BillingEmailUpdated";
  readonly email: string;
}

export interface SettingUpdatedAction {
  readonly type: "SettingUpdated";
  readonly setting: UserSetting;
  readonly isTeam?: boolean;
}

export interface GotWorkerTokenAction {
  readonly type: "GotWorkerToken";
  readonly workerToken: string;
}

export interface GotSubscriptionDataAction {
  readonly type: "GotSubscriptionData";
  readonly subscriptionData: SubscriptionData;
}

export interface GotSettingAction {
  readonly type: "GotSetting";
  readonly setting: ResourceOwnerSetting;
}

export interface SelectTeamAction {
  readonly type: "SelectTeam";
  readonly payload: {
    resourceOwnerId?: string;
    user: User;
  };
}

export type ResourceOwnerAction =
  | PlanUpdatedAction
  | IsPaymentRequiredUpdatedAction
  | SettingUpdatedAction
  | PaymentMethodUpdatedAction
  | BillingEmailUpdatedAction
  | SelectTeamAction
  | GotWorkerTokenAction
  | GotSubscriptionDataAction
  | GotSettingAction;

export function subscribePlan(planId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId, isTeam } = getState().resourceOwner;
    const stripe = await getStripe();
    const paymentIntentSecret = await apiClient.subscribePlan(
      planId,
      resourceOwnerId
    );
    const result = await stripe.confirmCardPayment(paymentIntentSecret);

    if (result.error !== undefined) {
      console.error(result);
      throw result.error;
    }
    dispatch({
      type: "PlanUpdated",
      plan: "quota_plan",
      planId,
      teamId: isTeam ? resourceOwnerId : undefined,
    });
    dispatch({
      type: "IsPaymentRequiredUpdated",
      isPaymentRequired: false,
    });
  };
}

export function unsubscribe() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId, isTeam } = getState().resourceOwner;
    await apiClient.unsubscribe(resourceOwnerId);
    dispatch({
      type: "PlanUpdated",
      plan: "free",
      planId: null,
      teamId: isTeam ? resourceOwnerId : undefined,
    });
  };
}

export function checkIsPaymentRequired() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<boolean> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const isPaymentRequired = await apiClient.isPaymentRequired(
      resourceOwnerId
    );
    dispatch({
      type: "IsPaymentRequiredUpdated",
      isPaymentRequired,
    });
    return isPaymentRequired;
  };
}

export function updateSetting(setting: UserSetting) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId, isTeam } = getState().resourceOwner;

    const updated_setting = await handleConflict(
      () =>
        apiClient.updateResourceOwnerSetting(setting, false, resourceOwnerId),
      () =>
        apiClient.updateResourceOwnerSetting(setting, true, resourceOwnerId),
      {
        titleId: "setting.modified_prompt.title",
        messageId: "setting.modified_prompt.desc",
        actionId: "common.save_and_overwrite",
      }
    )(dispatch, getState);

    dispatch({ type: "SettingUpdated", setting: updated_setting, isTeam });

    return Promise.resolve();
  };
}

export function setPaymentMethod(
  card?: StripeCardElement,
  billingEmail?: string
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<PaymentMethod> => {
    const { resourceOwnerId, isTeam } = getState().resourceOwner;

    try {
      if (card) {
        const stripe = await getStripe();
        const { paymentMethod: stripePaymentMethod } =
          await stripe.createPaymentMethod({
            type: "card",
            card,
          });

        if (!stripePaymentMethod || !stripePaymentMethod.id) {
          throw errors.CreatePaymentMethodFailure;
        }

        const paymentMethod = await apiClient.setPaymentMethod(
          stripePaymentMethod.id,
          billingEmail,
          resourceOwnerId
        );

        dispatch({
          type: "PaymentMethodUpdated",
          payload: paymentMethod,
          isTeam: isTeam,
        });

        if (billingEmail) {
          dispatch({
            type: "BillingEmailUpdated",
            email: billingEmail,
          });
        }

        return paymentMethod;
      } else if (billingEmail) {
        const paymentMethod = await apiClient.setPaymentMethod(
          undefined,
          billingEmail,
          resourceOwnerId
        );

        dispatch({
          type: "BillingEmailUpdated",
          email: billingEmail,
        });

        return paymentMethod;
      } else {
        return Promise.reject();
      }
    } catch (e) {
      throw getPluginStripeError(e);
    }
  };
}

export function getWorkerToken() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<string> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const currentWorkerToken = getState().resourceOwner.workerToken;
    if (currentWorkerToken) {
      return currentWorkerToken;
    }

    const workerToken = await apiClient.getWorkerToken(resourceOwnerId);
    dispatch({ type: "GotWorkerToken", workerToken });
    return workerToken;
  };
}

export function getSubscriptionData() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<SubscriptionData> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const subscriptionData = await apiClient.getSubscriptionData(
      resourceOwnerId
    );

    dispatch({ type: "GotSubscriptionData", subscriptionData });
    return subscriptionData;
  };
}

export function getSetting() {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<ResourceOwnerSetting> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const setting = await apiClient.getSetting(resourceOwnerId);

    dispatch({ type: "GotSetting", setting });
    return setting;
  };
}

export function selectTeam(resourceOwnerId: string) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().user.currentUser;
    if (!currentUser) {
      return;
    }

    const currentResourceOwnerId = getState().resourceOwner.resourceOwnerId;
    if (resourceOwnerId === currentResourceOwnerId) {
      return;
    }

    const briefTeam = currentUser.teams.find(
      team => team.id === resourceOwnerId
    );
    if (briefTeam) {
      setParam(URLParamsKey.team, briefTeam.lookupId);
    }

    dispatch({
      type: "SelectTeam",
      payload: {
        resourceOwnerId,
        user: currentUser,
      },
    });

    checkIsPaymentRequired()(dispatch, getState);
  };
}

export function useResourceOwnerActionCreator() {
  return {
    updateSetting: useThunkDispatch(updateSetting),
    setPaymentMethod: useThunkDispatch(setPaymentMethod),
    subscribePlan: useThunkDispatch(subscribePlan),
    unsubscribe: useThunkDispatch(unsubscribe),
    checkIsPaymentRequired: useThunkDispatch(checkIsPaymentRequired),

    selectTeam: useThunkDispatch(selectTeam),
    getWorkerToken: useThunkDispatch(getWorkerToken),
    getSubscriptionData: useThunkDispatch(getSubscriptionData),
    getSetting: useThunkDispatch(getSetting),
  };
}
