import { MessageBar, MessageBarType } from "@fluentui/react";
import classnames from "classnames";
import * as React from "react";
import { useLocation } from "react-router";
import {
  DefaultToastContainer as RTNToastContainer,
  ToastContainerProps as RTNToastContainerProps,
  ToastProvider as RTNToastProvider,
  ToastProps,
  ToastProviderProps,
  useToasts,
} from "react-toast-notifications";

import { toast } from "../../utils/toast";
import styles from "./styles.module.scss";

const ToastAdapter: React.FC<unknown> = () => {
  const { addToast, removeAllToasts } = useToasts();

  toast.add = addToast;
  toast.dismiss = removeAllToasts;

  return null;
};

const AppearanceTypesToMessageBarType: {
  [key: string]: MessageBarType;
} = {
  info: MessageBarType.info,
  success: MessageBarType.success,
  warning: MessageBarType.warning,
  error: MessageBarType.error,
};

const MessageBarToast: React.FC<ToastProps> = (props: ToastProps) => {
  const { appearance, children, autoDismiss, onDismiss, transitionState } =
    props;

  const onToastDismiss = React.useCallback(
    (_ev?: React.MouseEvent<unknown>) => {
      onDismiss();
    },
    [onDismiss]
  );

  return (
    <div className={styles["toast"]}>
      <MessageBar
        className={classnames({
          [styles["fade-in"]]: transitionState === "entering",
          [styles["fade-out"]]: transitionState === "exiting",
        })}
        messageBarType={AppearanceTypesToMessageBarType[appearance]}
        isMultiline={true}
        onDismiss={autoDismiss ? undefined : onToastDismiss}
      >
        {children}
      </MessageBar>
    </div>
  );
};

const ToastContainer: React.FC<RTNToastContainerProps> = (
  props: RTNToastContainerProps
) => {
  const { pathname } = useLocation();
  const isSignUp = pathname.toLowerCase() === "/signup";
  const isLogin = pathname.toLowerCase() === "/login";

  return (
    <div
      className={classnames({
        [styles["login-toast-container"]]: isLogin,
        [styles["signup-toast-container"]]: isSignUp,
        [styles["toast-container"]]: !isLogin && !isSignUp,
      })}
    >
      <RTNToastContainer {...props} />
    </div>
  );
};

export const ToastProvider: React.FC<ToastProviderProps> = React.memo(
  (props: ToastProviderProps) => (
    <RTNToastProvider
      {...props}
      components={{
        Toast: MessageBarToast,
        ToastContainer,
      }}
      transitionDuration={3000}
    >
      <ToastAdapter />
      {props.children}
    </RTNToastProvider>
  )
);
