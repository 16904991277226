@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

$create-form-bar-height: 42px;

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: $create-form-bar-height;
  background-color: #fff;
  border-bottom: 1px solid $separator-color-2;

  padding-left: 32px;

  .button-area {
    cursor: pointer;
    text-align: center;
    padding-right: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > i {
      margin-right: 8px;
    }
  }
}

.content {
  height: calc(100% - #{$create-form-bar-height});
}

.import-icon {
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/icon-import.svg");
  background-size: contain;
  margin-right: 8px;
}
