import { PrimaryButton } from "@fluentui/react";
import classnames from "classnames";
import React from "react";

import { useFormEditor } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { ConfirmModalType } from "../../types/confirmation";
import ConfirmModal from "../ConfirmModal";
import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

const TabBarContentLayout = (props: Props) => {
  const { children } = props;

  const { localized } = useLocale();
  const {
    onSaveButtonClick,
    isFormNotSaved,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    isSavingForm,
  } = useFormEditor();

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["container"]}>
      <div className={styles["separator"]} />
      <div className={styles["content"]}>{children}</div>
      {hasPermissionToEditResource && (
        <div className={styles["save-button-container"]}>
          <PrimaryButton
            className={classnames(styles["save-button"], {
              [styles["disabled"]]: !isFormNotSaved || isSavingForm,
            })}
            text={localized("common.save")}
            onClick={() => onSaveButtonClick(false)}
            disabled={!isFormNotSaved || isSavingForm}
          />
        </div>
      )}
      <ConfirmModal
        isOpen={isConfirmationModalOpen}
        modalType={ConfirmModalType.Save}
        titleId="form_editor.form_modifed_prompt.title"
        messageId="form_editor.form_modifed_prompt.desc"
        actionId="common.save_and_overwrite"
        onCancel={() => setIsConfirmationModalOpen(false)}
        onConfirm={() => onSaveButtonClick(true)}
      />
    </div>
  );
};

export default TabBarContentLayout;
