import * as React from "react";

import { Img } from "../Img";
import styles from "./styles.module.scss";

interface Props {
  images: string[];
  onSelectImage(url: string): void;
}

function _ImageSelector(props: Props) {
  const { onSelectImage } = props;

  const onImageClick = React.useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      if (e.target instanceof HTMLImageElement) {
        onSelectImage(e.target.src as string);
      }
    },
    [onSelectImage]
  );

  const { images } = props;
  return (
    <div className={styles["image-selector"]}>
      {images.map((x, idx) => (
        <Img
          frameClass={styles["image-frame"]}
          key={idx}
          src={x}
          onClick={onImageClick}
        />
      ))}
    </div>
  );
}

export const ImageSelector = React.memo(_ImageSelector);
export default ImageSelector;
