import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useState } from "react";

import { VALID_URL_REGEX } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { ListAllFormsContainerState } from "../../hooks/form";
import { ListAllFormGroupsContainerState } from "../../hooks/form_group";
import { Webhook } from "../../types/webhook";
import ErrorPlaceholder from "../ErrorPlaceholder";
import SaveWebhookBar from "../SaveWebhookBar";
import ShortSpinner from "../ShortSpinner";
import { WebhookConnectFormField } from "../WebhookConnectFormField";
import WebhookNavBar from "../WebhookNavBar";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

interface Props {
  webhook?: Webhook;
  formsState?: ListAllFormsContainerState;
  formGroupsState?: ListAllFormGroupsContainerState;
  submit: (
    name: string,
    url: string,
    formIds: string[],
    formGroupIds: string[]
  ) => void;
  onRemoveClicked?: () => void;
}

const WebhookEditor = React.memo((props: Props) => {
  const { webhook, formsState, formGroupsState, submit, onRemoveClicked } =
    props;
  const { localized } = useLocale();
  const [webhookName, setWebhookName] = useState(webhook?.name ?? "");
  const [webhookUrl, setWebhookUrl] = useState(webhook?.webhookUrl ?? "");
  const [connectWithFormIds, setConnectWithFormIds] = useState<string[]>(
    webhook?.forms?.map(f => f.id) ?? []
  );
  const [connectWithFormGroupIds, setConnectWithFormGroupIds] = useState<
    string[]
  >(webhook?.formGroups?.map(f => f.id) ?? []);
  const [webhookNameErrorMessage, setWebhookNameErrorMessage] = useState<
    string | undefined
  >();
  const [webhookUrlErrorMessage, setWebhookUrlErrorMessage] = useState<
    string | undefined
  >();
  const [connectWithErrorMessage, setConnectWithErrorMessage] = useState<
    string | undefined
  >();

  const isCreate = React.useMemo(() => webhook == null, [webhook]);

  const onWebhookNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setWebhookName(value);
        setWebhookNameErrorMessage(undefined);
      }
    },
    []
  );

  const onWebhookUrlChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setWebhookUrl(value);
        setWebhookUrlErrorMessage(undefined);
      }
    },
    []
  );

  const onConnectFormGroupChange = useCallback(
    (formGroupId: string) =>
      (ev?: React.FormEvent<unknown>, checked?: boolean) => {
        if (ev) {
          ev.stopPropagation();
        }

        if (checked === undefined) {
          return;
        }

        let connectWithFormGroupsList = [...connectWithFormGroupIds];
        if (checked) {
          connectWithFormGroupsList = connectWithFormGroupsList.concat([
            formGroupId,
          ]);
        } else {
          connectWithFormGroupsList = connectWithFormGroupsList.filter(
            x => x !== formGroupId
          );
        }

        setConnectWithFormGroupIds(connectWithFormGroupsList);
        setConnectWithErrorMessage(undefined);
      },
    [connectWithFormGroupIds]
  );

  const onConnectFormChange = useCallback(
    (formId: string) => (ev?: React.FormEvent<unknown>, checked?: boolean) => {
      if (ev) {
        ev.stopPropagation();
      }

      if (checked === undefined) {
        return;
      }

      let connectWithFormsList = [...connectWithFormIds];
      if (checked) {
        connectWithFormsList = connectWithFormsList.concat([formId]);
      } else {
        connectWithFormsList = connectWithFormsList.filter(x => x !== formId);
      }

      setConnectWithFormIds(connectWithFormsList);
      setConnectWithErrorMessage(undefined);
    },
    [connectWithFormIds]
  );

  const validationExistenceCheck = useCallback(
    (webhookName: string, webhookUrl: string): boolean => {
      let result = true;

      if (!webhookName) {
        result = false;
        setWebhookNameErrorMessage("error.webhook.empty_webhook_name");
      }
      if (!webhookUrl) {
        result = false;
        setWebhookUrlErrorMessage("error.webhook.empty_webhook_url");
      }

      return result;
    },
    []
  );

  const validationUrl = useCallback((webhookUrl: string): boolean => {
    if (!webhookUrl.match(VALID_URL_REGEX)) {
      setWebhookUrlErrorMessage("error.webhook.invalid_webhook_url");
      return false;
    }

    return true;
  }, []);

  const validateInputs = useCallback(
    (webhookName: string, webhookUrl: string): boolean => {
      if (!validationExistenceCheck(webhookName, webhookUrl)) {
        return false;
      }

      if (!validationUrl(webhookUrl)) {
        return false;
      }

      return true;
    },
    [validationExistenceCheck, validationUrl]
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!validateInputs(webhookName, webhookUrl)) {
        return;
      }

      submit(
        webhookName,
        webhookUrl,
        connectWithFormIds,
        connectWithFormGroupIds
      );
    },
    [
      webhookName,
      webhookUrl,
      connectWithFormIds,
      connectWithFormGroupIds,
      validateInputs,
      submit,
    ]
  );

  return (
    <form onSubmit={onSubmit}>
      <SaveWebhookBar isCreate={isCreate} onRemoveClicked={onRemoveClicked} />
      <div className={styles["webhook-form"]}>
        <WebhookNavBar isCreate={isCreate} />
        <div className={styles["fields-container"]}>
          <TextField
            type="text"
            className={styles["input-field"]}
            labelId="webhook.name"
            placeholder={localized("webhook.name.placeholder")}
            value={webhookName}
            onChange={onWebhookNameChange}
            errorMessage={
              webhookNameErrorMessage && localized(webhookNameErrorMessage)
            }
          />
          <TextField
            type="text"
            className={styles["input-field"]}
            labelId="webhook.url"
            value={webhookUrl}
            onChange={onWebhookUrlChange}
            errorMessage={
              webhookUrlErrorMessage && localized(webhookUrlErrorMessage)
            }
          />
          {/* Only show connect with field on Edit Page */}
          {!isCreate && (
            <div className={styles["input-field"]}>
              <Label>
                <FormattedMessage id="webhook.connect_with" />
              </Label>
              {formsState?.state === "success" &&
                formGroupsState?.state === "success" && (
                  <WebhookConnectFormField
                    forms={formsState.forms}
                    formGroups={formGroupsState.formGroups}
                    connectWithFormIds={connectWithFormIds}
                    connectWithFormGroupIds={connectWithFormGroupIds}
                    onConnectFormChange={onConnectFormChange}
                    onConnectFormGroupChange={onConnectFormGroupChange}
                    connectWithErrorMessage={connectWithErrorMessage}
                  />
                )}
              {(formsState?.state === "loading" ||
                formGroupsState?.state === "loading") && <ShortSpinner />}
              {(formsState?.state === "error" ||
                formGroupsState?.state === "error") && (
                <ErrorPlaceholder messageId="webhook.edit.fetch_forms_form_groups_fail" />
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
});

export default WebhookEditor;
