import * as React from "react";
import { useHistory } from "react-router";

import { useLocalizeRegion } from "../../hooks/app";
import { TeamListItem } from "../../types/team";
import { capitalizeFirstLetter } from "../../utils/capitalize";
import styles from "./styles.module.scss";

interface Props {
  team: TeamListItem;
  key: number;
  regionKey: string;
}

export function _TeamTableRow(props: Props) {
  const { team, regionKey } = props;
  const history = useHistory();
  const localizeRegion = useLocalizeRegion();

  const onRowClick = React.useCallback(() => {
    history.push(
      `/admin/team/${regionKey}/${team.id}${window.location.search}`
    );
  }, [history, team.id, regionKey]);

  return (
    <tr onClick={onRowClick} className={styles["team-table-row"]}>
      <td>
        <div className={styles["basic-info"]}>
          <div className={styles["name"]}>{team.name}</div>
          <div className={styles["lookup-id"]}>{team.lookupId}</div>
        </div>
      </td>
      <td>
        <div className={styles["detail-info-capitalize"]}>
          {localizeRegion(regionKey)}
        </div>
      </td>
      <td>
        <div className={styles["detail-info"]}>{team.contactEmail}</div>
      </td>
      <td>
        <div className={styles["detail-info-capitalize"]}>
          {capitalizeFirstLetter(team.plan || "")}
        </div>
      </td>
      <td>
        <div className={styles["detail-info"]}>
          {team.createdAt.toLocaleDateString()}{" "}
          {team.createdAt.toLocaleTimeString()}
        </div>
      </td>
    </tr>
  );
}

export const TeamTableRow = React.memo(_TeamTableRow);
export default TeamTableRow;
