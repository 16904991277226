import * as yup from "yup";

const keyValuePosition = yup
  .string()
  .oneOf(["above", "left", "right", "below"])
  .defined();

const keyValueTokenSchema = yup
  .object({
    token: yup.string().defined(),
    useFuzzySearch: yup.boolean().defined(),
  })
  .camelCase();

export const keyValueSchema = yup
  .object({
    name: yup.string().defined(),
    pattern: yup.string().defined(),
    position: keyValuePosition.defined(),
    extra: yup.mixed().optional(),
    tokens: yup.array(keyValueTokenSchema).defined(),
  })
  .transform(value => {
    const { created_at, ...rest } = value;
    return {
      ...rest,
      created_at: created_at || 0,
    };
  })
  .shape({
    created_at: yup.number().defined(),
  });

export type KeyValueToken = yup.InferType<typeof keyValueTokenSchema>;

export type KeyValuePosition = yup.InferType<typeof keyValuePosition>;

export type KeyValue = yup.InferType<typeof keyValueSchema>;

export type KeyValueTokenResp = {
  token: string;
  use_fuzzy_search: boolean;
};

export type KeyValueResp = Omit<KeyValue, "tokens"> & {
  tokens: KeyValueTokenResp[];
};
