@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.form-group-table,
.form-group-table-no-hover {
  padding-top: 16px;
}

.form-group-table-no-hover {
  tbody > tr:hover {
    background-color: transparent;
  }
}

.form-group-token-group-table-row {
  td {
    vertical-align: text-top;
  }

  td:nth-child(1) {
    min-width: 100px;
  }

  td:nth-child(2) {
    min-width: 150px;
  }

  td:nth-child(3) {
    min-width: 100px;
  }

  td:nth-child(4) {
    width: 100px;
  }

  .action-container {
    display: flex;
  }

  .action-button {
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
  }
}

.form-group-anchor-table-row {
  td:nth-child(1) {
    width: 100%;
  }
  td:nth-child(2) {
    width: 100px;
  }

  .action-container {
    display: flex;
  }

  .action-button {
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
  }
}

.form-group-editor {
  padding: 10px;
  padding-top: 0;
  height: 100%;
  overflow: auto;

  h1 {
    padding: 10px 0;
    font-size: 18px;
    margin: 10px 20px;
    border-bottom: 1px solid #ccc;
  }

  .description {
    margin-bottom: 20px;
    font-size: 12pt;
    line-height: 1.5em;
  }

  .section {
    padding: 0 30px;
    margin-bottom: 20px;
  }

  .buttons {
    padding: 0 15px;
    button {
      margin: 6px;
    }
  }

  .group-name {
    font-size: 16px;
    line-height: 20pt;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.form-group-form-list {
  position: relative;

  :global(.ms-DetailsRow-cell) {
    font-size: 14px;
    color: black;
  }

  :global(.ms-DetailsRow):hover {
    background-color: white;
  }

  .add-new-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    padding-bottom: 0px;
  }

  .add-new-form-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .add-new-form-button-icon {
    margin-right: 10px;
  }

  .action-container {
    display: flex;
  }

  .action-button {
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: white, $alpha: 0.5);
  }
}

.anchor-modal-description {
  margin: 10px 0px;
}

.form-group-token-group-content {
  display: flex;
  flex-direction: column;
  padding: 4px 0 4px 8px;
  width: 100%;

  .token-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .link {
    margin-left: 8px;
    padding: 8px 0 6px;
    font-size: 14px;
  }

  .token-form-name {
    padding-bottom: 10px;
  }
}

.form-group-token-group-modal-form {
  .match-mode-choice-group {
    margin-top: 20px;

    :global(.ms-ChoiceFieldLabel) {
      white-space: pre-wrap;
    }

    :global(.ms-ChoiceField) {
      & + :global(.ms-ChoiceField) {
        margin-top: 25px;
      }
    }
  }
}
