import {
  ITextFieldProps,
  Label,
  TextField as MSTextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

export interface Props extends Omit<ITextFieldProps, "onRendeLabel" | "label"> {
  labelId: string;
}

export default function TextField(props: Props) {
  const { required, labelId, disabled, ...otherProps } = props;
  const onRenderLabel = useCallback(() => {
    return (
      <Label required={required} disabled={disabled}>
        <FormattedMessage id={labelId} />
      </Label>
    );
  }, [labelId, required, disabled]);

  return (
    <MSTextField
      {...otherProps}
      required={required}
      label=" " // dummy label for no duplicated asterisks
      onRenderLabel={onRenderLabel}
      disabled={disabled}
    />
  );
}
