import { Checkbox } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { useTeamPermission } from "../../hooks/permission";
import IconPdf from "../../images/icon-pdf-file.svg";
import styles from "./styles.module.scss";

interface Props {
  name: string;
  url: string;
  checked: boolean;
  disabled?: boolean;
  date: number;
  contentType: string;
  onCheckedChange: (_event?: React.FormEvent<any>, value?: boolean) => void;
}

export default function CustomModelEditorImageGridItem(props: Props) {
  const { name, url, checked, disabled, date, onCheckedChange, contentType } =
    props;
  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["grid-item"]}>
      {SUPPORTED_IMAGE_MIME.includes(contentType) ? (
        <img alt="preview" src={url} className={styles["image-preview"]} />
      ) : (
        <img alt="pdf" src={IconPdf} className={styles["pdf-icon"]} />
      )}
      {hasPermissionToEditResource && (
        <Checkbox
          checked={checked}
          disabled={disabled}
          className={styles["checkbox"]}
          onChange={onCheckedChange}
        />
      )}

      <div className={styles["label"]}>{name}</div>
      <div className={styles["date"]}>
        <FormattedMessage
          id="custom_model_editor.image_grid.item.upload_at"
          values={{ date: new Date(date).toLocaleDateString() }}
        />
      </div>
    </div>
  );
}
