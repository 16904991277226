export enum MerchantSettingInfoPresetType {
  BrandName = "brand_name",
  MerchantName = "merchant_name",
  PhoneNumber = "phone_number",
  FaxNumber = "fax_number",
  Url = "url",
}

export type MerchantSettingInfoResp = {
  type: string;
  pattern: string;
  is_exact_match_only: boolean;
  is_preset: boolean;
};

export type MerchantSettingInfo = {
  type: string;
  pattern: string;
  isExactMatchOnly: boolean;
  isPreset: boolean;
};

export type MerchantSettingResp = {
  id: string;
  name: string;
  match_mode: "best" | "all";
  infos: MerchantSettingInfoResp[];
  updated_at: number;
};

export type MerchantSetting = {
  id: string;
  name: string;
  matchMode: "best" | "all";
  infos: MerchantSettingInfo[];
  updatedAt: number;
};

export type MerchantExtractionFallbackStrategry = "hk_popular_merchant" | null;
