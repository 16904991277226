import {
  FormCreated,
  FormRemoved,
  FormSaved,
  FormsInvalidated,
} from "../actions/form";
import {
  FormGroupCreated,
  FormGroupDeleted,
  FormGroupsInvalidated,
  GotFormGroup,
  GotFormGroupList,
  GotFormGroupListAction,
  GotForms,
} from "../actions/formGroup";
import {
  CreateTeam,
  TeamInvitationAccepted,
  TeamUserRemoved,
} from "../actions/team";
import { RootAction } from "../redux/types";
import { BriefForm } from "../types/form";
import { BriefFormGroup, DetailFormGroup } from "../types/formGroup";
import { BriefFormMapper } from "../types/mappers/form";
import { BriefFormGroupMapper } from "../types/mappers/formGroup";
import { PageInfo } from "../types/pageInfo";

export interface FormGroupState {
  readonly formGroups: BriefFormGroup[];
  readonly forms: BriefForm[];
  readonly currentFormGroup: DetailFormGroup | undefined;
  readonly pageInfo?: PageInfo;
  readonly isFetching: boolean;
  readonly shouldLoadForms: boolean;
  readonly listVersion: number;
}

const defaultState: FormGroupState = {
  formGroups: [],
  forms: [],
  currentFormGroup: undefined,
  pageInfo: undefined,
  isFetching: false,
  shouldLoadForms: true,
  listVersion: 0,
};

function handleGotFormGroupList(
  state: FormGroupState,
  action: GotFormGroupListAction
): FormGroupState {
  const { formGroups, pageInfo, listVersion } = action.payload;
  const updatedForms = state.formGroups.concat(formGroups);

  if (listVersion !== state.listVersion) {
    return state;
  }

  return {
    ...state,
    formGroups: updatedForms,
    pageInfo: {
      ...pageInfo,
    },
    isFetching: false,
    listVersion,
  };
}

function updateFormGroups(
  originalFormGroups: BriefFormGroup[],
  updatedFormGroup: DetailFormGroup
): BriefFormGroup[] {
  const formIndex = originalFormGroups.findIndex(
    group => group.id === updatedFormGroup.id
  );
  if (formIndex === -1) {
    return originalFormGroups;
  }
  const newGroups = [...originalFormGroups];
  newGroups[formIndex] = BriefFormGroupMapper.fromDetail(updatedFormGroup);
  return newGroups;
}

export function reducer(
  state: FormGroupState = defaultState,
  action: RootAction
): FormGroupState {
  switch (action.type) {
    case "GettingFormGroupList":
      const { listVersion } = action;
      return {
        ...state,
        listVersion,
      };
    case GotFormGroupList:
      return handleGotFormGroupList(state, action);
    case FormGroupsInvalidated:
      return {
        ...state,
        formGroups: [],
        currentFormGroup: undefined,
        pageInfo: undefined,
      };
    case FormGroupCreated:
      return {
        ...state,
        formGroups: [action.payload.formGroup, ...state.formGroups],
      };
    case FormGroupDeleted:
      return {
        ...state,
        formGroups: state.formGroups.filter(
          group => group.id !== action.payload.formGroupId
        ),
      };
    case GotFormGroup:
      return {
        ...state,
        currentFormGroup: action.payload.formGroup,
        formGroups: updateFormGroups(
          state.formGroups,
          action.payload.formGroup
        ),
      };
    case GotForms:
      return {
        ...state,
        forms: action.payload.forms,
        shouldLoadForms: false,
      };
    case FormsInvalidated:
      return {
        ...state,
        forms: [],
        shouldLoadForms: true,
      };
    case FormCreated:
      return {
        ...state,
        forms: [BriefFormMapper.fromDetailedForm(action.payload.form)].concat(
          state.forms
        ),
      };
    case FormSaved:
      return {
        ...state,
        forms: state.forms.map(x =>
          x.id === action.payload.form.id ? { ...action.payload.form } : x
        ),
        formGroups: state.formGroups.map((group: BriefFormGroup) => ({
          ...group,
          forms: group.forms.map(x =>
            x.id === action.payload.form.id ? { ...action.payload.form } : x
          ),
        })),
        currentFormGroup: state.currentFormGroup && {
          ...state.currentFormGroup,
          anchors: state.currentFormGroup.anchors.map(anchor =>
            anchor.formId === action.payload.form.id
              ? {
                  ...anchor,
                  form: { ...action.payload.form },
                }
              : anchor
          ),
          tokenGroups: state.currentFormGroup.tokenGroups.map(tokenGroup =>
            tokenGroup.formId === action.payload.form.id
              ? {
                  ...tokenGroup,
                  form: { ...action.payload.form },
                }
              : tokenGroup
          ),
        },
      };

    case FormRemoved:
      return {
        ...state,
        forms: state.forms.filter(x => x.id !== action.payload.formId),
        formGroups: state.formGroups.map((group: BriefFormGroup) => ({
          ...group,
          forms: group.forms.filter(x => x.id !== action.payload.formId),
        })),
        currentFormGroup: state.currentFormGroup && {
          ...state.currentFormGroup,
          anchors: state.currentFormGroup.anchors.filter(
            x => x.formId !== action.payload.formId
          ),
          tokenGroups: state.currentFormGroup.tokenGroups.filter(
            x => x.formId !== action.payload.formId
          ),
        },
      };

    case TeamUserRemoved: {
      const { removedUserId, currentUser } = action;
      if (removedUserId === currentUser.id) {
        return {
          ...defaultState,
        };
      } else {
        return state;
      }
    }

    case "UserLogin":
    case "UserLogout":
      return {
        ...defaultState,
      };

    case "SelectTeam":
    case CreateTeam:
    case TeamInvitationAccepted:
      return {
        ...defaultState,
        listVersion: state.listVersion,
      };
    case "TeamDeleted":
    default:
      return state;
  }
}
