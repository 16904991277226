import { CustomModel } from "../types/customModel";

export const DefaultCustomModel: CustomModel = {
  id: "",
  name: "",
  config: {
    fields: [""],
    frozenFields: [],
    sampleImages: [],
    remark: "",
    trainingRequested: false,
    fxcmType: "doc_info",
  },
  CVATProjectID: null,
  formID: null,
  lastCVATProjectStartSyncAt: null,
  lastCVATProjectFinishSyncAt: null,
  startTrainingAt: null,
  finishTrainingAt: null,
  startDeploymentAt: null,
  finishDeploymentAt: null,
  modelVersions: [],
  resourceOwnerId: "",
  status: "not_synced",
};
