import {
  TokenGroup,
  TokenGroupImage,
  TokenGroupImageResp,
  TokenGroupResp,
  TokenGroupText,
  TokenGroupTextResp,
} from "../tokenGroup";

export const TokenGroupTextMapper = {
  toResp: (tokenGroupTexts: TokenGroupText[]): TokenGroupTextResp[] =>
    tokenGroupTexts.map(
      ({ id, value, isExactMatchOnly, isIgnoreWhiteSpace, label }) => {
        const resp: TokenGroupTextResp = {
          id,
          value,
          is_exact_match_only: isExactMatchOnly,
          is_ignore_white_space: isIgnoreWhiteSpace,
        };
        if (label) {
          resp.label = label;
        }
        return resp;
      }
    ),
};

export const TokenGroupImageMapper = {
  toResp: (images: TokenGroupImage[]): TokenGroupImageResp[] => {
    return images.map(({ assetId, ...restImageProps }) => ({
      asset_id: assetId,
      ...restImageProps,
    }));
  },
};

export const TokenGroupMapper = {
  toResp: (tokenGroups: TokenGroup[]): TokenGroupResp[] =>
    tokenGroups.map(
      ({ id, name, images, texts, matchMode, tokenType, created_at }) => {
        const result: TokenGroupResp = {
          id,
          name,
          images: TokenGroupImageMapper.toResp(images),
          texts: TokenGroupTextMapper.toResp(texts),
          created_at,
          match_mode: matchMode,
        };
        if (tokenType) {
          result.token_type = tokenType;
        }
        return result;
      }
    ),
};
