@import "../../styles/variables.scss";

$top-height: 48px;
$bottom-height: 64px;
$button-color: #ffd633;
$button-width: 240px;

.container {
  position: absolute;
  top: $top-height;
  width: 100%;
  height: calc(100vh - #{$top-height});
  max-height: calc(100vh - #{$top-height});
  border-left: 0.5px solid #cccccc;
}

.content {
  width: 100%;
  overflow-y: auto;
  padding: 20px 24px 20px 24px;
  height: calc(100vh - #{$top-height} - #{$bottom-height});
}

.bottom {
  width: 100%;
  height: $bottom-height;
  max-height: $bottom-height;
}

.title {
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 22px;
  border-bottom: 1px solid #c5c5c6;
}

.desc {
  font-size: 14px;
  margin-top: 12px;
}

.subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.create-model-button {
  float: right;
  margin-right: 28px;
  margin-top: 10px;
}

.create-model-button:not(:disabled) {
  color: #000000;
  background-color: $button-color;
  border-color: $button-color;
}

.create-model-button:hover {
  background-color: darken($button-color, 30%);
  border-color: darken($button-color, 30%);
}

.create-model-button:active {
  background-color: darken($button-color, 20%);
  border-color: darken($button-color, 20%);
}

.text-field {
  max-width: $button-width;
  width: $button-width;
  margin-bottom: 12px;
  margin-right: 8px;

  ::placeholder {
    font-size: 12px;
  }
}

.info-field {
  display: flex;
}

.add-new-info-button {
  width: $button-width;
  margin-bottom: 12px;
}
