@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.webhook-form {
  overflow-y: auto;
  background-color: #fff;
  padding: 32px 26px;

  .title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .fields-container {
    max-width: 532px;
    padding: 15px 6px;

    .input-field {
      margin-top: 15px;

      .connect-container {
        border: 1px solid #edebe9;
        border-radius: 2px;
        padding: 0 10px;
        height: 160px;
        overflow-y: auto;

        &.error {
          border-color: $input-error-color;
        }

        .connect-list-header {
          margin-top: 5px;
          border-bottom: 1px solid #cccccc;
          margin-bottom: 13px;
        }

        .row {
          margin-bottom: 8px;
        }
      }
    }
  }

  .error-message {
    font-size: 12px;
    font-weight: 400;
    color: $input-error-color;
  }
}
