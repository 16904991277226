import { ICommandBarItemProps } from "@fluentui/react";
import copy from "copy-to-clipboard";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { getExtractAPIDescription } from "../apidoc/extract";
import { ExtractReceiptInfoAPIDescription } from "../apidoc/extract_receipt_info";
import APINote from "../components/APINote";
import { Layout, Main, Top } from "../components/Layout";
import { ReceiptNavBarLayout } from "../components/ReceiptNavBar";
import HeaderContainer from "../containers/Header";
import { useLocale } from "../contexts/locale";
import { useWorkerToken } from "../hooks/app";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { BriefFormWithType } from "../types/form";
import { ReceiptGroupWithType } from "../types/receiptGroup";

interface screenProps {
  commbarBarItems: () => ICommandBarItemProps[];
  receiptGroup: ReceiptGroupWithType | BriefFormWithType;
}

function Screen(props: screenProps) {
  const { commbarBarItems, receiptGroup } = props;

  return (
    <Main hasTop={true}>
      <ReceiptNavBarLayout commbarBarItems={commbarBarItems()}>
        {receiptGroup && (
          <APINote
            getDescription={
              receiptGroup.type === "form"
                ? getExtractAPIDescription
                : () => ExtractReceiptInfoAPIDescription
            }
            shouldShowAsync={receiptGroup.type === "form"}
            shouldShowMultiDocument={receiptGroup.type === "form"}
          />
        )}
      </ReceiptNavBarLayout>
    </Main>
  );
}

function _ReceiptAPIContainer() {
  const { localized } = useLocale();
  const { token } = useWorkerToken();

  const toast = useToast();

  const receiptGroup = useSelector<RootState>(
    state => state.receiptGroup.currentReceiptGroup
  ) as ReceiptGroupWithType | BriefFormWithType;

  const onCopyToken = useCallback(() => {
    copy(token || "");
    toast.success("receipt_api.token_copied");
  }, [token, toast]);

  const onCopyID = useCallback(() => {
    copy(receiptGroup ? receiptGroup.id : "");
    toast.success("receipt_api.id_copied");
  }, [receiptGroup, toast]);

  const commbarBarItems = (): ICommandBarItemProps[] => [
    {
      key: "copy-receipt-group-id",
      text: localized(
        receiptGroup && receiptGroup.type === "receipt_group"
          ? "receipt_api.copy_id"
          : "ocrapi.copy_id"
      ),
      iconProps: { iconName: "Tag" },
      onClick: onCopyID,
    },
    {
      key: "copy-access-token",
      text: localized("receipt_api.copy_token"),
      iconProps: { iconName: "Signin" },
      onClick: onCopyToken,
    },
  ];

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Screen commbarBarItems={commbarBarItems} receiptGroup={receiptGroup} />
    </Layout>
  );
}

export const ReceiptAPIContainer = React.memo(_ReceiptAPIContainer);
export default ReceiptAPIContainer;
