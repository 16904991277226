import * as React from "react";
import { useParams } from "react-router";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FormGroupNavBarLayout } from "../components/FormGroupNavBar";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { OCRTest } from "../components/OCRTest";
import HeaderContainer from "../containers/Header";
import { useTestCommandBarItems } from "../hooks/command_bar_items";
import { useExtractTest } from "../hooks/extract_test";
import { useCommonFormGroupContainerState } from "../hooks/form_group";
import { FormExtractionMode } from "../types/form";

type PathParam = {
  formGroupId: string;
};

export const FormGroupTestContainer = React.memo(() => {
  const { formGroupId } = useParams<PathParam>();

  const containerState = useCommonFormGroupContainerState(formGroupId);

  const {
    extractWithFile,
    extractWithURL,
    isRecognizing,
    isUploading,
    testReport,
    setTestReport,
    extractionMode,
    onExtractionModeChanged,
  } = useExtractTest(
    containerState.state === "success" ? containerState.formGroup.id : undefined
  );

  const commbarBarItems = useTestCommandBarItems(
    extractWithFile,
    !!testReport && extractionMode !== FormExtractionMode.singlePage,
    !!testReport,
    () => setTestReport(undefined)
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
        ) : containerState.state === "success" ? (
          <>
            <FormGroupNavBarLayout
              formGroup={containerState.formGroup}
              commbarBarItems={commbarBarItems}
            >
              <OCRTest
                onSelectImage={extractWithFile}
                onSelectSampleImage={extractWithURL}
                isSampleForm={false}
                isTemplate={containerState.formGroup.isTemplate}
                isReceiptForm={false}
                ocrTestReport={testReport}
                formName={containerState.formGroup.name}
                extractionMode={extractionMode}
                onExtractionModeChanged={onExtractionModeChanged}
              />
            </FormGroupNavBarLayout>
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
          </>
        ) : null}
      </Main>
    </Layout>
  );
});

export default FormGroupTestContainer;
