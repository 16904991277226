import produce from "immer";

import {
  MoveToNextStep,
  SetCurrentTutorial,
  TerminateCurrentTutorial,
} from "../actions/tutorial";
import { Tutorial, TutorialContent, TutorialIndex } from "../models";
import { RootAction } from "../redux/types";

export interface TutorialState {
  tutorial?: Tutorial;
  indexPath?: number[];
  content?: TutorialContent;
  currentLevelLength?: number;
}

export function getTutorialContentByIndexPath(
  tutorial: Tutorial,
  indexPath: TutorialIndex,
  reachLeaf: boolean = false,
  accIndexPath: TutorialIndex = [],
  parentLength: number = 0
): [Tutorial, TutorialIndex, number] {
  if (!Array.isArray(tutorial)) {
    return [tutorial, accIndexPath, parentLength];
  } else {
    if (tutorial.length === 0) {
      throw new Error("Empty tutorial");
    }

    if (indexPath.length === 0) {
      return reachLeaf
        ? getTutorialContentByIndexPath(
            tutorial[0],
            [],
            reachLeaf,
            [...accIndexPath, 0],
            tutorial.length
          )
        : [tutorial, accIndexPath, parentLength];
    }

    if (indexPath[0] >= tutorial.length) {
      throw new Error("Invalid index");
    }

    return getTutorialContentByIndexPath(
      tutorial[indexPath[0]],
      indexPath.slice(1, indexPath.length),
      reachLeaf,
      [...accIndexPath, indexPath[0]],
      tutorial.length
    );
  }
}

export function getNextTutorialContent(
  tutorial: Tutorial,
  currentItemIndexPath: number[]
): [TutorialContent, number[], number] {
  const incrementedLastIndex =
    currentItemIndexPath[currentItemIndexPath.length - 1] + 1;
  const droppedLastIndexPath = currentItemIndexPath.slice(0, -1);

  const [parentLevelTutorial] = getTutorialContentByIndexPath(
    tutorial,
    droppedLastIndexPath,
    false
  );

  if (
    Array.isArray(parentLevelTutorial) &&
    parentLevelTutorial.length > incrementedLastIndex
  ) {
    const [_content, _indexPath, parentLevelLength] =
      getTutorialContentByIndexPath(
        tutorial,
        [...droppedLastIndexPath, incrementedLastIndex],
        true
      );
    return [_content as TutorialContent, _indexPath, parentLevelLength];
  } else {
    return getNextTutorialContent(tutorial, droppedLastIndexPath);
  }
}

export function reducer(
  state: TutorialState = {},
  action: RootAction
): TutorialState {
  switch (action.type) {
    case SetCurrentTutorial:
      const SetCurrentTutorialResult = produce(
        state,
        (draftState: TutorialState) => {
          draftState.tutorial = action.tutorial as Tutorial;
          const [tutorialContent, indexPath, currentLevelLength] =
            getTutorialContentByIndexPath(action.tutorial, [], true);
          draftState.content = tutorialContent as TutorialContent;
          draftState.indexPath = indexPath;
          draftState.currentLevelLength = currentLevelLength;
        }
      );
      return SetCurrentTutorialResult;
    case TerminateCurrentTutorial:
      return produce(state, (draftState: TutorialState) => {
        draftState.tutorial = undefined;
        draftState.content = undefined;
        draftState.indexPath = undefined;
      });
    case MoveToNextStep:
      return produce(state, (draftState: TutorialState) => {
        const { tutorial, indexPath } = draftState;

        if (tutorial === undefined || indexPath === undefined) {
          throw new Error(
            "Impossible State - current tutorial and step have to be set before moving to next step."
          );
        }

        if (!Array.isArray(tutorial)) {
          throw new Error("No next step as the tutorial isn't an array!");
        }

        const [nextTutorialContent, nextIndexPath, parentLength] =
          getNextTutorialContent(tutorial, indexPath);

        draftState.content = nextTutorialContent as TutorialContent;
        draftState.indexPath = nextIndexPath;
        draftState.currentLevelLength = parentLength;
      });
    default:
      return state;
  }
}
