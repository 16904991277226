export const LABELLER_PADDING = 60;
export const PAYMENT_REQUIRED_TOAST_ID = "payment_required";
export const ToolBoxTutorialTargetIds = {
  InfoIcon: "info-icon",
  SelectToolBox: "select-tool-box",
  AnchorToolBox: "anchor-tool-box",
  DetectionRegionToolBox: "detection-region-tool-box",
};
export const MAX_FETCH_CUSTOM_MODELS = 50;

export const DEFAULT_CUSTOM_MODEL_REMARK = "None";
export const CUSTOM_MODEL_SAVE_DEBOUNCE_INTERVAL = 500;
export const CONFIRM_MODAL_FADE_OUT_DELAY = 300;

export const PLAN_DETAIL_COUNT_MAP: {
  [key: string]: number;
} = {
  free: 0,
  starter: 3,
  enterprise: 6,
};

export const EXTRACTION_POLLING_INTERVAL = 2000;
export const EXTRACTION_MAXIMUM_POLLING_TIME = 300000; // 5 mintues

export const SHOULD_RELOAD_COOKIE_POLLING_INTERVAL = 30000;
