import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { useDragAndDropFiles } from "../../hooks/drag_and_drop";
import sampleReceipt1 from "../../images/sample/sample-receipt-1.jpg";
import sampleReceipt2 from "../../images/sample/sample-receipt-2.jpg";
import sampleReceipt3 from "../../images/sample/sample-receipt-3.jpg";
import sampleReceipt4 from "../../images/sample/sample-receipt-4.jpg";
import sampleReceipt5 from "../../images/sample/sample-receipt-5.jpg";
import sampleReceipt6 from "../../images/sample/sample-receipt-6.jpg";
import { ReceiptTestReport } from "../../models";
import { ExternalServicesAlertBoxForReceipt } from "../ExternalServiceAlertBox";
import ImageSelector from "../ImageSelector";
import { OCRTestPlaceholder } from "../OCRTestPlaceholder";
import styles from "./styles.module.scss";

export const SampleReceiptImages = [
  sampleReceipt1,
  sampleReceipt2,
  sampleReceipt3,
  sampleReceipt4,
  sampleReceipt5,
  sampleReceipt6,
];

interface Props {
  onSelectImage: (file?: File) => void;
  onSelectSampleImage: (url: string) => void;
  receiptTestReport?: ReceiptTestReport;
  imageDataURI?: string;
}

function _ReceiptTest(props: Props) {
  useDragAndDropFiles(
    (files?: File[]) => props.onSelectImage(files && files[0]),
    SUPPORTED_EXTRACT_MIME
  );

  const {
    receiptTestReport,
    imageDataURI,
    onSelectImage,
    onSelectSampleImage,
  } = props;

  return (
    <div className={styles["receipt-test"]}>
      <ExternalServicesAlertBoxForReceipt />
      <div className={styles["sample-image-box"]}>
        <h1>
          <FormattedMessage id="receipt_test.try_sample" />
        </h1>
        <ImageSelector
          images={SampleReceiptImages}
          onSelectImage={onSelectSampleImage}
        />
      </div>

      {receiptTestReport && imageDataURI ? (
        <ReceiptReportView
          imageDataURI={imageDataURI}
          report={receiptTestReport}
        />
      ) : (
        <OCRTestPlaceholder onSelectImage={onSelectImage} />
      )}
    </div>
  );
}

interface ReceiptReportViewProps {
  imageDataURI: string;
  report: ReceiptTestReport;
}

function ReceiptReportView(props: ReceiptReportViewProps) {
  const { imageDataURI, report } = props;
  return (
    <div className={styles["report"]}>
      <div className={styles["image-pane"]}>
        <img src={imageDataURI} alt="" />
      </div>
      <div className={styles["info-pane"]}>
        <FieldAttr
          labelId="receipt_test.amount"
          value={report.amount ? `$${report.amount.toFixed(2)}` : ""}
        />
        <FieldAttr labelId="receipt_test.date" value={report.date} />
        <FieldAttr labelId="receipt_test.time" value={report.time} />
        <div className={styles["field-attr"]}>
          <label>
            <FormattedMessage id={"receipt_test.tokens"} />
          </label>
          <ul className={styles["token-list"]}>
            {report.tokens.map((token, i) => (
              <li key={i}>{token}</li>
            ))}
          </ul>
        </div>
        <div className={styles["field-attr"]}>
          <label>
            <FormattedMessage id={"receipt_test.custom_fields"} />
          </label>
          <ul className={styles["custom-field-list"]}>
            {report.custom_fields.map((field, i) => (
              <li key={i}>
                <span>{field.name}</span>
                <span>{field.value}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

interface FieldAttrProps {
  labelId: string;
  value: string;
}

function FieldAttr(props: FieldAttrProps) {
  return (
    <div className={styles["field-attr"]}>
      <label>
        <FormattedMessage id={props.labelId} />
      </label>
      <span>{props.value}</span>
    </div>
  );
}

export const ReceiptTest = React.memo(_ReceiptTest);
export default ReceiptTest;
