import { ICommandBarItemProps } from "@fluentui/react";
import copy from "copy-to-clipboard";
import React from "react";

import { DetectMultiDocumentAPIDescription } from "../apidoc/detect_documents";
import APINote from "../components/APINote";
import DetectMultiDocumentNavBar from "../components/DetectMultiDocumentNavBar";
import { Layout, Main, Top } from "../components/Layout";
import HeaderContainer from "../containers/Header";
import { useLocale } from "../contexts/locale";
import { useWorkerToken } from "../hooks/app";
import { useToast } from "../hooks/toast";

export function useCommandBarItems(token?: string) {
  const { localized } = useLocale();
  const toast = useToast();

  const onCopyToken = React.useCallback(() => {
    copy(token || "");
    toast.success("ocrapi.token_copied");
  }, [toast, token]);

  return React.useMemo(
    (): ICommandBarItemProps[] => [
      {
        key: "copy-access-token",
        text: localized("command_bar_items.copy_access_token"),
        iconProps: { iconName: "Signin" },
        onClick: onCopyToken,
      },
    ],
    [onCopyToken, localized]
  );
}

function _DetectMultiDocumentAPIContainer() {
  const { token } = useWorkerToken();
  const commandBarItems = useCommandBarItems(token);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <DetectMultiDocumentNavBar commbarBarItems={commandBarItems}>
          <APINote
            getDescription={() => DetectMultiDocumentAPIDescription}
            shouldShowAsync={false}
            shouldShowMultiDocument={false}
          />
        </DetectMultiDocumentNavBar>
      </Main>
    </Layout>
  );
}

export const DetectMultiDocumentAPIContainer = React.memo(
  _DetectMultiDocumentAPIContainer
);
export default DetectMultiDocumentAPIContainer;
