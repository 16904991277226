import React from "react";
import { useParams } from "react-router";

import { getExtractAPIDescription } from "../apidoc/extract";
import APINote from "../components/APINote";
import { CustomModelNavBarLayout } from "../components/CustomModelNavBar";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { useAPICommandBarItems } from "../hooks/command_bar_items";
import { useCommonCustomModelContainerState } from "../hooks/custom_model";

type PathParam = {
  customModelId: string;
};

function _CustomModelAPIContainer() {
  const { customModelId } = useParams<PathParam>();

  const containerState = useCommonCustomModelContainerState(customModelId);

  const commandBarItems = useAPICommandBarItems(
    ...(containerState.state === "success" && containerState.customModel.formID
      ? [containerState.customModel.formID, containerState.workerToken]
      : [undefined, undefined])
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        ) : containerState.state === "success" ? (
          <CustomModelNavBarLayout
            customModel={containerState.customModel}
            commbarBarItems={commandBarItems}
          >
            <APINote
              getDescription={getExtractAPIDescription}
              shouldShowAsync={true}
              shouldShowMultiDocument={true}
            />
          </CustomModelNavBarLayout>
        ) : null}
      </Main>
    </Layout>
  );
}

export const CustomModelAPIContainer = React.memo(_CustomModelAPIContainer);
export default CustomModelAPIContainer;
