import { DefaultButton, IButtonProps, PrimaryButton } from "@fluentui/react";
import * as React from "react";

import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { useLocale } from "../../contexts/locale";

interface Props extends Omit<IButtonProps, "text"> {
  textId: string;
  accept?: string;
  onFiles?: (files?: File[]) => void;
}

const FILE_INPUT_ID = "formx_file_input_id";

export function chooseFile(
  accept: string = SUPPORTED_IMAGE_MIME.join(",")
): Promise<File[] | undefined> {
  return new Promise((resolve, _reject) => {
    const existingInputElement = document.getElementById(FILE_INPUT_ID);
    const isCreatingInputElement = existingInputElement === null;
    const inputElement = (existingInputElement ||
      document.createElement("input")) as HTMLInputElement;

    if (isCreatingInputElement) {
      inputElement.id = FILE_INPUT_ID;
      inputElement.type = "file";
      inputElement.style.display = "none";
      document.body.appendChild(inputElement);
    } else {
      // Make sure the old values are cleared
      inputElement.value = "";
    }

    inputElement.accept = accept;
    inputElement.onchange = (e: Event) => {
      if (e.target instanceof HTMLInputElement) {
        const { files } = e.target;

        if (files) {
          const fileArray: File[] = [];
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < files.length; i++) {
            fileArray.push(files[i]);
          }
          resolve(fileArray);
        } else {
          resolve(undefined);
        }
      }
    };

    inputElement.click();
  });
}

function FileButtonWrapper(
  Component: React.ComponentType<IButtonProps>
): React.FC<Props> {
  return function WrappedFileButton(props: Props) {
    const { accept, onFiles, textId, ...rest } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { localized } = useLocale();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const onButtonClick = React.useCallback(() => {
      chooseFile(accept || SUPPORTED_IMAGE_MIME.join(","))
        .then(onFiles)
        .catch(() => {});
    }, [accept, onFiles]);

    return (
      <Component text={localized(textId)} {...rest} onClick={onButtonClick} />
    );
  };
}

export const FileDefaultButton = FileButtonWrapper(DefaultButton);
export const FilePrimaryButton = FileButtonWrapper(PrimaryButton);
