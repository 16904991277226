import {
  DirectionalHint,
  ITooltipHostStyles,
  Icon,
  IconButton,
  TooltipHost,
  getTheme,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import classnames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

import { LABELLER_PADDING, ToolBoxTutorialTargetIds } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { useDetectionRegionFieldTypeEngineMap } from "../../hooks/detection_region_field";
import { useTeamPermission } from "../../hooks/permission";
import { AnchorToolHandler } from "../../labeller/anchor_tool";
import { DetectionRegionToolHandler } from "../../labeller/detection_region_tool";
import { FieldToolHandler } from "../../labeller/field_tool";
import { InteractionHandler } from "../../labeller/handler";
import { Renderer } from "../../labeller/renderer";
import {
  SelectToolHandler,
  SelectToolSelection,
} from "../../labeller/select_tool";
import { CanvasStore } from "../../labeller/store";
import { Tutorial } from "../../models";
import { LabellerToolBarTutorial } from "../../tutorials/labellerToolBar";
import { Anchor } from "../../types/anchor";
import { DetectionRegion } from "../../types/detectionRegion";
import { Field } from "../../types/field";
import styles from "./styles.module.scss";

type ToolLabel =
  | "select-tool"
  | "anchor-tool"
  | "field-tool"
  | "detection-region-tool";

interface Props {
  canvasStore: CanvasStore;
  imageURL: string;
  width: number;
  height: number;
  anchors: Anchor[];
  fields: Field[];
  detectionRegions: DetectionRegion[];
  onSelectAnchor: (anchorId?: string) => void;
  onSelectField: (fieldId?: string) => void;
  onSelectDetectionRegion: (detectionRegionId?: string) => void;
  setCurrentTutorial: (tutorial: Tutorial) => void;
  selectedAnchorId: string | undefined;
  selectedFieldId: string | undefined;
  selectedDetectionRegionId: string | undefined;
}

function useInteractionHandler(
  canvas: HTMLCanvasElement | undefined,
  canvasStore: CanvasStore,
  width: number,
  height: number,
  onSelectAnchor: Props["onSelectAnchor"],
  onSelectField: Props["onSelectField"],
  onSelectDetectionRegion: Props["onSelectDetectionRegion"]
) {
  const detectionRegionFieldTypeEngineMap =
    useDetectionRegionFieldTypeEngineMap();

  const canvasRef = useRef<HTMLCanvasElement | undefined>(canvas);
  canvasRef.current = canvas;

  const canvasStoreRef = useRef<CanvasStore>(canvasStore);
  canvasStoreRef.current = canvasStore;

  const { hasPermissionToEditResource } = useTeamPermission();

  const selectToolHandlerRef = React.useRef(
    new SelectToolHandler(
      {
        onSelectAnchor: onSelectAnchor,
        onSelectField: onSelectField,
        onSelectDetectionRegion: onSelectDetectionRegion,
      },
      { width, height },
      hasPermissionToEditResource
    )
  );

  const anchorToolHandlerRef = React.useRef(
    new AnchorToolHandler({ onCreateAnchor: () => {} })
  );

  const fieldToolHandlerRef = React.useRef(new FieldToolHandler());

  const detectionRegionToolHandlerRef = React.useRef(
    new DetectionRegionToolHandler({
      onCreateDetectionRegion: _detectionRegionId => {},
      engineByFieldTypeMap: detectionRegionFieldTypeEngineMap,
    })
  );

  const interactionHandlerRef = React.useRef<InteractionHandler>(
    selectToolHandlerRef.current
  );

  const changeInteractionHandler = useCallback(
    (newHandler: InteractionHandler) => {
      if (canvasRef.current && newHandler !== interactionHandlerRef.current) {
        interactionHandlerRef.current.uninstall(canvasRef.current);
        newHandler.install(canvasRef.current);
        interactionHandlerRef.current = newHandler;
      }
    },
    []
  );

  const installInteractionHandler = useCallback((canvas: HTMLCanvasElement) => {
    interactionHandlerRef.current.install(canvas);
  }, []);

  useEffect(() => {
    selectToolHandlerRef.current.setStore(canvasStoreRef.current);
    fieldToolHandlerRef.current.setStore(canvasStoreRef.current);
    anchorToolHandlerRef.current.setStore(canvasStoreRef.current);
    detectionRegionToolHandlerRef.current.setStore(canvasStoreRef.current);
  }, []);

  useEffect(() => {
    const currentCanvas = canvas;
    return () => {
      if (currentCanvas) {
        interactionHandlerRef.current.uninstall(currentCanvas);
      }
    };
  }, [canvas]);

  return React.useMemo(
    () => ({
      selectToolHandlerRef,
      anchorToolHandlerRef,
      fieldToolHandlerRef,
      detectionRegionToolHandlerRef,
      changeInteractionHandler,
      installInteractionHandler,
    }),
    [changeInteractionHandler, installInteractionHandler]
  );
}

function useToolbox(
  selectToolHandlerRef: React.MutableRefObject<SelectToolHandler>,
  anchorToolHandlerRef: React.MutableRefObject<AnchorToolHandler>,
  fieldToolHandlerRef: React.MutableRefObject<FieldToolHandler>,
  detectionRegionToolHandlerRef: React.MutableRefObject<DetectionRegionToolHandler>,
  changeInteractionHandler: (handler: InteractionHandler) => void
) {
  const [selectedTool, setSelectedTool] = useState<ToolLabel>("select-tool");

  const chooseSelectTool = useCallback(
    (selection?: SelectToolSelection) => {
      if (selectedTool !== "select-tool") {
        setSelectedTool("select-tool");
        changeInteractionHandler(selectToolHandlerRef.current);
        if (selection) selectToolHandlerRef.current.doSelection(selection);
      }
    },
    [changeInteractionHandler, selectToolHandlerRef, selectedTool]
  );

  const chooseAnchorTool = useCallback(() => {
    if (selectedTool !== "anchor-tool") {
      setSelectedTool("anchor-tool");
      changeInteractionHandler(anchorToolHandlerRef.current);
    }
  }, [changeInteractionHandler, anchorToolHandlerRef, selectedTool]);

  const chooseFieldTool = useCallback(() => {
    if (selectedTool !== "field-tool") {
      setSelectedTool("field-tool");
      changeInteractionHandler(fieldToolHandlerRef.current);
    }
  }, [changeInteractionHandler, fieldToolHandlerRef, selectedTool]);

  const chooseDetectionRegionTool = useCallback(() => {
    if (selectedTool !== "detection-region-tool") {
      setSelectedTool("detection-region-tool");
      changeInteractionHandler(detectionRegionToolHandlerRef.current);
      detectionRegionToolHandlerRef.current.onCreateDetectionRegion =
        detectionRegionId => {
          chooseSelectTool({ detectionRegionId });
        };
    }
  }, [
    changeInteractionHandler,
    detectionRegionToolHandlerRef,
    chooseSelectTool,
    selectedTool,
  ]);

  return React.useMemo(
    () => ({
      selectedTool,
      chooseSelectTool,
      chooseAnchorTool,
      chooseFieldTool,
      chooseDetectionRegionTool,
    }),
    [
      selectedTool,
      chooseSelectTool,
      chooseAnchorTool,
      chooseFieldTool,
      chooseDetectionRegionTool,
    ]
  );
}

function useCanvasRenderer(
  canvasStore: CanvasStore,
  selectedAnchorId: string | undefined,
  selectedFieldId: string | undefined,
  selectedDetectionRegionId: string | undefined,
  selectedTool: ToolLabel,
  width: number,
  height: number
) {
  const canvasStoreRef = useRef<CanvasStore>(canvasStore);
  canvasStoreRef.current = canvasStore;

  const rendererRef = useRef<Renderer>();

  const renderCanvas = useCallback(() => {
    if (rendererRef.current) {
      rendererRef.current.render(
        canvasStoreRef.current,
        selectedAnchorId,
        selectedFieldId,
        selectedDetectionRegionId
      );
    }
  }, [selectedAnchorId, selectedFieldId, selectedDetectionRegionId]);

  const renderCanvasRef = React.useRef(renderCanvas);
  renderCanvasRef.current = renderCanvas;

  const setupCanvasRenderer = useCallback(
    (ref: HTMLCanvasElement) => {
      rendererRef.current = new Renderer(ref);
      renderCanvasRef.current();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, height]
  );

  useEffect(() => {
    const render = () => renderCanvasRef.current();

    canvasStoreRef.current.delegates.push({
      anchorVertexAdded: render,
      creatingFieldUpdated: render,
      creatingDetectionRegionUpdated: render,
      loaded: render,
    });

    render();
  }, []);

  useEffect(() => {
    renderCanvas();
  }, [renderCanvas, selectedTool]);

  return setupCanvasRenderer;
}

function _Labeller(props: Props) {
  const {
    canvasStore,
    imageURL,
    width,
    height,
    fields,
    setCurrentTutorial,
    selectedAnchorId,
    selectedFieldId,
    selectedDetectionRegionId,
    onSelectAnchor,
    onSelectField,
    onSelectDetectionRegion,
  } = props;

  const [canvas, setCanvas] = useState<HTMLCanvasElement>();

  const {
    selectToolHandlerRef,
    anchorToolHandlerRef,
    fieldToolHandlerRef,
    detectionRegionToolHandlerRef,
    changeInteractionHandler,
    installInteractionHandler,
  } = useInteractionHandler(
    canvas,
    canvasStore,
    width,
    height,
    onSelectAnchor,
    onSelectField,
    onSelectDetectionRegion
  );

  const {
    selectedTool,
    chooseSelectTool,
    chooseAnchorTool,
    chooseFieldTool,
    chooseDetectionRegionTool,
  } = useToolbox(
    selectToolHandlerRef,
    anchorToolHandlerRef,
    fieldToolHandlerRef,
    detectionRegionToolHandlerRef,
    changeInteractionHandler
  );

  const setupCanvasRenderer = useCanvasRenderer(
    canvasStore,
    selectedAnchorId,
    selectedFieldId,
    selectedDetectionRegionId,
    selectedTool,
    width,
    height
  );

  const setCanvasContext = useCallback(
    (ref: HTMLCanvasElement | null) => {
      if (ref) {
        setCanvas(ref);
        installInteractionHandler(ref);
        setupCanvasRenderer(ref);
      }
    },
    [installInteractionHandler, setupCanvasRenderer]
  );

  const startLabellerToolTutorial = useCallback(() => {
    setCurrentTutorial(LabellerToolBarTutorial);
  }, [setCurrentTutorial]);

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["labeller"]}>
      <div
        className={styles["canvas"]}
        style={{
          backgroundImage: `url("${imageURL}")`,
          padding: LABELLER_PADDING,
          width: `${width + LABELLER_PADDING * 2}px`,
          height: `${height + LABELLER_PADDING * 2}px`,
        }}
      >
        <canvas width={width} height={height} ref={setCanvasContext} />
      </div>
      {hasPermissionToEditResource && (
        <div className={styles["floating-left"]}>
          <Icon
            id={ToolBoxTutorialTargetIds.InfoIcon}
            className={classnames(styles["info"])}
            iconName="Info"
            onClick={startLabellerToolTutorial}
          />

          <div className={styles["toolbox"]}>
            <Tool
              id={ToolBoxTutorialTargetIds.SelectToolBox}
              name="select-tool"
              isSelected={selectedTool === "select-tool"}
              onClick={chooseSelectTool}
            />
            <Tool
              id={ToolBoxTutorialTargetIds.AnchorToolBox}
              name="anchor-tool"
              isSelected={selectedTool === "anchor-tool"}
              onClick={chooseAnchorTool}
            />
            {/* doing this to make sure the last item ends with rounded corner, 
        will remove once field is deprecated */}
            <div style={{ display: fields.length > 0 ? "block" : "none" }}>
              <Tool
                name="field-tool"
                isSelected={selectedTool === "field-tool"}
                onClick={chooseFieldTool}
              />
            </div>
            <Tool
              id={ToolBoxTutorialTargetIds.DetectionRegionToolBox}
              name="detection-region-tool"
              isSelected={selectedTool === "detection-region-tool"}
              onClick={chooseDetectionRegionTool}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export const Labeller = React.memo(_Labeller);
export default Labeller;

interface ToolProps {
  name: ToolLabel;
  isSelected: boolean;
  onClick: () => void;
  id?: string;
}

function Tool(props: ToolProps) {
  const { id, name, isSelected, onClick } = props;

  let iconName = "";
  let iconColor = "";
  let tooltipText = "";
  let directionalHint: DirectionalHint = DirectionalHint.topRightEdge;

  switch (name) {
    case "select-tool":
      iconName = "TouchPointer";
      iconColor = "#ffffff";
      directionalHint = DirectionalHint.rightTopEdge;
      tooltipText = "form_inspector.detection_region.tooltip.pointer";
      break;
    case "anchor-tool":
      iconName = "MapPin";
      iconColor = "#e95c5c";
      directionalHint = DirectionalHint.rightCenter;
      tooltipText = "form_inspector.detection_region.tooltip.anchor_region";
      break;
    case "field-tool":
      iconName = "TextField";
      iconColor = "#6db76d";
      directionalHint = DirectionalHint.rightCenter;
      tooltipText = "form_inspector.detection_region.tooltip.field_tool";
      break;
    default:
      iconName = "TextBox";
      iconColor = "#6888fa";
      directionalHint = DirectionalHint.rightBottomEdge;
      tooltipText = "form_inspector.detection_region.tooltip.detection_region";
      break;
  }

  const tooltipId = useId(id || uuid());
  const toolTipStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };
  const calloutProps = { gapSpace: -5, isBeakVisible: false };
  const { localized } = useLocale();

  return (
    <TooltipHost
      content={localized(tooltipText)}
      id={tooltipId}
      calloutProps={calloutProps}
      directionalHint={directionalHint}
      styles={toolTipStyles}
    >
      <IconButton
        id={id}
        className={styles["tool"]}
        onClick={onClick}
        styles={{
          icon: { color: iconColor },
          root: {
            borderRadius: 0,
          },
          rootChecked: {
            backgroundColor: getTheme().palette.themePrimary,
          },
        }}
        iconProps={{ iconName }}
        checked={isSelected}
      />
    </TooltipHost>
  );
}
