import {
  applyMiddleware,
  combineReducers,
  createStore as createReduxStore,
} from "redux";

import { reducer as adminReducer } from "../reducers/admin";
import { reducer as appReducer } from "../reducers/app";
import { reducer as customModelReducer } from "../reducers/customModel";
import { reducer as formReducer } from "../reducers/form";
import { reducer as formGroupReducer } from "../reducers/formGroup";
import { reducer as receiptGroupReducer } from "../reducers/receiptGroup";
import { reducer as resourceOwnerReducer } from "../reducers/resourceOwner";
import { reducer as tutorialReducer } from "../reducers/tutorial";
import { reducer as userReducer } from "../reducers/user";
import { reducer as webhookReducer } from "../reducers/webhook";
import thunk from "./thunk";
import { RootAction, RootState, Store } from "./types";

export default function createStore(): Store {
  return createReduxStore(
    combineReducers({
      app: appReducer,
      user: userReducer,
      resourceOwner: resourceOwnerReducer,
      form: formReducer,
      receiptGroup: receiptGroupReducer,
      formGroup: formGroupReducer,
      tutorial: tutorialReducer,
      customModel: customModelReducer,
      webhook: webhookReducer,
      admin: adminReducer,
    }),
    applyMiddleware(thunk<RootState, RootAction>())
  );
}
