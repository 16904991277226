@import "../../styles/variables.scss";

$step_counter_color: #25d0b1;

.container {
  padding: 36px 44px;
  counter-reset: step;
  .step {
    position: relative;
    max-width: 568px;
    margin-left: 14px;
    padding-left: 28px;
    padding-bottom: 36px;
    border-left: 1px solid #e1dfdd;

    .title {
      margin-bottom: 12px;
    }
    .description {
      color: #605e5c;
      padding: 0 0 12px 0;
      line-height: 24px;

      b {
        font-weight: 600;
      }

      code {
        background-color: $neutral-lighter;
        border-radius: 4px;
        padding: 0 4px;
        font-family: monospace;
      }
    }
    .action-button {
      font-size: 14px;
      font-weight: 600;
      border: 1.5px solid #8a8886;
      margin: 12px 0;

      :global(div.ms-Spinner) {
        margin-right: 8px;
      }
    }

    .action-button:disabled {
      border: 0;

      :global(div.ms-Spinner) {
        margin-right: 8px;
        filter: grayscale(1);
      }
    }
  }

  .step::before {
    counter-increment: step;
    content: "0" counter(step);

    position: absolute;
    display: block;
    left: -15px;
    top: -1px;
    width: 24px;
    height: 24px;

    border-radius: 50%;
    border: 2px solid $step_counter_color;

    background-color: white;
    color: $step_counter_color;

    line-height: 24px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    user-select: none;
  }

  .step:first-of-type {
    margin-top: 31px;
  }

  .step:last-child {
    border-left: 0;
  }
}
