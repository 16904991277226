import { LocalizerValue } from "./models";

export class FOCRError {
  messageId: string;
  detail?: LocalizerValue;

  constructor(messageId: string, detail?: LocalizerValue) {
    this.messageId = messageId;
    this.detail = detail;
  }
}

export enum ApiError {
  UnexpectedError = 100,
  NotAuthenticated = 101,
  PasswordIsWeak = 201,
  InvalidCredential = 202,
  AuthIdentityAlreadyExists = 203,
  PasswordIsSame = 205,
  PasswordIsReused = 206,
  AccountLocked = 207,
  PasswordExpired = 208,
  UserNotFound = 2001,
  UserAlreadyExists = 2008,
  TeamUserAlreadyExists = 13002,
  TeamLookupIDAlreadyExist = 13005,
  TeamInvitationNotFound = 15001,
  TeamInvitationAlreadyExists = 15002,
  TeamInvitationEmailNotMatch = 15003,
  TeamInvitationInvalid = 15004,
  ConflictFound = 3005,
  AnchorNotFound = 4001,
  FieldNotFound = 5001,
  DetectionRegionNotFound = 7001,
  DeleteFallbackFormFailure = 3006,
}

export enum WorkerError {
  InvalidToken = 1001,
  FormIdNotFound = 1002,
  FormImageURLNotFound = 1003,
  CannotLoadImage = 1004,
  UploadedFileTooLarge = 1005,
  ErrorPaymentRequired = 1006,
  TooManyRequest = 1007,
  UsageReachedHardLimit = 1008,
  ImageDimensionExceedLimit = 1009,
  FormNotFound = 2001,
  FormNotReady = 2002,
  FormUnmatchedQueryImage = 2003,
  ErrorDuringExtractingFormInfo = 2004,
  FormGroupNotFound = 2005,
  FormGroupIsEmpty = 2006,
  ReceiptGroupIdNotFound = 3001,
  GoogleCloudVisionServiceAccountKeyIsNotSet = 3002,
  ErrorDuringExtractingReceiptInfo = 3003,
  ErrorDuringAccessingGoogleVisionAPI = 3004,
  AzureComputerVisionSettingIsNotSet = 3005,
}

export enum PluginError {
  ReceiptGroupReachedLimit = 6002,
  StripeCardError = 9003,
  StripeError = 9004,
}

export enum AuditLogError {
  AuditLogNotFound = 18001,
}

const errors = {
  DuplicatedUser: new FOCRError("error.duplicated_user"),
  UserNotFound: new FOCRError("error.user_not_found"),
  UnknownError: new FOCRError("error.unknown_error"),
  IncorrectPassword: new FOCRError("error.incorrect_password"),
  IncorrectEmailOrPassword: new FOCRError("error.incorrect_email_or_password"),
  CannotGetImageDimension: new FOCRError("error.cannot_get_image_dimension"),
  FormImageSizeTooLarge: new FOCRError("error.form_image_size_too_large"),
  PasswordTooSimple: new FOCRError("error.password_too_simple"),
  PasswordIsSame: new FOCRError("error.password_is_same"),
  PasswordIsReused: new FOCRError("error.password_is_reused"),
  AccountLocked: new FOCRError("error.account_locked"),
  PasswordExpired: new FOCRError("error.password_expired"),
  ConflictFound: new FOCRError("error.conflict_found"),
  InvalidToken: new FOCRError("error.invalid_token"),
  FormIdNotFound: new FOCRError("error.form_id_not_found"),
  FormImageURLNotFound: new FOCRError("error.form_compute_feature_no_image"),
  CannotLoadImage: new FOCRError("error.cannot_load_image"),
  ImageDimensionExceedLimit: new FOCRError(
    "error.image_dimension_exceed_limit"
  ),
  UploadedFileTooLarge: new FOCRError("error.uploaded_file_too_large"),
  FormNotFound: new FOCRError("error.form_not_found"),
  FormNotReady: new FOCRError("error.form_not_ready"),
  FormUnmatchedQueryImage: new FOCRError("error.form_unmatched_query_image"),
  ExtractionTimeout: new FOCRError("error.extraction_timeout"),
  ErrorDuringExtractingFormInfo: new FOCRError(
    "error.error_during_extracting_form_info"
  ),
  FormGroupNotFound: new FOCRError("error.form_group_not_found"),
  FormGroupIsEmpty: new FOCRError("error.form_group_is_empty"),
  ReceiptGroupIdNotFound: new FOCRError("error.receipt_group_id_not_found"),
  GoogleCloudVisionServiceAccountKeyIsNotSet: new FOCRError(
    "error.google_service_account_key_is_not_set"
  ),
  ErrorDuringExtractingReceiptInfo: new FOCRError(
    "error.error_during_extract_receipt_info"
  ),
  ErrorDuringAccessingGoogleVisionAPI: new FOCRError(
    "error.error_during_accessing_google_vision_api"
  ),
  AzureComputerVisionSettingIsNotSet: new FOCRError(
    "error.azure_computer_vision_setting_is_not_set"
  ),
  ErrorPaymentRequired: new FOCRError("error.payment.required"),
  TooManyRequest: new FOCRError("error.too_many_request"),
  UsageReachedHardLimit: new FOCRError("error.usage_reached_hard_limit"),
  AnchorNotFound: new FOCRError("error.anchor_not_found"),
  FieldNotFound: new FOCRError("error.field_not_found"),
  DetectionRegionNotFound: new FOCRError("error.detection_region_not_found"),
  DeleteFallbackFormFailure: new FOCRError(
    "error.delete_fallback_form_failure"
  ),
  CreatePaymentMethodFailure: new FOCRError(
    "error.create_payment_method_failure"
  ),
  StripeKeyIsEmpty: new FOCRError("error.stripe.key.empty"),
  StripeLoadFailure: new FOCRError("error.stripe.load.failure"),

  StripeError: new FOCRError("error.stripe.error"),

  StripeCardProcessingError: new FOCRError(
    "errors.stripe.card.processing_error"
  ),
  StripeCardDeclinedError: new FOCRError("errors.stripe.card.card_declined"),
  StripeCardIncorrectCvcError: new FOCRError(
    "errors.stripe.card.incorrect_cvc"
  ),
  StripeCardExpiredError: new FOCRError("errors.stripe.card.expired_card"),

  UnexpectedServerResponseError: new FOCRError(
    "error.unexpected_server_response"
  ),

  TeamUserAlreadyExists: new FOCRError("error.invite.team_user_already_exists"),
  TeamLookupIDAlreadyExists: new FOCRError(
    "error.team.lookup_id_already_exists"
  ),
  TeamInvitationAlreadyExists: new FOCRError(
    "error.invite.invitation_already_exists"
  ),
  TeamInvitationEmailNotMatch: new FOCRError(
    "error.invite.invitation_email_not_match"
  ),
  TeamInvitationInvalid: new FOCRError("error.invite.invalid_invitation"),
  TeamInvitationNotFound: new FOCRError("error.invite.not_found"),
  UserAlreadyExists: new FOCRError("error.user_already_exists"),
  ConfirmationRejected: new FOCRError("error.user_rejected_confirmation"),
  AuditLogNotFound: new FOCRError("error.audit_log.not_found"),
};

export const WorkerErrorMap: { [key: number]: FOCRError } = {
  [WorkerError.InvalidToken]: errors.InvalidToken,
  [WorkerError.FormIdNotFound]: errors.FormIdNotFound,
  [WorkerError.FormImageURLNotFound]: errors.FormImageURLNotFound,
  [WorkerError.ImageDimensionExceedLimit]: errors.ImageDimensionExceedLimit,
  [WorkerError.CannotLoadImage]: errors.CannotLoadImage,
  [WorkerError.UploadedFileTooLarge]: errors.UploadedFileTooLarge,
  [WorkerError.FormNotFound]: errors.FormNotFound,
  [WorkerError.FormNotReady]: errors.FormNotReady,
  [WorkerError.FormUnmatchedQueryImage]: errors.FormUnmatchedQueryImage,
  [WorkerError.ErrorDuringExtractingFormInfo]:
    errors.ErrorDuringExtractingFormInfo,
  [WorkerError.FormGroupNotFound]: errors.FormGroupNotFound,
  [WorkerError.FormGroupIsEmpty]: errors.FormGroupIsEmpty,
  [WorkerError.ReceiptGroupIdNotFound]: errors.ReceiptGroupIdNotFound,
  [WorkerError.GoogleCloudVisionServiceAccountKeyIsNotSet]:
    errors.GoogleCloudVisionServiceAccountKeyIsNotSet,
  [WorkerError.ErrorDuringExtractingReceiptInfo]:
    errors.ErrorDuringExtractingReceiptInfo,
  [WorkerError.ErrorDuringAccessingGoogleVisionAPI]:
    errors.ErrorDuringAccessingGoogleVisionAPI,
  [WorkerError.AzureComputerVisionSettingIsNotSet]:
    errors.AzureComputerVisionSettingIsNotSet,
  [WorkerError.ErrorPaymentRequired]: errors.ErrorPaymentRequired,
  [WorkerError.TooManyRequest]: errors.TooManyRequest,
  [WorkerError.UsageReachedHardLimit]: errors.UsageReachedHardLimit,
};

export default errors;

export const ApiErrorMap: { [key in ApiError]?: FOCRError } = {
  [ApiError.AuthIdentityAlreadyExists]: errors.DuplicatedUser,
  [ApiError.InvalidCredential]: errors.IncorrectEmailOrPassword,
  [ApiError.PasswordIsWeak]: errors.PasswordTooSimple,
  [ApiError.PasswordIsSame]: errors.PasswordIsSame,
  [ApiError.PasswordIsReused]: errors.PasswordIsReused,
  [ApiError.AccountLocked]: errors.AccountLocked,
  [ApiError.PasswordExpired]: errors.PasswordExpired,
  [ApiError.UnexpectedError]: errors.UnknownError,
  [ApiError.TeamUserAlreadyExists]: errors.TeamUserAlreadyExists,
  [ApiError.TeamLookupIDAlreadyExist]: errors.TeamLookupIDAlreadyExists,
  [ApiError.TeamInvitationAlreadyExists]: errors.TeamInvitationAlreadyExists,
  [ApiError.TeamInvitationEmailNotMatch]: errors.TeamInvitationEmailNotMatch,
  [ApiError.TeamInvitationInvalid]: errors.TeamInvitationInvalid,
  [ApiError.TeamInvitationNotFound]: errors.TeamInvitationNotFound,
  [ApiError.ConflictFound]: errors.ConflictFound,
  [ApiError.AnchorNotFound]: errors.AnchorNotFound,
  [ApiError.FieldNotFound]: errors.FieldNotFound,
  [ApiError.DetectionRegionNotFound]: errors.DetectionRegionNotFound,
  [ApiError.DeleteFallbackFormFailure]: errors.DeleteFallbackFormFailure,
  [ApiError.UserNotFound]: errors.UserNotFound,
  [ApiError.UserAlreadyExists]: errors.UserAlreadyExists,
};
