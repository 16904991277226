@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.receipt-group-editor {
  padding: 10px;
  padding-top: 0;
  height: 100%;
  overflow: auto;

  h1 {
    padding: 10px 0;
    font-size: 18px;
    margin: 10px 20px;
    border-bottom: 1px solid #ccc;
  }

  .description {
    margin-bottom: 20px;
    font-size: 12pt;
    line-height: 1.5em;
  }

  .section {
    padding: 0 30px;
    margin-bottom: 20px;
  }

  .group-name {
    font-size: 16px;
    line-height: 20pt;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .tag-list {
    margin-top: 18px;
    margin-bottom: 12px;

    display: flex;
    flex-wrap: wrap;
    .token-card {
      @include cardBoxBorderRadius;
      margin: 8px;
    }

    .text-token-card {
      background-color: #f3f2f1;
      display: flex;
      flex-direction: row;
      align-items: center;

      padding: 8px;
      padding-left: 12px;
      margin-right: 8px;
      height: 32px;

      > span {
        white-space: nowrap;
        font-size: 14px;
        padding-right: 6px;
      }
    }
  }

  .custom-field-list {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    margin-top: 18px;
    margin-bottom: 12px;
    flex-wrap: wrap;

    .custom-field-item {
      min-width: 300px;
      margin-right: 12px;
      width: inherit;
    }
  }

  div.empty-state {
    margin-bottom: 12px;
    font-weight: bold;
  }
}
