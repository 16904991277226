@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.key-value-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 24px 10px 8px;

  .button {
    margin-top: 15px;
    align-self: flex-start;
  }
}
