import { Stripe, loadStripe } from "@stripe/stripe-js";

import { STRIPE_PUBLISHABLE_KEY } from "../config";
import errors from "../errors";

let stripePromise: Promise<Stripe> | null = null;

export function getStripe() {
  if (!STRIPE_PUBLISHABLE_KEY) {
    return Promise.reject(errors.StripeKeyIsEmpty);
  } else if (stripePromise !== null) {
    return stripePromise;
  } else {
    stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY).then(
      (stripe: Stripe | null) => {
        if (stripe === null) {
          throw errors.StripeLoadFailure;
        }
        return stripe;
      }
    );
    return stripePromise;
  }
}

export function getStripeError(errorCode: string) {
  switch (errorCode) {
    case "incomplete_number":
    case "invalid_number":
      return "error.subscription.stripe.invalid.card.number";
    case "incomplete_expiry":
    case "invalid_expiry_year_past":
    case "invalid_expiry_month_past":
      return "error.subscription.stripe.invalid.expiry.date";
    case "incomplete_cvc":
      return "error.subscription.stripe.invalid.cvc";
    case "incomplete_zip":
      return "error.subscription.stripe.invalid.postal.code";
    default:
      return "error.subscription.stripe.invalid.default";
  }
}
