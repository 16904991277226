export enum UserFeatureFlag {
  All = "all",
  AutoExtractionItemCompany = "ner.company",
  AutoExtractionItemNERAddress = "ner.address",
  AutoExtractionItemNERName = "ner.name",
  AutoExtractionItemDateInAddressProof = "date_in_address_proof",
  CustomModelTraining = "custom_model_training",
  TableExtraction = "table_extraction",
  Invoice = "invoice",
  InternationalId = "international_id",
  Signature = "signature",
  PostProcessScript = "post_process_script",
}

export const RESTRICTED_DOCUMENT_TYPES_FLAGS = new Map([
  ["invoice", UserFeatureFlag.Invoice],
  ["international_id", UserFeatureFlag.InternationalId],
]);

export const RESTRICTED_AUTO_EXTRACTION_ITEMS_FLAGS = new Map([
  [
    "general",
    new Map([
      ["company", UserFeatureFlag.AutoExtractionItemCompany],
      ["table_extraction", UserFeatureFlag.TableExtraction],
      ["signature", UserFeatureFlag.Signature],
    ]),
  ],
  [
    "address_proof",
    new Map([
      ["ner_name", UserFeatureFlag.AutoExtractionItemNERName],
      ["ner_address", UserFeatureFlag.AutoExtractionItemNERAddress],
      ["date", UserFeatureFlag.AutoExtractionItemDateInAddressProof],
    ]),
  ],
  ["invoice", new Map([["table_extraction", UserFeatureFlag.TableExtraction]])],
]);

export const RESTRICTED_DETECTION_REGION_FIELD_TYPE_FLAGS = new Map([
  ["table", UserFeatureFlag.TableExtraction],
]);
