import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import CustomModelEditorImageGrid from "../components/CustomModelEditorImageGrid";
import CustomModelEditorRightBar from "../components/CustomModelEditorRightBar";
import CustomModelEditorRightBarV2 from "../components/CustomModelEditorRightBarV2";
import CustomModelEditorUploadPane from "../components/CustomModelEditorUploadPane";
import { CustomModelNavBarLayout } from "../components/CustomModelNavBar";
import CustomModelRemarkModal from "../components/CustomModelRemarkModal";
import CustomModelRenameModal from "../components/CustomModelRenameModal";
import CustomModelSampleUploadButton from "../components/CustomModelSampleUploadButton";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Right, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { UserFeatureFlag } from "../constants";
import HeaderContainer from "../containers/Header";
import {
  CustomModelEditorProvider,
  useCustomModelEditor,
} from "../contexts/customModelEditor";
import { useDelayFirstTransition } from "../hooks/common";
import { useTeamPermission } from "../hooks/permission";
import { RootState } from "../redux/types";

type PathParam = {
  customModelId: string;
};

function CustomModelEditor() {
  const {
    customModel,
    isImageGridVisible,
    InvisibleUploadImageButton,
    customModelRemarkModalProps,
    isCustomModelFetchError,
  } = useCustomModelEditor();

  const isFeatureEnabled = useSelector((state: RootState) =>
    state.resourceOwner.isFeatureEnabled()
  );

  const isLoading = useDelayFirstTransition(
    customModel.id === "" && !isCustomModelFetchError
  );

  const [isRenameDialogOpened, setIsRenameDialogOpened] =
    useState<boolean>(false);
  const onRenameDialogClosed = useCallback(() => {
    setIsRenameDialogOpened(false);
  }, []);
  const openRenameDialog = useCallback(() => {
    setIsRenameDialogOpened(true);
  }, []);

  const { hasPermissionToEditResource } = useTeamPermission();

  return isLoading ? (
    <LoadingModal isOpen={true} />
  ) : isCustomModelFetchError ? (
    <Main hasTop={true}>
      <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
    </Main>
  ) : (
    <>
      <Main hasTop={true} hasRight={isImageGridVisible}>
        <CustomModelNavBarLayout
          customModel={customModel}
          openRenameDialog={openRenameDialog}
          shouldHideTabBar={
            !isImageGridVisible ||
            !isFeatureEnabled(UserFeatureFlag.CustomModelTraining)
          }
        >
          {isImageGridVisible ? (
            <>
              <CustomModelEditorImageGrid />
              {hasPermissionToEditResource && <CustomModelSampleUploadButton />}
            </>
          ) : (
            <CustomModelEditorUploadPane />
          )}
        </CustomModelNavBarLayout>
      </Main>
      {isImageGridVisible && (
        <Right>
          {isFeatureEnabled(UserFeatureFlag.CustomModelTraining) ? (
            <CustomModelEditorRightBarV2 />
          ) : (
            <CustomModelEditorRightBar />
          )}
        </Right>
      )}
      <InvisibleUploadImageButton />
      <CustomModelRenameModal
        isOpen={isRenameDialogOpened}
        onClose={onRenameDialogClosed}
        originalName={customModel.name}
      />
      <CustomModelRemarkModal {...customModelRemarkModalProps} />
    </>
  );
}

function _CustomModelEditorContainer() {
  const { customModelId } = useParams<PathParam>();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <CustomModelEditorProvider assignedCustomModelId={customModelId}>
        <CustomModelEditor />
      </CustomModelEditorProvider>
    </Layout>
  );
}

export const CustomModelEditorContainer = React.memo(
  _CustomModelEditorContainer
);
export default CustomModelEditorContainer;
