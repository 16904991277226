import {
  Breadcrumb,
  IBreadcrumbItem,
  Icon,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import * as React from "react";
import { useHistory } from "react-router";

import { useLocale } from "../../contexts/locale";
import { useLocalizeRegion } from "../../hooks/app";
import { TeamDetail } from "../../types/team";
import TeamDetailMemberList from "../TeamDetailMemberList";
import TeamDetailPlan from "../TeamDetailPlan";
import TeamDetailResources from "../TeamDetailResources";
import TeamDetailUsage from "../TeamDetailUsage";
import styles from "./styles.module.scss";

interface Props {
  region: string;
  team: TeamDetail;
  exportForm: (formId: string) => void;
  exportFormGroup: (formGroupId: string) => void;
  presetGiveTrialModel: () => void;
  presetClearTrialModel: () => void;
}

enum TeamDetailTab {
  usage = "usage",
  members = "members",
  resources = "resources",
  plan = "plan",
}

const TAB_VALUES = Object.keys(TeamDetailTab);

function isOfTeamDetailTab(keyInput: string): keyInput is TeamDetailTab {
  return TAB_VALUES.includes(keyInput);
}

function _TeamDetailLayout(props: Props) {
  const {
    region,
    team,
    exportForm,
    exportFormGroup,
    presetGiveTrialModel,
    presetClearTrialModel,
  } = props;
  const { localized } = useLocale();
  const localizeRegion = useLocalizeRegion();
  const [currentTab, setCurrentTab] = React.useState<TeamDetailTab>(
    TeamDetailTab.usage
  );
  const history = useHistory();

  const onBreadcrumbItemClick = React.useCallback(
    (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      if (ev === undefined || item === undefined || item.href === undefined) {
        return;
      }
      ev.preventDefault();
      history.push(item.href);
    },
    [history]
  );

  const breadcrumbItems: IBreadcrumbItem[] = React.useMemo(() => {
    return [
      {
        text: localized("team.list.title"),
        key: "teams",
        href: `/admin/team${history.location.search}`,
        onClick: onBreadcrumbItemClick,
      },
      {
        text: localized("team.detail.title"),
        key: "detail",
      },
    ];
  }, [localized, history, onBreadcrumbItemClick]);

  const onPivotItemClick = React.useCallback(
    (item?: PivotItem, e?: React.MouseEvent<HTMLElement>) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (item && item.props.itemKey && isOfTeamDetailTab(item.props.itemKey)) {
        setCurrentTab(item.props.itemKey);
      }
    },
    []
  );

  return (
    <div className={styles["team-detail"]}>
      <Breadcrumb className={styles["nav-bar"]} items={breadcrumbItems} />
      <div className={styles["team-info"]}>
        <div className={styles["team-icon"]}>
          <Icon iconName="People" />
        </div>
        <div className={styles["team-info-text-container"]}>
          <div>{team.name}</div>
          <div>{team.contactEmail}</div>
          <div className={styles["team-info-detail-columns"]}>
            <div className={styles["team-info-detail-column"]}>
              <div>
                {localized("team.detail.region")}
                {": "}
                {localizeRegion(region)}
              </div>
              <div>
                {localized("team.detail.lookup_id")}
                {": "}
                {team.lookupId}
              </div>
              <div>
                {localized("team.detail.created_at")}
                {": "}
                {team.createdAt.toLocaleDateString()}{" "}
                {team.createdAt.toLocaleTimeString()}
              </div>
            </div>
            <div className={styles["team-info-detail-column"]}>
              <div>
                {localized("team.detail.total_usage")}
                {": "}
                {team.totalUsage}
              </div>
              {team.lastUseAt && (
                <div>
                  {localized("team.detail.last_usage_at")}
                  {": "}
                  {team.lastUseAt.toLocaleDateString()}{" "}
                  {team.lastUseAt.toLocaleTimeString()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles["tab-bar"]}>
        <Pivot onLinkClick={onPivotItemClick} selectedKey={currentTab}>
          {TAB_VALUES.map(tab => {
            return (
              <PivotItem
                key={tab}
                headerText={localized(`team.detail.${tab}`)}
                itemKey={tab}
              />
            );
          })}
        </Pivot>
      </div>
      <div className={styles["tabs"]}>
        {currentTab === TeamDetailTab.usage && (
          <TeamDetailUsage region={region} teamId={team.id} />
        )}
        {currentTab === TeamDetailTab.members && (
          <TeamDetailMemberList region={region} teamId={team.id} />
        )}
        {currentTab === TeamDetailTab.resources && (
          <TeamDetailResources
            region={region}
            teamId={team.id}
            exportForm={exportForm}
            exportFormGroup={exportFormGroup}
          />
        )}
        {currentTab === TeamDetailTab.plan && (
          <TeamDetailPlan
            region={region}
            team={team}
            presetGiveTrialModel={presetGiveTrialModel}
            presetClearTrialModel={presetClearTrialModel}
          />
        )}
      </div>
    </div>
  );
}

export const TeamDetailLayout = React.memo(_TeamDetailLayout);
export default TeamDetailLayout;
