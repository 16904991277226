import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
  TextField,
  Toggle,
} from "@fluentui/react";
import classnames from "classnames";
import produce from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CUSTOM_FIELD_POSITION_MENU_OPTIONS } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { CustomField } from "../../types/receiptGroup";
import RegexBuilder from "../RegexBuilder";
import styles from "./styles.module.scss";

interface Props {
  mode: "edit" | "create";
  existingFieldNames: string[];
  isOpen: boolean;
  customField?: CustomField;

  onCancel(): void;
  onSubmit(customField: CustomField, originalName?: string): void;
}

interface BaseProps extends Props {
  titleId: string;
}

const EmptyCustomField = {
  name: "",
  token: "",
  pattern: "",
  use_fuzzy_search: true,
  position: "right" as any,
};

const ModalBase = React.memo((props: BaseProps) => {
  const {
    customField: defaultCustomField,
    existingFieldNames,
    onSubmit,
    mode,
    onCancel,
    isOpen,
    titleId,
  } = props;
  const { localized } = useLocale();

  const [customField, setCustomField] = useState<CustomField>(EmptyCustomField);
  const [isRegexBuilderShown, setIsRegexBuilderShown] = useState(false);

  const [keyErrorMessageId, setKeyErrorMessageId] = useState<
    string | undefined
  >();
  const [valueErrorMessageId, setValueErrorMessageId] = useState<
    string | undefined
  >();
  const [patternErrorMessageId, setPatternErrorMessageId] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (defaultCustomField) {
      setCustomField(defaultCustomField);
    }
  }, [defaultCustomField]);

  const validate = useCallback(() => {
    let isValid = true;

    const originalFieldName = defaultCustomField
      ? defaultCustomField.name
      : undefined;

    if (customField.name.trim().length === 0) {
      setKeyErrorMessageId("key_value.modal.label.empty_error");
      isValid = false;
    }

    if (
      customField.name.trim() !== originalFieldName &&
      existingFieldNames.indexOf(customField.name.trim()) !== -1
    ) {
      setKeyErrorMessageId("key_value.modal.label.duplicated_error");
      isValid = false;
    }

    if (customField.token.trim().length === 0) {
      setValueErrorMessageId("key_value.modal.key.empty_error");
      isValid = false;
    }

    try {
      (() => new RegExp(customField.pattern))();
    } catch (err) {
      setPatternErrorMessageId("key_value.modal.pattern.invalid_regex_error");
      isValid = false;
    }

    return isValid;
  }, [
    customField.name,
    customField.pattern,
    customField.token,
    defaultCustomField,
    existingFieldNames,
  ]);

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!validate()) {
        return;
      }

      onSubmit(
        customField,
        defaultCustomField ? defaultCustomField.name : undefined
      );
    },
    [customField, defaultCustomField, onSubmit, validate]
  );

  const onKeyChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      event.stopPropagation();
      event.preventDefault();
      if (newValue !== undefined) {
        setCustomField(
          produce(customField, draftCustomField => {
            draftCustomField.name = newValue;
          })
        );

        setKeyErrorMessageId(undefined);
      }
    },
    [customField]
  );

  const onValueChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      event.stopPropagation();
      event.preventDefault();
      if (newValue !== undefined) {
        setCustomField(
          produce(customField, draftCustomField => {
            draftCustomField.token = newValue;
          })
        );
        setValueErrorMessageId(undefined);
      }
    },
    [customField]
  );

  const onPatternChange = useCallback(
    (
      event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (newValue !== undefined) {
        setCustomField(
          produce(customField, draftCustomField => {
            draftCustomField.pattern = newValue;
          })
        );
        setPatternErrorMessageId(undefined);
      }
    },
    [customField]
  );

  const onUseFuzzySearchChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
      event.preventDefault();
      event.stopPropagation();
      if (checked !== undefined) {
        setCustomField(
          produce(customField, draftCustomField => {
            draftCustomField.use_fuzzy_search = checked;
          })
        );
      }
    },
    [customField]
  );

  const onPositionChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option) {
        setCustomField(
          produce(customField, draftCustomField => {
            draftCustomField.position = option.key as any;
          })
        );
      }
    },
    [customField]
  );

  const onApply = (regex: string) => {
    onPatternChange(undefined, regex);
    setIsRegexBuilderShown(false);
  };

  const onClickBuilder = () => {
    setIsRegexBuilderShown(true);
  };

  const onRegexBuilderCancel = () => {
    setIsRegexBuilderShown(false);
  };

  const onDismissed = useCallback(() => {
    setCustomField(EmptyCustomField);
    setKeyErrorMessageId(undefined);
    setValueErrorMessageId(undefined);
    setPatternErrorMessageId(undefined);
    setIsRegexBuilderShown(false);
  }, []);

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(`${titleId}.${mode}`),
    }),
    [localized, mode, titleId]
  );

  return (
    <>
      <Dialog
        minWidth={500}
        hidden={!isOpen}
        onDismiss={onCancel}
        modalProps={modalProps}
        dialogContentProps={dialogContentProps}
      >
        <form className={styles["modal"]} onSubmit={_onSubmit}>
          <TextField
            className={styles["input-field"]}
            label={localized("key_value.modal.label")}
            onChange={onKeyChange}
            value={customField.name}
            errorMessage={keyErrorMessageId && localized(keyErrorMessageId)}
          />

          <TextField
            className={styles["input-field"]}
            label={localized("key_value.modal.key")}
            placeholder={localized("key_value.modal.key.placeholder")}
            onChange={onValueChange}
            value={customField.token}
            errorMessage={valueErrorMessageId && localized(valueErrorMessageId)}
          />

          <Toggle
            className={styles["input-field"]}
            label={localized("key_value.modal.enable_fuzzy_search")}
            onText={localized("common.on")}
            offText={localized("common.off")}
            checked={customField.use_fuzzy_search}
            onChange={onUseFuzzySearchChange}
          />
          <Dropdown
            className={styles["input-field"]}
            label={localized("edit_custom_field.position")}
            options={CUSTOM_FIELD_POSITION_MENU_OPTIONS.map(
              ({ key, text }) => ({
                key,
                text: localized(text),
              })
            )}
            selectedKey={customField.position}
            onChange={onPositionChange}
          />
          <div
            className={classnames(styles["input-field"], styles["pattern-row"])}
          >
            <TextField
              className={styles["regex-input"]}
              label={localized("key_value.modal.pattern")}
              onChange={onPatternChange}
              value={customField.pattern}
              errorMessage={
                patternErrorMessageId && localized(patternErrorMessageId)
              }
            />
            <DefaultButton
              className={styles["build-button"]}
              text={localized("key_value.modal.pattern_builder")}
              onClick={onClickBuilder}
            />
          </div>

          <DialogFooter>
            <DefaultButton
              onClick={onCancel}
              text={localized("common.cancel")}
            />
            <PrimaryButton
              type="submit"
              text={localized(`edit_custom_field.submit.${mode}`)}
            />
          </DialogFooter>
        </form>
        <RegexBuilder
          isOpen={isRegexBuilderShown}
          onApply={onApply}
          onCancel={onRegexBuilderCancel}
        />
      </Dialog>
    </>
  );
});

const KeyValueModal = React.memo((props: Props) => {
  return <ModalBase titleId={"key_value_modal.title"} {...props} />;
});

const CustomFieldModal = React.memo((props: Props) => {
  return <ModalBase titleId={"edit_custom_field.title"} {...props} />;
});

export { KeyValueModal, CustomFieldModal };
