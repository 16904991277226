@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.payment {
  min-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 10px;

  h3 {
    padding: 10px 0;
    font-size: 18px;
    margin: 10px 0;
    border-bottom: 1px solid #ccc;
  }

  h4 {
    padding: 8px 0 4px;
    margin-bottom: 8px;
    font-size: 16px;
    border-bottom: 1px solid #ccc;

    span {
      font-size: 12px;
      padding-left: 8px;
      font-weight: normal;
    }
  }

  .button-container {
    display: flex;
    margin: 25px 0px;
    flex-direction: row;
    justify-content: flex-start;
  }

  .section {
    margin: 20px;
    margin-top: 0;

    .description {
      padding-top: 12px;
      font-size: 14px;
    }

    .payment-info {
      max-width: 365px;

      .header {
        margin-top: 15px;
      }
    }
  }

  .plan-container {
    display: flex;
    flex-direction: row;
  }

  .plan-box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    @include cardBoxBorderRadius();
    @include ms-depth-4;

    width: 250px;
    padding: 8px 12px;
    margin-right: 32px;
    h3 {
      margin-bottom: 8px;
      > span {
        margin-left: 8px;
        color: $alert-color;
        font-size: 14px;
      }
    }

    .description {
      font-size: 12px;
      margin-bottom: 10px;
    }

    .price {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .details {
      font-size: 12px;

      ul {
        padding-left: 20px;
        line-height: 1.5em;
      }
    }
  }

  .plan-box-grayed {
    background-color: rgba($color: #000, $alpha: 0.05);
    color: rgba($color: #000, $alpha: 0.3);
    h3 {
      border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
    }
  }

  .no-left-margin {
    margin-left: 0 !important;
  }

  .card-input {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 4px 10px;

    :global(.StripeElement) {
      box-sizing: border-box;
      height: 40px;
      width: 400px;
      padding: 10px 12px;

      border: 1px solid transparent;
      @include cardBoxBorderRadius();

      background-color: white;

      @include ms-depth-4;
      -webkit-transition: box-shadow 150ms ease;
      transition: box-shadow 150ms ease;
    }

    :global(.StripeElement--focus) {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }

    :global(.StripeElement--invalid) {
      border-color: #fa755a;
    }

    :global(.StripeElement--webkit-autofill) {
      background-color: #fefde5 !important;
    }
    .card-errors {
      font-size: 10pt;
      color: red;
      margin-top: 4px;
    }
  }

  .existing-card {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    width: 400px;
    @include cardBoxBorderRadius();

    margin: 4px 10px;
    padding: 12px 12px;
    @include ms-depth-4;

    .card-icon {
      width: 21px;
    }

    .last4 {
      color: #000;
      margin-left: 6px;
      font-size: 14px;
      font-family: sans-serif;
    }
  }

  .unsubscription-container {
    padding: 10px 12px;
  }

  .card-container-no-padding {
    position: relative;
  }

  .card-container {
    position: relative;
    padding-left: 30px;
  }

  .card-selected::before {
    @include icon-frame-size(24px);
    content: "";
    background-image: url("../../images/icon-green-tick.png");
    background-size: 16px;
    background-position: center;
    position: absolute;
    top: 10px;
    left: 6px;
    border-radius: 12px;
    background-color: #fff;
  }
}
