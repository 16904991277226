import { Dropdown, IDropdownOption, IDropdownProps } from "@fluentui/react";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { useReceiptGroups } from "../../hooks/receipt_groups";
import CreateReceiptGroupButton from "./CreateReceiptGroupButton";
import styles from "./styles.module.scss";

interface ReceiptDropdownOption extends IDropdownOption {
  type: "form" | "receipt_group";
}

const ReceiptDropdown = React.memo(() => {
  const { receiptGroups, forms, currentReceiptGroup, selectReceiptGroup } =
    useReceiptGroups();

  const { localized } = useLocale();

  const options = React.useMemo((): ReceiptDropdownOption[] => {
    return [...forms, ...receiptGroups].map(option => ({
      key: option.id,
      text: option.name,
      type: option.type,
    }));
  }, [receiptGroups, forms]);

  const onSelect = React.useCallback(
    (_, option: ReceiptDropdownOption | undefined) => {
      if (!option) return;
      if (option.type === "receipt_group") {
        const receiptGroup = receiptGroups.find(
          group => group.id === option.key
        );
        if (receiptGroup) {
          selectReceiptGroup(receiptGroup);
        }
      } else {
        const form = forms.find(form => form.id === option.key);
        if (form) {
          selectReceiptGroup(form);
        }
      }
    },
    [selectReceiptGroup, receiptGroups, forms]
  );

  const { hasPermissionToCreateResource } = useTeamPermission();

  return (
    <div className={styles["receipt-dropdown-container"]}>
      <Dropdown
        className={styles["receipt-dropdown"]}
        options={options}
        selectedKey={currentReceiptGroup && currentReceiptGroup.id}
        onChange={onSelect as IDropdownProps["onChange"]}
        placeholder={localized("receipt_dropdown.placeholder")}
      />
      {hasPermissionToCreateResource && <CreateReceiptGroupButton />}
    </div>
  );
});

export default ReceiptDropdown;
