import * as React from "react";
import { useSelector } from "react-redux";

import {
  createFormGroup,
  deleteFormGroup,
  importFormGroup,
  listFormGroups,
} from "../actions/formGroup";
import ConfirmModal from "../components/ConfirmModal";
import { CreateFormBarLayout } from "../components/CreateFormBar";
import CreateFormGroupModal from "../components/CreateFormGroupModal";
import { FormGroupGrid } from "../components/FormGroupGrid";
import { ImportFormGroupModal } from "../components/ImportFormGroupModal";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { useThunkDispatch } from "../hooks/thunk";
import { useToast } from "../hooks/toast";
import { RootState, ThunkActionCreatorType } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { BriefFormGroup, FormGroupType } from "../types/formGroup";

interface ReduxProps {
  formGroups: BriefFormGroup[];
  hasMore: boolean;
  isEmpty: boolean;
  listFormGroup: ThunkActionCreatorType<typeof listFormGroups>;
  createFormGroup: ThunkActionCreatorType<typeof createFormGroup>;
  deleteFormGroup: ThunkActionCreatorType<typeof deleteFormGroup>;
  importFormGroup: ThunkActionCreatorType<typeof importFormGroup>;
  resourceOwnerId?: string;
}

function useBindActionCreators() {
  return {
    listFormGroup: useThunkDispatch(listFormGroups),
    createFormGroup: useThunkDispatch(createFormGroup),
    deleteFormGroup: useThunkDispatch(deleteFormGroup),
    importFormGroup: useThunkDispatch(importFormGroup),
  };
}

function useRedux(): ReduxProps {
  const { formGroups, hasMore, isEmpty, resourceOwnerId } = useSelector(
    (state: RootState) => {
      const { formGroups, pageInfo } = state.formGroup;
      const { isLeftBarCollapsed } = state.app;
      const { resourceOwnerId } = state.resourceOwner;
      const hasMore = pageInfo ? pageInfo.hasNext : true;
      const isEmpty = !!(
        pageInfo &&
        !pageInfo.hasNext &&
        formGroups.length === 0
      );
      return {
        formGroups,
        isLeftBarCollapsed,
        hasMore,
        isEmpty,
        resourceOwnerId,
      };
    }
  );
  const { listFormGroup, deleteFormGroup, createFormGroup, importFormGroup } =
    useBindActionCreators();

  return {
    formGroups,
    hasMore,
    isEmpty,
    listFormGroup,
    createFormGroup,
    deleteFormGroup,
    importFormGroup,
    resourceOwnerId,
  };
}

const FormGridContainer = React.memo(() => {
  const {
    formGroups,
    hasMore,
    isEmpty,
    createFormGroup,
    deleteFormGroup,
    listFormGroup,
    importFormGroup,
    resourceOwnerId,
  } = useRedux();

  const toast = useToast();

  const [isCreatingFormGroup, setIsCreatingFormGroup] =
    React.useState<boolean>(false);
  const [isDeletingFormGroup, setIsDeletingFormGroup] =
    React.useState<boolean>(false);
  const [isCreateFormGroupModalOpened, setIsCreateFormGroupModalOpened] =
    React.useState<boolean>(false);
  const [isImportFormGroupModalOpened, setIsImportFormGroupModalOpened] =
    React.useState<boolean>(false);

  const [formGroupIdToDelete, setFormGroupIdToDelete] = React.useState<
    string | undefined
  >(undefined);

  const startImportFormGroup = React.useCallback(() => {
    setIsImportFormGroupModalOpened(true);
  }, []);

  const closeImportFormGroupModal = React.useCallback(() => {
    setIsImportFormGroupModalOpened(false);
  }, []);

  const startCreateFormGroup = React.useCallback(() => {
    setIsCreateFormGroupModalOpened(true);
  }, []);

  const closeCreateFormGroupModal = React.useCallback(() => {
    setIsCreateFormGroupModalOpened(false);
  }, []);

  const doCreateFormGroup = React.useCallback(
    async (name: string, type: FormGroupType) => {
      setIsCreatingFormGroup(true);
      closeCreateFormGroupModal();
      try {
        await createFormGroup(name, type);
      } catch (e) {
        console.error("Failed to create form group: ", e);
        toast.error("error.fail_to_create_form_group");
      } finally {
        setIsCreatingFormGroup(false);
      }
    },
    [createFormGroup, toast, closeCreateFormGroupModal]
  );

  const openDeleteFormGroupModal = React.useCallback((formGroupId: string) => {
    setFormGroupIdToDelete(formGroupId);
  }, []);

  const closeDeleteFormGroupModal = React.useCallback(() => {
    setFormGroupIdToDelete(undefined);
  }, []);

  const onImportFormGroupSuccess = React.useCallback(() => {
    closeImportFormGroupModal();
  }, [closeImportFormGroupModal]);

  const doConfirmDeleteFormGroup = React.useCallback(async () => {
    const formGroupId = formGroupIdToDelete;
    if (!formGroupId) return;
    closeDeleteFormGroupModal();
    try {
      setIsDeletingFormGroup(true);
      await deleteFormGroup(formGroupId);
    } catch (e) {
      console.error("Failed to delete form group: ", e);
      toast.error("error.error_during_remove_form_group");
    } finally {
      setIsDeletingFormGroup(false);
    }
  }, [formGroupIdToDelete, closeDeleteFormGroupModal, deleteFormGroup, toast]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <CreateFormBarLayout
          type="form-group"
          onCreate={startCreateFormGroup}
          onImport={startImportFormGroup}
        >
          <FormGroupGrid
            formGroups={formGroups}
            listFormGroup={listFormGroup}
            hasMore={hasMore}
            isEmpty={isEmpty}
            onDeleteFormGroup={openDeleteFormGroupModal}
          />
        </CreateFormBarLayout>

        <CreateFormGroupModal
          isOpen={isCreateFormGroupModalOpened}
          onCancel={closeCreateFormGroupModal}
          onCreate={doCreateFormGroup}
        />
        {isImportFormGroupModalOpened && (
          <ImportFormGroupModal
            resourceOwnerId={resourceOwnerId}
            isOpen={isImportFormGroupModalOpened}
            onCancel={closeImportFormGroupModal}
            onSuccess={onImportFormGroupSuccess}
            importFormGroup={importFormGroup}
          />
        )}
        <ConfirmModal
          isOpen={!!formGroupIdToDelete}
          modalType={ConfirmModalType.Destory}
          titleId="form_group_grid.delete_form"
          messageId="form_group_grid.are_you_sure_to_delete_form_group"
          actionId="form_group_grid.delete"
          onCancel={closeDeleteFormGroupModal}
          onConfirm={doConfirmDeleteFormGroup}
        />
        <LoadingModal
          isOpen={isCreatingFormGroup || isDeletingFormGroup}
          messageId={
            isCreatingFormGroup
              ? "form_group_grid.creating_form_group"
              : "form_group_grid.deleting_form_group"
          }
        />
      </Main>
    </Layout>
  );
});

export default FormGridContainer;
