import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useState } from "react";

import { useFormEditor } from "../../contexts/formEditor";
import { useTeamPermission } from "../../hooks/permission";
import { useToast } from "../../hooks/toast";
import { FilePrimaryButton } from "../FileButton";
import LoadingModal from "../LoadingModal";
import styles from "./styles.module.scss";

const NoFormImagePlaceholder = React.memo(() => {
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const { updateImage } = useFormEditor();

  const onFiles = useCallback(
    (files?: File[]) => {
      setIsLoading(true);

      if (!files || files[0] === undefined) {
        toast.error("form.editor.no.image.missing.image");
        return;
      }

      updateImage(files[0]).catch(() => {
        toast.error("error.master_image.cannot_load_image");
        setIsLoading(false);
      });
    },
    [toast, updateImage]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <>
      <div className={styles["content"]}>
        <Text variant="large" className={styles["instruction1"]}>
          <FormattedMessage id="form.editor.no.image.placeholder.instruction1" />
        </Text>
        <Text variant="medium" className={styles["instruction2"]}>
          <FormattedMessage id="form.editor.no.image.placeholder.instruction2" />
        </Text>
        {hasPermissionToEditResource && (
          <FilePrimaryButton
            textId={"form.editor.no.image.upload.button"}
            onFiles={onFiles}
          />
        )}
      </div>
      <LoadingModal
        messageId="form.editor.no.image.uploading"
        isOpen={isLoading}
      />
    </>
  );
});

export default NoFormImagePlaceholder;
