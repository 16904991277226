import {
  ActionButton,
  DefaultButton,
  Dropdown,
  Icon,
  Label,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import { UserFeatureFlag } from "../../constants";
import { useFormEditor } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useSettingTabPane } from "../../contexts/settingTabPane";
import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import { DetailedForm } from "../../types/form";
import { FileDefaultButton } from "../FileButton";
import LoadingModal from "../LoadingModal";
import SpinButton from "../WrappedMSComponents/SpinButton";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

interface Props {
  form: DetailedForm;
}

function useFormNameField() {
  const { formSettings, onFormNameChange } = useSettingTabPane();
  const [formName, setFormName] = React.useState(formSettings.name);

  const onFormNameFieldChange = React.useCallback(
    (_event: React.FormEvent<any>, newValue?: string) => {
      if (newValue !== undefined) {
        setFormName(newValue);
      }
    },
    []
  );

  const onFormNameFieldBlur = React.useCallback(
    (_event: React.FocusEvent<HTMLInputElement>) => {
      const newName = formName.trim();
      if (newName === "") {
        setFormName(formSettings.name);
      } else {
        onFormNameChange(newName);
      }
    },
    [formName, formSettings, onFormNameChange]
  );

  return {
    formName,
    onFormNameFieldChange,
    onFormNameFieldBlur,
  };
}

const SettingTabPane = React.memo((props: Props) => {
  const { form } = props;
  const { id } = form;
  const { localized } = useLocale();

  const { hideInV1Form } = useFormEditor();

  const isFeatureEnabled = useSelector((state: RootState) =>
    state.resourceOwner.isFeatureEnabled()
  );

  const {
    formSettings,
    isLoading,
    isExporting,
    isImporting,
    isAdvancedFormSettingVisible,
    onClickExportButton,
    onClickImportButton,
    onClickEditPostProcessScriptButton,
    onNumFormFeatureChange,
    onNumQueryFeatureChange,
    onImageFileChange,
    onAdvancedFormSettings,
    selectedCustomModel,
    customModelSelectionErrorMessage,
    onCustomModelSelectionChange,
    addCustomModelToForm,
    customModelOptions,
    hasCustomModel,
    existingCustomModelOptions,
    removeCustomModelFromForm,
    confirmModal,
  } = useSettingTabPane();

  const { formName, onFormNameFieldChange, onFormNameFieldBlur } =
    useFormNameField();

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["content"]}>
      <LoadingModal
        messageId="form.editor.no.image.uploading"
        isOpen={isLoading}
      />

      <div className={styles["form-id"]}>
        <Label>
          <FormattedMessage id="form_inspector.form_id" />
        </Label>
        <Text>{id}</Text>
      </div>

      {hasPermissionToEditResource ? (
        <TextField
          className={styles["input-field"]}
          labelId={"form_inspector.form_name"}
          value={formName}
          onBlur={onFormNameFieldBlur}
          onChange={onFormNameFieldChange}
        />
      ) : (
        <div className={styles["form-name"]}>
          <Label>
            <FormattedMessage id="form_inspector.form_name" />
          </Label>
          <Text>{formName}</Text>
        </div>
      )}

      {hasPermissionToEditResource &&
        hideInV1Form(
          form,
          <>
            <div className={styles["input-field"]}>
              <Label>
                <FormattedMessage id="setting.tab.replace_master_image" />
              </Label>

              <FileDefaultButton
                textId={"setting.tab.choose_a_file"}
                onFiles={onImageFileChange}
              />
            </div>
          </>
        )}

      <div
        className={styles["advanced-form-settings"]}
        onClick={onAdvancedFormSettings}
      >
        <Icon
          className={classnames(
            {
              [styles["expanded"]]: isAdvancedFormSettingVisible,
            },
            styles["advanced-form-settings-label"]
          )}
          iconName="ChevronDown"
        />
        <Label className={styles["advanced-form-settings-label"]}>
          <FormattedMessage id="setting.tab.advanced_form_settings" />
        </Label>
      </div>

      {isAdvancedFormSettingVisible && (
        <>
          <SpinButton
            className={styles["input-field"]}
            onChange={onNumFormFeatureChange}
            labelPosition={0}
            label={localized("form_inspector.num_form_feature")}
            value={formSettings.numFormFeature.toString()}
            min={100}
            max={1000000}
            disabled={!hasPermissionToEditResource}
          />

          <SpinButton
            className={styles["input-field"]}
            onChange={onNumQueryFeatureChange}
            labelPosition={0}
            label={localized("form_inspector.num_query_feature")}
            value={formSettings.numQueryFeature.toString()}
            min={100}
            max={1000000}
            disabled={!hasPermissionToEditResource}
          />
        </>
      )}

      {isAdvancedFormSettingVisible &&
        hasPermissionToEditResource &&
        isFeatureEnabled(UserFeatureFlag.PostProcessScript) && (
          <div className={styles["input-field"]}>
            <DefaultButton
              text={localized("setting.tab.edit_post_process_script")}
              onClick={onClickEditPostProcessScriptButton}
            />
          </div>
        )}

      {hasCustomModel && isFeatureEnabled(UserFeatureFlag.CustomModelTraining) && (
        <>
          <div className={styles["separator"]} />

          <div className={styles["custom-model-section"]}>
            <Label className={styles["custom_extraction_items_label"]}>
              <FormattedMessage id="setting.tab.custom_models" />
            </Label>
            <Dropdown
              disabled={customModelOptions.length === 0}
              selectedKey={selectedCustomModel}
              placeholder={localized(
                "setting.tab.custom_model.dropdown.placeholder"
              )}
              options={customModelOptions}
              onChange={onCustomModelSelectionChange}
              errorMessage={customModelSelectionErrorMessage}
            />
            <div className={styles["button-container"]}>
              <PrimaryButton
                disabled={customModelOptions.length === 0}
                className={styles["button"]}
                text={localized("setting.tab.custom_model.button.add")}
                onClick={addCustomModelToForm}
              />
            </div>

            {existingCustomModelOptions.map(option => (
              <div className={styles["custom-model-item"]} key={option.key}>
                <div className={styles["custom-model-name"]}>{option.text}</div>
                <ActionButton
                  iconProps={{ iconName: "Delete" }}
                  onClick={() => removeCustomModelFromForm(`${option.key}`)}
                />
              </div>
            ))}
          </div>
        </>
      )}

      <div className={styles["separator"]} />

      <div className={styles["input-field"]}>
        <Label>
          <FormattedMessage id="setting.tab.export_form" />
        </Label>
        <DefaultButton
          text={localized(
            isExporting ? "common.exporting" : "setting.tab.download_as_zip"
          )}
          iconProps={{ iconName: "Export" }}
          onClick={onClickExportButton}
          disabled={isExporting}
        />
      </div>

      {hasPermissionToEditResource && (
        <div className={styles["input-field"]}>
          <Label>
            <FormattedMessage id="setting.tab.import_form" />
          </Label>
          <DefaultButton
            text={localized(
              isImporting ? "common.importing" : "setting.tab.import_from_zip"
            )}
            iconProps={{ iconName: "Import" }}
            onClick={onClickImportButton}
            disabled={isImporting}
          />
        </div>
      )}

      {confirmModal}
    </div>
  );
});

export default SettingTabPane;
