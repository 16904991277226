.message-bar {
  position: absolute;
  max-width: 600px;
  min-width: 100px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 12px;
  z-index: 100;

  .upgrade-button {
    margin-top: 8px;
  }
}
