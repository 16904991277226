import { Dropdown, IDropdownOption, Icon, Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import { produce } from "immer";
import React, { Ref, useCallback, useImperativeHandle, useState } from "react";

import {
  AZURE_ENGINE_API_OPTIONS,
  DetectionRegionFieldTypeMap,
  EngineSpecificAdvanceSettingMap,
  GOOGLE_ENGINE_API_OPTIONS,
} from "../../constants";
import { useDetectionRegionInspector } from "../../contexts/detectionRegionInspector";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import {
  DetectionRegionFieldAdvancedSetting,
  DetectionRegionFieldEngine,
  DetectionRegionFieldParams,
  DetectionRegionFieldType,
} from "../../types/detectionRegion";
import { getArrayIntersection } from "../../utils/array";
import formInspectorStyles from "../FormInspectorV2/styles.module.scss";
import styles from "./styles.module.scss";

function getDetectionRegionFieldTypeAdvancedSettings(
  engine: DetectionRegionFieldEngine,
  fieldType: DetectionRegionFieldType
): undefined | DetectionRegionFieldAdvancedSetting[] {
  const settings =
    DetectionRegionFieldTypeMap[fieldType as DetectionRegionFieldType]
      .advancedSettings;

  if (settings === undefined || Array.isArray(settings)) {
    return settings;
  }

  return settings[engine];
}

interface AdvancedSettingApiSelectorProps {
  index: number;
  fieldType: DetectionRegionFieldType;
  engine: DetectionRegionFieldEngine;
  readOnly: boolean;
  api?: string;
  onFieldApiChange: (
    _e: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    _index?: number
  ) => void;
}

const DetectionRegionAdvancedSettingApiSelector = React.memo(
  ({
    fieldType,
    engine,
    api,
    onFieldApiChange,
    readOnly,
  }: AdvancedSettingApiSelectorProps) => {
    const { localized } = useLocale();
    const availableAdvancedSettings =
      getDetectionRegionFieldTypeAdvancedSettings(engine, fieldType);

    if (!availableAdvancedSettings) {
      return null;
    }

    return getArrayIntersection(
      EngineSpecificAdvanceSettingMap[engine],
      availableAdvancedSettings
    ).length > 0 ? (
      <Dropdown
        selectedKey={api}
        label={localized("form_inspector.detection_region_field_api")}
        options={
          engine === "google"
            ? GOOGLE_ENGINE_API_OPTIONS.map(x => ({
                key: x.label,
                text: x.value,
              }))
            : AZURE_ENGINE_API_OPTIONS.map(x => ({
                key: x.label,
                text: x.value,
              }))
        }
        onChange={onFieldApiChange}
        placeholder={localized("dropdown.placeholder")}
        disabled={readOnly}
      />
    ) : null;
  }
);

interface AdvancedSettingProps {
  fieldIndex: number;
  fieldType: DetectionRegionFieldType;
  fieldParams: DetectionRegionFieldParams | undefined;
  selectedDetectionRegionId: string;
  isAlwaysOpen?: boolean;
}

export interface DetectionRegionAdvancedSettingHandle {
  getParams: () => DetectionRegionFieldParams | undefined;
}

const DetectionRegionAdvancedSetting = React.forwardRef(
  (
    {
      fieldType,
      fieldParams,
      fieldIndex,
      selectedDetectionRegionId,
      isAlwaysOpen,
    }: AdvancedSettingProps,
    ref: Ref<DetectionRegionAdvancedSettingHandle>
  ) => {
    const [storedFieldParams, setStoredFieldParams] = useState<
      DetectionRegionFieldParams | undefined
    >(fieldParams);

    const getParams = useCallback(() => {
      return storedFieldParams;
    }, [storedFieldParams]);

    useImperativeHandle(ref, () => ({
      getParams,
    }));

    const {
      onAdvancedSettingsToggle,
      advancedSettingsVisibilities,
      detectionRegionFieldTypeEngineMap,
    } = useDetectionRegionInspector();

    const availableEngines = detectionRegionFieldTypeEngineMap[fieldType] || [];

    const isShowAdvancedSettings =
      isAlwaysOpen ||
      (Object.keys(advancedSettingsVisibilities).length > 0 &&
        advancedSettingsVisibilities[selectedDetectionRegionId][fieldIndex]);

    const engineOptions = availableEngines.map(engine => ({
      key: engine,
      text: engine,
    }));

    const onFieldEngineChange = useCallback(
      (
        _e: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        _index?: number
      ) => {
        if (!option) {
          return;
        }
        const engine = option.key as DetectionRegionFieldEngine;
        if (
          storedFieldParams === undefined ||
          storedFieldParams.engine === engine
        )
          return;

        setStoredFieldParams(
          produce(storedFieldParams, draft => {
            const api =
              engine === "google"
                ? GOOGLE_ENGINE_API_OPTIONS[0].label
                : engine === "azure"
                ? AZURE_ENGINE_API_OPTIONS[0].label
                : undefined;

            if (draft === undefined) {
              return {
                engine,
                api,
              };
            }
            draft.engine = engine;
            draft.api = api;
            return draft;
          })
        );
      },
      [setStoredFieldParams, storedFieldParams]
    );

    const onFieldApiChange = useCallback(
      (
        _e: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        _index?: number
      ) => {
        if (!option) {
          return;
        }
        setStoredFieldParams(
          produce(storedFieldParams, draft => {
            const api = option.key as string;
            if (draft === undefined) {
              return;
            }
            draft.api = api;
          })
        );
      },
      [setStoredFieldParams, storedFieldParams]
    );

    const { localized } = useLocale();

    const { hasPermissionToEditResource } = useTeamPermission();

    return (
      <>
        <div className={styles["advanced-settings"]}>
          {isAlwaysOpen ? (
            <Label>
              <FormattedMessage id="form_inspector.detection_region_field_advanced_settings" />
            </Label>
          ) : (
            <>
              <div className={styles["toggle-area"]}>
                <Icon
                  className={classnames({
                    [styles["expanded"]]: isShowAdvancedSettings,
                  })}
                  iconName="ChevronDown"
                  onClick={
                    onAdvancedSettingsToggle(
                      selectedDetectionRegionId,
                      fieldIndex
                    ) as any
                  }
                />
                <Label
                  onClick={
                    onAdvancedSettingsToggle(
                      selectedDetectionRegionId,
                      fieldIndex
                    ) as any
                  }
                >
                  <FormattedMessage id="form_inspector.detection_region_field_advanced_settings" />
                </Label>
              </div>
              <div className={styles["space"]} />
            </>
          )}
        </div>
        {isShowAdvancedSettings && (
          <div>
            <div className={formInspectorStyles["select-menu"]}>
              <Dropdown
                selectedKey={
                  storedFieldParams ? storedFieldParams.engine : null
                }
                label={localized(
                  "form_inspector.detection_region_field_engine"
                )}
                options={engineOptions}
                onChange={onFieldEngineChange}
                placeholder={localized("dropdown.placeholder")}
                disabled={!hasPermissionToEditResource}
              />
              {storedFieldParams && storedFieldParams.engine && (
                <DetectionRegionAdvancedSettingApiSelector
                  index={fieldIndex}
                  fieldType={fieldType}
                  engine={storedFieldParams.engine}
                  api={storedFieldParams.api}
                  onFieldApiChange={onFieldApiChange}
                  readOnly={!hasPermissionToEditResource}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default DetectionRegionAdvancedSetting;
