import {
  Webhook,
  WebhookList,
  webhookListSchema,
  webhookSchema,
} from "../../types/webhook";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface WebhookApiClient {
  createWebhook: (
    name: string,
    url: string,
    formIds: string[],
    formGroupIds: string[],
    resourceOwnerId?: string
  ) => Promise<Webhook>;
  listWebhooks: (resourceOwnerId: string) => Promise<WebhookList>;
  getWebhook: (webhookId: string, resourceOwnerId?: string) => Promise<Webhook>;
  updateWebhook: (
    webhookId: string,
    name: string,
    url: string,
    formIds: string[],
    formGroupIds: string[],
    retrievedAat?: string,
    resourceOwnerId?: string
  ) => Promise<Webhook>;
  deleteWebhook: (webhookId: string, resourceOwnerId?: string) => Promise<void>;
}

export function withWebhookApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listWebhooks(resourceOwnerId: string): Promise<WebhookList> {
      return this.lambda(
        "webhook:list",
        this.injectOptionalFields(
          {},
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        webhookListSchema,
        null
      );
    }

    async createWebhook(
      name: string,
      url: string,
      formIds: string[],
      formGroupIds: string[],
      resourceOwnerId?: string
    ): Promise<Webhook> {
      const args = this.injectOptionalFields(
        {
          name,
          url,
          form_ids: formIds,
          form_group_ids: formGroupIds,
        },
        {
          resource_owner_id: resourceOwnerId,
        }
      );

      return this.lambda("webhook:create", args, webhookSchema);
    }

    async getWebhook(
      webhookId: string,
      resourceOwnerId?: string
    ): Promise<Webhook> {
      return this.lambda<Webhook>(
        "webhook:get",
        this.injectOptionalFields(
          {
            webhook_id: webhookId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        webhookSchema
      );
    }

    async updateWebhook(
      webhookId: string,
      name: string,
      url: string,
      formIds: string[],
      formGroupIds: string[],
      retrievedAt?: string,
      resourceOwnerId?: string
    ): Promise<Webhook> {
      const args = this.injectOptionalFields(
        {
          webhook_id: webhookId,
          name,
          url,
          form_ids: formIds,
          form_group_ids: formGroupIds,
        },
        {
          resource_owner_id: resourceOwnerId,
          retrieved_at: retrievedAt,
        }
      );

      return this.lambda("webhook:update", args, webhookSchema);
    }

    async deleteWebhook(
      webhookId: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "webhook:delete",
        this.injectOptionalFields(
          {
            webhook_id: webhookId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }
  };
}
