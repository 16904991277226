@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 24px 10px 8px;

  .button {
    align-self: flex-end;
  }

  :global(.ms-GroupHeader) {
    flex: 1;
  }

  :global(.ms-GroupedList) {
    width: 100%;
    margin-top: 12px;

    .token-group-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $tab-bar-separator;

      .token-group-edit,
      .token-group-delete {
        cursor: pointer;
        width: 32px;
      }
    }

    :global(.ms-GroupHeader-title) {
      font-size: 16px;
    }
  }

  :global(.ms-GroupHeader):hover {
    background-color: unset;
  }

  :global(.ms-GroupHeader) > div {
    :global(.ms-GroupSpacer) {
      width: 0px !important;
    }

    :global(.ms-GroupHeader-title) {
      max-width: 240px;
      margin-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
