import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../redux/types";

export function useThunkDispatch<
  F extends (...args: any) => (...args2: any) => any
>(actionCreator: F): (...args: Parameters<F>) => ReturnType<ReturnType<F>> {
  const states = useSelector((state: RootState) => state);
  const statesRef = useRef(states);
  statesRef.current = states;
  const dispatch = useDispatch();

  return useCallback(
    (...args: Parameters<typeof actionCreator>) => {
      const fn = actionCreator(...(args as any));
      return fn.length > 1
        ? fn(dispatch, () => statesRef.current)
        : fn(dispatch);
    },

    [actionCreator, dispatch]
  );
}
