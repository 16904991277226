import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { AppConfig } from "../config";
import {
  DetectionRegionFieldTypeHierarchy,
  DetectionRegionFieldTypeHierarchySubType,
  DetectionRegionFieldTypeHierarchyType,
  DetectionRegionFieldTypeMap,
  RESTRICTED_DETECTION_REGION_FIELD_TYPE_FLAGS,
} from "../constants";
import { RootState } from "../redux/types";
import {
  DetectionRegionFieldEngine,
  DetectionRegionFieldType,
} from "../types/detectionRegion";
import {
  Abbyy,
  AzureComputerVision,
  GoogleCloudVision,
  Tesseract,
} from "../types/ocrConfig";
import { getArrayIntersection } from "../utils/array";
import { useUserSetting } from "./user";

export function useAvailableEngines() {
  const {
    isGoogleServiceAccoutKeySet,
    isAzureSubscriptionKeySet,
    isCustomPlan,
  } = useUserSetting();

  return useMemo(() => {
    const shouldIgnoreExternalServiceSettings = !isCustomPlan;
    const userAvailableEngines: DetectionRegionFieldEngine[] = [Tesseract];

    if (shouldIgnoreExternalServiceSettings || isGoogleServiceAccoutKeySet) {
      userAvailableEngines.push(GoogleCloudVision);
    }

    if (shouldIgnoreExternalServiceSettings || isAzureSubscriptionKeySet) {
      userAvailableEngines.push(AzureComputerVision);
    }

    if (AppConfig.enableAbbyy) {
      userAvailableEngines.push(Abbyy);
    }

    return userAvailableEngines;
  }, [isCustomPlan, isGoogleServiceAccoutKeySet, isAzureSubscriptionKeySet]);
}

export function useDetectionRegionFieldTypeOptions() {
  const availableEngines = useAvailableEngines();
  return useMemo(() => {
    return Object.entries(DetectionRegionFieldTypeMap).reduce(
      (acc: { key: string; text: string }[], [fieldType, config]) => {
        if (
          config.possibleEngines === undefined ||
          getArrayIntersection(config.possibleEngines, availableEngines)
            .length > 0
        ) {
          acc.push({
            key: fieldType,
            text: config.label,
          });
        }

        return acc;
      },
      []
    );
  }, [availableEngines]);
}

type DetectionRegionFieldTypeToEnginesMapType = {
  [key in DetectionRegionFieldType]: DetectionRegionFieldEngine[] | undefined;
};

export function useDetectionRegionFieldTypeEngineMap() {
  const availableEngines = useAvailableEngines();
  return useMemo(() => {
    return Object.entries(DetectionRegionFieldTypeMap).reduce(
      (acc: DetectionRegionFieldTypeToEnginesMapType, [fieldType, config]) => {
        acc[fieldType as DetectionRegionFieldType] =
          config.possibleEngines &&
          getArrayIntersection(config.possibleEngines, availableEngines);
        return acc;
      },
      {} as any
    );
  }, [availableEngines]);
}

export function useDetectionRegionFieldTypeHierarchy() {
  const isFeatureEnabled = useSelector((state: RootState) =>
    state.resourceOwner.isFeatureEnabled()
  );

  const findDetectionRegionFieldTypeHierarchySubTypeKeys = useCallback(
    (key: string) => {
      const index = DetectionRegionFieldTypeHierarchy.findIndex(
        h => h.key === key
      );
      if (index < 0) return [];

      return DetectionRegionFieldTypeHierarchy[index].options.map(
        (option: DetectionRegionFieldTypeHierarchySubType) => option.key,
        []
      );
    },
    []
  );

  const findDetectionRegionFieldTypeHierarchySubTypeKeyTexts = useCallback(
    (key: string) => {
      const index = DetectionRegionFieldTypeHierarchy.findIndex(
        h => h.key === key
      );
      if (index < 0) return [];

      return DetectionRegionFieldTypeHierarchy[index].options.map(
        (option: DetectionRegionFieldTypeHierarchySubType) => {
          const { key, label } = option;
          return {
            key,
            text: label,
          };
        },
        []
      );
    },
    []
  );

  const getDetectionRegionFieldTypeHierarchyKeyTexts = useCallback(
    () =>
      DetectionRegionFieldTypeHierarchy.map(
        (hier: DetectionRegionFieldTypeHierarchyType) => ({
          key: hier.key,
          text: hier.label,
        })
      ).filter(x => {
        const flag = RESTRICTED_DETECTION_REGION_FIELD_TYPE_FLAGS.get(x.key);
        return !flag || isFeatureEnabled(flag);
      }),
    [isFeatureEnabled]
  );

  const findDetectionRegionFieldTypeHierarchyRootKey = useCallback(
    (key: string) => {
      const roots = DetectionRegionFieldTypeHierarchy.filter(hier => {
        const options = hier.options.filter(options => {
          return options.key === key;
        });
        return options.length > 0;
      });

      if (roots.length === 0) {
        return "";
      }

      return roots[0].key;
    },
    []
  );

  const getSubKeysOfDetectionRegionFieldWithSetting = useCallback(
    () =>
      DetectionRegionFieldTypeHierarchy.filter(
        hierarchy =>
          hierarchy.isNoSetting === undefined || hierarchy.isNoSetting === false
      )
        .map(hierarchy => hierarchy.options.map(option => option.key))
        .reduce((acc, cur) => acc.concat(cur), []),
    []
  );

  const getSubTypeBySubKey = useCallback(subkey => {
    for (const hierachy of DetectionRegionFieldTypeHierarchy) {
      for (const subtype of hierachy.options) {
        if (subtype.key === subkey) {
          return subtype;
        }
      }
    }

    return undefined;
  }, []);

  return useMemo(
    () => ({
      findDetectionRegionFieldTypeHierarchySubTypeKeys,
      findDetectionRegionFieldTypeHierarchySubTypeKeyTexts,
      getDetectionRegionFieldTypeHierarchyKeyTexts,
      DetectionRegionFieldTypeHierarchy,
      findDetectionRegionFieldTypeHierarchyRootKey,
      getSubKeysOfDetectionRegionFieldWithSetting,
      getSubTypeBySubKey,
    }),
    [
      findDetectionRegionFieldTypeHierarchySubTypeKeys,
      findDetectionRegionFieldTypeHierarchySubTypeKeyTexts,
      getDetectionRegionFieldTypeHierarchyKeyTexts,
      findDetectionRegionFieldTypeHierarchyRootKey,
      getSubKeysOfDetectionRegionFieldWithSetting,
      getSubTypeBySubKey,
    ]
  );
}
