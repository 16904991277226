.description {
  margin-bottom: 20px;
}

.date-time-field-modal {
  :global .ms-ChoiceField {
    display: inline-block;
    margin-right: 40px;
    margin-top: 10px;
  }
}

.water-mark-setting {
  margin-top: 16px;
}
