import {
  Checkbox,
  ChoiceGroup,
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

const MIN_DATE = new Date();
const INITIAL_QUOTA = 100;

interface Props {
  isOpen: boolean;
  onCancel(): void;
  onGiveTrial(quota?: number, endTrialAt?: Date): void;
}

type TrialMode = "unlimited" | "quota";

const GiveTrialModal = (props: Props) => {
  const { onCancel, isOpen, onGiveTrial } = props;
  const [trialMode, setTrialMode] = React.useState<TrialMode>("unlimited");
  const [quota, setQuota] = React.useState<number>(INITIAL_QUOTA);
  const [isQuotaFieldValid, setIsQuotaFieldValid] =
    React.useState<boolean>(true);
  const [endAt, setEndAt] = React.useState<Date>(MIN_DATE);
  const [isTrialIndefinitely, setTrialIndefinitely] =
    React.useState<boolean>(true);

  const { localized } = useLocale();

  const onDismissed = useCallback(() => {
    setTrialMode("unlimited");
    setQuota(INITIAL_QUOTA);
    setIsQuotaFieldValid(true);
    setEndAt(MIN_DATE);
    setTrialIndefinitely(true);
  }, []);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      let submitQuota: number | undefined = undefined;
      let submitEndAt: Date | undefined = undefined;

      if (trialMode === "quota") {
        submitQuota = quota;
      }
      if (!isTrialIndefinitely) {
        submitEndAt = endAt;
      }

      onGiveTrial(submitQuota, submitEndAt);
    },
    [trialMode, quota, endAt, isTrialIndefinitely, onGiveTrial]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("team.detail.plan.give_trial"),
    }),
    [localized]
  );

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const options: IChoiceGroupOption[] = useMemo(() => {
    return [
      {
        key: "unlimited",
        text: localized("team.detail.plan.quota.unlimited"),
      },
      {
        key: "quota",
        text: localized("team.detail.quota"),
      },
    ];
  }, [localized]);

  const onTrialModelChange = useCallback(
    (
      _ev: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
      option?: IChoiceGroupOption
    ) => {
      if (option) {
        setTrialMode(option.key as TrialMode);
      }
    },
    []
  );

  const onQuotaChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value === undefined || isNaN(parseInt(value))) {
        return;
      }
      setQuota(parseInt(value));
      setIsQuotaFieldValid(true);
    },
    []
  );

  const onEndAtChange = useCallback((date?: Date | null) => {
    if (date) setEndAt(date);
  }, []);

  const onIsTrialDefinitely = useCallback(
    (
      event?: React.FormEvent<HTMLInputElement | HTMLElement>,
      checked?: boolean
    ) => {
      if (event === undefined || checked === undefined) {
        return;
      }
      event.stopPropagation();
      setTrialIndefinitely(checked);
    },
    []
  );

  const formatDate = useCallback((date?: Date) => {
    return date?.toLocaleDateString("en-GB") ?? "";
  }, []);

  return (
    <Dialog
      minWidth={400}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={onSubmit}>
        <ChoiceGroup
          defaultSelectedKey={trialMode}
          options={options}
          onChange={onTrialModelChange}
          label={localized("team.detail.plan.trial_model")}
        />
        {trialMode === "quota" && (
          <TextField
            label={localized("team.detail.plan.no_of_pages")}
            type="number"
            onChange={onQuotaChange}
            value={quota.toString()}
            errorMessage={
              isQuotaFieldValid
                ? undefined
                : localized("error.give_trial.empty_quota")
            }
          />
        )}
        {!isTrialIndefinitely && (
          <DatePicker
            label={localized("team.detail.end_at")}
            className={styles["give-trial-end-at"]}
            onSelectDate={onEndAtChange}
            value={endAt}
            minDate={MIN_DATE}
            formatDate={formatDate}
          />
        )}
        <Checkbox
          checked={isTrialIndefinitely}
          className={styles["give-trial-trial-indefinitely"]}
          label={localized("team.detail.plan.trial_indefinitely")}
          onChange={onIsTrialDefinitely}
        />
        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
          <PrimaryButton
            type="submit"
            text={localized("team.detail.plan.save")}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export default GiveTrialModal;
