@import "../../styles/variables.scss";

.upload-image-field {
  margin: 12px 0 16px;

  .file-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .file-name-container {
      position: relative;
      display: inline-block;
      border-bottom: 1px solid $text-field-border-color;
      height: 30px;
      width: 212px;
      padding-bottom: 2px;

      > span {
        width: 220px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: absolute;
        bottom: 4px;
      }
    }
  }
}

.invisible-image-upload-input {
  display: none;
}
