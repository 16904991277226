import {
  Dialog,
  DialogType,
  IDialogContentProps,
  IModalProps,
  Text,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { HELP_PAGE_URL } from "../../config";
import { useLocale } from "../../contexts/locale";
import newFormDiffLayoutIcon from "../../images/icon-new-form-diff-layout.svg";
import newFormFixedIcon from "../../images/icon-new-form-fixed.svg";
import newFormNonFixedIcon from "../../images/icon-new-form-non-fixed.svg";
import styles from "./styles.module.scss";

enum DocumentType {
  FixedFormat = "fixed-format",
  NonFixedFormat = "non-fixed-format",
  DiffLayoutFormat = "diff-layout-format",
}

const sessionTable = {
  "fixed-format": {
    title: "create_document_extractor.session_fixed_format.title",
    desc: "create_document_extractor.session_fixed_format.description",
    icon: newFormFixedIcon,
  },
  "non-fixed-format": {
    title: "create_document_extractor.session_non_fixed_format.title",
    desc: "create_document_extractor.session_non_fixed_format.description",
    icon: newFormNonFixedIcon,
  },
  "diff-layout-format": {
    title: "create_document_extractor.session_diff_layout_format.title",
    desc: "create_document_extractor.session_diff_layout_format.description",
    icon: newFormDiffLayoutIcon,
  },
};

const Session = (props: {
  format: DocumentType;
  onClick: (type: DocumentType) => void;
}) => {
  const { format, onClick } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { localized } = useLocale();

  const { title, desc, icon } = useMemo(() => {
    return sessionTable[format];
  }, [format]);

  const _onClick = useCallback(
    (e: MouseEvent) => {
      const element = e.target as HTMLElement;
      if (element.tagName === "A") {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      onClick(format);
    },
    [onClick, format]
  );

  const values: { [key in string]: any } = {
    keyValueLink: (
      <a
        href={`${HELP_PAGE_URL}features/key-value-pattern-detection`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {localized("create_document_extractor.link.key_value")}
      </a>
    ),
    readMore: (
      <a
        href={`${HELP_PAGE_URL}get-started/set-up-a-form-with-master-image`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {localized("create_document_extractor.link.read_more")}
      </a>
    ),
  };

  useEffect(() => {
    const { current } = ref;
    if (!current) {
      return;
    }

    current.addEventListener("click", _onClick);

    return () => {
      current.removeEventListener("click", _onClick);
    };
  }, [_onClick]);

  return (
    <div
      className={classnames(styles["session"], styles["session-" + format])}
      ref={ref}
    >
      <div className={styles["title"]}>
        <FormattedMessage id={title} />
      </div>
      <div className={styles["desc"]}>
        <Text variant="small">
          <FormattedMessage id={desc} values={values} />
        </Text>
      </div>
      <div className={styles["icon"]}>
        <img src={icon} alt="" />
      </div>
    </div>
  );
};

interface Props {
  isOpen: boolean;
  onCancel(): void;
  onFixedDocumentFormatChosen(): void;
  onNonFixedDocumentFormatChosen(): void;
  onDiffLayoutDocumentFormatChosen(): void;
}

const CreateDocumentExtractorModal = (props: Props) => {
  const {
    onCancel,
    isOpen,
    onFixedDocumentFormatChosen,
    onNonFixedDocumentFormatChosen,
    onDiffLayoutDocumentFormatChosen,
  } = props;

  const { localized } = useLocale();

  const onDismissed = useCallback(() => {}, []);

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("create_document_extractor.title"),
    }),
    [localized]
  );

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const onClick = useCallback(
    (type: DocumentType) => {
      switch (type) {
        case DocumentType.FixedFormat:
          onFixedDocumentFormatChosen();
          break;
        case DocumentType.NonFixedFormat:
          onNonFixedDocumentFormatChosen();
          break;
        case DocumentType.DiffLayoutFormat:
          onDiffLayoutDocumentFormatChosen();
          break;
        default:
          break;
      }
    },
    [
      onFixedDocumentFormatChosen,
      onNonFixedDocumentFormatChosen,
      onDiffLayoutDocumentFormatChosen,
    ]
  );

  return (
    <Dialog
      minWidth={560}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <Session format={DocumentType.FixedFormat} onClick={onClick} />
      <Session format={DocumentType.NonFixedFormat} onClick={onClick} />
      <Session format={DocumentType.DiffLayoutFormat} onClick={onClick} />
    </Dialog>
  );
};

export default CreateDocumentExtractorModal;
