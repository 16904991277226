import React, { useCallback, useEffect } from "react";

import { SUPPORTED_IMAGE_MIME } from "../constants";
import { createOnDrop, onDragOver } from "../utils/file";

export function useDragAndDrop(_onDrop: (e: React.DragEvent) => void) {
  const onDrop = useCallback(_onDrop, [_onDrop]);

  useEffect(() => {
    window.addEventListener("drop", onDrop as any);

    return () => {
      window.removeEventListener("drop", onDrop as any);
    };
  }, [onDrop]);
  useEffect(() => {
    window.addEventListener("dragover", onDragOver as any);

    return () => {
      window.removeEventListener("drag", onDragOver as any);
    };
  }, []);
}

export function useDragAndDropFiles(
  onFiles: (files?: File[]) => void,
  supportedMIME: string[] = SUPPORTED_IMAGE_MIME
) {
  return useDragAndDrop(createOnDrop(onFiles, supportedMIME));
}
