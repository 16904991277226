@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  font-size: 10pt;
  display: flex;
  flex-direction: row;
  width: $left-bar-full-width;
  background-color: #fff;
  margin-left: 0;
  transition: margin-left 100ms;

  .collapse-button-bar {
    height: inherit;
    background-color: #f6f6f6;
    border-left: 1px solid $separator-color;
    border-right: 1px solid $separator-color;
    width: calc(#{$left-bar-collapsed-width} - 6px);

    cursor: pointer;
  }
  .collapse-button-bar:hover {
    background-color: darken($color: #f8f8f8, $amount: 5%);
  }
  .button-collapsed {
    width: $left-bar-collapsed-width;
    background-image: url("../../images/icon-expand-right.png");
    background-size: 8px 8px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.content {
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  .template-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 12px;

    div:nth-child(1) {
      @include icon-frame-size(80px);
      display: inline-block;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    .items {
      > * {
        > div,
        a {
          padding: 12px;
          padding-left: 24px;
          border-bottom: 1px solid $separator-color;
          cursor: pointer;
          display: block;
        }
      }

      .section-header {
        margin-top: 0px;
        padding: 6px 12px;
        > p {
          font-size: 10pt;
          font-weight: bold;
        }
      }

      .section-header:not(:first-child) {
        margin-top: 40px;
      }

      .tawk-padding {
        height: 100px;
      }
      a:hover {
        text-decoration: none;
      }
    }

    .selected:before {
      margin-left: -15px;
      content: "❯";
      display: inline-block;
      width: 15px;
    }
  }
}

.container-collapsed {
  margin-left: calc(16px - $left-bar-full-width);
  transition: margin-left 100ms;
}

.promotion-box {
  font-size: 10pt;
  padding: 18px 16px;
  margin: 12px;
  border-radius: 6px;
  background-color: #ffa648;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  line-height: 1.3em;

  .promotion-box-button {
    color: #fff;
    width: 80%;
    margin-top: 24px;
    padding: 6px;
    border-radius: 6px;

    border: #fff 1px solid;

    text-align: center;
    font-weight: bold;
    font-size: 11pt;
    cursor: pointer;
  }

  .promotion-box-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.powered-by-formx {
  margin: 16px 12px;
  font-size: 12px;
  color: #605e5c;
}
