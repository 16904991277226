import {
  CommandBar,
  IBreadcrumbItem,
  ICommandBarItemProps,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

import { useLocale } from "../../contexts/locale";
import { Breadcrumb } from "../WrappedMSComponents/Breadcrumb";
import styles from "./styles.module.scss";

interface Props {
  commbarBarItems?: ICommandBarItemProps[];
}

const NavBar = React.memo((props: Props) => {
  const { commbarBarItems } = props;
  const location = useLocation();
  const history = useHistory();
  const { localized } = useLocale();

  const onPivotItemClick = useCallback(
    (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
      if (!ev || !item || !item.props.itemKey) {
        return;
      }
      history.push(`/${item.props.itemKey}`);
    },
    [history]
  );

  const breadcrumbItems: IBreadcrumbItem[] = useMemo(() => {
    return [
      {
        text: localized("header.detect_documents"),
        key: "detect_documents",
      },
    ];
  }, [localized]);

  const selectedKey = useMemo(
    () => location.pathname.substring(1),
    [location.pathname]
  );

  return (
    <div className={styles["container"]}>
      <Breadcrumb className={styles["breadcrumb"]} items={breadcrumbItems} />
      <div className={styles["tab-bar"]}>
        <Pivot onLinkClick={onPivotItemClick} selectedKey={selectedKey}>
          <PivotItem
            headerText={localized("header.receipt_api")}
            itemKey="detect-documents/api"
            itemIcon="PlugConnected"
          />
          <PivotItem
            headerText={localized("header.receipt_test")}
            itemKey="detect-documents/test"
            itemIcon="TestBeaker"
          />
        </Pivot>
        {commbarBarItems && <CommandBar items={commbarBarItems} />}
      </div>
    </div>
  );
});

interface DetectMultiDocumentNavBarProps extends Props {
  children: React.ReactNode;
}

const DetectMultiDocumentNavBar: React.FC<DetectMultiDocumentNavBarProps> = (
  props: DetectMultiDocumentNavBarProps
) => {
  const { children, ...rest } = props;

  return (
    <>
      <NavBar {...rest} />
      <div className={styles["content"]}>{children}</div>
    </>
  );
};

export default DetectMultiDocumentNavBar;
