import { loadTheme } from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { enableMapSet, setAutoFreeze } from "immer";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";

import { AppConfig } from "./config";
import App from "./containers/App";
import { LocaleProvider } from "./contexts/locale";
import createStore from "./redux/createStore";
// Apply global style
import "./styles/global.scss";
import Theme from "./theme";

document.title = AppConfig.documentTitle || "FormExtractor";
enableMapSet();
setAutoFreeze(false);
loadTheme(Theme);

const fontBaseUrl = (window as any).FabricConfig?.fontBaseUrl;
if (fontBaseUrl) {
  initializeIcons(`${fontBaseUrl}/fabric-icon-fonts/`);
} else {
  initializeIcons();
}

const store = createStore();
const element = (
  <LocaleProvider locale="en">
    <Provider store={store}>
      <BrowserRouter>
        <Route component={App} />
      </BrowserRouter>
    </Provider>
  </LocaleProvider>
);
render(element, document.getElementById("root"));
