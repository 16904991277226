export function validateTeamName(teamName: string): string | undefined {
  if (teamName.length === 0) {
    return "error.create_team.team_name.validation.empty_team_name";
  }

  if (teamName.length < 3) {
    return "error.create_team.team_name.validation.too_short";
  }

  if (teamName.length > 100) {
    return "error.create_team.team_name.validation.too_long";
  }

  if (teamName.startsWith(" ") || teamName.endsWith(" ")) {
    return "error.create_team.team_name.validation.no_leading_or_trailing_whitespace";
  }

  if (teamName.includes("  ")) {
    return "error.create_team.lookup_id.validation.no_multiple_spaces";
  }

  return undefined;
}

export function validateTeamID(teamID: string): string | undefined {
  if (teamID.length < 4) {
    return "error.create_team.lookup_id.validation.too_short";
  }

  if (teamID.length > 100) {
    return "error.create_team.lookup_id.validation.too_long";
  }

  const upperCaseRegex = /[A-Z]/;
  if (upperCaseRegex.test(teamID)) {
    return "error.create_team.lookup_id.validation.no_upper_case";
  }

  const noSpaceRegex = /\s/;
  if (noSpaceRegex.test(teamID)) {
    return "error.create_team.lookup_id.validation.no_space";
  }

  const alphaNumericOrHypenOnlyRegex = /^[a-z0-9-]+$/;
  if (!alphaNumericOrHypenOnlyRegex.test(teamID)) {
    return "error.create_team.lookup_id.validation.alphanumeric_or_hyphens_only";
  }
  return undefined;
}
