@import "../../styles/variables.scss";

.detect-multi-document-test {
  position: relative;
  height: 100%;
  padding: 0 10px;
  overflow: auto;
}

.report {
  padding: 0 10px;
  margin-bottom: 80px;

  .section {
    margin: 20px;
    margin-top: 0;

    .warped-image-wrapper {
      display: flex;
      background: #faf9f8;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: 500px;
    }

    h1 {
      padding: 10px 0;
      font-size: 18px;
      margin: 10px 0;
      border-bottom: 1px solid #ccc;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    pre {
      padding: 10px;
      font-family: monospace;
      font-size: 14px;
      line-height: 1.6em;

      background-color: #ddd;
      overflow-x: auto;

      pre {
        padding: 0;
        &.highlighted {
          background-color: #ffd63320;
        }
      }
    }

    img {
      max-width: 100%;
      object-fit: contain;
      margin: 0 auto;
      max-height: 100%;
    }
  }
}
