import * as React from "react";

import { extractFieldInTestMode } from "../actions/form";
import { AppConfig } from "../config";
import { FOCRError } from "../errors";
import { OCRTestReport } from "../models";
import { FormExtractionMode } from "../types/form";
import { imageURLToFile } from "../utils/image";
import { useWorkerToken } from "./app";
import { useToast } from "./toast";

export function useExtractTest(entityId?: string) {
  const toast = useToast();
  const { token } = useWorkerToken();
  const [isRecognizing, setIsRecognizing] = React.useState<boolean>(false);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [testReport, setTestReport] = React.useState<OCRTestReport | undefined>(
    undefined
  );

  const [extractionMode, setExtractionMode] =
    React.useState<FormExtractionMode>(FormExtractionMode.singlePage);

  const onExtractionModeChanged = React.useCallback(
    (value: FormExtractionMode) => {
      setExtractionMode(value);
    },
    [setExtractionMode]
  );

  const { shouldAsyncDisabled } = AppConfig.worker;

  const extractWithFile = React.useCallback(
    async (file?: File) => {
      if (!file || !token || !entityId) return;

      if (shouldAsyncDisabled) {
        setIsRecognizing(true);
      } else {
        setIsUploading(true);
      }

      try {
        const response = await extractFieldInTestMode(
          token,
          entityId,
          file,
          extractionMode,
          () => {
            if (!shouldAsyncDisabled) {
              setIsUploading(false);
              setIsRecognizing(true);
            }
          }
        );

        setIsRecognizing(false);
        setTestReport(response);
      } catch (error) {
        setIsUploading(false);
        setIsRecognizing(false);
        if (error instanceof FOCRError) {
          toast.error(error.messageId, undefined, error.detail);
        } else {
          toast.error("error.cannot_load_image");
        }
      }
    },
    [token, entityId, shouldAsyncDisabled, extractionMode, toast]
  );

  const extractWithURL = React.useCallback(
    (url: string) => {
      imageURLToFile(url)
        .then(extractWithFile)
        .catch(() => {
          toast.error("error.cannot_load_image");
        });
    },
    [extractWithFile, toast]
  );

  React.useEffect(() => {
    setTestReport(undefined);
  }, [entityId]);

  return {
    extractWithFile,
    extractWithURL,
    isRecognizing,
    isUploading,
    testReport,
    setTestReport,
    extractionMode,
    onExtractionModeChanged,
  };
}
