import * as yup from "yup";

import { Usage, UsageRange, usageSchema } from "../../types/usage";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface RequestLogApiClient {
  getUsage: (
    range: UsageRange,
    resourceOwnerId?: string,
    region?: string
  ) => Promise<Usage[]>;
}

export function withRequestLogApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async getUsage(
      range: UsageRange,
      resourceOwnerId?: string,
      region?: string
    ): Promise<Usage[]> {
      return this.lambda(
        "request_log:count",
        this.injectOptionalFields(
          {
            range,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        yup.array(usageSchema).defined(),
        undefined,
        region !== undefined ? { region } : undefined
      );
    }
  };
}
