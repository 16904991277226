import { useMonaco } from "@monaco-editor/react";
import { useEffect } from "react";

export default function useConfigMonaco(builtTypeDefinitions: string) {
  const monaco = useMonaco();

  useEffect(() => {
    monaco?.languages.typescript.javascriptDefaults.setCompilerOptions({
      lib: ["es2015"],
      target: monaco.languages.typescript.ScriptTarget.ES2015,
      allowNonTsExtensions: true, // As we are loading from memory
      allowJs: true,
    });
    monaco?.languages.typescript.javascriptDefaults.setExtraLibs([
      { content: builtTypeDefinitions },
    ]);
  }, [monaco, builtTypeDefinitions]);
}
