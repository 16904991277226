import React from "react";
import { Route, Switch } from "react-router";

import { Layout, Main, Top } from "../components/Layout";
import { UUIDPattern } from "../constants";
import HeaderContainer from "../containers/Header";
import AdminTeamDetailContainer from "./AdminTeamDetail";
import AdminTeamListContainer from "./AdminTeamList";

function _AdminContainer() {
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true} hasLeft={true}>
        <Switch>
          <Route
            exact
            path={`/admin/team/:region/:teamId(${UUIDPattern})/`}
            component={AdminTeamDetailContainer}
          />
          <Route
            exact
            path={`/admin/team`}
            component={AdminTeamListContainer}
          />
        </Switch>
      </Main>
    </Layout>
  );
}

export const AdminContainer = React.memo(_AdminContainer);
export default AdminContainer;
