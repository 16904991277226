import { Icon, Text } from "@fluentui/react";
import React from "react";
import { useSelector } from "react-redux";
import * as uuid from "uuid";

import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import {
  DetailFormGroup,
  FormGroupAnchor,
  FormGroupAnchorBase,
} from "../../types/formGroup";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import { FormGroupAnchorModal } from "./FormGroupAnchorModal";
import styles from "./styles.module.scss";

interface Props {
  formGroup: DetailFormGroup;
  onUpdateAnchors: (anchors: FormGroupAnchorBase[]) => Promise<void>;
}

interface FormGroupAnchorTableRowProps {
  anchor: FormGroupAnchor;
  shouldShowEditButton: boolean;
  onEditAnchor: (anchor: FormGroupAnchor) => void;
  onRemoveAnchor: (anchorId: string) => Promise<void>;
}

function FormGroupAnchorTableRow(props: FormGroupAnchorTableRowProps) {
  const { anchor, shouldShowEditButton, onEditAnchor, onRemoveAnchor } = props;
  return (
    <tr className={styles["form-group-anchor-table-row"]}>
      <td>{anchor.form.name}</td>
      {shouldShowEditButton && (
        <td className={styles["action-container"]}>
          <Icon
            iconName="Edit"
            className={styles["action-button"]}
            onClick={() => onEditAnchor(anchor)}
          />
          <Icon
            iconName="Delete"
            className={styles["action-button"]}
            onClick={() => onRemoveAnchor(anchor.id)}
          />
        </td>
      )}
    </tr>
  );
}

export const FormGroupAnchorList = React.memo((props: Props) => {
  const { formGroup, onUpdateAnchors } = props;
  const { localized } = useLocale();

  const forms = useSelector((state: RootState) => state.formGroup.forms);

  const selectableForms = React.useMemo(() => {
    return forms.filter(
      form => !formGroup.anchors.find(anchor => anchor.formId === form.id)
    );
  }, [forms, formGroup]);

  const [isAnchorModalOpen, setIsAnchorModalOpen] =
    React.useState<boolean>(false);
  const [anchorToEdit, setAnchorToEdit] = React.useState<
    FormGroupAnchor | undefined
  >(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { hasPermissionToEditResource } = useTeamPermission();

  const onEditAnchor = React.useCallback((anchor: FormGroupAnchor) => {
    setAnchorToEdit(anchor);
    setIsAnchorModalOpen(true);
  }, []);

  const onRemoveAnchor = React.useCallback(
    async (anchorId: string) => {
      const newAnchors = formGroup.anchors.filter(
        anchor => anchor.id !== anchorId
      );
      try {
        setIsLoading(true);
        await onUpdateAnchors(newAnchors);
      } finally {
        setIsLoading(false);
      }
    },
    [formGroup.anchors, onUpdateAnchors]
  );

  const onAddAnchor = React.useCallback(() => {
    setIsAnchorModalOpen(true);
    setAnchorToEdit(undefined);
  }, []);

  const onCloseAnchorModal = React.useCallback(() => {
    setIsAnchorModalOpen(false);
    setAnchorToEdit(undefined);
  }, []);

  const onSaveAnchor = React.useCallback(
    async (anchorId: string | undefined, formId: string) => {
      onCloseAnchorModal();
      const newAnchors: FormGroupAnchorBase[] = [...formGroup.anchors];
      if (anchorId) {
        const anchorIndex = newAnchors.findIndex(
          anchor => anchor.id === anchorId
        );
        if (anchorIndex === -1) {
          console.error(`Cannot find anchor ${anchorId} in form group`);
          return;
        }
        newAnchors[anchorIndex] = { id: anchorId, formId };
      } else {
        newAnchors.push({
          id: uuid.v4(),
          formId,
        });
      }
      try {
        setIsLoading(true);
        await onUpdateAnchors(newAnchors);
      } finally {
        setIsLoading(false);
      }
    },
    [onCloseAnchorModal, formGroup, onUpdateAnchors]
  );

  return (
    <div className={styles["form-group-form-list"]}>
      {hasPermissionToEditResource && (
        <div className={styles["add-new-area"]}>
          <div className={styles["add-new-form-button"]} onClick={onAddAnchor}>
            <Icon
              iconName="BuildQueueNew"
              className={styles["add-new-form-button-icon"]}
            />
            <Text>{localized("add.new.form")}</Text>
          </div>
        </div>
      )}
      <Table
        columnIds={["form_group_anchor_editor.form"].concat(
          hasPermissionToEditResource ? ["form_group_anchor_editor.action"] : []
        )}
        className={styles["form-group-table"]}
      >
        {formGroup.anchors.map(anchor => (
          <FormGroupAnchorTableRow
            key={anchor.id}
            anchor={anchor}
            shouldShowEditButton={hasPermissionToEditResource}
            onEditAnchor={onEditAnchor}
            onRemoveAnchor={onRemoveAnchor}
          />
        ))}
      </Table>

      {isAnchorModalOpen && (
        <FormGroupAnchorModal
          anchor={anchorToEdit}
          onCloseModal={onCloseAnchorModal}
          onSave={onSaveAnchor}
          forms={selectableForms}
        />
      )}
      {isLoading && (
        <div className={styles["loading-container"]}>
          <ShortSpinner />
        </div>
      )}
    </div>
  );
});
