import {
  CreateTeam,
  TeamInvitationAccepted,
  TeamUserRemoved,
} from "../actions/team";
import {
  GotWebhookAction,
  GotWebhookListAction,
  WebhookCreatedAction,
  WebhookRemovedAction,
  WebhookUpdatedAction,
} from "../actions/webhook";
import { RootAction } from "../redux/types";
import { Webhook } from "../types/webhook";

export interface WebhookState {
  readonly webhooks: Webhook[];
  readonly isFetching: boolean;
  readonly currentWebhook?: Webhook;
}

const defaultState: WebhookState = {
  webhooks: [],
  isFetching: false,
};

function handleGotWebhookList(
  state: WebhookState,
  action: GotWebhookListAction
): WebhookState {
  const { webhooks } = action.payload;

  return {
    ...state,
    currentWebhook: undefined,
    webhooks: webhooks,
    isFetching: false,
  };
}

function handleWebhookCreated(
  state: WebhookState,
  action: WebhookCreatedAction
): WebhookState {
  const { webhook } = action.payload;
  const { webhooks } = state;

  return {
    ...state,
    currentWebhook: webhook,
    webhooks: [...webhooks, webhook],
  };
}

function handleGotWebhook(
  state: WebhookState,
  action: GotWebhookAction
): WebhookState {
  const { webhook } = action.payload;

  return {
    ...state,
    currentWebhook: webhook,
  };
}

function handleWebhookUpdated(
  state: WebhookState,
  action: WebhookUpdatedAction
): WebhookState {
  const { webhook } = action.payload;
  const { webhooks } = state;
  const indexOfCurrentWebhook = webhooks.findIndex(w => w.id === webhook.id);
  let newWebhookList = [...webhooks];

  if (indexOfCurrentWebhook === -1) {
    newWebhookList = newWebhookList.concat([webhook]);
  } else {
    newWebhookList[indexOfCurrentWebhook] = webhook;
  }

  return {
    ...state,
    webhooks: newWebhookList,
  };
}

function handleWebhookRemoved(
  state: WebhookState,
  action: WebhookRemovedAction
): WebhookState {
  const { webhookId } = action.payload;
  const { webhooks, currentWebhook } = state;

  return {
    ...state,
    currentWebhook:
      currentWebhook?.id === webhookId ? undefined : currentWebhook,
    webhooks: webhooks.filter(w => w.id !== webhookId),
  };
}

export function reducer(
  state: WebhookState = defaultState,
  action: RootAction
): WebhookState {
  switch (action.type) {
    case "StartedFetchingWebhookList":
      return {
        ...state,
        isFetching: true,
      };
    case "GotWebhookList":
      return handleGotWebhookList(state, action);
    case "WebhookCreated":
      return handleWebhookCreated(state, action);
    case "GotWebhook":
      return handleGotWebhook(state, action);
    case "WebhookUpdated":
      return handleWebhookUpdated(state, action);
    case "WebhookRemoved":
      return handleWebhookRemoved(state, action);
    case TeamUserRemoved: {
      const { removedUserId, currentUser } = action;
      if (removedUserId === currentUser.id) {
        return {
          ...defaultState,
        };
      } else {
        return state;
      }
    }
    case "TeamDeleted":
    case "UserLogin":
    case "UserLogout":
    case "SelectTeam":
    case CreateTeam:
    case TeamInvitationAccepted:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
}
