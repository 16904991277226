import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  Label,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";

import { CUSTOM_FIELD_PATTERN_OPTIONS } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { localizedMenuOptions } from "../../utils/menu";
import ErrorText from "../WrappedMSComponents/ErrorText";
import SpinButton from "../WrappedMSComponents/SpinButton";
import styles from "./styles.module.scss";

interface Props {
  onApply: (regex: string) => void;
  onCancel: () => void;
  isOpen: boolean;
}

interface PatternChoice {
  label: string;
  value: string;
}

const RegexBuilder = React.memo((props: Props) => {
  const { onApply, onCancel, isOpen } = props;
  const { localized } = useLocale();

  const patternMenuOptions = localizedMenuOptions(
    localized,
    CUSTOM_FIELD_PATTERN_OPTIONS
  );

  const [patternChoices, setPatternChoices] = useState<PatternChoice[]>([]);
  const [patternMinLength, setPatternMinLength] = useState(0);
  const [patternMaxLength, setPatternMaxLength] = useState(0);
  const [errorMessageId, setErrorMessageId] = useState<string | undefined>();

  const onPatternChoiceChange = useCallback(
    (_patternChoices: PatternChoice[]) => {
      setPatternChoices(_patternChoices);
    },
    []
  );

  const onPatternMinLengthChange = useCallback((v: string) => {
    setPatternMinLength(parseInt(v, 10) || 0);
  }, []);

  const onPatternMaxLengthChange = useCallback((v: string) => {
    setPatternMaxLength(parseInt(v, 10) || 0);
  }, []);

  const validate = useCallback(() => {
    if (patternMinLength > patternMaxLength && patternMaxLength !== 0) {
      setErrorMessageId("error.regex_builder.invalid_pattern_length_range");
      return false;
    } else {
      return true;
    }
  }, [patternMinLength, patternMaxLength]);

  const constructPattern = useCallback(() => {
    const patternRegexMap: { [key: string]: string } = {
      lowercase_alphabet: "a-z",
      uppercase_alphabet: "A-Z",
      chinese_char: "\\p{Script=Hani}",
      number: "0-9",
      dot: ".",
    };

    const allowableCharacter = patternChoices
      .map(choice => patternRegexMap[choice.value])
      .join("");

    const pattern = allowableCharacter ? `[${allowableCharacter}]` : ".";

    const quantifier =
      patternMinLength || patternMaxLength
        ? `{${patternMinLength || ""},${patternMaxLength || ""}}`
        : "+";

    return pattern + quantifier;
  }, [patternChoices, patternMaxLength, patternMinLength]);

  const onApplyClicked = useCallback(() => {
    if (!validate()) {
      return;
    }
    onApply(constructPattern());
  }, [constructPattern, onApply, validate]);

  const onDismissed = useCallback(() => {
    setPatternChoices([]);
    setPatternMinLength(0);
    setPatternMaxLength(0);
    setErrorMessageId(undefined);
  }, []);

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("regex_builder.title"),
    }),
    [localized]
  );

  return (
    <Dialog
      minWidth={450}
      hidden={!isOpen}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <Label>
        <FormattedMessage id="regex_builder.pattern_choice" />
      </Label>
      <Select
        className={styles["pattern-preset-selector"]}
        searchable={false}
        clearable={false}
        multi={true}
        value={patternChoices}
        options={patternMenuOptions}
        onChange={onPatternChoiceChange as any}
      />
      <Label>
        <FormattedMessage id="regex_builder.pattern_length" />
      </Label>

      <div className={styles["pattern-range"]}>
        <SpinButton
          className={styles["spin-button"]}
          label={localized("regex_builder.pattern_range_min")}
          labelPosition={0}
          value={patternMinLength.toString()}
          min={0}
          max={100}
          onChange={onPatternMinLengthChange}
        />
        <div className={styles["spin-separator"]} />
        <SpinButton
          className={styles["spin-button"]}
          label={localized("regex_builder.pattern_range_max")}
          labelPosition={0}
          value={patternMaxLength.toString()}
          min={0}
          max={100}
          onChange={onPatternMaxLengthChange}
        />
      </div>

      <div className={styles["reminder"]}>
        <Text>
          <FormattedMessage id="regex_builder.instruction.zero_for_unspecified_length" />
        </Text>
        <Text>
          <FormattedMessage id="regex_builder.instruction.same_value_for_exact_length" />
        </Text>
      </div>

      {errorMessageId && (
        <ErrorText>
          <FormattedMessage id={errorMessageId} />
        </ErrorText>
      )}

      <DialogFooter>
        <DefaultButton text={localized("common.cancel")} onClick={onCancel} />
        <PrimaryButton
          type="submit"
          text={localized("regex_builder.apply")}
          onClick={onApplyClicked}
        />
      </DialogFooter>
    </Dialog>
  );
});

export default RegexBuilder;
