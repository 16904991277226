import { Icon, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useTeamPermission } from "../../hooks/permission";
import styles from "./styles.module.scss";

interface Props {
  type: "form" | "form-group";
  onCreate(): void;
  onImport?: () => void;
}

export const CreateFormBar = React.memo(
  ({ onCreate: onCreateForm, type, onImport }: Props) => {
    return (
      <div className={styles["top-bar"]}>
        <div className={styles["button-area"]} onClick={onCreateForm}>
          <Icon iconName="BuildQueueNew" />
          <Text>
            <FormattedMessage
              id={type === "form" ? "add.new.form" : "add.new.form_group"}
            />
          </Text>
        </div>
        {onImport && (
          <div className={styles["button-area"]} onClick={onImport}>
            <div className={styles["import-icon"]} />
            <Text>
              <FormattedMessage
                id={
                  type === "form" ? "import.new.form" : "import.new.form_group"
                }
              />
            </Text>
          </div>
        )}
      </div>
    );
  }
);

interface CreateFormBarLayoutProps extends Props {
  children: React.ReactNode;
}

export const CreateFormBarLayout: React.FC<CreateFormBarLayoutProps> = (
  props: CreateFormBarLayoutProps
) => {
  const { children, ...rest } = props;
  const { hasPermissionToCreateResource } = useTeamPermission();

  return hasPermissionToCreateResource ? (
    <>
      <CreateFormBar {...rest} />
      <div className={styles["content"]}>{children}</div>
    </>
  ) : (
    <>{children}</>
  );
};

export default CreateFormBar;
