import { AppConfig } from "../config";
import { APIDescription } from "../models";

export const getExtractAPIDescription = (
  isMultiDocument: boolean
): APIDescription => {
  const documentFields = [
    { name: "form_id", fieldType: "string" },
    { name: "fields", fieldType: "Field[]" },
    {
      name: "auto_extraction_items",
      isOptional: true,
      fieldType: "AutoExtractionItem[]",
    },
    { name: "key_values", isOptional: true, fieldType: "KeyValue[]" },
    { name: "token_groups", isOptional: true, fieldType: "TokenGroup[]" },
    { name: "error", isOptional: true, fieldType: "any" },
  ];

  const description: APIDescription = {
    name: "extract",
    method: "POST",
    endpoint: `${AppConfig.worker.endpoint}extract`,

    headerParams: [
      { name: "Content-Type", isOptional: true },
      { name: "X-WORKER-TOKEN" },
      { name: "X-WORKER-FORM-ID" },
      { name: "X-WORKER-IMAGE-URL", isOptional: true },
      { name: "X-WORKER-ENCODING", isOptional: true, defaultValue: "raw" },
      { name: "X-WORKER-PDF-DPI", isOptional: true, defaultValue: "150" },
      {
        name: "X-WORKER-SHOW-CONFIDENCE",
        isOptional: true,
        defaultValue: "false",
      },
      {
        name: "X-WORKER-AUTO-ADJUST-IMAGE-SIZE",
        isOptional: true,
        defaultValue: "true",
      },
      {
        name: "X-WORKER-MULTI-PAGE-PDF",
        isOptional: true,
        defaultValue: "false",
      },
      {
        name: "X-WORKER-DETECT-MULTI-DOCUMENT",
        isOptional: true,
        defaultValue: "false",
      },
      {
        name: "X-WORKER-RETURN-REQUEST-ID",
        isOptional: true,
        defaultValue: "false",
      },
    ],
    formParams: [
      { name: "token" },
      { name: "form_id" },
      { name: "image", isOptional: true },
      { name: "image_url", isOptional: true },
      { name: "pdf_dpi", isOptional: true, defaultValue: "150" },
      { name: "show_confidence", isOptional: true, defaultValue: "false" },
      {
        name: "auto_adjust_image_size",
        isOptional: true,
        defaultValue: "true",
      },
      { name: "multi_page_pdf", isOptional: true, defaultValue: "false" },
      {
        name: "detect_multi_document",
        isOptional: true,
        defaultValue: "false",
      },
      { name: "return_request_id", isOptional: true, defaultValue: "false" },
    ],
    responseFields: [{ name: "status", fieldType: "string" }],
    types: [
      {
        name: "Field",
        fields: [
          { name: "region_id", fieldType: "string" },
          { name: "name", fieldType: "string" },
          { name: "type", fieldType: "string" },
          { name: "value", isOptional: true, fieldType: "any" },
          { name: "error", isOptional: true, fieldType: "string" },
          { name: "confidence", isOptional: true, fieldType: "number" },
        ],
      },
      {
        name: "AutoExtractionItem",
        fields: [
          { name: "name", fieldType: "string" },
          { name: "value", fieldType: "any" },
          { name: "confidence", isOptional: true, fieldType: "number" },
        ],
      },
      {
        name: "KeyValue",
        fields: [
          { name: "name", fieldType: "string" },
          { name: "value", fieldType: "string" },
          { name: "confidence", isOptional: true, fieldType: "number" },
        ],
      },
      {
        name: "TokenGroup",
        fields: [
          { name: "name", fieldType: "string" },
          { name: "texts", isOptional: true, fieldType: "Token[]" },
          { name: "images", isOptional: true, fieldType: "Token[]" },
        ],
      },
      {
        name: "Token",
        fields: [
          { name: "id", fieldType: "string" },
          { name: "value", fieldType: "string" },
          { name: "confidence", isOptional: true, fieldType: "number" },
        ],
      },
      {
        name: "CustomModel",
        fields: [
          { name: "id", fieldType: "string" },
          { name: "model_version", fieldType: "string" },
          { name: "name", fieldType: "string" },
          { name: "result", fieldType: "any" },
        ],
      },
    ],
    cURLExample: `curl -X POST \\
  ${AppConfig.worker.endpoint}extract \\
  -H 'Content-Type: image/jpeg' \\
  -H 'X-WORKER-FORM-ID: REPLACE-YOUR-FORM-ID-HERE' \\
  -H 'X-WORKER-TOKEN: REPLACE-YOUR-WORKER-TOKEN-HERE' \\
  --data-binary "@/path/to/query/image.jpg"`,
  };

  if (isMultiDocument) {
    description.responseFields = [
      ...description.responseFields,
      { name: "documents", fieldType: "Document[]" },
    ];
    description.types = [
      {
        name: "Document",
        fields: documentFields,
      },
      ...(description.types || []),
    ];
  } else {
    description.responseFields = [
      ...description.responseFields,
      ...documentFields,
    ];
  }

  if (!AppConfig.worker.shouldAsyncDisabled) {
    description.headerParams = [
      ...description.headerParams,
      {
        name: "X-WORKER-ASYNC",
        isOptional: true,
        defaultValue: "false",
        actions: ["asyncMode"],
      },
    ];

    description.formParams = [
      ...description.formParams,
      {
        name: "async",
        isOptional: true,
        defaultValue: "false",
        actions: ["asyncMode"],
      },
    ];
  }

  return description;
};
