import { apiClient } from "../apiClient";
import { TEMPLATES_TO_HIDE, TEMPLATE_ORDERINGS } from "../constants";
import { Dispatch } from "../redux/types";
import { Template } from "../types/template";

export interface GotTemplateListAction {
  readonly type: "GotTemplateList";
  readonly payload: {
    templates: Template[];
  };
}

function sortTemplates(templates: Template[]): Template[] {
  const output: Template[] = [];
  const templateMap = new Map<string, Template>(
    templates
      .filter(x => !TEMPLATES_TO_HIDE.includes(x.name))
      .map(x => [x.name, x])
  );
  TEMPLATE_ORDERINGS.forEach(group => {
    group.forEach(templateName => {
      const template = templateMap.get(templateName);
      if (template) {
        output.push(template);
        templateMap.delete(templateName);
      }
    });
  });

  return [...templateMap.values()].concat(output);
}

export function list() {
  return async (dispatch: Dispatch): Promise<void> => {
    const templates = await apiClient.listTemplates();
    dispatch({
      type: "GotTemplateList",
      payload: {
        templates: sortTemplates(templates),
      },
    });
  };
}

export type TemplateAction = GotTemplateListAction;
