import classnames from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/types";
import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

const StickyButtonBox = React.memo(({ children }: Props) => {
  const isLeftBarCollapsed = useSelector(
    (state: RootState) => state.app.isLeftBarCollapsed
  );

  return (
    <div
      className={classnames(styles["sticky-button-box"], {
        [styles["collapsed"]]: isLeftBarCollapsed,
      })}
    >
      {children}
    </div>
  );
});

export default StickyButtonBox;
