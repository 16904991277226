@import "../../styles/mixin.scss";

@include ms-motion-keyframes;

.toast {
  padding: 4px 0;
  width: 480px;
  @media only screen and (max-width: 480px) {
    width: calc(100vw - 40px);
  }
}

.fade-in {
  @include ms-motion-scaleDownIn;
}

.fade-out {
  @include ms-motion-scaleDownOut;
}

.login-toast-container {
  :global(.react-toast-notifications__container) {
    margin-top: calc(50vh + 100px);
  }
}

.signup-toast-container {
  :global(.react-toast-notifications__container) {
    margin-top: calc(50vh + 220px);
  }
}

.toast-container {
  :global(.react-toast-notifications__container) {
    margin-top: 48px;
  }
}
