import * as React from "react";
import { useSelector } from "react-redux";

import { useFormGroupActionCreator } from "../actions/formGroup";
import { RootState } from "../redux/types";
import { BriefFormGroup, DetailFormGroup } from "../types/formGroup";
import { useWorkerToken } from "./app";

export function useFormGroup(formGroupId: string) {
  const { get: getFormGroup } = useFormGroupActionCreator();
  const formGroup = useSelector(
    (state: RootState) => state.formGroup.currentFormGroup
  );
  const [isFailedToFetchFormGroup, setIsFailedToFetchFormGroup] =
    React.useState(false);

  React.useEffect(() => {
    if (!formGroup || formGroup.id !== formGroupId) {
      getFormGroup(formGroupId).catch(() => {
        setIsFailedToFetchFormGroup(true);
      });
    }
  }, [formGroup, getFormGroup, formGroupId]);

  return {
    formGroup,
    isFailedToFetchFormGroup,
  };
}

export function useFormOptions() {
  const { fetchForms } = useFormGroupActionCreator();
  const formOptions = useSelector((state: RootState) =>
    state.formGroup.shouldLoadForms ? undefined : state.formGroup.forms
  );
  const [isFailedToFetchFormOptions, setIsFailedToFetchFormOptions] =
    React.useState(false);

  React.useEffect(() => {
    if (formOptions === undefined) {
      fetchForms().catch(e => {
        console.error(e);
        setIsFailedToFetchFormOptions(true);
      });
    }
  }, [formOptions, fetchForms]);

  return {
    formOptions,
    isFailedToFetchFormOptions,
  };
}

interface CommonFormGroupContainerSuccessState {
  state: "success";
  workerToken: string;
  formGroup: DetailFormGroup;
}

export interface CommonFormGroupContainerErrorState {
  state: "error";
}

export interface CommonFormGroupContainerLoadingState {
  state: "loading";
}

export type CommonFormGroupContainerState =
  | CommonFormGroupContainerLoadingState
  | CommonFormGroupContainerErrorState
  | CommonFormGroupContainerSuccessState;

export function useCommonFormGroupContainerState(formGroupId: string) {
  const { formGroup, isFailedToFetchFormGroup } = useFormGroup(formGroupId);
  const { token: workerToken, isFailed: isFailedToGetWorkerToken } =
    useWorkerToken();

  const containerState: CommonFormGroupContainerState =
    isFailedToGetWorkerToken || isFailedToFetchFormGroup
      ? { state: "error" }
      : formGroup !== undefined &&
        formGroup.id === formGroupId &&
        workerToken !== undefined
      ? { state: "success", formGroup, workerToken }
      : { state: "loading" };

  return containerState;
}

export function useListAllFormGroups() {
  const { list: listFormGroups } = useFormGroupActionCreator();
  const formGroups = useSelector(
    (state: RootState) => state.formGroup.formGroups
  );
  const pageInfo = useSelector((state: RootState) => state.formGroup.pageInfo);
  const [isFailedToFetchFormGroups, setIsFailedToFetchFormGroups] =
    React.useState(false);

  React.useEffect(() => {
    if (isFailedToFetchFormGroups) {
      return;
    }
    if ((formGroups.length === 0 && !pageInfo) || pageInfo?.hasNext) {
      listFormGroups().catch(() => {
        setIsFailedToFetchFormGroups(true);
      });
    }
  }, [formGroups, pageInfo, listFormGroups, isFailedToFetchFormGroups]);

  return {
    formGroups,
    pageInfo,
    isFailedToFetchFormGroups,
  };
}

interface ListAllFormGroupsContainerSuccessState {
  state: "success";
  formGroups: BriefFormGroup[];
}

interface ListAllFormGroupsContainerErrorState {
  state: "error";
}

interface ListAllFormGroupsContainerLoadingState {
  state: "loading";
}

export type ListAllFormGroupsContainerState =
  | ListAllFormGroupsContainerLoadingState
  | ListAllFormGroupsContainerErrorState
  | ListAllFormGroupsContainerSuccessState;

export function useListAllFormGroupsContainerState() {
  const { formGroups, pageInfo, isFailedToFetchFormGroups } =
    useListAllFormGroups();

  const containerState: ListAllFormGroupsContainerState =
    isFailedToFetchFormGroups
      ? { state: "error" }
      : pageInfo != null && !pageInfo.hasNext
      ? { state: "success", formGroups }
      : { state: "loading" };

  return containerState;
}
