import React from "react";
import { useSelector } from "react-redux";

import { useAppActionCreator } from "../actions/app";
import FreePlanUgradeMessageBar from "../components/FreePlanUpgradeMessageBar";
import { RootState } from "../redux/types";

function useUpgradeBar() {
  const shouldShowUpgradeBar = useSelector((state: RootState) => {
    const currentPlan = state.resourceOwner.plan;
    const { isUpgradeBarDismissed } = state.app;
    if (isUpgradeBarDismissed) {
      return false;
    }
    return (currentPlan && currentPlan === "free") || false;
  });
  const { dismissUpgradeBar } = useAppActionCreator();

  return {
    dismissUpgradeBar,
    shouldShowUpgradeBar,
  };
}

const FreePlanUpgradeMessageBarContainer = React.memo(() => {
  const { dismissUpgradeBar, shouldShowUpgradeBar } = useUpgradeBar();

  return shouldShowUpgradeBar ? (
    <FreePlanUgradeMessageBar onDismiss={dismissUpgradeBar} />
  ) : null;
});

export default FreePlanUpgradeMessageBarContainer;
