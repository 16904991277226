import {
  IButtonProps,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Text,
} from "@fluentui/react";
import React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface LoadingButtonProps extends Omit<IButtonProps, "text"> {
  isLoading: boolean;
  loadingMessageId?: string;
}

function LoadingContent(props: { labelId?: string }) {
  const { localized } = useLocale();
  const { labelId } = props;
  return (
    <div className={styles["loading-content"]}>
      <div className={styles["spinner-container"]}>
        <Spinner size={SpinnerSize.medium} />
      </div>
      <Text className={styles["loading-label"]}>
        {localized(labelId || "common.loading")}
      </Text>
    </div>
  );
}

export const PrimaryLoadingButton = React.forwardRef<
  PrimaryButton,
  LoadingButtonProps
>((props, ref) => {
  const { isLoading, loadingMessageId, children, disabled, ...restProps } =
    props;

  return (
    <PrimaryButton ref={ref} {...restProps} disabled={disabled || isLoading}>
      {isLoading ? <LoadingContent labelId={loadingMessageId} /> : children}
    </PrimaryButton>
  );
});
