import { Dispatch } from "redux";

import { Tutorial } from "../models";

export const SetCurrentTutorial = "SetCurrentTutorial";
export interface SetCurrentTutorialAction {
  readonly type: typeof SetCurrentTutorial;
  readonly tutorial: Tutorial;
}

export const TerminateCurrentTutorial = "TerminateCurrentTutorial";
export interface TerminateCurrentTutorialAction {
  readonly type: typeof TerminateCurrentTutorial;
}

export const MoveToNextStep = "MoveToNextStep";
export interface MoveToNextStepAction {
  readonly type: typeof MoveToNextStep;
}

export function setCurrentTutorial(tutorial: Tutorial) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SetCurrentTutorial, tutorial });
  };
}

export function terminateCurrentTutorial() {
  return (dispatch: Dispatch) => {
    dispatch({ type: TerminateCurrentTutorial });
  };
}

export function moveToNextStep() {
  return (dispatch: Dispatch) => {
    dispatch({ type: MoveToNextStep });
  };
}

export type TutorialActionType =
  | typeof SetCurrentTutorial
  | typeof TerminateCurrentTutorial
  | typeof MoveToNextStep;

export type TutorialAction =
  | SetCurrentTutorialAction
  | TerminateCurrentTutorialAction
  | MoveToNextStepAction;
