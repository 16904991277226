.form-group-mode-choice-group {
  margin-top: 15px;

  :global(.ms-ChoiceFieldLabel) {
    white-space: pre-wrap;
  }

  :global(.ms-ChoiceField) {
    & + :global(.ms-ChoiceField) {
      margin-top: 25px;
    }
  }
}
