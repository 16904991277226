@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.receipt-test {
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 0 10px;

  text-align: center;
}

.sample-image-box {
  text-align: left;
  padding: 0 10px;

  h1 {
    font-size: 16px;
    margin: 0 10px;
    margin-bottom: 10px;
  }
}

.report {
  text-align: center;
  margin: 20px;

  .image-pane {
    display: inline-block;
    width: 50%;
    @media only screen and (max-width: 840px) {
      width: 70%;
    }
    text-align: center;
    img {
      border-radius: 2px;
      max-width: 100%;
      border: 1px solid #ccc;
      margin: 10px;
    }
  }

  .info-pane {
    text-align: left;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    @media only screen and (max-width: 840px) {
      width: 100%;
    }
    padding: 20px;

    .field-attr {
      margin: 10px;
      vertical-align: top;
      font-size: 14px;

      label {
        display: inline-block;
        @media only screen and (max-width: 840px) {
          display: block;
        }
        border: 1px solid #ccc;
        height: 30px;
        line-height: 30px;
        width: 120px;
        text-align: center;
        margin-right: 10px;
        vertical-align: top;
      }

      > span {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        @media only screen and (max-width: 840px) {
          margin: 5px;
        }
      }

      ul {
        width: calc(100% - 150px); // stylelint-disable-line
        display: inline-block;
        @media only screen and (max-width: 840px) {
          width: 100%;
          display: block;
          margin: 5px;
        }
        li {
          display: inline-block;
          color: #eee;
          background-color: #999;
          font-size: 10pt;
          padding-left: 7px;
          padding-right: 7px;
          margin: 3px 3px;
          margin-right: 3px;
          height: 24px;
          line-height: 24px;
        }
      }

      ul.custom-field-list {
        li {
          white-space: nowrap;
          padding: 0;
          span {
            display: inline-block;
            padding-left: 7px;
            padding-right: 7px;
            overflow: visible;
          }
          span:first-child {
            background-color: #bbb;
          }
        }
      }
    }
  }
}
