import { useCallback } from "react";
import { useSelector } from "react-redux";

import {
  create as _createReceiptGroup,
  list as _listReceiptGroup,
  select as _selectReceiptGroup,
} from "../actions/receiptGroup";
import { useThunkDispatch } from "../hooks/thunk";
import { RootState } from "../redux/types";

export function useReceiptGroups() {
  const listReceiptGroup = useThunkDispatch(_listReceiptGroup);
  const selectReceiptGroup = useThunkDispatch(_selectReceiptGroup);
  const createReceiptGroup = useThunkDispatch(_createReceiptGroup);
  const {
    receiptGroups,
    forms,
    currentReceiptGroup,
    isFetching,
    areReceiptGroupsFetched,
  } = useSelector((state: RootState) => ({
    receiptGroups: state.receiptGroup.receiptGroups,
    forms: state.receiptGroup.forms,
    currentReceiptGroup: state.receiptGroup.currentReceiptGroup,
    isFetching: state.receiptGroup.isFetching,
    areReceiptGroupsFetched: state.receiptGroup.areReceiptGroupsFetched,
  }));

  const fetchReceiptGroupIfNeeded = useCallback(async () => {
    if (isFetching || areReceiptGroupsFetched) {
      return;
    }
    await listReceiptGroup();
  }, [isFetching, areReceiptGroupsFetched, listReceiptGroup]);

  return {
    fetchReceiptGroupIfNeeded,
    isFetching,
    createReceiptGroup,
    listReceiptGroup,
    selectReceiptGroup,
    receiptGroups,
    forms,
    currentReceiptGroup,
  };
}
