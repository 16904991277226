import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { useDragAndDropFiles } from "../../hooks/drag_and_drop";
import { DetectMultiDocumentReport } from "../../models";
import { DetectMultiDocumentTestPlaceholder } from "../DetectMultiDocumentTestPlaceholder";
import { TestReportTable } from "./TestReportTable";
import styles from "./styles.module.scss";

interface Props {
  onSelectImage: (file?: File) => void;
  testReport?: DetectMultiDocumentReport;
}

function _convertToJSON(testReport: DetectMultiDocumentReport) {
  return {
    status: "ok",
    documents: testReport.documents,
  };
}

function convertToJSON(testReport: DetectMultiDocumentReport) {
  return <pre>{JSON.stringify(_convertToJSON(testReport), null, 2)}</pre>;
}

function _DetectMultiDocumentTest(props: Props) {
  useDragAndDropFiles(
    (files?: File[]) => props.onSelectImage(files && files[0]),
    SUPPORTED_EXTRACT_MIME
  );

  const { onSelectImage, testReport } = props;

  return (
    <div className={styles["detect-multi-document-test"]}>
      {testReport ? (
        <ReportView report={testReport} />
      ) : (
        <DetectMultiDocumentTestPlaceholder onSelectImage={onSelectImage} />
      )}
    </div>
  );
}

interface ReportViewProps {
  report: DetectMultiDocumentReport;
}

function ReportView(props: ReportViewProps) {
  const { report } = props;

  return (
    <div className={styles["report"]}>
      <div className={styles["section"]}>
        <h1>
          <FormattedMessage id="ocr_test.warped_image" />
        </h1>
        <div className={styles["warped-image-wrapper"]}>
          <img src={report.warped_image} alt="warped" />
        </div>
      </div>

      {report && <TestReportTable report={report} />}

      <div className={styles["section"]}>
        <h1>
          <FormattedMessage id="ocr_test.json" />
        </h1>
        {convertToJSON(report)}
      </div>
    </div>
  );
}

export const DetectMultiDocumentTest = React.memo(_DetectMultiDocumentTest);
export default DetectMultiDocumentTest;
