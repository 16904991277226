import * as React from "react";
import { Redirect } from "react-router";

import { BatchNote } from "../components/BatchNote";
import { Layout, Main, Top } from "../components/Layout";
import { ReceiptNavBarLayout } from "../components/ReceiptNavBar";
import HeaderContainer from "../containers/Header";
import { useWorkerToken } from "../hooks/app";
import { useBatchCommandBarItems } from "../hooks/command_bar_items";
import { useReceiptGroups } from "../hooks/receipt_groups";

export const ReceiptBatchContainer: React.FC<unknown> = () => {
  const { currentReceiptGroup } = useReceiptGroups();
  const { token } = useWorkerToken();
  const commandBarItems = useBatchCommandBarItems(
    currentReceiptGroup && currentReceiptGroup.id,
    currentReceiptGroup && currentReceiptGroup.name,
    token
  );

  if (!currentReceiptGroup || currentReceiptGroup.type !== "form") {
    return <Redirect to="/receipt-edit" />;
  }

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <ReceiptNavBarLayout commbarBarItems={commandBarItems}>
          <BatchNote />
        </ReceiptNavBarLayout>
      </Main>
    </Layout>
  );
};

export default ReceiptBatchContainer;
