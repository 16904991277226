import { MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";
import { useHistory } from "react-router";

import styles from "./styles.module.scss";

interface Props {
  onDismiss: () => void;
}

const FreePlanUgradeMessageBar = React.memo((props: Props) => {
  const { onDismiss } = props;

  const history = useHistory();
  const onUpgradeClick = useCallback(() => {
    history.push("/payment");
  }, [history]);

  return (
    <MessageBar
      className={styles["message-bar"]}
      messageBarType={MessageBarType.warning}
      onDismiss={onDismiss}
      actions={
        <MessageBarButton
          onClick={onUpgradeClick}
          className={styles["upgrade-button"]}
        >
          <FormattedMessage id="left_bar.promotion_box.upgrade_now" />
        </MessageBarButton>
      }
    >
      <FormattedMessage id="left_bar.promotion_box.message" />
    </MessageBar>
  );
});
export default FreePlanUgradeMessageBar;
