export function extractAlphaNumbericAndHyphenChars(str: string): string {
  return str.replace(/[^a-z0-9\s]/gi, "");
}

export function generateRandomStringWithLowercaseAndNumber(
  length: number
): string {
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}
