import { replaceImageUrl } from "../../utils/replaceImageUrl";
import {
  CustomModel,
  CustomModelResp,
  CustomModelVersion,
  CustomModelVersionResp,
} from "../customModel";

function parseDateOrNull(value: any): number | null {
  return (value && Date.parse(value)) || null;
}

export const CustomModelVersionMapper = {
  fromResp: (
    resp: CustomModelVersionResp,
    deployedModelVersion: string | null,
    isInTraining: boolean
  ): CustomModelVersion => ({
    tag: resp.tag,
    createdAt: new Date(resp.created_at * 1000),
    fields: resp.fields,
    isActive: resp.tag === deployedModelVersion,
    isInTraining,
  }),
  toResp: (model: CustomModelVersion): CustomModelVersionResp => ({
    tag: model.tag,
    created_at: Math.round(model.createdAt.getTime() / 1000),
    fields: model.fields,
  }),
};

export const CustomModelMapper = {
  fromResp: (resp: CustomModelResp): CustomModel => {
    const sampleImages = resp.config.sample_images.map(image => {
      return {
        assetId: image.asset_id,
        filename: image.filename,
        updatedAt: Date.parse(image.updated_at),
        url: replaceImageUrl(image.url),
        isOnCVAT: image.is_on_cvat,
        contentType: image.content_type ? image.content_type : "",
      };
    });

    const trainingRequested =
      resp.config.training_requested === undefined
        ? false
        : resp.config.training_requested;

    const config = {
      remark: resp.config.remark,
      fields: resp.config.fields,
      frozenFields: resp.config.frozen_fields || [],
      sampleImages,
      fxcmType: resp.config.fxcm_type || "layoutlm",
      trainingRequested,
    };

    const modelVersions = resp.model_versions
      ? resp.model_versions.map(x =>
          CustomModelVersionMapper.fromResp(
            x,
            resp.deployed_model_version,
            false
          )
        )
      : [];

    const modelVersionInTraining =
      resp.model_version_in_training &&
      CustomModelVersionMapper.fromResp(
        resp.model_version_in_training,
        resp.deployed_model_version,
        true
      );

    return {
      id: resp.id,
      name: resp.name,
      createdAt: resp.created_at,
      updatedAt: resp.updated_at,
      CVATProjectID: resp.cvat_project_id,
      formID: resp.form_id,
      lastCVATProjectStartSyncAt: parseDateOrNull(
        resp.last_cvat_project_start_sync_at
      ),
      lastCVATProjectFinishSyncAt: parseDateOrNull(
        resp.last_cvat_project_finish_sync_at
      ),
      startTrainingAt: parseDateOrNull(resp.start_training_at),
      finishTrainingAt: parseDateOrNull(resp.finish_training_at),
      startDeploymentAt: parseDateOrNull(resp.start_deployment_at),
      finishDeploymentAt: parseDateOrNull(resp.finish_deployment_at),
      config,
      modelVersions:
        modelVersionInTraining !== null
          ? [modelVersionInTraining, ...modelVersions]
          : modelVersions,
      resourceOwnerId: resp.resource_owner_id,
      status: resp.status,
    };
  },
  toResp: (customModel: CustomModel): CustomModelResp => {
    const sample_images = customModel.config.sampleImages.map(image => {
      return {
        asset_id: image.assetId,
        filename: image.filename,
        updated_at: new Date(image.updatedAt).toISOString(),
        content_type: image.contentType,
        is_on_cvat: image.isOnCVAT,
      };
    });

    const config = {
      remark: customModel.config.remark,
      fields: customModel.config.fields,
      frozen_fields: customModel.config.frozenFields,
      sample_images,
      fxcm_type: customModel.config.fxcmType,
      training_requested: customModel.config.trainingRequested,
    };

    const toISODateString = (timestamp: number | null | undefined) => {
      return timestamp ? new Date(timestamp).toISOString() : null;
    };

    const modelVersions = customModel.modelVersions
      .filter(x => !x.isInTraining)
      .map(CustomModelVersionMapper.toResp);

    const inTrainingModelVersionIndex = customModel.modelVersions.findIndex(
      x => x.isInTraining
    );

    const modelVersionInTraining =
      inTrainingModelVersionIndex >= 0
        ? CustomModelVersionMapper.toResp(
            customModel.modelVersions[inTrainingModelVersionIndex]
          )
        : null;

    const activeModelVersionIndex = customModel.modelVersions.findIndex(
      x => x.isActive
    );
    const deployedModelVersion =
      activeModelVersionIndex >= 0
        ? customModel.modelVersions[activeModelVersionIndex].tag
        : null;

    return {
      id: customModel.id,
      name: customModel.name,
      created_at: customModel.createdAt || "",
      updated_at: customModel.updatedAt || "",
      config,
      cvat_project_id: customModel.CVATProjectID,
      form_id: customModel.formID,
      last_cvat_project_start_sync_at: toISODateString(
        customModel.lastCVATProjectStartSyncAt
      ),
      last_cvat_project_finish_sync_at: toISODateString(
        customModel.lastCVATProjectFinishSyncAt
      ),
      start_training_at: toISODateString(customModel.startTrainingAt),
      finish_training_at: toISODateString(customModel.finishTrainingAt),
      start_deployment_at: toISODateString(customModel.startDeploymentAt),
      finish_deployment_at: toISODateString(customModel.finishDeploymentAt),
      deployed_model_version: deployedModelVersion,
      model_versions: modelVersions,
      model_version_in_training: modelVersionInTraining,
      resource_owner_id: customModel.resourceOwnerId,
      status: customModel.status,
    };
  },
};
