@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.form-grid-item {
  @include ms-depth-4;
  @include cardBoxBorderRadius;

  width: 300px;
  height: 352px;
  margin: 30px 15px;
  background-color: #fff;

  transition: all 0.15s ease;
  display: inline-block;

  .form-image {
    width: 300px;
    height: 235px;
    border-radius: 2px 2px 0 0;
    margin: 0;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image-placeholder {
    background-color: $button-disabled-color;
  }

  .bottom-container {
    display: flex;
    flex-direction: column;
    padding: 12px;

    .form-type {
      font-size: 12px;
      margin-bottom: 4px;
    }

    .form-name {
      font-size: 14px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 276px;
      padding: 0px;
    }
    .separator {
      height: 1px;
      width: 100%;
      background-color: $separator-color-2;
      margin: 12px 0 15px;
    }
    .delete-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      > i {
        cursor: pointer;
      }
    }
  }
}

.form-grid-item:hover {
  transition: all 0.15s ease;

  transform: scale(1.02);
}
a:hover {
  color: inherit;
}

.compact {
  height: 322px;
}
