@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.form-group-grid-item {
  @include ms-depth-4;
  @include cardBoxBorderRadius;

  width: 300px;
  height: 330px;
  margin: 30px 15px;
  background-color: #fff;

  transition: all 0.15s ease; // stylelint-disable-line
  display: inline-block;

  .form-group-image-container {
    width: 300px;
    height: 235px;
    border-radius: 2px 2px 0 0;
    margin: 0;
    padding: 10px 0px 0px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-group-image {
    width: 86px;
    height: 86px;
    margin: 0px 10px 10px 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: $form-extractor-logo-color;
  }

  .image-placeholder {
    background-color: $button-disabled-color;
  }

  .bottom-container {
    display: flex;
    flex-direction: column;
    padding: 12px;

    .form-group-name {
      font-size: 14px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 276px;
      padding: 0px;
    }
    .separator {
      height: 1px;
      width: 100%;
      background-color: $separator-color-2;
      margin: 12px 0 15px;
    }
    .delete-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      > i {
        cursor: pointer;
      }
    }
  }
}

.form-group-grid-item:hover {
  transition: all 0.15s ease; // stylelint-disable-line

  transform: scale(1.02);
}
a:hover {
  color: inherit;
}

.compact {
  height: 290px;
}
