import { ActionButton, PrimaryButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { useTeamPermission } from "../../hooks/permission";
import artkworkNoCustomModel from "../../images/artwork-no-custom-model.svg";
import { BriefCustomModel } from "../../types/customModel";
import CustomModelGridItem from "../CustomModelGridItem";
import ShortSpinner from "../ShortSpinner";
import styles from "./styles.module.scss";

interface Props {
  resourceOwnerId: string | undefined;
  hasMore: boolean;
  isEmpty: boolean;
  loadMore: (page: number) => void;
  customModels: BriefCustomModel[];
  onCreateCustomModel: () => void;
  onDeleteCustomModel: (customModelId: string) => void;
}

function CustomModelEmpty(props: { onCreateCustomModel: () => void }) {
  const { onCreateCustomModel } = props;
  const { hasPermissionToCreateResource } = useTeamPermission();

  return (
    <div className={styles["no-model"]}>
      <img src={artkworkNoCustomModel} alt="" />
      <div className={styles["title"]}>
        <FormattedMessage
          id={
            hasPermissionToCreateResource
              ? "custom_model_grid.no_model.title"
              : "custom_model_grid.team_no_model.title"
          }
        />
      </div>
      <div className={styles["desc"]}>
        <FormattedMessage id="custom_model_grid.no_model.desc" />
      </div>

      {hasPermissionToCreateResource && (
        <PrimaryButton
          iconProps={{ iconName: "Add" }}
          onClick={onCreateCustomModel}
        >
          <FormattedMessage id="custom_model_grid.no_model.button" />
        </PrimaryButton>
      )}
    </div>
  );
}

function _CustomModelGrid(props: Props) {
  const {
    customModels,
    onCreateCustomModel,
    onDeleteCustomModel,
    isEmpty,
    hasMore,
    loadMore,
  } = props;
  const { hasPermissionToCreateResource } = useTeamPermission();

  return (
    <div className={styles["container"]}>
      {hasPermissionToCreateResource && (
        <div className={styles["menu-bar"]}>
          <ActionButton
            className={styles["action-button"]}
            iconProps={{ iconName: "BuildQueueNew" }}
            onClick={onCreateCustomModel}
          >
            <FormattedMessage id="custom_model_grid.new_custom_model_button" />
          </ActionButton>
        </div>
      )}

      {!isEmpty ? (
        <InfiniteScroll
          className={styles["grid"]}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={<ShortSpinner key={0} />}
          useWindow={false}
        >
          {customModels.map(customModel => (
            <CustomModelGridItem
              name={customModel.name}
              url={
                (customModel.previewImage && customModel.previewImage.url) || ""
              }
              id={customModel.id}
              key={customModel.id}
              contentType={
                (customModel.previewImage &&
                  customModel.previewImage.contentType) ||
                ""
              }
              onDeleteCustomModel={onDeleteCustomModel}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <CustomModelEmpty onCreateCustomModel={onCreateCustomModel} />
      )}
    </div>
  );
}

const CustomModelGrid = React.memo(_CustomModelGrid);
export default CustomModelGrid;
