import * as React from "react";
import { AppearanceTypes, useToasts } from "react-toast-notifications";

import { useLocale } from "../contexts/locale";
import { LocalizerValue, ToastOptions } from "../models";

export function useToast() {
  const { localized } = useLocale();
  const { addToast, removeAllToasts, removeToast } = useToasts();

  const makeToastEmitter =
    (appearance: AppearanceTypes) =>
    (key: string, options?: ToastOptions, detail?: LocalizerValue) => {
      addToast(localized(key, detail), {
        appearance,
        ...options,
      });
    };

  return React.useMemo(
    () => ({
      info: makeToastEmitter("info"),
      success: makeToastEmitter("success"),
      warn: makeToastEmitter("warning"),
      error: makeToastEmitter("error"),
      dismiss: removeAllToasts,
      removeToast,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
