import { apiClient } from "../apiClient";
import { AppConfig } from "../config";
import errors, { FOCRError } from "../errors";
import { useThunkDispatch } from "../hooks/thunk";
import { Dispatch, RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import {
  BriefTeam,
  Permission,
  TeamInvitationIsValidResp,
  TeamMembersAndInvitations,
  TeamRef,
  TeamRenameResp,
  TeamSetUserPermissionResp,
  adminPermission,
} from "../types/team";
import { User } from "../types/user";
import { URLParamsKey, setParam } from "../utils/params";
import { handleConflict, requestUserConfirmation } from "./app";
import { createUser } from "./user";

export const TeamUserRemoved = "TeamUserRemoved";
export interface TeamUserRemoved {
  readonly type: typeof TeamUserRemoved;
  readonly teamId: string;
  readonly removedUserId: string;
  readonly currentUser: User;
}

export const CreateTeam = "CreateTeam";
export interface CreateTeamAction {
  readonly type: typeof CreateTeam;
  readonly team: BriefTeam;
  readonly permission: Permission;
}

export const UserPermissionUpdated = "UserPermissionUpdated";

export interface UserPermissionUpdated {
  readonly type: typeof UserPermissionUpdated;
  readonly permission: Permission;
  readonly teamId: string;
  readonly isCurrentUser: boolean;
}

export const TeamInvitationAccepted = "TeamInvitationAccepted";

export interface TeamInvitationAccepted {
  readonly type: typeof TeamInvitationAccepted;
  readonly team: BriefTeam;
  readonly permission: Permission;
}

export const TeamRenamed = "TeamRenamed";

export interface TeamRenamed {
  readonly type: typeof TeamRenamed;
  readonly teamId: string;
  readonly name: string;
}

export interface GotTeamRefs {
  readonly type: "GotTeamRefs";
  readonly region: string;
  readonly refs: TeamRef[];
}

export interface TeamDeleted {
  readonly type: "TeamDeleted";
  readonly teamId: string;
  readonly currentUser: User;
}

export function listTeamRef(region: string) {
  return async (dispatch: Dispatch): Promise<TeamRef[]> => {
    const refs = await apiClient.listTeamRefs(region);

    if (refs.length > 0) {
      dispatch({
        type: "GotTeamRefs",
        region,
        refs,
      });
    }

    return refs;
  };
}

export function createTeam(name: string, region: string, lookupId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<BriefTeam> => {
    const isCurrentUserExist = getState().user.currentUser !== undefined;
    const { region: currentRegion } = AppConfig;

    try {
      if (!isCurrentUserExist || region !== currentRegion) {
        await createUser(region)(dispatch);
      }
    } catch (e) {
      if (!(e instanceof FOCRError && e === errors.UserAlreadyExists)) {
        throw e;
      }
    }

    const team = await apiClient.createTeam(name, region, lookupId);

    setParam(URLParamsKey.team, team.lookupId);
    dispatch({ type: CreateTeam, team, permission: adminPermission });

    return team;
  };
}

function getCurrentTeamId(getState: () => RootState): Promise<string> {
  const { resourceOwnerId, isTeam } = getState().resourceOwner;

  if (isTeam && resourceOwnerId) {
    return Promise.resolve(resourceOwnerId);
  } else {
    return Promise.reject();
  }
}

export function listTeamMembers(teamId?: string) {
  return async (
    _dispatch: Dispatch,
    getState: () => RootState
  ): Promise<TeamMembersAndInvitations> => {
    if (!teamId) {
      teamId = await getCurrentTeamId(getState);
    }
    return await apiClient.listTeamMembers(teamId);
  };
}

export function setTeamUserPermission(
  userId: string,
  permission: Permission,
  retrievedAt: string,
  teamId?: string
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<TeamSetUserPermissionResp> => {
    const currentUserId = getState().user.currentUser?.id;

    if (currentUserId === userId) {
      await requestUserConfirmation({
        titleId: "team.change.permission.title",
        actionId: "common.confirm",
        messageId: "team.change.admin_permission.message",
        type: ConfirmModalType.Destory,
      })(dispatch, getState);
    }

    const resp = await handleConflict(
      async () => {
        if (!teamId) {
          teamId = await getCurrentTeamId(getState);
        }
        return apiClient.setTeamUserPermission(
          teamId,
          userId,
          permission,
          retrievedAt
        );
      },
      async () => {
        if (!teamId) {
          teamId = await getCurrentTeamId(getState);
        }
        return apiClient.setTeamUserPermission(
          teamId,
          userId,
          permission,
          undefined
        );
      },
      {
        titleId: "team.modified_prompt.title",
        messageId: "team.modified_prompt.desc",
        actionId: "common.save_and_overwrite",
      }
    )(dispatch, getState);

    const currentUser = getState().user.currentUser;
    if (!teamId) {
      teamId = await getCurrentTeamId(getState);
    }

    dispatch({
      type: UserPermissionUpdated,
      permission: permission,
      teamId: teamId,
      isCurrentUser: currentUser?.id === userId,
    });

    return resp;
  };
}

export function removeTeamUser(userId: string, teamId?: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    if (!teamId) {
      teamId = await getCurrentTeamId(getState);
    }
    await apiClient.removeTeamUser(teamId, userId);

    const currentUser = getState().user.currentUser;

    if (!currentUser) {
      return Promise.reject();
    }

    dispatch({
      type: TeamUserRemoved,
      teamId: teamId,
      removedUserId: userId,
      currentUser: currentUser,
    });

    return Promise.resolve();
  };
}

export function inviteTeamUser(
  email: string,
  permission: Permission,
  teamId?: string
) {
  return async (
    _dispatch: Dispatch,
    getState: () => RootState
  ): Promise<string> => {
    if (!teamId) {
      teamId = await getCurrentTeamId(getState);
    }
    return await apiClient.inviteTeamUser(teamId, email, permission);
  };
}

export function invitationIsValid(invitationCode: string) {
  return async (): Promise<TeamInvitationIsValidResp> => {
    return apiClient.invitationIsValid(invitationCode);
  };
}

export function acceptTeamInvitation(invitationCode: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<BriefTeam> => {
    const isCurrentUserExists = getState().user.currentUser !== undefined;

    if (!isCurrentUserExists) {
      await createUser(AppConfig.region)(dispatch);
    }

    const result = await apiClient.acceptTeamInvitation(invitationCode);

    dispatch({
      type: TeamInvitationAccepted,
      team: result.team,
      permission: result.permission,
    });

    return result.team;
  };
}

export function removeTeamInvitation(invitationCode: string) {
  return async (
    _dispatch: Dispatch,
    _getState: () => RootState
  ): Promise<void> => {
    await apiClient.removeInvitation(invitationCode);
  };
}

export function renameTeam(name: string, retrievedAt: string, teamId?: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<TeamRenameResp> => {
    const resp = await handleConflict(
      async () => {
        if (!teamId) {
          teamId = await getCurrentTeamId(getState);
        }
        return await apiClient.renameTeam(teamId, name, retrievedAt);
      },
      async () => {
        if (!teamId) {
          teamId = await getCurrentTeamId(getState);
        }
        return await apiClient.renameTeam(teamId, name, undefined);
      },
      {
        titleId: "team.modified_prompt.title",
        messageId: "team.modified_prompt.desc",
        actionId: "common.save_and_overwrite",
      }
    )(dispatch, getState);

    if (!teamId) {
      teamId = await getCurrentTeamId(getState);
    }

    dispatch({
      type: TeamRenamed,
      name: name,
      teamId: teamId,
    });

    return resp;
  };
}

export function deleteTeam(teamId?: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    if (!teamId) {
      teamId = await getCurrentTeamId(getState);
    }
    const currentUser = getState().user.currentUser;
    if (!currentUser) {
      return Promise.reject();
    }

    await apiClient.deleteTeam(teamId);

    dispatch({
      type: "TeamDeleted",
      teamId: teamId,
      currentUser: currentUser,
    });
  };
}

export type TeamAction =
  | CreateTeamAction
  | UserPermissionUpdated
  | TeamUserRemoved
  | TeamInvitationAccepted
  | TeamRenamed
  | TeamDeleted
  | GotTeamRefs;

export function useTeamActionCreator() {
  return {
    createTeam: useThunkDispatch(createTeam),
    listTeamRefs: useThunkDispatch(listTeamRef),
    listTeamMembers: useThunkDispatch(listTeamMembers),
    setTeamUserPermission: useThunkDispatch(setTeamUserPermission),
    removeTeamUser: useThunkDispatch(removeTeamUser),
    inviteTeamUser: useThunkDispatch(inviteTeamUser),
    invitationIsValid: useThunkDispatch(invitationIsValid),
    acceptTeamInvitation: useThunkDispatch(acceptTeamInvitation),
    removeTeamInvitation: useThunkDispatch(removeTeamInvitation),
    renameTeam: useThunkDispatch(renameTeam),
    deleteTeam: useThunkDispatch(deleteTeam),
  };
}
