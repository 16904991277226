import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { FileDefaultButton } from "../FileButton";
import styles from "./styles.module.scss";

interface Props {
  onSelectImage: (file?: File) => void;
}

export const DetectMultiDocumentTestPlaceholder: React.FC<Props> = (
  props: Props
) => {
  const { onSelectImage } = props;

  const onFiles = React.useCallback(
    (files?: File[]) => onSelectImage(files && files[0]),
    [onSelectImage]
  );

  return (
    <div className={styles["placeholder"]}>
      <div>
        <div className={styles["upload-icon"]}>
          <Icon iconName="TestBeakerSolid" />
        </div>

        <div className={styles["instructions"]}>
          <p>
            <FormattedMessage id="detect_documents_test.no_image" />
          </p>
          <p>
            <FileDefaultButton
              textId={"common.choose"}
              onFiles={onFiles}
              accept={SUPPORTED_EXTRACT_MIME.join(",")}
            />
          </p>
        </div>
      </div>
    </div>
  );
};
