@import "../../styles/variables.scss";

.session {
  width: 100%;
  padding: 20px 20px 20px 20px;
  height: 145px;
  margin-bottom: 20px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  border-radius: 2px;

  .title {
    margin-bottom: 15px;
    font-weight: 600;
  }

  .desc {
    line-height: 16px;
    a {
      text-decoration: underline;
    }

    a:focus {
      outline: none;
    }
  }

  .icon {
    position: absolute;
    top: 20px;
    right: 25px;

    img {
      width: 40px;
    }
  }
}

.session:hover {
  filter: brightness(85%);
  border: 1px solid #000000;
}

.session:active {
  filter: brightness(70%);
}

.session-fixed-format {
  background: #25d0b1;
  padding-right: 80px;
  color: #ffffff;
  span {
    color: #ffffff;
  }
}

.session-non-fixed-format {
  background: #6888fa;
  padding-right: 86px;
  color: #ffffff;
  span {
    color: #ffffff;
  }
}

.session-diff-layout-format {
  background: #ffffff;
  border: 1px solid #ffffff;
  padding-right: 60px;
  color: #464646;
  span {
    color: #464646;
  }
}
