import { ICommandBarItemProps } from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";

import { detectMultiDocument as detectMultiDocumentAction } from "../actions/detectMultiDocument";
import DetectMultiDocumentNavBar from "../components/DetectMultiDocumentNavBar";
import DetectMultiDocumentTest from "../components/DetectMultiDocumentTest";
import { chooseFile } from "../components/FileButton";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { SUPPORTED_EXTRACT_MIME } from "../constants";
import HeaderContainer from "../containers/Header";
import { useLocale } from "../contexts/locale";
import { FOCRError } from "../errors";
import { useWorkerToken } from "../hooks/app";
import { useToast } from "../hooks/toast";
import { DetectMultiDocumentReport } from "../models";

function useExtractInfo(token: string | undefined) {
  const [testReport, setOcrTestReport] = useState<
    DetectMultiDocumentReport | undefined
  >(undefined);

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const toast = useToast();

  const clearOcrTestReport = useCallback(() => {
    setOcrTestReport(undefined);
  }, []);

  const extractWithImageFile = useCallback(
    (file?: File) => {
      if (file && token) {
        setIsUploading(true);
        setIsLoading(true);
        const promise = detectMultiDocumentAction(token, file).then(
          x => x as DetectMultiDocumentReport
        );

        promise
          .then(resp => {
            setIsLoading(false);
            setIsUploading(false);
            setOcrTestReport(resp as DetectMultiDocumentReport);
          })
          .catch(error => {
            setIsUploading(false);
            setIsLoading(false);
            if (error instanceof FOCRError) {
              toast.error(error.messageId, undefined, error.detail);
            } else {
              toast.error("error.cannot_load_image");
            }
          });
      }
    },
    [token, toast]
  );

  return React.useMemo(
    () => ({
      testReport,
      clearOcrTestReport,
      extractWithImageFile,
      isLoading,
      isUploading,
    }),
    [
      testReport,
      clearOcrTestReport,
      extractWithImageFile,
      isLoading,
      isUploading,
    ]
  );
}

function useCommandBarItems(
  extractWithImageFile: (file?: File) => void,
  clearOcrTestReport: () => void,
  testReport: DetectMultiDocumentReport | undefined
) {
  const { localized } = useLocale();
  const onFiles = useCallback(
    (files?: File[]) => extractWithImageFile(files && files[0]),
    [extractWithImageFile]
  );

  return useMemo((): ICommandBarItemProps[] => {
    return [
      testReport
        ? {
            key: "choose",
            text: localized("detect_documents_test.upload_another_file"),
            iconProps: { iconName: "FileImage" },
            onClick: clearOcrTestReport,
          }
        : {
            key: "choose",
            text: localized("detect_documents_test.choose"),
            iconProps: { iconName: "FileImage" },
            onClick: () => {
              chooseFile(SUPPORTED_EXTRACT_MIME.join(","))
                .then(onFiles)
                .catch(() => {});
            },
          },
    ];
  }, [localized, testReport, onFiles, clearOcrTestReport]);
}

function _DetectMultiDocumentTestContainer() {
  const { token } = useWorkerToken();

  const {
    testReport,
    clearOcrTestReport,
    extractWithImageFile,
    isLoading,
    isUploading,
  } = useExtractInfo(token);

  const commbarBarItems = useCommandBarItems(
    extractWithImageFile,
    clearOcrTestReport,
    testReport
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {isLoading ? (
          <LoadingModal
            messageId="detect_documents_test.detecting"
            isOpen={isLoading}
          />
        ) : (
          <span />
        )}
        {isUploading ? (
          <LoadingModal messageId="common.uploading" isOpen={isUploading} />
        ) : (
          <span />
        )}
        <DetectMultiDocumentNavBar commbarBarItems={commbarBarItems}>
          <DetectMultiDocumentTest
            onSelectImage={extractWithImageFile}
            testReport={testReport}
          />
        </DetectMultiDocumentNavBar>
      </Main>
    </Layout>
  );
}

export const DetectMultiDocumentTestContainer = React.memo(
  _DetectMultiDocumentTestContainer
);
export default DetectMultiDocumentTestContainer;
