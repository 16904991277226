import { Icon, Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";

import { list } from "../../actions/form";
import { useTeamPermission } from "../../hooks/permission";
import { RootState, ThunkActionCreatorType } from "../../redux/types";
import { BriefForm } from "../../types/form";
import FormGridItem from "../FormGridItem";
import ShortSpinner from "../ShortSpinner";
import styles from "./styles.module.scss";

interface Props {
  forms: BriefForm[];
  hasMore: boolean;
  isEmpty: boolean;

  list: ThunkActionCreatorType<typeof list>;
  onDeleteForm(formId: string): void;
}

function EmptyStateUI() {
  const { hasPermissionToCreateResource } = useTeamPermission();
  const isTeam = useSelector<RootState, boolean>(
    (state: RootState) => state.resourceOwner.isTeam
  );

  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        {hasPermissionToCreateResource && <Icon iconName="PageAdd" />}
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage
              id={
                isTeam ? "form_grid.team_no_form_yet" : "form_grid.no_form_yet"
              }
            />
          </Label>
          <Label>
            {hasPermissionToCreateResource && (
              <FormattedMessage id="form_grid.empty_state_tips" />
            )}
          </Label>
        </div>
      </div>
    </div>
  );
}

function _FormGrid(props: Props) {
  const { list } = props;
  const loadMore = React.useCallback(
    (_page: number) => {
      return list();
    },
    [list]
  );
  const resourceOwnerId = useSelector((state: RootState) => {
    return state.resourceOwner.resourceOwnerId;
  });

  const { isEmpty, forms, onDeleteForm, hasMore } = props;
  return (
    <div className={styles["form-grid"]}>
      {isEmpty ? (
        <EmptyStateUI />
      ) : (
        <InfiniteScroll
          className={styles["form-list"]}
          loadMore={loadMore}
          hasMore={hasMore}
          key={resourceOwnerId}
          loader={<ShortSpinner key={0} />}
          useWindow={false}
        >
          {forms.map((form, i) => (
            <FormGridItem onDeleteForm={onDeleteForm} form={form} key={i} />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}

export const FormGrid = React.memo(_FormGrid);
export default FormGrid;
