import { Icon, Link, PrimaryButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import _ from "lodash";
import * as React from "react";

import {
  SUPPORTED_EXTRACT_MIME,
  SampleFormImageMapping,
  TemplateDescriptionMapping,
  TemplateSampleMapping,
} from "../../constants";
import { useDragAndDropFiles } from "../../hooks/drag_and_drop";
import extractErrorIcon from "../../images/extract-error.svg";
import {
  OCRTestReport,
  OCRTestReportError,
  OCRTestReportMultipleDocument,
  OCRTestReportSingleDocument,
} from "../../models";
import { FormExtractionMode } from "../../types/form";
import {
  ExternalServicesAlertBoxForForm,
  ExternalServicesAlertBoxForReceipt,
} from "../ExternalServiceAlertBox";
import ImageSelector from "../ImageSelector";
import { OCRTestPlaceholder } from "../OCRTestPlaceholder";
import { SampleReceiptImages } from "../ReceiptTest";
import { OCRTestReportTable } from "./OCRTestReportTable";
import styles from "./styles.module.scss";

interface Props {
  onSelectImage: (file?: File) => void;
  onSelectSampleImage: (url: string) => void;
  ocrTestReport?: OCRTestReport;
  isSampleForm: boolean;
  isReceiptForm: boolean;
  isTemplate: boolean;
  formName: string;
  extractionMode: FormExtractionMode;
  onExtractionModeChanged: (extractionMode: FormExtractionMode) => void;
}

function _convertOCRReportSingleDocumentToOutputJSON(
  ocrTestReport: OCRTestReportSingleDocument
) {
  return {
    status: "ok",
    form_id: ocrTestReport.form_id,
    fields: ocrTestReport.fields.map(x => _.omit(x, "image")),
    auto_extraction_items: ocrTestReport.auto_extraction_items?.map(x =>
      _.omit(x, "image")
    ),
    key_values: ocrTestReport.key_values,
    token_groups: ocrTestReport.token_groups,
    custom_models: ocrTestReport.custom_models,
  };
}

function convertOCRReportSingleDocumentToOutputJSON(
  ocrTestReport: OCRTestReportSingleDocument
) {
  return (
    <pre>
      {JSON.stringify(
        _convertOCRReportSingleDocumentToOutputJSON(ocrTestReport),
        null,
        2
      )}
    </pre>
  );
}

function convertOCRReportMultipleDocumentToOutputJSON(
  ocrTestReport: OCRTestReportMultipleDocument,
  cursor: number,
  currentJsonRef: React.RefObject<HTMLPreElement>
) {
  const documents = ocrTestReport.documents.map(d => {
    let result = "";
    if ("error" in d) {
      result = JSON.stringify(d, null, 2);
    } else {
      const { status, ...data } =
        _convertOCRReportSingleDocumentToOutputJSON(d);
      result = JSON.stringify(data, null, 2);
    }
    return result
      .split("\n")
      .map(s => "    " + s)
      .join("\n");
  });

  return (
    <pre>
      <pre>{"{"}</pre>
      <pre>{'  "status": "ok",'}</pre>
      <pre>{'  "documents": ['}</pre>
      {documents.map((d, index) => (
        <pre
          ref={cursor === index ? currentJsonRef : undefined}
          className={cursor === index ? styles["highlighted"] : undefined}
        >
          {d}
          {index < documents.length - 1 && ","}
        </pre>
      ))}
      <pre>{"  ]"}</pre>
      <pre>{"}"}</pre>
    </pre>
  );
}

function _OCRTest(props: Props) {
  useDragAndDropFiles(
    (files?: File[]) => props.onSelectImage(files && files[0]),
    SUPPORTED_EXTRACT_MIME
  );

  const {
    ocrTestReport,
    formName,
    isSampleForm,
    isReceiptForm,
    onSelectImage,
    onSelectSampleImage,
    extractionMode,
    onExtractionModeChanged,
  } = props;

  const samples =
    (isSampleForm
      ? SampleFormImageMapping
      : isReceiptForm
      ? SampleReceiptImages
      : TemplateSampleMapping[formName]) || [];

  const templateDescription =
    props.isTemplate && TemplateDescriptionMapping[formName];

  return (
    <div className={styles["ocr-test"]}>
      {isReceiptForm ? (
        <ExternalServicesAlertBoxForReceipt />
      ) : (
        <ExternalServicesAlertBoxForForm />
      )}
      {samples.length > 0 ? (
        <div className={styles["sample-image-box"]}>
          <h1>
            <FormattedMessage
              id={
                isReceiptForm
                  ? "receipt_test.try_sample"
                  : "ocr_test.try_sample"
              }
            />
            {templateDescription &&
              (templateDescription.url ? (
                <a
                  href={templateDescription.url}
                  target="_blank"
                  className={styles["template-description"]}
                >
                  <Icon iconName="Lightbulb" />
                  <FormattedMessage id={templateDescription.label} />
                </a>
              ) : (
                <span className={styles["template-description"]}>
                  <Icon iconName="Lightbulb" />
                  <FormattedMessage id={templateDescription.label} />
                </span>
              ))}
          </h1>
          <ImageSelector images={samples} onSelectImage={onSelectSampleImage} />
        </div>
      ) : (
        <div />
      )}
      {ocrTestReport ? (
        <OCRReportView report={ocrTestReport} />
      ) : (
        <OCRTestPlaceholder
          onSelectImage={onSelectImage}
          extractionMode={extractionMode}
          onExtractionModeChanged={onExtractionModeChanged}
        />
      )}
    </div>
  );
}

interface OCRReportViewProps {
  report: OCRTestReport;
}

function OCRReportView(props: OCRReportViewProps) {
  const { report } = props;

  const currentJsonRef = React.useRef<HTMLPreElement>(null);

  const isMultiDocument = React.useMemo(() => "documents" in report, [report]);

  const documentCount = React.useMemo(
    () => ("documents" in report ? report.documents.length : 1),
    [report]
  );

  const [cursor, setCursor] = React.useState(0);

  React.useEffect(() => {
    setCursor(0);
  }, [report]);

  const currentDocument = React.useMemo<
    OCRTestReportSingleDocument | OCRTestReportError | undefined
  >(() => {
    if ("documents" in report) {
      if (cursor < report.documents.length) {
        const document = report.documents[cursor];
        return document;
      }
      return undefined;
    } else {
      return report;
    }
  }, [report, cursor]);

  const jumpToJson = React.useCallback(() => {
    if (currentJsonRef && currentJsonRef.current) {
      currentJsonRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [currentJsonRef]);

  return (
    <div className={styles["report"]}>
      <div className={styles["section"]}>
        <h1>
          <FormattedMessage id="ocr_test.warped_image" />
        </h1>
        <div className={styles["warped-image-wrapper"]}>
          {isMultiDocument && (
            <PrimaryButton
              className={styles["control-button"]}
              iconProps={{ iconName: "ChevronLeft" }}
              onClick={() => setCursor(cursor - 1)}
              disabled={cursor === 0}
            />
          )}

          {currentDocument &&
            ("error" in currentDocument ? (
              <div className={styles["extract-error"]}>
                <img src={extractErrorIcon} alt="error" />
                <p>
                  <FormattedMessage id="ocr_test.extract_error" />
                </p>
                <p className={styles["reason"]}>
                  <FormattedMessage
                    id="ocr_test.extract_error.reason"
                    values={{ message: currentDocument.error.message }}
                  />
                </p>
              </div>
            ) : (
              <img src={currentDocument.warped_image} alt="warped" />
            ))}

          {isMultiDocument && (
            <>
              <PrimaryButton
                className={styles["control-button"]}
                iconProps={{ iconName: "ChevronRight" }}
                onClick={() => setCursor(cursor + 1)}
                disabled={cursor === documentCount - 1}
              />
              <div className={styles["pagination"]}>
                {`${cursor + 1} / ${documentCount}`}
              </div>
              <div className={styles["jump-to-json"]}>
                <Link onClick={jumpToJson}>
                  <FormattedMessage id="ocr_test.jump_to_json" />
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      {currentDocument && !("error" in currentDocument) && (
        <OCRTestReportTable report={currentDocument} />
      )}

      <div className={styles["section"]}>
        <h1>
          <FormattedMessage id="ocr_test.json" />
        </h1>
        {"documents" in report
          ? convertOCRReportMultipleDocumentToOutputJSON(
              report,
              cursor,
              currentJsonRef
            )
          : convertOCRReportSingleDocumentToOutputJSON(report)}
      </div>
    </div>
  );
}

export const OCRTest = React.memo(_OCRTest);
export default OCRTest;
