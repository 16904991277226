import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { useFormActionCreator } from "../actions/form";
import ConfirmModal from "../components/ConfirmModal";
import CreateCustomModelModal from "../components/CreateCustomModelModal";
import CreateDocumentExtractorModal from "../components/CreateDocumentExtractorModal";
import { CreateFormBarLayout } from "../components/CreateFormBar";
import CreateFormModal from "../components/CreateFormModal";
import { chooseFile } from "../components/FileButton";
import FormGrid from "../components/FormGrid";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { FOCRError } from "../errors";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { BriefForm } from "../types/form";
import { rotateAndCompressImage } from "../utils/image";

function useReduxStore() {
  const forms = useSelector<RootState, BriefForm[]>(state => state.form.forms);
  const hasMore = useSelector<RootState, boolean>(state =>
    state.form.pageInfo ? state.form.pageInfo.hasNext : true
  );
  const isEmpty = useSelector<RootState, boolean>(state =>
    state.form.pageInfo === undefined
      ? false
      : !state.form.pageInfo.hasNext && state.form.forms.length === 0
  );

  return useMemo(
    () => ({ forms, hasMore, isEmpty }),
    [forms, hasMore, isEmpty]
  );
}

function _FormGridContainer() {
  const [isDeleteFormConfirmModalOpened, setIsDeleteFormConfirmModalOpened] =
    useState(false);
  const [isCreateFormModalOpened, setIsCreateFormModalOpened] = useState(false);
  const [isCreatingForm, setIsCreatingForm] = useState(false);
  const [
    isCreateDocumentExtractorModalOpened,
    setIsCreateDocumentExtractorModalOpened,
  ] = useState(false);
  const [isCreateCustomModelModalOpened, setIsCreateCustomModelModalOpened] =
    useState(false);
  const [isImportingForm, setIsImportingForm] = useState(false);
  const [uploadImageSupported, setUploadImageSupported] = useState(false);
  const [formIdToDelete, setFormIdToDelete] = useState<undefined | string>(
    undefined
  );

  const { list, create, remove, importForm } = useFormActionCreator();
  const { forms, hasMore, isEmpty } = useReduxStore();

  const history = useHistory();
  const toast = useToast();

  const onDeleteForm = useCallback((formId: string) => {
    setIsDeleteFormConfirmModalOpened(true);
    setFormIdToDelete(formId);
  }, []);

  const closeDeleteFormModal = useCallback(() => {
    setIsDeleteFormConfirmModalOpened(false);
    setFormIdToDelete(undefined);
  }, []);

  const onConfirmDelete = useCallback(() => {
    closeDeleteFormModal();
    if (!formIdToDelete) {
      return;
    }

    remove(formIdToDelete)
      .then(() => {
        setFormIdToDelete(undefined);
      })
      .catch(e => {
        if (e instanceof FOCRError) {
          toast.error(e.messageId);
        } else {
          toast.error("error.error_during_remove_form");
        }
      });
  }, [closeDeleteFormModal, formIdToDelete, remove, toast]);

  const closeAllModal = useCallback(() => {
    setIsCreateFormModalOpened(false);
    setIsCreateDocumentExtractorModalOpened(false);
    setIsCreateCustomModelModalOpened(false);
  }, []);

  const onCreateForm = useCallback(() => {
    setIsCreateDocumentExtractorModalOpened(true);
  }, []);

  const doCreateForm = useCallback(
    (formName: string, file?: File) => {
      closeAllModal();
      setIsCreatingForm(true);

      const promise: Promise<File | undefined> = file
        ? rotateAndCompressImage(file)
        : Promise.resolve(undefined);

      promise
        .then((image?: File) => create(formName, image))
        .then(form => {
          setIsCreatingForm(false);
          const url = `/form/${form.id}/edit`;
          history.push(url);
        })
        .catch(e => {
          setIsCreatingForm(false);
          if (e instanceof FOCRError) {
            toast.error(e.messageId);
          } else {
            toast.error("error.fail_to_create_form");
          }
        });
    },
    [closeAllModal, create, history, toast]
  );

  const onImportForm = useCallback(async () => {
    try {
      const files = await chooseFile("application/zip");
      if (!files || files.length === 0) return;
      setIsImportingForm(true);
      await importForm(files[0]);
      toast.success("common.import_form_success");
    } catch {
      toast.error("error.fail_to_import_form");
    } finally {
      setIsImportingForm(false);
    }
  }, [importForm, toast]);

  const onFixedDocumentFormatChosen = useCallback(() => {
    setIsCreateFormModalOpened(true);
    setIsCreateDocumentExtractorModalOpened(false);
    setUploadImageSupported(true);
  }, []);

  const onNonFixedDocumentFormatChosen = useCallback(() => {
    setIsCreateFormModalOpened(true);
    setIsCreateDocumentExtractorModalOpened(false);
    setUploadImageSupported(false);
  }, []);

  const onDiffLayoutDocumentFormatChosen = useCallback(() => {
    setIsCreateCustomModelModalOpened(true);
    setIsCreateDocumentExtractorModalOpened(false);
  }, []);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <CreateFormBarLayout
          type="form"
          onCreate={onCreateForm}
          onImport={onImportForm}
        >
          <FormGrid
            forms={forms}
            list={list}
            hasMore={hasMore}
            isEmpty={isEmpty}
            onDeleteForm={onDeleteForm}
          />
        </CreateFormBarLayout>
      </Main>
      <ConfirmModal
        isOpen={isDeleteFormConfirmModalOpened}
        modalType={ConfirmModalType.Destory}
        titleId="form_grid.delete_form"
        messageId="form_grid.are_you_sure_to_delete_form"
        actionId="form_grid.delete"
        onCancel={closeDeleteFormModal}
        onConfirm={onConfirmDelete}
      />
      <CreateFormModal
        isOpen={isCreateFormModalOpened}
        onCancel={closeAllModal}
        onCreate={doCreateForm}
        uploadImageSupported={uploadImageSupported}
      />
      <CreateCustomModelModal
        isOpen={isCreateCustomModelModalOpened}
        onClose={closeAllModal}
      />
      <CreateDocumentExtractorModal
        isOpen={isCreateDocumentExtractorModalOpened}
        onCancel={closeAllModal}
        onFixedDocumentFormatChosen={onFixedDocumentFormatChosen}
        onNonFixedDocumentFormatChosen={onNonFixedDocumentFormatChosen}
        onDiffLayoutDocumentFormatChosen={onDiffLayoutDocumentFormatChosen}
      />
      <LoadingModal
        isOpen={isCreatingForm || isImportingForm}
        messageId={
          isCreatingForm
            ? "form_grid.creating_form"
            : "form_grid.importing_form"
        }
      />
    </Layout>
  );
}

export const FormGridContainer = React.memo(_FormGridContainer);
export default FormGridContainer;
