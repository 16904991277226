@import "../../styles/variables.scss";

.container {
  max-width: 300px;
  max-height: 320px;
  min-width: 300px;
  min-height: 320px;
  margin: 32px;

  background: #ffffff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  position: relative;
  overflow: hidden;

  .preivew-container {
    min-width: 300px;
    max-width: 300px;
    min-height: 200px;
    max-height: 200px;
  }

  .image-preview {
    object-fit: cover;
    min-width: inherit;
    max-width: inherit;
    min-height: inherit;
    max-height: inherit;
  }

  .pdf-icon {
    position: absolute;
    left: 50%;
    top: 60px;
    width: 72px;
    height: 72px;
    transform: translate(-50%, 0);
  }

  .name {
    position: absolute;
    top: 240px;
    left: 12px;
    right: 12px;
    max-width: 276px;
    overflow: hidden;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }

  .label {
    position: absolute;
    top: 212px;
    left: 12px;
    right: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $darker-primary-color;
  }

  .divider {
    position: absolute;
    top: 272px;
    height: 1px;
    left: 12px;
    right: 12px;
    background: #edebe9;
  }

  .buttons {
    position: absolute;
    top: 280px;
    right: 12px;
    :global .ms-Button-icon {
      color: #000000;
    }
  }
}

.container:hover {
  transition: all 0.15s ease;

  transform: scale(1.02);
}

.compact {
  min-height: 282px;
}
