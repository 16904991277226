import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useTeamPermission } from "../../hooks/permission";
import {
  DetailFormGroup,
  FormGroupAnchorBase,
  FormGroupTokenGroupBase,
} from "../../types/formGroup";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import { FormGroupAnchorList } from "./FormGroupAnchorList";
import { FormGroupTokenGroupList } from "./FormGroupTokenGroupList";
import styles from "./styles.module.scss";

interface Props {
  formGroup: DetailFormGroup;
  onRenameFormGroup: () => void;
  onOpenFormGroupScriptModal: () => void;
  onUpdateAnchors: (anchors: FormGroupAnchorBase[]) => Promise<void>;
  onUpdateTokenGroups: (
    tokenGroups: FormGroupTokenGroupBase[]
  ) => Promise<void>;
}

const FormGroupEditor = React.memo((props: Props) => {
  const {
    formGroup,
    onRenameFormGroup,
    onUpdateAnchors,
    onUpdateTokenGroups,
    onOpenFormGroupScriptModal,
  } = props;

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["form-group-editor"]}>
      <div className={styles["buttons"]}>
        {hasPermissionToEditResource && (
          <DefaultButton
            textId="form_group_editor.rename"
            onClick={onRenameFormGroup}
          />
        )}
        {hasPermissionToEditResource && (
          <DefaultButton
            textId="form_group_editor.edit_post_process_script"
            onClick={onOpenFormGroupScriptModal}
          />
        )}
      </div>

      <h1>
        <FormattedMessage id="form_group_editor.forms" />
      </h1>
      <div className={styles["section"]}>
        {formGroup.type === "anchor" && (
          <FormGroupAnchorList
            formGroup={formGroup}
            onUpdateAnchors={onUpdateAnchors}
          />
        )}
        {formGroup.type === "token" && (
          <FormGroupTokenGroupList
            formGroup={formGroup}
            onUpdateTokenGroups={onUpdateTokenGroups}
          />
        )}
      </div>
    </div>
  );
});

export default FormGroupEditor;
