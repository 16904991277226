import { apiClient } from "../apiClient";
import {
  MIN_NUMBER_OF_FORM_FEATURE,
  MIN_NUMBER_OF_QUERY_FEATURE,
} from "../constants";
import { useThunkDispatch } from "../hooks/thunk";
import {
  Dimension,
  OCRTestReportMultipleDocument,
  OCRTestReportSingleDocument,
  UpdateImagePayload,
} from "../models";
import { Dispatch } from "../redux/types";
import { LambdaSuccess, RootState } from "../redux/types";
import { Anchor } from "../types/anchor";
import { DetectionRegion } from "../types/detectionRegion";
import { Field } from "../types/field";
import { BriefForm, DetailedForm, FormExtractionMode } from "../types/form";
import { FormSettings } from "../types/formConfig";
import { KeyValue } from "../types/keyValue";
import {
  MerchantExtractionFallbackStrategry,
  MerchantSetting,
} from "../types/merchantSetting";
import { PageInfo } from "../types/pageInfo";
import { TokenGroup } from "../types/tokenGroup";
import { deepClone } from "../utils/deepClone";
import { triggerFileSave, uploadAsset } from "../utils/file";
import { isDetectorSettingChanged } from "../utils/formConfig";
import { getImageSizeFromFile } from "../utils/image";
import { workerClient } from "../workerClient";
import { selectTeam } from "./resourceOwner";

interface OCRTestResponseSingleDocument
  extends LambdaSuccess,
    OCRTestReportSingleDocument {}

interface OCRTestResponseMultipleDocument
  extends LambdaSuccess,
    OCRTestReportMultipleDocument {}

type OCRTestResponse =
  | OCRTestResponseSingleDocument
  | OCRTestResponseMultipleDocument;

export const GettingFormList = "GettingFormList";
export interface GettingFormListAction {
  readonly type: typeof GettingFormList;
  readonly listVersion: number;
}

export const GotFormList = "GotFormList";
export interface GotFormListAction {
  readonly type: typeof GotFormList;
  readonly payload: {
    forms: BriefForm[];
    pageInfo: PageInfo;
    listVersion: number;
  };
}

export const FormCreated = "FormCreated";
export interface FormCreatedAction {
  readonly type: typeof FormCreated;
  readonly payload: {
    form: DetailedForm;
  };
}

export const GotForm = "GotForm";
export interface GotFormAction {
  readonly type: typeof GotForm;
  readonly payload: {
    form: DetailedForm;
  };
}

export const DiscardForm = "DiscardForm";
export interface ClearFormAction {
  readonly type: typeof DiscardForm;
}

export const FormRemoved = "FormRemoved";
export interface FormRemovedAction {
  readonly type: typeof FormRemoved;
  readonly payload: {
    formId: string;
  };
}

export const GotFormImageSize = "GotFormImageSize";
export interface GotFormImageSizeAction {
  readonly type: typeof GotFormImageSize;
  readonly payload: Dimension;
}

export const AnchorAdded = "AnchorAdded";
export interface AnchorAddedAction {
  readonly type: typeof AnchorAdded;
  readonly payload: {
    anchor: Anchor;
  };
}

export const AnchorUpdated = "AnchorUpdated";
export interface AnchorUpdatedAction {
  readonly type: typeof AnchorUpdated;
  readonly payload: {
    anchor: Anchor;
  };
}

export const AnchorDeleted = "AnchorDeleted";
export interface AnchorDeletedAction {
  readonly type: typeof AnchorDeleted;
  readonly payload: {
    anchorId: string;
  };
}

export const FieldAdded = "FieldAdded";
export interface FieldAddedAction {
  readonly type: typeof FieldAdded;
  readonly payload: {
    field: Field;
  };
}

export const FieldUpdated = "FieldUpdated";
export interface FieldUpdatedAction {
  readonly type: typeof FieldUpdated;
  readonly payload: {
    field: Field;
  };
}

export const FieldDeleted = "FieldDeleted";
export interface FieldDeletedAction {
  readonly type: typeof FieldDeleted;
  readonly payload: {
    fieldId: string;
  };
}

export const DetectionRegionAdded = "DetectionRegionAdded";
export interface DetectionRegionAddedAction {
  readonly type: typeof DetectionRegionAdded;
  readonly payload: {
    detectionRegion: DetectionRegion;
  };
}

export const DetectionRegionUpdated = "DetectionRegionUpdated";
export interface DetectionRegionUpdatedAction {
  readonly type: typeof DetectionRegionUpdated;
  readonly payload: {
    detectionRegion: DetectionRegion;
  };
}

export const DetectionRegionDeleted = "DetectionRegionDeleted";
export interface DetectionRegionDeletedAction {
  readonly type: typeof DetectionRegionDeleted;
  readonly payload: {
    detectionRegionId: string;
  };
}

export const FormUpdated = "FormUpdated";
export interface FormUpdatedAction {
  readonly type: typeof FormUpdated;
  readonly payload: {
    formId: string;
    imagePayload?: UpdateImagePayload;
    settings?: FormSettings;
    keyValues?: KeyValue[];
    tokenGroups?: TokenGroup[];
    customMerchants?: MerchantSetting[];
    cutomMerchantsFallback?: MerchantExtractionFallbackStrategry;
    customModelIds?: string[];
  };
}

export const FormSaved = "FormSaved";
export interface FormSavedAction {
  readonly type: typeof FormSaved;
  readonly payload: {
    form: BriefForm;
  };
}

export const FormsInvalidated = "FormsInvalidated";
export interface FormsInvalidatedAction {
  readonly type: typeof FormsInvalidated;
}

export function list(size: number = 20) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const state = getState();
    const { areFormFetched, pageInfo, listVersion } = state.form;
    const { resourceOwnerId } = state.resourceOwner;
    const newVersion = listVersion + 1;

    if (areFormFetched && pageInfo && !pageInfo.hasNext) {
      dispatch({
        type: GotFormList,
        payload: {
          forms: [],
          pageInfo: pageInfo,
          listVersion: newVersion,
        },
      });
      return Promise.resolve();
    }

    const options = undefined;

    const cursor =
      areFormFetched && pageInfo && pageInfo.hasNext ? pageInfo.cursor : "";

    dispatch({
      type: GettingFormList,
      listVersion: newVersion,
    });

    const listResult = await apiClient.listForm(
      size,
      cursor,
      options,
      resourceOwnerId
    );

    dispatch({
      type: GotFormList,
      payload: {
        forms: listResult.forms,
        pageInfo: listResult.pageInfo,
        listVersion: newVersion,
      },
    });
  };
}

export async function createImageToken(file: File) {
  const { url, name } = await uploadAsset(file);
  return {
    url,
    assetId: name,
  };
}

export function create(formName: string, image?: File) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<BriefForm> => {
    const { resourceOwnerId } = getState().resourceOwner;

    const options = image
      ? {
          masterImageInfo: {
            size: await getImageSizeFromFile(image),
            imageId: (await apiClient.createAsset(image)).name,
          },
        }
      : undefined;

    const formId = (
      await apiClient.createForm(formName, options, resourceOwnerId)
    ).id;
    const detailedForm = await apiClient.getForm(formId, resourceOwnerId);

    dispatch({
      type: FormCreated,
      payload: {
        form: detailedForm,
      },
    });

    return getState().form.forms[0];
  };
}

export function updateImage(image: File) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { currentForm } = getState().form;
    if (!currentForm) return;

    const imageDimension = await getImageSizeFromFile(image);
    const { url: imageUrl, name: imageId } = await apiClient.createAsset(image);

    if (!imageUrl || !imageId) {
      return;
    }

    dispatch({
      type: GotFormImageSize,
      payload: {
        ...imageDimension,
      },
    });

    dispatch({
      type: FormUpdated,
      payload: {
        formId: currentForm.id,
        imagePayload: {
          imageUrl,
          imageId,
        },
      },
    });
  };
}

export function get(formId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const form = await apiClient.getForm(formId, resourceOwnerId);

    if (form.resourceOwnerId) {
      selectTeam(form.resourceOwnerId)(dispatch, getState);
    }

    dispatch({
      type: GotForm,
      payload: {
        form,
      },
    });
  };
}

export function discardForm() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: DiscardForm,
    });
  };
}

export function remove(formId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    await apiClient.deleteForm(formId, resourceOwnerId);

    dispatch({
      type: FormRemoved,
      payload: {
        formId: formId,
      },
    });
  };
}

export function getFormImageSize() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const currentForm = getState().form.currentForm;
    if (!currentForm || !currentForm.image) {
      return;
    }

    const image = new Image();
    image.onload = () => {
      dispatch({
        type: GotFormImageSize,
        payload: {
          width: image.width,
          height: image.height,
        },
      });
    };
    image.src = currentForm.image;
  };
}

export function addAnchor(anchor: Anchor) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AnchorAdded,
      payload: { anchor },
    });
  };
}

export function updateAnchor(anchor: Anchor) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AnchorUpdated,
      payload: { anchor },
    });
  };
}

export function deleteAnchor(anchorId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AnchorDeleted,
      payload: { anchorId },
    });
  };
}

export function addField(field: Field) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: FieldAdded,
      payload: { field },
    });
  };
}

export function updateField(field: Field) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: FieldUpdated,
      payload: { field },
    });
  };
}

export function deleteField(fieldId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: FieldDeleted,
      payload: { fieldId },
    });
  };
}

export function addDetectionRegion(detectionRegion: DetectionRegion) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: DetectionRegionAdded,
      payload: { detectionRegion },
    });
  };
}

export function updateDetectionRegion(detectionRegion: DetectionRegion) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: DetectionRegionUpdated,
      payload: { detectionRegion },
    });
  };
}

export function deleteDetectionRegion(detectionRegionId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: DetectionRegionDeleted,
      payload: { detectionRegionId },
    });
  };
}

export function updateForm(settings: FormSettings) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const { currentForm } = getState().form;
    if (!currentForm) return;

    dispatch({
      type: FormUpdated,
      payload: {
        formId: currentForm.id,
        settings: settings,
      },
    });
  };
}

export function updateFormKeyValues(keyValues: KeyValue[]) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const { currentForm } = getState().form;
    if (!currentForm) return;

    dispatch({
      type: FormUpdated,
      payload: {
        formId: currentForm.id,
        keyValues,
      },
    });
  };
}

export function updateFormTokenGroups(tokenGroups: TokenGroup[]) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const { currentForm } = getState().form;
    if (!currentForm) return;

    dispatch({
      type: FormUpdated,
      payload: {
        formId: currentForm.id,
        tokenGroups,
      },
    });
  };
}

export function updateCustomMerchants(customMerchants: MerchantSetting[]) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const { currentForm } = getState().form;
    if (!currentForm) return;

    dispatch({
      type: FormUpdated,
      payload: {
        formId: currentForm.id,
        customMerchants,
      },
    });
  };
}

export function updateFormCustomModels(customModelIds: string[]) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const { currentForm } = getState().form;
    if (!currentForm) return;

    dispatch({
      type: FormUpdated,
      payload: {
        formId: currentForm.id,
        customModelIds,
      },
    });
  };
}

function ensureFormConfigValue(form: DetailedForm) {
  if ("detector" in form.config && form.config.detector.type === "orb") {
    form.config.detector.number_of_source_feature = Math.max(
      MIN_NUMBER_OF_FORM_FEATURE,
      form.config.detector.number_of_source_feature
    );

    form.config.detector.number_of_query_feature = Math.max(
      MIN_NUMBER_OF_QUERY_FEATURE,
      form.config.detector.number_of_query_feature
    );
  }
}

export function saveForm(shouldIgnoreConflict: boolean = false) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const state = getState();
    const {
      currentForm,
      originalForm,
      isAnyAnchorChanged,
      isAnyFieldOrDetectionRegionChanged,
      currentFormImageSize,
    } = state.form;

    const { resourceOwnerId } = state.resourceOwner;

    if (!currentForm || !originalForm) throw "No form is selected";

    ensureFormConfigValue(currentForm);

    const {
      id: formId,
      name: formName,
      config,
      tokenGroups,
      keyValues,
      imageId,
      customModelIds,
      updatedAt,
    } = currentForm;

    const shouldRecomputeFeatures =
      isAnyAnchorChanged ||
      isDetectorSettingChanged(currentForm.config, originalForm.config) ||
      imageId !== undefined;

    const updatedForm = await apiClient.updateForm(
      formId,
      {
        name: formName.trim(),
        config: deepClone(config),
        shouldRecomputeFeatures,
        tokenGroups,
        keyValues,
        customModelIds,
        lastRetrieved: updatedAt,
        data:
          isAnyAnchorChanged || isAnyFieldOrDetectionRegionChanged
            ? {
                detectionRegions: currentForm.detectionRegions,
                anchors: currentForm.anchors,
                fields: currentForm.fields,
              }
            : undefined,
        shouldOverwrite: shouldIgnoreConflict,
        ...(currentFormImageSize && imageId
          ? {
              masterImageInfo: {
                imageId,
                size: currentFormImageSize,
              },
            }
          : {}),
      },
      resourceOwnerId
    );

    dispatch({
      type: FormSaved,
      payload: {
        form: updatedForm,
      },
    });
  };
}

export async function extractFieldInTestMode(
  token: string,
  entityId: string,
  image: File,
  extractionMode: FormExtractionMode,
  startsRecognizing: () => void
): Promise<OCRTestResponse> {
  return await workerClient(token).extractFieldInTestMode(
    entityId,
    image,
    extractionMode,
    startsRecognizing
  );
}

export async function exportForm(
  formId: string,
  resourceOwnerId?: string,
  region?: string
): Promise<void> {
  const path =
    `/export-form?form_id=${formId}` +
    (resourceOwnerId ? `&resource_owner_id=${resourceOwnerId}` : "");

  const url = apiClient.getEffectiveEndpoint(path, region);
  const response = await apiClient.fetch(url);
  triggerFileSave(response);
}

export function importForm(file: File, formId: string | null = null) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<any> => {
    const url = apiClient.getEffectiveEndpoint("/import-form");
    const { resourceOwnerId } = getState().resourceOwner;

    const formData = new FormData();
    formData.append("file", file);
    if (formId !== null) {
      formData.append("form_id", formId);
    }

    if (resourceOwnerId) {
      formData.append("resource_owner_id", resourceOwnerId);
    }

    const response = await apiClient.fetch(url, {
      method: "POST",
      body: formData,
    });

    const { result } = await response.json();

    if (!result || result.status !== "ok") {
      throw result;
    }
    dispatch({
      type: FormsInvalidated,
    });
    return result;
  };
}

export async function fetchCustomModelOptions(
  resourceOwnerId: string | undefined
) {
  return await apiClient.listCustomModelOptions(resourceOwnerId);
}

export type FormAction =
  | GotFormListAction
  | GotFormAction
  | GettingFormListAction
  | ClearFormAction
  | FormCreatedAction
  | FormRemovedAction
  | GotFormImageSizeAction
  | AnchorAddedAction
  | AnchorUpdatedAction
  | AnchorDeletedAction
  | FieldAddedAction
  | FieldUpdatedAction
  | FieldDeletedAction
  | DetectionRegionAddedAction
  | DetectionRegionUpdatedAction
  | DetectionRegionDeletedAction
  | FormUpdatedAction
  | FormSavedAction
  | FormsInvalidatedAction;

export function useFormActionCreator() {
  return {
    updateFormCustomModels: useThunkDispatch(updateFormCustomModels),
    list: useThunkDispatch(list),
    get: useThunkDispatch(get),
    create: useThunkDispatch(create),
    remove: useThunkDispatch(remove),
    importForm: useThunkDispatch(importForm),
    getFormImageSize: useThunkDispatch(getFormImageSize),
    discardForm: useThunkDispatch(discardForm),
    addAnchor: useThunkDispatch(addAnchor),
    updateAnchor: useThunkDispatch(updateAnchor),
    deleteAnchor: useThunkDispatch(deleteAnchor),
    addField: useThunkDispatch(addField),
    updateField: useThunkDispatch(updateField),
    deleteField: useThunkDispatch(deleteField),
    addDetectionRegion: useThunkDispatch(addDetectionRegion),
    updateDetectionRegion: useThunkDispatch(updateDetectionRegion),
    deleteDetectionRegion: useThunkDispatch(deleteDetectionRegion),
    updateForm: useThunkDispatch(updateForm),
    saveForm: useThunkDispatch(saveForm),
    updateImage: useThunkDispatch(updateImage),
    updateFormKeyValues: useThunkDispatch(updateFormKeyValues),
    updateFormTokenGroups: useThunkDispatch(updateFormTokenGroups),
    updateCustomMerchants: useThunkDispatch(updateCustomMerchants),
  };
}
