import {
  IButtonProps,
  DefaultButton as MSDefaultButton,
  PrimaryButton as MSPrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import cn from "classnames";
import React from "react";

import { useLocale } from "../../../contexts/locale";
import styles from "./styles.module.scss";

interface BaseButtonProps extends Omit<IButtonProps, "text"> {
  textId: string;
  loading?: boolean;
}

function LoadingButtonWrapper(Component: React.ComponentType<IButtonProps>) {
  return (props: BaseButtonProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { localized } = useLocale();
    const { className, loading, textId, ...restProps } = props;
    return (
      <Component
        {...restProps}
        text={localized(textId)}
        className={cn(className, { [styles.buttonLoading]: loading })}
      >
        {loading && (
          <Spinner size={SpinnerSize.medium} className={styles.buttonSpinner} />
        )}
      </Component>
    );
  };
}

export const PrimaryButton = LoadingButtonWrapper(MSPrimaryButton);
export const DefaultButton = LoadingButtonWrapper(MSDefaultButton);
