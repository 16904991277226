import * as yup from "yup";

export const resourceOwnerSchema = yup
  .object({
    id: yup.string().required(),
    plan: yup.string().required(),
    planId: yup.string().defined().nullable(),
    isGoogleOcrEngineSet: yup.boolean().defined(),
    isAzureOcrEngineSet: yup.boolean().defined(),
    enabledFeatures: yup.array(yup.string().defined()).defined(),
    enabledAuditLog: yup.boolean().defined(),
  })
  .camelCase();

export const resourceOwnerSettingSchema = yup
  .object({
    googleServiceAccountKey: yup.mixed<any>().optional().nullable(),
    azureSubscriptionKey: yup.string().optional(),
    azureEndpoint: yup.string().optional(),
    enabledFeatures: yup.array(yup.string().required()).optional(),
    updatedAt: yup.string().defined(),
  })
  .camelCase();

export type ResourceOwnerSetting = yup.InferType<
  typeof resourceOwnerSettingSchema
>;
