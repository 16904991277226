import { Values } from "@oursky/react-messageformat";

export enum ConfirmModalType {
  Destory,
  Save,
  Normal,
}
export interface ConfirmationTask {
  modalType: ConfirmModalType;
  titleId: string;
  messageId: string;
  actionId: string;
  onCancel: () => void;
  onConfirm: () => void;
  messageValues?: Values;
}
