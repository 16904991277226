import {
  CommandBar,
  ICommandBarItemProps,
  Icon,
  Pivot,
  PivotItem,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

import { useLocale } from "../../contexts/locale";
import { useReceiptGroups } from "../../hooks/receipt_groups";
import { useToast } from "../../hooks/toast";
import NoResultPlaceholder from "../NoResultPlaceholder";
import ShortSpinner from "../ShortSpinner";
import ReceiptDropdown from "./ReceiptDropdown";
import styles from "./styles.module.scss";

interface Props {
  commbarBarItems?: ICommandBarItemProps[];
}

const ReceiptGroupBreadcrumb = React.memo(() => {
  const { localized } = useLocale();

  return (
    <div className={styles["breadcrumb"]}>
      <Text variant="large" className={styles["breadcrumb-text-item"]}>
        {localized("header.receipts")}
      </Text>
      <Icon iconName="ChevronRight" className={styles["breadcrumb-arrow"]} />
      <ReceiptDropdown />
    </div>
  );
});

interface ReceiptNavBarProps extends Props {
  showTabs: boolean;
}

const ReceiptNavBar = React.memo((props: ReceiptNavBarProps) => {
  const { commbarBarItems, showTabs } = props;
  const location = useLocation();
  const history = useHistory();
  const { localized } = useLocale();
  const { currentReceiptGroup } = useReceiptGroups();

  const onPivotItemClick = useCallback(
    (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
      if (!ev || !item || !item.props.itemKey) {
        return;
      }
      history.push(`/${item.props.itemKey}`);
    },
    [history]
  );

  const selectedKey = useMemo(
    () => location.pathname.split("/")[1],
    [location.pathname]
  );

  return (
    <div className={styles["container"]}>
      <ReceiptGroupBreadcrumb />
      {showTabs && (
        <div className={styles["tab-bar"]}>
          <Pivot onLinkClick={onPivotItemClick} selectedKey={selectedKey}>
            <PivotItem
              headerText={localized("header.receipt_edit")}
              itemKey="receipt-edit"
              itemIcon="Tag"
            />
            <PivotItem
              headerText={localized("header.receipt_api")}
              itemKey="receipt-api"
              itemIcon="PlugConnected"
            />
            <PivotItem
              headerText={localized("header.receipt_test")}
              itemKey="receipt-test"
              itemIcon="TestBeaker"
            />
            {currentReceiptGroup && currentReceiptGroup.type === "form" && (
              <PivotItem
                headerText={localized("header.receipt_batch")}
                itemKey="receipt-batch"
                itemIcon="Copy"
              />
            )}
          </Pivot>
          {commbarBarItems && <CommandBar items={commbarBarItems} />}
        </div>
      )}
    </div>
  );
});

interface ReceiptNavBarLayoutProps extends Props {
  children: React.ReactNode;
}

export const ReceiptNavBarLayout: React.FC<ReceiptNavBarLayoutProps> = (
  props: ReceiptNavBarLayoutProps
) => {
  const { children, ...rest } = props;
  const toast = useToast();
  const { currentReceiptGroup, fetchReceiptGroupIfNeeded, isFetching } =
    useReceiptGroups();

  React.useEffect(
    () => {
      fetchReceiptGroupIfNeeded().catch(() => {
        toast.error("error.fail_to_get_receipt_groups");
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <ReceiptNavBar {...rest} showTabs={!!currentReceiptGroup} />
      {isFetching ? (
        <div className={styles["placeholder-container"]}>
          <ShortSpinner />
        </div>
      ) : currentReceiptGroup ? (
        <div className={styles["content"]}>{children}</div>
      ) : (
        <div className={styles["placeholder-container"]}>
          <NoResultPlaceholder messageId="receipt.empty" />
        </div>
      )}
    </>
  );
};
