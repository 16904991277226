import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import TextField from "../WrappedMSComponents/TextField";

interface Props {
  isOpen: boolean;
  titleId: string;
  textFieldLabelId: string;
  buttonLabelId: string;
  onCancel: () => void;
  onSubmit: (inputValue: string) => void;
  inputValidatorWithMessage?: (inputValue: string) => string | undefined;
  width?: number;
  defaultValue?: string;
}

function _InputModal(props: Props) {
  const {
    width,
    defaultValue,
    onCancel,
    inputValidatorWithMessage,
    onSubmit,
    titleId,
    textFieldLabelId,
    isOpen,
    buttonLabelId,
  } = props;

  const [inputValue, setInputValue] = useState<string>("");
  const [inputErrorMessageId, setInputErrorMessageId] = useState<
    string | undefined
  >();
  const { localized } = useLocale();

  const onInputValueChange = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      if (value === undefined) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      setInputErrorMessageId(undefined);
      setInputValue(value);
    },
    []
  );

  useEffect(() => {
    if (defaultValue && isOpen) {
      setInputValue(defaultValue);
    }
  }, [defaultValue, isOpen]);

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const _inputValue = inputValue.trim();
      e.preventDefault();
      e.stopPropagation();

      if (inputValidatorWithMessage) {
        const errorId = inputValidatorWithMessage(_inputValue);
        if (errorId !== undefined) {
          setInputErrorMessageId(errorId);
          return;
        }
      }

      onSubmit(_inputValue);

      return;
    },
    [inputValidatorWithMessage, inputValue, onSubmit]
  );

  const onDismissed = useCallback(() => {
    setInputErrorMessageId(undefined);
    setInputValue("");
  }, []);

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(titleId),
    }),
    [localized, titleId]
  );

  return (
    <Dialog
      minWidth={width || 400}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={_onSubmit}>
        <TextField
          labelId={textFieldLabelId}
          onChange={onInputValueChange}
          value={inputValue}
          errorMessage={inputErrorMessageId && localized(inputErrorMessageId)}
        />

        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
          <PrimaryButton type="submit" text={localized(buttonLabelId)} />
        </DialogFooter>
      </form>
    </Dialog>
  );
}

export const InputModal = React.memo(_InputModal);
export default InputModal;
