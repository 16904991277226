import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";

import { useCustomModelEditor } from "../../contexts/customModelEditor";
import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface ContentProps {
  onClose(): void;
  originalName: string;
}

const Content = (props: ContentProps) => {
  const { onClose, originalName } = props;

  const { localized } = useLocale();

  const [name, setName] = useState(originalName);
  const confirmEnabled = name.trim() !== "";
  const { renameCustomModel } = useCustomModelEditor();

  const onNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value) {
        setName(value as string);
      } else {
        setName("");
      }
    },
    []
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();
      renameCustomModel(name);
      onClose();
    },
    [onClose, name, renameCustomModel]
  );

  return (
    <form onSubmit={onSubmit}>
      <TextField
        label={localized("custom_model_editor.rename_dialog.label")}
        onChange={onNameChange}
        value={name}
      />

      <DialogFooter>
        <DefaultButton onClick={onClose} text={localized("common.cancel")} />
        <PrimaryButton
          type="submit"
          text={localized("common.confirm")}
          disabled={!confirmEnabled}
        />
      </DialogFooter>
    </form>
  );
};

interface Props extends ContentProps {
  isOpen: boolean;
}

const CustomModelRenameModal = (props: Props) => {
  const { onClose, isOpen, originalName } = props;

  const { localized } = useLocale();

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("custom_model_editor.rename_dialog.title"),
      titleProps: {
        className: styles["title"],
      },
    }),
    [localized]
  );

  return (
    <Dialog
      minWidth={405}
      hidden={!isOpen}
      onDismiss={onClose}
      dialogContentProps={dialogContentProps}
    >
      <Content onClose={onClose} originalName={originalName} />
    </Dialog>
  );
};

export default CustomModelRenameModal;
