import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Label,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import { TeamListState } from "../../reducers/admin";
import { Plan } from "../../types/plan";
import { capitalizeFirstLetter } from "../../utils/capitalize";
import Paginator from "../Paginator";
import { Table } from "../Table";
import { TeamTableRow } from "../TeamTableRow";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

interface Props {
  teamsStateByRegion: {
    [key: string]: TeamListState;
  };
  plans: Plan[];
  offset: number;
  pageSize: number;
  selectedPlan?: string;
  regionOptions: { key: string; label: string }[];
  regionKey: string;
  setRegionKey: (key: string) => void;
  setSelectedPlan: (key?: string) => void;
  fieldToSearch?: string;
  setFieldToSearch: (key?: string) => void;
  searchText?: string;
  setSearchText: (text?: string) => void;
  onSubmitSearch: () => void;
  onNavigateToPage: (page: number) => void;
}

function EmptyStateUI() {
  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage id="team.list.no_team_yet" />
          </Label>
        </div>
      </div>
    </div>
  );
}

function _TeamListLayout(props: Props) {
  const {
    teamsStateByRegion,
    plans,
    offset,
    pageSize,
    selectedPlan,
    regionOptions,
    regionKey,
    setRegionKey,
    setSelectedPlan,
    searchText,
    setSearchText,
    onSubmitSearch,
    onNavigateToPage,
    fieldToSearch,
    setFieldToSearch,
  } = props;

  const { localized } = useLocale();

  const planOptions = React.useMemo(() => {
    return [
      {
        key: "",
        text: localized("team.list.all"),
      },
      ...AppConfig.admin.planOptions.map(option => {
        return {
          key: option,
          text: capitalizeFirstLetter(option),
        };
      }),
      ...plans
        .map(plan => {
          return {
            key: plan.id,
            text: capitalizeFirstLetter(plan.name),
          };
        })
        .filter(p => !AppConfig.admin.planOptions.includes(p.key)),
    ];
  }, [plans, localized]);

  const onPlanChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option?.key && option?.key !== "") {
        setSelectedPlan(`${option.key}`);
      } else {
        setSelectedPlan(undefined);
      }
    },
    [setSelectedPlan]
  );

  const onSearchByChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option?.key && option?.key !== "") {
        setFieldToSearch(`${option.key}`);
      } else {
        setFieldToSearch(undefined);
      }
    },
    [setFieldToSearch]
  );

  const searchByOptions = React.useMemo(() => {
    return [
      {
        key: "",
        text: localized("team.list.search_team_name"),
      },
      {
        key: "email",
        text: localized("team.list.search_team_email"),
      },
    ];
  }, [localized]);

  const onSearchTextChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.stopPropagation();
      event.preventDefault();
      setSearchText(value);
    },
    [setSearchText]
  );

  const onSearchKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
        onSubmitSearch();
      }
    },
    [onSubmitSearch]
  );

  const onPivotItemClick = React.useCallback(
    (item?: PivotItem, e?: React.MouseEvent<HTMLElement>) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (item && item.props.itemKey) {
        setRegionKey(item.props.itemKey);
      }
    },
    [setRegionKey]
  );

  const currentPage: number = React.useMemo(() => {
    if (offset === 0) {
      return 1;
    }
    return Math.max(Math.ceil((offset + 1) / pageSize), 1);
  }, [offset, pageSize]);

  const { teams, isEmpty, totalCount } = React.useMemo(() => {
    const teamState = teamsStateByRegion[regionKey];
    if (teamState === undefined) {
      return {
        teams: [],
        isEmpty: true,
        totalCount: 0,
      };
    }
    return {
      teams: teamState.teams ?? [],
      isEmpty: !teamState.isFetching && teamState.pageInfo?.totalCount === 0,
      totalCount: teamState.pageInfo?.totalCount ?? 0,
    };
  }, [teamsStateByRegion, regionKey]);

  return (
    <div className={styles["team-list"]}>
      <h3 className={styles["title"]}>
        <FormattedMessage id="team.list.title" />
      </h3>
      <div className={styles["search-filter"]}>
        <div className={styles["search-by-text"]}>
          <Label>
            <FormattedMessage id="team.list.search_by" />
          </Label>
        </div>
        <Dropdown
          className={styles["search-by-dropdown"]}
          placeholder={localized("team.list.search_by")}
          options={searchByOptions}
          selectedKey={fieldToSearch}
          onChange={onSearchByChange}
        />
        <TextField
          labelId=""
          type="search-team-name"
          className={styles["input-field"]}
          placeholder={localized("team.list.search")}
          value={searchText}
          onKeyDown={onSearchKeyDown}
          onChange={onSearchTextChange}
        />
        <div className={styles["plan-filter-text"]}>
          <Label>
            <FormattedMessage id="team.list.plan" />
          </Label>
        </div>
        <Dropdown
          className={styles["plan-filter"]}
          placeholder={localized("team.list.all")}
          options={planOptions}
          selectedKey={selectedPlan}
          onChange={onPlanChange}
        />
        <DefaultButton
          className={styles["search-button"]}
          onClick={onSubmitSearch}
          text={localized("team.list.search")}
        />
      </div>
      <div className={styles["tab-bar"]}>
        <Pivot onLinkClick={onPivotItemClick} selectedKey={regionKey}>
          {regionOptions.map(r => (
            <PivotItem key={r.key} headerText={r.label} itemKey={r.key} />
          ))}
        </Pivot>
      </div>
      {isEmpty ? (
        <EmptyStateUI />
      ) : (
        <>
          <Table
            columnIds={[
              "team.list.team",
              "team.list.region",
              "team.list.contact_email",
              "team.list.plan",
              "team.list.created_at",
            ]}
          >
            {teams.map((team, index) => (
              <TeamTableRow team={team} key={index} regionKey={regionKey} />
            ))}
          </Table>
          <Paginator
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            navigateToPage={onNavigateToPage}
          />
        </>
      )}
    </div>
  );
}

export const TeamListLayout = React.memo(_TeamListLayout);
export default TeamListLayout;
