import { ITextProps, Icon, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import styles from "./styles.module.scss";

interface Props {
  notes: string[];
  variant?: ITextProps["variant"];
}

const InfoNote = React.memo((props: Props) => {
  const { notes, variant } = props;

  return (
    <div className={styles["info-note"]}>
      {notes.map((note, index) => (
        <Text key={index} variant={variant || "small"}>
          <FormattedMessage id={note} />
        </Text>
      ))}
      <div className={styles["icon"]}>
        <Icon iconName="Info" />
      </div>
    </div>
  );
});

export default InfoNote;
