import * as yup from "yup";

export interface UsageRange {
  start: string;
  end: string;
}

export const usageCountSchema = yup
  .object({
    name: yup.string().required(),
    entityId: yup.string().defined().min(0),
    count: yup.number().required(),
  })
  .camelCase();

export type UsageCount = yup.InferType<typeof usageCountSchema>;

export const usageSchema = yup
  .object({
    api: yup.string().required(),
    counts: yup.array(usageCountSchema).defined(),
    total: yup.number().required(),
  })
  .camelCase();

export type Usage = yup.InferType<typeof usageSchema>;
