import * as React from "react";
import { useHistory, useParams } from "react-router";

import { updateFormGroup } from "../actions/formGroup";
import { UpdateFormGroupRequestParams } from "../apiClient/mixin/formGroup";
import ConfirmModal from "../components/ConfirmModal";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import FormGroupEditor from "../components/FormGroupEditor";
import { FormGroupNavBarLayout } from "../components/FormGroupNavBar";
import InputModal from "../components/InputModal";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import ScriptEditorModal from "../components/ScriptEditorModal";
import HeaderContainer from "../containers/Header";
import errors, { FOCRError } from "../errors";
import { useFormGroupEditCommandBarItems } from "../hooks/command_bar_items";
import { useFormGroup, useFormOptions } from "../hooks/form_group";
import {
  CommonFormGroupContainerErrorState,
  CommonFormGroupContainerLoadingState,
} from "../hooks/form_group";
import { useThunkDispatch } from "../hooks/thunk";
import { useToast } from "../hooks/toast";
import { ThunkActionCreatorType } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { BriefForm } from "../types/form";
import {
  DetailFormGroup,
  FormGroupAnchorBase,
  FormGroupTokenGroupBase,
} from "../types/formGroup";
import { FormGroupConfig } from "../types/formGroupConfig";

type PathParam = {
  formGroupId: string;
};

function useBindActionCreators() {
  return {
    updateFormGroup: useThunkDispatch(updateFormGroup),
  };
}

function validateFormGroupName(name: string) {
  return !name.trim() ? "error.rename_form_group.empty_name" : undefined;
}

function useTemplateSentry(formGroup: DetailFormGroup) {
  const history = useHistory();
  const { formGroupId } = useParams<PathParam>();

  React.useEffect(() => {
    if (formGroupId === formGroup.id && formGroup.isTemplate) {
      history.push(`/form-group/${formGroupId}/test`);
    }
  }, [formGroupId, formGroup, history]);
}

interface FormGroupEditorProps {
  formGroup: DetailFormGroup;
  updateFormGroup: ThunkActionCreatorType<typeof updateFormGroup>;
}

const FormGroupEdit = React.memo((props: FormGroupEditorProps) => {
  const { formGroup, updateFormGroup: _updateFormGroup } = props;

  const toast = useToast();

  useTemplateSentry(formGroup);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isRenameModalOpened, setIsRenameModalOpened] =
    React.useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false);
  const [isScriptModalOpened, setisScriptModalOpened] =
    React.useState<boolean>(false);

  const [nameToUpdate, setNameToUpdate] = React.useState<string | undefined>(
    undefined
  );
  const [ConfigToUpdate, setConfigToUpdate] = React.useState<
    FormGroupConfig | undefined
  >(undefined);
  const [anchorsToUpdate, setAnchorsToUpdate] = React.useState<
    FormGroupAnchorBase[] | undefined
  >(undefined);
  const [tokenGroupsToUpdate, setTokenGroupsToUpdate] = React.useState<
    FormGroupTokenGroupBase[] | undefined
  >(undefined);

  const onRenameFormGroup = React.useCallback(() => {
    setIsRenameModalOpened(true);
  }, []);

  const closeRenameModal = React.useCallback(() => {
    setIsRenameModalOpened(false);
  }, []);

  const updateFormGroup = React.useCallback(
    async (
      params: UpdateFormGroupRequestParams,
      ignoreConflict: boolean = false
    ) => {
      try {
        const parameters = ignoreConflict
          ? {
              anchors: params.anchors ?? formGroup.anchors,
              tokenGroups: params.tokenGroups ?? formGroup.tokenGroups,
              name: params.name ?? formGroup.name,
              config: params.config ?? formGroup.config,
            }
          : params;

        await _updateFormGroup(formGroup.id, {
          ...parameters,
          retrievedAt: ignoreConflict ? undefined : formGroup.updatedAt,
        });
      } catch (e) {
        if (e instanceof FOCRError && e === errors.ConflictFound) {
          if (params.anchors) {
            setAnchorsToUpdate(params.anchors);
          }
          if (params.name) {
            setNameToUpdate(params.name);
          }
          if (params.tokenGroups) {
            setTokenGroupsToUpdate(params.tokenGroups);
          }
          if (params.config) {
            setConfigToUpdate(params.config);
          }
          setIsConfirmationModalOpen(true);
        } else {
          throw e;
        }
      }
    },
    [_updateFormGroup, formGroup]
  );

  const clearResourcesToUpdateAndCloseConfirmationModal =
    React.useCallback(() => {
      setNameToUpdate(undefined);
      setAnchorsToUpdate(undefined);
      setTokenGroupsToUpdate(undefined);
      setIsConfirmationModalOpen(false);
      setConfigToUpdate(undefined);
    }, []);

  const doConfirmUpdateFormGroup = React.useCallback(async () => {
    await updateFormGroup(
      {
        name: nameToUpdate,
        anchors: anchorsToUpdate,
        tokenGroups: tokenGroupsToUpdate,
        config: ConfigToUpdate,
      },
      true
    );
    clearResourcesToUpdateAndCloseConfirmationModal();
  }, [
    anchorsToUpdate,
    clearResourcesToUpdateAndCloseConfirmationModal,
    nameToUpdate,
    tokenGroupsToUpdate,
    updateFormGroup,
    ConfigToUpdate,
  ]);

  const doRenameFormGroup = React.useCallback(
    async (name: string) => {
      closeRenameModal();
      setIsLoading(true);
      try {
        await updateFormGroup({ name: name });
      } catch (e) {
        console.error("Rename form group error: ", e);
        toast.error("error.fail_to_update_form_group");
      } finally {
        setIsLoading(false);
      }
    },
    [closeRenameModal, updateFormGroup, toast]
  );

  const onCloseFormGroupScriptModal = React.useCallback(() => {
    setisScriptModalOpened(false);
  }, []);

  const doUpdateFormGroupAnchors = React.useCallback(
    async (anchors: FormGroupAnchorBase[]) => {
      try {
        await updateFormGroup({
          anchors: anchors,
        });
      } catch (e) {
        console.error("Update form group anchor error: ", e);
        toast.error("error.fail_to_update_form_group");
      }
    },
    [toast, updateFormGroup]
  );

  const doUpdateFormGroupTokenGroups = React.useCallback(
    async (tokenGroups: FormGroupTokenGroupBase[]) => {
      try {
        await updateFormGroup({
          tokenGroups: tokenGroups,
        });
      } catch (e) {
        console.error("Update form group token group error: ", e);
        toast.error("error.fail_to_update_form_group");
      }
    },
    [toast, updateFormGroup]
  );

  const commandBarItems = useFormGroupEditCommandBarItems(formGroup.id);

  const onOpenFormGroupScriptModal = React.useCallback(() => {
    setisScriptModalOpened(true);
  }, []);

  const updatePostProcessScript = React.useCallback(
    async (script: string) => {
      if (formGroup.config.post_process_script !== script) {
        setIsLoading(true);
        try {
          await updateFormGroup({
            config: {
              post_process_script:
                script === undefined || script.trim() === ""
                  ? undefined
                  : script,
            },
          });
        } catch (e) {
          console.error("Update form group script error: ", e);
          toast.error("error.fail_to_update_form_group");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [formGroup.config.post_process_script, toast, updateFormGroup]
  );

  return (
    <Main hasTop={true}>
      <FormGroupNavBarLayout
        formGroup={formGroup}
        commbarBarItems={commandBarItems}
      >
        <FormGroupEditor
          formGroup={formGroup}
          onRenameFormGroup={onRenameFormGroup}
          onUpdateAnchors={doUpdateFormGroupAnchors}
          onUpdateTokenGroups={doUpdateFormGroupTokenGroups}
          onOpenFormGroupScriptModal={onOpenFormGroupScriptModal}
        />
      </FormGroupNavBarLayout>
      {isScriptModalOpened && (
        <ScriptEditorModal
          payload={{
            formGroup,
            onSave: updatePostProcessScript,
          }}
          onCloseModal={onCloseFormGroupScriptModal}
        />
      )}
      {isRenameModalOpened && (
        <InputModal
          titleId="rename_form_group.title"
          textFieldLabelId="rename_form_group.form_group_name"
          buttonLabelId="rename_form_group.rename"
          inputValidatorWithMessage={validateFormGroupName}
          isOpen={isRenameModalOpened}
          onCancel={closeRenameModal}
          onSubmit={doRenameFormGroup}
          defaultValue={formGroup.name}
        />
      )}
      <ConfirmModal
        isOpen={isConfirmationModalOpen}
        modalType={ConfirmModalType.Save}
        titleId="form_group.modifed_prompt.title"
        messageId="form_group.modifed_prompt.desc"
        actionId="common.save_and_overwrite"
        onCancel={clearResourcesToUpdateAndCloseConfirmationModal}
        onConfirm={doConfirmUpdateFormGroup}
      />
      <LoadingModal isOpen={isLoading} />
    </Main>
  );
});

interface FormGroupEditContainerSuccessState {
  state: "success";
  formGroup: DetailFormGroup;
  formOptions: BriefForm[];
}

type FormGroupEditContainerState =
  | FormGroupEditContainerSuccessState
  | CommonFormGroupContainerErrorState
  | CommonFormGroupContainerLoadingState;

function useFormGroupEditorState(formGroupId: string) {
  const { formGroup, isFailedToFetchFormGroup } = useFormGroup(formGroupId);
  const { formOptions, isFailedToFetchFormOptions } = useFormOptions();

  const containerState: FormGroupEditContainerState =
    isFailedToFetchFormOptions || isFailedToFetchFormGroup
      ? { state: "error" }
      : formGroup && formGroup.id === formGroupId && formOptions !== undefined
      ? { state: "success", formGroup, formOptions }
      : { state: "loading" };

  return containerState;
}

export const FormGroupEditContainer = React.memo(() => {
  const { formGroupId } = useParams<PathParam>();

  const { updateFormGroup } = useBindActionCreators();

  const containerState = useFormGroupEditorState(formGroupId);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      {containerState.state === "success" ? (
        <FormGroupEdit
          formGroup={containerState.formGroup}
          updateFormGroup={updateFormGroup}
        />
      ) : containerState.state === "error" ? (
        <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
      ) : null}
    </Layout>
  );
});

export default FormGroupEditContainer;
