import {
  AnchorAdded,
  AnchorDeleted,
  AnchorUpdated,
  DetectionRegionAdded,
  DetectionRegionDeleted,
  DetectionRegionUpdated,
  DiscardForm,
  FieldAdded,
  FieldDeleted,
  FieldUpdated,
  FormCreated,
  FormRemoved,
  FormSaved,
  FormUpdated,
  FormsInvalidated,
  GettingFormList,
  GotForm,
  GotFormImageSize,
  GotFormList,
  GotFormListAction,
} from "../actions/form";
import {
  CreateTeam,
  TeamInvitationAccepted,
  TeamUserRemoved,
} from "../actions/team";
import { ENGINE_TO_EXTERNAL_SERVICE_MAP } from "../constants";
import { MerchantSettingsMapper } from "../modelMapper";
import { ExternalServices } from "../models";
import { RootAction } from "../redux/types";
import { BriefForm, DetailedForm } from "../types/form";
import { BriefFormMapper } from "../types/mappers/form";
import { PageInfo } from "../types/pageInfo";
import { Template } from "../types/template";
import { deepClone } from "../utils/deepClone";
import { settingsToConfig } from "../utils/formConfig";
import { replaceImageUrl } from "../utils/replaceImageUrl";

export interface FormState {
  readonly forms: BriefForm[];
  readonly templates?: Template[];
  readonly areFormFetched: boolean;
  readonly pageInfo?: PageInfo;
  readonly isFetching: boolean;
  readonly currentForm?: DetailedForm;
  readonly currentFormImageSize?: {
    width: number;
    height: number;
  };

  readonly isAnyAnchorChanged: boolean;
  readonly isAnyFieldOrDetectionRegionChanged: boolean;
  readonly originalForm?: DetailedForm;
  readonly isFormChanged: boolean;
  readonly listVersion: number;
  getRequiredExternalServices(): ExternalServices[];
  isRequireFullImageOCR(): boolean;
}

const defaultState: FormState = {
  forms: [],
  areFormFetched: false,
  isFetching: true,
  isAnyAnchorChanged: false,
  isAnyFieldOrDetectionRegionChanged: false,
  isFormChanged: false,
  listVersion: 0,
  getRequiredExternalServices: function (this: FormState) {
    if (this.currentForm === undefined) {
      return [];
    }

    const externalServicesSet = new Set<ExternalServices>();

    if (this.isRequireFullImageOCR()) {
      externalServicesSet.add("google");
    }

    for (const detectionRegion of this.currentForm.detectionRegions) {
      const { fields } = detectionRegion.config;
      for (const field of fields) {
        const params = field.params;
        if (params && params.engine) {
          const externalService = ENGINE_TO_EXTERNAL_SERVICE_MAP[params.engine];
          if (externalService) {
            externalServicesSet.add(externalService);
          }
        }
      }
    }
    return Array.from(externalServicesSet);
  },

  isRequireFullImageOCR: function (this: FormState) {
    if (this.currentForm === undefined) {
      return false;
    }

    const hasTextToken =
      this.currentForm.tokenGroups.findIndex(group => group.texts.length > 0) >=
      0;

    return (
      hasTextToken ||
      this.currentForm.keyValues.length > 0 ||
      (this.currentForm.config.auto_extraction_items &&
        this.currentForm.config.auto_extraction_items.length > 0)
    );
  },
};

function handleGotFormList(
  state: FormState,
  action: GotFormListAction
): FormState {
  const { forms, pageInfo, listVersion } = action.payload;

  if (listVersion !== state.listVersion) {
    return state;
  }

  const updatedForms =
    forms.length > 0
      ? state.forms.concat(
          forms.map(x => ({
            ...x,
            image: replaceImageUrl(x.image),
          }))
        )
      : state.forms;

  return {
    ...state,
    forms: updatedForms,
    areFormFetched: state.forms.length + forms.length > 0,
    pageInfo: {
      ...pageInfo,
    },
    isFetching: false,
    listVersion: listVersion,
  };
}

export function reducer(
  state: FormState = defaultState,
  action: RootAction
): FormState {
  switch (action.type) {
    case GettingFormList:
      return {
        ...state,
        listVersion: action.listVersion,
      };

    case GotFormList:
      return handleGotFormList(state, action);

    case FormsInvalidated:
      return {
        ...state,
        forms: [],
        areFormFetched: false,
        pageInfo: undefined,
        currentForm: undefined,
      };

    case "GotTemplateList":
      return {
        ...state,
        templates: action.payload.templates,
      };

    case FormCreated:
      action.payload.form.image = replaceImageUrl(action.payload.form.image);

      return {
        ...state,
        currentForm: action.payload.form,
        originalForm: deepClone(action.payload.form),
        isFormChanged: false,
        isAnyAnchorChanged: false,
        isAnyFieldOrDetectionRegionChanged: false,
        forms: [BriefFormMapper.fromDetailedForm(action.payload.form)].concat(
          state.forms
        ),
      };

    case GotForm:
      action.payload.form.image = replaceImageUrl(action.payload.form.image);

      return {
        ...state,
        currentForm: action.payload.form,
        originalForm: deepClone(action.payload.form),
        isFormChanged: false,
        isAnyAnchorChanged: false,
        isAnyFieldOrDetectionRegionChanged: false,
        currentFormImageSize:
          state.currentForm && state.currentForm.id === action.payload.form.id
            ? state.currentFormImageSize
            : undefined,
      };

    case DiscardForm:
      return {
        ...state,
        currentForm: deepClone(state.originalForm),
        isFormChanged: false,
        isAnyAnchorChanged: false,
        isAnyFieldOrDetectionRegionChanged: false,
        currentFormImageSize: undefined,
      };

    case GotFormImageSize:
      return {
        ...state,
        currentFormImageSize: {
          ...action.payload,
        },
      };

    case FormRemoved:
      return {
        ...state,
        forms: state.forms.filter(x => x.id !== action.payload.formId),
      };

    case AnchorAdded:
      if (state.currentForm) {
        const newAnchor = action.payload.anchor;
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            anchors: state.currentForm.anchors.concat([deepClone(newAnchor)]),
          },
          isAnyAnchorChanged: true,
        };
      } else {
        return state;
      }

    case AnchorDeleted:
      if (state.currentForm) {
        const anchorIdToDelete = action.payload.anchorId;
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            anchors: state.currentForm.anchors.filter(
              x => x.id !== anchorIdToDelete
            ),
          },
          isAnyAnchorChanged: true,
        };
      } else {
        return state;
      }

    case AnchorUpdated:
      if (state.currentForm) {
        const updatedAnchor = action.payload.anchor;
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            anchors: state.currentForm.anchors.map(x => {
              if (x.id === updatedAnchor.id) {
                return deepClone(updatedAnchor);
              } else {
                return x;
              }
            }),
          },
          isAnyAnchorChanged: true,
        };
      } else {
        return state;
      }

    case FieldAdded:
      if (state.currentForm) {
        const newField = action.payload.field;
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            fields: state.currentForm.fields.concat([deepClone(newField)]),
          },
          isAnyFieldOrDetectionRegionChanged: true,
        };
      } else {
        return state;
      }

    case FieldDeleted:
      if (state.currentForm) {
        const fieldIdToDelete = action.payload.fieldId;
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            fields: state.currentForm.fields.filter(
              x => x.id !== fieldIdToDelete
            ),
          },
          isAnyFieldOrDetectionRegionChanged: true,
        };
      } else {
        return state;
      }

    case FieldUpdated:
      if (state.currentForm) {
        const updatedField = action.payload.field;
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            fields: state.currentForm.fields.map(x => {
              if (x.id === updatedField.id) {
                return deepClone(updatedField);
              } else {
                return x;
              }
            }),
          },
          isAnyFieldOrDetectionRegionChanged: true,
        };
      } else {
        return state;
      }

    case DetectionRegionAdded:
      if (state.currentForm) {
        const newDetectionRegion = action.payload.detectionRegion;
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            detectionRegions: state.currentForm.detectionRegions.concat([
              deepClone(newDetectionRegion),
            ]),
          },
          isAnyFieldOrDetectionRegionChanged: true,
        };
      } else {
        return state;
      }

    case DetectionRegionDeleted:
      if (state.currentForm) {
        const detectionRegionIdToDelete = action.payload.detectionRegionId;
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            detectionRegions: state.currentForm.detectionRegions.filter(
              x => x.id !== detectionRegionIdToDelete
            ),
          },
          isAnyFieldOrDetectionRegionChanged: true,
        };
      } else {
        return state;
      }

    case DetectionRegionUpdated:
      if (state.currentForm) {
        const updatedDetectionRegion = action.payload.detectionRegion;
        return {
          ...state,
          currentForm: {
            ...state.currentForm,
            detectionRegions: state.currentForm.detectionRegions.map(x => {
              if (x.id === updatedDetectionRegion.id) {
                return deepClone(updatedDetectionRegion);
              } else {
                return x;
              }
            }),
          },
          isAnyFieldOrDetectionRegionChanged: true,
        };
      } else {
        return state;
      }

    case FormUpdated:
      if (state.currentForm && action.payload.formId === state.currentForm.id) {
        const {
          settings,
          imagePayload,
          keyValues,
          tokenGroups,
          customMerchants,
          cutomMerchantsFallback,
          customModelIds,
        } = action.payload;

        const currentForm = { ...state.currentForm };
        if (settings) {
          currentForm["name"] = settings.name;
          currentForm["config"] = {
            ...currentForm.config,
            ...settingsToConfig(settings),
          };
        }
        if (imagePayload) {
          currentForm["image"] = imagePayload.imageUrl;
          currentForm["imageId"] = imagePayload.imageId;
        }
        if (keyValues) {
          currentForm["keyValues"] = keyValues;
        }
        if (tokenGroups) {
          currentForm["tokenGroups"] = tokenGroups;
        }
        if (customMerchants) {
          currentForm.config = {
            ...currentForm.config,
            custom_merchants: MerchantSettingsMapper.toResp(customMerchants),
          };
        }
        if (cutomMerchantsFallback !== undefined) {
          currentForm.config = {
            ...currentForm.config,
            custom_merchants_fallback: cutomMerchantsFallback,
          };
        }
        if (customModelIds !== undefined) {
          currentForm.customModelIds = [...customModelIds];
        }

        return {
          ...state,
          isFormChanged: true,
          currentForm,
        };
      } else {
        return state;
      }

    case FormSaved:
      const form = action.payload.form;
      if (state.currentForm && form.id === state.currentForm.id) {
        const currentForm = {
          ...state.currentForm,
          ...form,
          image: state.currentForm.image,
        };

        return {
          ...state,
          isFormChanged: false,
          isAnyAnchorChanged: false,
          isAnyFieldOrDetectionRegionChanged: false,
          currentForm,
          originalForm: deepClone(currentForm),
          forms: state.forms.map(x => {
            if (x.id === form.id) {
              return {
                ...x,
                name: form.name,
                image: replaceImageUrl(form.image),
                documentType: form.documentType,
              };
            } else {
              return x;
            }
          }),
        };
      } else {
        return state;
      }

    case TeamUserRemoved: {
      const { removedUserId, currentUser } = action;
      if (removedUserId === currentUser.id) {
        return {
          ...defaultState,
        };
      } else {
        return state;
      }
    }

    case "UserLogin":
    case "UserLogout":
      return {
        ...defaultState,
      };

    case "SelectTeam":
    case CreateTeam:
    case "TeamDeleted":
    case TeamInvitationAccepted:
      return {
        ...defaultState,
        templates: state.templates,
        listVersion: state.listVersion,
      };

    default:
      return state;
  }
}
