@import "../../styles/variables.scss";

.input-field {
  width: 450px;
  margin-bottom: 16px;
}

.pattern-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .regex-input {
    width: 300px;
  }

  .build-button {
    margin-top: 30px;
  }
}
