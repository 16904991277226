@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 22px 12px 16px;

  .input-field {
    width: 100%;
    margin-bottom: 12px;

    .check-box {
      margin-top: 8px;
    }
  }

  .advanced-form-settings-label {
    color: #25d0b1;
  }

  .advanced-form-settings {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    * {
      cursor: pointer;
    }

    i {
      font-size: 8pt;
      padding-right: 6px;
    }

    .expanded {
      transform: scaleY(-1);
    }
  }

  .invisible-image-upload-input {
    display: none;
  }

  .upload-button:focus-within {
    box-shadow: 0 0 6px 6px $form-focus-color;
  }

  .upload-button:active {
    color: $form-button-active-color;
    border: 1px solid $form-button-active-color;
  }

  .separator {
    height: 1px;
    width: 100%;
    margin: 16px 0 16px;
    background-color: $separator-color-1;
  }

  .form-id,
  .form-name {
    width: 100%;
    margin-bottom: 12px;
  }

  .custom-model-section {
    width: 100%;
    > label {
      font-size: 16px;
      margin-bottom: 4px;
    }

    .button-container {
      margin-top: 8px;
      margin-bottom: 20px;
    }

    .custom-model-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #000;

      .custom-model-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      i {
        color: initial;
      }
    }
  }
}

.confirm_replace_dialog {
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .filename {
    margin-bottom: 20px;
    color: rgb(155, 153, 151);
  }

  .message {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
  }
}
