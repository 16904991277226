import {
  Page,
  SessionStateChangeReason,
  SettingOptions,
  UserInfo,
  WebContainer,
} from "@authgear/web";

import { AppConfig, AuthGearConfig } from "../config";
import {
  AuthState,
  authEncodeResponseSchema,
  authStateSchema,
} from "../types/auth";
import {
  CookieKey,
  getCookie,
  removeCookie,
  setCookie,
} from "../utils/cookies";
import {
  PreferenceKey,
  getPreference,
  setPreference,
} from "../utils/preference";
import { ApiClientInitializeResult, _BaseApiClient } from "./base";

export type { SettingOptions, UserInfo } from "@authgear/web";

export class AuthGearAPIClient extends _BaseApiClient {
  authGearConfig: AuthGearConfig;
  container: WebContainer;

  onNoSession?: () => void;

  constructor(
    endpoint: string,
    regionalEndpoints: { [Key: string]: string },
    config: AuthGearConfig
  ) {
    super(endpoint, regionalEndpoints);
    this.authGearConfig = config;
    this.container = new WebContainer();
    this.container.delegate = this;
  }

  async fetch(resource: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.container.fetch(resource, init);
  }

  async getUserInfo(): Promise<UserInfo | undefined> {
    if (this.container.sessionState !== "AUTHENTICATED") {
      return undefined;
    }
    try {
      const userInfo = await this.container.fetchUserInfo();
      return userInfo;
    } catch (e) {
      return undefined;
    }
  }

  async initialize(): Promise<ApiClientInitializeResult> {
    const { redirectURI, ...config } = this.authGearConfig;

    const refreshToken = getCookie(CookieKey.authGearDefaultRefreshToken);
    if (refreshToken) {
      setPreference(PreferenceKey.authGearDefaultRefreshToken, refreshToken);
    }

    await this.container.configure({
      ...config,
      sessionType: "refresh_token",
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let invitationCode = urlParams.get("invitation-code") || undefined;
    const state = urlParams.get("state");

    if (state !== null && !invitationCode) {
      invitationCode =
        (await this.decodeState(state)).invitationCode || undefined;
    }

    if (window.location.pathname.startsWith("/authorized")) {
      try {
        await this.container.finishAuthentication();
        return { isAuthenticated: true, invitationCode };
      } catch (e) {
        console.error(e);
        return { isAuthenticated: false, invitationCode };
      }
    }

    const userInfo = await this.getUserInfo();

    if (!userInfo) {
      removeCookie(CookieKey.authGearDefaultRefreshToken);
      if (!invitationCode) {
        const encodedState = await this.encodeState({
          region: AppConfig.region,
        });

        await this.container.startAuthentication({
          redirectURI,
          state: encodedState,
        });
      }
      return { isAuthenticated: false, invitationCode, userInfo };
    } else {
      return { isAuthenticated: true, invitationCode, userInfo };
    }
  }

  async authgearLogout(force?: boolean): Promise<void> {
    setCookie(CookieKey.shouldReload, "true");
    const redirectURI = "https://www.formx.ai";
    await this.container.logout({ force, redirectURI });
  }

  async authgearOpenUserSetting(options?: SettingOptions): Promise<void> {
    await this.container.open(Page.Settings, options);
  }

  async authgearOpenUserIdentities(options?: SettingOptions): Promise<void> {
    await this.container.open(Page.Identities, options);
  }

  onSessionStateChange(
    container: WebContainer,
    _reason: SessionStateChangeReason
  ) {
    if (container.sessionState === "NO_SESSION") {
      this.onNoSession && this.onNoSession();
      removeCookie(CookieKey.authGearDefaultRefreshToken);
    } else if (container.sessionState === "AUTHENTICATED") {
      setTimeout(() => {
        const token = getPreference(PreferenceKey.authGearDefaultRefreshToken);
        if (token != null) {
          removeCookie(CookieKey.shouldReload);
          setCookie(CookieKey.authGearDefaultRefreshToken, token);
        }
      }, 0);
    }
  }

  async encodeState(payload: AuthState): Promise<string> {
    const resp = await this.lambda(
      "authgear:encode-state",
      this.injectOptionalFields(
        {},
        {
          invitation_code: payload.invitationCode,
          region: payload.region,
        }
      ),
      authEncodeResponseSchema,
      null
    );
    return resp.state;
  }

  async decodeState(state: string): Promise<AuthState> {
    return await this.lambda(
      "authgear:decode-state",
      {
        state: state,
      },
      authStateSchema
    );
  }
}
