import { useDebouncedCallback } from "use-debounce";

import { apiClient } from "../apiClient";
import { ENVIRONMENT } from "../config";
import { AppConfig } from "../config";
import {
  CUSTOM_MODEL_SAVE_DEBOUNCE_INTERVAL,
  MAX_FETCH_CUSTOM_MODELS,
} from "../constants";
import { useThunkDispatch } from "../hooks/thunk";
import { Dispatch, RootState } from "../redux/types";
import {
  BriefCustomModel,
  CustomModel,
  PaginatedBriefCustomModel,
} from "../types/customModel";
import { PageInfo } from "../types/pageInfo";
import { workerClient } from "../workerClient";
import { handleConflict } from "./app";
import { selectTeam } from "./resourceOwner";

export const CreateCustomModel = "CreateCustomModel";
export interface CreateCustomModelAction {
  readonly type: typeof CreateCustomModel;
  readonly payload: {
    customModel: CustomModel;
  };
}

export const ResetDefaultCustomModel = "ResetDefaultCustomModel";
export interface ResetDefaultCustomModelAction {
  readonly type: typeof ResetDefaultCustomModel;
  readonly payload: {
    name: string;
  };
}

export const RenameCustomModel = "RenameCustomModel";
export interface RenameCustomModelAction {
  readonly type: typeof RenameCustomModel;
  readonly payload: {
    name: string;
  };
}

export const RequestCustomModelTraining = "RequestCustomModelTraining";
export interface RequestCustomModelTrainingAction {
  readonly type: typeof RequestCustomModelTraining;
  readonly payload: {
    remark: string;
  };
}

export const FreezeFieldsForLabelling = "FreezeFieldsForLabelling";
export interface FreezeFieldsForLabellingAction {
  readonly type: typeof FreezeFieldsForLabelling;
}

export const TriggerSyncWithCVAT = "TriggerSyncWithCVAT";
export interface TriggerSyncWithCVATAction {
  readonly type: typeof TriggerSyncWithCVAT;
}

export const TriggerModelTraining = "TriggerModelTraining";
export interface TriggerModelTrainingAction {
  readonly type: typeof TriggerModelTraining;
}

export const DeployModelVersion = "DeployModelVersion";
export interface DeployModelVersionAction {
  readonly type: typeof DeployModelVersion;
  readonly payload: {
    modelVersionTag: string;
  };
}

export interface GettingCustomModelsAction {
  readonly type: "GettingCustomModels";
  readonly listVersion: number;
}

export const GetCustomModels = "GetCustomModels";
export interface GetCustomModelsAction {
  readonly type: typeof GetCustomModels;
  readonly payload: {
    customModels: BriefCustomModel[];
    pageInfo?: PageInfo;
    refresh: boolean;
    listVersion: number;
  };
}

export const GetCustomModel = "GetCustomModel";
export interface GetCustomModelAction {
  readonly type: typeof GetCustomModel;
  readonly payload: {
    currentCustomModel: CustomModel;
  };
}

export const DeleteCustomModel = "DeleteCustomModel";
export interface DeleteCustomModelAction {
  readonly type: typeof DeleteCustomModel;
  readonly payload: {
    customModelId: string;
  };
}

export const AppendSampleImage = "AppendSampleImage";
export interface AppendSampleImageAction {
  readonly type: typeof AppendSampleImage;
  readonly payload: {
    assetId: string;
    filename: string;
    updatedAt: number;
    contentType: string;
    isOnCVAT: boolean;
    url: string;
  };
}

export const RemoveSampleImages = "RemoveSampleImages";
export interface RemoveSampleImagesAction {
  readonly type: typeof RemoveSampleImages;
  readonly payload: {
    assetIds: Set<string>;
  };
}

export const AddCustomModelInfoField = "AddCustomModelInfoField";
export interface AddCustomModelInfoFieldAction {
  readonly type: typeof AddCustomModelInfoField;
  readonly payload: {
    value: string;
  };
}

export const RemoveCustomModelInfoField = "RemoveCustomModelInfoField";
export interface RemoveCustomModelInfoFieldAction {
  readonly type: typeof RemoveCustomModelInfoField;
  readonly payload: {
    index: number;
  };
}

export const UpdateCustomModelInfoField = "UpdateCustomModelInfoField";
export interface UpdateCustomModelInfoFieldAction {
  readonly type: typeof UpdateCustomModelInfoField;
  readonly payload: {
    index: number;
    value: string;
  };
}

export const UpdateCustomModel = "UpdateCustomModel";
export interface UpdateCustomModelAction {
  readonly type: typeof UpdateCustomModel;
  readonly payload: {
    custom_model: CustomModel;
  };
}

export function getCustomModel(customModelId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<CustomModel> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const customModel = await apiClient.getCustomModel(
      customModelId,
      resourceOwnerId
    );

    selectTeam(customModel.resourceOwnerId)(dispatch, getState);

    dispatch({
      type: GetCustomModel,
      payload: {
        currentCustomModel: customModel,
      },
    });

    return customModel;
  };
}

export function getCustomModels(options: { refresh: boolean }) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<PaginatedBriefCustomModel> => {
    const { refresh } = options;
    const state = getState();
    const { pageInfo: curPageInfo, listVersion } = state.customModel;
    const { resourceOwnerId } = state.resourceOwner;

    if (curPageInfo && !curPageInfo.hasNext) {
      return Promise.resolve({ pageInfo: curPageInfo, customModels: [] });
    }

    const cursor =
      !refresh && curPageInfo && curPageInfo.hasNext ? curPageInfo.cursor : "";

    const newListVersion = listVersion + 1;

    dispatch({
      type: "GettingCustomModels",
      listVersion: newListVersion,
    });

    const { pageInfo, customModels } = await apiClient.listCustomModels(
      MAX_FETCH_CUSTOM_MODELS,
      cursor,
      resourceOwnerId
    );

    dispatch({
      type: GetCustomModels,
      payload: {
        customModels,
        refresh,
        pageInfo,
        listVersion: newListVersion,
      },
    });

    return { pageInfo, customModels };
  };
}

export function updateCustomModel(
  customModel: CustomModel,
  shouldIgnoreConflict: boolean = false
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<CustomModel> => {
    const { resourceOwnerId } = getState().resourceOwner;

    const model = await handleConflict(
      () =>
        apiClient.updateCustomModel(
          customModel,
          shouldIgnoreConflict,
          resourceOwnerId
        ),
      () => apiClient.updateCustomModel(customModel, true, resourceOwnerId),
      {
        titleId: "custom_model_editor.modifed_prompt.title",
        messageId: "custom_model_editor.modifed_prompt.desc",
        actionId: "common.save_and_overwrite",
      }
    )(dispatch, getState);

    dispatch({
      type: GetCustomModel,
      payload: {
        currentCustomModel: {
          ...getState().customModel.currentCustomModel,
          updatedAt: model.updatedAt,
        },
      },
    });

    return model;
  };
}

export function deleteCustomModel(customModelId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    await apiClient.deleteCustomModel(customModelId, resourceOwnerId);

    dispatch({
      type: DeleteCustomModel,
      payload: {
        customModelId,
      },
    });
  };
}

export function createCustomModel(customModel: CustomModel, remark: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<CustomModel> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const returnedCustomModel = await apiClient.createCustomModel(
      customModel,
      remark,
      resourceOwnerId
    );

    dispatch({
      type: CreateCustomModel,
      payload: {
        customModel: returnedCustomModel,
      },
    });

    return returnedCustomModel;
  };
}

export function triggerSyncWithCVAT(token: string, customModelId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    await workerClient(token).syncCvatProjectForCustomModel(customModelId);
    dispatch({ type: TriggerSyncWithCVAT });
  };
}

export function triggerModelTraining(customModelId: string) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    await apiClient.triggerModelTraining(customModelId, resourceOwnerId);
    dispatch({
      type: TriggerModelTraining,
    });
  };
}

export function deployModelVersion(
  customModelId: string,
  modelVersionTag: string
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    await apiClient.deployModelVersion(
      customModelId,
      modelVersionTag,
      resourceOwnerId
    );

    dispatch({
      type: DeployModelVersion,
      payload: {
        modelVersionTag,
      },
    });
  };
}

export function appendSampleImage(
  customModelId: string,
  file: File,
  dataURI: string | undefined,
  width: number | undefined,
  height: number | undefined
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const assetId = await apiClient.uploadFileForCustomModel(
      resourceOwnerId,
      customModelId,
      file,
      width,
      height
    );
    dispatch({
      type: AppendSampleImage,
      payload: {
        assetId: assetId,
        filename: file.name,
        updatedAt: new Date().getTime(),
        contentType: file.type,
        isOnCVAT: false,
        url: dataURI || assetId,
      },
    });
  };
}

export function removeSampleImages(assetIds: Set<string>) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: RemoveSampleImages,
      payload: {
        assetIds: assetIds,
      },
    });
  };
}

export type CustomModelAction =
  | UpdateCustomModelAction
  | GettingCustomModelsAction
  | GetCustomModelsAction
  | GetCustomModelAction
  | DeleteCustomModelAction
  | AppendSampleImageAction
  | RemoveSampleImagesAction
  | ResetDefaultCustomModelAction
  | AddCustomModelInfoFieldAction
  | RemoveCustomModelInfoFieldAction
  | CreateCustomModelAction
  | RenameCustomModelAction
  | RequestCustomModelTrainingAction
  | UpdateCustomModelInfoFieldAction
  | FreezeFieldsForLabellingAction
  | TriggerSyncWithCVATAction
  | TriggerModelTrainingAction
  | DeployModelVersionAction;

export function useCustomModelActionCreator() {
  return {
    getCustomModels: useThunkDispatch(getCustomModels),
    getCustomModel: useThunkDispatch(getCustomModel),
    updateCustomModel: useDebouncedCallback(
      useThunkDispatch(updateCustomModel),
      CUSTOM_MODEL_SAVE_DEBOUNCE_INTERVAL
    ),
    deleteCustomModel: useThunkDispatch(deleteCustomModel),
    createCustomModel: useThunkDispatch(createCustomModel),
    triggerSyncWithCVAT: useThunkDispatch(triggerSyncWithCVAT),
    triggerModelTraining: useThunkDispatch(triggerModelTraining),
    deployModelVersion: useThunkDispatch(deployModelVersion),
    appendSampleImage: useThunkDispatch(appendSampleImage),
    removeSampleImages: useThunkDispatch(removeSampleImages),
  };
}

export async function submitCustomModelRequest(
  username: string,
  email: string,
  model_name: string,
  fields: string,
  remark: string,
  sampleUrls: string[]
): Promise<boolean> {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("num_sample", sampleUrls.length.toString());
  sampleUrls.forEach(x => formData.append("sample_urls", x));
  formData.append("email", email);
  formData.append("model_name", model_name);
  formData.append("fields", fields);
  formData.append("remark", remark);
  formData.append("environment", ENVIRONMENT);

  try {
    const response = await (
      await fetch(AppConfig.zapier.createAIModelRequestUrl, {
        method: "POST",
        body: formData,
      })
    ).json();

    return response.status === "success";
  } catch {
    return false;
  }
}
