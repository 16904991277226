import { PrimaryButton } from "@fluentui/react";
import {
  GroupHeader,
  GroupedList,
  IGroupHeaderProps,
  IGroupRenderProps,
} from "@fluentui/react/lib/GroupedList";
import { Icon } from "@fluentui/react/lib/Icon";
import React, { useCallback } from "react";

import { useLocale } from "../../contexts/locale";
import { useTokenGroupTabPane } from "../../contexts/tokenGroupTabPane";
import { useTeamPermission } from "../../hooks/permission";
import { ModalState } from "../../models";
import { TokenGroup } from "../../types/tokenGroup";
import ImageTokenModal from "../ImageTokenModal";
import { TokenGroupPaneNote } from "../InspectorNote";
import TokenGroupContent from "../TokenGroupContent";
import TokenGroupModal from "../TokenGroupModal";
import TokenGroupTextModal from "../TokenGroupTextModal";
import styles from "./styles.module.scss";

interface Props {
  tokenGroups: TokenGroup[];
}

const TokenGroupHeader = React.memo((props?: IGroupHeaderProps) => {
  const { onToggleCollapse, onEditGroupClick, onDeleteTokenGroup } =
    useTokenGroupTabPane();
  const { hasPermissionToEditResource } = useTeamPermission();

  if (!props || !props.group || props.groupIndex === undefined) {
    return null;
  }

  return (
    <div className={styles["token-group-header"]}>
      <GroupHeader
        {...props}
        styles={{
          check: { display: "none" },
          headerCount: { display: "none" },
        }}
        onToggleCollapse={onToggleCollapse(props.groupIndex)}
        onGroupHeaderClick={onToggleCollapse(props.groupIndex)}
      />
      {hasPermissionToEditResource && (
        <>
          <Icon
            className={styles["token-group-edit"]}
            iconName="Edit"
            onClick={onEditGroupClick(props.groupIndex)}
          />
          <Icon
            className={styles["token-group-delete"]}
            iconName="delete"
            onClick={onDeleteTokenGroup(props.groupIndex)}
          />
        </>
      )}
    </div>
  );
});

const GroupProps: IGroupRenderProps = {
  onRenderHeader: props => <TokenGroupHeader {...props} />,
};

const TokenGroupTabPane = React.memo(({ tokenGroups }: Props) => {
  const {
    isTokenGroupModalOpened,
    edittingTokenGroup,
    closeTokenGroupModal,
    onCreateTokenGroup,
    onTokenGroupModalSubmit,
    expandedTokenGroupIndex,
    isTextTokenModalOpened,
    closeTextTokenModal,
    edittingTextToken,
    onTextTokenModalSubmit,
    edittingImageToken,
    checkIfImageTokenNameUnique,
    isImageTokenModalOpened,
    closeImageTokenModal,
    onImageTokenModalSubmit,
    validateTokenGroupName,
    validateTextTokenValue,
  } = useTokenGroupTabPane();

  const { localized } = useLocale();
  const { hasPermissionToEditResource } = useTeamPermission();

  const onRenderCell = useCallback(
    (_arg1?: number, _arg2?: any, index?: number) => {
      if (index === undefined) {
        return null;
      }

      return (
        <TokenGroupContent
          tokenGroup={tokenGroups[index]}
          tokenGroupIndex={index}
        />
      );
    },
    [tokenGroups]
  );

  return (
    <div className={styles["content"]}>
      <TokenGroupPaneNote />
      {hasPermissionToEditResource && (
        <PrimaryButton
          className={styles["button"]}
          text={localized("add.token_group.button")}
          onClick={onCreateTokenGroup}
        />
      )}
      <GroupedList
        compact={true}
        groups={tokenGroups.map((tokenGroup, i) => {
          return {
            key: tokenGroup.id,
            name: tokenGroup.name,
            count: 1,
            startIndex: i,
            level: 1,
            isCollapsed: i !== expandedTokenGroupIndex,
          };
        })}
        groupProps={GroupProps}
        items={tokenGroups.map(({ name }) => name)}
        onRenderCell={onRenderCell}
      />

      <TokenGroupModal
        tokenGroupNameValidatorWithMessage={validateTokenGroupName}
        isOpen={isTokenGroupModalOpened !== ModalState.Closed}
        onCancel={closeTokenGroupModal}
        defaultValue={edittingTokenGroup}
        onSubmit={onTokenGroupModalSubmit}
      />
      <TokenGroupTextModal
        tokenTextValidatorWithMessage={validateTextTokenValue}
        isOpen={isTextTokenModalOpened !== ModalState.Closed}
        onCancel={closeTextTokenModal}
        defaultValue={edittingTextToken}
        onSubmit={onTextTokenModalSubmit}
      />
      <ImageTokenModal
        edittingImageToken={edittingImageToken}
        checkIsNameUnique={checkIfImageTokenNameUnique}
        onCancel={closeImageTokenModal}
        onSubmit={onImageTokenModalSubmit}
        isOpen={isImageTokenModalOpened !== ModalState.Closed}
      />
    </div>
  );
});

export default TokenGroupTabPane;
