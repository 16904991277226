import {
  CommandBar,
  IBreadcrumbItem,
  ICommandBarItemProps,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

import { useLocale } from "../../contexts/locale";
import { DetailFormGroup } from "../../types/formGroup";
import { Breadcrumb } from "../WrappedMSComponents/Breadcrumb";
import styles from "./styles.module.scss";

interface Props {
  formGroup: DetailFormGroup;
  commbarBarItems?: ICommandBarItemProps[];
}

const FormGroupNavBar = React.memo((props: Props) => {
  const { formGroup, commbarBarItems } = props;
  const { localized } = useLocale();
  const history = useHistory();
  const location = useLocation();

  const onBreadcrumbItemClick = useCallback(
    (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      if (ev === undefined || item === undefined || item.href === undefined) {
        return;
      }
      ev.preventDefault();
      history.push(item.href);
    },
    [history]
  );

  const breadcrumbItems: IBreadcrumbItem[] = useMemo(
    () =>
      formGroup.isTemplate
        ? [
            {
              text: localized("form_template.header_prefix", {
                name: localized(`form_template.${formGroup.name}`),
              }),
              key: formGroup.name,
            },
          ]
        : [
            {
              text: localized("header.form_groups"),
              key: "my-form-groups",
              href: "/form-group",
              onClick: onBreadcrumbItemClick,
            },
            { text: formGroup.name, key: formGroup.name },
          ],
    [formGroup.name, formGroup.isTemplate, localized, onBreadcrumbItemClick]
  );

  const onPivotItemClick = useCallback(
    (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
      if (!ev || !item || !item.props.itemKey) {
        return;
      }
      ev.preventDefault();
      ev.stopPropagation();
      if (window.location.pathname.split("/")[1] === "form-group-template") {
        history.push(
          `/form-group-template/${formGroup.id}/${item.props.itemKey}`
        );
      } else {
        history.push(`/form-group/${formGroup.id}/${item.props.itemKey}`);
      }
    },
    [formGroup.id, history]
  );

  const selectedKey = useMemo(
    () => location.pathname.split("/")[3],
    [location.pathname]
  );

  return (
    <div className={styles["container"]}>
      <Breadcrumb className={styles["breadcrumb"]} items={breadcrumbItems} />
      <div className={styles["tab-bar"]}>
        <Pivot onLinkClick={onPivotItemClick} selectedKey={selectedKey}>
          {!formGroup.isTemplate && (
            <PivotItem
              headerText={localized("header.edit")}
              itemKey="edit"
              itemIcon="tag"
            />
          )}
          <PivotItem
            headerText={localized("header.api")}
            itemKey="api"
            itemIcon="PlugConnected"
          />
          <PivotItem
            headerText={localized("header.test")}
            itemKey="test"
            itemIcon="TestBeaker"
          />
          <PivotItem
            headerText={localized("header.batch")}
            itemKey="batch"
            itemIcon="Copy"
          />
        </Pivot>
        {commbarBarItems && <CommandBar items={commbarBarItems} />}
      </div>
    </div>
  );
});

interface FormNavBarLayoutProps extends Props {
  children: React.ReactNode;
}

export const FormGroupNavBarLayout: React.FC<FormNavBarLayoutProps> = (
  props: FormNavBarLayoutProps
) => {
  const { children, ...rest } = props;

  return (
    <>
      <FormGroupNavBar {...rest} />
      <div className={styles["content"]}>{children}</div>
    </>
  );
};

export default FormGroupNavBar;
