import { produce } from "immer";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import {
  AddCustomModelInfoField,
  FreezeFieldsForLabelling,
  RemoveCustomModelInfoField,
  RenameCustomModel,
  RequestCustomModelTraining,
  UpdateCustomModelInfoField,
  submitCustomModelRequest,
  useCustomModelActionCreator,
} from "../actions/customModel";
import {
  DEFAULT_CUSTOM_MODEL_REMARK,
  DefaultCustomModel,
  SUPPORTED_EXTRACT_MIME,
  SUPPORTED_IMAGE_MIME,
} from "../constants";
import errors from "../errors";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { CustomModel, CustomModelSampleImage } from "../types/customModel";

const SUPPORTED_MIME_TYPES = SUPPORTED_EXTRACT_MIME.slice(0);

interface ContextProps {
  assignedName?: string | undefined;
  isCreationMode?: boolean | undefined;
  assignedCustomModelId?: string | undefined;
}

interface SharedData {
  customModel: CustomModel;
  isCreationMode: boolean;
  setIsCreationMode: (value: boolean) => void;
  assignedName: string;
  assignedCustomModelId: string | undefined;
  initialTrainingRequested: boolean;
  validateInfoFields: (fields: string[]) => (string | null)[];
}

function getJPEGDimension(dataURI: string) {
  const data = window.atob(dataURI.slice(dataURI.indexOf(",") + 1));
  const bufferSize = data.length;
  const startOfFrameMarkers = [
    0xc0, 0xc1, 0xc2, 0xc3, 0xc5, 0xc6, 0xc7, 0xc9, 0xca, 0xc8, 0xcd, 0xce,
    0xcf,
  ];

  const uint16At = (offset: number) =>
    (data.charCodeAt(offset) << 8) + data.charCodeAt(offset + 1);

  if (!(data.charCodeAt(0) === 0xff && data.charCodeAt(1) === 0xd8)) {
    return null;
  }

  let i = 2;
  while (i < bufferSize - 2) {
    if (data.charCodeAt(i) !== 0xff) {
      return null;
    }
    const marker = data.charCodeAt(i + 1);

    if (startOfFrameMarkers.includes(marker)) {
      return {
        height: uint16At(i + 5),
        width: uint16At(i + 7),
      };
    } else {
      const blockLength =
        marker >= 0xd0 && marker <= 0xd9
          ? 0
          : marker === 0xdd
          ? 4
          : uint16At(i + 2);

      i += blockLength + 2;
    }
  }

  return null;
}

function useImageHelpers(
  sharedData: SharedData,
  updateCurrentCustomModel: () => void
) {
  const { customModel } = sharedData;
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const { appendSampleImage, removeSampleImages } =
    useCustomModelActionCreator();
  const [checkedImageSet, setCheckedImageSet] = useState<Set<string>>(
    new Set<string>()
  );
  const toast = useToast();

  const isImageGridVisible = customModel.config.sampleImages.length > 0;

  const deleteImageEnabled = checkedImageSet.size > 0;

  const onCheckedImageMapChange = useCallback(
    (id: string) => (_event?: React.FormEvent<any>, value?: boolean) => {
      setCheckedImageSet(
        produce(checkedImageSet, draft => {
          if (value === undefined) {
            return;
          }
          if (value) {
            draft.add(id);
          } else {
            draft.delete(id);
          }
        })
      );
    },
    [checkedImageSet, setCheckedImageSet]
  );

  const removeCheckedImages = useCallback(() => {
    removeSampleImages(checkedImageSet);
    setCheckedImageSet(
      produce(checkedImageSet, draft => {
        draft.clear();
      })
    );
    updateCurrentCustomModel();
  }, [removeSampleImages, checkedImageSet, updateCurrentCustomModel]);

  const uploadCustomModelImage = useCallback(
    (file: File) => {
      if (!SUPPORTED_MIME_TYPES.includes(file.type)) {
        return;
      }

      const upload = async (
        file: File,
        dataURI?: string,
        width?: number,
        height?: number
      ) => {
        try {
          await appendSampleImage(customModel.id, file, dataURI, width, height);
          updateCurrentCustomModel();
        } catch (e) {
          console.error(e);
          toast.error("error.cannot_load_image");
        }
      };

      const validateImageThenUpload = (file: File, content: string) => {
        const image = new Image();
        image.addEventListener("load", async () => {
          const { width, height } =
            file.type === "image/jpeg"
              ? getJPEGDimension(content) || image
              : image;

          upload(file, content, width, height);
        });

        image.addEventListener("error", () => {
          toast.error("error.cannot_load_image");
        });

        image.src = content as string;
      };

      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          if (SUPPORTED_IMAGE_MIME.includes(file.type)) {
            validateImageThenUpload(file, reader.result as string);
          } else {
            upload(file);
          }
        },
        false
      );
      reader.readAsDataURL(file);
    },
    [appendSampleImage, toast, updateCurrentCustomModel, customModel]
  );

  const uploadCustomModelImages = useCallback(
    (files: File[]) => {
      const filesExcludingDotFiles = files.filter(file => {
        return !file.name.startsWith(".");
      });

      const filteredFiles = filesExcludingDotFiles.filter(file => {
        return SUPPORTED_MIME_TYPES.includes(file.type);
      });

      if (filteredFiles.length !== filesExcludingDotFiles.length) {
        if (filteredFiles.length === 0) {
          toast.error("error.custom_model.image_format_not_supported");
        } else {
          toast.info("error.custom_model.some_image_not_supported");
        }
      }

      filteredFiles.forEach(file => uploadCustomModelImage(file));
    },
    [uploadCustomModelImage, toast]
  );

  const onImageChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      if (
        !(
          e.target instanceof HTMLInputElement &&
          e.target.files &&
          e.target.files.length > 0
        )
      ) {
        return;
      }
      e.persist();
      const files = Array.from(e.target.files);

      uploadCustomModelImages(files);
      if (uploadInputRef.current) uploadInputRef.current.value = "";
    },
    [uploadCustomModelImages]
  );

  const InvisibleUploadImageButton = useCallback(() => {
    return (
      <input
        ref={uploadInputRef}
        style={{ display: "none" }}
        type="file"
        accept={SUPPORTED_EXTRACT_MIME.join(",")}
        multiple
        onChange={onImageChange}
      />
    );
  }, [onImageChange]);

  const openUploadFileDialog = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, []);

  return useMemo(
    () => ({
      uploadInputRef,
      openUploadFileDialog,
      InvisibleUploadImageButton,
      isImageGridVisible,
      onCheckedImageMapChange,
      checkedImageSet,
      removeCheckedImages,
      deleteImageEnabled,
      uploadCustomModelImage,
      uploadCustomModelImages,
    }),
    [
      uploadInputRef,
      InvisibleUploadImageButton,
      openUploadFileDialog,
      isImageGridVisible,
      onCheckedImageMapChange,
      checkedImageSet,
      removeCheckedImages,
      deleteImageEnabled,
      uploadCustomModelImage,
      uploadCustomModelImages,
    ]
  );
}

function useInfoFieldValidation() {
  const [infoFieldErrors, setInfoFieldErrors] = useState<
    (null | string)[] | null
  >(null);

  const validateInfoFields = useCallback(
    (infoFields: string[]) => {
      const errors = infoFields.map(field => {
        if (field.trim().length === 0)
          return "custom_model_editor.right_bar.label.error.empty";

        if (/^[a-z0-9_]+$/.test(field) === false)
          return "custom_model_editor.right_bar.label.error.invalid_format";

        return null;
      });
      setInfoFieldErrors(errors);
      return errors;
    },
    [setInfoFieldErrors]
  );

  return useMemo(
    () => ({
      validateInfoFields,
      infoFieldErrors,
      setInfoFieldErrors,
    }),
    [validateInfoFields, infoFieldErrors, setInfoFieldErrors]
  );
}

function useInfoFields(
  updateCurrentCustomModel: () => void,
  setInfoFieldErrors: ReturnType<
    typeof useInfoFieldValidation
  >["setInfoFieldErrors"]
) {
  const dispatch = useDispatch();

  const appendInfoField = useCallback(() => {
    dispatch({
      type: AddCustomModelInfoField,
      payload: {
        value: "",
      },
    });
    updateCurrentCustomModel();
    setInfoFieldErrors(errors => (errors === null ? null : [null, ...errors]));
  }, [dispatch, updateCurrentCustomModel, setInfoFieldErrors]);

  const onInfoFieldChange = useCallback(
    (index: number) =>
      (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        value?: string
      ) => {
        if (value === undefined) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();

        dispatch({
          type: UpdateCustomModelInfoField,
          payload: {
            index,
            value,
          },
        });
        setInfoFieldErrors(errors =>
          errors === null
            ? null
            : errors.map((v, i) => (i === index ? null : v))
        );
      },
    [dispatch, setInfoFieldErrors]
  );

  const onInfoFieldChangeCommit = useCallback(() => {
    updateCurrentCustomModel();
  }, [updateCurrentCustomModel]);

  const onInfoFieldRemove = useCallback(
    (index: number) => () => {
      dispatch({
        type: RemoveCustomModelInfoField,
        payload: {
          index,
        },
      });
      updateCurrentCustomModel();
      setInfoFieldErrors(errors => {
        const result =
          errors === null
            ? null
            : errors.slice(0, index).concat(errors.slice(index + 1));
        return result;
      });
    },
    [dispatch, updateCurrentCustomModel, setInfoFieldErrors]
  );

  return useMemo(
    () => ({
      appendInfoField,
      onInfoFieldChange,
      onInfoFieldChangeCommit,
      onInfoFieldRemove,
    }),
    [
      appendInfoField,
      onInfoFieldChange,
      onInfoFieldRemove,
      onInfoFieldChangeCommit,
    ]
  );
}

interface CustomModelRemarkModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (remark: string) => void;
}

function useNetworkHelpers(sharedData: SharedData) {
  const { customModel, initialTrainingRequested, validateInfoFields } =
    sharedData;
  const { currentUser } = useSelector((state: RootState) => {
    return state.user;
  });

  const toast = useToast();
  const customModelActionCreator = useCustomModelActionCreator();
  const { updateCustomModel, createCustomModel } = customModelActionCreator;
  const store = useStore();
  const dispatch = useDispatch();

  const [customModelRemarkModalProps, setCustomModelRemarkModalProps] =
    useState<CustomModelRemarkModalProps>({
      isOpen: false,
      onCancel: () => {},
      onSubmit: () => {},
    });

  const updateCurrentCustomModel = useCallback(async () => {
    try {
      const state = store.getState();
      const customModel = state.customModel.currentCustomModel;

      await updateCustomModel(customModel);
    } catch (e) {
      if (e !== errors.ConflictFound) {
        toast.error("error.custom_model.fail_to_save_custom_model");
        throw e;
      }
    }
  }, [updateCustomModel, toast, store]);

  const [
    isRequestCustomModelTrainingError,
    setIsRequestCustomModelTrainingError,
  ] = useState<boolean>(false);

  const requestCustomModelTraining = useCallback(() => {
    const closeModal = () => {
      setCustomModelRemarkModalProps({
        isOpen: false,
        onCancel: () => {},
        onSubmit: () => {},
      });
    };

    const request = async (remark: string) => {
      dispatch({
        type: RequestCustomModelTraining,
        payload: {
          remark,
        },
      });
      closeModal();
      const state = store.getState();
      const customModel = state.customModel.currentCustomModel;

      try {
        await updateCurrentCustomModel();
      } catch (e) {
        return;
      }

      try {
        if (currentUser !== undefined) {
          await submitCustomModelRequest(
            currentUser.username || "<no name>",
            currentUser.email,
            customModel.name,
            customModel.config.fields.join(","),
            customModel.config.remark,
            customModel.config.sampleImages.map(
              (x: CustomModelSampleImage) => x.assetId || ""
            )
          );
          toast.success("create_ai_form.info.submitted_form");
        }
      } catch (e) {
        toast.error("create_ai_form.error.fail_to_submit_form");
      }
    };

    const hasEmptyField = customModel.config.fields.some(f => f.trim() === "");

    if (hasEmptyField) {
      setIsRequestCustomModelTrainingError(true);
      return;
    }

    setIsRequestCustomModelTrainingError(false);

    if (!initialTrainingRequested) {
      setCustomModelRemarkModalProps({
        isOpen: true,
        onCancel: closeModal,
        onSubmit: (remark: string) => {
          request(remark === "" ? DEFAULT_CUSTOM_MODEL_REMARK : remark);
        },
      });
    } else {
      request(customModel.config.remark);
    }
  }, [
    initialTrainingRequested,
    customModel,
    currentUser,
    toast,
    dispatch,
    store,
    updateCurrentCustomModel,
  ]);

  const freezeFieldsForLabelling = useCallback(async (): Promise<boolean> => {
    const isInfoFieldsValid = validateInfoFields(
      customModel.config.fields
    ).every(x => x === null);

    if (!isInfoFieldsValid) {
      return false;
    }

    dispatch({
      type: FreezeFieldsForLabelling,
    });

    try {
      await updateCurrentCustomModel();
      return true;
    } catch (e) {
      return false;
    }
  }, [dispatch, updateCurrentCustomModel, validateInfoFields, customModel]);

  const renameCustomModel = useCallback(
    (name: string) => {
      dispatch({
        type: RenameCustomModel,
        payload: {
          name,
        },
      });

      return updateCurrentCustomModel();
    },
    [dispatch, updateCurrentCustomModel]
  );

  return useMemo(
    () => ({
      createCustomModel,
      renameCustomModel,
      customModelRemarkModalProps,
      updateCurrentCustomModel,
      requestCustomModelTraining,
      isRequestCustomModelTrainingError,
      freezeFieldsForLabelling,
    }),
    [
      createCustomModel,
      renameCustomModel,
      updateCurrentCustomModel,
      customModelRemarkModalProps,
      requestCustomModelTraining,
      isRequestCustomModelTrainingError,
      freezeFieldsForLabelling,
    ]
  );
}

function useGetCustomModelIfNotLaoded(sharedData: SharedData) {
  const { assignedCustomModelId, customModel } = sharedData;
  const { getCustomModel } = useCustomModelActionCreator();
  const toast = useToast();
  const [isFetching, setIsFetching] = useState<boolean>();
  const [errorCustomModelId, setErrorCustomModelId] = useState<string>("");

  const isCustomModelFetchError = errorCustomModelId === assignedCustomModelId;

  useEffect(() => {
    if (
      assignedCustomModelId === undefined ||
      customModel.id === assignedCustomModelId ||
      isFetching ||
      assignedCustomModelId === errorCustomModelId
    ) {
      return;
    }

    setIsFetching(true);
    setErrorCustomModelId("");
    getCustomModel(assignedCustomModelId)
      .then(() => {
        setIsFetching(false);
      })
      .catch(() => {
        setErrorCustomModelId(assignedCustomModelId);
        setIsFetching(false);
      });
  }, [
    getCustomModel,
    toast,
    assignedCustomModelId,
    customModel,
    isFetching,
    errorCustomModelId,
  ]);

  return useMemo(
    () => ({
      isCustomModelFetchError,
    }),
    [isCustomModelFetchError]
  );
}

function useMakeContext(props: ContextProps) {
  const assignedName =
    props.assignedName !== undefined ? props.assignedName : "";

  const assignedCustomModelId = props.assignedCustomModelId;

  const [isCreationMode, setIsCreationMode] = useState<boolean>(
    props.isCreationMode !== undefined ? props.isCreationMode : false
  );

  let customModel = useSelector((state: RootState) => {
    return state.customModel.currentCustomModel;
  });
  if (
    assignedCustomModelId !== undefined &&
    customModel.id !== assignedCustomModelId
  ) {
    customModel = { ...DefaultCustomModel };
  }

  const customModelValid =
    customModel.config.sampleImages.length > 0 &&
    customModel.config.fields.filter(field => field.trim() !== "").length > 0;

  const initialTrainingRequested = customModel.config.remark !== "";

  const updateTrainingRequestEnabled =
    customModelValid &&
    (!initialTrainingRequested
      ? true
      : customModel.config.trainingRequested !== true);

  const { infoFieldErrors, setInfoFieldErrors, validateInfoFields } =
    useInfoFieldValidation();

  const sharedData = {
    customModel,
    isCreationMode,
    setIsCreationMode,
    assignedName,
    assignedCustomModelId,
    initialTrainingRequested,
    validateInfoFields,
  };

  const loadErrors = useGetCustomModelIfNotLaoded(sharedData);

  const networkHelpers = useNetworkHelpers(sharedData);
  const imageHelpers = useImageHelpers(
    sharedData,
    networkHelpers.updateCurrentCustomModel
  );
  const infoFields = useInfoFields(
    networkHelpers.updateCurrentCustomModel,
    setInfoFieldErrors
  );

  return useMemo(
    () => ({
      customModel,
      isCreationMode,
      customModelValid,
      initialTrainingRequested,
      updateTrainingRequestEnabled,
      infoFieldErrors,
      ...loadErrors,
      ...imageHelpers,
      ...infoFields,
      ...networkHelpers,
    }),
    [
      isCreationMode,
      imageHelpers,
      infoFields,
      customModel,
      networkHelpers,
      customModelValid,
      initialTrainingRequested,
      updateTrainingRequestEnabled,
      loadErrors,
      infoFieldErrors,
    ]
  );
}

type CustomModelEditorContextValue = ReturnType<typeof useMakeContext>;
const CustomModelEditorContext = createContext<CustomModelEditorContextValue>(
  null as any
);

interface Props extends ContextProps {
  children: React.ReactNode;
}

export const CustomModelEditorProvider = (props: Props) => {
  const { assignedName, isCreationMode, assignedCustomModelId } = props;
  const value = useMakeContext({
    assignedName,
    isCreationMode,
    assignedCustomModelId,
  });
  return <CustomModelEditorContext.Provider {...props} value={value} />;
};

export function useCustomModelEditor() {
  return useContext(CustomModelEditorContext);
}
