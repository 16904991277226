import { IDropdownOption } from "@fluentui/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";

import {
  exportForm,
  fetchCustomModelOptions,
  useFormActionCreator,
} from "../actions/form";
import { chooseFile } from "../components/FileButton";
import ConfirmModal from "../components/SettingTabPane/ConfirmModal";
import { useToast } from "../hooks/toast";
import { useMasterImageSizeValidator } from "../hooks/validators";
import { RootState } from "../redux/types";
import { DetailedForm } from "../types/form";
import { rotateAndCompressImage } from "../utils/image";
import { buildFormSettings, useFormEditor } from "./formEditor";
import { useLocale } from "./locale";

function useCustomModelOptions(existingCustomModelIds: string[]) {
  const resourceOwnerId = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.resourceOwnerId
  );
  const [fetchedCustomModelOptions, setFetchedCustomModelOptions] = useState<
    IDropdownOption[]
  >([]);

  useEffect(() => {
    fetchCustomModelOptions(resourceOwnerId).then(customModels => {
      setFetchedCustomModelOptions(
        customModels.map(x => ({
          key: x.id,
          text: x.name,
        }))
      );
    });
  }, [resourceOwnerId]);

  return useMemo(
    () => ({
      hasCustomModel: fetchedCustomModelOptions.length > 0,
      customModelOptions: fetchedCustomModelOptions.filter(
        x => !existingCustomModelIds.includes(`${x.key}`)
      ),
      existingCustomModelOptions: fetchedCustomModelOptions.filter(x =>
        existingCustomModelIds.includes(`${x.key}`)
      ),
    }),
    [fetchedCustomModelOptions, existingCustomModelIds]
  );
}

function useCustomModelSelection(existingCustomModelIds: string[]) {
  const { localized } = useLocale();
  const { customModelOptions, hasCustomModel, existingCustomModelOptions } =
    useCustomModelOptions(existingCustomModelIds);
  const { updateFormCustomModels } = useFormActionCreator();
  const [selectedCustomModel, setSelectedCustomModel] = useState("");
  const [
    customModelSelectionErrorMessage,
    setCustomModelSelectionErrorMessage,
  ] = useState<string | undefined>();

  const onCustomModelSelectionChange = useCallback(
    (
      _event: React.FormEvent<unknown>,
      option?: IDropdownOption,
      _n?: number
    ) => {
      if (option === undefined) {
        return;
      }
      setCustomModelSelectionErrorMessage(undefined);
      setSelectedCustomModel(option.key as string);
    },
    []
  );

  const addCustomModelToForm = useCallback(() => {
    if (selectedCustomModel === "") {
      setCustomModelSelectionErrorMessage(
        localized("setting.tab.custom_model.error.empty")
      );
      return;
    }
    updateFormCustomModels([...existingCustomModelIds, selectedCustomModel]);
  }, [
    localized,
    selectedCustomModel,
    updateFormCustomModels,
    existingCustomModelIds,
  ]);

  const removeCustomModelFromForm = useCallback(
    (customModelId: string) => {
      updateFormCustomModels(
        existingCustomModelIds.filter(x => x !== customModelId)
      );
    },
    [updateFormCustomModels, existingCustomModelIds]
  );

  return useMemo(
    () => ({
      selectedCustomModel,
      customModelSelectionErrorMessage,
      onCustomModelSelectionChange,
      addCustomModelToForm,
      removeCustomModelFromForm,
      customModelOptions,
      existingCustomModelOptions,
      hasCustomModel,
    }),
    [
      selectedCustomModel,
      customModelSelectionErrorMessage,
      onCustomModelSelectionChange,
      addCustomModelToForm,
      removeCustomModelFromForm,
      customModelOptions,
      existingCustomModelOptions,
      hasCustomModel,
    ]
  );
}

function useMakeContext(form: DetailedForm) {
  const toast = useToast();
  const { updateForm, updateImage, importForm } = useFormEditor();

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [fileToImport, setFileToImport] = useState<File>();
  useState(false);
  const [isAdvancedFormSettingVisible, setIsAdvancedFormSettingVisible] =
    useState<boolean>(false);

  const formSettings = buildFormSettings(form);

  const states = useSelector((state: RootState) => state);

  const { resourceOwnerId } = states.resourceOwner;

  const onClickExportButton = useCallback(async () => {
    setIsExporting(true);
    try {
      await exportForm(form.id, resourceOwnerId);
    } catch {
      toast.error("error.fail_to_export_form");
    } finally {
      setIsExporting(false);
    }
  }, [form, toast, resourceOwnerId]);

  const { onOpenScriptModal } = useFormEditor();

  const updatePostProcessScript = React.useCallback(
    (script: string) => {
      updateForm(
        buildFormSettings(form, {
          postProcessScript:
            script === undefined || script.trim() === "" ? undefined : script,
        })
      );
    },
    [updateForm, form]
  );

  const openScriptModal = onOpenScriptModal({
    form,
    onSave: updatePostProcessScript,
  });

  const onClickEditPostProcessScriptButton = useCallback(() => {
    try {
      openScriptModal();
    } catch {
      toast.error("error.fail_to_open_script_editor");
    }
  }, [toast, openScriptModal]);

  const onConfirmImport = useCallback(async () => {
    try {
      if (fileToImport) {
        setIsImporting(true);
        await importForm(fileToImport, form.id);
        toast.success("common.import_form_success");
      }
    } catch {
      toast.error("error.fail_to_import_form");
    } finally {
      setIsImporting(false);
    }
  }, [form, toast, importForm, fileToImport]);

  const onClickImportButton = useCallback(async () => {
    const files = await chooseFile("application/zip");
    if (!files || files.length === 0) return;
    setFileToImport(files[0]);
  }, []);

  const onFormNameChange = useCallback(
    (name: string) => {
      updateForm(buildFormSettings(form, { name }));
    },
    [updateForm, form]
  );

  const onNumFormFeatureChange = useCallback(
    (newValue: string) => {
      updateForm(
        buildFormSettings(form, {
          numFormFeature: +newValue,
        })
      );
    },
    [form, updateForm]
  );

  const onNumQueryFeatureChange = useCallback(
    (newValue: string) => {
      updateForm(
        buildFormSettings(form, {
          numQueryFeature: +newValue,
        })
      );
    },
    [form, updateForm]
  );

  const valdiateUploadImageSize = useMasterImageSizeValidator();

  const onImageFileChange = useCallback(
    (files?: File[]) => {
      setIsLoading(true);

      if (!files || !files[0]) {
        toast.error("form.editor.no.image.missing.image");
        return;
      }

      if (!valdiateUploadImageSize(files[0])) {
        setIsLoading(false);
        return;
      }

      rotateAndCompressImage(files[0])
        .then(updateImage)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          toast.error("error.cannot_load_image");
          setIsLoading(false);
        });
    },
    [toast, updateImage, valdiateUploadImageSize]
  );

  const onAdvancedFormSettings = useCallback(() => {
    setIsAdvancedFormSettingVisible(prevState => !prevState);
  }, []);

  const customModelSelection = useCustomModelSelection(form.customModelIds);

  const confirmModal = useMemo(
    () => (
      <ConfirmModal
        file={fileToImport}
        onCancel={() => setFileToImport(undefined)}
        onConfirm={() => {
          onConfirmImport();
          setFileToImport(undefined);
        }}
      />
    ),
    [onConfirmImport, fileToImport]
  );

  return useMemo(
    () => ({
      isLoading,
      isExporting,
      isImporting,
      isAdvancedFormSettingVisible,
      onClickExportButton,
      onClickImportButton,
      onClickEditPostProcessScriptButton,
      onFormNameChange,
      onNumFormFeatureChange,
      onNumQueryFeatureChange,
      onImageFileChange,
      onAdvancedFormSettings,
      formSettings,
      confirmModal,
      ...customModelSelection,
    }),
    [
      isLoading,
      isImporting,
      isExporting,
      isAdvancedFormSettingVisible,
      onClickExportButton,
      onClickImportButton,
      onClickEditPostProcessScriptButton,
      onFormNameChange,
      onNumFormFeatureChange,
      onNumQueryFeatureChange,
      onImageFileChange,
      onAdvancedFormSettings,
      formSettings,
      customModelSelection,
      confirmModal,
    ]
  );
}

type SettingTabContextValue = ReturnType<typeof useMakeContext>;
const SettingTabContext = createContext<SettingTabContextValue>(null as any);

interface Props {
  form: DetailedForm;
  children: React.ReactNode;
}

export const SettingTabProvider = (props: Props) => {
  const value = useMakeContext(props.form);
  return <SettingTabContext.Provider {...props} value={value} />;
};

export function useSettingTabPane() {
  return useContext(SettingTabContext);
}
