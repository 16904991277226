@import "../../styles/variables.scss";

.pattern-preset-selector {
  width: 100%;
  display: inline-block;
  color: #a3a2a0;
}

.pattern-preset-selector > :global(.Select-control) {
  border-radius: 2px;
  border: 1px solid #a3a2a0 !important;
  box-shadow: none !important;
}

.pattern-range {
  display: flex;
  flex-direction: row;
  flex: 1;

  .spin-separator {
    width: 48px;
  }

  .spin-button {
    width: 100px;
  }
}

.reminder {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  > span {
    font-size: 10px;
  }
}
