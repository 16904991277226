import { PreferenceKey, setPreference } from "./preference";

export function getParam(key: URLParamsKey): string | null {
  return new URLSearchParams(window.location.search).get(key);
}

export function setParam(
  key: URLParamsKey,
  value: string,
  isReplace: boolean = true
) {
  setParams(new Map([[key, value]]), isReplace);
}

export function removeParam(key: URLParamsKey, isReplace: boolean = false) {
  const url = new URL(window.location.href);
  url.searchParams.delete(key);
  setTimeout(() => {
    if (isReplace) {
      window.history.replaceState(null, "", url.toString());
    } else {
      window.history.pushState(null, "", url.toString());
    }
  }, 0);
}

export function setParams(
  keyValues: Map<URLParamsKey, string | undefined>,
  isReplace: boolean = true
) {
  const url = new URL(window.location.href);
  let teamValue: string | undefined = undefined;

  for (const key of keyValues.keys()) {
    const value = keyValues.get(key);

    if (key === URLParamsKey.team) {
      teamValue = value;
    }

    if (value === undefined || value === "") {
      url.searchParams.delete(key);
    } else {
      url.searchParams.set(key, value);
    }
  }

  setTimeout(() => {
    if (isReplace) {
      window.history.replaceState(null, "", url.toString());
    } else {
      window.history.pushState(null, "", url.toString());
    }

    if (teamValue) {
      setPreference(PreferenceKey.lastSelectedTeamLookupId, teamValue);
    }
  }, 0);
}

export enum URLParamsKey {
  team = "team",
  region = "region",
  filterType = "filter_type",
  page = "page",
  search = "search",
  plan = "plan",
}
