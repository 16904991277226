import React, { createContext, useContext, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

import {
  moveToNextStep as _moveToNextStep,
  setCurrentTutorial as _setCurrentTutorial,
  terminateCurrentTutorial as _terminateCurrentTutorial,
} from "../actions/tutorial";
import { useThunkDispatch } from "../hooks/thunk";
import { RootState } from "../redux/types";

function useBindActionCreators() {
  return {
    setCurrentTutorial: useThunkDispatch(_setCurrentTutorial),
    terminateCurrentTutorial: useThunkDispatch(_terminateCurrentTutorial),
    moveToNextStep: useThunkDispatch(_moveToNextStep),
  };
}

function useStatesDeducedFromReduxStore() {
  return useSelector((state: RootState) => {
    const { tutorial, indexPath, content, currentLevelLength } = state.tutorial;

    const [numerator, denominator] =
      indexPath && currentLevelLength
        ? [indexPath[indexPath.length - 1] + 1, currentLevelLength]
        : [0, 0];
    return {
      tutorial,
      step: [numerator, denominator],
      content,
    };
  }, shallowEqual);
}

function useMakeContext() {
  const boundActionCreators = useBindActionCreators();
  const states = useStatesDeducedFromReduxStore();

  return useMemo(
    () => ({
      ...boundActionCreators,
      ...states,
    }),
    [boundActionCreators, states]
  );
}

type TutorialContextValue = ReturnType<typeof useMakeContext>;
const TutorialContext = createContext<TutorialContextValue>(null as any);

interface Props {
  children: React.ReactNode;
}

export const TutorialProvider = (props: Props) => {
  const value = useMakeContext();
  return <TutorialContext.Provider {...props} value={value} />;
};
export function useTutorial() {
  return useContext(TutorialContext);
}
