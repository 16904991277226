import { DefaultButton, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface Props {
  isSyncWithCVATInProgress: boolean;
  canAccessCVATProject: boolean;
  isTrainingInProgress: boolean;
  triggerSyncWithCVAT: () => void;
  openCVATProject: () => void;
  triggerModelTraining: () => void;
}

function _CustomModelLabel(props: Props) {
  const { localized } = useLocale();
  const {
    isSyncWithCVATInProgress,
    canAccessCVATProject,
    isTrainingInProgress,
    triggerSyncWithCVAT,
    openCVATProject,
    triggerModelTraining,
  } = props;

  const syncDescriptionHighlightedText = (
    <b>{localized("custom_model_label.sync.desc2.highlighted")}</b>
  );

  return (
    <div className={styles["container"]}>
      <Text variant="xxLarge">
        <FormattedMessage id="custom_model_label.title" />
      </Text>

      <div className={styles["step"]}>
        <div className={styles["title"]}>
          <Text variant="large">
            <FormattedMessage id="custom_model_label.sync.title" />
          </Text>
        </div>
        <div className={styles["description"]}>
          <Text variant="medium">
            <FormattedMessage id="custom_model_label.sync.desc" />
          </Text>
        </div>
        <div className={styles["description"]}>
          <Text variant="medium">
            <FormattedMessage
              id="custom_model_label.sync.desc2"
              values={{
                highlighted: syncDescriptionHighlightedText,
              }}
            />
          </Text>
        </div>
        <DefaultButton
          className={styles["action-button"]}
          disabled={isSyncWithCVATInProgress}
          onClick={triggerSyncWithCVAT}
        >
          {isSyncWithCVATInProgress ? (
            <>
              <Spinner size={SpinnerSize.small} />
              <FormattedMessage id="custom_model_label.sync.button.in_progress" />
            </>
          ) : (
            <FormattedMessage id="custom_model_label.sync.button" />
          )}
        </DefaultButton>
      </div>
      <div className={styles["step"]}>
        <div className={styles["title"]}>
          <Text variant="large">
            <FormattedMessage id="custom_model_label.label.title" />
          </Text>
        </div>
        <div className={styles["description"]}>
          <Text>
            <FormattedMessage
              id="custom_model_label.label.desc"
              values={{ documentLayout: <code>{"document.layout"}</code> }}
            />
          </Text>
        </div>
        <div className={styles["description"]}>
          <Text>
            <FormattedMessage id="custom_model_label.label.desc2" />
          </Text>
        </div>
        <DefaultButton
          className={styles["action-button"]}
          disabled={!canAccessCVATProject}
          onClick={openCVATProject}
        >
          <FormattedMessage id="custom_model_label.label.button" />
        </DefaultButton>
      </div>
      <div className={styles["step"]}>
        <div className={styles["title"]}>
          <Text variant="large">
            <FormattedMessage id="custom_model_label.train.title" />
          </Text>
        </div>
        <div className={styles["description"]}>
          <Text>
            <FormattedMessage id="custom_model_label.train.desc" />
          </Text>
        </div>
        <DefaultButton
          className={styles["action-button"]}
          disabled={!canAccessCVATProject || isTrainingInProgress}
          onClick={triggerModelTraining}
        >
          {isTrainingInProgress ? (
            <>
              <Spinner size={SpinnerSize.small} />
              <FormattedMessage id="custom_model_label.train.button.in_progress" />
            </>
          ) : (
            <FormattedMessage id="custom_model_label.train.button" />
          )}
        </DefaultButton>
      </div>
    </div>
  );
}

export const CustomModelLabel = React.memo(_CustomModelLabel);
export default CustomModelLabel;
