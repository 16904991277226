import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import { exportForm } from "../../actions/form";
import { exportFormGroup } from "../../actions/formGroup";
import { useLocale } from "../../contexts/locale";
import {
  useFetchTeamCustomModels,
  useFetchTeamFormGroups,
  useFetchTeamForms,
} from "../../hooks/admin";
import { RootState } from "../../redux/types";
import { BriefCustomModel } from "../../types/customModel";
import { BriefForm } from "../../types/form";
import { BriefFormGroup } from "../../types/formGroup";
import { PageInfoWithOffset } from "../../types/pageInfo";
import Paginator from "../Paginator";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import styles from "./styles.module.scss";

const PAGE_SIZE = 20;

function getCurrentPage(offset: number): number {
  if (offset === 0) {
    return 1;
  }
  return Math.max(Math.ceil((offset + 1) / PAGE_SIZE), 1);
}

function getOffset(page: number | null): number {
  if (page === null || isNaN(page) || page <= 0) {
    return 0;
  }
  return PAGE_SIZE * (page - 1);
}

function getIsEmpty(
  isFetching: boolean,
  pageInfo?: PageInfoWithOffset
): boolean {
  return !isFetching && (pageInfo?.totalCount ?? 0) === 0;
}

interface ReduxProps {
  forms?: BriefForm[];
  isFormsFetching: boolean;
  formsPageInfo?: PageInfoWithOffset;
  formGroups?: BriefFormGroup[];
  isFormGroupsFetching: boolean;
  formGroupsPageInfo?: PageInfoWithOffset;
  customModels?: BriefCustomModel[];
  isCustomModelsFetching: boolean;
  customModelsPageInfo?: PageInfoWithOffset;
}

function useRedux(): ReduxProps {
  const {
    forms,
    formGroups,
    customModels,
    isFormsFetching,
    isFormGroupsFetching,
    isCustomModelsFetching,
    formsPageInfo,
    formGroupsPageInfo,
    customModelsPageInfo,
  } = useSelector((state: RootState) => {
    const { formGroupsState, formsState, customModelsState } =
      state.admin.teamDetail.resourceState;

    return {
      forms: formsState.forms,
      isFormsFetching: formsState.isFetching,
      formsPageInfo: formsState.pageInfo,
      formGroups: formGroupsState.formGroups,
      isFormGroupsFetching: formGroupsState.isFetching,
      formGroupsPageInfo: formGroupsState.pageInfo,
      customModels: customModelsState.customModels,
      isCustomModelsFetching: customModelsState.isFetching,
      customModelsPageInfo: customModelsState.pageInfo,
    };
  });

  return {
    forms,
    formGroups,
    customModels,
    isFormsFetching,
    isFormGroupsFetching,
    isCustomModelsFetching,
    formsPageInfo,
    formGroupsPageInfo,
    customModelsPageInfo,
  };
}

interface EmptyStateUIProps {
  messageId: string;
}

function EmptyStateUI(props: EmptyStateUIProps) {
  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage id={props.messageId} />
          </Label>
        </div>
      </div>
    </div>
  );
}

interface CustomModelTableRowProps {
  name: string;
  picturesUploaded: number;
  id: string;
  createdAt?: Date | null;
  status: string;
}

function CustomModelTableRowItem(props: CustomModelTableRowProps) {
  const { name, picturesUploaded, id, status, createdAt } = props;

  return (
    <tr className={styles["resource-table-row"]}>
      <td>{name}</td>
      <td className={styles["resource-table-row-info"]}>{picturesUploaded}</td>
      <td className={styles["resource-table-row-info"]}>{id}</td>
      <td className={styles["resource-table-row-info"]}>
        {createdAt != null && (
          <>
            {createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}
          </>
        )}
      </td>
      <td className={styles["resource-table-row-info"]}>{status}</td>
    </tr>
  );
}

interface CustomModelTableProps {
  teamId: string;
  region: string;
}

function CustomModelTable(props: CustomModelTableProps) {
  const { teamId, region } = props;
  const { customModels, isCustomModelsFetching, customModelsPageInfo } =
    useRedux();
  const { localized } = useLocale();
  const [offset, setOffset] = React.useState(0);
  const onNavigateToPage = React.useCallback((pageNumber: number) => {
    setOffset(getOffset(pageNumber));
  }, []);

  const currentPage = React.useMemo(() => {
    return getCurrentPage(offset);
  }, [offset]);

  const isEmpty = React.useMemo(() => {
    return getIsEmpty(isCustomModelsFetching, customModelsPageInfo);
  }, [isCustomModelsFetching, customModelsPageInfo]);

  useFetchTeamCustomModels(PAGE_SIZE, offset, teamId, region);

  if (isCustomModelsFetching) {
    return <ShortSpinner />;
  }

  if (customModels && !isEmpty) {
    return (
      <>
        <Table
          columnIds={[
            "team.detail.resources.name",
            "team.detail.resources.no_of_picture_uploaded",
            "team.detail.resources.model_id",
            "team.detail.created_at",
            "team.detail.resources.status",
          ]}
          className={styles["team-table"]}
        >
          {customModels.map(customModel => (
            <CustomModelTableRowItem
              key={customModel.id}
              name={customModel.name}
              id={customModel.id}
              createdAt={customModel.createdAt}
              picturesUploaded={customModel.noOfSampleImages}
              status={localized(
                `team.detail.resources.custom_model.status.${customModel.status}`
              )}
            />
          ))}
        </Table>
        <Paginator
          currentPage={currentPage}
          totalCount={customModelsPageInfo?.totalCount ?? 0}
          pageSize={PAGE_SIZE}
          navigateToPage={onNavigateToPage}
        />
      </>
    );
  }

  return <EmptyStateUI messageId="team.detail.resources.custom_models.empty" />;
}

interface FormOrGroupTableRowItemProps {
  name: string;
  type: string;
  id: string;
  exportHandler(id: string): void;
}

function FormOrGroupTableRowItem(props: FormOrGroupTableRowItemProps) {
  const { name, type, id, exportHandler } = props;

  const onExportClicked = React.useCallback(() => {
    exportHandler(id);
  }, [exportHandler, id]);

  return (
    <tr className={styles["resource-table-row"]}>
      <td>{name}</td>
      <td className={styles["resource-table-row-info"]}>{type}</td>
      <td className={styles["resource-table-row-info"]}>{id}</td>
      <td className={styles["download-button"]} onClick={onExportClicked}>
        <FormattedMessage id="audit.download" />
      </td>
    </tr>
  );
}

interface FormTableProps {
  teamId: string;
  region: string;
  exportForm: (formId: string) => void;
}

function FormTable(props: FormTableProps) {
  const { teamId, region, exportForm } = props;
  const { localized } = useLocale();

  const { forms, isFormsFetching, formsPageInfo } = useRedux();
  const [offset, setOffset] = React.useState(0);
  const onNavigateToPage = React.useCallback((pageNumber: number) => {
    setOffset(getOffset(pageNumber));
  }, []);
  const onExportClicked = React.useCallback(
    async (formId: string) => {
      exportForm(formId);
    },
    [exportForm]
  );

  const currentPage = React.useMemo(() => {
    return getCurrentPage(offset);
  }, [offset]);

  const isEmpty = React.useMemo(() => {
    return getIsEmpty(isFormsFetching, formsPageInfo);
  }, [isFormsFetching, formsPageInfo]);

  useFetchTeamForms(PAGE_SIZE, offset, teamId, region);

  if (isFormsFetching) {
    return <ShortSpinner />;
  }

  if (forms && !isEmpty) {
    return (
      <>
        <Table
          columnIds={[
            "team.detail.resources.name",
            "form_inspector.document_field.type",
            "team.detail.resources.form_id",
            "common.export",
          ]}
          className={styles["team-table"]}
        >
          {forms.map(form => (
            <FormOrGroupTableRowItem
              key={form.id}
              name={form.name}
              type={localized(`document_type.${form.documentType}`)}
              exportHandler={onExportClicked}
              id={form.id}
            />
          ))}
        </Table>
        <Paginator
          currentPage={currentPage}
          totalCount={formsPageInfo?.totalCount ?? 0}
          pageSize={PAGE_SIZE}
          navigateToPage={onNavigateToPage}
        />
      </>
    );
  }

  return <EmptyStateUI messageId="team.detail.resources.forms.empty" />;
}

interface FormGroupTableProps {
  teamId: string;
  region: string;
  exportFormGroup: (formGroupId: string) => void;
}

function FormGroupTable(props: FormGroupTableProps) {
  const { teamId, region, exportFormGroup } = props;
  const { localized } = useLocale();

  const { formGroups, isFormGroupsFetching, formGroupsPageInfo } = useRedux();
  const [offset, setOffset] = React.useState(0);
  const onNavigateToPage = React.useCallback((pageNumber: number) => {
    setOffset(getOffset(pageNumber));
  }, []);
  const onExportClicked = React.useCallback(
    async (formGroupId: string) => {
      exportFormGroup(formGroupId);
    },
    [exportFormGroup]
  );

  const currentPage = React.useMemo(() => {
    return getCurrentPage(offset);
  }, [offset]);

  const isEmpty = React.useMemo(() => {
    return getIsEmpty(isFormGroupsFetching, formGroupsPageInfo);
  }, [isFormGroupsFetching, formGroupsPageInfo]);

  useFetchTeamFormGroups(PAGE_SIZE, offset, teamId, region);

  if (isFormGroupsFetching) {
    return <ShortSpinner />;
  }

  if (formGroups && !isEmpty) {
    return (
      <>
        <Table
          columnIds={[
            "team.detail.resources.name",
            "team.detail.resources.mode",
            "team.detail.resources.form_group_id",
            "common.export",
          ]}
          className={styles["team-table"]}
        >
          {formGroups.map(formGroup => (
            <FormOrGroupTableRowItem
              key={formGroup.id}
              name={formGroup.name}
              type={localized(
                `team.detail.resources.form_group_type.${formGroup.type}`
              )}
              id={formGroup.id}
              exportHandler={onExportClicked}
            />
          ))}
        </Table>
        <Paginator
          currentPage={currentPage}
          totalCount={formGroupsPageInfo?.totalCount ?? 0}
          pageSize={PAGE_SIZE}
          navigateToPage={onNavigateToPage}
        />
      </>
    );
  }

  return <EmptyStateUI messageId="team.detail.resources.form_groups.empty" />;
}

enum TeamResourceTab {
  forms = "forms",
  formGroups = "form_groups",
  customModels = "custom_models",
}

interface ResourceTabItemProps {
  label: string;
  type: TeamResourceTab;
  isActive?: boolean;
  setCurrentTab: (tab: TeamResourceTab) => void;
}

function ResourceTabItem(props: ResourceTabItemProps) {
  const { label, isActive, type, setCurrentTab } = props;

  const onClick = React.useCallback(() => {
    setCurrentTab(type);
  }, [type, setCurrentTab]);

  return (
    <div
      className={cn(styles["resource-tab-item"], {
        [styles["active"]]: isActive,
      })}
      onClick={onClick}
    >
      {label}
    </div>
  );
}

interface Props {
  teamId: string;
  region: string;
  exportForm: (formId: string) => void;
  exportFormGroup: (formGroupId: string) => void;
}

function _TeamDetailResources(props: Props) {
  const { teamId, region } = props;
  const { localized } = useLocale();
  const [currentTab, setCurrentTab] = React.useState<TeamResourceTab>(
    TeamResourceTab.forms
  );

  return (
    <div className={styles["team-resource"]}>
      <div className={styles["tab-container"]}>
        {Object.values(TeamResourceTab).map(tab => {
          return (
            <ResourceTabItem
              key={tab}
              label={localized(`team.detail.${tab.valueOf()}`)}
              type={tab}
              isActive={currentTab === tab}
              setCurrentTab={setCurrentTab}
            />
          );
        })}
      </div>
      <div className={styles["list-container"]}>
        {currentTab === TeamResourceTab.forms && (
          <FormTable teamId={teamId} region={region} exportForm={exportForm} />
        )}
        {currentTab === TeamResourceTab.formGroups && (
          <FormGroupTable
            teamId={teamId}
            region={region}
            exportFormGroup={exportFormGroup}
          />
        )}
        {currentTab === TeamResourceTab.customModels && (
          <CustomModelTable teamId={teamId} region={region} />
        )}
      </div>
    </div>
  );
}

export const TeamDetailResources = React.memo(_TeamDetailResources);
export default TeamDetailResources;
