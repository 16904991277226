import { AppConfig } from "../config";
import { APIDescription } from "../models";

export const DetectMultiDocumentAPIDescription: APIDescription = {
  name: "detect_documents",
  method: "POST",
  endpoint: `${AppConfig.worker.endpoint}detect-documents`,

  headerParams: [
    { name: "Content-Type", isOptional: true },
    { name: "X-WORKER-TOKEN" },
    { name: "X-WORKER-IMAGE-URL", isOptional: true },
    { name: "X-WORKER-ENCODING", isOptional: true, defaultValue: "raw" },
    { name: "X-WORKER-PDF-DPI", isOptional: true, defaultValue: "150" },
  ],

  formParams: [
    { name: "image", isOptional: true },
    { name: "image_url", isOptional: true },
    { name: "pdf_dpi", isOptional: true, defaultValue: "100" },
  ],
  responseFields: [
    { name: "status", fieldType: "string" },
    { name: "documents", fieldType: "Document[]" },
    { name: "error", isOptional: true, fieldType: "any" },
  ],
  types: [
    {
      name: "Document",
      fields: [
        { name: "bbox", fieldType: "number[]" },
        { name: "bbox_score", fieldType: "number" },
        { name: "type", fieldType: "string" },
        { name: "type_score", fieldType: "number" },
      ],
    },
  ],
  cURLExample: `curl -X POST \\
${AppConfig.worker.endpoint}detect-documents \\
-H 'Content-Type: image/jpeg' \\
-H 'X-WORKER-TOKEN: REPLACE-YOUR-WORKER-TOKEN-HERE' \\
--data-binary "@/path/to/query/image.jpg"`,
};
