import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import Modal from "react-modal";

import Spinner from "../Spinner";
import styles from "./styles.module.scss";

interface Props extends Modal.Props {
  messageId?: string;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "2px",
  },
  overlay: {
    zIndex: 1001,
  },
};

function _LoadingModal(props: Props) {
  const { messageId, ...modalProps } = props;

  return (
    <Modal {...modalProps} style={customStyles}>
      <div className={styles["loading"]}>
        <Spinner />
        <div className={styles["message"]}>
          <FormattedMessage id={messageId ? messageId : "common.loading"} />
        </div>
      </div>
    </Modal>
  );
}

export const LoadingModal = React.memo(_LoadingModal);
export default LoadingModal;
