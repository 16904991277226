import { DocumentType } from "./form";
import {
  MerchantExtractionFallbackStrategry,
  MerchantSettingResp,
} from "./merchantSetting";

export type FormSettings = {
  name: string;
  numFormFeature: number;
  numQueryFeature: number;
  documentType: DocumentType;
  autoExtractionItems: AutoExtractionItem[];
  postProcessScript?: string;
  dateFormat?: string;
  merchantNames?: string[];
};

export type FormConfig = {
  detector: DetectorConfig;
  index_param: IndexParam;
  search_param: SearchParam;
  document_type: DocumentType;
  auto_extraction_items: AutoExtractionItem[];
  post_process_script?: string;
  custom_merchants?: MerchantSettingResp[];
  custom_merchants_fallback?: MerchantExtractionFallbackStrategry;
  date_format?: string;
  merchant_names?: string[];
};

export type ORBConfig = {
  type: "orb";
  number_of_source_feature: number;
  number_of_query_feature: number;
};

export type DetectorConfig = ORBConfig;

export type IndexParam = {
  algorithm: number;
  table_number: number;
  key_size: number;
  multi_probe_level: number;
};

export type SearchParam = {
  checks: number;
};

export enum Language {
  English = "eng",
  TraditionalChinese = "chi-tra",
}

export type AutoExtractionItem =
  | "total_amount"
  | "date"
  | "short_date"
  | "time"
  | "name"
  | "address"
  | "invoice_number"
  | "telephone"
  | "merchant_fax"
  | "merchant_website"
  | "merchant_name"
  | "store_number"
  | "subtotal"
  | "rounding"
  | "total_discount"
  | "enter_time"
  | "leave_time"
  | "num_person"
  | "credit_card_type"
  | "credit_card_no"
  | "product_info"
  | "payment_info"
  | "company"
  | "job_title"
  | "merchant"
  | "detect_manipulation"
  | "issue_date"
  | "table_extraction"
  | "ner_name"
  | "ner_address"
  | "invoice_date"
  | "due_date"
  | "bank_account_no"
  | "bill_to_phone"
  | "reference_no"
  | "purchase_order_no"
  | "address_code"
  | "address_full"
  | "code_document"
  | "code_identity"
  | "date_birth"
  | "date_expiry"
  | "date_issue"
  | "gender"
  | "issuing_authority"
  | "name_full"
  | "name_full_non_latin"
  | "nationality"
  | "place_of_birth"
  | "detect_signature"
  | "barcode"
  | "detect_portrait";
