import { Icon, Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { useTeamPermission } from "../../hooks/permission";
import theme from "../../theme";
import { BriefForm } from "../../types/form";
import { Img } from "../Img";
import styles from "./styles.module.scss";

interface Props {
  form: BriefForm;
  key: number;

  onDeleteForm(formId: string): void;
}

function _FormGridItem(props: Props) {
  const { onDeleteForm } = props;
  const { hasPermissionToRemoveResource } = useTeamPermission();

  const onDeleteClick = React.useCallback(
    (formId: string) => {
      onDeleteForm(formId);
    },
    [onDeleteForm]
  );

  const { form } = props;

  const link = `/form/${form.id}/edit`;

  return (
    <div
      className={classnames(styles["form-grid-item"], {
        [styles["compact"]]: !hasPermissionToRemoveResource,
      })}
      title={form.name}
    >
      <Link to={link}>
        {form.image ? (
          <Img frameClass={styles["form-image"]} src={form.image} />
        ) : (
          <div
            className={classnames(
              styles["form-image"],
              styles["image-placeholder"]
            )}
          />
        )}
      </Link>
      <div className={styles["bottom-container"]}>
        <Label
          className={styles["form-type"]}
          styles={{ root: { color: theme.palette.themePrimary } }}
        >
          <FormattedMessage id={`document_type.${form.documentType}`} />
        </Label>
        <Label className={styles["form-name"]}>{form.name}</Label>

        {hasPermissionToRemoveResource && (
          <>
            <div className={styles["separator"]} />
            <div className={styles["delete-row"]}>
              <Icon iconName="Delete" onClick={() => onDeleteClick(form.id)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export const FormGridItem = React.memo(_FormGridItem);
export default FormGridItem;
