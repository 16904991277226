@import "../../styles/variables.scss";

$nav-bar-height: 96px;
$pivot-max-width: 200px;
.container {
  padding-top: 4px;
  padding-left: 12px;
  height: $nav-bar-height;
  background-color: $bgcolor;

  .breadcrumb {
    margin-top: 0;
  }

  .tab-bar {
    position: relative;

    :global(.ms-Pivot) {
      position: absolute;
      max-width: $pivot-max-width;
    }
    :global(.ms-CommandBar) {
      float: right;
      margin-left: $pivot-max-width;
    }
  }
}

.content {
  height: calc(100% - #{$nav-bar-height});
}
