import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  Label,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useMemo, useRef, useState } from "react";

import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { useMasterImageSizeValidator } from "../../hooks/validators";
import ErrorText from "../WrappedMSComponents/ErrorText";
import styles from "./styles.module.scss";

interface Props {
  onCancel(): void;
  onCreate(formName: string, image?: File): void;
  uploadImageSupported: boolean;
  isOpen: boolean;
}

const Content = (props: Props) => {
  const { onCreate, onCancel, isOpen, uploadImageSupported } = props;

  const { localized } = useLocale();

  const [formName, setFormName] = useState<string>("");
  const [isFormNameFieldValid, setIsFormNameFieldValid] =
    useState<boolean>(true);

  const [isImageMissing, setIsImageMissing] = useState<boolean>(false);

  const [image, setImage] = useState<File | undefined>();

  const validateMasterImageSize = useMasterImageSizeValidator();

  const onFormNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setFormName(value);
        setIsFormNameFieldValid(true);
      }
    },
    []
  );

  const onImageChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      if (e.target instanceof HTMLInputElement && e.target.files) {
        setImage(e.target.files[0]);
        setIsImageMissing(false);
      }
    },
    [setImage, setIsImageMissing]
  );

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (!isOpen) return;
      event.preventDefault();
      event.stopPropagation();

      const _formName = formName.trim();
      if (!_formName) {
        setIsFormNameFieldValid(false);
      }

      if (!_formName) {
        return;
      }

      if (uploadImageSupported) {
        if (image === undefined) {
          setIsImageMissing(true);
          return;
        }

        if (!validateMasterImageSize(image)) {
          onCancel();
          return;
        }
      }

      onCreate(_formName, image);
    },
    [
      isOpen,
      formName,
      image,
      onCreate,
      uploadImageSupported,
      onCancel,
      validateMasterImageSize,
    ]
  );

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const onChooseImageButtonClick = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <TextField
        label={localized("create_form.form_name")}
        onChange={onFormNameChange}
        value={formName}
        errorMessage={
          isFormNameFieldValid
            ? undefined
            : localized("error.create_form.empty_name")
        }
      />
      {uploadImageSupported && (
        <React.Fragment>
          <div className={styles["upload-image-field"]}>
            <Label>
              <FormattedMessage id="create_form.template_image" />
            </Label>

            <div className={styles["file-section"]}>
              <div className={styles["file-name-container"]}>
                <Text>{image && image.name}</Text>
                {isImageMissing && (
                  <ErrorText>
                    {localized("error.create_form.no_image")}
                  </ErrorText>
                )}
              </div>
              <DefaultButton
                text={localized("common.choose_a_file")}
                onClick={onChooseImageButtonClick}
              />
            </div>
          </div>
          <input
            ref={uploadInputRef}
            className={styles["invisible-image-upload-input"]}
            type="file"
            accept={SUPPORTED_IMAGE_MIME.join(",")}
            onChange={onImageChange}
          />
        </React.Fragment>
      )}
      <DialogFooter>
        <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
        <PrimaryButton type="submit" text={localized("create_form.create")} />
      </DialogFooter>
    </form>
  );
};

const CreateFormModal = (props: Props) => {
  const { onCreate, onCancel, isOpen, uploadImageSupported } = props;

  const { localized } = useLocale();

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("create_form.title"),
    }),
    [localized]
  );

  return (
    <Dialog
      minWidth={400}
      hidden={!isOpen}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
    >
      <Content
        onCreate={onCreate}
        onCancel={onCancel}
        uploadImageSupported={uploadImageSupported}
        isOpen={true}
      />
    </Dialog>
  );
};

export default CreateFormModal;
