@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.team-list {
  overflow-y: auto;
  background-color: #fff;
  padding: 32px;

  .title {
    margin-bottom: 8px;
  }

  .empty-state-container {
    display: flex;
    width: 100%;

    .empty-state {
      @include no-select();

      .empty-state-text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        label {
          margin-top: 0;
          font-weight: 500;
        }
      }
    }
  }

  .search-filter {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .input-field {
    width: 300px;
  }

  .plan-filter-text,
  .plan-filter,
  .search-by-text,
  .search-by-dropdown {
    padding-top: 10px;
  }

  .search-button {
    margin-left: 16px;
    margin-bottom: -10px;
  }

  .plan-filter-text {
    margin-left: 16px;
    margin-right: 8px;
  }

  .search-by-text {
    margin-right: 8px;
  }

  .plan-filter,
  .search-by-dropdown {
    width: 120px;
  }

  .search-by-dropdown {
    margin-right: 16px;
  }

  .tab-bar {
    margin-left: -10px;
    margin-bottom: 24px;
  }
}
