import { produce } from "immer";

import {
  AdminGettingTeamList,
  AdminGotTeamCustomModels,
  AdminGotTeamDetail,
  AdminGotTeamFormGroups,
  AdminGotTeamForms,
  AdminGotTeamList,
  AdminGotTeamMembers,
  AdminGotTeamUsage,
} from "../actions/admin";
import { ListTeamOptions } from "../apiClient/mixin/team";
import { RootAction } from "../redux/types";
import { BriefCustomModel } from "../types/customModel";
import { BriefForm } from "../types/form";
import { BriefFormGroup } from "../types/formGroup";
import { PageInfoWithOffset } from "../types/pageInfo";
import {
  TeamDetail,
  TeamListItem,
  TeamMembersAndInvitations,
} from "../types/team";
import { Usage } from "../types/usage";

export interface TeamListState {
  readonly teams: TeamListItem[];
  readonly listTeamOptions?: ListTeamOptions;
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
}

export interface AdminTeamDetailMemberState {
  readonly membersAndInvitations?: TeamMembersAndInvitations;
  readonly isFetching: boolean;
}

export interface AdminTeamDetailUsageState {
  readonly usages?: Usage[];
  readonly isFetching: boolean;
}

export interface AdminTeamDetailResourceFormsState {
  readonly forms?: BriefForm[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
}

export interface AdminTeamDetailResourceFormGroupsState {
  readonly formGroups?: BriefFormGroup[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
}

export interface AdminTeamDetailResourceCustomModelsState {
  readonly customModels?: BriefCustomModel[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
}

export interface AdminTeamDetailResourceState {
  readonly formsState: AdminTeamDetailResourceFormsState;
  readonly formGroupsState: AdminTeamDetailResourceFormGroupsState;
  readonly customModelsState: AdminTeamDetailResourceCustomModelsState;
}

export interface AdminTeamDetailState {
  readonly team?: TeamDetail;
  readonly isFetching: boolean;
  readonly membersState: AdminTeamDetailMemberState;
  readonly usageState: AdminTeamDetailUsageState;
  readonly resourceState: AdminTeamDetailResourceState;
}

export interface AdminState {
  readonly teamsStateByRegion: {
    [key: string]: TeamListState;
  };
  readonly teamDetail: AdminTeamDetailState;
}

const defaultState: AdminState = {
  teamsStateByRegion: {},
  teamDetail: {
    isFetching: false,
    membersState: {
      isFetching: false,
    },
    usageState: {
      isFetching: false,
    },
    resourceState: {
      formsState: {
        isFetching: false,
      },
      formGroupsState: {
        isFetching: false,
      },
      customModelsState: {
        isFetching: false,
      },
    },
  },
};

function handleGotTeamList(
  state: AdminState,
  action: AdminGotTeamList
): AdminState {
  return produce(state, draft => {
    const { region, teams, pageInfo } = action.payload;

    draft.teamsStateByRegion[region].teams = teams;
    draft.teamsStateByRegion[region].pageInfo = pageInfo;
    draft.teamsStateByRegion[region].isFetching = false;
  });
}

function handleGettingTeamList(
  state: AdminState,
  action: AdminGettingTeamList
): AdminState {
  return produce(state, draft => {
    const { region } = action.payload;

    if (draft.teamsStateByRegion[region]) {
      draft.teamsStateByRegion[region].isFetching = true;
    } else {
      draft.teamsStateByRegion[region] = {
        teams: [],
        isFetching: true,
      };
    }
  });
}

function handleGotTeamDetail(
  state: AdminState,
  action: AdminGotTeamDetail
): AdminState {
  return produce(state, draft => {
    const { team } = action.payload;

    draft.teamDetail.team = team;
    draft.teamDetail.isFetching = false;
  });
}

function handleGotTeamMembers(
  state: AdminState,
  action: AdminGotTeamMembers
): AdminState {
  return produce(state, draft => {
    const { membersAndInvitations } = action.payload;

    draft.teamDetail.membersState.membersAndInvitations = membersAndInvitations;
    draft.teamDetail.membersState.isFetching = false;
  });
}

function handleGotTeamUsage(
  state: AdminState,
  action: AdminGotTeamUsage
): AdminState {
  return produce(state, draft => {
    const { usages } = action.payload;

    draft.teamDetail.usageState.isFetching = false;
    draft.teamDetail.usageState.usages = usages;
  });
}

function handleGotTeamForms(
  state: AdminState,
  action: AdminGotTeamForms
): AdminState {
  return produce(state, draft => {
    const { forms, pageInfo } = action.payload;

    draft.teamDetail.resourceState.formsState.isFetching = false;
    draft.teamDetail.resourceState.formsState.pageInfo = pageInfo;
    draft.teamDetail.resourceState.formsState.forms = forms;
  });
}

function handleGotTeamFormGroups(
  state: AdminState,
  action: AdminGotTeamFormGroups
): AdminState {
  return produce(state, draft => {
    const { formGroups, pageInfo } = action.payload;

    draft.teamDetail.resourceState.formGroupsState.isFetching = false;
    draft.teamDetail.resourceState.formGroupsState.pageInfo = pageInfo;
    draft.teamDetail.resourceState.formGroupsState.formGroups = formGroups;
  });
}

function handleGotTeamCustomModels(
  state: AdminState,
  action: AdminGotTeamCustomModels
): AdminState {
  return produce(state, draft => {
    const { customModels, pageInfo } = action.payload;

    draft.teamDetail.resourceState.customModelsState.isFetching = false;
    draft.teamDetail.resourceState.customModelsState.pageInfo = pageInfo;
    draft.teamDetail.resourceState.customModelsState.customModels =
      customModels;
  });
}

export function reducer(
  state: AdminState = defaultState,
  action: RootAction
): AdminState {
  switch (action.type) {
    case "AdminGotTeamList":
      return handleGotTeamList(state, action);
    case "AdminGettingTeamList":
      return handleGettingTeamList(state, action);
    case "AdminGotTeamDetail":
      return handleGotTeamDetail(state, action);
    case "AdminGettingTeamDetail":
      return produce(state, draft => {
        draft.teamDetail.isFetching = true;
      });
    case "AdminGettingTeamMembers":
      return produce(state, draft => {
        draft.teamDetail.membersState.isFetching = true;
      });
    case "AdminGotTeamMembers":
      return handleGotTeamMembers(state, action);
    case "AdminGettingTeamUsage":
      return produce(state, draft => {
        draft.teamDetail.usageState.isFetching = true;
      });
    case "AdminGotTeamUsage":
      return handleGotTeamUsage(state, action);
    case "AdminGettingTeamForms":
      return produce(state, draft => {
        draft.teamDetail.resourceState.formsState.isFetching = true;
      });
    case "AdminGotTeamForms":
      return handleGotTeamForms(state, action);
    case "AdminGettingTeamFormGroups":
      return produce(state, draft => {
        draft.teamDetail.resourceState.formGroupsState.isFetching = true;
      });
    case "AdminGotTeamFormGroups":
      return handleGotTeamFormGroups(state, action);
    case "AdminGettingTeamCustomModels":
      return produce(state, draft => {
        draft.teamDetail.resourceState.customModelsState.isFetching = true;
      });
    case "AdminGotTeamCustomModels":
      return handleGotTeamCustomModels(state, action);
    default:
      return state;
  }
}
