import { ICommandBarItemProps } from "@fluentui/react";
import copy from "copy-to-clipboard";
import * as React from "react";
import { useSelector } from "react-redux";

import { exportFormGroup } from "../actions/formGroup";
import { chooseFile } from "../components/FileButton";
import MultiDocumentIndicator from "../components/MultiDocumentIndicator";
import { AppConfig } from "../config";
import { SUPPORTED_EXTRACT_MIME } from "../constants";
import { useLocale } from "../contexts/locale";
import { RootState } from "../redux/types";
import { useToast } from "./toast";

export function useFormGroupEditCommandBarItems(formGroupId: string) {
  const { localized } = useLocale();
  const toast = useToast();

  const [isExporting, setIsExporting] = React.useState<boolean>(false);
  const states = useSelector((state: RootState) => state);
  const { resourceOwnerId } = states.resourceOwner;

  const onExport = React.useCallback(() => {
    setIsExporting(true);
    exportFormGroup(formGroupId, resourceOwnerId)
      .then(() => {
        setIsExporting(false);
      })
      .catch(() => {
        toast.error("error.unknown_error");
        setIsExporting(false);
      });
  }, [toast, formGroupId, resourceOwnerId]);

  return React.useMemo(
    (): ICommandBarItemProps[] => [
      {
        key: "export",
        text: localized(
          isExporting ? "common.exporting" : "command_bar_items.export_as_zip"
        ),
        iconProps: { iconName: "Export" },
        onClick: onExport,
        disabled: isExporting,
      },
    ],
    [onExport, localized, isExporting]
  );
}

export function useAPICommandBarItems(entityId?: string, token?: string) {
  const { localized } = useLocale();
  const toast = useToast();

  const onCopyToken = React.useCallback(() => {
    copy(token || "");
    toast.success("ocrapi.token_copied");
  }, [toast, token]);

  const onCopyID = React.useCallback(() => {
    copy(entityId || "");
    toast.success("ocrapi.id_copied");
  }, [toast, entityId]);

  return React.useMemo(
    (): ICommandBarItemProps[] => [
      {
        key: "copy-form-id",
        text: localized("command_bar_items.copy_form_id"),
        iconProps: { iconName: "Tag" },
        onClick: onCopyID,
      },
      {
        key: "copy-access-token",
        text: localized("command_bar_items.copy_access_token"),
        iconProps: { iconName: "Signin" },
        onClick: onCopyToken,
      },
    ],
    [onCopyID, onCopyToken, localized]
  );
}

export function useTestCommandBarItems(
  onSelectImage: (file?: File) => void,
  showMultiDocument: boolean,
  shouldUploadAnotherFile: boolean,
  onUploadAnotherFile: () => void
) {
  const { localized } = useLocale();
  const onSelectImageRef = React.useRef(onSelectImage);
  onSelectImageRef.current = onSelectImage;

  return React.useMemo(
    (): ICommandBarItemProps[] => [
      ...(showMultiDocument
        ? [
            {
              key: "multi-document",
              commandBarButtonAs: () => {
                return <MultiDocumentIndicator />;
              },
              renderedInOverflow: false,
            },
          ]
        : []),
      {
        key: "choose",
        text: localized(
          shouldUploadAnotherFile
            ? "ocr_test.upload_another_file"
            : "ocr_test.choose"
        ),
        iconProps: { iconName: "FileImage" },
        onClick: () => {
          if (shouldUploadAnotherFile) {
            onUploadAnotherFile();
          } else {
            chooseFile(SUPPORTED_EXTRACT_MIME.join(","))
              .then(files => {
                if (!files || files.length < 1) {
                  return;
                }
                onSelectImageRef.current(files[0]);
              })
              .catch(e => {
                console.error("FormGroupTest choose file error: ", e);
              });
          }
        },
      },
    ],
    [localized, showMultiDocument, shouldUploadAnotherFile, onUploadAnotherFile]
  );
}

export function useBatchCommandBarItems(
  entityId?: string,
  entityName?: string,
  token?: string
) {
  const { localized } = useLocale();
  const toast = useToast();

  const onCopyToken = React.useCallback(() => {
    copy(token || "");
    toast.success("ocrapi.token_copied");
  }, [toast, token]);

  const onCopyID = React.useCallback(() => {
    copy(entityId || "");
    toast.success("ocrapi.id_copied");
  }, [toast, entityId]);

  const onOpenInDesktopApp = React.useCallback(() => {
    if (entityId !== undefined && entityName !== undefined) {
      window.location.href = encodeURI(
        `form-extractor://open?form_id=${entityId}&access_token=${token}&form_name=${entityName}&region=${AppConfig.region}`
      );
    }
  }, [entityId, entityName, token]);

  return React.useMemo(
    (): ICommandBarItemProps[] => [
      {
        key: "copy-form-id",
        text: localized("command_bar_items.copy_form_id"),
        iconProps: { iconName: "Tag" },
        onClick: onCopyID,
      },
      {
        key: "copy-access-token",
        text: localized("command_bar_items.copy_access_token"),
        iconProps: { iconName: "Signin" },
        onClick: onCopyToken,
      },
      {
        key: "open-desktop-app",
        text: localized("command_bar_items.open_in_desktop_app"),
        iconProps: { iconName: "OpenInNewTab" },
        onClick: onOpenInDesktopApp,
      },
    ],
    [localized, onCopyID, onCopyToken, onOpenInDesktopApp]
  );
}
