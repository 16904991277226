import { Dialog, DialogFooter } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import {
  DefaultButton,
  PrimaryButton,
} from "../../components/WrappedMSComponents/Buttons";
import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface ConfirmModalProps {
  file: File | undefined;
  onCancel(): void;
  onConfirm(): void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = props => {
  const { localized } = useLocale();
  const { onCancel, onConfirm, file } = props;

  return (
    <Dialog
      title={localized("setting.tab.confirm_replace_dialog.title")}
      className={styles["confirm_replace_dialog"]}
      hidden={!file}
      onDismiss={onCancel}
    >
      <div className={styles["title"]}>
        <FormattedMessage id="setting.tab.file" />
      </div>
      <p className={styles["filename"]}>{file?.name}</p>
      <p className={styles["message"]}>
        <FormattedMessage id="setting.tab.confirm_replace_current_form" />
      </p>
      <DialogFooter>
        <DefaultButton onClick={onCancel} textId={"common.cancel"} />
        <PrimaryButton onClick={onConfirm} textId={"setting.tab.import"} />
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmModal;
