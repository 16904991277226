import { User, userSchema } from "../../types/user";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface UserApiClient {
  getUser: () => Promise<User>;
  createUser: (region: string) => Promise<User>;
}

export function withUserApi<TBase extends ApiClientConstructor<_BaseApiClient>>(
  Base: TBase
) {
  return class extends Base {
    async getUser(): Promise<User> {
      return {
        ...(await this.lambda("user:get", {}, userSchema)),
        resourceOwner: null,
      };
    }

    async createUser(region: string): Promise<User> {
      return this.lambda("user:create", {}, userSchema, undefined, { region });
    }
  };
}
