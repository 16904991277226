import { apiClient } from "../apiClient";
import { SUPPORTED_IMAGE_MIME } from "../constants";

export function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        resolve("");
      }
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsText(file);
  });
}

export const uploadAsset = apiClient.createAsset.bind(apiClient);

export async function triggerFileSave(response: Response) {
  const blob = await response.blob();

  let contentDispositionHeader = response.headers.get("content-disposition");
  if (contentDispositionHeader === null) {
    throw "response does not contain 'content-disposition' header";
  }
  contentDispositionHeader = decodeURIComponent(contentDispositionHeader);
  const filenameRegexMatch = contentDispositionHeader.match(/"([^"]+)"/);
  const filename = filenameRegexMatch ? filenameRegexMatch[1] : "unknown";
  const a = document.createElement("a");
  const objUrl = window.URL.createObjectURL(blob);
  a.href = objUrl;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(objUrl);
}

export function disableDefaultFormSubmit(e: React.FormEvent<HTMLFormElement>) {
  e.preventDefault();
  e.stopPropagation();
}

export const createOnDrop =
  (onFiles: (files?: File[]) => any, supportedMIME = SUPPORTED_IMAGE_MIME) =>
  (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    const fileArray: File[] = [];
    if (e.dataTransfer.items) {
      for (const i of [...Array(e.dataTransfer.items.length).keys()]) {
        const item = e.dataTransfer.items[i];
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (!file || supportedMIME.indexOf(file.type) === -1) continue;
          fileArray.push(file);
        }
      }
      onFiles(fileArray);
      e.dataTransfer.items.clear();
    } else {
      for (const i of [...Array(e.dataTransfer.files.length).keys()]) {
        const file = e.dataTransfer.files[i];
        if (supportedMIME.indexOf(file.type) === -1) continue;
        fileArray.push(file);
      }
      onFiles(fileArray);
      e.dataTransfer.clearData();
    }
  };

export const onDragOver = (e: React.DragEvent): void => {
  e.preventDefault();
  e.stopPropagation();
};
