import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React from "react";
import { useHistory } from "react-router";

import { AppConfig } from "../../config";
import { LeftBarSelectedPage } from "../../containers/LeftBar";
import { Template } from "../../types/template";
import { LinkWithTeamRef } from "../LinkWithTeamRef";
import Link from "../WrappedMSComponents/Link";
import styles from "./styles.module.scss";

interface Props {
  isLeftBarCollapsed: boolean;
  areTemplatesLoaded: boolean;
  selectedPage?: LeftBarSelectedPage;
  onClickTemplate: (template: Template) => void;
  toggleLeftBarCollapsed: (value: boolean | undefined) => void;
  templates: Template[] | undefined;
}

interface AdminProps {
  isLeftBarCollapsed: boolean;
  selectedPage?: LeftBarSelectedPage;
  toggleLeftBarCollapsed: (value: boolean | undefined) => void;
}

function TemplateRow(props: {
  template: Template;
  selectedPage: string | undefined;
  onClick: (template: Template) => void;
}) {
  const { onClick, template, selectedPage } = props;

  const handleClick = React.useCallback(() => {
    onClick(template);
  }, [onClick, template]);

  return (
    <div
      onClick={handleClick}
      className={classnames({
        [styles["selected"]]: selectedPage === template.name,
      })}
    >
      <FormattedMessage id={`form_template.${template.name}`} />
    </div>
  );
}

const LeftBar = React.memo((props: Props) => {
  const {
    isLeftBarCollapsed,
    areTemplatesLoaded,
    onClickTemplate,
    selectedPage,
    toggleLeftBarCollapsed,
    templates,
  } = props;

  return (
    <div
      className={classnames(styles["container"], {
        [styles["container-collapsed"]]: isLeftBarCollapsed,
      })}
    >
      <div className={styles["content"]}>
        {!isLeftBarCollapsed && (
          <>
            <div className={styles["list"]}>
              <div className={styles["items"]}>
                <div className={styles["section-header"]}>
                  <p>
                    <FormattedMessage id="left_bar.forms" />
                  </p>
                </div>

                <LinkWithTeamRef to="/form">
                  <div
                    className={classnames({
                      [styles["selected"]]: selectedPage === "forms",
                    })}
                  >
                    <FormattedMessage id="left_bar.form_list" />
                  </div>
                </LinkWithTeamRef>

                <LinkWithTeamRef to="/form-group">
                  <div
                    className={classnames({
                      [styles["selected"]]: selectedPage === "form-groups",
                    })}
                  >
                    <FormattedMessage id="left_bar.form_group_list" />
                  </div>
                </LinkWithTeamRef>

                {AppConfig.leftBar.hideCustomModel !== true && (
                  <>
                    <div className={styles["section-header"]}>
                      <p>
                        <FormattedMessage id="left_bar.custom_model" />
                      </p>
                    </div>
                    <LinkWithTeamRef to="/custom-model">
                      <div
                        className={classnames({
                          [styles["selected"]]: selectedPage === "custom-model",
                        })}
                      >
                        <FormattedMessage id="left_bar.custom_model_list" />
                      </div>
                    </LinkWithTeamRef>
                  </>
                )}

                {areTemplatesLoaded && AppConfig.leftBar.hideTemplate !== true && (
                  <>
                    <div className={styles["section-header"]}>
                      <p>
                        <FormattedMessage id="left_bar.templates" />
                      </p>
                    </div>

                    <div>
                      <LinkWithTeamRef to="/receipt-test">
                        <div
                          className={classnames({
                            [styles["selected"]]: selectedPage === "receipts",
                          })}
                        >
                          <FormattedMessage id="header.receipts" />
                        </div>
                      </LinkWithTeamRef>
                      {templates &&
                        templates.map(template => (
                          <TemplateRow
                            key={template.id}
                            selectedPage={selectedPage}
                            template={template}
                            onClick={onClickTemplate}
                          />
                        ))}
                    </div>
                  </>
                )}

                {AppConfig.leftBar.hideIntegration !== true && (
                  <>
                    <div className={styles["section-header"]}>
                      <p>
                        <FormattedMessage id="left_bar.integrations" />
                      </p>
                    </div>

                    <LinkWithTeamRef to="/detect-documents/test">
                      <div
                        className={classnames({
                          [styles["selected"]]:
                            selectedPage === "detect-documents",
                        })}
                      >
                        <FormattedMessage id="left_bar.detect_documents" />
                      </div>
                    </LinkWithTeamRef>

                    <LinkWithTeamRef to="/webhook">
                      <div
                        className={classnames({
                          [styles["selected"]]: selectedPage === "webhook",
                        })}
                      >
                        <FormattedMessage id="left_bar.webhooks" />
                      </div>
                    </LinkWithTeamRef>
                  </>
                )}
                {!AppConfig.shouldTawkDisabled && (
                  <div className={styles["tawk-padding"]} />
                )}
              </div>
            </div>
            {AppConfig.shouldTawkDisabled && (
              <div className={styles["powered-by-formx"]}>
                <FormattedMessage id="left_bar.powered_by_formx" />
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={classnames(styles["collapse-button-bar"], {
          [styles["button-collapsed"]]: isLeftBarCollapsed,
        })}
        onClick={() => toggleLeftBarCollapsed(undefined)}
      />
    </div>
  );
});

export const AdminLeftBar = React.memo((props: AdminProps) => {
  const { isLeftBarCollapsed, selectedPage, toggleLeftBarCollapsed } = props;
  const history = useHistory();

  return (
    <div
      className={classnames(styles["container"], {
        [styles["container-collapsed"]]: isLeftBarCollapsed,
      })}
    >
      <div className={styles["content"]}>
        {!isLeftBarCollapsed && (
          <>
            <div className={styles["list"]}>
              <div className={styles["items"]}>
                <Link to={`/admin/team${history.location.search}`}>
                  <div
                    className={classnames({
                      [styles["selected"]]: selectedPage === "team-list",
                    })}
                  >
                    <FormattedMessage id="left_bar.teams" />
                  </div>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={classnames(styles["collapse-button-bar"], {
          [styles["button-collapsed"]]: isLeftBarCollapsed,
        })}
        onClick={() => toggleLeftBarCollapsed(undefined)}
      />
    </div>
  );
});

export default LeftBar;
