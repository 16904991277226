@import "../../styles/variables.scss";

.ocr-test-report-table {
  margin: 20px;
  border-collapse: collapse;
  table-layout: fixed;
  width: calc(100% - 40px);

  .table-row {
    vertical-align: top;

    td {
      padding: 12px 12px 12px 0;

      &.type-column {
        min-width: 128px;
        width: 128px;
      }

      &.name-column {
        min-width: 176px;
        width: 176px;
      }

      &.confidence-column {
        min-width: 90px;
        width: 90px;
      }
    }

    &.header-row {
      border-top: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;

      .header-text {
        font-weight: 600;
        font-size: 14px;
        color: $dark-color;
      }
    }

    &.body-row {
      border-bottom: 1px solid #e9e9e9;

      .body-cell {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-wrap;

        &.image-cell {
          padding: 10px 12px;
          cursor: pointer;
          img {
            max-width: 500px;
          }
        }
      }

      .body-text {
        font-size: 14px;
        color: $dark-color;

        pre {
          font-family: monospace;
          padding: 10px;
          background-color: #ddd;
          overflow-x: scroll;
        }

        span.title {
          margin-right: 8px;
          font-style: italic;
        }
      }
    }

    .value-list {
      padding-left: 25px;
    }

    .auto-extraction-table {
      font-size: 14px;
      border-collapse: collapse;
      width: auto;
      margin: 12px 12px 12px;
      white-space: pre-wrap;
      td {
        border: 1px solid #000000;
        padding: 2px 6px;
      }
    }
  }
}
