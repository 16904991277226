import { PrimaryButton } from "@fluentui/react";
import React from "react";

import { useKeyValueTabPane } from "../../contexts/keyValueTab";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { ModalState } from "../../models";
import { KeyValue } from "../../types/keyValue";
import { KeyValuePaneNote } from "../InspectorNote";
import { KeyValueCard } from "../KeyValueCard";
import { KeyValueModal } from "../KeyValueModal";
import styles from "./styles.module.scss";

interface Props {
  keyValues: KeyValue[];
}

const KeyValueTabPane = React.memo(({ keyValues }: Props) => {
  const {
    modalMode,
    isKeyValueModalOpened,
    edittingKeyValue,
    closeKeyValueModal,
    onCreateKeyValue,
    onEditKeyValue,
    onKeyValueSubmit,
    onDeleteKeyValue,
    form,
  } = useKeyValueTabPane();

  const { hasPermissionToEditResource } = useTeamPermission();

  const { localized } = useLocale();

  return (
    <div className={styles["key-value-pane"]}>
      <KeyValuePaneNote />
      {hasPermissionToEditResource && (
        <PrimaryButton
          className={styles["button"]}
          text={localized("add.key_value.button")}
          onClick={onCreateKeyValue}
        />
      )}

      {keyValues.map((keyValue, i) => (
        <KeyValueCard
          key={i}
          keyValue={keyValue}
          onDelete={onDeleteKeyValue}
          onEdit={onEditKeyValue}
        />
      ))}

      <KeyValueModal
        mode={modalMode}
        form={form}
        renameEnabled={true}
        isOpen={isKeyValueModalOpened !== ModalState.Closed}
        keyValue={edittingKeyValue}
        onCancel={closeKeyValueModal}
        onSubmit={onKeyValueSubmit}
      />
    </div>
  );
});

export default KeyValueTabPane;
