import {
  CommandBar,
  IBreadcrumbItem,
  ICommandBarItemProps,
  IconButton,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import classnames from "classnames";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { CustomModel } from "../../types/customModel";
import { Breadcrumb } from "../WrappedMSComponents/Breadcrumb";
import styles from "./styles.module.scss";

interface Props {
  customModel: CustomModel;
  openRenameDialog?: () => void;
  commbarBarItems?: ICommandBarItemProps[];
  shouldHideTabBar?: boolean;
}

function _CustomModelNavBar(props: Props) {
  const { customModel, commbarBarItems, openRenameDialog, shouldHideTabBar } =
    props;
  const history = useHistory();
  const { localized } = useLocale();
  const location = useLocation();

  const onBreadcrumbItemClick = useCallback(
    (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      if (ev === undefined || item === undefined || item.href === undefined) {
        return;
      }
      ev.preventDefault();
      history.push(item.href);
    },
    [history]
  );

  const breadcrumbItems: IBreadcrumbItem[] = useMemo(
    () => [
      {
        text: localized("header.custom_models"),
        key: "custom-models",
        href: "/custom-model",
        onClick: onBreadcrumbItemClick,
      },
      { text: customModel.name, key: customModel.name },
    ],
    [customModel, localized, onBreadcrumbItemClick]
  );

  const onPivotItemClick = useCallback(
    (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
      if (!ev || !item || !item.props.itemKey) {
        return;
      }
      ev.preventDefault();
      ev.stopPropagation();
      history.push(`/custom-model/${customModel.id}/${item.props.itemKey}`);
    },
    [customModel, history]
  );

  const selectedKey = useMemo(
    () => location.pathname.split("/")[3],
    [location.pathname]
  );

  const isReadyToLabel = customModel.config.frozenFields.length > 0;
  const hasModelVerison = customModel.modelVersions.length > 0;
  const hasDeployedModel =
    customModel.modelVersions.findIndex(x => x.isActive) >= 0 &&
    customModel.formID;

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div
      className={classnames(
        styles["container"],
        styles[
          shouldHideTabBar
            ? "container-without-tabbar"
            : "container-with-tabbar"
        ]
      )}
    >
      <div className={styles["breadcrumb-container"]}>
        <Breadcrumb className={styles["breadcrumb"]} items={breadcrumbItems} />
        {openRenameDialog && hasPermissionToEditResource && (
          <IconButton
            className={styles["rename-buttom"]}
            iconProps={{ iconName: "Edit" }}
            onClick={openRenameDialog}
          />
        )}
      </div>
      {!shouldHideTabBar && (
        <div className={styles["tab-bar"]}>
          <Pivot onLinkClick={onPivotItemClick} selectedKey={selectedKey}>
            <PivotItem
              headerText={localized("header.setup")}
              itemKey="setup"
              itemIcon="BulkUpload"
            />
            {isReadyToLabel && (
              <PivotItem
                headerText={localized("header.label")}
                itemKey="label"
                itemIcon="tag"
              />
            )}
            {hasModelVerison && (
              <PivotItem
                headerText={localized("header.model")}
                itemKey="model"
                itemIcon="BuildQueue"
              />
            )}
            {hasDeployedModel && (
              <PivotItem
                headerText={localized("header.api")}
                itemKey="api"
                itemIcon="PlugConnected"
              />
            )}

            {hasDeployedModel && (
              <PivotItem
                headerText={localized("header.test")}
                itemKey="test"
                itemIcon="TestBeaker"
              />
            )}
            {hasDeployedModel && (
              <PivotItem
                headerText={localized("header.batch")}
                itemKey="batch"
                itemIcon="Copy"
              />
            )}
          </Pivot>
          {commbarBarItems && <CommandBar items={commbarBarItems} />}
        </div>
      )}
    </div>
  );
}

interface CustomModelNavBarLayoutProps extends Props {
  children: React.ReactNode;
}

function _CustomModelNavBarLayout(props: CustomModelNavBarLayoutProps) {
  const { children, ...rest } = props;

  return (
    <>
      <CustomModelNavBar {...rest} />
      <div
        className={
          styles[rest.shouldHideTabBar ? "content-without-tabbar" : "content"]
        }
      >
        {children}
      </div>
    </>
  );
}

export const CustomModelNavBar = React.memo(_CustomModelNavBar);
export default CustomModelNavBar;

export const CustomModelNavBarLayout = React.memo(_CustomModelNavBarLayout);
