import React, { useCallback, useState } from "react";

import { useTeamActionCreator } from "../actions/team";
import { Layout, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { OnboardingImpl } from "../components/Onboarding";
import { AppConfig } from "../config";
import errors, { FOCRError } from "../errors";
import { useToast } from "../hooks/toast";
import { redirectToRegionForTeam } from "../utils/region";
import HeaderContainer from "./Header";

export default function OnboardingContainer() {
  const { createTeam } = useTeamActionCreator();
  const [isCreatingTeam, setIsCreatingTeam] = useState(false);
  const toast = useToast();

  const onConfirm = useCallback(
    async (name: string, region: string, preferredLookupId: string) => {
      setIsCreatingTeam(true);

      const { region: currentRegion } = AppConfig;

      try {
        const { lookupId } = await createTeam(name, region, preferredLookupId);

        if (currentRegion !== region) {
          redirectToRegionForTeam(region, lookupId);
        }
      } catch (e) {
        if (e instanceof FOCRError && e !== errors.UnknownError) {
          toast.error(e.messageId);
        } else {
          toast.error("error.create_team.failed");
        }
      } finally {
        setIsCreatingTeam(false);
      }
    },
    [createTeam, setIsCreatingTeam, toast]
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <OnboardingImpl onCreateTeam={onConfirm}></OnboardingImpl>
      <LoadingModal isOpen={isCreatingTeam} messageId={"team.creating_team"} />
    </Layout>
  );
}
