import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { Permission, getTeamRoleMessageId } from "../../types/team";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface InvitationInvalidProps {
  returnHome: () => void;
}

export function InvitationInvalid(props: InvitationInvalidProps) {
  const { returnHome } = props;

  return (
    <div className={styles["invitation-container"]}>
      <h3>
        <FormattedMessage id={"team.invitation.invalid"} />
      </h3>

      <div className={styles["message"]}>
        <FormattedMessage id={"team.invitation.invalid.message"} />
      </div>

      <div className={styles["action"]}>
        <PrimaryButton
          textId="team.invitation.continue_to_formx"
          onClick={returnHome}
        />
      </div>
    </div>
  );
}

interface InvitationLoggedOutProps {
  teamName?: string;
  signup: () => void;
}

export function InvitationLoggedOut(props: InvitationLoggedOutProps) {
  const { teamName, signup } = props;

  return (
    <div className={styles["invitation-container"]}>
      {teamName && (
        <h3>
          <FormattedMessage
            id={"team.invitation.logged_out"}
            values={{ team: teamName }}
          />
        </h3>
      )}

      <div className={styles["message"]}>
        <FormattedMessage id={"team.invitation.logged_out.message"} />
      </div>

      <div className={styles["action"]}>
        <PrimaryButton textId="common.continue" onClick={signup} />
      </div>
    </div>
  );
}

interface InvitationAcceptNotMatchProps {
  loginWithAnotherUser: () => void;
}

export function InvitationAccountNotMatch(
  props: InvitationAcceptNotMatchProps
) {
  const { loginWithAnotherUser } = props;

  return (
    <div className={styles["invitation-container"]}>
      <h3>
        <FormattedMessage id={"team.invitation.account_not_match"} />
      </h3>

      <div className={styles["message"]}>
        <FormattedMessage id={"team.invitation.account_not_match.message"} />
      </div>

      <div className={styles["action"]}>
        <PrimaryButton
          textId="team.invitation.account_not_match.login"
          onClick={loginWithAnotherUser}
        />
      </div>
    </div>
  );
}

interface InvitationReceivedProps {
  onAccept: () => void;
  teamName?: string;
  teamPermission?: Permission;
}

export function InvitationReceived(props: InvitationReceivedProps) {
  const { onAccept, teamName, teamPermission } = props;
  const { localized } = useLocale();

  return (
    <div className={styles["invitation-container"]}>
      {teamName && (
        <h3>
          <FormattedMessage
            id={"team.invitation.received"}
            values={{ team: teamName }}
          />
        </h3>
      )}

      <div className={styles["message"]}>
        {teamName && teamPermission && (
          <FormattedMessage
            id={"team.invitation.received.message"}
            values={{
              team: teamName,
              role: localized(getTeamRoleMessageId(teamPermission, true)),
            }}
          />
        )}
      </div>

      <div className={styles["action"]}>
        <PrimaryButton
          textId="team.invitation.accept_invitation"
          onClick={onAccept}
        />
      </div>
    </div>
  );
}
