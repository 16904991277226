import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface ContentProps {
  onCancel(): void;
  onSubmit(name: string): void;
}

const Content = (props: ContentProps) => {
  const { onCancel, onSubmit } = props;

  const { localized } = useLocale();

  const [remark, setRemark] = useState("");

  const onRemarkChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value) {
        setRemark(value as string);
      } else {
        setRemark("");
      }
    },
    []
  );

  const _onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onSubmit(remark);
    },
    [onSubmit, remark]
  );

  return (
    <form onSubmit={_onSubmit}>
      <div className={styles["desc"]}>
        <FormattedMessage id="custom_model_editor.remark_dialog.desc" />
      </div>

      <TextField
        placeholder={localized("custom_model_editor.remark_dialog.placeholder")}
        multiline
        rows={5}
        onChange={onRemarkChange}
      />

      <DialogFooter>
        <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
        <PrimaryButton type="submit" text={localized("common.done")} />
      </DialogFooter>
    </form>
  );
};

interface Props extends ContentProps {
  isOpen: boolean;
}

const CustomModelRemarkModal = (props: Props) => {
  const { onCancel, onSubmit, isOpen } = props;

  const { localized } = useLocale();

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("custom_model_editor.remark_dialog.title"),
      titleProps: {
        className: styles["title"],
      },
    }),
    [localized]
  );

  return (
    <Dialog
      minWidth={405}
      hidden={!isOpen}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
    >
      <Content onCancel={onCancel} onSubmit={onSubmit} />
    </Dialog>
  );
};

export default CustomModelRemarkModal;
