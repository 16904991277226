import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IRenderFunction,
  ITextFieldProps,
  Icon,
  PrimaryButton,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useRef } from "react";
import { useCallback, useMemo, useState } from "react";

import { getRegionKeys } from "../../config";
import { useLocale } from "../../contexts/locale";
import { useLocalizeRegion } from "../../hooks/app";
import {
  extractAlphaNumbericAndHyphenChars,
  generateRandomStringWithLowercaseAndNumber,
} from "../../utils/string";
import { validateTeamID, validateTeamName } from "../../utils/validation";
import styles from "./styles.module.scss";

interface Props {
  isRename: boolean;
  isOpen: boolean;
  onCancel(): void;
  onConfirm(name: string, region?: string, lookupId?: string): Promise<void>;
}

interface FormProps {
  isRename: boolean;
  onCancel?(): void;
  onConfirm(name: string, region?: string, lookupId?: string): Promise<void>;
}

export function CreateRenameTeamForm(props: FormProps) {
  const { isRename, onCancel, onConfirm } = props;

  const [name, setName] = useState("");
  const [isNameOnceValid, setIsNameOnceValid] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState<
    string | undefined
  >();
  const [regionKey, setRegionKey] = useState(getRegionKeys()[0]);

  const uuidRef = useRef(generateRandomStringWithLowercaseAndNumber(8));
  const [teamLookupID, setTeamLookupID] = useState<string | undefined>(
    undefined
  );
  const [preferredTeamLookupID, setPreferredTeamLookupID] = useState<
    string | undefined
  >(undefined);
  const [lookupIdErrorMessage, setLookupIdErrorMessage] = useState<
    string | undefined
  >();
  const [isCustomizeTeamLookupId, setIsCustomizeTeamLookupId] = useState(false);

  const nameRef = useRef(name);
  nameRef.current = name;

  const regionKeyRef = useRef(regionKey);
  regionKeyRef.current = regionKey;

  const generatedTeamLookupIDRef = useRef(teamLookupID);
  generatedTeamLookupIDRef.current = teamLookupID;

  const preferredTeamLookupIDRef = useRef(preferredTeamLookupID);
  preferredTeamLookupIDRef.current = preferredTeamLookupID;

  const { localized } = useLocale();
  const localizeRegion = useLocalizeRegion();
  const onRegionChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option?.key) setRegionKey(`${option.key}`);
    },
    []
  );

  const _validateTeamName = useCallback(
    (name: string, alwaysShowError: boolean = false) => {
      const teamNameError = validateTeamName(name);
      if (!teamNameError) {
        setIsNameOnceValid(true);
      }

      if (teamNameError && (alwaysShowError || isNameOnceValid)) {
        setNameErrorMessage(teamNameError);
      } else {
        setNameErrorMessage(undefined);
      }
      return teamNameError;
    },
    [isNameOnceValid]
  );

  const _validateTeamID = useCallback((name: string) => {
    const teamIDError = validateTeamID(name);

    if (teamIDError) {
      setLookupIdErrorMessage(teamIDError);
    } else {
      setLookupIdErrorMessage(undefined);
    }
    return teamIDError;
  }, []);

  const onNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();

      if (value !== undefined) {
        setName(value);
        setNameErrorMessage(undefined);
      } else {
        return;
      }

      _validateTeamName(value);

      if (value.length === 0) {
        setTeamLookupID(undefined);
        return;
      }

      const alphaNumbericCharacters = extractAlphaNumbericAndHyphenChars(value);
      const generatedLookupID = `${alphaNumbericCharacters
        .replaceAll(" ", "-")
        .toLowerCase()}-${uuidRef.current.substring(0, 4)}`;
      if (
        alphaNumbericCharacters.length === 0 ||
        validateTeamID(generatedLookupID)
      ) {
        setTeamLookupID(uuidRef.current);
      } else {
        setTeamLookupID(generatedLookupID);
      }
    },
    [_validateTeamName]
  );

  const onPreferredTeamLookupIdChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();

      if (value !== undefined) {
        setPreferredTeamLookupID(value);
        setLookupIdErrorMessage(undefined);
      } else {
        return;
      }

      const errorMessage = validateTeamID(value);
      if (errorMessage && !errorMessage.endsWith("too_short")) {
        setLookupIdErrorMessage(errorMessage);
      } else {
        setLookupIdErrorMessage(undefined);
      }
    },
    []
  );

  const onCreateTeamSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const isUsingCustomizedLookupId =
        isCustomizeTeamLookupId &&
        preferredTeamLookupIDRef.current &&
        preferredTeamLookupIDRef.current.length !== 0;

      const lookupId = isUsingCustomizedLookupId
        ? preferredTeamLookupIDRef.current
        : generatedTeamLookupIDRef.current;

      const teamIDError = isUsingCustomizedLookupId
        ? _validateTeamID(lookupId ?? "")
        : undefined;

      const teamNameError = _validateTeamName(nameRef.current, true);

      if (!teamNameError && !teamIDError) {
        onConfirm(nameRef.current, regionKeyRef.current, lookupId);
      }
    },
    [_validateTeamID, _validateTeamName, isCustomizeTeamLookupId, onConfirm]
  );

  const onRenameSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const teamNameError = _validateTeamName(nameRef.current, true);

      if (!teamNameError) {
        onConfirm(nameRef.current);
      }
    },
    [_validateTeamName, onConfirm]
  );

  const regionOptions = useMemo(
    () =>
      getRegionKeys().map(e => ({
        key: e,
        text: localizeRegion(e),
      })),
    [localizeRegion]
  );

  const onWrapDefaultLabelRenderer = (
    props?: ITextFieldProps,
    defaultRender?: IRenderFunction<ITextFieldProps>
  ): JSX.Element => {
    return (
      <div className={styles["lookup-id-text-field-label"]}>
        {defaultRender && <>{defaultRender(props)}</>}

        <TooltipHost
          content={localized("team.lookup_id.desc")}
          id="lookup-id-props"
          calloutProps={{}}
          className={styles["info-icon"]}
        >
          <Icon className={styles["info-icon"]} iconName="info" />
        </TooltipHost>
      </div>
    );
  };

  return (
    <form onSubmit={isRename ? onRenameSubmit : onCreateTeamSubmit}>
      <TextField
        label={localized("new_team.team_name")}
        value={name}
        onChange={onNameChange}
        errorMessage={nameErrorMessage && localized(nameErrorMessage)}
      />
      {!isRename && (
        <>
          <div className={styles["lookup-id-section-compact"]}>
            {!isCustomizeTeamLookupId &&
              teamLookupID &&
              !nameErrorMessage &&
              isNameOnceValid && (
                <>
                  <TooltipHost
                    content={localized("team.lookup_id.desc")}
                    id="lookup-id-props"
                    calloutProps={{}}
                  >
                    <Text
                      variant="small"
                      className={styles["generated-lookup-id"]}
                    >
                      <FormattedMessage
                        id={"team.lookup_id.smol"}
                        values={{ id: teamLookupID ?? "team-name" }}
                      />
                    </Text>
                  </TooltipHost>
                  <Text
                    className={styles["customize-button"]}
                    variant="small"
                    onClick={() => {
                      setIsCustomizeTeamLookupId(true);
                    }}
                  >
                    <FormattedMessage id={"common.customize"} />
                  </Text>
                </>
              )}
          </div>

          {isCustomizeTeamLookupId && (
            <div className={styles["lookup-id-section"]}>
              <TextField
                label={localized("team.lookup_id")}
                errorMessage={
                  lookupIdErrorMessage && localized(lookupIdErrorMessage)
                }
                placeholder={teamLookupID}
                onRenderLabel={onWrapDefaultLabelRenderer}
                onChange={onPreferredTeamLookupIdChange}
                value={preferredTeamLookupID}
              />
            </div>
          )}
          <Dropdown
            label={localized("new_team.team_region")}
            options={regionOptions}
            selectedKey={regionKey}
            onChange={onRegionChange}
          />
          <Text variant="small">
            <FormattedMessage id={"new_team.team_region.desc"} />
          </Text>
        </>
      )}
      <DialogFooter>
        {onCancel && (
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
        )}

        <PrimaryButton
          type="submit"
          text={localized(isRename ? "common.rename" : "create_form.create")}
        />
      </DialogFooter>
    </form>
  );
}

function _CreateRenameTeamModal(props: Props) {
  const { isRename, isOpen, onCancel, onConfirm } = props;
  const { localized } = useLocale();

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(isRename ? "team.rename" : "new_team.title"),
    }),
    [isRename, localized]
  );

  return (
    <Dialog
      minWidth={500}
      hidden={!isOpen}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
    >
      <CreateRenameTeamForm
        isRename={isRename}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </Dialog>
  );
}

export const CreateRenameTeamModal = React.memo(_CreateRenameTeamModal);
export default CreateRenameTeamModal;
