@import "../../styles/variables.scss";

$top-height: 48px;
$bottom-height: 64px;
$right-width: 384px;

.bottom-right {
  float: right;
  margin-top: 10px;
  margin-right: 24px;
}

.upload-files-button {
  color: $darker-primary-color;
  border-color: $darker-primary-color;
  margin-left: 8px;
  vertical-align: middle;
}

.upload-files-button:hover {
  color: $darker-primary-color;
  border-color: $darker-primary-color;
}

.arrow-body {
  position: absolute;
  right: 20px;
  bottom: calc(#{$bottom-height} + 5px);

  background-color: $darker-primary-color;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  color: #ffffff;
  padding-right: 12px;
  padding-left: 12px;
}

.arrow-down {
  display: inline-block;
  width: 0px;
  height: 0px;
  position: absolute;
  right: 80px;
  bottom: calc(#{$bottom-height} - 5px);

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid $darker-primary-color;
}

.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  height: $bottom-height;
  z-index: 2;
  width: 180px;
}
