import * as React from "react";
import { useParams } from "react-router";

import { BatchNote } from "../components/BatchNote";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FormNavBarLayout } from "../components/FormNavBar";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { useBatchCommandBarItems } from "../hooks/command_bar_items";
import { useCommonFormContainerState } from "../hooks/form";

type PathParam = {
  formId: string;
};

export const FormBatchContainer = React.memo(() => {
  const { formId } = useParams<PathParam>();

  const containerState = useCommonFormContainerState(formId);

  const commandBarItems = useBatchCommandBarItems(
    ...(containerState.state === "success"
      ? [
          containerState.form.id,
          containerState.form.name,
          containerState.workerToken,
        ]
      : [undefined, undefined, undefined])
  );
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
        ) : containerState.state === "success" ? (
          <FormNavBarLayout
            form={containerState.form}
            commbarBarItems={commandBarItems}
          >
            <BatchNote />
          </FormNavBarLayout>
        ) : null}
      </Main>
    </Layout>
  );
});

export default FormBatchContainer;
