import { MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { MISSING_EXTERNAL_SERVICES_ERROR_MAP } from "../../constants";
import { ExternalServices } from "../../models";
import { RootState } from "../../redux/types";
import styles from "./styles.module.scss";

interface Props {
  requiredExternalServices: ExternalServices[];
}

export const ExternalServicesAlertBox = React.memo((props: Props) => {
  const { requiredExternalServices } = props;

  const isGoogleServiceAccoutKeySet = useSelector(
    (state: RootState) => state.resourceOwner.isGoogleOcrEngineSet
  );
  const isAzureSubscriptionKeySet = useSelector(
    (state: RootState) => state.resourceOwner.isAzureOcrEngineSet
  );

  const isCustomPlan = useSelector((state: RootState) => {
    const { plan } = state.resourceOwner;
    return (plan && plan === "custom") || false;
  });

  const history = useHistory();

  const avaialableExternalServices = [
    [isAzureSubscriptionKeySet || false, "azure"],
    [isGoogleServiceAccoutKeySet || false, "google"],
  ]
    .filter(x => x[0])
    .map(x => x[1]);

  const missingExternalServices = requiredExternalServices.filter(
    x => !avaialableExternalServices.includes(x)
  );

  const onSetupButtonClick = React.useCallback(() => {
    history.push("/setting");
  }, [history]);

  if (!isCustomPlan) {
    return null;
  }

  return (
    <>
      {missingExternalServices.map(service => (
        <MessageBar
          key={service}
          className={styles["message-bar"]}
          messageBarType={MessageBarType.warning}
          actions={
            <MessageBarButton
              className={styles["button"]}
              onClick={onSetupButtonClick}
            >
              <FormattedMessage id="alert.set_up" />
            </MessageBarButton>
          }
        >
          <FormattedMessage id={MISSING_EXTERNAL_SERVICES_ERROR_MAP[service]} />
        </MessageBar>
      ))}
    </>
  );
});

export const ExternalServicesAlertBoxForReceipt = () => (
  <ExternalServicesAlertBox requiredExternalServices={["google"]} />
);

export const ExternalServicesAlertBoxForForm = () => {
  const requiredExternalServices = useSelector((state: RootState) =>
    state.form.getRequiredExternalServices()
  );
  return (
    <ExternalServicesAlertBox
      requiredExternalServices={requiredExternalServices}
    />
  );
};
