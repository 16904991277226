import { IChoiceGroupOption } from "@fluentui/react";

import { ExternalServices } from "../models";
import { DocumentType } from "../types/form";
import { Language } from "../types/formConfig";
import { AzureComputerVision, GoogleCloudVision } from "../types/ocrConfig";
import { DEFAULT_TESSERACT_CONFIG } from "./ocrConfig";

export const CONTROL_POINT_SIZE = 7;
export const MIN_FIELD_SIZE = 16;
export const MIN_DETECTION_REGION = 16;
export const DEFAULT_FIELD_LABEL = "new field";
export const DEFAULT_FIELD_LANGUAGES = [Language.English];
export const DEFAULT_FIELD_OCR_CONFIG = DEFAULT_TESSERACT_CONFIG;
export const MAX_ALLOWED_FORM_IMAGE_SIZE_IN_BYTES = 5 * 1024 * 1024;
export const MAX_NUMBER_OF_MERCHANT_NAMES = 1000;

export const MIN_NUMBER_OF_FORM_FEATURE = 100;
export const MIN_NUMBER_OF_QUERY_FEATURE = 1000;

export const DEFAULT_SCRIPT = "result = input.text;";
export const DEFAULT_POST_PROCESS_SCRIPT = "result = input;";
export const DEFAULT_DATE_FORMAT = "%Y-%m-%d";
export const PREVIOUS_DEFAULT_DATE_FORMAT = "%d/%m/%Y";

export const DATE_FORMAT_OPTIONS: IChoiceGroupOption[] = [
  {
    key: "%Y-%m-%d",
    text: "YYYY-MM-DD",
  },
  {
    key: "%d/%m/%Y",
    text: "DD/MM/YYYY",
  },
];

export const ENGINE_TO_EXTERNAL_SERVICE_MAP: {
  [key: string]: ExternalServices;
} = {
  [GoogleCloudVision]: "google",
  [AzureComputerVision]: "azure",
};

export const MISSING_EXTERNAL_SERVICES_ERROR_MAP = {
  google: "alert.template_google_setting_is_required",
  azure: "alert.template_azure_setting_is_required",
};

export const DOCUMENT_TYPES: DocumentType[] = [
  "general",
  "receipt",
  "bank_statement",
  "address_proof",
  "invoice",
  "international_id",
];
