import * as React from "react";
import { useSelector } from "react-redux";

import {
  getCustomModel as _getCustomModel,
  useCustomModelActionCreator,
} from "../actions/customModel";
import { useThunkDispatch } from "../hooks/thunk";
import { RootState } from "../redux/types";
import { CustomModel } from "../types/customModel";
import { useWorkerToken } from "./app";

export function isActionInProgress(
  startAt: number | null,
  finishAt: number | null
) {
  if (startAt !== null && finishAt !== null) {
    return finishAt < startAt;
  }
  return startAt !== null;
}

export function useAutoReloadCustomModel(
  customModelId: string | null,
  reloadCondition: boolean,
  reloadInterval: number
) {
  const { getCustomModel } = useCustomModelActionCreator();

  React.useEffect(() => {
    if (reloadCondition && customModelId) {
      const timerId = setInterval(() => {
        getCustomModel(customModelId);
      }, reloadInterval);

      return () => {
        clearTimeout(timerId);
      };
    }

    return () => {};
  }, [customModelId, reloadCondition, reloadInterval, getCustomModel]);
}

export function useCustomModel(customModelId: string) {
  const getCustomModel = useThunkDispatch(_getCustomModel);
  const customModel = useSelector(
    (state: RootState) => state.customModel.currentCustomModel
  );
  const [isFailedToFetchCustomModel, setIsFailedToFetchCustomModel] =
    React.useState(false);

  const shouldFetchCustomModel =
    !customModel ||
    customModel.id !== customModelId ||
    (customModel.id === customModelId &&
      (isActionInProgress(
        customModel.startTrainingAt,
        customModel.finishTrainingAt
      ) ||
        isActionInProgress(
          customModel.startDeploymentAt,
          customModel.finishDeploymentAt
        ) ||
        isActionInProgress(
          customModel.lastCVATProjectStartSyncAt,
          customModel.lastCVATProjectFinishSyncAt
        )));

  React.useEffect(() => {
    if (shouldFetchCustomModel) {
      getCustomModel(customModelId).catch(() => {
        setIsFailedToFetchCustomModel(true);
      });
    }
  }, [getCustomModel, customModelId, shouldFetchCustomModel]);

  return {
    customModel,
    isFailedToFetchCustomModel,
  };
}

interface CommonCustomModelContainerSuccessState {
  state: "success";
  workerToken: string;
  customModel: CustomModel;
  isSyncWithCVATInProgress: boolean;
  canAccessCVATProject: boolean;
  isTrainingInProgress: boolean;
  isDeploymentInProgress: boolean;
}

interface CommonCustomModelContainerErrorState {
  state: "error";
}

interface CommonCustomModelContainerLoadingState {
  state: "loading";
}

export type CommonCustomModelContainerState =
  | CommonCustomModelContainerLoadingState
  | CommonCustomModelContainerErrorState
  | CommonCustomModelContainerSuccessState;

export function useCommonCustomModelContainerState(customModelId: string) {
  const { customModel, isFailedToFetchCustomModel } =
    useCustomModel(customModelId);
  const { token: workerToken, isFailed: isFailedToGetWorkerToken } =
    useWorkerToken();

  const containerState: CommonCustomModelContainerState =
    isFailedToGetWorkerToken || isFailedToFetchCustomModel
      ? { state: "error" }
      : customModel && customModel.id === customModelId && workerToken
      ? {
          state: "success",
          customModel,
          workerToken,
          isSyncWithCVATInProgress: isActionInProgress(
            customModel.lastCVATProjectStartSyncAt,
            customModel.lastCVATProjectFinishSyncAt
          ),
          isTrainingInProgress: isActionInProgress(
            customModel.startTrainingAt,
            customModel.finishTrainingAt
          ),
          isDeploymentInProgress: isActionInProgress(
            customModel.startDeploymentAt,
            customModel.finishDeploymentAt
          ),
          canAccessCVATProject:
            customModel.lastCVATProjectFinishSyncAt !== null,
        }
      : { state: "loading" };

  return containerState;
}
