.section {
  position: relative;
  text-align: center;

  .invitation-container {
    max-width: 320px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;

    h3 {
      margin-bottom: 18px;
    }

    .message {
      margin-bottom: 18px;
    }

    .action {
      .default-button {
        margin-left: 18px;
      }
    }
  }
}
