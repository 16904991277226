@import "../../styles/variables.scss";

.usage-date-range-selector {
  margin: 24px 30px;
  min-width: 540px;

  h1 {
    padding: 10px 0;
    font-size: 18px;
    margin: 10px 0;
    font-weight: 600;
    line-height: 24px;
  }

  .date-picker-row {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .date-picker {
    padding: 0 5px;
    width: 200px;
  }

  .search-button {
    margin-left: 5px;
    margin-top: 24px;
  }
}
