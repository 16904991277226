import * as React from "react";
import { useParams } from "react-router";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FormNavBarLayout } from "../components/FormNavBar";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { OCRTest } from "../components/OCRTest";
import HeaderContainer from "../containers/Header";
import { useTestCommandBarItems } from "../hooks/command_bar_items";
import { useExtractTest } from "../hooks/extract_test";
import { useCommonFormContainerState } from "../hooks/form";
import { FormExtractionMode } from "../types/form";

type PathParam = {
  formId: string;
};

export const FormTestContainer = React.memo(() => {
  const { formId } = useParams<PathParam>();

  const containerState = useCommonFormContainerState(formId);

  const {
    extractWithFile,
    extractWithURL,
    isRecognizing,
    isUploading,
    testReport,
    setTestReport,
    extractionMode,
    onExtractionModeChanged,
  } = useExtractTest(
    containerState.state === "success" ? containerState.form.id : undefined
  );

  const commbarBarItems = useTestCommandBarItems(
    extractWithFile,
    !!testReport && extractionMode !== FormExtractionMode.singlePage,
    !!testReport,
    () => setTestReport(undefined)
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
        ) : containerState.state === "success" ? (
          <>
            <FormNavBarLayout
              form={containerState.form}
              commbarBarItems={commbarBarItems}
            >
              <OCRTest
                onSelectImage={extractWithFile}
                onSelectSampleImage={extractWithURL}
                isSampleForm={containerState.form.isSample}
                isTemplate={containerState.form.isTemplate}
                isReceiptForm={false}
                ocrTestReport={testReport}
                formName={containerState.form.name}
                extractionMode={extractionMode}
                onExtractionModeChanged={onExtractionModeChanged}
              />
            </FormNavBarLayout>
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
          </>
        ) : null}
      </Main>
    </Layout>
  );
});

export default FormTestContainer;
