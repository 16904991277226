import { History } from "history";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import { toggleLeftBarCollapsed as _toggleLeftBarCollapsed } from "../actions/app";
import { list as _list } from "../actions/template";
import LeftBar, { AdminLeftBar } from "../components/LeftBar";
import { useThunkDispatch } from "../hooks/thunk";
import { RootState } from "../redux/types";
import { AvailableTemplateType, Template } from "../types/template";
import { runWithRetry } from "../utils/retry";

interface Props extends RouteComponentProps {
  history: History;
}

export type LeftBarSelectedPage = "forms" | "receipts" | AvailableTemplateType;

function useBindActionCreators() {
  return {
    list: useThunkDispatch(_list),
    toggleLeftBarCollapsed: useThunkDispatch(_toggleLeftBarCollapsed),
  };
}

function useSelectedPage(
  location: Props["location"],
  history: History,
  templates?: Template[]
) {
  const [selectedPage, setSelectedPage] = useState<
    LeftBarSelectedPage | undefined
  >();

  const [isAdminPage, setIsAdminPage] = useState<boolean>(false);

  const onLocationChange = useCallback(
    location => {
      const pathData = location.pathname.split("/");

      if (pathData.length < 2) {
        setSelectedPage(undefined);
        return;
      }

      if (["admin"].includes(pathData[1])) {
        setIsAdminPage(true);

        if (["team"].includes(pathData[2])) {
          setSelectedPage("team-list");
          return;
        }
      }

      if (["form-template", "form"].includes(pathData[1])) {
        const formId = pathData[2];
        if (formId && templates !== undefined) {
          const selectedTemplate = templates.find(x => x.formId === formId);
          if (selectedTemplate) {
            setSelectedPage(selectedTemplate.name);
            return;
          }
        }
        setSelectedPage("forms");
        return;
      } else if (
        [
          "receipt-edit",
          "receipt-test",
          "receipt-api",
          "receipt-batch",
        ].includes(pathData[1])
      ) {
        setSelectedPage("receipts");
        return;
      } else if (["form-group-template", "form-group"].includes(pathData[1])) {
        const formGroupId = pathData[2];
        if (formGroupId && templates !== undefined) {
          const selectedTemplate = templates.find(
            x => x.formGroupId === formGroupId
          );
          if (selectedTemplate) {
            setSelectedPage(selectedTemplate.name);
            return;
          }
        }
        setSelectedPage("form-groups");
        return;
      } else if (["custom-model"].includes(pathData[1])) {
        setSelectedPage("custom-model");
        return;
      } else if (["webhook"].includes(pathData[1])) {
        setSelectedPage("webhook");
        return;
      } else if (["detect-documents"].includes(pathData[1])) {
        setSelectedPage("detect-documents");
        return;
      }
      setSelectedPage(undefined);
    },
    [templates]
  );

  useEffect(() => {
    onLocationChange(location);
    return history.listen(onLocationChange);
  }, [location, history, onLocationChange]);

  return { isAdminPage, selectedPage };
}

export function useLoadTemplate() {
  const templates = useSelector((state: RootState) => state.form.templates);
  const { list } = useBindActionCreators();

  useEffect(
    () => {
      if (templates === undefined) {
        runWithRetry(list).catch(e => {
          console.error("List template error:", e);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list]
  );
}

const LeftBarContainer = React.memo((props: Props) => {
  const { history, location } = props;

  const { toggleLeftBarCollapsed } = useBindActionCreators();

  const templates = useSelector((state: RootState) => state.form.templates);
  const isLeftBarCollapsed = useSelector(
    (state: RootState) => state.app.isLeftBarCollapsed
  );

  const { isAdminPage, selectedPage } = useSelectedPage(
    location,
    history,
    templates
  );

  useLoadTemplate();

  const onClickTemplate = useCallback(
    (template: Template) => {
      if (templates === undefined) {
        return;
      }
      switch (template.type) {
        case "form":
          history.push(`/form-template/${template.formId}/test`);
          break;
        case "form_group":
          history.push(`/form-group-template/${template.formGroupId}/test`);
          break;
        default:
          break;
      }
    },
    [templates, history]
  );

  if (isAdminPage) {
    return (
      <AdminLeftBar
        isLeftBarCollapsed={isLeftBarCollapsed}
        selectedPage={selectedPage}
        toggleLeftBarCollapsed={toggleLeftBarCollapsed}
      />
    );
  }

  return (
    <LeftBar
      isLeftBarCollapsed={isLeftBarCollapsed}
      areTemplatesLoaded={templates !== undefined}
      onClickTemplate={onClickTemplate}
      selectedPage={selectedPage}
      toggleLeftBarCollapsed={toggleLeftBarCollapsed}
      templates={templates}
    />
  );
});

export default withRouter(LeftBarContainer);
