import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import { MatchMode, TokenGroup, TokenGroupType } from "../../types/tokenGroup";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (
    inputValue: string,
    matchMode: MatchMode,
    tokenType: TokenGroupType | undefined
  ) => void;
  tokenGroupNameValidatorWithMessage?: (
    inputValue: string
  ) => string | undefined;
  defaultValue?: TokenGroup;
}

interface MatchModeOptions extends IChoiceGroupOption {
  key: MatchMode;
}

interface TokenTypeOptions extends IChoiceGroupOption {
  key: TokenGroupType;
}

const TokenGroupModal = React.memo((props: Props) => {
  const {
    defaultValue,
    onCancel,
    tokenGroupNameValidatorWithMessage,
    onSubmit,
    isOpen,
  } = props;

  const isEdit = defaultValue !== undefined;

  const { localized } = useLocale();

  const matchModeOptions = useMemo(
    (): MatchModeOptions[] => [
      { key: "all", text: localized("label.match_all") },
      { key: "best", text: localized("label.match_one_only") },
    ],
    [localized]
  );
  const tokenTypeOptions = useMemo(
    (): TokenTypeOptions[] => [
      { key: "texts", text: localized("label.texts") },
      { key: "images", text: localized("label.images") },
    ],
    [localized]
  );

  const [tokenGroupName, setTokenGroupName] = useState<string>("");
  const [matchMode, setMatchMode] = useState<MatchMode>("all");
  const [tokenType, setTokenType] = useState<TokenGroupType | undefined>(
    "texts"
  );
  const [groupNameErrorMessageId, setGroupNameErrorMessageId] = useState<
    string | undefined
  >();

  const onGroupNameChange = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      if (value === undefined) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      setGroupNameErrorMessageId(undefined);
      setTokenGroupName(value);
    },
    []
  );

  const onMatchModeChange = useCallback(
    (
      event?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      option?: IChoiceGroupOption | undefined
    ) => {
      if (event === undefined || option === undefined) {
        return;
      }
      event.stopPropagation();
      setMatchMode((option as MatchModeOptions).key);
    },
    []
  );

  const onTokenTypeChange = useCallback(
    (
      event?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      option?: IChoiceGroupOption | undefined
    ) => {
      if (event === undefined || option === undefined) {
        return;
      }
      event.stopPropagation();
      setTokenType((option as TokenTypeOptions).key);
    },
    []
  );

  useEffect(() => {
    if (defaultValue) {
      setTokenGroupName(defaultValue.name);
      setMatchMode(defaultValue.matchMode);
      setTokenType(defaultValue.tokenType);
    }
  }, [defaultValue]);

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const _tokenGroupName = tokenGroupName.trim();
      e.preventDefault();
      e.stopPropagation();

      if (tokenGroupNameValidatorWithMessage) {
        const errorId = tokenGroupNameValidatorWithMessage(_tokenGroupName);
        if (errorId !== undefined) {
          setGroupNameErrorMessageId(errorId);
          return;
        }
      }

      onSubmit(_tokenGroupName, matchMode, tokenType);

      return;
    },
    [
      tokenGroupNameValidatorWithMessage,
      tokenGroupName,
      matchMode,
      tokenType,
      onSubmit,
    ]
  );

  const onDismissed = useCallback(() => {
    setGroupNameErrorMessageId(undefined);
    setTokenGroupName("");
    setMatchMode("all");
    setTokenType("texts");
  }, []);

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
      className: styles["token-group-modal"],
    }),
    [onDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isEdit ? "edit.token.group.title" : "add.token.group.title"
      ),
    }),
    [localized, isEdit]
  );

  return (
    <Dialog
      minWidth={400}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={_onSubmit}>
        <TextField
          labelId="label.token_group_name"
          onChange={onGroupNameChange}
          value={tokenGroupName}
          errorMessage={
            groupNameErrorMessageId && localized(groupNameErrorMessageId)
          }
        />
        <ChoiceGroup
          className={styles["match-mode-choice-group"]}
          selectedKey={matchMode}
          options={matchModeOptions}
          label={localized("label.match_mode")}
          onChange={onMatchModeChange}
        />
        <ChoiceGroup
          className={styles["match-mode-choice-group"]}
          selectedKey={tokenType}
          options={tokenTypeOptions}
          label={localized("label.token_type")}
          onChange={onTokenTypeChange}
        />
        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
          <PrimaryButton
            type="submit"
            text={localized("token.modal.confirm.button")}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
});
export default TokenGroupModal;
