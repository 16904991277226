import { Dispatch } from "redux";

import { apiClient } from "../apiClient";
import { useThunkDispatch } from "../hooks/thunk";
import { RootState } from "../redux/types";
import { Usage, UsageRange } from "../types/usage";

function getUsage(range: UsageRange) {
  return async (
    _dispatch: Dispatch,
    getState: () => RootState
  ): Promise<Usage[]> => {
    const { resourceOwnerId } = getState().resourceOwner;
    return apiClient.getUsage(range, resourceOwnerId);
  };
}

export function useRequestLogActionCreator() {
  return {
    getUsage: useThunkDispatch(getUsage),
  };
}
