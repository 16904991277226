import {
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
} from "@fluentui/react";
import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { useTeamActionCreator } from "../actions/team";
import ConfirmModal from "../components/ConfirmModal";
import CreateRenameTeamModal from "../components/CreateTeamModal";
import InviteTeamUserModal from "../components/InviteTeamUserModal";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { Team } from "../components/Team";
import { PrimaryButton } from "../components/WrappedMSComponents/Buttons";
import HeaderContainer from "../containers/Header";
import { useLocale } from "../contexts/locale";
import errors, { FOCRError } from "../errors";
import { useRemoteData } from "../hooks/remoteData";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { Permission, getTeamRoleMessageId } from "../types/team";

function useDeleteTeamModal() {
  const teamPlan = useSelector((state: RootState) => state.resourceOwner.plan);
  const { deleteTeam } = useTeamActionCreator();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const toast = useToast();
  const { localized } = useLocale();
  const history = useHistory();
  const [isDeleteTeamModalOpened, setIsDeleteTeamModalOpened] =
    React.useState(false);

  const [isTeamNotFreeModalOpened, setIsTeamNotFreeModalOpened] =
    React.useState(false);

  const openDeleteTeamModal = React.useCallback(() => {
    if (teamPlan !== "free") {
      setIsTeamNotFreeModalOpened(true);
      return;
    }

    setIsDeleteTeamModalOpened(true);
  }, [teamPlan]);

  const closeDeleteTeamModal = React.useCallback(() => {
    setIsTeamNotFreeModalOpened(false);
    setIsDeleteTeamModalOpened(false);
  }, []);

  const onDeleteTeam = React.useCallback(async () => {
    setIsDeleting(true);
    try {
      await deleteTeam();
      history.push("/form");
    } catch (e) {
      if (e instanceof FOCRError) {
        toast.error(e.messageId);
      } else {
        toast.error("team.delete.fail_message");
      }
    } finally {
      setIsDeleting(false);
    }
  }, [deleteTeam, history, toast]);

  const teamPlanIsNotFreeDialogContentProps: IDialogContentProps =
    React.useMemo(
      () => ({
        type: DialogType.normal,
        title: localized("team.delete.plan_is_not_free.title"),
        subText: localized("team.delete.plan_is_not_free.message"),
        onClose: closeDeleteTeamModal,
        showCloseButton: false,
      }),
      [closeDeleteTeamModal, localized]
    );

  return React.useMemo(
    () => ({
      isDeleteTeamModalOpened,
      openDeleteTeamModal,
      closeDeleteTeamModal,
      isDeleting,
      onDeleteTeam,
      isTeamNotFreeModalOpened,
      teamPlanIsNotFreeDialogContentProps,
    }),
    [
      isDeleteTeamModalOpened,
      isDeleting,
      onDeleteTeam,
      openDeleteTeamModal,
      closeDeleteTeamModal,
      isTeamNotFreeModalOpened,
      teamPlanIsNotFreeDialogContentProps,
    ]
  );
}

function useInviteTeamUserModal() {
  const { inviteTeamUser } = useTeamActionCreator();

  const [isInviteTeamUserModalOpened, setIsInviteTeamUserModalOpened] =
    React.useState(false);
  const openInviteTeamUserModal = React.useCallback(() => {
    setIsInviteTeamUserModalOpened(true);
  }, []);
  const closeInviteTeamUserModal = React.useCallback(() => {
    setIsInviteTeamUserModalOpened(false);
  }, []);
  const onInviteTeamUser = React.useCallback(
    async (email: string, permission: Permission) => {
      return inviteTeamUser(email, permission);
    },
    [inviteTeamUser]
  );

  return React.useMemo(
    () => ({
      isInviteTeamUserModalOpened,
      openInviteTeamUserModal,
      closeInviteTeamUserModal,
      onInviteTeamUser,
    }),
    [
      isInviteTeamUserModalOpened,
      openInviteTeamUserModal,
      closeInviteTeamUserModal,
      onInviteTeamUser,
    ]
  );
}

function useRenameTeamModal() {
  const { renameTeam } = useTeamActionCreator();
  const [isRenameTeamModalOpened, setIsRenameTeamModalOpened] =
    React.useState(false);
  const [isRenaming, setIsRenaming] = React.useState(false);
  const openRenameTeamModal = React.useCallback(() => {
    setIsRenameTeamModalOpened(true);
  }, []);
  const closeRenameTeamModal = React.useCallback(() => {
    setIsRenameTeamModalOpened(false);
  }, []);
  const toast = useToast();
  const onRenameTeam = React.useCallback(
    async (name: string, retrievedAt: string): Promise<string> => {
      closeRenameTeamModal();
      setIsRenaming(true);
      try {
        const { updatedAt } = await renameTeam(name, retrievedAt);
        toast.success("team.rename.success_message", undefined, { team: name });
        return updatedAt;
      } catch (e) {
        if (e instanceof FOCRError) {
          if (e !== errors.ConflictFound) {
            toast.error(e.messageId);
          }
        } else {
          toast.error("team.rename.fail_message");
        }
        return Promise.reject();
      } finally {
        setIsRenaming(false);
      }
    },
    [closeRenameTeamModal, renameTeam, toast]
  );

  return React.useMemo(
    () => ({
      isRenameTeamModalOpened,
      openRenameTeamModal,
      closeRenameTeamModal,
      onRenameTeam,
      isRenaming,
    }),
    [
      isRenameTeamModalOpened,
      openRenameTeamModal,
      closeRenameTeamModal,
      onRenameTeam,
      isRenaming,
    ]
  );
}

function _TeamContainer() {
  const { listTeamMembers } = useTeamActionCreator();
  const resourceOwnerId = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.resourceOwnerId
  );

  const fetchFunction = useCallback(() => {
    return listTeamMembers(resourceOwnerId);
  }, [listTeamMembers, resourceOwnerId]);

  const { remoteData, setSuccessfulRemoteDataValue, reloadRemoteData } =
    useRemoteData(fetchFunction);

  const listTeamMembersRef = useRef(listTeamMembers);
  listTeamMembersRef.current = listTeamMembers;

  const { setTeamUserPermission, removeTeamUser, removeTeamInvitation } =
    useTeamActionCreator();

  const toast = useToast();
  const { localized } = useLocale();

  const updateUserPermission = useCallback(
    async (userId: string, permission: Permission) => {
      if (remoteData.state !== "success") {
        return;
      }

      try {
        const { updatedAt } = await setTeamUserPermission(
          userId,
          permission,
          remoteData.value.updatedAt
        );

        setSuccessfulRemoteDataValue({
          ...remoteData.value,
          updatedAt,
          members: remoteData.value.members.map(user => {
            if (user.userId === userId) {
              return {
                ...user,
                permission,
              };
            }
            return user;
          }),
        });

        const userEmail = remoteData.value.members.find(
          member => member.userId === userId
        )?.email;
        if (userEmail)
          toast.success("team.role.updated", undefined, {
            email: userEmail,
            role: localized(getTeamRoleMessageId(permission)),
          });
      } catch (e) {
        if (e !== errors.ConflictFound && e !== errors.ConfirmationRejected) {
          toast.error("team.role.failed_to_update");
        }
      }
    },
    [
      localized,
      remoteData,
      setSuccessfulRemoteDataValue,
      setTeamUserPermission,
      toast,
    ]
  );

  const removeUser = useCallback(
    async (userId: string) => {
      try {
        await removeTeamUser(userId);
      } catch {
        toast.error("team.remove_user.unexpected_error");
        return;
      }

      if (remoteData.state !== "success") {
        return;
      }

      toast.success("team.remove_user.success_message");

      setSuccessfulRemoteDataValue({
        ...remoteData.value,
        members: remoteData.value.members.filter(
          user => user.userId !== userId
        ),
      });
    },
    [remoteData, removeTeamUser, setSuccessfulRemoteDataValue, toast]
  );

  const removeInvitation = useCallback(
    async (invitationId: string) => {
      if (remoteData.state !== "success") {
        return;
      }

      try {
        await removeTeamInvitation(invitationId);
      } catch (e) {
        if (e instanceof FOCRError) {
          if (e === errors.TeamInvitationNotFound) {
            reloadRemoteData();
          }
          toast.error(e.messageId);
        } else {
          toast.error("team.invitation.remove.unexpected_error");
        }
        return;
      }

      toast.success("team.invitation.remove.success_message");

      setSuccessfulRemoteDataValue({
        ...remoteData.value,
        invitations: remoteData.value.invitations.filter(
          invitation => invitation.id !== invitationId
        ),
      });
    },
    [
      reloadRemoteData,
      remoteData,
      removeTeamInvitation,
      setSuccessfulRemoteDataValue,
      toast,
    ]
  );

  const {
    isInviteTeamUserModalOpened,
    openInviteTeamUserModal,
    closeInviteTeamUserModal,
    onInviteTeamUser,
  } = useInviteTeamUserModal();

  const [isInviting, setIsInviting] = React.useState(false);

  const onInvite = useCallback(
    async (email: string, permission: Permission) => {
      closeInviteTeamUserModal();
      setIsInviting(true);

      try {
        const invitationId = await onInviteTeamUser(email, permission);

        toast.success("invite.new.member.success_message", undefined, {
          email,
        });

        if (remoteData.state !== "success") {
          return;
        }

        setSuccessfulRemoteDataValue({
          ...remoteData.value,
          invitations: remoteData.value.invitations.concat({
            email,
            permission,
            id: invitationId,
          }),
        });
      } catch (e) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined, { email });
        } else {
          toast.error("invite.new.member.fail_message", undefined, { email });
        }
      } finally {
        setIsInviting(false);
      }
    },
    [
      closeInviteTeamUserModal,
      onInviteTeamUser,
      remoteData,
      setSuccessfulRemoteDataValue,
      toast,
    ]
  );

  const {
    isRenameTeamModalOpened,
    openRenameTeamModal,
    closeRenameTeamModal,
    onRenameTeam,
    isRenaming,
  } = useRenameTeamModal();

  const _onRenameTeam = useCallback(
    async (name: string) => {
      if (remoteData.state !== "success") {
        return;
      }
      try {
        const updatedAt = await onRenameTeam(name, remoteData.value.updatedAt);

        setSuccessfulRemoteDataValue({
          ...remoteData.value,
          updatedAt,
        });
      } catch (error) {
        throw error;
      }
    },
    [onRenameTeam, remoteData, setSuccessfulRemoteDataValue]
  );

  const {
    isDeleteTeamModalOpened,
    openDeleteTeamModal,
    closeDeleteTeamModal,
    onDeleteTeam,
    isTeamNotFreeModalOpened,
    teamPlanIsNotFreeDialogContentProps,
    isDeleting,
  } = useDeleteTeamModal();

  const teamName = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.teamName
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <>
        <Main hasTop={true}>
          {remoteData.state === "success" && (
            <Team
              usersRemoteData={remoteData}
              setTeamUserPermission={updateUserPermission}
              removeTeamUser={removeUser}
              onInviteMember={openInviteTeamUserModal}
              onRenameTeam={openRenameTeamModal}
              onRemoveTeam={openDeleteTeamModal}
              removeInvitation={removeInvitation}
            />
          )}
          <InviteTeamUserModal
            isOpen={isInviteTeamUserModalOpened}
            onCancel={closeInviteTeamUserModal}
            onInvite={onInvite}
          />
          <CreateRenameTeamModal
            isRename={true}
            isOpen={isRenameTeamModalOpened}
            onCancel={closeRenameTeamModal}
            onConfirm={_onRenameTeam}
          />
          <LoadingModal
            isOpen={
              remoteData.state === "loading" ||
              isInviting ||
              isRenaming ||
              isDeleting
            }
            messageId={
              isInviting
                ? "invite.new.member.inviting"
                : isRenaming
                ? "team.renaming"
                : isDeleting
                ? "team.deleting"
                : undefined
            }
          />
          <ConfirmModal
            isOpen={isDeleteTeamModalOpened}
            modalType={ConfirmModalType.Destory}
            onCancel={closeDeleteTeamModal}
            onConfirm={onDeleteTeam}
            titleId="team.delete"
            actionId="common.delete"
            messageId="team.delete.confirm"
            challenge={teamName}
          />
          <Dialog
            hidden={!isTeamNotFreeModalOpened}
            dialogContentProps={teamPlanIsNotFreeDialogContentProps}
          >
            <DialogFooter>
              <PrimaryButton
                onClick={closeDeleteTeamModal}
                textId={"common.close"}
              />
            </DialogFooter>
          </Dialog>
        </Main>
      </>
    </Layout>
  );
}

export const TeamContainer = React.memo(_TeamContainer);
export default TeamContainer;
