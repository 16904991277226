@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.api-note {
  height: 100%;
  overflow: auto;
  padding: 0 10px;
  padding-bottom: 60px;

  .section {
    margin: 20px;
    margin-top: 0;

    h1 {
      padding: 10px 0;
      font-size: 18px;
      margin: 10px 0;
      border-bottom: 1px solid #ccc;
    }
  }

  .description {
    margin: 0;
    font-family: inherit;
    background-color: transparent;
  }

  .default-value {
    span {
      color: #962e40;
      background-color: #ffc6a8;
      padding: 4px;
      font-size: 10px;
      margin-left: 5px;
    }
  }
  label {
    margin: 10px;
    margin-top: 30px;
    margin-bottom: 0;
  }
  table {
    margin: 10px;
    border-collapse: collapse;
    font-size: 14px;
    min-width: 600px;
    @media only screen and (max-width: 600px) {
      width: calc(100% - 20px);
      min-width: inherit;
    }
  }

  table,
  th,
  td {
    border: 1px solid #ccc;
    color: #333;
    line-height: 1.5em;
  }

  thead {
    background-color: #ddd;
  }

  th,
  td {
    padding: 5px;
  }

  span.optional {
    font-size: 10px;
    color: #eee;
    background-color: #444;
    padding: 4px;
    margin-left: 5px;
  }

  .endpoint-group {
    padding: 10px;

    span.method {
      padding: 8px;
      color: #eee;
      background-color: #0894a1;
      border-radius: 3px;
      font-size: 14px;
    }

    span.endpoint {
      margin-left: 10px;
      padding: 8px;
      color: #eee;
      background-color: #444;
      border-radius: 3px;
      font-size: 14px;
      white-space: nowrap;
    }
  }

  pre {
    margin: 10px;
    padding: 10px;
    font-family: monospace;
    font-size: 14px;
    overflow-x: auto;
    line-height: 1.6em;

    background-color: #ddd;
  }

  .heading-with-toggle {
    display: flex;
    label {
      margin-top: 0;
    }
    .heading {
      flex: 1;
    }

    :global(.ms-Toggle-innerContainer) {
      label {
        min-width: 26px;
      }
    }
  }

  .async {
    h1 {
      font-size: 16px;
      border-bottom: 0;
    }
    > h1 span {
      cursor: pointer;
    }

    p {
      padding: 10px;
      font-size: 14px;
      line-height: 1.6em;
    }
    code {
      padding: 2px 4px;
      color: #1f1f1f;
      background-color: #f0f0f0;
      border-radius: 4px;
    }
    h4 {
      padding: 0 10px;
      margin-top: 20px;
    }
  }

  .action-button {
    cursor: pointer;
    font-weight: bold;
  }
}
