import React from "react";

import FieldCard from "../FieldCard";
import styles from "./styles.module.scss";

interface Props {
  fieldNameValue: string;
  fieldNameErrorMessage: string;
  onFieldNameValueChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => void;
  onDeleteClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onSettingTrigger: () => void;
  type: string;
}

const mapTypeToText = new Map([
  ["keyValueType", "form_inspector.document_field.key_value_type"],
  ["textTokenGroupType", "form_inspector.document_field.text_token_group_type"],
  [
    "imageTokenGroupType",
    "form_inspector.document_field.image_token_group_type",
  ],
]);

const DocumentFieldCard = React.memo((props: Props) => {
  const {
    fieldNameValue,
    onFieldNameValueChange,
    onDeleteClick,
    onSettingTrigger,
    fieldNameErrorMessage,
    type,
  } = props;

  const typeText = mapTypeToText.get(type) as string;

  return (
    <div className={styles["document-field-card"]}>
      <FieldCard
        field1Id="form_inspector.document_field.field_name"
        field1Value={fieldNameValue}
        onField1ValueChange={onFieldNameValueChange}
        field1ErrorMessage={fieldNameErrorMessage}
        field2Label="form_inspector.document_field.type"
        field2Key={typeText}
        settingEnabled={true}
        onSettingTrigger={onSettingTrigger}
        onDeleteClick={onDeleteClick}
      />
    </div>
  );
});

export default DocumentFieldCard;
