@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.placeholder {
  pointer-events: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed $theme-primary;
  margin: 10px;

  > div {
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    pointer-events: auto;
  }

  .upload-icon {
    @include no-select();
    font-size: 60px;
    line-height: 60px;
    height: 60px;
    align-self: start;
  }

  .instructions {
    margin-left: 12px;
    font-weight: 500;

    p {
      margin: 5px 0;

      button {
        margin: 0 8px 0 8px;
      }
    }

    .multidoc-dropdown {
      margin-bottom: 10px;
    }

    .multidoc-desc {
      font-size: 12px;
      color: #605e5c;
      margin-top: 3px;
      margin-left: 29px;
    }
  }
}
